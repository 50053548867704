/**
 * Returns an object from the array having Specific Sales Mode
 * @param {Array} saleModeStats Array Provide for object return
 * @param {String} saleMode Sale Mode for filtering the array
 * @returns
 */
const findSaleStatsBySaleMode = (saleModeStats, saleMode) => {
  if (saleModeStats.find((x) => x.saleMode === saleMode)) {
    return saleModeStats.find((x) => x.saleMode === saleMode);
  } else return {};
};

/**
 * Returns an object from the array having Specific Sales Type
 * @param {Array} saleTypeStats Array Provide for object return
 * @param {String} saleType Sale Type for filtering the array
 * @returns
 */

/**
 * To check the Value is undefined or not..
 * @param {Any} data value given to check the status
 * @param {Any} val if condition is true (Returning Value)
 * @returns Any
 */
const checkIfValueExists = (item, replacementValue) => {
  if (item === undefined || item === 0 || item === null)
    return replacementValue;
  else return item;
};

/**
 * Object Designing of Receipt Generic Report
 * @param {Array} data Data from which Shift Sales Receipt Report's object will be made
 * @returns
 */
export default function (data) {
  let model = [];

  if (data.total !== undefined && data.total.length > 0) {
    model = populateModel(data);
  } else {
    model = [];
  }

  localStorage.setItem('ShiftSalesReceiptReport', JSON.stringify(model));
}

/**
 * Returns the Object for rendering the report
 * @param {Array} data array provided to make the report object
 * @returns
 */
const populateModel = (data) => {
  const cashStats = findSaleStatsBySaleMode(data.saleModeStats, 'cash');
  const creditCardStats = findSaleStatsBySaleMode(
    data.saleModeStats,
    'creditcard'
  );

  /**
   * Constant variables for declaring in Report Object
   */
  const companyName = localStorage.getItem('CompanyName');
  const receiptName = 'Shift Sales Receipt';
  const shiftNumber = checkIfValueExists(data.shiftNumber, 'N/A');
  const openDate = checkIfValueExists(data.createdAt, 'N/A');
  const openUser = checkIfValueExists(data.createdBy.name, 'N/A');
  const closeDate = checkIfValueExists(data.updatedAt, 'N/A');
  const closeUser = checkIfValueExists(data.createdBy.name, 'N/A');
  const subTotal = checkIfValueExists(
    data.total[0].subTotal - data.total[0].tax - data.total[0].discount,
    0
  );
  const tax = checkIfValueExists(data.total[0].tax, 0);
  const deliveryCharges = checkIfValueExists(
    cashStats.deliveryCharges + creditCardStats.deliveryCharges,
    0
  );
  const discount = checkIfValueExists(data.total[0].discount, 0);
  const netSales = checkIfValueExists(
    data.total[0].subTotal +
      data.total[0].deliveryCharges -
      data.total[0].discount,
    0
  );
  const cashReceived = checkIfValueExists(
    cashStats.subTotal - cashStats.discount + cashStats.deliveryCharges,
    0
  );
  const creditCardReceived = checkIfValueExists(
    creditCardStats.subTotal -
      creditCardStats.discount +
      creditCardStats.deliveryCharges,
    0
  );
  const openingCash = checkIfValueExists(data.openingAmount, 0);
  const cashReceive = checkIfValueExists(
    cashStats.subTotal + cashStats.deliveryCharges + cashStats.openingAmount,
    0
  );
  const netCash = checkIfValueExists(
    cashStats.subTotal + cashStats.deliveryCharges,
    0
  );
  const closingCash = checkIfValueExists(data.actualClosingAmount, 0);
  const excessShort =
    checkIfValueExists(data.actualClosingAmount, 0) -
    checkIfValueExists(cashStats.subTotal + cashStats.deliveryCharges, 0);

  return [
    {
      heading: {
        companyName,
        receiptName,
      },
    },
    {
      data: {
        'Shift #': shiftNumber,
        'Open Date': openDate,
        'Open User ID': openUser,
        'Close Date': closeDate,
        'Close User ID': closeUser,
      },
    },
    {
      data: {
        'Sub Total (+)': subTotal,
        'Tax (+)': tax,
        'Delivery Charges (+)': deliveryCharges,
        'Discount (-)': discount,
        'Net Sales': netSales,
      },
    },
    {
      data: {
        'Cash Received': cashReceived,
        'CC Received': creditCardReceived,
      },
    },
    {
      subHeading: {
        name: 'Cash Reconciliation',
      },
    },
    {
      data: {
        'Opening Cash': openingCash,
        'Cash Received': cashReceive,
        'Net Cash': netCash,
        'Closing Cash': closingCash,
        'Excess / Short': excessShort,
      },
    },
  ];
};
