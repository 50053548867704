import React, { useState, useEffect } from 'react';
import Controls from '../../../Utilities/Controls/Controls';
import { Form, useForm } from '../../../Utilities/ReuseableCommponents/useForm';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import PageTitle from '../../../Layout/PageTitle';
import ConfirmDialog from '../../../Utilities/ReuseableCommponents/ConfirmDialog';
import Notification from '../../../Utilities/ReuseableCommponents/Notification';
import {
  Paper,
  Grid,
  IconButton,
  Divider,
  Typography,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { getCustomerType, getCreditLimit } from '../../../../Common/constants';
import Delete from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { getAllCitiesForDropDown } from '../../City/CityService';
import { getAllAreaes } from '../../Area/AreaService';
import * as CustomerService from '../CustomerService';
import * as setupService from '../../Setups/SetupService';
import styles from './CustomerDetailsComponent.module.css';
import useStyles from '../../../../Style/AppStylesAdmin';

const genericAddress = {
  addressDetails: '',
  nearestLandMark: '',
  addressLabel: 'Home',
  areaRef: null,
  cityRef: null,
  isPrimary: false,
  areaOptions: [],
};
const CustomerDetailsComponent = ({
  pageHeaderTitile,
  openInPos = false,
  recordForEdit = null,
  setCustomerResponse = null,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const [areas, setAreas] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [fieldList, setFieldList] = useState([]);
  const [dynamicFields, setDynamicFields] = useState([]);

  function emptyAddressArray() {
    return [
      {
        shipping: { ...genericAddress, addressType: 'shipping' },
        billing: {
          ...genericAddress,
          addressType: 'billing',
          sameAsShipping: true,
        },
      },
    ];
  }

  useEffect(() => {
    setupService.getSetupWithName('CustomerConfig').then((response) => {
      if (response && response.data && response.data.data.length) {
        setFieldList(response.data.data[0].data.customerFieldsConfig);
        setDynamicFields(response.data.data[0].data.dynamicFields);
        if (openInPos && recordForEdit != null) {
          setDynamicFieldValues(
            recordForEdit.dynamicFields,
            response.data.data[0].data.dynamicFields
          );
        } else if (location.state && location.state.recordForEdit) {
          setDynamicFieldValues(
            location.state.recordForEdit.dynamicFields,
            response.data.data[0].data.dynamicFields
          );
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // handle input change address fields
  const handleInputChangeAddress = (e, index, type) => {
    const { name, value } = e.target;
    const list = [...addresses];

    list[index][type][name] = value;
    if (type === 'shipping' && list[index]['billing']['sameAsShipping']) {
      list[index]['billing'][name] = value;
    }
    setAddresses(list);
  };

  // handle input change is primary
  const handleInputChangeIsPrimary = (e, index) => {
    const { name, value } = e.target;
    const list = [...addresses];

    if (e.target.name === 'isPrimary') {
      list[index]['shipping']['isPrimary'] = false;
      list[index]['billing']['isPrimary'] = false;
    }
    list[index]['shipping'][name] = value;
    list[index]['billing'][name] = value;
    setAddresses(list);
  };

  // handle input change same as shipping address
  const handleInputChangeSameAs = (e, index) => {
    const { name, value } = e.target;
    const list = [...addresses];

    if (name === 'sameAsShipping' && value) {
      list[index]['billing'] = { ...list[index]['shipping'] };
      list[index]['billing']['addressType'] = 'billing';
      list[index]['billing']['sameAsShipping'] = value;
    } else {
      list[index]['billing'] = {
        addressDetails: '',
        nearestLandMark: '',
        addressLabel: '',
        addressType: 'billing',
        areaRef: null,
        cityRef: null,
        isPrimary: false,
        sameAsShipping: false,
        areaOptions: [],
      };
      list[index]['billing']['sameAsShipping'] = value;
    }
    setAddresses(list);
  };

  // handle click event of the Remove button
  const handleRemoveClickCustomerAddress = (index) => {
    const list = [...addresses];
    list.splice(index, 1);
    let isPrimaryExist = false;
    list.forEach(function (_, indx) {
      if (list[indx]['billing']['isPrimary'] === true && !isPrimaryExist) {
        isPrimaryExist = true;
      }
    });

    if (!isPrimaryExist && list.length > 0) {
      list[list.length - 1]['billing']['isPrimary'] = true;
      list[list.length - 1]['shipping']['isPrimary'] = true;
    }
    setAddresses(list);
  };

  // handle click event of the Add button
  const handleAddClickCustomerAddress = () => {
    if (addresses.length < 5) {
      setAddresses([
        ...addresses,
        {
          shipping: { ...genericAddress, addressType: 'shipping' },
          billing: {
            ...genericAddress,
            addressType: 'billing',
            sameAsShipping: true,
          },
        },
      ]);
    } else {
      setNotify({
        isOpen: true,
        message: 'Limit of multiple addressess is exceeded',
        type: 'warning',
      });
    }
  };

  const setAreaDropdown = (cityId, index, type) => {
    [...addresses][index][type]['areaOptions'] = getArea(areas, cityId);
  };

  const initialFormValues = {
    _id: 0,
    name: '',
    CustomerType: 'walkin',
    city: '',
    status: true,
    customerId: '',
    contactNumber: '',
    alternateContact: '',
    email: '',
    nearestLandMark: '',
    creditLimit: '#',
    openingAmount: 0,
    addressList: addresses,
    dynamicFields: dynamicFields,
  };
  let Responce = {
    data: null,
    error: null,
    loading: false,
  };

  let apiResponce = {
    data: null,
    error: null,
    loading: false,
    statusCode: 0,
  };

  const history = useHistory();

  const [cities, setCities] = useState([]);

  const addressLabelArr = [
    {
      _id: 'Home',
      name: 'Home',
      nameValue: 'Home',
    },
    {
      _id: 'Office',
      name: 'Office',
      nameValue: 'Office',
    },
    {
      _id: 'Other',
      name: 'Other',
      nameValue: 'Other',
    },
  ];

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const { values, setValues, handleInputChange, resetForm } = useForm(
    initialFormValues
  );

  async function getCityListBrnch() {
    await getAllCitiesForDropDown().then((response) => {
      if (response.data) {
        //Map data array with Generic List Object with Properties id,text
        //Dropdown only accepts list of Object with Properties id,text
        var result = response.data.data.map((dataArrayRow) => ({
          _id: dataArrayRow._id,
          name: dataArrayRow.name,
        }));

        var cityList = [];
        result.forEach((city) => {
          let cityForDD = {
            _id: city._id,
            name: city.name,
            nameValue: city.name,
          };
          cityList.push(cityForDD);
        });
        setCities(cityList);
        addresses.forEach((address) => {
          if (address.shipping.cityRef && address.billing.cityRef) {
            address.shipping.cityRef = cityList[0]._id;
            address.billing.cityRef = cityList[0]._id;
          }
        });
        if (response.data.count === 0) {
          setNotify({
            isOpen: true,
            message: 'No Cities To Load',
            type: 'error',
          });
        }
      } else {
        console.error('Unable To Get Records');
      }
    });
  }

  async function getAreaValues() {
    var areaList = [];
    await getAllAreaes().then((response) => {
      if (response.data) {
        //Map data array with Generic List Object with Properties id,text
        //Dropdown only accepts list of Object with Properties id,text
        var result = response.data.data
          .filter((fl) => fl.status == 'active')
          .map((dataArrayRow) => ({
            _id: dataArrayRow._id,
            name: dataArrayRow.name,
            cityRef: dataArrayRow.cityRef,
          }));

        result.forEach((area) => {
          let areaForDD = {
            _id: area._id,
            name: area.name,
            cityRef: [area.cityRef],
          };
          areaList.push(areaForDD);
        });
        addresses.forEach((address) => {
          if (address.shipping.areaRef && address.billing.areaRef) {
            address.shipping.areaRef = areaList[0]._id;
            address.billing.areaRef = areaList[0]._id;
          }
        });
      }
    });

    return areaList;
  }

  async function getAreaList() {
    const areaA = await getAreaValues();
    setAreas(areaA);
  }

  const addOrEdit = async (customer, resetFormCust) => {
    if (addresses && addresses.length) {
      let index = addresses.findIndex((ad) => ad.shipping.isPrimary === true);
      if (index < 0) {
        addresses[0]['shipping']['isPrimary'] = true;
        addresses[0]['billing']['isPrimary'] = true;
      }
    }
    let addressList = [];
    addresses.forEach((address) => {
      if (address.billing.sameAsShipping) {
        address.billing = { ...address.shipping };
        address.billing.addressType = 'billing';
      }
      addressList.push(address.shipping);
      addressList.push(address.billing);
    });

    var customerInsert = {
      customerId: parseInt(customer.contactNumber),
      name: customer.name,
      contactNumber: customer.contactNumber,
      alternateContact: customer.alternateContact,
      email: customer.email,
      CustomerType: customer.CustomerType,
      creditLimit: customer.creditLimit === '#' ? 'none' : customer.creditLimit,
      openingAmount: customer.openingAmount,
      addresses: addressList,
      dynamicFields: dynamicFields,
    };

    if (
      customer._id === 0 &&
      customerInsert.name &&
      customerInsert.contactNumber
    ) {
      try {
        Responce.data = null;
        Responce.error = null;

        Responce = await CustomerService.insertCustomer(
          customerInsert,
          addresses
        ).then((ResponceIns) => {
          return ResponceIns;
        });

        if (Responce.data) {
          resetFormCust();
          if (addresses && addresses.length) setAddresses(emptyAddressArray());
          let fList = [...dynamicFields];
          fList.map((_) => (_.value = ''));
          setDynamicFields(fList);

          setNotify({
            isOpen: true,
            message: 'Submitted Successfully',
            type: 'success',
          });
        }
        if (Responce.error) {
          setNotify({
            isOpen: true,
            message: Responce.messege || Responce.message,
            type: 'error',
          });
        }
        if (setCustomerResponse != null && Responce.data != null) {
          setCustomerResponse(Responce.data.data);
        }
      } catch (e) {
        setNotify({
          isOpen: true,
          message: 'Unable To Process Request',
          type: 'error',
        });
      }
    } else if (
      customer._id !== 0 &&
      customerInsert.name &&
      customerInsert.contactNumber
    ) {
      try {
        addressList = [];
        addresses.forEach((address) => {
          addressList.push(address.shipping);
          addressList.push(address.billing);
        });

        customerInsert = {
          _id: customer._id,
          name: customer.name,
          contactNumber: customer.contactNumber,
          alternateContact: customer.alternateContact,
          email: customer.email,
          CustomerType: customer.CustomerType,
          creditLimit:
            customer.creditLimit === '#' ? 'none' : customer.creditLimit,
          openingAmount: customer.openingAmount,
          addresses: addressList,
          dynamicFields: dynamicFields,
        };
        apiResponce.data = null;
        apiResponce.error = null;
        apiResponce = await CustomerService.updateCustomer(customerInsert).then(
          (ResponceUpdate) => {
            return ResponceUpdate;
          }
        );
        if (apiResponce.data) {
          setNotify({
            isOpen: true,
            message: 'Customer Detail Updated Successfully',
            type: 'success',
          });
        }
        if (apiResponce.error) {
          setNotify({
            isOpen: true,
            message: Responce.messege || Responce.message,
            type: 'error',
          });
        }
        if (setCustomerResponse != null && apiResponce.data != null) {
          setCustomerResponse(apiResponce.data.data);
        }
      } catch (e) {
        setNotify({
          isOpen: true,
          message: 'Unable To Process Request',
          type: 'error',
        });
      }
    } else {
      setNotify({
        isOpen: true,
        message: 'Customer Name and Contact Number is required',
        type: 'error',
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addOrEdit(values, resetForm);
  };

  useEffect(() => {
    async function manuplate() {
      getCityListBrnch();
      getAreaList();
      if (openInPos && recordForEdit != null) {
        setValues({ ...recordForEdit });
        jsonConvertor(recordForEdit.addresses);
      } else {
        if (location.state && location.state.recordForEdit != null) {
          setValues({ ...location.state.recordForEdit });
          jsonConvertor(location.state.recordForEdit.addresses);
        }
      }
    }
    manuplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openInPos ? recordForEdit : location.state.recordForEdit]);

  function jsonConvertor(addressArray) {
    let arr = [];
    let final = [];
    [...addressArray].forEach((address) => {
      address.cityRef =
        (address.cityRef && address.cityRef._id) || address.cityRef;
      address.areaRef =
        (address.areaRef && address.areaRef._id) || address.areaRef;

      arr.push(address);
      if (arr.length === 2) {
        let result = {};
        if (arr[0].addressType === 'billing') {
          result.billing = arr[0];
          result.shipping = arr[1];
        } else {
          result.shipping = arr[0];
          result.billing = arr[1];
        }
        final.push(result);
        arr = [];
      }
    });
    setAddresses(final);
  }

  function setDynamicFieldValues(dynamicFieldsWithValues, customFields) {
    let tempDynamicFields = [...customFields];
    if (dynamicFieldsWithValues) {
      [...dynamicFieldsWithValues].forEach((field) => {
        let index = tempDynamicFields.findIndex(
          (dField) => dField.id === field.id
        );
        if (index > -1) tempDynamicFields[index].value = field.value;
      });
    }
    setDynamicFields(tempDynamicFields);
  }

  function isFieldRequired(fieldName) {
    let findField = fieldList.find((field) => field.name === fieldName);
    return (fieldList.length && findField && findField.isRequired) || false;
  }

  function isFieldVisible(fieldName) {
    let findField = fieldList.find((field) => field.name === fieldName);
    return (fieldList.length && findField && findField.isVisible) || false;
  }

  function handleDynamicFieldChange(event, index) {
    let customFields = [...dynamicFields];
    customFields[index].value = event.target.value;
    setDynamicFields(customFields);
  }

  return (
    <>
      <PageTitle
        title={pageHeaderTitile}
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="customerDetailsHeading"
      />
      <Paper elevation={6} square className="paperMargin">
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid container>
              <Grid item xs={4} sm={4}>
                <Controls.Input
                  name="name"
                  label="Customer Name"
                  value={values.name}
                  onChange={handleInputChange}
                  id="txtCustName"
                  required
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                <Controls.Input
                  name="contactNumber"
                  label="Contact Number"
                  value={values.contactNumber}
                  onChange={handleInputChange}
                  id="txtCustContactNumber"
                  helperText={'Cell: 03XXYYYYYYY'}
                  required
                  inputProps={{
                    className: classes.invalid,
                    pattern: '^[0][0-9]{10}$|^[1-9]{9}$',
                    maxLength: 11,
                  }}
                />
              </Grid>
              {isFieldVisible('Alternate Contact') && (
                <Grid item xs={4} sm={4}>
                  <Controls.Input
                    name="alternateContact"
                    label="Alternate Contact"
                    value={values.alternateContact}
                    onChange={handleInputChange}
                    id="txtCustAlternateContact"
                    helperText={'Cell: 03XXYYYYYYY'}
                    required={isFieldRequired('Alternate Contact')}
                    inputProps={{
                      className: classes.invalid,
                      pattern: '^[0][0-9]{10}$|^[1-9]{9}$',
                      maxLength: 11,
                    }}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container>
              {isFieldVisible('Email') && (
                <Grid item xs={4} sm={4}>
                  <Controls.Input
                    name="email"
                    type="email"
                    label="Email"
                    value={values.email}
                    onChange={handleInputChange}
                    helperText="example@domain.com"
                    id="txtCustEmail"
                    required={isFieldRequired('Email')}
                  />
                </Grid>
              )}
              {isFieldVisible('Customer Type') && (
                <Grid item xs={4} sm={4}>
                  <Controls.Select
                    name="CustomerType"
                    label="Customer Type"
                    value={values.CustomerType}
                    options={getCustomerType()}
                    onChange={handleInputChange}
                    id="ddCustomerType"
                    required={isFieldRequired('Customer Type')}
                    InputLabelProps={{
                      classes: {
                        asterisk: classes.asterisk,
                      },
                    }}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container>
              {isFieldVisible('Opening Amount') && (
                <Grid item xs={4} sm={4}>
                  <Controls.Input
                    name="openingAmount"
                    type="number"
                    label="Opening Amount"
                    value={values.openingAmount}
                    onChange={handleInputChange}
                    id="txtCustOpeningAmount"
                    required={isFieldRequired('Opening Amount')}
                  />
                </Grid>
              )}
              {isFieldVisible('Credit Limit') && (
                <Grid item xs={4} sm={4}>
                  <Controls.Select
                    name="creditLimit"
                    label="Credit Limit"
                    value={values.creditLimit}
                    options={getCreditLimit()}
                    onChange={handleInputChange}
                    id="ddCustCreditLimit"
                    required={isFieldRequired('Credit Limit')}
                    InputLabelProps={{
                      classes: {
                        asterisk: classes.asterisk,
                      },
                    }}
                  />
                </Grid>
              )}
            </Grid>
            {addresses && addresses.length > 0 && (
              <>
                <Grid item xs={12} className={styles.dividerStyle}>
                  <Divider variant="middle" />
                  <span className={styles.addressDivider}>
                    <>Address Section ({addresses.length} / 5)</>
                  </span>
                </Grid>
                {addresses.map((address, index) => (
                  <Grid key={index} container className={styles.gridContainer}>
                    <Grid container justify="space-between">
                      <Grid item>
                        <Controls.Checkbox
                          name="isPrimary"
                          label="Primary"
                          value={address.shipping.isPrimary}
                          onChange={(e) => handleInputChangeIsPrimary(e, index)}
                          id={`chkCustIsPrimary${index}`}
                        />
                      </Grid>
                      <Grid item>
                        <IconButton
                          color="secondary"
                          aria-label="delete address"
                          onClick={() =>
                            handleRemoveClickCustomerAddress(index)
                          }
                        >
                          <Delete />
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body1"
                        className={styles.addressTitle}
                      >
                        Shipping Address
                      </Typography>
                      <Grid container>
                        {isFieldVisible('Address Label') && (
                          <Grid item xs={6}>
                            <Controls.Select
                              name={'addressLabel'}
                              label="Address Label"
                              value={address.shipping.addressLabel}
                              options={addressLabelArr}
                              onChange={(e) => {
                                handleInputChangeAddress(e, index, 'shipping');
                              }}
                              id={`ddCustAddressLabel${index}`}
                              isNoneAllowed={false}
                              required={isFieldRequired('Address Label')}
                              InputLabelProps={{
                                classes: {
                                  asterisk: classes.asterisk,
                                },
                              }}
                            />
                          </Grid>
                        )}
                        {isFieldVisible('City') && (
                          <Grid item xs={6}>
                            <Controls.Select
                              name="cityRef"
                              label="City"
                              value={address.shipping.cityRef}
                              options={cities}
                              onChange={(e) => {
                                handleInputChangeAddress(e, index, 'shipping');
                                setAreaDropdown(
                                  e.target.value,
                                  index,
                                  'shipping'
                                );
                              }}
                              id={`ddCustCity${index}`}
                              isNoneAllowed={false}
                              required={isFieldRequired('City')}
                              InputLabelProps={{
                                classes: {
                                  asterisk: classes.asterisk,
                                },
                                shrink: true,
                              }}
                            />
                          </Grid>
                        )}
                      </Grid>
                      <Grid container>
                        {isFieldVisible('Nearest Landmark') && (
                          <Grid item xs={6}>
                            <Controls.Input
                              name="nearestLandMark"
                              label="Nearest Landmark"
                              value={address.shipping.nearestLandMark}
                              onChange={(e) =>
                                handleInputChangeAddress(e, index, 'shipping')
                              }
                              id={`txtCustNearestLandmark${index}`}
                              required={isFieldRequired('Nearest Landmark')}
                            />
                          </Grid>
                        )}
                        {isFieldVisible('Area') && (
                          <Grid item xs={6}>
                            <Controls.Select
                              name="areaRef"
                              label="Area"
                              value={address.shipping.areaRef}
                              options={areas.filter(
                                (elem) =>
                                  elem.cityRef[0]._id ===
                                  address.shipping.cityRef
                              )}
                              onChange={(e) =>
                                handleInputChangeAddress(e, index, 'shipping')
                              }
                              id={`ddCustArea${index}`}
                              isNoneAllowed={false}
                              required={isFieldRequired('Area')}
                              InputLabelProps={{
                                classes: {
                                  asterisk: classes.asterisk,
                                },
                                shrink: true,
                              }}
                            />
                          </Grid>
                        )}
                      </Grid>
                      <Grid container>
                        {isFieldVisible('Address Details') && (
                          <Grid item xs={12}>
                            <Controls.Input
                              name="addressDetails"
                              label="Address Details"
                              multiline
                              row={3}
                              value={address.shipping.addressDetails}
                              onChange={(e) => {
                                handleInputChangeAddress(e, index, 'shipping');
                              }}
                              id={`txtCustAddressDetails${index}`}
                              required={isFieldRequired('Address Details')}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Divider orientation="vertical" variant="middle" />
                    {/* Billing Address */}
                    <Grid item xs>
                      <Grid container justify="space-between">
                        <Grid item>
                          <Typography
                            variant="body1"
                            style={{ paddingLeft: 8 }}
                          >
                            Billing Address
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Controls.Checkbox
                            name="sameAsShipping"
                            label="Same as shipping address"
                            value={address.billing.sameAsShipping}
                            onChange={(e) => handleInputChangeSameAs(e, index)}
                            id={`chkCustSameAsShippingAddr${index}`}
                          />
                        </Grid>
                      </Grid>
                      <Grid container>
                        {isFieldVisible('Address Label') && (
                          <Grid item xs={6}>
                            <Controls.Select
                              name={'addressLabel'}
                              label="Address Label"
                              value={address.billing.addressLabel}
                              options={addressLabelArr}
                              onChange={(e) => {
                                handleInputChangeAddress(e, index, 'billing');
                              }}
                              id={`ddCustAddressLabel${index}`}
                              isNoneAllowed={false}
                              disabled={address.billing.sameAsShipping}
                              required={isFieldRequired('Address Label')}
                              InputLabelProps={{
                                classes: {
                                  asterisk: classes.asterisk,
                                },
                              }}
                            />
                          </Grid>
                        )}
                        {isFieldVisible('City') && (
                          <Grid item xs={6}>
                            <Controls.Select
                              name="cityRef"
                              label="City"
                              value={address.billing.cityRef || ''}
                              options={cities}
                              onChange={(e) => {
                                handleInputChangeAddress(e, index, 'billing');
                                setAreaDropdown(
                                  e.target.value,
                                  index,
                                  'billing'
                                );
                              }}
                              id={`ddCustCity${index}`}
                              isNoneAllowed={false}
                              disabled={address.billing.sameAsShipping}
                              required={isFieldRequired('City')}
                              InputLabelProps={{
                                classes: {
                                  asterisk: classes.asterisk,
                                },
                              }}
                            />
                          </Grid>
                        )}
                      </Grid>
                      <Grid container>
                        {isFieldVisible('Nearest Landmark') && (
                          <Grid item xs={6}>
                            <Controls.Input
                              name="nearestLandMark"
                              label="Nearest Landmark"
                              value={address.billing.nearestLandMark}
                              onChange={(e) =>
                                handleInputChangeAddress(e, index, 'billing')
                              }
                              id={`txtCustNearestLandmark${index}`}
                              disabled={address.billing.sameAsShipping}
                              required={isFieldRequired('Nearest Landmark')}
                            />
                          </Grid>
                        )}
                        {isFieldVisible('Area') && (
                          <Grid item xs={6}>
                            <Controls.Select
                              name="areaRef"
                              label="Area"
                              value={address.billing.areaRef || ''}
                              options={areas.filter(
                                (elem) =>
                                  elem.cityRef[0]._id ===
                                  address.billing.cityRef
                              )}
                              onChange={(e) =>
                                handleInputChangeAddress(e, index, 'billing')
                              }
                              id={`ddCustArea${index}`}
                              isNoneAllowed={false}
                              disabled={address.billing.sameAsShipping}
                              required={isFieldRequired('Area')}
                              InputLabelProps={{
                                classes: {
                                  asterisk: classes.asterisk,
                                },
                              }}
                            />
                          </Grid>
                        )}
                      </Grid>
                      <Grid container>
                        {isFieldVisible('Address Details') && (
                          <Grid item xs={12}>
                            <Controls.Input
                              name="addressDetails"
                              label="Address Details"
                              multiline
                              row={3}
                              value={address.billing.addressDetails}
                              onChange={(e) => {
                                handleInputChangeAddress(e, index, 'billing');
                              }}
                              id={`txtCustAddressDetails${index}`}
                              disabled={address.billing.sameAsShipping}
                              required={isFieldRequired('Address Details')}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </>
            )}
            <Grid item xs={1} sm={2} align="left">
              <div>
                <Controls.Button
                  startIcon={<AddIcon />}
                  text="Add Address"
                  id="btnAddAddress"
                  onClick={handleAddClickCustomerAddress}
                />
              </div>
            </Grid>

            {dynamicFields && dynamicFields.length > 0 && (
              <Grid
                container
                style={{ border: '1px solid #C8C8C8', borderRadius: 10 }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ paddingLeft: 8, marginTop: 10, width: '100%' }}
                >
                  Custom Fields
                </Typography>
                {dynamicFields.map((field, fIndex) => (
                  <Grid key={fIndex} item xs={4}>
                    <Controls.Input
                      label={field.label}
                      required={field.isRequired}
                      value={field.value}
                      onChange={(e) => {
                        handleDynamicFieldChange(e, fIndex);
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            )}

            <Grid container className="buttonsCotainerMargin">
              <br></br>
              <Grid item xs={3} sm={4} align="left">
                {!openInPos && (
                  <Controls.Button
                    text="Cancel"
                    color="default"
                    onClick={history.goBack}
                    startIcon={<CloseIcon />}
                    id="btnCustCancel"
                  />
                )}
              </Grid>
              <Grid item xs={6} sm={6}></Grid>
              <Grid item xs={1} sm={2} align="right">
                <div>
                  <Controls.Button
                    type="submit"
                    startIcon={<SaveIcon />}
                    text="Save"
                    id="btnCustSave"
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

function getArea(areasList, cityId) {
  let areaArr = [];
  if (areasList != undefined) {
    for (const innerobj of areasList) {
      if (innerobj.cityRef[0] != null) {
        if (innerobj.cityRef[0]._id === cityId) {
          areaArr.push(innerobj);
        }
      }
    }
  }
  return areaArr;
}

CustomerDetailsComponent.propTypes = {
  pageHeaderTitile: PropTypes.any,
  openInPos: PropTypes.any,
  recordForEdit: PropTypes.any,
  setCustomerResponse: PropTypes.any,
};

export default CustomerDetailsComponent;
