import React, { useState, useEffect } from 'react';
import { Grid, Paper, TextField } from '@material-ui/core';
import Controls from '../../Utilities/Controls/Controls';
import { useForm, Form } from '../../Utilities/ReuseableCommponents/useForm';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import PageTitle from '../../Layout/PageTitle';
import { useLocation, useHistory } from 'react-router-dom';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import * as BranchMappingService from './BranchMappingService';
import useStyles from '../../../Style/AppStylesAdmin';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PropTypes from 'prop-types';

const initialFValues = {
  _id: 0,
  name: '',
  status: '',
  cityRef: '',
  areaInstruction: '',
  branchMapping: [],
  createdAt: '',
  updatedAt: '',
  __v: 0,
};
let Responce = {
  data: null,
  error: null,
  messege: '',
  loading: false,
};

let apiResponce = {
  data: null,
  error: null,
  loading: false,
  statusCode: 0,
};
function BranchMappingDetails() {
  const history = useHistory();
  const classes = useStyles();
  const [areas, setAreas] = useState([]);
  const location = useLocation();
  const [updateFlag, setUpdateFlag] = useState(false);
  const [branches, setBranches] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const { values, setValues, resetForm } = useForm(initialFValues, true);

  const addOrEdit = async (areaa, resetFormMapping) => {
    try {
      if (!updateFlag) {
        Responce.data = null;
        Responce.error = null;
        Responce = await BranchMappingService.updateArea(areaa);
        if (Responce.data) {
          values.branchMapping = values.branchMapping.splice(
            0,
            values.branchMapping.length
          );
          resetFormMapping();
          setNotify({
            isOpen: true,
            message: 'Submitted Successfully',
            type: 'success',
          });
        } else {
          setNotify({
            isOpen: true,
            message: Responce.messege
              ? Responce.messege
              : 'Unable to process request',
            type: 'error',
          });
        }
      }
      if (updateFlag) {
        apiResponce.data = null;
        apiResponce.error = null;
        apiResponce = await BranchMappingService.updateArea(areaa);
        if (apiResponce.data) {
          values.branchMapping = values.branchMapping.splice(
            0,
            values.branchMapping.length
          );
          resetFormMapping();
          setNotify({
            isOpen: true,
            message: 'Branch-Area Mapping Updated Successfully',
            type: 'success',
          });
        }
        if (apiResponce.error) {
          setNotify({
            isOpen: true,
            message: 'Unable To Process Request',
            type: 'error',
          });
        }
      }
    } catch (e) {
      setNotify({
        isOpen: true,
        message: 'Unable To Process Request',
        type: 'error',
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    addOrEdit(values, resetForm);
  };

  useEffect(() => {
    if (location.state.updateFlag != null)
      setUpdateFlag(location.state.updateFlag);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state.updateFlag]);
  useEffect(() => {
    if (location.state.recordForEdit != null)
      setValues({ ...location.state.recordForEdit });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state.recordForEdit]);
  useEffect(() => {
    BranchMappingService.getAllAreasForDropDown().then((Response) => {
      if (Response.data) {
        //Map data array with Generic List Object with Properties id,text
        //Dropdown only accepts list of Object with Properties id,text
        var result = Response.data.data.filter(
          (dataArrayRow) => dataArrayRow.branchMapping.length === 0
        );
        setAreas(result);
        if (Response.data.count === 0) {
          setNotify({
            isOpen: true,
            message: 'No Cashier Found',
            type: 'error',
          });
        }
      } else {
        console.error('Unable To Get Records');
      }
    });

    BranchMappingService.getAllBranchesForDropDown().then((Response) => {
      if (Response.data) {
        //Map data array with Generic List Object with Properties id,text
        //Dropdown only accepts list of Object with Properties id,text
        var result = Response.data.data.map((dataArrayRow) => ({
          _id: dataArrayRow._id,
          name: dataArrayRow.name,
        }));
        setBranches(result);
        if (Response.data.count === 0) {
          setNotify({
            isOpen: true,
            message: 'No Cashier Found',
            type: 'error',
          });
        }
      } else {
        console.error('Unable To Get Records');
      }
    });
  }, []);

  const handleInputArea = (val) => {
    let temp = { ...values };
    temp._id = val._id;
    temp.name = val.name;
    temp.status = val.status;
    temp.startTime = val.startTime;
    temp.endTime = val.endTime;
    temp.cityRef = val.cityRef;
    temp.areaInstruction = val.areaInstruction;
    setValues(temp);
  };
  return (
    <>
      <PageTitle
        title={location.state.pageHeaderTitile}
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="BranchMappingDetailsHeading"
      />
      <Paper
        elevation={6}
        square
        className="paperMargin"
        style={{
          borderRadius: '10px',
        }}
      >
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <h3 style={{ flex: '1 0 100%', marginBottom: 0 }}>{'Area'}</h3>
            <Controls.ControllableStateEditableDropDown
              id="txtSearchArea"
              label="Area"
              variant="outlined"
              data={areas}
              value={{
                _id: values._id,
                name: values.name,
                status: values.status,
              }}
              onChange={(e, newValue) => {
                if (newValue !== null) handleInputArea(newValue);
              }}
              autoComplete="off"
              others={{
                disabled: updateFlag ? true : false,
              }}
              required={true}
            />

            <BranchWiseSetup
              title="Main Branch"
              branches={branches}
              value={values}
              ind={0}
              setValues={setValues}
              required={true}
            />
            <BranchWiseSetup
              title="Alternative Branch 1"
              branches={branches}
              value={values}
              ind={1}
              setValues={setValues}
            />
            <BranchWiseSetup
              title="Alternative Branch 2"
              branches={branches}
              value={values}
              ind={2}
              setValues={setValues}
            />
            <BranchWiseSetup
              title="Alternative Branch 3"
              branches={branches}
              value={values}
              ind={3}
              setValues={setValues}
            />

            <Grid container className={classes.buttonsCotainerMargin}>
              <br></br>
              <Grid item xs={12} sm={6} align="left">
                <Controls.Button
                  text="Cancel"
                  color="default"
                  onClick={history.goBack}
                  startIcon={<CloseIcon />}
                  className="actionBtnMargin"
                  id="btnCancelCat"
                />
              </Grid>
              <Grid item xs={12} sm={6} align="right">
                <div>
                  <Controls.Button
                    type="submit"
                    startIcon={<SaveIcon />}
                    text="Save"
                    className="actionBtnMargin"
                    id="btnSaveCat"
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}

function BranchWiseSetup({ title, branches, value, ind, setValues, required }) {
  const obj =
    value.branchMapping[ind] && value.branchMapping[ind].branchRef
      ? {
          _id: value.branchMapping[ind].branchRef._id,
          name: value.branchMapping[ind].branchRef.name,
        }
      : '';

  const handleChangeDeliveryCharges = (e, index) => {
    let temp = { ...value };
    temp.branchMapping[index] = {
      ...temp.branchMapping[index],
      deliveryCharges: e.target.value,
    };
    setValues(temp);
  };
  const handleChangeDeliveryTime = (e, index) => {
    let temp = { ...value };
    temp.branchMapping[index] = {
      ...temp.branchMapping[index],
      delieveryTime: e.target.value,
    };
    setValues(temp);
  };
  const handleOnDelete = (delIndex) => {
    let temp = { ...value };
    temp.branchMapping.splice(delIndex, 1);

    setValues(temp);
  };

  const handleDropDownBranch = (index, newValue) => {
    let temp = { ...value };
    if (newValue !== null) {
      temp.branchMapping[index] = {
        branchRef: {
          _id: newValue._id,
          name: newValue.name,
        },
        deliveryCharges: value.branchMapping[index]
          ? value.branchMapping[index].deliveryCharges || ''
          : '',
        delieveryTime: value.branchMapping[index]
          ? value.branchMapping[index].delieveryTime || ''
          : '',
      };
    } else {
      temp.branchMapping[index] = {
        deliveryCharges: value.branchMapping[index]
          ? value.branchMapping[index].deliveryCharges || ''
          : '',
        delieveryTime: value.branchMapping[index]
          ? value.branchMapping[index].delieveryTime || ''
          : '',
      };
    }
    setValues(temp);
  };

  return (
    <>
      <h2 style={{ flex: '1 0 100%', marginBottom: 0 }}>{title}</h2>
      <br />
      <Controls.ControllableStateEditableDropDown
        id="textSearchBranches"
        label="Branches"
        data={branches}
        value={obj}
        onChange={(e, newValue) => {
          handleDropDownBranch(ind, newValue);
        }}
        autoComplete="off"
        required={required}
      />

      <TextField
        id="txtFieldDeliveryCharges"
        label="Delivery Charges"
        variant="outlined"
        value={
          value.branchMapping[ind]
            ? value.branchMapping[ind].deliveryCharges
            : ''
        }
        onChange={(e) => handleChangeDeliveryCharges(e, ind)}
        autoComplete="off"
      />

      <TextField
        id="textFieldDeliveryTime"
        label="Delivery Time"
        variant="outlined"
        value={
          value.branchMapping[ind] ? value.branchMapping[ind].delieveryTime : ''
        }
        onChange={(e) => handleChangeDeliveryTime(e, ind)}
        autoComplete="off"
      />
      {value.branchMapping[ind] && (
        <Controls.ActionButton
          color="secondary"
          id={`btnDelCat${ind}`}
          name="btnDelete"
          onClick={() => handleOnDelete(ind)}
        >
          <HighlightOffIcon />
        </Controls.ActionButton>
      )}
    </>
  );
}

BranchWiseSetup.propTypes = {
  title: PropTypes.string.isRequired,
  branches: PropTypes.array.isRequired,
  value: PropTypes.object.isRequired,
  ind: PropTypes.number.isRequired,
  setValues: PropTypes.any,
  handleDropDownBranch: PropTypes.func.isRequired,
  handleChangeDeliveryCharges: PropTypes.func.isRequired,
  handleChangeDeliveryTime: PropTypes.func.isRequired,
  required: PropTypes.bool,
};
export { BranchMappingDetails as default };
