import React from 'react';
import CustomerComponent from '../Customer/CustomerComponents/CustomerComponent';

export default function Customer() {
  return (
    <>
      <CustomerComponent openInPos={false} />
    </>
  );
}
