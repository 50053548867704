export function getModalStyle() {
  const top = 50;
  const left = 50;
  const height = 75;
  const width = 75;
  const overflow = 'auto';
  const borderRadius = 10;
  const borderColor = 'transparent';

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    height: `${height}%`,
    width: `${width}%`,
    overflow: `${overflow}`,
    borderRadius: `${borderRadius}px`,
    borderColor: `${borderColor}`,
  };
}
export function getShiftButtonData() {
  const Val = localStorage.getItem('_IdShift');
  return Val ? true : false;
}
