import React, { useState, useEffect } from 'react';
import Controls from '../../Utilities/Controls/Controls';
import { Form } from '../../Utilities/ReuseableCommponents/useForm';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import PageTitle from '../../Layout/PageTitle';
import * as orderService from '../Order/OrderService';
import * as creditService from '../Credit/CreditService';
import * as customerService from '../Customer/CustomerService';
import APIService from '../../Pages/POS/SalesAPIService';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import ReactDOM from 'react-dom';
import useTable from '../../Utilities/ReuseableCommponents/useTable';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import RembrandsPrint from '../../Pages/POS/PrintActions/print-rembrandssalesInvoice';
import Print from '../../Pages/POS/PrintActions/print';
import {
  Grid,
  Typography,
  Paper,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PrintIcon from '@material-ui/icons/Print';
import { getFormattedDateTime as ISOdateFormatter } from '../../../Common/constants';
import GenericTable from '../../Utilities/ReuseableCommponents/TableReport/GenericTable';

const headCells = [
  { id: 'name', label: 'Item Name' },
  { id: 'categoryName', label: 'Item Category' },
  { id: 'barcode', label: 'Barcode', align: 'center' },
  { id: 'quantitySold', label: 'Qty Sold', align: 'center' },
  { id: 'qtyReturned', label: 'Qty Returned', align: 'center' },
  { id: 'saleFinalPrice', label: 'Final Sale Price', align: 'right' },
  { id: 'discountAmount', label: 'Discount Price', align: 'right' },
];

export default function OrderDetails() {
  const location = useLocation();
  const history = useHistory();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });
  APIService.GetInvoiceTemplateType('sales');
  const [records] = useState(() => {
    if (location.state)
      if (location.state.recordForEdit)
        if (!location.state.recordForEdit.saleDetails.itemsSold)
          return history.goBack();
        else return location.state.recordForEdit;
      else return history.goBack();
    else return history.goBack();
  });
  const [newItemsSold, setNewItemsSold] = useState([]);
  const [status, setStatus] = useState(records.History[0].state);
  const [feedBack, setFeedBack] = useState(records.feedBack);

  useEffect(() => {
    function fnnewItemSold(particularSale) {
      var newItemsSold = particularSale.saleDetails.itemsSold.map(
        (particularItemSold) => {
          let particularItemSold_Id = particularItemSold.itemRef._id;
          var matchedReturnedItem = [];
          if (
            particularSale.saleDetails.itemsReturned &&
            particularSale.saleDetails.itemsReturned.length > 0
          ) {
            matchedReturnedItem = particularSale.itemsReturned.filter(
              (particularItemReturned) => {
                return (
                  particularItemReturned.itemRef._id.toString() ==
                  particularItemSold_Id.toString()
                );
              }
            );
          }
          var itemSaleInfo = {
            ...particularItemSold,
            qtyReturned:
              matchedReturnedItem.length > 0
                ? matchedReturnedItem[0].quantity
                : 0,
          };
          return itemSaleInfo;
        }
      );

      return newItemsSold;
    }

    if (records.saleDetails.itemsSold.length > 0) {
      var newItemsSold = fnnewItemSold(records);
      setNewItemsSold(newItemsSold);
    }
  }, [records]);

  const [filterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(newItemsSold, headCells, filterFn);

  async function SaleAnOrder(order) {
    return await APIService.InvoiceSyncRequest(order).then((response) => {
      return response;
    });
  }
  function InvoiceSync(updatedOrder) {
    let itemList = [];
    records.saleDetails.itemsSold.forEach((item) => {
      let newItem = {
        _id: item.itemRef._id,
        salePrice: item.itemRef.salePrice,
        Quantity: item.quantity,
        Tempdiscount: item.discountAmount,
        unitSaleFinalPrice: item.unitSaleFinalPrice,
        saleFinalPrice: item.saleAmountTotal,
      };
      itemList.push(newItem);
    });
    const order = {
      saleType: records.saleDetails.saleType,
      saleMode: records.saleDetails.saleMode,
      shiftNumber: records.saleDetails.shiftNumber,
      invoiceNumber: records.saleDetails.invoiceNumber,
      itemsSold: itemList,
      clientCreatedDate: updatedOrder.createdAt,
      clientModifiedDate: updatedOrder.updatedAt,
      overallSaleDiscountAmount: records.saleDetails.overallSaleDiscountAmount,
      saleAmountSumOfItems: records.saleDetails.saleAmountSumOfItems,
      saleAmountTotal: records.saleDetails.saleAmountTotal,
      customerRef: records.customerRef,
    };
    try {
      let promise = SaleAnOrder(order);
      promise.then((response) => {
        if (response.data) {
          getCustomerCurrentBalance(response.data.data);
        }
      });
    } catch (e) {
      //do nothing just exit from loop
    }
  }

  async function getCustomerCurrentBalance(saleDetails) {
    await creditService
      .getCustomerBalance(saleDetails.customerRef)
      .then((response) => {
        if (response.data && response.data.data.length) {
          creditSale(saleDetails, response.data.data[0]);
        } else {
          customerService
            .getCustomerById(saleDetails.customerRef)
            .then((response) => {
              if (response.data.data) {
                creditSale(saleDetails, {
                  currentBalance: response.data.data.openingAmount,
                });
              }
            });
        }
      });
  }

  async function updateStatus() {
    if (status === 'completed' && !feedBack) {
      setNotify({
        isOpen: true,
        message: 'Feedback is required.',
        type: 'warning',
      });
      return;
    }
    const payLoad = {
      state: status,
      paymentMode: records.paymentMode,
      orderMode: records.orderMode,
      orderId: records._id,
      customerRef: records.customerRef._id,
      saleDetails: records.saleDetails,
      feedBack: status === 'completed' ? feedBack : undefined,
    };
    await orderService.createOrder(payLoad).then((response) => {
      if (response.data) {
        if (response.data.data.state === 'dispatched') {
          InvoiceSync(response.data.data);
        }
        localStorage.removeItem('bookOrder');
        setNotify({
          isOpen: true,
          message: 'Order status changed successfully',
          type: 'success',
        });
      }
    });
  }
  function getInvoiceNumber() {
    let newDate = new Date();
    return (
      '/0001/0020/' +
      newDate.getFullYear() +
      '' +
      (newDate.getMonth() + 1) +
      '' +
      newDate.getDate() +
      '' +
      newDate.getHours() +
      '' +
      newDate.getMinutes() +
      '' +
      newDate.getSeconds()
    );
  }

  function printInvoice() {
    let date = getInvoiceNumber();
    let finalItems = [];
    const shiftData = JSON.parse(localStorage.getItem('_IdShift'));
    records.saleDetails.itemsSold.forEach((item) => {
      let newItem = {
        _id: item.itemRef._id,
        name: item.itemRef.name,
        Tempdiscount: item.discountAmount,
        saleFinalPrice: item.itemRef.saleFinalPrice,
        Quantity: item.quantity,
      };
      finalItems.push(newItem);
    });
    const Order = {
      invoiceNumber: `${date}`,
      saleType: records.orderMode,
      saleMode: 'creditcard',
      shiftNumber: shiftData ? shiftData.shiftNumber : 'N/A',
      saleAmountSumOfItems: parseFloat(
        records.saleDetails.saleAmountSumOfItems
      ).toFixed(2),
      discount: parseFloat(
        records.saleDetails.overallSaleDiscountAmount
      ).toFixed(2),
      overallSaleDiscountAmount: parseFloat(
        records.saleDetails.overallSaleDiscountAmount
      ).toFixed(2),
      saleAmountTotal: parseFloat(records.saleDetails.saleAmountTotal).toFixed(
        2
      ),
      TotalQuantity: records.saleDetails.itemsSold.length,
      itemsSold: finalItems,
      totalTaxAmount: records.saleDetails.overallSaleTaxAmount,
      customerRef: records.customerRef ? records.customerRef._id : null,
      customerInfo: records.customerRef,
    };
    sessionStorage.removeItem('Invoice');
    window.sessionStorage.setItem('Invoice', JSON.stringify(Order));
    const TemplateType = localStorage.getItem('TemplateType').toString();
    if (TemplateType === '1') {
      ReactDOM.render(
        <RembrandsPrint />,
        document.getElementById('print-mount')
      );
    } else ReactDOM.render(<Print />, document.getElementById('print-mount'));

    document.getElementById('print-button').click();
    ReactDOM.unmountComponentAtNode(document.getElementById('print-mount'));
  }

  async function creditSale(saleData, customerBalance) {
    const payLoad = {
      customerRef: saleData.customerRef,
      debit: saleData.saleAmountTotal,
      saleRef: saleData._id,
      currentBalance:
        parseFloat(customerBalance.currentBalance) - saleData.saleAmountTotal,
      comment: '',
    };
    await creditService.insertCredit(payLoad).then((response) => {
      if (response.data) {
        return response;
      }
    });
  }

  return (
    <>
      <div id="print-mount"></div>
      <PageTitle
        title={location.state.pageHeaderTitile}
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="orderDetailsHeading"
      />
      <Paper elevation={6} square className="paperMargin">
        <Form>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <Controls.Input
                disabled
                name="_id"
                label="Order ID"
                value={records._id}
                InputProps={{
                  readOnly: true,
                  disabled: true,
                }}
                id="txtOrderDetailsOrderId"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controls.Input
                disabled
                name="clientUpdatedAt"
                label="Date & Time"
                value={ISOdateFormatter(records.History[0].createdAt)}
                InputProps={{
                  readOnly: true,
                  disabled: true,
                }}
                id="txtOrderDetailsDateTime"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controls.Input
                disabled
                name="orderMode"
                label="Order Mode"
                value={records.orderMode}
                InputProps={{
                  readOnly: true,
                  disabled: true,
                }}
                id="txtOrderDetailsOrderMode"
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Controls.Input
                disabled
                name="customerName"
                label="Customer Name"
                value={records.customerRef.name}
                InputProps={{
                  readOnly: true,
                  disabled: true,
                }}
                id="txtOrderDetailsCustomerName"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controls.Input
                disabled
                name="paymentMode"
                label="Payment Mode"
                value={records.paymentMode}
                InputProps={{
                  readOnly: true,
                  disabled: true,
                }}
                id="txtOrderDetailsPaymentMode"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controls.Select
                name="status"
                label="status"
                options={
                  records.History[0].state === 'dispatched'
                    ? orderService
                        .orderStatuses()
                        .filter((status) => status._id !== 'booked')
                    : orderService.orderStatuses()
                }
                value={status}
                id="ddOrderDetailsStatus"
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Controls.Input
                disabled
                name="createdBy"
                label="Created By"
                value={records.History[0].createdBy.name}
                InputProps={{
                  readOnly: true,
                  disabled: true,
                }}
                id="txtOrderDetailsUsername"
              />
            </Grid>
            {status === 'completed' ? (
              <Grid item xs={12} sm={8}>
                <Controls.Input
                  name="feedback"
                  label="Feedback"
                  value={feedBack}
                  onChange={(e) => {
                    setFeedBack(e.target.value);
                  }}
                  id="txtOrderDetailsFeedback"
                />
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Form>
      </Paper>

      <Paper elevation={6} square className="paperMargin">
        <Typography variant="body2" className="headLabel">
          Items Ordered
        </Typography>
        <TblContainer>
          <TblHead />
          <TableBody id="orderDetailsListTbody">
            {recordsAfterPagingAndSorting().map((item, index) => (
              <TableRow key={item._id} id={`salesDetailsListTrow${index}`}>
                <TableCell>{item.itemRef && item.itemRef.name}</TableCell>
                <TableCell>
                  {(item.itemRef &&
                    item.itemRef.categoriesRef &&
                    item.itemRef.categoriesRef[0].name) ||
                    ''}
                </TableCell>
                <TableCell align="center">
                  {item.itemRef && item.itemRef.barcode}
                </TableCell>
                <TableCell align="center">
                  {item.quantity && item.quantity.toLocaleString('en')}
                </TableCell>
                <TableCell align="center">
                  {item.qtyReturned ? item.qtyReturned.toLocaleString('en') : 0}
                </TableCell>
                <TableCell className="cellRightAlign">
                  Rs.{' '}
                  {item.saleFinalPrice
                    ? item.saleFinalPrice.toLocaleString('en')
                    : 0}
                </TableCell>
                <TableCell className="cellRightAlign">
                  Rs.{' '}
                  {item.discountAmount
                    ? item.discountAmount.toLocaleString('en')
                    : 0}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
        <div container className="actionButtonDiv">
          <div>
            <Controls.Button
              text="Back"
              color="default"
              onClick={history.goBack}
              id="btnBackOrderDetails"
              startIcon={<ArrowBackIcon />}
            />
          </div>
          <div>
            {records.History[0].state !== 'completed' ? (
              <Controls.Button
                text="Save"
                color="primary"
                onClick={updateStatus}
                id="btnSaveOrderDetails"
                startIcon={<SaveIcon />}
              />
            ) : (
              <></>
            )}
            <Controls.Button
              text="Print Invoice"
              color="primary"
              onClick={printInvoice}
              id="btnPrintInvoice"
              startIcon={<PrintIcon />}
            />
          </div>
        </div>
      </Paper>
      <Paper elevation={6} square className="paperMargin">
        <Typography variant="body2" className="headLabel">
          Order History
        </Typography>
        <GenericTable
          data={records.History}
          TableId={'orderDetailsHistory'}
          TableTitle={'Order History'}
          cellData={[
            { name: 'state', type: 'string' },
            { name: 'createdAt', type: 'date' },
            { name: 'createdBy.name', type: 'string' },
          ]}
          headCells={[
            { id: 'state', label: 'State', type: 'string' },
            { id: 'Date & Time', label: 'Date & Time', type: 'date' },
            {
              id: 'ChangedBy',
              label: 'Changed By',
              type: 'string',
            },
          ]}
          recordsCount={records.History.length || 0}
          getRecordsFn={() => records.History}
        />
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
