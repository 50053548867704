import React, { useState, useEffect } from 'react';
import { Grid, Typography, Divider, TextField } from '@material-ui/core';
import CircularLoader from '../../../Utilities/ReuseableCommponents/CircularLoader';
import Notification from '../../../Utilities/ReuseableCommponents/Notification';
import * as SetupService from '../SetupService';
import Controls from '../../../Utilities/Controls/Controls';
import SaveIcon from '@material-ui/icons/Save';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Link from '@material-ui/core/Link';

let apiResponseDto = {
  data: [],
  error: null,
  loading: false,
  statusCode: 0,
};

export default function CancelOrderReasonTab() {
  const [valueReasonText, setValueReasonText] = useState([]);
  const [setUpDefaultData, setsetUpDefaultData] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  function handleActivationReasonText() {
    CancelOrderReasonTextActivation().then((Response) => {
      return Response;
    });
  }

  async function CancelOrderReasonTextActivation() {
    var payLoad = {
      name: 'CancelOrderReason',
      data: [
        {
          reason: '',
          status: 'active',
        },
      ],
    };
    var Response = await SetupService.insertSetup(payLoad).then(
      (ResponseInsert) => {
        return ResponseInsert;
      }
    );
    setValueReasonText([Response.data.data]);
    return Response;
  }
  function changeTFCancelReason(value, index) {
    let newdata = { ...valueReasonText };
    newdata[0].data[index].reason = value;
    newdata[0].data[index].status = 'active';

    setValueReasonText([newdata[0]]);
  }

  useEffect(() => {
    setIsLoaded(true);
    configureCancelOrderReasonTab();
  }, [setUpDefaultData]);

  function configureCancelOrderReasonTab() {
    let data = (async () => {
      apiResponseDto = await SetupService.getAllSetups().then((Response) => {
        setIsLoaded(false);
        return Response;
      });
      return apiResponseDto;
    })();
    data.then((Response) => {
      if (Response.data.count === 0) {
        setsetUpDefaultData(true);
        setNotify({
          isOpen: true,
          message: 'No Data Found',
          type: 'error',
        });
      } else {
        let CancelOrderReason = Response.data.data
          .filter((e) => e.name === 'CancelOrderReason')
          .map((e) => e);
        setValueReasonText(CancelOrderReason);
      }
    });
  }
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  function RemoveReasonTextField(index) {
    let newdata = { ...valueReasonText };
    newdata[0].data.splice(index, 1);
    setValueReasonText([newdata[0]]);
  }

  function payloadReady(payload) {
    return {
      name: payload.name,
      data: payload.data,
    };
  }
  async function UpdateSetup(obj) {
    return SetupService.updateSetup(payloadReady(obj[0]), obj[0]._id).then(
      (res) => {
        return res;
      }
    );
  }

  function handleSave() {
    UpdateSetup(valueReasonText).then((Response) => {
      if (Response.data) {
        displayNotify('Data successfully updated', 'success');
      } else {
        displayNotify('Error while updating data', 'error');
      }
      return Response;
    });
  }

  function displayNotify(msg, displayType) {
    setNotify({
      isOpen: true,
      message: msg,
      type: displayType,
    });
  }

  function AddReasonTextField() {
    const tempReasonData = {
      reason: '',
      status: 'active',
    };
    let newdata = { ...valueReasonText };
    newdata[0].data.push(tempReasonData);
    setValueReasonText([newdata[0]]);
  }

  let ReasonHtml;

  if (valueReasonText.length > 0) {
    ReasonHtml = valueReasonText.map((item) => (
      <>
        <Grid item xs={2}>
          <Typography variant="subtitle2" gutterBottom>
            {item.name}
          </Typography>
        </Grid>
        <Grid item xs={10}>
          {item.data.map((subItem, index) => (
            <>
              <TextField
                id={'tf-area' + index}
                key={'tf-area' + index}
                value={subItem.reason}
                variant="outlined"
                style={{
                  margin: '5px',
                  width: '90%',
                }}
                onChange={(e) => changeTFCancelReason(e.target.value, index)}
              />
              <IconButton
                aria-label="delete"
                color="secondary"
                onClick={() => RemoveReasonTextField(index)}
              >
                <DeleteIcon />
              </IconButton>
              <br />
            </>
          ))}

          <Link
            component="button"
            variant="body2"
            onClick={() => {
              AddReasonTextField();
            }}
            style={{
              margin: '5px',
            }}
          >
            + Add more
          </Link>
        </Grid>

        <Grid item xs={12}>
          <Controls.Button
            text="Save Changes"
            variant="contained"
            color="primary"
            id="btnProdSearch"
            startIcon={<SaveIcon />}
            onClick={handleSave}
          />
        </Grid>
      </>
    ));
  } else {
    ReasonHtml = (
      <>
        <React.Fragment key={'initialReason'}>
          <Grid item xs={2}>
            <Typography
              variant="subtitle2"
              gutterBottom
              key={'initialReasonTypo'}
            >
              {'Order Cancel Reason'}
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Controls.Button
              text="Activate Order Cancel Reason"
              variant="contained"
              color="primary"
              id="btnactivatereason"
              onClick={handleActivationReasonText}
            />
          </Grid>
        </React.Fragment>
        <Notification notify={notify} setNotify={setNotify} />
      </>
    );
  }

  return (
    <>
      <CircularLoader isload={isLoaded} />
      <React.Fragment key={'setting'}>
        <Grid container>
          {ReasonHtml}
          <Grid item xs={12}>
            <Divider variant="middle" />
          </Grid>
        </Grid>
      </React.Fragment>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
