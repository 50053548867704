import React from 'react';
import './App.module.css';
import POS from './POS';
import Store from '../../../Actions/Store';
export default function App() {
  return (
    <div className="App">
      <Store>
        <POS />
      </Store>
    </div>
  );
}
