import React from 'react';
import {
  InputAdornment,
  TextField,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Paper,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import clsx from 'clsx';
import PropTypes from 'prop-types';
const CustomerModalBody = ({
  classes,
  modalStyle,
  keyPressDownForSearchCustomer,
  handleCustomerAppend,
  customerList,
  customerTableRef,
}) => {
  return (
    <>
      <div style={modalStyle} className={classes.paper4}>
        <h2 id="simple-modal-title">Search Customer</h2>
        <TextField
          label="Contact Number"
          margin="normal"
          onKeyDown={keyPressDownForSearchCustomer}
          id="txtPOSCustomerSearch"
          className={clsx(classes.margin, classes.textField)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          autoComplete="off"
          autoFocus
        />
        <TableContainer
          component={Paper}
          style={{
            height: '400px',
            overflow: 'auto',
          }}
        >
          <Table
            id="posAddCustomerTable"
            className={classes.table}
            aria-label="a dense table"
            innerRef={customerTableRef}
          >
            <TableHead>
              <TableRow>
                <TableCell>Customer ID</TableCell>
                <TableCell align="center">Customer Name</TableCell>
                <TableCell align="center">Contact Number</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody id="posSalesReturnTbody">
              {customerList.map((row, index) => (
                <TableRow
                  id={'posSalesReturnTrow' + index}
                  key={row.customerId + index}
                  tabIndex={index}
                  className={classes.posCustTableRow}
                >
                  <TableCell component="th" scope="row">
                    {row.customerId}
                  </TableCell>
                  <TableCell align="center">{row.name}</TableCell>
                  <TableCell align="center">{row.contactNumber}</TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      onClick={() => handleCustomerAppend(row)}
                      id={'btnPOSAddCustomer' + index}
                      color="primary"
                    >
                      Add
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};
CustomerModalBody.propTypes = {
  classes: PropTypes.any,
  modalStyle: PropTypes.any,
  keyPressDownForSearchCustomer: PropTypes.any,
  handleCustomerAppend: PropTypes.any,
  customerList: PropTypes.any,
  customerTableRef: PropTypes.any,
};
export default CustomerModalBody;
