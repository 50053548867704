import authHeader from '../../../Services/auth-header';
import { callAPI } from '../../../Services/ApiCaller';
import { API_VERSION } from '../../../Common/constants';

export async function getAllArea(
  page,
  rowsPerPage,
  sortDirection,
  sortColumnName,
  searchString
) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  let rowsFrom = page * rowsPerPage;

  let sortOrderdir = sortDirection === 'asc' ? 1 : -1;
  return callAPI(
    `api/${API_VERSION}/areas`,
    null,
    headerObject,
    'GET',
    `?populate=branchRef,cityRef&branchMapping=true&$orderBy=${sortColumnName}&$orderDir=${sortOrderdir}&$skip=${rowsFrom}&$top=${rowsPerPage}${searchString}`
  ).then((Responce) => {
    return Responce;
  });
}

export async function getAllBranchesForDropDown(searchString) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  return callAPI(
    `api/${API_VERSION}/branches`,
    null,
    headerObject,
    'GET',
    `?$orderBy=name&$orderDir=1&$top=50000&${searchString}`
  ).then((Responce) => {
    return Responce;
  });
}

export async function getAllAreasForDropDown() {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  return callAPI(
    `api/${API_VERSION}/areas`,
    null,
    headerObject,
    'GET',
    `?$orderBy=name&$orderDir=1&$top=50000&`
  ).then((Responce) => {
    return Responce;
  });
}
export async function getAllBranches() {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  return callAPI(
    `api/${API_VERSION}/branches`,
    null,
    headerObject,
    'GET',
    `?$orderBy=name&$orderDir=1&$top=50000`
  );
}

export async function deleteCity(_id) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  return callAPI(
    `api/${API_VERSION}/cities/`,
    null,
    headerObject,
    'DELETE',
    _id
  ).then((Responce) => {
    return Responce;
  });
}

export async function updateArea(area) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  return callAPI(
    `api/${API_VERSION}/areas`,
    area,
    headerObject,
    'PUT',
    `/${area._id}`
  ).then((Responce) => {
    return Responce;
  });
}

export async function patchCity(id, CityObject) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  return callAPI(
    `api/${API_VERSION}/cities`,
    CityObject,
    headerObject,
    'PATCH',
    `/${id}`
  ).then((Responce) => {
    return Responce;
  });
}
