import React from 'react';
import './end-shift-report.module.css';
import { getFormattedDateTime } from '../../../../Common/constants';

class ComponentToPrint extends React.Component {
  render() {
    const companyName = localStorage.getItem('CompanyName');
    const cashierName = localStorage.getItem('username');
    const currentDate = getFormattedDateTime(new Date().toISOString());
    const data = JSON.parse(localStorage.getItem('ShiftReport'));

    return (
      <div>
        <h1 className="reportCompanyName">{companyName}</h1>
        <table>
          <tbody>
            <tr>
              <td className="generalReportPadding">Cashier: {cashierName}</td>
            </tr>
            <tr>
              <td className="generalReportPadding">Date: {currentDate}</td>
            </tr>
          </tbody>
        </table>
        <p className="posHeadingParagraph">END SHIFT CASH MANAGEMENT</p>
        <table>
          <tbody>
            <tr className="reportTableRow" key={'ssd'}>
              <td className="generalReportPadding">Shift Start Date</td>
              <td className="reportTableRowRight">{data.startDate}</td>
            </tr>
            <tr className="reportTableRow" key={'sed'}>
              <td className="generalReportPadding">Shift End Date</td>
              <td className="reportTableRowRight">{currentDate}</td>
            </tr>
            <tr className="reportTableRow" key={'ea'}>
              <td className="generalReportPadding">Expected Amount</td>
              <td className="reportTableRowRight">Rs. {data.expectedAmount}</td>
            </tr>
            <tr className="reportTableRow" key={'ca'}>
              <td className="generalReportPadding">Closing Amount</td>
              <td className="reportTableRowRight">Rs. {data.closingAmount}</td>
            </tr>
            <tr className="reportTableRow" key={'iss'}>
              <td className="generalReportPadding">Internal Sales</td>
              <td className="reportTableRowRight">Rs. {data.internalSale}</td>
            </tr>
            <tr className="reportTableRow" key={'diff'}>
              <td className="generalReportPadding">Difference</td>
              <td className="reportTableRowRight">Rs. {data.difference}</td>
            </tr>
          </tbody>
        </table>
        <p className="generalReportPadding">Comments: {data.comments}</p>
        <br />
        <br />
        <p className="reportFooterParagraph">
          Software provided by: ConcavePOS
        </p>
      </div>
    );
  }
}

export default ComponentToPrint;
