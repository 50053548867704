import React from 'react';
import { Button, Box, Badge } from '@material-ui/core';
import PropTypes from 'prop-types';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import ReorderIcon from '@material-ui/icons/Reorder';
import ReceiptIcon from '@material-ui/icons/Receipt';
const Footer = ({
  classes,
  totalItems,
  handleOpen3,
  shiftButton,
  holdOrderCount,
  openSpecialNoteModal,
  riderName,
  orderMode,
  openRiderModal,
  PrePaymentBill,
  kotList,
  isOdms,
  handleOpenQueuedOrders,
  qOrderCount,
}) => {
  return (
    <>
      <Box align="center" className={classes.actionBtns}>
        <Button
          onClick={handleOpenQueuedOrders}
          variant="contained"
          color="primary"
          aria-label="contained primary button group"
          style={{ marginLeft: '5px', marginTop: '10px' }}
          id="btnFoodQueueOrders"
          disabled={shiftButton || isOdms ? false : true}
        >
          {qOrderCount === 0 ? (
            <ReorderIcon />
          ) : (
            <Badge
              color="error"
              style={{ left: 75 }}
              badgeContent={qOrderCount}
            />
          )}
          &nbsp;&nbsp;&nbsp;QUEUE&nbsp;&nbsp;&nbsp;&nbsp;
        </Button>

        {ShowRunningOrdersButton(
          isOdms,
          handleOpen3,
          shiftButton,
          holdOrderCount
        )}
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            openSpecialNoteModal();
          }}
          aria-label="contained primary button group"
          style={{ marginLeft: '5px', marginTop: '10px' }}
          id="btnFoodSpecialNote"
          disabled={totalItems == 0 ? true : false}
        >
          <FormatQuoteIcon />
          &nbsp;D. INSTRUCTIONS
        </Button>
        {ShowPrePaymentButton(
          isOdms,
          riderName,
          orderMode,
          openRiderModal,
          PrePaymentBill,
          shiftButton,
          kotList
        )}
      </Box>
    </>
  );
};
Footer.propTypes = {
  classes: PropTypes.any,
  totalItems: PropTypes.any,
  handleOpen3: PropTypes.any,
  shiftButton: PropTypes.any,
  holdOrderCount: PropTypes.any,
  openSpecialNoteModal: PropTypes.any,
  riderName: PropTypes.any,
  orderMode: PropTypes.any,
  openRiderModal: PropTypes.any,
  PrePaymentBill: PropTypes.any,
  kotList: PropTypes.any,
  isOdms: PropTypes.any,
  handleOpenQueuedOrders: PropTypes.any,
  qOrderCount: PropTypes.any,
};
function ShowPrePaymentButton(
  isOdms,
  riderName,
  orderMode,
  openRiderModal,
  PrePaymentBill,
  shiftButton,
  kotList
) {
  if (!isOdms) {
    return (
      <Button
        onClick={() => {
          if (riderName === undefined && +orderMode === 2) {
            openRiderModal();
          } else {
            PrePaymentBill();
          }
        }}
        color="primary"
        variant="contained"
        aria-label="contained primary button group"
        id="btnFoodPrePaymentBill"
        disabled={
          shiftButton
            ? kotList.filter((item) => !item.isKot).length > 0 ||
              kotList.length <= 0
            : true
        }
        style={{ marginLeft: '5px', marginTop: '10px' }}
      >
        <ReceiptIcon />
        &nbsp;&nbsp;P.BILL
      </Button>
    );
  }
}

function ShowRunningOrdersButton(
  isOdms,
  handleOpen3,
  shiftButton,
  holdOrderCount
) {
  if (!isOdms) {
    return (
      <Button
        onClick={handleOpen3}
        variant="contained"
        color="primary"
        aria-label="contained primary button group"
        style={{ marginLeft: '5px', marginTop: '10px' }}
        id="btnFoodRunningOrders"
        disabled={shiftButton || isOdms ? false : true}
      >
        {holdOrderCount === 0 ? (
          <ReorderIcon />
        ) : (
          <Badge
            color="error"
            style={{ left: 75 }}
            badgeContent={holdOrderCount}
          />
        )}
        ORDERS&nbsp;&nbsp;&nbsp;&nbsp;
      </Button>
    );
  }
}

export default Footer;
