import { callAPI } from '../../../Services/ApiCaller';
import { API_VERSION } from '../../../Common/constants';
import {
  getAPIrequestHeadersBasic,
  getPaginationSortingValues,
} from '../../../Common/APICallingServiceConstants';
const API_ENDPOINT = `api/${API_VERSION}/riders`;

export async function getAllRider(
  page,
  rowsPerPage,
  sortDirection,
  sortColumnName,
  searchString
) {
  const headerObject = getAPIrequestHeadersBasic();

  let { sortOrderdir, rowsFrom } = getPaginationSortingValues(
    page,
    rowsPerPage,
    sortDirection
  );

  return callAPI(
    `${API_ENDPOINT}`,
    null,
    headerObject,
    'GET',
    `?$orderBy=${sortColumnName}&$orderDir=${sortOrderdir}&$skip=${rowsFrom}&$top=${rowsPerPage}${searchString}`
  ).then((response) => {
    return response;
  });
}

export async function insertRider(rider) {
  const headerObject = getAPIrequestHeadersBasic();

  let riderForPost = {
    ...rider,
    status: rider.status ? 'active' : 'disabled',
  };

  delete riderForPost._id;

  return callAPI(
    `${API_ENDPOINT}`,
    riderForPost,
    headerObject,
    'POST',
    ''
  ).then((response) => {
    return response;
  });
}

export async function deleteRider(_id) {
  const headerObject = getAPIrequestHeadersBasic();

  return callAPI(
    `${API_ENDPOINT}`,
    null,
    headerObject,
    'DELETE',
    `/${_id}`
  ).then((response) => {
    return response;
  });
}

export async function updateRider(rider) {
  const headerObject = getAPIrequestHeadersBasic();

  var riderUpdate = {
    ...rider,
    status: rider.status ? 'active' : 'disabled',
  };

  return callAPI(
    `${API_ENDPOINT}`,
    riderUpdate,
    headerObject,
    'PUT',
    `/${rider._id}`
  ).then((response) => {
    return response;
  });
}

export async function patchRider(id, riderObject) {
  const headerObject = getAPIrequestHeadersBasic();

  return callAPI(
    `${API_ENDPOINT}`,
    riderObject,
    headerObject,
    'PATCH',
    `/${id}`
  ).then((response) => {
    return response;
  });
}
