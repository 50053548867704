import { Paper, Toolbar, TextField, Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import React, { useEffect, useState } from 'react';
import PageTitle from '../../Layout/PageTitle';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import * as productService from '../Products/ProductService';
import * as categoryService from '../Category/CategoryService';
import Controls from '../../Utilities/Controls/Controls';
import { useHistory } from 'react-router-dom';
import PublishIcon from '@material-ui/icons/Publish';
import Modal from '@material-ui/core/Modal';
import LinearProgress from '@material-ui/core/LinearProgress';
import CSVTable from '@material-ui/core/Table';
import CSVTableBody from '@material-ui/core/TableBody';
import CSVTableCell from '@material-ui/core/TableCell';
import CSVTableContainer from '@material-ui/core/TableContainer';
import CSVTableHead from '@material-ui/core/TableHead';
import CSVTableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import CircularLoader from '../../Utilities/ReuseableCommponents/CircularLoader';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CSVExportIcon from '../../../Assets/Images/exportcsv.png';
import sampleFile from '../../../Assets/Files/FileTest.csv';
import Typography from '@material-ui/core/Typography';
import useStyles from '../../../Style/AppStylesAdmin';
import GenericTable from '../../Utilities/ReuseableCommponents/TableReport/GenericTable';
import activityMonitoring from '../../../Common/functions';

require('./customStyles.css');

const headCells = [
  { id: 'name', label: 'Product Name', align: 'left' },
  { id: 'category', label: 'Category', align: 'left' },
  { id: 'barcode', label: 'Barcode', type: 'float' },
  { id: 'sku', label: 'SKU' },
  { id: 'discountPercentage', label: 'Discount', type: 'percentage' },
  {
    id: 'saleFinalPrice',
    label: 'Final Sale Price',
    type: 'float',
  },
  { id: 'createdAt', label: 'Created On', type: 'date' },
  { id: 'status', label: 'Status', disableSorting: true, align: 'right' },
  { id: 'actions', label: 'Actions', disableSorting: true, align: 'center' },
];

let apiResponseDto = {
  data: null,
  error: null,
  loading: true,
  statusCode: 0,
};

export default function Products() {
  const [isLoading, setIsLoading] = React.useState(false);
  const classes = useStyles();
  const [records, setRecords] = useState([]);
  const [recordForEdit] = useState(null);
  const [recordsCount, setRecordsCount] = useState(0);
  const [categories, setCategories] = useState([]);
  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  const [barcode, setBarcode] = useState('');
  const [sku, setSku] = useState('');
  const history = useHistory();
  const rowOnClick = {
    path: '/ProductDetails',
    header: 'View/Edit Product',
    property: ['recordForEdit'],
  };

  /**
   * @abstract Main Function for getting data for the table.
   * @param {number} page - Skip Records
   * @param {number} rowsPerPage - No of records to show
   * @param {number} sortDirection - 1 and -a as Asc and Desc
   * @param {String} sortColumnName - Column Name On Which Need To Sort The Data
   * @returns {Array} Array of Objects caontains details of products
   */
  function getRecords(page, rowsPerPage, sortDirection, sortColumnName) {
    let searchString = '';
    let data = (async () => {
      setIsLoading(true);
      if (name) {
        searchString += `&name=${name}`;
      }
      if (category && category !== '#') {
        searchString += `&categoriesRef=${category._id}`;
      }
      if (barcode) {
        searchString += `&barcode=${barcode}`;
      }
      if (sku) {
        searchString += `&sku=${sku}`;
      }
      apiResponseDto = await productService
        .getAllProducts(
          page,
          rowsPerPage,
          sortDirection,
          sortColumnName,
          searchString
        )
        .then((Response) => {
          return Response;
        });
      return apiResponseDto;
    })();

    data.then((Response) => {
      setIsLoading(false);
      if (Response.data) {
        if (Response.data.data) {
          // filter data - Must have categoriesRef
          var filteredData = Response.data.data.filter(function (e) {
            if (e.categoriesRef) {
              return e.categoriesRef.length > 0;
            }
            return null;
          });

          //Get First Available Category Name from categoriesRef Array
          var result = filteredData.map((productArrayRow) => {
            let { _id, name } = productArrayRow.categoriesRef[0];

            let discountLimit = productArrayRow.minDiscountPercentage
              ? productArrayRow.minDiscountPercentage ==
                productArrayRow.maxDiscountPercentage
                ? productArrayRow.minDiscountPercentage == '0'
                  ? '0.00 %'
                  : parseFloat(productArrayRow.minDiscountPercentage).toFixed(
                      2
                    ) + ' %'
                : parseFloat(productArrayRow.minDiscountPercentage).toFixed(2) +
                  '% - ' +
                  parseFloat(productArrayRow.maxDiscountPercentage).toFixed(2) +
                  '%'
              : '0.00 %';

            return {
              _id: productArrayRow._id,
              categoriesRef_id: _id,
              categoriesRef_name: name,
              status: productArrayRow.status,
              name: productArrayRow.name,
              purchasePrice: productArrayRow.purchasePrice,
              salePrice: productArrayRow.salePrice,
              taxPercentage: productArrayRow.taxPercentage,
              saleFinalPrice: productArrayRow.saleFinalPrice,
              conversionUnit: productArrayRow.conversionUnit,
              unitOfPurchase: productArrayRow.unitOfPurchase,
              unitOfSale: productArrayRow.unitOfSale,
              barcode: productArrayRow.barcode,
              createdAt: productArrayRow.updatedAt,
              discountLimit: discountLimit,
              minDiscountPercentage: productArrayRow.minDiscountPercentage,
              maxDiscountPercentage: productArrayRow.maxDiscountPercentage,
              description: productArrayRow.description,
              image: productArrayRow.image,
              sku: productArrayRow.sku,
              isSaleable:
                'isSaleable' in productArrayRow
                  ? productArrayRow.isSaleable
                  : true,
              isRecipeItem: productArrayRow.isRecipeItem,
            };
          });
          setRecords(result);
          setRecordsCount(Response.data.count);
        }
      } else console.error('Error While Fetching Data: ', Response.error);
    });
  }

  //Use Effect For Initial Rending - Initially Get Data From An API
  useEffect(() => {
    (async () => {
      let Response = await categoryService
        .getAllCategoriesForDropDown()
        .then((Responce) => {
          return Responce;
        });
      return Response;
    })().then((Response) => {
      if (Response.data) {
        //Map data array with Generic List Object with Properties id,text
        //Dropdown only accepts list of Object with Properties id,text
        var result = Response.data.data.map((dataArrayRow) => ({
          _id: dataArrayRow._id,
          name: dataArrayRow.name,
        }));
        setCategories(result);
        if (Response.data.count === 0) {
          setNotify({
            isOpen: true,
            message: 'No Categories To Load',
            type: 'error',
          });
        }
      } else {
        console.error('Unable To Get Records');
      }
    });
  }, []);

  useEffect(() => {
    activityMonitoring('Products');
  }, []);

  const handleStatusChange = async (event, index) => {
    let cloneDatatableRecords = records;
    let id = cloneDatatableRecords[index]._id;
    let checkedStatus = event.target.checked;

    if (id) {
      try {
        let productStatusUpdate = {
          status: checkedStatus ? 'active' : 'disabled',
        };
        apiResponseDto.data = null;
        apiResponseDto.error = null;
        apiResponseDto = await productService
          .patchProduct(id, productStatusUpdate)
          .then((Responce) => {
            return Responce;
          });

        if (apiResponseDto.data) {
          let newStatusChangedRecord = {
            ...cloneDatatableRecords[index],
            status: checkedStatus ? 'active' : 'disabled',
          };

          cloneDatatableRecords[index] = newStatusChangedRecord;
          setRecords(cloneDatatableRecords);
          setNotify({
            isOpen: true,
            message: 'Product Status Updated Successfully',
            type: 'success',
            notificationTime: 15000,
          });
        }

        if (apiResponseDto.error) {
          setNotify({
            isOpen: true,
            message: 'Unable To Process Request',
            type: 'error',
          });
        }
      } catch (e) {
        setNotify({
          isOpen: true,
          message: 'Unable To Process Request',
          type: 'error',
        });
      }
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    getRecords(0, 10, 1, 'updatedAt');
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const onDelete = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    try {
      apiResponseDto.data = null;
      apiResponseDto.error = null;
      apiResponseDto = await productService
        .deleteProduct(id)
        .then((Response) => {
          return Response;
        });
      if (apiResponseDto.data) {
        getRecords(0, 10, 1, 'updatedAt');
        setNotify({
          isOpen: true,
          message: 'Deleted Successfully',
          type: 'success',
        });
      }
      if (apiResponseDto.error) {
        if (apiResponseDto.statusCode === 403) {
          setNotify({
            isOpen: true,
            message:
              'Unable To Delete Product : This Product Is A Part Of Sale',
            type: 'error',
          });
        } else {
          setNotify({
            isOpen: true,
            message: 'Unable To Process Request',
            type: 'error',
          });
        }
      }
    } catch (e) {
      setNotify({
        isOpen: true,
        message: 'Unable To Process Request',
        type: 'error',
      });
    }
  };
  function getModalStyle() {
    const top = 50;
    const left = 50;
    const height = 65;
    const width = 70;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      height: `${height}%`,
      width: `${width}%`,
      textAlign: `center`,
    };
  }
  const [modalStyle] = useState(getModalStyle);
  const [openBulkUploadModal, setOpenBulkUploadModal] = useState(false);
  const [showProgressbar, setShowProgressbar] = useState(false);
  const [showCSVTable, setShowCSVTable] = useState(false);
  const [isCSVLoaded, setIsCSVLoaded] = useState(false);
  const [cSVData, setCSVData] = useState([]);
  const [csvFileStore, setcsvFileStore] = useState();
  const [isBulkDataComplete, setIsBulkDataComplete] = useState(true);

  function CSVDataCancel() {
    setShowCSVTable(false);
    setIsCSVLoaded(false);
  }
  const handleBulkUploadModalClose = () => {
    setOpenBulkUploadModal(false);
  };
  const handleBulkUploadModalOpen = () => {
    setOpenBulkUploadModal(true);
  };

  function CSVDataUpload() {
    productService
      .FileuploadHandler(csvFileStore, 'upload')
      .then((res) => {
        if (res.status === 200) {
          setShowCSVTable(false);
          setIsCSVLoaded(false);
          handleBulkUploadModalClose();
          setNotify({
            isOpen: true,
            message: 'Data has been uploaded Successfully!',
            type: 'success',
          });
        }
        if (res.status !== 200) {
          setNotify({
            isOpen: true,
            message: 'unable to process please verify your file!',
            type: 'error',
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function FileuploadHandler(e) {
    setShowProgressbar(true);
    let files = e.target.files[0];
    setcsvFileStore(files);
    productService
      .FileuploadHandler(files, 'validate')
      .then((res) => {
        if (res.status === 200) {
          setShowCSVTable(true);
          setIsCSVLoaded(true);
          setIsBulkDataComplete(true);
          setCSVData(res.data.data);

          res.data.data.forEach((row) => {
            for (var name in row) {
              // eslint-disable-next-line no-prototype-builtins
              if (row.hasOwnProperty(name)) {
                if (typeof row[name] === 'boolean') continue;
                if (
                  row[name] ||
                  (name === 'previousStock' && row[name] >= 0) ||
                  name === 'tax'
                ) {
                  // do nothing
                } else {
                  setIsBulkDataComplete(false);
                  break;
                }
              }
            }
          });
        }
        if (res.status !== 200) {
          setShowCSVTable(false);
          setIsCSVLoaded(false);
        }
      })
      .catch((error) => {
        console.error(error.response);
        setOpenBulkUploadModal(false);
        setNotify({
          isOpen: true,
          message: error.response.data.data,
          type: 'error',
        });
      });
    setShowProgressbar(false);
  }
  const body2 = (
    <div style={modalStyle} className={classes.paper2}>
      <h2 id="simple-modal-title" className="modalTitle">
        Upload Product
      </h2>

      <IconButton
        className="modalCloseButton"
        color="inherit"
        aria-label="menu"
        onClick={handleBulkUploadModalClose}
        id="btnCloseModal"
      >
        <ClearIcon />
      </IconButton>
      {cSVData.filter((csvItem) => !csvItem.productExists).length > 0 ? (
        <Typography
          variant="h6"
          gutterBottom
          style={{ color: 'green', textAlign: 'left' }}
        >
          {cSVData.filter((csvItem) => !csvItem.productExists).length} Out Of{' '}
          {cSVData.length} Products Are New
        </Typography>
      ) : (
        <></>
      )}

      {showCSVTable ? (
        isCSVLoaded ? (
          <div
            style={{
              width: '100%',
              overflowX: 'hidden',
              overflowY: 'scroll',
              maxHeight: '350px',
            }}
          >
            <CSVTableContainer className={classes.tableContent}>
              <CSVTable
                id="bulkUploadTable"
                className={classes.toTable}
                aria-label="simple table"
              >
                <CSVTableHead>
                  <CSVTableRow>
                    <CSVTableCell>Item Name</CSVTableCell>
                    <CSVTableCell>Item Category</CSVTableCell>
                    <CSVTableCell>Previous Stock</CSVTableCell>
                    <CSVTableCell>Addition in Stock</CSVTableCell>
                    <CSVTableCell>Purchase Price</CSVTableCell>
                    <CSVTableCell>Tax</CSVTableCell>
                    <CSVTableCell>Sale Price</CSVTableCell>
                    <CSVTableCell>Discount</CSVTableCell>
                    <CSVTableCell>Units</CSVTableCell>
                    <CSVTableCell>Barcode</CSVTableCell>
                  </CSVTableRow>
                </CSVTableHead>
                <CSVTableBody id="bulkUploadTbody">
                  {cSVData.map((row, index) => (
                    <CSVTableRow key={index} id={'bulkUploadTrow' + index}>
                      {row.productExists ? (
                        <CSVTableCell>{row.product}</CSVTableCell>
                      ) : (
                        <CSVTableCell
                          style={{ color: row.product ? 'green' : 'red' }}
                        >
                          {row.product ? `${row.product}**` : 'cannot be empty'}
                        </CSVTableCell>
                      )}
                      {row.categoryExists ? (
                        <CSVTableCell>{row.category}</CSVTableCell>
                      ) : (
                        <CSVTableCell
                          style={{ color: row.category ? 'green' : 'red' }}
                        >
                          {row.category
                            ? `${row.category}**`
                            : 'cannot be empty'}
                        </CSVTableCell>
                      )}
                      <CSVTableCell>{row.previousStock}</CSVTableCell>
                      <CSVTableCell
                        style={{ color: row.stockCount ? '' : 'red' }}
                      >
                        {row.stockCount ? row.stockCount : 'cannot be empty'}
                      </CSVTableCell>
                      <CSVTableCell
                        style={{ color: row.purchasePrice ? '' : 'red' }}
                      >
                        {row.purchasePrice
                          ? row.purchasePrice
                          : 'cannot be empty'}
                      </CSVTableCell>
                      <CSVTableCell>{row.tax ? row.tax : '0'}</CSVTableCell>
                      <CSVTableCell
                        style={{ color: row.salePrice ? '' : 'red' }}
                      >
                        {row.salePrice ? row.salePrice : 'cannot be empty'}
                      </CSVTableCell>
                      <CSVTableCell
                        style={{
                          color:
                            row.MinimumDiscount && row.MaximumDiscount
                              ? ''
                              : 'red',
                        }}
                      >
                        {row.MinimumDiscount && row.MaximumDiscount
                          ? `${row.MinimumDiscount}% - ${row.MaximumDiscount}%`
                          : 'cannot be empty'}
                      </CSVTableCell>
                      <CSVTableCell
                        style={{
                          color: row.noUnits && row.unitType ? '' : 'red',
                        }}
                      >
                        {row.noUnits && row.unitType
                          ? row.noUnits + ' - ' + row.unitType
                          : 'cannot be empty'}
                      </CSVTableCell>
                      <CSVTableCell style={{ color: row.barcode ? '' : 'red' }}>
                        {row.barcode ? row.barcode : 'cannot be empty'}
                      </CSVTableCell>
                    </CSVTableRow>
                  ))}
                </CSVTableBody>
              </CSVTable>
            </CSVTableContainer>
            <div
              style={{
                bottom: '15px',
                position: 'fixed',
                width: '94%',
                display: 'flex',
              }}
            >
              <Button
                variant="contained"
                style={{
                  marginRight: 'auto',
                  marginLeft: '5px',
                  height: '50px',
                  width: '150px',
                }}
                onClick={CSVDataCancel}
                id="btnBulkUploadCancel"
              >
                Cancel
              </Button>
              {isBulkDataComplete ? (
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    marginLeft: 'auto',
                    marginRight: '5px',
                    height: '50px',
                    width: '150px',
                  }}
                  onClick={CSVDataUpload}
                  id="btnBulkUploadSave"
                >
                  Save
                </Button>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <></>
        )
      ) : (
        <div>
          <div>
            <label className="custom-file-upload">
              <input
                id="btnBulkUploadChFile"
                type="file"
                name="Choose File"
                onChange={(e) => FileuploadHandler(e)}
              />
              <span style={{ right: '2px', top: '5px', position: 'relative' }}>
                <CloudUploadIcon />
              </span>

              <span>Choose File</span>
            </label>
          </div>

          <div>
            <a
              href={sampleFile}
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              <img variant="square" alt="CSV Export Icon" src={CSVExportIcon} />
            </a>
          </div>

          <div style={{ textAlign: 'left' }}>
            <Typography
              variant="body1"
              gutterBottom
              style={{ textDecorationLine: 'underline' }}
            >
              Note:
            </Typography>
            <Typography variant="body2" gutterBottom>
              1. Download the sample .CSV file from the icon above.
            </Typography>
            <Typography variant="body2" gutterBottom>
              2. Product Name, Category, Purchase Price, Barcode, Sales Price,
              Stock Count,Minimum Discount, Maximum Discount, NoOfUnits & Unit
              Type are compulsory.
            </Typography>
            <Typography variant="body2" gutterBottom>
              3. Then upload the file from the above `Choose File`.
            </Typography>
          </div>
        </div>
      )}
      {showProgressbar ? <LinearProgress id="progressProdUpload" /> : null}
    </div>
  );
  return (
    <>
      <Modal
        open={openBulkUploadModal}
        onClose={handleBulkUploadModalClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body2}
      </Modal>
      <PageTitle
        title="Products"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="productListHeading"
      />
      <Paper elevation={6} square className={classes.PaperMargin}>
        <Toolbar disableGutters>
          <form onSubmit={handleSearch} style={{ width: '100%' }}>
            <Grid container>
              <Grid item xs={12} sm={3} md={3} lg={2}>
                <TextField
                  id="txtSearchProdName"
                  label="Name"
                  className={classes.searchInput}
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={2}>
                <div className={classes.searchDropdown}>
                  <Controls.EditableDropDown
                    name="categoriesRef_id"
                    label="Category"
                    data={categories}
                    onChange={(e, newValue) => setCategory(newValue)}
                    options={categories}
                    id="ddSearchProdCat"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={2}>
                <TextField
                  id="txtSearchProdBarcode"
                  label="Barcode"
                  className={classes.searchInput}
                  variant="outlined"
                  value={barcode}
                  onChange={(e) => setBarcode(e.target.value)}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={2}>
                <TextField
                  id="txtSearchProdSKU"
                  label="SKU"
                  className={classes.searchInput}
                  variant="outlined"
                  value={sku}
                  onChange={(e) => setSku(e.target.value)}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4}>
                <Controls.Button
                  text="Search"
                  variant="contained"
                  color="primary"
                  id="btnProdSearch"
                  startIcon={<SearchIcon />}
                  className={classes.searchButton}
                  type="submit"
                />
                <Controls.Button
                  text="Bulk Upload"
                  variant="contained"
                  color="primary"
                  id="btnUploadProduct"
                  startIcon={<PublishIcon />}
                  className={classes.uploadButton}
                  onClick={() => {
                    handleBulkUploadModalOpen();
                  }}
                />
                <Controls.Button
                  text="Add New"
                  variant="contained"
                  color="primary"
                  id="btnAddProduct"
                  startIcon={<AddIcon />}
                  className={classes.newButton}
                  onClick={() => {
                    history.push({
                      pathname: '/ProductDetails',
                      state: {
                        pageHeaderTitile: 'Add Product',
                        recordForEdit,
                      },
                    });
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </Toolbar>
        <CircularLoader isload={isLoading} />
        <GenericTable
          data={records}
          TableId={'productsTable'}
          TableTitle={'Product Table'}
          cellData={[
            { name: 'name', type: 'string' },
            { name: 'categoriesRef_name', type: 'string' },
            { name: 'barcode', type: 'float' },
            { name: 'sku', type: 'string' },
            { name: 'discountLimit', type: 'float' },
            { name: 'saleFinalPrice', type: 'float' },
            { name: 'createdAt', type: 'date' },
            { name: 'status', type: 'status' },
            { name: 'remove', type: 'remove' },
          ]}
          headCells={headCells}
          recordsCount={recordsCount}
          getRecordsFn={getRecords}
          statusChangeFn={handleStatusChange}
          rowDeleteDialog={setConfirmDialog}
          rowDeletefn={onDelete}
          rowOnClick={rowOnClick}
          isLoading={isLoading}
        />
      </Paper>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        id_Yes="btnProdDelYes"
        id_No="btnProdDelNo"
      />
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
