import React, { useEffect, useState } from 'react';
import PageTitle from '../../Layout/PageTitle';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import { Paper, TableBody, TableRow, TableCell } from '@material-ui/core';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import { getCompanyBillingHistoryByName } from './BillingService';
import useStyles from '../../../Style/AppStylesAdmin';
import useTableServerSide from '../../Utilities/ReuseableCommponents/useTableServerSide';
import { useLocation } from 'react-router';

const headCells = [
  { id: 'month', label: 'Month', align: 'left' },
  { id: 'companyName', label: 'Company Name', align: 'left' },
  { id: 'version', label: 'Version', align: 'left' },
  { id: 'status', label: 'Bill Status', align: 'left' },
  { id: 'monthlyCharges', label: 'Amount', align: 'left' },
];

export default function Billing() {
  const classes = useStyles();
  const location = useLocation();
  const [records, setRecords] = useState([]);
  const [recordsCount] = useState(0);

  function getRecords() {
    try {
      if (
        location.state.recordForEdit != null &&
        location.state.recordForEdit.companyRef &&
        location.state.recordForEdit.companyRef.companyName
      ) {
        getCompanyBillingHistoryByName(location.state.recordForEdit.companyName)
          .then((companies) => {
            if (companies.data && companies.data.data) {
              setRecords(companies.data.data);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    try {
      if (location.state.recordForEdit != null) {
        getCompanyBillingHistoryByName(location.state.recordForEdit.companyName)
          .then((companies) => {
            if (companies.data && companies.data.data) {
              setRecords(companies.data.data);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    } catch (error) {
      console.error(error);
    }
  }, [location.state.recordForEdit]);
  const { TblContainer, TblHead, TblPagination } = useTableServerSide(
    records,
    recordsCount,
    headCells,
    getRecords
  );

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  return (
    <>
      <PageTitle
        title={location.state.pageHeaderTitile}
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="categoryDetailsHeading"
      />
      <Paper elevation={6} square className={classes.PaperMargin}>
        <TblContainer>
          <TblHead />
          <TableBody id="catListTbody">
            {records.map((item, index) => (
              <TableRow
                key={item._id}
                id={`catListTrow${index}`}
                name="catListTrow"
              >
                <TableCell id={`cityName${index}`}>{item.month}</TableCell>
                <TableCell id={`cityName${index}`}>
                  {item?.companyName}
                </TableCell>
                <TableCell id={`cityName${index}`}>
                  {item?.companyRef?.version}
                </TableCell>
                <TableCell id={`cityName${index}`}>{item.status}</TableCell>
                <TableCell id={`cityName${index}`}>
                  {item?.monthlyCharges}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        id_Yes="delCat"
        id_No="dontDelCat"
      />
    </>
  );
}
