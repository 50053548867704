import React from 'react';
import './invoice.module.css';

class ComponentToPrint extends React.Component {
  render() {
    const data = JSON.parse(window.sessionStorage.getItem('KotOrder'));
    const companyName = localStorage.getItem('CompanyName');
    var d = new Date().toLocaleString();
    return (
      <div style={{ width: '100%' }}>
        <div style={{ textAlign: 'center' }}>
          <span className="cls_002">{companyName}</span>
        </div>
        <hr />
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td>
                <span className="cls_002">Order Number:</span>
              </td>
              <td style={{ textAlign: 'left' }}>
                <span className="cls_003">
                  {data.KotOrders[0].HoldOrderNumber}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span className="cls_002">Order Date:</span>
              </td>
              <td style={{ textAlign: 'left' }}>
                <span className="cls_003">{d}</span>
              </td>
            </tr>
            <tr>
              <td>
                <span className="cls_002">Order Mode:</span>
              </td>
              <td style={{ textAlign: 'left' }}>
                <span className="cls_003">
                  {data.KotOrders[0].OrderModeName}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <hr />
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <th style={{ textAlign: 'center' }}>
                <span className="cls_002">Qty</span>
              </th>
              <th style={{ textAlign: 'left' }}>
                <span className="cls_002">Product</span>
              </th>
              <th style={{ textAlign: 'left' }}>
                <span className="cls_002"></span>
              </th>
            </tr>
            {data.KotOrders[0].Products.map((number) =>
              number.isKot === false ? (
                <tr key={number._id}>
                  <td style={{ textAlign: 'center' }}>
                    <span className="cls_003">{number.quantity}</span>
                  </td>
                  {number.isDeal ? (
                    <td style={{ textAlign: 'left' }}>
                      <span>
                        {number.name}
                        <br />
                      </span>
                      {number.dealItems.map((di, index) => (
                        <span key={index}>
                          {number.isLess === true ? (
                            <p
                              style={{
                                marginLeft: 15,
                                marginTop: 2,
                                marginBottom: 2,
                              }}
                            >
                              <strike>
                                <span className="cls_004">
                                  {di.itemRef.name}
                                </span>
                                <br />
                              </strike>
                            </p>
                          ) : (
                            <p
                              style={{
                                marginLeft: 15,
                                marginTop: 2,
                                marginBottom: 2,
                              }}
                            >
                              <span className="cls_004">
                                {di.itemRef.name}
                                <br />
                              </span>
                            </p>
                          )}
                        </span>
                      ))}
                    </td>
                  ) : (
                    <td style={{ textAlign: 'left' }}>
                      {number.isLess === true ? (
                        <p>
                          <strike>
                            <span className="cls_004">{number.name}</span>
                          </strike>
                        </p>
                      ) : (
                        <span className="cls_004">{number.name}</span>
                      )}
                    </td>
                  )}

                  <td style={{ textAlign: 'left' }}>
                    <span className="cls_003">
                      {number.isLess === true ? 'LESS' : 'ADD'}
                    </span>
                  </td>
                </tr>
              ) : (
                ''
              )
            )}
          </tbody>
        </table>
        <hr />
      </div>
    );
  }
}

export default ComponentToPrint;
