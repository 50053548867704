import React, { useEffect, useState } from 'react';
import { Paper, makeStyles, Grid } from '@material-ui/core';
import Controls from '../../Utilities/Controls/Controls';
import { useForm, Form } from '../../Utilities/ReuseableCommponents/useForm';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import PageTitle from '../../Layout/PageTitle';
import { useLocation, useHistory } from 'react-router-dom';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import * as WaiterService from './WaiterService';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: '75%',
  },
  newButton: {
    position: 'absolute',
    right: '0',
  },
  PaperMargin: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  invalid: {
    '&:invalid': {
      border: 'red solid 2px',
    },
  },
}));
const initialFValues = {
  _id: 0,
  name: '',
  cnic: '',
  status: true,
  createdBy: '',
  createdAt: '',
  updatedAt: '',
  __v: 0,
};
let Responce = {
  data: null,
  error: null,
  loading: false,
};

let apiResponce = {
  data: null,
  error: null,
  loading: false,
  statusCode: 0,
};
function WaiterDetails() {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const { values, setValues, handleInputChange, resetForm } = useForm(
    initialFValues
  );

  const addOrEdit = async (waiter, resetForm) => {
    if (waiter._id === 0 && waiter.name && waiter.cnic) {
      try {
        Responce.data = null;
        Responce.error = null;
        Responce = await WaiterService.insertWaiter(waiter).then((Responce) => {
          return Responce;
        });

        if (Responce.data) {
          resetForm();

          setNotify({
            isOpen: true,
            message: 'Submitted Successfully',
            type: 'success',
          });
        }

        if (Responce.error) {
          setNotify({
            isOpen: true,
            message: 'Unable To Process Request',
            type: 'error',
          });
        }
      } catch (e) {
        setNotify({
          isOpen: true,
          message: 'Unable To Process Request',
          type: 'error',
        });
      }
    }
    if (waiter._id !== 0 && waiter.name && waiter.cnic) {
      try {
        apiResponce.data = null;
        apiResponce.error = null;
        apiResponce = await WaiterService.updateWaiter(waiter).then(
          (Responce) => {
            return Responce;
          }
        );
        if (apiResponce.data) {
          resetForm();
          setNotify({
            isOpen: true,
            message: 'Waiter Detail Updated Successfully',
            type: 'success',
          });
        }
        if (apiResponce.error) {
          setNotify({
            isOpen: true,
            message: 'Unable To Process Request',
            type: 'error',
          });
        }
      } catch (e) {
        setNotify({
          isOpen: true,
          message: 'Unable To Process Request',
          type: 'error',
        });
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    addOrEdit(values, resetForm);
  };

  useEffect(() => {
    if (location.state.recordForEdit != null)
      setValues({ ...location.state.recordForEdit });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state.recordForEdit]);

  return (
    <>
      <PageTitle
        title={location.state.pageHeaderTitile}
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="waiterDetailsHeading"
      />
      <Paper elevation={0} square className="paperMargin">
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item sm={4}>
              <Controls.Input
                name="name"
                label="Waiter Name"
                value={values.name}
                onChange={handleInputChange}
                required
                id="txtWaiterName"
              />
            </Grid>

            <Grid item sm={4}>
              <Controls.Input
                name="cnic"
                label="Waiter Cnic"
                value={values.cnic}
                helperText={'CNIC Format: XXXXX-XXXXXXX-X'}
                onChange={handleInputChange}
                id="txtWaiterCnic"
                required
                inputProps={{
                  maxLength: 15,
                  className: classes.invalid,
                  pattern: '^[0-9+]{5}-[0-9+]{7}-[0-9]{1}$',
                }}
              />
            </Grid>
            <Grid item sm={4}>
              <Controls.Checkbox
                name="status"
                label="Active"
                value={values.status}
                onChange={handleInputChange}
                id="chkWaiterActive"
              />
            </Grid>
          </Grid>

          <Grid container className="buttonsCotainerMargin">
            <br></br>
            <Grid item xs={3} sm={4} align="left">
              <Controls.Button
                text="Cancel"
                color="default"
                onClick={history.goBack}
                startIcon={<CloseIcon />}
                id="btnCancelWaiter"
              />
            </Grid>
            <Grid item xs={6} sm={6}></Grid>
            <Grid item xs={1} sm={2} align="right">
              <div>
                <Controls.Button
                  type="submit"
                  startIcon={<SaveIcon />}
                  text="Save"
                  id="btnSaveWaiter"
                />
              </div>
            </Grid>
          </Grid>
        </Form>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}

export { WaiterDetails as default };
