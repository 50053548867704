/**
 * @author Mustehsan Armaghan Ghouri
 * @description File for the constants to be used in all components related service files, espacially used for requesting API
 */
import authHeader from '../Services/auth-header';

/**
 *  @class paginationSortingObject
 *  @type {Object}
 *  @global
 *  @default - default values
 *  @property {number} rowsFrom - Pagnation start point for the API request parameter.
 *  @property {string} sortOrderdir - Sort direction for the API request parameter.
 */
let paginationSortingObject = {
  rowsFrom: 0,
  sortOrderdir: 'asc',
};

/**
 * @function
 * @name getAPIrequestHeadersBasic
 * @description Methode to get the Header Info with the basic required details including Content-Type and Authentication Details
 * @global
 * @public
 * @yields {object} - headerObject
 */
export function getAPIrequestHeadersBasic() {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  return headerObject;
}

/**
 * @function
 * @name getPaginationSortingValues
 * @description Methode to get the computed valus for sortOrderdir and rowsFrom
 * @yields {object} - paginationSortingObject - Return Object
 * @property {number} - rowsFrom - Return Object's Property
 * @property {string} - sortOrderdir - Return Object's Property
 * @global
 * @public
 * @yields {object} - headerObject
 */
export function getPaginationSortingValues(page, rowsPerPage, sortDirection) {
  try {
    paginationSortingObject.rowsFrom = page * rowsPerPage;

    paginationSortingObject.sortOrderdir = sortDirection === 'asc' ? 1 : -1;

    return paginationSortingObject;
  } catch (error) {
    console.error(
      'Error Occured While Converting Values For Pagination And Sorgting : Error Details ',
      error
    );
    return paginationSortingObject;
  }
}
