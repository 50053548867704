import { callAPI } from '../../../Services/ApiCaller';
import { API_VERSION } from '../../../Common/constants';
import {
  getAPIrequestHeadersBasic,
  getPaginationSortingValues,
} from '../../../Common/APICallingServiceConstants';
const API_ENDPOINT = `api/${API_VERSION}/tables`;

export async function getAllTable(
  page,
  rowsPerPage,
  sortDirection,
  sortColumnName,
  searchString
) {
  const headerObject = getAPIrequestHeadersBasic();

  let { sortOrderdir, rowsFrom } = getPaginationSortingValues(
    page,
    rowsPerPage,
    sortDirection
  );

  return callAPI(
    `${API_ENDPOINT}`,
    null,
    headerObject,
    'GET',
    `?$orderBy=${sortColumnName}&$orderDir=${sortOrderdir}&$skip=${rowsFrom}&$top=${rowsPerPage}${searchString}`
  ).then((response) => {
    return response;
  });
}

export async function insertTable(table) {
  const headerObject = getAPIrequestHeadersBasic();

  let tableForPost = {
    name: table.name,
    status: !table.status ? 'disabled' : 'active',
  };

  return callAPI(
    `${API_ENDPOINT}`,
    tableForPost,
    headerObject,
    'POST',
    ''
  ).then((response) => {
    return response;
  });
}

export async function deleteTable(_id) {
  const headerObject = getAPIrequestHeadersBasic();

  return callAPI(
    `${API_ENDPOINT}`,
    null,
    headerObject,
    'DELETE',
    `/${_id}`
  ).then((response) => {
    return response;
  });
}

export async function updateTable(table) {
  const headerObject = getAPIrequestHeadersBasic();

  let tableForPost = {
    name: table.name,
  };

  return callAPI(
    `${API_ENDPOINT}/`,
    tableForPost,
    headerObject,
    'PUT',
    `/${table._id}`
  ).then((response) => {
    return response;
  });
}

export async function patchTable(id, tableObject) {
  const headerObject = getAPIrequestHeadersBasic();

  return callAPI(
    `${API_ENDPOINT}`,
    tableObject,
    headerObject,
    'PATCH',
    `/${id}`
  ).then((response) => {
    return response;
  });
}
