import React from 'react';
import { Modal } from '@material-ui/core';
import CustomerModalBody from './CustomerModalBody';
import PropTypes from 'prop-types';
const CustomerModal = (props) => {
  return (
    <div>
      <Modal
        open={props.openCustomer}
        onClose={props.handleCloseCustomerModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div>
          <CustomerModalBody {...props} />
        </div>
      </Modal>
    </div>
  );
};
CustomerModal.propTypes = {
  openCustomer: PropTypes.any,
  handleCloseCustomerModal: PropTypes.any,
};
export default CustomerModal;
