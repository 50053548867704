import { callAPI } from '../Services/ApiCaller';
import authHeader from '../Services/auth-header';
import { API_VERSION } from './constants';

export async function insertActivityMonitoring(data) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  return callAPI(
    `api/${API_VERSION}/activityMonitoring`,
    data,
    headerObject,
    'POST',
    ``
  ).then((Responce) => {
    return Responce;
  });
}
