import React, { useEffect, useState } from 'react';
import { Grid, Paper } from '@material-ui/core';
import Controls from '../../Utilities/Controls/Controls';
import { useForm, Form } from '../../Utilities/ReuseableCommponents/useForm';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import PageTitle from '../../Layout/PageTitle';
import { useLocation, useHistory } from 'react-router-dom';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import * as BranchService from './BranchService';
import { getAllCitiesForDropDown } from '../City/CityService';

const initialFValues = {
  _id: 0,
  name: '',
  cityRef: [],
  citiesRef_id: '',
  createdBy: '',
  createdAt: '',
  updatedAt: '',
  __v: 0,
};
let Responce = {
  data: null,
  error: null,
  messege: '',
  loading: false,
};

let apiResponce = {
  data: null,
  error: null,
  loading: false,
  statusCode: 0,
};
function BranchDetails() {
  const history = useHistory();

  const location = useLocation();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });
  const [records, setRecords] = useState([]);

  const { values, setValues, handleInputChange, resetForm } = useForm(
    initialFValues
  );

  const addOrEdit = async (Branch, resetFormBranch) => {
    if (Branch._id === 0 && Branch.name) {
      try {
        Responce.data = null;
        Responce.error = null;
        Responce = await BranchService.insertBranch(Branch).then(
          (ResponceIns) => {
            return ResponceIns;
          }
        );

        if (Responce.data) {
          resetFormBranch();

          setNotify({
            isOpen: true,
            message: 'Submitted Successfully',
            type: 'success',
          });
        }

        if (Responce.error) {
          setNotify({
            isOpen: true,
            message: Responce.messege
              ? Responce.messege
              : 'Unable To Process Request',
            type: 'error',
          });
        }
      } catch (e) {
        setNotify({
          isOpen: true,
          message: 'Unable To Process Request',
          type: 'error',
        });
      }
    }
    if (Branch._id !== 0 && Branch.name) {
      try {
        apiResponce.data = null;
        apiResponce.error = null;
        apiResponce = await BranchService.updateBranch(Branch);
        if (apiResponce.data) {
          resetFormBranch();
          setNotify({
            isOpen: true,
            message: 'Branch Detail Updated Successfully',
            type: 'success',
          });
        }
        if (apiResponce.error) {
          setNotify({
            isOpen: true,
            message: 'Unable To Process Request',
            type: 'error',
          });
        }
      } catch (e) {
        setNotify({
          isOpen: true,
          message: 'Unable To Process Request',
          type: 'error',
        });
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addOrEdit(values, resetForm);
  };

  function loadCitiesForDropdown() {
    getAllCitiesForDropDown().then((response) => {
      if (response.data) {
        //Map data array with Generic List Object with Properties id,text
        //Dropdown only accepts list of Object with Properties id,text
        var result = response.data.data.map((dataArrayRow) => ({
          _id: dataArrayRow._id,
          name: dataArrayRow.name,
        }));
        setRecords(result);
        if (response.data.count === 0) {
          setNotify({
            isOpen: true,
            message: 'No Categories To Load',
            type: 'error',
          });
        }
      } else {
        console.error('Unable To Get Records');
      }
    });
  }
  useEffect(() => {
    loadCitiesForDropdown();
    if (location.state.recordForEdit != null)
      setValues({ ...location.state.recordForEdit });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state.recordForEdit]);

  return (
    <>
      <PageTitle
        title={location.state.pageHeaderTitile}
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="BranchDetailsHeading"
      />
      <Paper
        elevation={6}
        square
        className="paperMargin"
        style={{
          borderRadius: '10px',
        }}
      >
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12} sm={6} md={4}>
              <Controls.Select
                name="citiesRef_id"
                label="City"
                value={values.citiesRef_id}
                onChange={handleInputChange}
                options={records}
                id="ddBranchCity"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Controls.Input
                name="name"
                label="Branch Name"
                value={values.name}
                onChange={handleInputChange}
                className="searchInput"
                id="txtBranchName"
                required
              />
            </Grid>
            <Grid container className="buttonsCotainerMargin">
              <br></br>
              <Grid item xs={12} sm={6} align="left">
                <Controls.Button
                  text="Cancel"
                  color="default"
                  onClick={history.goBack}
                  startIcon={<CloseIcon />}
                  className="actionBtnMargin"
                  id="btnCancelCat"
                />
              </Grid>
              <Grid item xs={12} sm={6} align="right">
                <div>
                  <Controls.Button
                    type="submit"
                    startIcon={<SaveIcon />}
                    text="Save"
                    className="actionBtnMargin"
                    id="btnSaveCat"
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}

export { BranchDetails as default };
