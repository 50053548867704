import React from 'react';
import '../Style.css';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { Router, Switch } from 'react-router-dom';
import AppRoute from './Layout/AppRoute.js';
import MainPage from './Pages/Dashboard/Dashboard';
import Unauthorized from './Pages/unauthorized';
import Products from './Pages/Products/Products';
import ProductDetails from './Pages/Products/ProductDetails';
import Categories from './Pages/Category/Categories';
import City from './Pages/City/City';
import Branches from './Pages/Branch/Branches';
import FullWidthLayout from './Layout/FullWidthLayout';
import MasterLayout from './Layout/MasterLayout';
import { createBrowserHistory } from 'history';
import POS from './Pages/POS/App';
import SignIn from './Pages/Authorization/SignIn/SignIn';
import SignUp from './Pages/Authorization/SignUp/SignUp';
import CategoryDetails from './Pages/Category/CategoryDetails';
import CityDetails from './Pages/City/CityDetails';
import BranchDetails from './Pages/Branch/BranchDetails';
import Sales from './Pages/Sales/Sales';
import SalesDetails from './Pages/Sales/SalesDetails';
import Inventory from './Pages/Inventory/Inventory';
import InventoryDetails from './Pages/Inventory/InventoryDetails';
import InventoryUpdate from './Pages/Inventory/InventoryUpdate';
import InventoryPurchaseDetail from './Pages/Inventory/InventoryBase';
import PurchaseReturnInventory from './Pages/Inventory/PurchaseReturnInventory';
import PurchaseReturnInventoryDetails from './Pages/Inventory/PurchaseReturnInventoryDetails';
import CashReconciles from './Pages/CashReconcile/CashReconciles';
import CashReconcileDetails from './Pages/CashReconcile/CashReconcileDetails';
import User from './Pages/User/User';
import ForgetPassword from './Pages/ForgotPassword/ForgetPassword';
import UserDetails from './Pages/User/UserDetails';
import ProfitAndLoss from './Pages/ProfitAndLoss/ProfitAndLoss';
import Customer from './Pages/Customer/Customer';
import CustomerDetails from './Pages/Customer/CustomerDetails';
import Vendor from './Pages/Vendor/Vendor';
import VendorDetails from './Pages/Vendor/VendorDetails';
import NayaPayActivation from './Pages/NayaPayOnboarding/NayaPayActivation';
import CacheBuster from '../CacheBuster';
import POSFood from './Pages/Food/App';
import Departments from './Pages/Department/Departments';
import DepartmentDetails from './Pages/Department/DepartmentDetails';
import Tables from './Pages/Table/Tables';
import TableDetails from './Pages/Table/TableDetails';
import Waiters from './Pages/Waiter/Waiters';
import WaiterDetails from './Pages/Waiter/WaiterDetails';
import Riders from './Pages/Rider/Riders';
import RiderDetails from './Pages/Rider/RiderDetails';
import NayaPayTransactions from './Pages/NayaPayOnboarding/NayaPayTransactions';
import OrderDetails from './Pages/Order/OrderDetails';
import Credit from './Pages/Credit/Credit';
import CreditDetails from './Pages/Credit/CreditDetails';
import UserAnalyticsAndBillingInfo from './Pages/Setups/UserAnalyticsAndBillingInfo';
import ProductSales from './Pages/ProductSales/ProductSales';
import ProductMix from './Pages/ProductMix/ProductMix';
import Recipes from './Pages/Recipe/Recipes';
import RecipeDetails from './Pages/Recipe/RecipeDetails';
import Deals from './Pages/Deal/Deals';
import DealDetails from './Pages/Deal/DealDetails';
import CustomerSales from './Pages/CustomerSales/CustomerSales';
import VendorSales from './Pages/vendorSales/VendorSales';
import CustomerSalesDetail from './Pages/CustomerSales/CustomerSalesDetail';
import BranchMappingDetails from './Pages/BranchMapping/BranchMappingDetails';
import CustomerSaleItemsDetail from './Pages/CustomerSales/CustomerSaleItemsDetail';
import CommissionReport from './Pages/CommissionReport/CommissionReport';
import Settings from './Pages/Setups/Settings';
import BranchMapping from './Pages/BranchMapping/BranchMapping';
import PrintBarcode from './Pages/PrintBarcode/PrintBarcode';
import Areas from './Pages/Area/Areas';
import AreaDetails from './Pages/Area/AreaDetails';
import InventoryPurchase from './Pages/Inventory/InventoryPurchase';
import Billing from './Pages/FinanceManagement/Billing';
import BillingDetails from './Pages/FinanceManagement/BillingDetails';
import CompanyBillingControl from './Pages/FinanceManagement/CompanyBillingControl';
import CompanyBillingControlDetail from './Pages/FinanceManagement/CompanyBillingControlDetail';
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1976d2',
      light: '#F3F3F8',
    },
    secondary: {
      main: '#f83245',
      light: '#f8324526',
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        transform: 'translateZ(0)',
      },
    },
  },
  props: {
    MuiIconButton: {
      disableRipple: true,
    },
  },
});
function Application() {
  //Routes
  const history = createBrowserHistory();
  let hideFeatures = JSON.parse(localStorage.getItem('_HideFeature')) || [];
  let foundFeature = hideFeatures.find((hf) => hf.name === 'Barcode');
  const isPrintBarcode = foundFeature && foundFeature.status ? true : false;
  let commissionHideFeature = hideFeatures.find(
    (hf) => hf.name === 'CommissionReport'
  );
  const isCommissionReport =
    commissionHideFeature && commissionHideFeature.status ? true : false;
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }
        return (
          <ThemeProvider theme={theme}>
            <Router history={history}>
              <Switch>
                <AppRoute
                  exact
                  path="/"
                  component={MainPage}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/Products"
                  component={Products}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/Dashboard"
                  component={MainPage}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/Categories"
                  component={Categories}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/City"
                  component={City}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/Branches"
                  component={Branches}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/Areas"
                  component={Areas}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/NayaPayActivation"
                  component={NayaPayActivation}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/NayaPayTransactions"
                  component={NayaPayTransactions}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/POS"
                  component={POS}
                  layout={FullWidthLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/POSFood"
                  component={POSFood}
                  layout={FullWidthLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/Signin"
                  component={SignIn}
                  layout={FullWidthLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/Signup"
                  component={SignUp}
                  layout={FullWidthLayout}
                  redirectcomponent={SignUp}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/ProductDetails"
                  component={ProductDetails}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/BranchMappingDetails"
                  component={BranchMappingDetails}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/CategoryDetails"
                  component={CategoryDetails}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/CityDetails"
                  component={CityDetails}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/BranchDetails"
                  component={BranchDetails}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/AreaDetails"
                  component={AreaDetails}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/ForgotPasswordRequest"
                  component={ForgetPassword}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/User"
                  component={User}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/UserDetails"
                  component={UserDetails}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/Departments"
                  component={Departments}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/DepartmentDetails"
                  component={DepartmentDetails}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/Tables"
                  component={Tables}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/TableDetails"
                  component={TableDetails}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/Waiters"
                  component={Waiters}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/WaiterDetails"
                  component={WaiterDetails}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/Riders"
                  component={Riders}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/BranchMapping"
                  component={BranchMapping}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/RiderDetails"
                  component={RiderDetails}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/Sales"
                  component={Sales}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/ProductSales"
                  component={ProductSales}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/ProductMix"
                  component={ProductMix}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/SalesDetails"
                  component={SalesDetails}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/CashReconciles"
                  component={CashReconciles}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/CashReconcileDetails"
                  component={CashReconcileDetails}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/Inventory"
                  component={Inventory}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/InventoryDetails"
                  component={InventoryDetails}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/InventoryUpdate"
                  component={InventoryUpdate}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/PurchaseInventory"
                  component={InventoryPurchase}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/PurchaseInventoryDetail"
                  component={InventoryPurchaseDetail}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/PurchaseReturnInventory"
                  component={PurchaseReturnInventory}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/PurchaseReturnInventoryDetails"
                  component={PurchaseReturnInventoryDetails}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/AuditInventory"
                  component={InventoryUpdate}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/ProfitAndLoss"
                  component={ProfitAndLoss}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/Customer"
                  component={Customer}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/CustomerDetails"
                  component={CustomerDetails}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/Billing"
                  component={Billing}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/BillingDetails"
                  component={BillingDetails}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/CompanyBillingControl"
                  component={CompanyBillingControl}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/CompanyBillingControlDetail"
                  component={CompanyBillingControlDetail}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/Vendor"
                  component={Vendor}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/VendorDetails"
                  component={VendorDetails}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/OrderDetails"
                  component={OrderDetails}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/CreditSales"
                  component={Credit}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/CreditSaleDetails"
                  component={CreditDetails}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/BillingInfo"
                  component={UserAnalyticsAndBillingInfo}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/Recipes"
                  component={Recipes}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/Recipes"
                  component={Recipes}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/RecipeDetails"
                  component={RecipeDetails}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/Deals"
                  component={Deals}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/DealDetails"
                  component={DealDetails}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/Settings"
                  component={Settings}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/VendorDetails"
                  component={VendorDetails}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/CustomerSales"
                  component={CustomerSales}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/VendorSales"
                  component={VendorSales}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/CustomerSalesDetail"
                  component={CustomerSalesDetail}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                <AppRoute
                  path="/CustomerSaleItemsDetail"
                  component={CustomerSaleItemsDetail}
                  layout={MasterLayout}
                  redirectcomponent={SignIn}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
                {isCommissionReport && (
                  <AppRoute
                    path="/CommissionReport"
                    component={CommissionReport}
                    layout={MasterLayout}
                    redirectcomponent={SignIn}
                    redirectlayout={FullWidthLayout}
                  ></AppRoute>
                )}

                {isPrintBarcode && (
                  <AppRoute
                    path="/PrintBarcode"
                    component={PrintBarcode}
                    layout={MasterLayout}
                    redirectcomponent={SignIn}
                    redirectlayout={FullWidthLayout}
                  ></AppRoute>
                )}

                <AppRoute
                  path="/unauthorized"
                  component={Unauthorized}
                  layout={FullWidthLayout}
                  redirectcomponent={Unauthorized}
                  redirectlayout={FullWidthLayout}
                ></AppRoute>
              </Switch>
            </Router>
          </ThemeProvider>
        );
      }}
    </CacheBuster>
  );
}
export default Application;
