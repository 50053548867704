import { makeStyles, Paper, Toolbar, TextField } from '@material-ui/core';
import Controls from '../../Utilities/Controls/Controls';
import SearchIcon from '@material-ui/icons/Search';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import React, { useEffect, useState } from 'react';
import PageTitle from '../../Layout/PageTitle';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import GenericTable from '../../Utilities/ReuseableCommponents/TableReport/GenericTable';
import * as profitAndLossService from './ProfitAndLossService';
import CircularLoader from '../../Utilities/ReuseableCommponents/CircularLoader';
import { dateTimeZone as timeZone } from '../../../Common/constants';
import activityMonitoring from '../../../Common/functions';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  PaperMargin: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  CellRightAlign: {
    textAlign: 'right',
  },
  newButton: {
    marginLeft: 'auto',
    display: 'flex',
    marginRight: '0px',
  },
  searchInput: {
    paddingRight: '5px',
    width: '15%',
  },
  tableFooterCell: {
    fontSize: 15,
    color: 'black',
    textAlign: 'center',
  },
}));

const headCells = [
  { id: '_id', label: 'Date', type: 'onlyDate' },
  {
    id: 'totalRevenue',
    label: 'Revenue',
    disableSorting: true,
    type: 'float',
  },
  {
    id: 'totalCost',
    label: 'COGS',
    disableSorting: true,
    type: 'float',
  },
  {
    id: 'profit',
    label: 'Gross Profit',
    disableSorting: true,
    type: 'float',
  },
  { id: 'margin', disableSorting: true, label: 'Margin (%)', type: 'float' },
  {
    id: 'tax',
    label: 'Tax',
    disableSorting: true,
    type: 'float',
  },
];

function ProfitAndLoss() {
  const classes = useStyles();
  const [records, setRecords] = useState([]);
  const [footerRecords, setFooterRecords] = useState({});
  const [recordsCount, setRecordsCount] = useState(0);
  const [saleMode, setSaleMode] = useState('');
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  function getRecords(page, rowsPerPage, sortDirection, sortColumnName) {
    let searchString = '';

    setIsLoading(true);
    if (saleMode) {
      searchString += `&saleMode=${saleMode.nameValue}`;
    }
    if (dateFrom) {
      searchString += `&startDate=${dateFrom}T00:00:00${timeZone()}`;
    }
    if (dateTo) {
      searchString += `&endDate=${dateTo}T23:59:59${timeZone()}`;
    }
    searchString += `&timezone=${
      Intl.DateTimeFormat().resolvedOptions().timeZone
    }`;
    profitAndLossService
      .FetchProfitAndLossReportList(
        page,
        rowsPerPage,
        sortDirection,
        sortColumnName,
        searchString
      )
      .then((Response) => {
        setIsLoading(false);
        if (Response.data) {
          if (Response.data.data) {
            setRecords(Response.data.data);
            setFooterRecords(Response.data.total);
            setRecordsCount(Response.data.count);
          }
        }
      });
  }

  const handleSearch = (e) => {
    e.preventDefault();
    getRecords(0, 5, -1, 'createdAt');
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  useEffect(() => {
    activityMonitoring('ProfitLossReport');
  }, []);
  return (
    <>
      <PageTitle
        title="Profit And Loss Report"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="pandLReportListHeading"
      />

      <Paper elevation={6} square className={classes.PaperMargin}>
        <Toolbar style={{ padding: '0' }}>
          <form
            onSubmit={handleSearch}
            style={{ width: '100%', display: 'flex', alignItems: 'center' }}
          >
            <Controls.EditableDropDown
              name="txtSearchSaleMode"
              label="Sale Mode"
              onChange={(e, newValue) => setSaleMode(newValue)}
              data={profitAndLossService.getSaleModes()}
              className={classes.searchInput}
              id="ddSearchPandLSaleMode"
            />
            <TextField
              id="txtSearchPandLDateFrom"
              label="Date From"
              variant="outlined"
              type="date"
              onChange={(e) => setDateFrom(e.target.value)}
              value={dateFrom}
              className={classes.searchInput}
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
            />
            <TextField
              id="txtSearchPandLDateTo"
              label="Date To"
              variant="outlined"
              type="date"
              onChange={(e) => setDateTo(e.target.value)}
              value={dateTo}
              className={classes.dateField}
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
            />
            <Controls.Button
              text="Search"
              variant="contained"
              color="primary"
              id="btnSearchPandL"
              startIcon={<SearchIcon />}
              className={classes.searchButton}
              type="submit"
            />
          </form>
        </Toolbar>
        <CircularLoader isload={isLoading} />
        <GenericTable
          data={records}
          TableId={'profitLossReport'}
          TableTitle={'Profit & Loss Report'}
          duration={`${dateFrom} ${dateTo ? 'To' : ''} ${dateTo}`}
          cellData={[
            { name: '_id', type: 'onlyDate' },
            { name: 'totalRevenue', type: 'float' },
            { name: 'totalCost', type: 'float' },
            { name: 'profit', type: 'float' },
            { name: 'margin', type: 'percentage' },
            { name: 'totalTax', type: 'float' },
          ]}
          headCells={headCells}
          recordsCount={recordsCount}
          getRecordsFn={getRecords}
          footerRecords={footerRecords}
          footerCellData={[
            { name: 'totalRevenue', type: 'float', align: 'right' },
            { name: 'totalCost', type: 'float', align: 'right' },
            { name: 'profit', type: 'float', align: 'right' },
            { name: 'margin', type: 'percentage', align: 'right' },
            { name: 'totalTax', type: 'float', align: 'right' },
          ]}
          isLoading={isLoading}
        />
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}

export default ProfitAndLoss;
