import { makeStyles, Paper, Toolbar, TextField } from '@material-ui/core';
import Controls from '../../Utilities/Controls/Controls';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect, useState } from 'react';
import PageTitle from '../../Layout/PageTitle';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import * as VendorSalesService from './VendorSalesService';
import CircularLoader from '../../Utilities/ReuseableCommponents/CircularLoader';
import GenericTable from '../../Utilities/ReuseableCommponents/TableReport/GenericTable';
import { dateTimeZone as timeZone } from '../../../Common/constants';
import activityMonitoring from '../../../Common/functions';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  newButton: {
    position: 'absolute',
    right: 10,
  },
  PaperMargin: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  CellRightAlign: {
    textAlign: 'right',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  searchInput: {
    paddingRight: '5px',
  },
}));

const headCells = [
  { id: 'name', label: 'Vendor Name', type: 'string' },
  { id: 'company', label: 'Vendor Company', type: 'string' },
  { id: 'contactPerson', label: 'Contact Person', type: 'string' },
  { id: 'contactNumber', label: 'Contact Number', type: 'string' },
  { id: 'paid', label: 'Paid', type: 'float' },
  { id: 'payable', label: 'Payable', type: 'float' },
  { id: 'total', label: 'Total', type: 'float' },
];

export default function VendorSales() {
  const classes = useStyles();
  const [records, setRecords] = useState([]);
  const [recordsCount, setRecordsCount] = useState(0);
  const [dateFrom, setDateFrom] = React.useState('');
  const [dateTo, setDateTo] = React.useState('');
  const [vendor, setVendor] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  function getRecords(page, rowsPerPage, sortDirection, sortColumnName) {
    let searchString = '';

    setIsLoading(true);
    if (dateFrom) {
      searchString += `&startDate=${dateFrom}T00:00:00${timeZone()}`;
    }
    if (dateTo) {
      searchString += `&endDate=${dateTo}T23:59:59${timeZone()}`;
    }
    if (vendor) {
      searchString += `&vendor=${vendor}`;
    }
    if (contactNumber) {
      searchString += ` &contactNumber=${contactNumber}`;
    }
    return VendorSalesService.getAllVendorSales(
      page,
      rowsPerPage,
      sortDirection,
      sortColumnName,
      searchString
    ).then((Response) => {
      setIsLoading(false);
      if (Response.data) {
        if (Response.data.data) {
          setRecords(Response.data.data);
          setRecordsCount(Response.data.count);
        }
      } else {
        console.error('Error While Fetching Data: ', Response.error);
      }
    });
  }

  const handleSearch = (e) => {
    e.preventDefault();
    getRecords(0, 5, -1, 'totalSale');
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  useEffect(() => {
    activityMonitoring('VendorSales');
  }, []);

  return (
    <>
      <PageTitle
        title="Vendor Report"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="vendorSalesHeading"
      />
      <Paper elevation={6} square className={classes.PaperMargin}>
        <Toolbar style={{ padding: '0' }}>
          <form
            onSubmit={handleSearch}
            style={{ width: '100%', display: 'flex', alignItems: 'center' }}
          >
            <TextField
              id="txtSearchVendorCompany"
              label="Vendor Company"
              className={classes.searchInput}
              variant="outlined"
              value={vendor}
              onChange={(e) => setVendor(e.target.value)}
              autoComplete="off"
            />
            <TextField
              id="txtSearchVendorContactNo"
              label="Contact Number"
              className={classes.searchInput}
              variant="outlined"
              value={contactNumber}
              onChange={(e) => setContactNumber(e.target.value)}
              autoComplete="off"
            />
            <TextField
              id="txtSearchVendorSalesDateFrom"
              label="Date From"
              variant="outlined"
              type="date"
              onChange={(e) => setDateFrom(e.target.value)}
              value={dateFrom}
              className={classes.searchInput}
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
            />
            <TextField
              id="txtSearchVendorSalesDateTo"
              label="Date To"
              variant="outlined"
              type="date"
              onChange={(e) => setDateTo(e.target.value)}
              value={dateTo}
              className={classes.searchInput}
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
            />
            <Controls.Button
              text="Search"
              variant="contained"
              color="primary"
              id="btnSearchVendorSales"
              startIcon={<SearchIcon />}
              className={classes.searchButton}
              type="submit"
            />
          </form>
        </Toolbar>
        <CircularLoader isload={isLoading} />
        <GenericTable
          data={records}
          TableId={'vendorSalesTable'}
          TableTitle={'Vendor Report'}
          duration={`${dateFrom} ${dateTo ? 'To' : ''} ${dateTo}`}
          cellData={[
            { name: 'vendorRef.name', type: 'string' },
            { name: 'vendorRef.company', type: 'string' },
            { name: 'vendorRef.contactPerson', type: 'string' },
            { name: 'vendorRef.contactNumber', type: 'string' },
            { name: 'paid', type: 'float' },
            { name: 'payable', type: 'float' },
            { name: 'paid', type: 'float' },
          ]}
          headCells={headCells}
          recordsCount={recordsCount}
          getRecordsFn={getRecords}
          isLoading={isLoading}
        />
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
