import React, { useEffect, useState } from 'react';
import Controls from '../../Utilities/Controls/Controls';
import { useForm, Form } from '../../Utilities/ReuseableCommponents/useForm';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import PageTitle from '../../Layout/PageTitle';
import { useLocation, useHistory } from 'react-router-dom';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import { makeStyles, Paper, Grid } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import * as TableService from './TableService';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  PaperMargin: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
}));
const initialFValues = {
  _id: 0,
  name: '',
  status: true,
  createdBy: '',
  createdAt: '',
  updatedAt: '',
  __v: 0,
};
let Responce = {
  data: null,
  error: null,
  loading: false,
};

let apiResponce = {
  data: null,
  error: null,
  loading: false,
  statusCode: 0,
};
function TableDetails() {
  const history = useHistory();
  const classes = useStyles();

  const location = useLocation();

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const { values, setValues, handleInputChange, resetForm } = useForm(
    initialFValues
  );

  async function insertData(table, resetFormFields) {
    if (table._id === 0 && table.name) {
      try {
        Responce.data = null;
        Responce.error = null;
        Responce = await TableService.insertTable(table).then((ResponceIns) => {
          return ResponceIns;
        });

        if (Responce.data) {
          resetFormFields();

          setNotify({
            isOpen: true,
            message: 'Submitted Successfully',
            type: 'success',
          });
        }

        if (Responce.error) {
          setNotify({
            isOpen: true,
            message: 'Unable To Process Request',
            type: 'error',
          });
        }
      } catch (e) {
        setNotify({
          isOpen: true,
          message: 'Unable To Process Request',
          type: 'error',
        });
      }
    }
  }

  async function updateData(table) {
    if (table._id !== 0 && table.name) {
      try {
        apiResponce.data = null;
        apiResponce.error = null;
        apiResponce = await TableService.updateTable(table).then(
          (ResponceUp) => {
            return ResponceUp;
          }
        );
        if (apiResponce.data) {
          resetForm();
          setNotify({
            isOpen: true,
            message: 'Table Detail Updated Successfully',
            type: 'success',
          });
        }
        if (apiResponce.error) {
          setNotify({
            isOpen: true,
            message: 'Unable To Process Request',
            type: 'error',
          });
        }
      } catch (e) {
        setNotify({
          isOpen: true,
          message: 'Unable To Process Request',
          type: 'error',
        });
      }
    }
  }

  const addOrEdit = async (table, resetFormFields) => {
    insertData(table, resetFormFields);
    updateData(table);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    addOrEdit(values, resetForm);
  };

  useEffect(() => {
    if (location.state.recordForEdit != null)
      setValues({ ...location.state.recordForEdit });
  }, [location.state.recordForEdit]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <PageTitle
        title={location.state.pageHeaderTitile}
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="tableDetailsHeading"
      />
      <Paper elevation={0} square className={classes.PaperMargin}>
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={6} sm={4}>
              <Controls.Input
                name="name"
                label="Table Name"
                value={values.name}
                onChange={handleInputChange}
                id="txtTableName"
                required
              />
            </Grid>
            <Grid item sm={4}>
              <Controls.Checkbox
                name="status"
                label="Active"
                value={values.status}
                onChange={handleInputChange}
                id="chkTableActive"
              />
            </Grid>
          </Grid>
          <Grid container className={classes.ButtonsCotainerMargin}>
            <br></br>
            <Grid item xs={3} sm={4} align="left">
              <Controls.Button
                text="Cancel"
                color="default"
                onClick={history.goBack}
                startIcon={<CloseIcon />}
                id="btnCancelTable"
              />
            </Grid>
            <Grid item xs={6} sm={6}></Grid>
            <Grid item xs={1} sm={2} align="right">
              <div>
                <Controls.Button
                  type="submit"
                  startIcon={<SaveIcon />}
                  text="Save"
                  id="btnSaveTable"
                />
              </div>
            </Grid>
          </Grid>
        </Form>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}

export { TableDetails as default };
