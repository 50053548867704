import React, { useEffect } from 'react';
import authHeader from '../../../Services/auth-header';
import {
  BaseAPIURL,
  initStatePOS,
  API_VERSION,
} from '../../../Common/constants';
import PropTypes from 'prop-types';
import { getAPIrequestHeadersBasic } from '../../../Common/APICallingServiceConstants';

export const CTX = React.createContext();

export const initCity = {
  data: [
    {
      _id: 0,
      name: 'city',
      nameValue: 'city',
    },
  ],
};
export const initBranch = {
  data: [
    {
      _id: 0,
      name: 'branch',
      nameValue: 'branch',
      cityRef: 0,
      status: 'active',
    },
  ],
};
export const initArea = {
  data: [
    {
      _id: 0,
      name: '',
      areaInstruction: '',
      cityRef: 0,
      status: 'active',
      startTime: '',
      endTime: '',
      branchMapping: [],
    },
  ],
};
export const initRider = {
  data: [
    {
      _id: 0,
      name: 'rider',
      nameValue: 'rider',
    },
  ],
};
export const initWaiter = {
  data: [
    {
      _id: 0,
      name: 'waiter',
      nameValue: 'waiter',
    },
  ],
};
export const initTable = {
  data: [
    {
      _id: 0,
      name: 'table',
      nameValue: 'table',
    },
  ],
};
export const initDeal = {
  data: [
    {
      _id: 0,
      name: 'deal',
      nameValue: 'deal',
    },
  ],
};
export const initSearchData = {
  data: [
    {
      _id: 0,
      name: 'product',
      barcode: 'barcode',
    },
  ],
};
export let initDealCategory = {
  data: {
    _id: 0,
    name: '',
    products: [],
  },
};
export const initDealProduct = {
  data: {
    _id: 0,
    _Cid: 0,
    name: '',
    unitSaleFinalPrice: '',
    salePrice: 0,
    unitSalePrice: 0,
    saleFinalPrice: 0,
    Quantity: 1,
    taxPercentage: 0,
    Tempdiscount: 0,
    discountPercentage: 0,
    unitQuantity: 1,
    barcode: '',
    minDiscountPercentage: 0,
    maxDiscountPercentage: 0,
    description: '',
    status: 'active',
    isDeal: true,
  },
};
export const initDealProductSearch = {
  data: {
    _id: 0,
    categoriesRef: [],
    status: 'active',
    isSaleable: true,
    name: '',
    salePrice: 0,
    taxPercentage: 0,
    saleFinalPrice: 0,
    unitOfPurchase: '',
    unitOfSale: '',
    conversionUnit: 1,
    barcode: '',
    minDiscountPercentage: 0,
    maxDiscountPercentage: 0,
    description: '',
    sku: '',
    isRecipeItem: false,
    unitQuantity: 1,
    Quantity: 1,
    discountPercentage: 0,
    Tempdiscount: 0,
    unitSaleFinalPrice: '',
    unitSalePrice: 0,
    _Cid: -1,
    isDeal: true,
  },
};
function reducer(state, action) {
  switch (action.type) {
    case 'OnSuccess':
      return { ...state, ...action.payload };
    case 'OnSuccessRider':
      return { ...state, ...action.payload };
    case 'OnSuccessWaiter':
      return { ...state, ...action.payload };
    case 'OnSuccessTable':
      return { ...state, ...action.payload };
    case 'OnSuccessSearch':
      return { ...state, ...action.payload };
    case 'OnSuccessDeal':
      return { ...state, ...action.payload };
    case 'OnSuccessCity':
      return { ...state, ...action.payload };
    case 'OnSuccessBranch':
      return { ...state, ...action.payload };
    case 'OnSuccessArea':
      return { ...state, ...action.payload };
    case 'OnFailure':
      return { ...state };
    default:
      return { ...state };
  }
}

function Store(prop) {
  const [allProducts, dispatch] = React.useReducer(reducer, initStatePOS);
  const [allRiders, dispatchRider] = React.useReducer(reducer, initRider);
  const [allWaiters, dispatchWaiter] = React.useReducer(reducer, initWaiter);
  const [allTables, dispatchTable] = React.useReducer(reducer, initTable);
  const [allData, dispatchData] = React.useReducer(reducer, initSearchData);
  const [allDeals, dispatchDeal] = React.useReducer(reducer, initDeal);
  const [allCity, dispatchCity] = React.useReducer(reducer, initCity);
  const [allBranch, dispatchBranch] = React.useReducer(reducer, initBranch);
  const [allArea, dispatchArea] = React.useReducer(reducer, initArea);

  useEffect(() => {
    var objectMapper = require('object-mapper');
    var FinalListArea = {
      data: {
        area: [],
      },
    };
    var FinalListBranch = {
      data: {
        branch: [],
      },
    };
    var FinalListCity = {
      data: {
        city: [],
      },
    };
    var FinalListRider = {
      data: {
        riders: [],
      },
    };
    var FinalListWaiter = {
      data: {
        waiters: [],
      },
    };
    var FinalListTable = {
      data: {
        tables: [],
      },
    };
    var FinalLIST = {
      data: {
        categories: [],
      },
    };

    var SearchData = {
      data: {
        search: [],
      },
    };
    var FinalListDeal = {
      data: {
        deals: [],
      },
    };

    var map = {
      'categoriesRef._id': 'data.categories.[]._id',
      'categoriesRef.name': 'data.categories.[].name',
      '[]._id': 'data.categories.[0].products.[]._id',
      '[]._Cid': 'data.categories.[0].products.[]._Cid',
      '[].name': 'data.categories.[0].products.[].name',
      '[].unitSaleFinalPrice':
        'data.categories.[0].products.[].unitSaleFinalPrice',
      '[].salePrice': 'data.categories.[0].products.[].salePrice',
      '[].unitSalePrice': 'data.categories.[0].products.[].unitSalePrice',
      '[].saleFinalPrice': 'data.categories.[0].products.[].saleFinalPrice',
      '[].Quantity': 'data.categories.[0].products.[].Quantity',
      '[].units': 'data.categories.[0].products.[].units',
      '[].unitType': 'data.categories.[0].products.[].unitType',
      '[].taxPercentage': 'data.categories.[0].products.[].taxPercentage',
      '[].Tempdiscount': 'data.categories.[0].products.[].Tempdiscount',
      '[].discountPercentage':
        'data.categories.[0].products.[].discountPercentage',
      '[].unitQuantity': 'data.categories.[0].products.[].unitQuantity',
      '[].barcode': 'data.categories.[0].products.[].barcode',
      '[].minDiscountPercentage':
        'data.categories.[0].products.[].minDiscountPercentage',
      '[].maxDiscountPercentage':
        'data.categories.[0].products.[].maxDiscountPercentage',
      '[].img': 'data.categories.[0].products.[].img',
      '[].description': 'data.categories.[0].products.[].description',
      '[].status': 'data.categories.[0].products.[].status',
      '[].isDeal': 'data.categories.[0].products.[].isDeal',
    };
    async function fetchData() {
      try {
        const headers = getAPIrequestHeadersBasic();
        const response = await fetch(
          BaseAPIURL +
            `/api/${API_VERSION}/items?populate=categoriesRef&$skip=0&$top=5000000&status=active`,
          {
            method: 'GET',
            headers: headers,
          }
        );
        if (response.ok) {
          let result = await response.json();
          var ListData = [];
          if (result.count > 0) {
            ListData.push(JSON.stringify(result.data));

            result.data = result.data.filter(function (e) {
              if (e.categoriesRef) {
                return (
                  e.categoriesRef.length > 0 &&
                  e.categoriesRef[0].status === 'active'
                );
              }
              return null;
            });
            result.data.forEach((category) => {
              category['isDeal'] = false;
              category['unitQuantity'] = '1';
              category['Quantity'] = '1';
              category['discountPercentage'] = category.minDiscountPercentage;

              category['Tempdiscount'] =
                (category.salePrice *
                  (category.minDiscountPercentage
                    ? category.minDiscountPercentage
                    : 0)) /
                100;
              category['unitSaleFinalPrice'] = category.saleFinalPrice;
              category['unitSalePrice'] = category.salePrice;
              category['_Cid'] = category.categoriesRef[0]._id;
              if (category.image) {
                category[
                  'img'
                ] = `data:${category.image.contentType};base64,${category.image.data}`;
              }
            });
            let mySet = new Set();
            result.data.map((category) =>
              mySet.add(category.categoriesRef[0]._id)
            );
            mySet.forEach(function (item) {
              var dest = objectMapper(
                result.data.filter((a) => a.categoriesRef[0]._id === item),
                map
              );
              FinalLIST.data.categories.push(dest.data.categories[0]);
            });

            initDealCategory = {
              data: {
                _id: 0,
                name: '',
                products: [],
              },
            };
            initDealCategory.data['_id'] = -1;
            initDealCategory.data['name'] = 'Deals';

            FinalLIST.data.categories.push(initDealCategory.data);
            const responseDeals = await fetch(
              BaseAPIURL +
                `/api/${API_VERSION}/deals?populate=dealRef&$skip=0&$top=5000000`,
              {
                method: 'GET',
                headers: authHeader(),
              }
            );

            if (responseDeals.ok) {
              let resultDeals = await responseDeals.json();

              if (resultDeals.count > 0) {
                resultDeals.data.forEach((item) => {
                  let object = { ...initDealProduct.data };

                  object._id = item._id;
                  object._Cid = -1;
                  object.name = item.name;
                  object.unitSaleFinalPrice = item.dealFinalPrice;
                  object.salePrice = item.dealPrice;
                  object.unitSalePrice = item.dealPrice;
                  object.saleFinalPrice = item.dealFinalPrice;
                  object.Quantity = 1;
                  object.taxPercentage = item.dealTaxPercentage;
                  object.Tempdiscount = 0;
                  object.discountPercentage = 0;
                  object.unitQuantity = 1;
                  object.barcode = '';
                  object.minDiscountPercentage = 0;
                  object.maxDiscountPercentage = 0;
                  object.description = '';
                  object.status = 'active';
                  object.isDeal = true;

                  FinalLIST.data.categories[
                    FinalLIST.data.categories.length - 1
                  ].products.push(object);

                  let objectSearch = { ...initDealProductSearch.data };

                  objectSearch._id = item._id;
                  objectSearch.categoriesRef = [];
                  objectSearch.status = 'active';
                  objectSearch.isSaleable = true;
                  objectSearch.name = item.name;
                  objectSearch.salePrice = item.dealPrice;
                  objectSearch.taxPercentage = item.dealTaxPercentage;
                  objectSearch.saleFinalPrice = item.dealFinalPrice;
                  objectSearch.unitOfPurchase = '';
                  objectSearch.unitOfSale = '';
                  objectSearch.conversionUnit = 1;
                  objectSearch.barcode = '';
                  objectSearch.minDiscountPercentage = 0;
                  objectSearch.maxDiscountPercentage = 0;
                  objectSearch.description = '';
                  objectSearch.sku = '';
                  objectSearch.isRecipeItem = false;
                  objectSearch.unitQuantity = 1;
                  objectSearch.Quantity = 1;
                  objectSearch.discountPercentage = 0;
                  objectSearch.Tempdiscount = 0;
                  objectSearch.unitSaleFinalPrice = item.dealFinalPrice;
                  objectSearch.unitSalePrice = item.dealPrice;
                  objectSearch._Cid = -1;
                  objectSearch.isDeal = true;

                  result.data.push(objectSearch);
                });
              }
            }

            localStorage.setItem('count', JSON.stringify(FinalLIST));
            localStorage.setItem('Search', JSON.stringify(result.data));

            dispatch({ type: 'OnSuccess', payload: FinalLIST });
          } else {
            localStorage.setItem('count', JSON.stringify(initStatePOS));
            localStorage.setItem('Search', JSON.stringify([]));
          }
        } else {
          dispatch({
            type: 'OnSuccess',
            payload: JSON.parse(localStorage.getItem('count')),
          });
        }
      } catch (e) {
        console.error(e);
        dispatch({
          type: 'OnSuccess',
          payload: JSON.parse(localStorage.getItem('count')),
        });
      }
    }
    async function fetchAllCities() {
      try {
        const response = await fetch(
          BaseAPIURL + `/api/${API_VERSION}/cities?$skip=0&$top=5000000`,
          {
            method: 'GET',
            headers: authHeader(),
          }
        );

        if (response.ok) {
          let result = await response.json();
          if (result.count > 0) {
            result.data.forEach((item) => {
              initCity['_id'] = item._id;
              initCity['name'] = item.name;
              initCity['nameValue'] = item.name;

              FinalListCity.data.city.push(initCity);
            });

            localStorage.setItem('City', JSON.stringify(result.data));

            dispatchCity({ type: 'OnSuccessCity', payload: FinalListCity });
          } else {
            localStorage.setItem('City', JSON.stringify([]));
            dispatchCity({ type: 'OnFailure', payload: [] });
          }
        } else {
          localStorage.setItem('City', JSON.stringify([]));
          dispatchCity({ type: 'OnFailure', payload: [] });
        }
      } catch (e) {
        dispatchCity({
          type: 'OnSuccess',
          payload: JSON.parse(localStorage.getItem('count')),
        });
      }
    }
    async function fetchAllBranches() {
      try {
        const response = await fetch(
          BaseAPIURL + `/api/${API_VERSION}/branches?$skip=0&$top=5000000`,
          {
            method: 'GET',
            headers: authHeader(),
          }
        );

        if (response.ok) {
          let result = await response.json();
          if (result.count > 0) {
            result.data.forEach((item) => {
              initBranch['_id'] = item._id;
              initBranch['name'] = item.name;
              initBranch['nameValue'] = item.name;
              initBranch['cityRef'] = item.cityRef;
              initBranch['status'] = item.status;

              FinalListBranch.data.branch.push(initBranch);
            });

            localStorage.setItem('Branch', JSON.stringify(result.data));

            dispatchBranch({
              type: 'OnSuccessBranch',
              payload: FinalListBranch,
            });
          } else {
            localStorage.setItem('Branch', JSON.stringify([]));
            dispatchBranch({ type: 'OnFailure', payload: [] });
          }
        } else {
          localStorage.setItem('Branch', JSON.stringify([]));
          dispatchBranch({ type: 'OnFailure', payload: [] });
        }
      } catch (e) {
        dispatchBranch({
          type: 'OnSuccess',
          payload: JSON.parse(localStorage.getItem('count')),
        });
      }
    }
    async function fetchAllAreas() {
      try {
        const response = await fetch(
          BaseAPIURL + `/api/${API_VERSION}/areas?$skip=0&$top=5000000`,
          {
            method: 'GET',
            headers: authHeader(),
          }
        );

        if (response.ok) {
          let result = await response.json();
          if (result.count > 0) {
            result.data.forEach((item) => {
              initArea['_id'] = item._id;
              initArea['name'] = item.name;
              initArea['cityRef'] = item.cityRef;
              initArea['status'] = item.status;
              initArea['startTime'] = item.startTime;
              initArea['endTime'] = item.endTime;

              FinalListArea.data.area.push(initArea);
            });

            localStorage.setItem('Area', JSON.stringify(result.data));

            dispatchArea({
              type: 'OnSuccessArea',
              payload: FinalListArea,
            });
          } else {
            localStorage.setItem('Area', JSON.stringify([]));
            dispatchArea({ type: 'OnFailure', payload: [] });
          }
        } else {
          localStorage.setItem('Area', JSON.stringify([]));
          dispatchArea({ type: 'OnFailure', payload: [] });
        }
      } catch (e) {
        dispatchArea({
          type: 'OnSuccess',
          payload: JSON.parse(localStorage.getItem('count')),
        });
      }
    }
    async function fetchAllRiders() {
      try {
        const response = await fetch(
          BaseAPIURL + `/api/${API_VERSION}/riders?$skip=0&$top=5000000`,
          {
            method: 'GET',
            headers: authHeader(),
          }
        );

        if (response.ok) {
          let result = await response.json();
          if (result.count > 0) {
            result.data.forEach((item) => {
              initRider['_id'] = item._id;
              initRider['name'] = item.name;
              initRider['nameValue'] = item.name;

              FinalListRider.data.riders.push(initRider);
            });

            localStorage.setItem('Riders', JSON.stringify(result.data));

            dispatchRider({ type: 'OnSuccessRider', payload: FinalListRider });
          } else {
            localStorage.setItem('Riders', JSON.stringify([]));
            dispatchRider({ type: 'OnFailure', payload: [] });
          }
        } else {
          localStorage.setItem('Riders', JSON.stringify([]));
          dispatchRider({ type: 'OnFailure', payload: [] });
        }
      } catch (e) {
        dispatchRider({
          type: 'OnSuccess',
          payload: JSON.parse(localStorage.getItem('count')),
        });
      }
    }
    async function fetchAllWaiters() {
      try {
        const response = await fetch(
          BaseAPIURL + `/api/${API_VERSION}/waiters?$skip=0&$top=5000000`,
          {
            method: 'GET',
            headers: authHeader(),
          }
        );

        if (response.ok) {
          let result = await response.json();
          if (result.count > 0) {
            result.data.forEach((item) => {
              initWaiter['_id'] = item._id;
              initWaiter['name'] = item.name;
              initWaiter['nameValue'] = item.nameValue;

              FinalListWaiter.data.waiters.push(initWaiter);
            });

            localStorage.setItem('Waiters', JSON.stringify(result.data));

            dispatchRider({
              type: 'OnSuccessWaiter',
              payload: FinalListWaiter,
            });
          } else {
            localStorage.setItem('Waiters', JSON.stringify([]));
            dispatchWaiter({ type: 'OnFailure', payload: [] });
          }
        } else {
          localStorage.setItem('Waiters', JSON.stringify([]));
          dispatchWaiter({ type: 'OnFailure', payload: [] });
        }
      } catch (e) {
        dispatchWaiter({ type: 'OnFailure', payload: [] });
      }
    }
    async function fetchAllTables() {
      try {
        const response = await fetch(
          BaseAPIURL + `/api/${API_VERSION}/tables?$skip=0&$top=5000000`,
          {
            method: 'GET',
            headers: authHeader(),
          }
        );
        if (response.ok) {
          let result = await response.json();
          if (result.count > 0) {
            result.data.forEach((item) => {
              initTable['_id'] = item._id;
              initTable['name'] = item.name;
              initTable['nameValue'] = item.nameValue;
            });
            FinalListTable.data.tables.push(initTable);
            localStorage.setItem('Tables', JSON.stringify(result.data));
            dispatchTable({
              type: 'OnSuccessTable',
              payload: FinalListTable,
            });
          } else {
            localStorage.setItem('Tables', JSON.stringify([]));
            dispatchTable({ type: 'OnFailure', payload: [] });
          }
        } else {
          localStorage.setItem('Tables', JSON.stringify([]));
          dispatchTable({ type: 'OnFailure', payload: [] });
        }
      } catch (e) {
        dispatchTable({ type: 'OnFailure', payload: [] });
      }
    }
    async function fetchAllSearchData() {
      try {
        const response = await fetch(
          BaseAPIURL +
            `/api/${API_VERSION}/items?populate=categoriesRef&$skip=0&$top=5000000`,
          {
            method: 'GET',
            headers: authHeader(),
          }
        );

        if (response.ok) {
          let result = await response.json();
          if (result.count > 0) {
            result.data.forEach((item) => {
              initSearchData['_id'] = item._id;
              initSearchData['name'] = item.name;
              initSearchData['barcode'] = item.barcode;

              SearchData.data.search.push(initSearchData);
            });

            localStorage.setItem(
              'Search',
              JSON.stringify(result.data.filter((a) => a.status == 'active'))
            );

            dispatchData({ type: 'OnSuccessSearch', payload: SearchData });
          } else {
            localStorage.setItem('Search', JSON.stringify([]));
            dispatchData({ type: 'OnFailure', payload: [] });
          }
        } else {
          localStorage.setItem('Search', JSON.stringify([]));
          dispatchData({ type: 'OnFailure', payload: [] });
        }
      } catch (e) {
        dispatchData({
          type: 'OnSuccess',
          payload: JSON.parse(localStorage.getItem('count')),
        });
      }
    }
    async function fetchAllDeals() {
      try {
        const response = await fetch(
          BaseAPIURL +
            `/api/${API_VERSION}/deals?populate=dealRef&$skip=0&$top=5000000`,
          {
            method: 'GET',
            headers: authHeader(),
          }
        );
        if (response.ok) {
          let result = await response.json();
          if (result.count > 0) {
            FinalListDeal.data.deals.push(result.data);
            localStorage.setItem('Deals', JSON.stringify(result.data));
            dispatchDeal({
              type: 'OnSuccessDeal',
              payload: FinalListDeal,
            });
          } else {
            localStorage.setItem('Deals', JSON.stringify([]));
            dispatchDeal({ type: 'OnFailure', payload: [] });
          }
        } else {
          localStorage.setItem('Deals', JSON.stringify([]));
          dispatchDeal({ type: 'OnFailure', payload: [] });
        }
      } catch (e) {
        dispatchDeal({ type: 'OnFailure', payload: [] });
      }
    }
    fetchData();
    fetchAllRiders();
    fetchAllWaiters();
    fetchAllTables();
    fetchAllSearchData();
    fetchAllDeals();
    fetchAllCities();
    fetchAllBranches();
    fetchAllAreas();
  }, [
    dispatch,
    dispatchRider,
    dispatchWaiter,
    dispatchTable,
    dispatchData,
    dispatchDeal,
    dispatchCity,
    dispatchBranch,
    dispatchArea,
  ]);

  return (
    <CTX.Provider
      value={{
        allProducts,
        allRiders,
        allWaiters,
        allTables,
        allData,
        allDeals,
        allCity,
        allBranch,
        allArea,
      }}
    >
      {prop.children}
    </CTX.Provider>
  );
}
Store.propTypes = {
  children: PropTypes.any,
};

export default Store;
