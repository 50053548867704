import React from 'react';
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { randomNumberGenerator } from '../../../Common/constants';
function Select(props) {
  const {
    name,
    label,
    value,
    error = null,
    onChange,
    options,
    isNoneAllowed = true,
    ...other
  } = props;
  return (
    <FormControl variant="outlined" {...(error && { error: true })} {...other}>
      <InputLabel className="asterisk">{label}</InputLabel>
      <MuiSelect label={label} name={name} value={value} onChange={onChange}>
        {isNoneAllowed ? (
          <MenuItem key={'#'} value={'#'}>
            None
          </MenuItem>
        ) : (
          <></>
        )}
        {options &&
          options.map((item) => (
            <MenuItem
              key={item._id + randomNumberGenerator() * 100}
              value={item._id}
            >
              {item.name}
            </MenuItem>
          ))}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
Select.propTypes = {
  name: PropTypes.any,
  label: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.any,
  error: PropTypes.any,
  isNoneAllowed: PropTypes.bool,
  options: PropTypes.any,
};
export default Select;
