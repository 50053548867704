import React, { useState, useEffect } from 'react';
import PageTitle from '../../Layout/PageTitle';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import { Grid, Paper, Toolbar } from '@material-ui/core';
import Controls from '../../Utilities/Controls/Controls';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import * as BranchMappingService from './BranchMappingService';
import { useHistory } from 'react-router-dom';
import CircularLoader from '../../Utilities/ReuseableCommponents/CircularLoader';
import { getAllCitiesForDropDown } from '../City/CityService';
import GenericTable from '../../Utilities/ReuseableCommponents/TableReport/GenericTable';
import useStyles from '../../../Style/AppStylesAdmin';
import activityMonitoring from '../../../Common/functions';

const headCells = [
  { id: 'area', label: 'Area', align: 'left' },
  { id: 'city', label: 'City', align: 'left' },
  { id: 'branch', label: 'Branch', align: 'left' },
  { id: 'alternateBranch1', label: 'Alternative Branch 1', align: 'left' },
  { id: 'alternateBranch2', label: 'Alternative Branch 2', align: 'left' },
  { id: 'alternateBranch3', label: 'Alternative Branch 3', align: 'left' },
  { id: 'action', label: 'Action', align: 'left', type: 'remove' },
];

export default function BranchMapping() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState(false);
  const history = useHistory();
  const recordForEdit = null;
  const [city, setCity] = useState('');
  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [area, setArea] = useState('');
  const [branches, setBranches] = useState([]);
  const [branch, setBranch] = useState('');
  const [records, setRecords] = useState([]);
  const [recordsCount, setRecordsCount] = useState([]);
  const rowOnClick = {
    path: '/BranchMappingDetails',
    header: 'View/Edit Area',
    updateFlag: true,
    property: ['recordForEdit'],
  };
  let apiResponseDto = {
    data: [],
    error: null,
    loading: false,
    statusCode: 0,
  };
  function formatBranchMappingInfo(branchMapping) {
    if (branchMapping && branchMapping.branchRef) {
      const branchName = branchMapping.branchRef.name;
      const branchDeliveryCharges = branchMapping.deliveryCharges
        ? branchMapping.deliveryCharges
        : 0;
      const branchDeliveryTime = branchMapping.delieveryTime
        ? branchMapping.delieveryTime
        : 0;
      return `${branchName}  Rs.${branchDeliveryCharges} | ${branchDeliveryTime} min`;
    }
    return '';
  }
  function getRecords(page, rowsPerPage, sortDirection, sortColumnName) {
    let searchString = '&branchMapping=true';

    setIsLoading(true);
    if (city) {
      searchString += `&cityRef=${city._id}`;
    }
    if (area) {
      searchString += `&areaRef=${area._id}`;
    }
    if (branch) {
      searchString += `&branchRef=${branch._id}`;
    }

    BranchMappingService.getAllArea(
      page,
      rowsPerPage,
      sortDirection,
      sortColumnName,
      searchString
    ).then((Response) => {
      setIsLoading(false);
      if (Response.data) {
        const branchMappingRecords = Response.data.data.filter(
          (item) => item.branchMapping.length > 0
        );
        Response.data.data.map((element) => {
          element.area = element.name;
          element.city = element.cityRef ? element.cityRef.name || '' : '';

          if (
            element &&
            element.branchMapping &&
            element.branchMapping.length > 0
          ) {
            element.branch = formatBranchMappingInfo(element.branchMapping[0]);
          } else {
            element.branch = '';
          }

          if (
            element &&
            element.branchMapping &&
            element.branchMapping.length > 1
          ) {
            element.alternateBranch1 = formatBranchMappingInfo(
              element.branchMapping[1]
            );
          } else {
            element.alternateBranch2 = '';
          }
          if (
            element &&
            element.branchMapping &&
            element.branchMapping.length > 2
          ) {
            element.alternateBranch2 = formatBranchMappingInfo(
              element.branchMapping[2]
            );
          } else {
            element.alternateBranch2 = '';
          }
          if (
            element &&
            element.branchMapping &&
            element.branchMapping.length > 3
          ) {
            element.alternateBranch3 = formatBranchMappingInfo(
              element.branchMapping[3]
            );
          } else {
            element.alternateBranch3 = '';
          }
        });
        setRecords(branchMappingRecords);
        setRecordsCount(Response.data.count);
        if (Response.data.count == 0) {
          setNotify({
            isOpen: true,
            message: 'No Data Found',
            type: 'error',
          });
        }
      } else console.error('Error While Fetching Data: ', Response.error);
    });
  }

  function BindCity() {
    getAllCitiesForDropDown().then((Response) => {
      if (Response.data) {
        //Map data array with Generic List Object with Properties id,text
        //Dropdown only accepts list of Object with Properties id,text
        var result = Response.data.data.map((dataArrayRow) => ({
          _id: dataArrayRow._id,
          name: dataArrayRow.name,
        }));
        setCities(result);
        if (Response.data.count === 0) {
          setNotify({
            isOpen: true,
            message: 'No City Found',
            type: 'error',
          });
        }
      } else {
        console.error('Unable To Get Records');
      }
    });
  }

  function BindArea() {
    BranchMappingService.getAllAreasForDropDown().then((Response) => {
      if (Response.data) {
        var result = getArray(Response.data.data);
        setAreas(result);
        if (Response.data.count === 0) {
          setNotify({
            isOpen: true,
            message: 'No Area Found',
            type: 'error',
          });
        }
      } else {
        console.error('Unable To Get Records');
      }
    });
  }

  function BindBranch() {
    BranchMappingService.getAllBranches().then((Response) => {
      if (Response.data) {
        var result = getArray(Response.data.data);
        setBranches(result);
        if (Response.data.count === 0) {
          setNotify({
            isOpen: true,
            message: 'No Branch Found',
            type: 'error',
          });
        }
      } else {
        console.error('Unable To Get Records');
      }
    });
  }

  useEffect(() => {
    getRecords(0, 5, -1, 'createdAt');

    BindCity();
    BindArea();
    BindBranch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getArray(list) {
    //Map data array with Generic List Object with Properties id,text
    //Dropdown only accepts list of Object with Properties id,text
    return list.map((dataArrayRow) => ({
      _id: dataArrayRow._id,
      name: dataArrayRow.name,
      cityRef: dataArrayRow.cityRef,
    }));
  }
  function getFilteredRecords(e) {
    e.preventDefault();
    getRecords(0, 5, -1, 'createdAt');
  }

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const onDelete = async (elem) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    elem = {
      ...elem,
      branchMapping: [],
    };
    try {
      apiResponseDto.data = null;
      apiResponseDto.error = null;
      apiResponseDto = await BranchMappingService.updateArea(elem).then(
        (Response) => {
          return Response;
        }
      );

      if (apiResponseDto.data) {
        getRecords(0, 5, -1, 'createdAt');
        setNotify({
          isOpen: true,
          message: 'Deleted Successfully',
          type: 'success',
        });
      }
      if (apiResponseDto.error) {
        if (apiResponseDto.statusCode === 403) {
          setNotify({
            isOpen: true,
            message:
              'Unable To Delete Category : Products are also Associated With This Category',
            type: 'error',
          });
        } else {
          setNotify({
            isOpen: true,
            message: 'Unable To Process Request',
            type: 'error',
          });
        }
      }
    } catch (e) {
      setNotify({
        isOpen: true,
        message: 'Unable To Process Request',
        type: 'error',
      });
    }
  };

  useEffect(() => {
    activityMonitoring('BranchMapping');
  }, []);
  return (
    <>
      <PageTitle
        title="Area-Branch Mapping Report"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="branchAreaMappingHeading"
      />
      <Paper elevation={6} square className={classes.PaperMargin}>
        <Toolbar disableGutters>
          <form onSubmit={getFilteredRecords} style={{ width: '100%' }}>
            <Grid container>
              <Grid item xs={12} sm={12} md={2}>
                <div className={classes.searchDropdown}>
                  <Controls.EditableDropDown
                    id="txtSearchcity"
                    label="City"
                    variant="outlined"
                    data={cities}
                    value={city}
                    onChange={(e, newValue) => {
                      setCity(newValue);
                    }}
                    autoComplete="off"
                  />{' '}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <div className={classes.searchDropdown}>
                  <Controls.EditableDropDown
                    id="txtSearchArea"
                    label="Area"
                    variant="outlined"
                    data={
                      city == '' || city == null
                        ? areas
                        : getArea(areas, city._id)
                    }
                    value={area}
                    onChange={(e, newValue) => setArea(newValue)}
                    autoComplete="off"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <div className={classes.searchDropdown}>
                  <Controls.EditableDropDown
                    id="txtSearchBranch"
                    label="Branch"
                    variant="outlined"
                    data={
                      city == '' || city == null
                        ? branches
                        : getBranch(branches, city._id)
                    }
                    value={branch}
                    onChange={(e, newValue) => setBranch(newValue)}
                    autoComplete="off"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <Controls.Button
                  text="Search"
                  variant="contained"
                  color="primary"
                  id="btnCatSearch"
                  className={classes.searchButton}
                  startIcon={<SearchIcon />}
                  type="submit"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Controls.Button
                  text="Add New"
                  variant="contained"
                  color="primary"
                  id="btnAddCategory"
                  startIcon={<AddIcon />}
                  className={classes.newButton}
                  onClick={() => {
                    history.push({
                      pathname: '/BranchMappingDetails',
                      state: {
                        pageHeaderTitile: 'Branch-Area Mapping',
                        updateFlag: false,
                        recordForEdit,
                      },
                    });
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </Toolbar>
        <CircularLoader isload={isLoading} />
        <GenericTable
          data={records}
          TableId={'branchMappingTable'}
          TableTitle={'Branch Mapping Table'}
          cellData={[
            { name: 'area', type: 'string' },
            { name: 'city', type: 'string' },
            { name: 'branch', type: 'string' },
            { name: 'alternateBranch1', type: 'string' },
            { name: 'alternateBranch2', type: 'string' },
            { name: 'alternateBranch3', type: 'string' },
            { name: 'remove', type: 'remove' },
          ]}
          headCells={headCells}
          recordsCount={recordsCount}
          getRecordsFn={getRecords}
          rowDeleteDialog={setConfirmDialog}
          rowDeletefn={onDelete}
          rowOnClick={rowOnClick}
          isLoading={isLoading}
        />
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}

function getArea(areasList, cityId) {
  let areaArr = [];

  areaArr = areasList.filter((item) => item.cityRef == cityId);

  return areaArr;
}
function getBranch(branchList, cityId) {
  let branchArr = [];

  branchArr = branchList.filter((item) => item.cityRef == cityId);

  return branchArr;
}
