import authHeader from '../../../Services/auth-header';
import { callAPI } from '../../../Services/ApiCaller';
import { API_VERSION } from '../../../Common/constants';

export async function getAllCategories(
  page,
  rowsPerPage,
  sortDirection,
  sortColumnName,
  searchString
) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  let rowsFrom = page * rowsPerPage;

  let sortOrderdir = sortDirection === 'asc' ? 1 : -1;
  return callAPI(
    `api/${API_VERSION}/categories`,
    null,
    headerObject,
    'GET',
    `?$orderBy=${sortColumnName}&$orderDir=${sortOrderdir}&$skip=${rowsFrom}&$top=${rowsPerPage}${searchString}`
  ).then((Responce) => {
    return Responce;
  });
}

export async function getAllCategoriesForDropDown() {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  return callAPI(
    `api/${API_VERSION}/categories`,
    null,
    headerObject,
    'GET',
    `?$orderBy=name&$orderDir=1&$top=50000`
  ).then((Responce) => {
    return Responce;
  });
}

export async function insertCategory(category) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  var categoryData = {
    name: category.name.trim(),
    status: 'active',
    commission: category.commission ? category.commission : 0,
  };
  return callAPI(
    `api/${API_VERSION}/categories`,
    categoryData,
    headerObject,
    'POST',
    ''
  ).then((Responce) => {
    return Responce;
  });
}

export async function deleteCategory(_id) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  return callAPI(
    `api/${API_VERSION}/categories/`,
    null,
    headerObject,
    'DELETE',
    _id
  ).then((Responce) => {
    return Responce;
  });
}

export async function updateCategory(category) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  var categoryUpdate = {
    name: category.name,
    commission: category.commission ? category.commission : 0,
  };

  return callAPI(
    `api/${API_VERSION}/categories`,
    categoryUpdate,
    headerObject,
    'PATCH',
    `/${category._id}`
  ).then((Responce) => {
    return Responce;
  });
}

export async function patchCategory(id, categoryObject) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  return callAPI(
    `api/${API_VERSION}/categories`,
    categoryObject,
    headerObject,
    'PATCH',
    `/${id}`
  ).then((Responce) => {
    return Responce;
  });
}
