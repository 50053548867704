import React from 'react';
import {
  InputAdornment,
  TextField,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Grid,
  IconButton,
} from '@material-ui/core';
import {
  Clear as ClearIcon,
  Stop as StopIcon,
  Print as PrintIcon,
  VisibilityRounded as VisibilityRoundedIcon,
  VisibilityOffRounded as VisibilityOffRoundedIcon,
  ArrowBackRounded as ArrowBackRoundedIcon,
} from '@material-ui/icons';
import { getFormattedDateTime as ISOdateFormatter } from '../../../../../Common/constants';
import PropTypes from 'prop-types';

const EndShiftModalBody = ({
  classes,
  modalStyle,
  handleClose5,
  endShiftSaleDetail,
  expectedAmount,
  closingAmount,
  setClosingAmount,
  internalSaleAmount,
  setInternalSaleAmount,
  comments,
  setComments,
  handleShiftEnd,
  PrintEndShift,
  isLoaded,
  handleFetchSales,
  hideSales,
  display,
  EndShiftSalesDetails,
  endShiftSaleDetailTab,
  TblContainer,
  TblHead,
  recordsAfterPagingAndSorting,
  TblPagination,
  records,
  endShiftSaleDetailTabBack,
  Error,
  totalExpense,
}) => {
  const openingAmount = JSON.parse(localStorage.getItem('_IdShift'))
    .openingAmount;
  let hideFeatures = JSON.parse(localStorage.getItem('_HideFeature')) || [];
  let expenseFeature = hideFeatures.find((hf) => hf.name === 'Expense');
  const isExpenseFeature =
    expenseFeature && expenseFeature.status ? true : false;

  return (
    <>
      <div style={modalStyle} className={classes.paper2}>
        <h2 id="simple-modal-title">End Shift</h2>
        <IconButton
          style={{
            top: '0px',
            right: '0px',
            position: 'absolute',
          }}
          color="inherit"
          aria-label="menu"
          onClick={handleClose5}
          id="closeEndShift"
        >
          <ClearIcon />
        </IconButton>
        <div style={{ display: endShiftSaleDetail }}>
          <div className={classes.endShiftMain}>
            <div className={classes.endShiftCalc}>
              <TextField
                id="txtPOSEndShiftOpeningAmt"
                label="Opening Amount"
                variant="outlined"
                color="primary"
                value={parseFloat(openingAmount).toFixed(2) || 0}
                margin="normal"
                style={{ width: '100%' }}
                InputProps={{
                  classes: {
                    input: classes.TextFieldresize,
                  },
                  startAdornment: (
                    <InputAdornment position="start">Rs.</InputAdornment>
                  ),
                }}
                autoComplete="off"
              />
              <br />
              {/* TODO: expectedAmount field should be calculated here via netSales + openingAmount - this is due
              for refactoring later */}
              <TextField
                id="txtPOSEndShiftTotalNetSaleAmt"
                label="Total Net Sale Amount"
                variant="outlined"
                color="primary"
                value={
                  parseFloat(
                    expectedAmount - openingAmount + totalExpense
                  ).toFixed(2) || 0
                }
                margin="normal"
                style={{ width: '100%' }}
                InputProps={{
                  classes: {
                    input: classes.TextFieldresize,
                  },
                  startAdornment: (
                    <InputAdornment position="start">Rs.</InputAdornment>
                  ),
                }}
                autoComplete="off"
              />
              <br />
              {isExpenseFeature && (
                <>
                  <TextField
                    id="txtPOSBussinesExpense"
                    label="Bussines Expense"
                    variant="outlined"
                    color="primary"
                    value={totalExpense}
                    margin="normal"
                    style={{ width: '100%' }}
                    InputProps={{
                      classes: {
                        input: classes.TextFieldresize,
                      },
                      startAdornment: (
                        <InputAdornment position="start">Rs.</InputAdornment>
                      ),
                    }}
                    autoComplete="off"
                  />
                  <br />
                </>
              )}

              <TextField
                id="txtPOSEndShiftExpectedAmt"
                label="Expected Amount"
                variant="outlined"
                color="primary"
                value={expectedAmount}
                margin="normal"
                style={{ width: '100%' }}
                InputProps={{
                  classes: {
                    input: classes.TextFieldresize,
                  },
                  startAdornment: (
                    <InputAdornment position="start">Rs.</InputAdornment>
                  ),
                }}
                autoComplete="off"
              />
              <br />
              <TextField
                id="txtPOSEndShiftClosingAmt"
                label="Closing Amount"
                variant="outlined"
                color="primary"
                margin="normal"
                required
                error={closingAmount === '' && !!Error.closingAmount}
                helperText={Error.closingAmount}
                style={{ width: '100%' }}
                value={closingAmount}
                type="number"
                onChange={(e) => {
                  console.log(e.target.value);

                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 6);
                  setClosingAmount(e.target.value);
                }}
                InputProps={{
                  classes: {
                    input: classes.TextFieldresize,
                  },
                  startAdornment: (
                    <InputAdornment position="start">Rs.</InputAdornment>
                  ),
                }}
                autoComplete="off"
              />
              <br />
              <TextField
                id="txtPOSEndShiftInternalSales"
                label="Internal Sales"
                variant="outlined"
                color="primary"
                margin="normal"
                style={{ width: '100%' }}
                required
                type="number"
                error={internalSaleAmount === '' && !!Error.internalSaleAmount}
                helperText={
                  internalSaleAmount === '' && Error.internalSaleAmount
                }
                value={internalSaleAmount}
                onChange={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 6);
                  setInternalSaleAmount(e.target.value);
                }}
                InputProps={{
                  classes: {
                    input: classes.TextFieldresize,
                  },
                  startAdornment: (
                    <InputAdornment position="start">Rs.</InputAdornment>
                  ),
                }}
                autoComplete="off"
              />
              <br />
              <TextField
                id="txtPOSEndShiftDifference"
                label="Differnce"
                variant="outlined"
                color="primary"
                margin="normal"
                style={{ width: '100%' }}
                value={
                  parseFloat(
                    parseFloat(!expectedAmount ? '0' : expectedAmount) -
                      parseFloat(!closingAmount ? '0' : closingAmount)
                  ).toFixed(2) || 0
                }
                InputProps={{
                  classes: {
                    input: classes.TextFieldresize,
                  },
                  startAdornment: (
                    <InputAdornment position="start">Rs.</InputAdornment>
                  ),
                }}
                autoComplete="off"
              />
              <br />
              <TextField
                id="txtPOSEndShiftComments"
                margin="normal"
                label="Comments"
                variant="outlined"
                color="primary"
                value={comments}
                style={{ width: '100%' }}
                onChange={(e) => setComments(e.target.value)}
                InputProps={{
                  classes: {
                    input: classes.TextFieldresize,
                  },
                }}
                autoComplete="off"
              />
              <br />

              <div
                className={classes.flex}
                style={{
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<StopIcon />}
                  style={{
                    height: '40px',
                    width: 'auto',
                    top: '10px',
                    position: 'relative',
                  }}
                  onClick={handleShiftEnd}
                  id="btnPOSEndShiftSave"
                >
                  End Shift
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<PrintIcon />}
                  style={{
                    height: '40px',
                    width: 'auto',
                    top: '10px',
                    position: 'relative',
                    padding: '0px 25px 0px !important',
                  }}
                  onClick={PrintEndShift}
                  id="btnPOSEndShiftSave"
                >
                  Print
                </Button>
                {!isLoaded ? (
                  <Button
                    variant="contained"
                    style={{
                      height: '40px',
                      width: 'auto',
                      top: '10px',
                      position: 'relative',
                      padding: '0px 25px 0px !important',
                    }}
                    onClick={handleFetchSales}
                    id="btnPOSEndShiftViewSales"
                  >
                    <VisibilityRoundedIcon style={{ marginRight: '5px' }} />
                    View Sales
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    style={{
                      height: '40px',
                      width: 'auto',
                      top: '10px',
                      position: 'relative',
                    }}
                    onClick={hideSales}
                    id="btnPOSEndShiftHideSales"
                  >
                    <VisibilityOffRoundedIcon style={{ marginRight: '5px' }} />
                    Hide Sales
                  </Button>
                )}
              </div>
            </div>

            {isLoaded && (
              <div
                className={classes.endShiftDetail}
                style={{
                  // width: '550px',
                  display: display,
                  overflowX: 'auto',
                  overflowY: 'auto',
                  height: '450px',
                  paddingLeft: '20px',
                }}
              >
                <TblContainer className={classes.tableContent}>
                  <Table
                    id="posEndShiftSalesTable"
                    className={classes.toTable}
                    aria-label="simple table"
                  >
                    <TblHead>
                      <TableRow>
                        <TableCell>S.No</TableCell>
                        <TableCell>Date & Time</TableCell>
                        <TableCell>Items Sold</TableCell>
                        <TableCell>Items Returned</TableCell>
                        <TableCell align="center">Net Sale Amount</TableCell>
                      </TableRow>
                    </TblHead>
                    <TableBody id="posEndShiftSalesTbody">
                      {recordsAfterPagingAndSorting().map((row, index) => (
                        <TableRow
                          key={index}
                          id={'posEndShiftSalesTrow' + index}
                          onClick={() => EndShiftSalesDetails(row)}
                        >
                          <TableCell>{index + 1}</TableCell>
                          {row.clientCreatedDate ? (
                            <TableCell>
                              {ISOdateFormatter(row.clientCreatedDate)}
                            </TableCell>
                          ) : (
                            <TableCell>
                              {ISOdateFormatter(row.createdAt)}
                            </TableCell>
                          )}
                          <TableCell align="center">
                            {row.itemsSold.length}
                          </TableCell>
                          <TableCell align="center">
                            {row.itemsReturned.length}
                          </TableCell>
                          <TableCell align="center">
                            Rs. {row.saleAmountTotal}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TblContainer>
                <TblPagination />
              </div>
            )}
          </div>
        </div>
        {endShiftSaleDetailTab === 'block' && (
          <div style={{ display: endShiftSaleDetailTab }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled
                  name="shiftNumber"
                  label="Shift Number"
                  variant="outlined"
                  fullWidth
                  value={records.shiftNumber}
                  InputProps={{
                    readOnly: true,
                    disabled: true,
                  }}
                  id="txtSalesShiftNumber"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled
                  name="clientCreatedAt"
                  label="Date & Time"
                  variant="outlined"
                  fullWidth
                  value={ISOdateFormatter(records.clientUpdatedAt)}
                  InputProps={{
                    readOnly: true,
                    disabled: true,
                  }}
                  id="txtDateAndTime"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled
                  name="invoiceNumber"
                  label="Invoice Number"
                  variant="outlined"
                  fullWidth
                  value={records.invoiceNumber}
                  InputProps={{
                    readOnly: true,
                    disabled: true,
                  }}
                  id="txtInvoiceNumber"
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  disabled
                  fullWidth
                  name="itemsSold"
                  label="Items Sold"
                  variant="outlined"
                  value={records.itemsSold.length}
                  InputProps={{
                    readOnly: true,
                    disabled: true,
                  }}
                  id="txtItemsSold"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled
                  fullWidth
                  name="itemsReturned"
                  label="Items Returned"
                  variant="outlined"
                  value={records.itemsReturned.length}
                  InputProps={{
                    readOnly: true,
                    disabled: true,
                  }}
                  id="txtItemsReturned"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled
                  fullWidth
                  name="overallSaleDiscountAmount"
                  label="Overall Discount"
                  variant="outlined"
                  value={
                    records.overallSaleDiscountAmount
                      ? records.overallSaleDiscountAmount
                      : 0
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rs.</InputAdornment>
                    ),
                    readOnly: true,
                    disabled: true,
                  }}
                  id="txtSalesOverallSaleDiscountAmount"
                  autoComplete="off"
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  disabled
                  fullWidth
                  name="saleAmountSumOfItems"
                  label="Total Sale Amount"
                  variant="outlined"
                  value={
                    records.saleAmountSumOfItems
                      ? records.saleAmountSumOfItems.toLocaleString('en')
                      : 0
                  }
                  InputProps={{
                    readOnly: true,
                    disabled: true,
                    startAdornment: (
                      <InputAdornment position="start">Rs.</InputAdornment>
                    ),
                  }}
                  id="txtSaleAmountSumOfItems"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled
                  fullWidth
                  variant="outlined"
                  name="returnedAmountSumOfItems"
                  label="Total Return Amount"
                  value={
                    records.returnedAmountSumOfItems
                      ? records.returnedAmountSumOfItems.toLocaleString('en')
                      : 0
                  }
                  InputProps={{
                    readOnly: true,
                    disabled: true,
                    startAdornment: (
                      <InputAdornment position="start">Rs.</InputAdornment>
                    ),
                  }}
                  id="txtReturnedAmountSumOfItems"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled
                  fullWidth
                  variant="outlined"
                  name="netSaleTotal"
                  label="Net Sale Amount"
                  value={(
                    records.saleAmountSumOfItems -
                    (records.overallSaleDiscountAmount +
                      (records.returnedAmountSumOfItems
                        ? records.returnedAmountSumOfItems
                        : 0))
                  ).toLocaleString('en')}
                  InputProps={{
                    readOnly: true,
                    disabled: true,
                    startAdornment: (
                      <InputAdornment position="start">Rs.</InputAdornment>
                    ),
                  }}
                  id="txtNetSaleTotal"
                />
              </Grid>
            </Grid>
            <h2>Items Sold</h2>
            <TableContainer
              style={{
                maxHeight: '330px',
              }}
            >
              <Table
                id="inventoryUpdateTable"
                className={classes.toTable}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Item Name</TableCell>
                    <TableCell>Item Category</TableCell>
                    <TableCell align="center">Qty Sold</TableCell>
                    <TableCell align="center">Qty Returned</TableCell>
                    <TableCell align="center">Discount Price</TableCell>
                    <TableCell align="center">Final Sale Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody id="inventoryUpdateTboday">
                  {records.itemsSold.map((item, index) => (
                    <TableRow
                      key={item._id}
                      id={`salesDetailsListTrow${index}`}
                    >
                      <TableCell>{item.itemRef.name}</TableCell>
                      <TableCell>
                        {item.itemRef.categoriesRef[0].name}
                      </TableCell>
                      <TableCell align="center">{item.quantity}</TableCell>
                      <TableCell align="center">
                        {itemReturnQty(records, item.itemRef._id)}
                      </TableCell>
                      <TableCell align="center">
                        Rs. {item.discountAmount}
                      </TableCell>
                      <TableCell align="center">
                        Rs. {item.saleAmountTotal}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid item xs={3}>
              <Button
                variant="contained"
                style={{
                  marginLeft: 'auto',
                  marginRight: '5px',
                  marginTop: '15px',
                }}
                onClick={endShiftSaleDetailTabBack}
                id="btn_shiftend_cancel"
              >
                <ArrowBackRoundedIcon
                  style={{
                    marginRight: '5px',
                  }}
                />
                Back
              </Button>
            </Grid>
          </div>
        )}
      </div>
    </>
  );
};
function itemReturnQty(records, itemId) {
  let returnQty = 0;
  records.itemsReturned.map((it) => {
    if (it.itemRef._id === itemId) {
      returnQty = it.quantity;
    }
  });
  return returnQty;
}

EndShiftModalBody.propTypes = {
  classes: PropTypes.any,
  modalStyle: PropTypes.any,
  handleClose5: PropTypes.any,
  endShiftSaleDetail: PropTypes.any,
  expectedAmount: PropTypes.any,
  closingAmount: PropTypes.any,
  setClosingAmount: PropTypes.any,
  internalSaleAmount: PropTypes.any,
  setInternalSaleAmount: PropTypes.any,
  comments: PropTypes.any,
  setComments: PropTypes.any,
  handleShiftEnd: PropTypes.any,
  PrintEndShift: PropTypes.any,
  isLoaded: PropTypes.any,
  handleFetchSales: PropTypes.any,
  hideSales: PropTypes.any,
  display: PropTypes.any,
  EndShiftSalesDetails: PropTypes.any,
  endShiftSaleDetailTab: PropTypes.any,
  TblContainer: PropTypes.any,
  TblHead: PropTypes.any,
  recordsAfterPagingAndSorting: PropTypes.any,
  TblPagination: PropTypes.any,
  records: PropTypes.any,
  endShiftSaleDetailTabBack: PropTypes.any,
  Error: PropTypes.object,
  totalExpense: PropTypes.any,
};
export default EndShiftModalBody;
