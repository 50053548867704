import React from 'react';
import { Modal } from '@material-ui/core';
import NayaPayModalBody from './NayaPayModalBody';

import PropTypes from 'prop-types';
const NayaPayModal = (props) => {
  return (
    <div>
      <Modal
        open={props.openNayaPay}
        onClose={props.modleCloseNayaPay}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div>
          <NayaPayModalBody {...props} />
        </div>
      </Modal>
    </div>
  );
};
NayaPayModal.propTypes = {
  openNayaPay: PropTypes.any,
  modleCloseNayaPay: PropTypes.any,
  props: PropTypes.any,
};
export default NayaPayModal;
