import React from 'react';
import { Modal } from '@material-ui/core';
import StartShiftModalBody from './StartShiftModalBody';

import PropTypes from 'prop-types';
const StartShiftModal = (props) => {
  return (
    <div>
      <Modal
        open={props.open4}
        onClose={props.handleClose4}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div>
          <StartShiftModalBody {...props} />
        </div>
      </Modal>
    </div>
  );
};
StartShiftModal.propTypes = {
  open4: PropTypes.any,
  handleClose4: PropTypes.any,
  props: PropTypes.any,
};
export default StartShiftModal;
