import React from 'react';
import './GenericReportTemplate.css';
import { getFormattedDateTime } from '../../../../Common/constants';
import PropTypes from 'prop-types';
class GenericReportTemplate extends React.Component {
  render() {
    const companyName = localStorage.getItem('CompanyName');
    const companyLogo = localStorage.getItem('Company Logo');
    const cashierName = localStorage.getItem('username');
    const currentDate = getFormattedDateTime(new Date().toISOString());

    return (
      <div>
        {companyLogo && (
          <div style={{ textAlign: 'center' }}>
            <img
              variant="square"
              style={{ width: 100, height: 100 }}
              alt="Company Logo"
              src={companyLogo}
            />
          </div>
        )}

        <p
          style={{
            fontWeight: 'bold',
            textAlign: 'center',
            textTransform: 'uppercase',
            fontSize: '24px',
            marginTop: '10px',
          }}
        >
          {companyName}
        </p>

        <h2
          style={{
            textAlign: 'center',
            textTransform: 'uppercase',
            marginTop: '0px',
            marginBottom: '10px',
            fontSize: '16px',
            fontWeight: 'bold',
          }}
        >
          {this.props.Data.ReportTitle}
        </h2>
        <table>
          <tbody>
            <tr>
              <td style={{ padding: '0px 10px 10px' }}>
                Printed By: {cashierName}
              </td>
            </tr>
            <tr>
              <td style={{ padding: '0px 10px 10px' }}>
                Print Date: {currentDate}
              </td>
              {this.props.Data.ReportDuration &&
                this.props.Data.ReportDuration.length > 5 && (
                  <td style={{ padding: '0px 10px 10px', textAlign: 'right' }}>
                    Duration: {this.props.Data.ReportDuration}
                  </td>
                )}
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr style={{ lineHeight: '20px' }}>
              {this.props.Data.ReportHeader ? (
                this.props.Data.ReportHeader.map((heads, index) => (
                  <th id="report" key={'header' + index}>
                    {heads.label}
                  </th>
                ))
              ) : (
                <></>
              )}
            </tr>
          </thead>
          <tbody>
            {this.props.Data.ReportBody ? (
              this.props.Data.ReportBody.map((body, index) => (
                <tr style={{ lineHeight: '30px' }} key={'body' + index}>
                  {this.props.Data.ReportHeader.map((heads, index) => (
                    <td key={heads + index}>{Object.values(body)[index]}</td>
                  ))}
                </tr>
              ))
            ) : (
              <></>
            )}
          </tbody>
          <tfoot>
            <tr style={{ lineHeight: '30px' }}>
              {this.props.Data.ReportFooter ? (
                this.props.Data.ReportFooter.map((foot) =>
                  this.props.Data.ReportHeader.map((heads, index) => (
                    <td key={heads + index}>{Object.values(foot)[index]}</td>
                  ))
                )
              ) : (
                <></>
              )}
            </tr>
          </tfoot>
        </table>
        <p style={{ textAlign: 'center' }}>Software provided by: Concave POS</p>
      </div>
    );
  }
}
GenericReportTemplate.propTypes = {
  Data: PropTypes.any,
};
export default GenericReportTemplate;
