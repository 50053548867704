import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import Controls from '../../Utilities/Controls/Controls';
import { useForm, Form } from '../../Utilities/ReuseableCommponents/useForm';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import PageTitle from '../../Layout/PageTitle';
import { useLocation, useHistory } from 'react-router-dom';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import * as RiderService from './RiderService';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: '75%',
  },
  newButton: {
    position: 'absolute',
    right: '0',
  },
  PaperMargin: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  invalid: {
    '&:invalid': {
      border: 'red solid 2px',
    },
  },
}));
const initialFValues = {
  _id: 0,
  name: '',
  cnic: '',
  address: '',
  cellNo: '',
  vehicleNo: '',
  status: true,
  createdBy: '',
  createdAt: '',
  updatedAt: '',
  __v: 0,
};
let Responce = {
  data: null,
  error: null,
  loading: false,
};

let apiResponce = {
  data: null,
  error: null,
  loading: false,
  statusCode: 0,
};
function RiderDetails() {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const {
    values,
    setValues,

    handleInputChange,
    resetForm,
  } = useForm(initialFValues);

  const addOrEdit = async (rider, resetForm) => {
    if (
      rider._id === 0 &&
      rider.name &&
      rider.cnic &&
      rider.cellNo &&
      rider.address &&
      rider.vehicleNo
    ) {
      try {
        Responce.data = null;
        Responce.error = null;
        Responce = await RiderService.insertRider(rider).then((Responce) => {
          return Responce;
        });

        if (Responce.data) {
          resetForm();

          setNotify({
            isOpen: true,
            message: 'Submitted Successfully',
            type: 'success',
          });
        }

        if (Responce.error) {
          setNotify({
            isOpen: true,
            message: 'Unable To Process Request',
            type: 'error',
          });
        }
      } catch (e) {
        setNotify({
          isOpen: true,
          message: 'Unable To Process Request',
          type: 'error',
        });
      }
    }
    if (
      rider._id != 0 &&
      rider.name &&
      rider.cnic &&
      rider.cellNo &&
      rider.address &&
      rider.vehicleNo
    ) {
      try {
        apiResponce.data = null;
        apiResponce.error = null;
        apiResponce = await RiderService.updateRider(rider).then((Responce) => {
          return Responce;
        });
        if (apiResponce.data) {
          resetForm();
          setNotify({
            isOpen: true,
            message: 'Rider Detail Updated Successfully',
            type: 'success',
          });
        }
        if (apiResponce.error) {
          setNotify({
            isOpen: true,
            message: 'Unable To Process Request',
            type: 'error',
          });
        }
      } catch (e) {
        setNotify({
          isOpen: true,
          message: 'Unable To Process Request',
          type: 'error',
        });
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    addOrEdit(values, resetForm);
  };

  useEffect(() => {
    if (location.state.recordForEdit != null)
      setValues({ ...location.state.recordForEdit });
  }, [location.state.recordForEdit]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <PageTitle
        title={location.state.pageHeaderTitile}
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="riderDetailsHeading"
      />
      <Paper elevation={0} square className={classes.PaperMargin}>
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <Controls.Input
                name="name"
                label="Rider Name"
                value={values.name}
                onChange={handleInputChange}
                id="txtRidName"
                required
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <Controls.Input
                name="cellNo"
                label="Cell No"
                helperText={'Mobile: 03XXYYYYYYY'}
                inputProps={{
                  className: classes.invalid,
                  pattern: '^[0][3][0-9]{9}$',
                }}
                value={values.cellNo}
                onChange={handleInputChange}
                id="txtRidCellNo"
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controls.Checkbox
                name="status"
                label="Active"
                value={values.status}
                onChange={handleInputChange}
                id="chkRidActive"
                required
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item sm={4}>
              <Controls.Input
                name="cnic"
                label="Rider Cnic"
                value={values.cnic}
                helperText={'CNIC Format: XXXXX-XXXXXXX-X'}
                onChange={handleInputChange}
                id="txtRidCnic"
                inputProps={{
                  className: classes.invalid,
                  maxLength: 15,
                  pattern: '^[0-9+]{5}-[0-9+]{7}-[0-9]{1}$',
                }}
                required
              />
            </Grid>
            <Grid item sm={4}>
              <Controls.Input
                name="vehicleNo"
                label="Vehicle"
                value={values.vehicleNo}
                onChange={handleInputChange}
                id="txtRidVehicle"
                required
              />
            </Grid>
            <Grid item sm={4}></Grid>
          </Grid>

          <Grid container>
            <Grid item sm={8}>
              <Controls.Input
                name="address"
                label="Rider's Home Address"
                value={values.address}
                onChange={handleInputChange}
                multiline
                id="txtRidAddress"
                required
              />
            </Grid>
          </Grid>

          <Grid container className={classes.ButtonsCotainerMargin}>
            <br></br>
            <Grid item xs={3} sm={4} align="left">
              <Controls.Button
                text="Cancel"
                color="default"
                onClick={history.goBack}
                startIcon={<CloseIcon />}
                id="btnCancelRid"
              />
            </Grid>
            <Grid item xs={6} sm={6}></Grid>
            <Grid item xs={1} sm={2} align="right">
              <div>
                <Controls.Button
                  type="submit"
                  startIcon={<SaveIcon />}
                  text="Save"
                  id="btnSaveRid"
                />
              </div>
            </Grid>
          </Grid>
        </Form>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}

export { RiderDetails as default };
