import React, { useState } from 'react';
import PageTitle from '../../Layout/PageTitle';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import {
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  TextField,
} from '@material-ui/core';
import Controls from '../../Utilities/Controls/Controls';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import * as departmentService from './DepartmentService';
import { useHistory } from 'react-router-dom';
import useTableServerSide from '../../Utilities/ReuseableCommponents/useTableServerSide';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Switch from '@material-ui/core/Switch';

const headCells = [
  { id: 'name', label: 'Department Name', align: 'left' },
  { id: 'status', label: 'Status', align: 'right' },
  { id: 'actions', label: 'Actions', disableSorting: true, align: 'right' },
];

let apiResponseDto = {
  data: [],
  error: null,
  loading: false,
  statusCode: 0,
};

export default function Departments() {
  const history = useHistory();
  const recordForEdit = null;
  const [records, setRecords] = useState([]);
  const [recordsCount, setRecordsCount] = useState(0);
  const [name, setName] = useState('');

  function getRecords(page, rowsPerPage, sortDirection, sortColumnName) {
    let searchString = '';
    let data = (async () => {
      if (name) {
        searchString += `&name=${name}`;
      }
      apiResponseDto = await departmentService
        .getAllDepartment(
          page,
          rowsPerPage,
          sortDirection,
          sortColumnName,
          searchString
        )
        .then((Response) => {
          return Response;
        });
      return apiResponseDto;
    })();
    data.then((Response) => {
      if (Response.data) {
        setRecords(Response.data.data);
        setRecordsCount(Response.data.count);
        if (Response.data.count == 0) {
          setNotify({
            isOpen: true,
            message: 'No Data Found',
            type: 'error',
          });
        }
      } else console.error('Error While Fetching Data: ', Response.error);
    });
  }

  const handleStatusChange = async (event, index) => {
    let cloneDatatableRecords = records;
    let id = cloneDatatableRecords[index]._id;
    let checkedStatus = event.target.checked;

    if (id) {
      try {
        let categoryStatusUpdate = {
          status: checkedStatus ? 'active' : 'disabled',
        };
        apiResponseDto.data = null;
        apiResponseDto.error = null;
        apiResponseDto = await departmentService
          .patchCategory(id, categoryStatusUpdate)
          .then((Responce) => {
            return Responce;
          });
        if (apiResponseDto.data) {
          let newStatusChangedRecord = {
            ...cloneDatatableRecords[index],
            status: checkedStatus ? 'active' : 'disabled',
          };
          cloneDatatableRecords[index] = newStatusChangedRecord;
          setRecords(cloneDatatableRecords);
          setNotify({
            isOpen: true,
            message: 'Department Status Updated Successfully',
            type: 'success',
            notificationTime: 15000,
          });
        }
        if (apiResponseDto.error) {
          setNotify({
            isOpen: true,
            message: 'Unable To Process Request',
            type: 'error',
          });
        }
      } catch (e) {
        setNotify({
          isOpen: true,
          message: 'Unable To Process Request',
          type: 'error',
        });
      }
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    getRecords(0, 5, -1, '');
  };

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTableServerSide(records, recordsCount, headCells, getRecords);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const onDelete = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    try {
      apiResponseDto.data = null;
      apiResponseDto.error = null;
      apiResponseDto = await departmentService
        .deleteDepartment(id)
        .then((Response) => {
          return Response;
        });
      if (apiResponseDto.data) {
        getRecords(0, 10, 1, 'name');
        setNotify({
          isOpen: true,
          message: 'Deleted Successfully',
          type: 'success',
        });
      }
      if (apiResponseDto.error) {
        if (apiResponseDto.statusCode === 403) {
          setNotify({
            isOpen: true,
            message:
              'Unable To Delete Department : Products are also Associated With This Department',
            type: 'error',
          });
        } else {
          setNotify({
            isOpen: true,
            message: 'Unable To Process Request',
            type: 'error',
          });
        }
      }
    } catch (e) {
      setNotify({
        isOpen: true,
        message: 'Unable To Process Request',
        type: 'error',
      });
    }
  };
  return (
    <>
      <PageTitle
        title="Department"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="departmentListHeading"
      />
      <Paper elevation={1} square className="paperMargin">
        <Toolbar>
          <form
            onSubmit={handleSearch}
            style={{ width: '100%', display: 'flex', alignItems: 'center' }}
          >
            <TextField
              id="txtSearchCat"
              label="Name"
              className="searchInput"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoComplete="off"
            />
            <Controls.Button
              text="Search"
              variant="contained"
              color="primary"
              id="btnCatSearch"
              startIcon={<SearchIcon />}
              type="submit"
            />
            <Controls.Button
              text="Add New"
              variant="contained"
              color="primary"
              id="btnAddDepartment"
              startIcon={<AddIcon />}
              className="newButton"
              onClick={() => {
                history.push({
                  pathname: '/DepartmentDetails',
                  state: {
                    pageHeaderTitile: 'Add New Department',
                    recordForEdit,
                  },
                });
              }}
            />
          </form>
        </Toolbar>
        <TblContainer>
          <TblHead />
          <TableBody id="catListTbody">
            {recordsAfterPagingAndSorting().map((item, index) => (
              <TableRow
                key={item._id}
                id={`catListTrow${index}`}
                onClick={(e) => {
                  if (!e.target.name) {
                    history.push({
                      pathname: '/DepartmentDetails',
                      state: {
                        pageHeaderTitile: 'View/Edit Department',
                        recordForEdit: item,
                      },
                    });
                  }
                }}
              >
                <TableCell id={`catName${index}`}>{item.name}</TableCell>

                <TableCell align="right">
                  <Switch
                    checked={
                      item.status
                        ? item.status === 'active'
                          ? true
                          : false
                        : false
                    }
                    name="switchStatus"
                    onChange={(e) => {
                      e.stopPropagation();
                      handleStatusChange(e, index);
                    }}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </TableCell>

                <TableCell align="right">
                  <Controls.ActionButton
                    color="secondary"
                    id={`btnDelCat${index}`}
                    onClick={(event) => {
                      event.stopPropagation();
                      setConfirmDialog({
                        isOpen: true,
                        title: 'Are you sure you want to delete this record?',
                        subTitle: "You can't undo this operation",
                        onConfirm: () => {
                          onDelete(item._id);
                        },
                      });
                    }}
                  >
                    <HighlightOffIcon />
                  </Controls.ActionButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        id_Yes="delCat"
        id_No="dontDelCat"
      />
    </>
  );
}
