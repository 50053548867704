import React from 'react';
import { Table, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: theme.spacing(1),
    '& thead th': {
      fontWeight: '500',
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
    },
    '& tbody td': {
      fontWeight: '300',
    },
    '& tbody tr:hover': {
      backgroundColor: '#f9f9f9',
      cursor: 'pointer',
    },
  },
}));
export default function TblContainer(props) {
  const classes = useStyles();
  return <Table className={classes.table}>{props.children}</Table>;
}

TblContainer.propTypes = {
  children: PropTypes.any,
};
