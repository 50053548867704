import React from 'react';
import { TextField, Button } from '@material-ui/core';
import { PlayArrowRounded as PlayArrowRoundedIcon } from '@material-ui/icons';

import PropTypes from 'prop-types';
const StartShiftModalBody = ({
  classes,
  openingamount,
  setOpeningamount,
  handleOpeningAmount,
}) => {
  return (
    <>
      <div className={classes.paperOpnAmt}>
        <h2 id="simple-modal-title">Opening Amount</h2>
        <div
          className={classes.flex}
          style={{
            textAlign: 'center',
            marginTop: '50px',
          }}
        >
          <TextField
            id="txtPOSOpeningAmount"
            label="Opening Amount"
            variant="outlined"
            type="number"
            inputProps={{ maxLength: 6 }}
            // color="secondary"
            value={openingamount}
            onChange={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 6);
              setOpeningamount(e.target.value);
            }}
            style={{
              marginLeft: 'auto',
            }}
            autoComplete="off"
            autoFocus
          />
          <Button
            variant="contained"
            onClick={handleOpeningAmount}
            id="btnPOSOpeningAmount"
            style={{
              display: 'flex',
              marginRight: 'auto',
              marginLeft: '10px',
            }}
            color="primary"
          >
            <PlayArrowRoundedIcon
              style={{ marginRight: '5px', marginLeft: '-5px' }}
            />
            START SHIFT
          </Button>
        </div>
      </div>
    </>
  );
};
StartShiftModalBody.propTypes = {
  classes: PropTypes.any,
  openingamount: PropTypes.any,
  setOpeningamount: PropTypes.any,
  handleOpeningAmount: PropTypes.any,
};
export default StartShiftModalBody;
