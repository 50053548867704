import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { CTX } from '../../../Actions/Store';
import useStyles from '../../../Style/AppStyles';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import APIService from './SalesAPIService';
import * as OrderService from '../Order/OrderService';
import * as creditService from '../Credit/CreditService';
import * as customerService from '../Customer/CustomerService';
import * as SetupService from '../Setups/SetupService';
import BarcodeReader from 'react-barcode-reader';
import styles from './POS.module.css';
import { ShiftSync } from './Sync';
import { getDateTime, DataDump } from '../../../Common/constants';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import useTableServerSide from '../../Utilities/ReuseableCommponents/useTableServerSide';
import SalesReturnPrint from './PrintActions/print-sales-return';
import EndShiftPrint from './PrintActions/end-shift-print';
import Print from './PrintActions/print';
import generateRetailDynamicCode from '../../../Common/NayaPay_QRCode';
import { getShiftButtonData, getModalStyle } from './POSComponents/funcs';
import CheckoutSideBar from './POSComponents/CheckoutSideBar';
import ProductListSlider from './POSComponents/ProductListSlider';
import MainCartBody from './POSComponents/MainCartBody';
import TopActionBar from './POSComponents/TopActionBar';
import Header from './POSComponents/Header';
import CustomerModal from './POSComponents/Modals/CustomerModal';
import EndShiftModal from './POSComponents/Modals/EndShiftModal';
import SalesReturnModal from './POSComponents/Modals/SalesReturnModal';
import NayaPayModal from './POSComponents/Modals/NayaPayModal';
import HoldOrderModal from './POSComponents/Modals/HoldOrderModal';
import StartShiftModal from './POSComponents/Modals/StartShiftModal';
import ShortcutsInfoModal from './POSComponents/Modals/ShortcutsInfoModal';
import ExpenseModal from './POSComponents/Modals/ExpenseModal';
import SalesAPIService from './SalesAPIService';
import activityMonitoring from '../../../Common/functions';
let catListIndex = 0;
let itemListIndex = 0;
let tableIndex = 0;
let custTableIndex = 0;
let heldOTableIndex = 0;
function POS() {
  const [CompanyName] = useState(localStorage.getItem('CompanyName'));
  const [modalStyle] = useState(getModalStyle);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [openShortcutInfo, setOpenShortcutInfo] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [isCustomerAttached, setIsCustomerAttached] = useState(false);
  const [openCustomer, setOpenCustomer] = useState(false);
  const [openExpense, setOpenExpense] = useState(false);
  const [totalExpense, setTotalExpense] = useState(0);
  const [openSalesReturn, setOpenSalesReturn] = useState(false);
  const [openNayaPay, setOpenNayaPay] = useState(false);
  const [signout, setsignout] = useState(false);
  const [shiftButton, setShiftButton] = useState(getShiftButtonData());
  const [openingamount, setOpeningamount] = useState('');
  const [expectedAmount, setExpectedAmount] = useState('');
  const [invoiceNo, setInvoiceNo] = useState('');
  const [finalDiscount, setFinalDiscount] = useState(0);
  const [closingAmount, setClosingAmount] = useState('');
  const [internalSaleAmount, setInternalSaleAmount] = useState('');
  const [comments, setComments] = useState('');
  const [nayaPayQRCode, setNayaPayQRCode] = useState('');
  const [discountBitFields, setDiscountBitFields] = useState(false);
  const [isCategorySelected, setIsCategorySelected] = useState(true);
  const [productSearchCatWise, setProductSearchCatWise] = useState('');
  const [UserRole] = useState(localStorage.getItem('role'));
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { allProducts } = React.useContext(CTX);
  const categories = allProducts.data.categories;
  const [productListInMainBody, setproductListInMainBody] = useState([]);
  const classes = useStyles();
  const [totalItems, setTotalItems] = useState(0);
  const [amountWithoutTax, setAmountWithoutTax] = useState(0);
  const [tax, setTax] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [discountMain, setDiscountMain] = useState(0);
  const [netTotal, setNetTotal] = useState(0);
  const [miscellaneousCharges, setMiscellaneousCharges] = useState(0);
  const [checked, setChecked] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [display, setDisplay] = useState('none');
  const [rows, setRows] = useState([]);
  const [rowsCount, setRowsCount] = useState(0);
  const [endShiftSaleDetail, setEndShiftSaleDetail] = useState('block');
  const [endShiftSaleDetailTab, setEndShiftSaleDetailTab] = useState('none');
  const [miscChargesLimit, setMiscChargesLimit] = useState({});
  const [isShortcutEnable, setIsShortcutEnable] = useState({ status: false });

  let [salesReturnList, setSalesReturnList] = useState({ itemsSold: [] });
  let [customerList, setCustomerList] = useState([]);
  let [customerinfo, setCustomerinfo] = useState();
  const [endShiftError, setEndShiftError] = useState({});
  const [offSet, setOffSet] = useState(25);

  const [syncTime, setSyncTime] = useState(
    'Last Sync At: ' + localStorage.getItem('_SyncDateTime')
  );
  const searchFocusRef = useRef(null);
  const holdOrderRef = useRef(null);
  const cashCheckoutRef = useRef(null);
  const cCardCheckoutRef = useRef(null);
  const nayaPayCheckoutRef = useRef(null);
  const creditCheckoutRef = useRef(null);
  const productTableRef = useRef(null);
  const backCatBtnRef = useRef(null);
  const removeCustRef = useRef(null);
  const signoutRef = useRef(null);
  const customerTableRef = useRef(null);
  const heldOrderTableRef = useRef(null);
  let listRef = useRef(null);
  let itemListRef = useRef(null);
  const quantityChangeRef = useRef(null);
  const miscellaneousChargesRef = useRef(null);
  const adjuestmentRef = useRef(null);
  const paidRef = useRef(null);

  useEffect(() => {
    activityMonitoring('POS');
  }, []);
  useEffect(() => {
    APIService.GetUserDiscount();
    APIService.getNayaPayData();
    APIService.GetSettings('Company Logo');
    getInvoiceTemplate();
    getMiscCharges();
    getShortcutKeys();
    return () => window.removeEventListener('keydown', keydownListener, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function keydownListener(keyDownEvent) {
    if (keyDownEvent.shiftKey && ['O', 'o'].includes(keyDownEvent.key)) {
      handleOpen4();
    }
    if (keyDownEvent.shiftKey && ['F', 'f'].includes(keyDownEvent.key)) {
      keyDownEvent.preventDefault();
      searchFocusRef?.current?.focus();
    }
    if (keyDownEvent.shiftKey && ['E', 'e'].includes(keyDownEvent.key)) {
      handleOpen5();
    }
    if (keyDownEvent.shiftKey && ['G', 'g'].includes(keyDownEvent.key)) {
      handleOpen3();
    }
    if (keyDownEvent.shiftKey && ['H', 'h'].includes(keyDownEvent.key)) {
      holdOrderRef?.current?.click();
    }
    if (keyDownEvent.shiftKey && ['C', 'c'].includes(keyDownEvent.key)) {
      clearProductFromMainBody();
    }
    if (keyDownEvent.shiftKey && ['R', 'r'].includes(keyDownEvent.key)) {
      keyDownEvent.preventDefault();
      modalOpenSalesReturn();
    }
    if (keyDownEvent.shiftKey && ['W', 'w'].includes(keyDownEvent.key)) {
      keyDownEvent.preventDefault();
      openCustomerModal();
    }
    if (keyDownEvent.shiftKey && ['V', 'v'].includes(keyDownEvent.key)) {
      removeCustRef?.current?.click();
    }
    if (keyDownEvent.shiftKey && ['X', 'x'].includes(keyDownEvent.key)) {
      handleChange9();
      listRef?.current?.firstChild.focus();
    }
    if (keyDownEvent.shiftKey && ['T', 't'].includes(keyDownEvent.key)) {
      if (itemListRef?.current?.id) {
        handleChange9();
      }
      productTableRef?.current?.lastChild?.children?.item(0)?.focus();
    }
    if (keyDownEvent.shiftKey && ['Q', 'q'].includes(keyDownEvent.key)) {
      keyDownEvent.preventDefault();
      miscellaneousChargesRef?.current?.focus();
    }
    if (keyDownEvent.shiftKey && ['N', 'n'].includes(keyDownEvent.key)) {
      keyDownEvent.preventDefault();
      openExpenseModal();
    }
    if (keyDownEvent.shiftKey && ['D', 'd'].includes(keyDownEvent.key)) {
      keyDownEvent.preventDefault();
      adjuestmentRef?.current?.focus();
    }

    if (keyDownEvent.shiftKey && ['P', 'p'].includes(keyDownEvent.key)) {
      keyDownEvent.preventDefault();
      paidRef?.current?.focus();
    }
    if (keyDownEvent.ctrlKey && ['X', 'x'].includes(keyDownEvent.key)) {
      signoutRef?.current?.click();
    }
    if (keyDownEvent.code === 'F2') {
      handleOpenShortcutInfo();
    }
    if (!keyDownEvent.ctrlKey && keyDownEvent.code === 'Space') {
      handleCloseShortcutInfo();
    }
    if (keyDownEvent.shiftKey && ['D', 'd'].includes(keyDownEvent.key)) {
      if (heldOrderTableRef?.current?.id === 'heldOrdersTable') {
        if (heldOrderTableRef.current?.lastChild?.children?.length > 0) {
          heldOrderTableRef.current?.lastChild?.children
            ?.item(heldOTableIndex)
            ?.lastChild?.getElementsByTagName('button')
            .item(1)
            ?.click();
          if (
            heldOrderTableRef?.current?.lastChild?.children?.length <=
            heldOTableIndex
          ) {
            heldOTableIndex =
              heldOrderTableRef?.current?.lastChild?.children?.length - 1;
          }
          if (heldOrderTableRef?.current?.lastChild?.children?.length > 0)
            heldOrderTableRef?.current?.lastChild?.children
              ?.item(heldOTableIndex < 0 ? 0 : heldOTableIndex)
              ?.focus();
        }
      }
    }
    if (keyDownEvent.shiftKey && ['V', 'v'].includes(keyDownEvent.key)) {
      if (heldOrderTableRef?.current?.id === 'heldOrdersTable') {
        if (heldOrderTableRef.current?.lastChild?.children?.length > 0) {
          heldOrderTableRef.current?.lastChild?.children
            ?.item(heldOTableIndex)
            ?.lastChild?.getElementsByTagName('button')
            .item(0)
            ?.click();
          if (
            heldOrderTableRef?.current?.lastChild?.children?.length <=
            heldOTableIndex
          ) {
            heldOTableIndex =
              heldOrderTableRef?.current?.lastChild?.children?.length - 1;
          }
          if (heldOrderTableRef?.current?.lastChild?.children?.length > 0)
            heldOrderTableRef?.current?.lastChild?.children
              ?.item(heldOTableIndex < 0 ? 0 : heldOTableIndex)
              ?.focus();
        }
      }
    }

    if (keyDownEvent.key === 'F1') {
      keyDownEvent.preventDefault();
      cashCheckoutRef?.current?.click();
    }
    if (keyDownEvent.key === 'F6') {
      cCardCheckoutRef?.current?.click();
    }
    if (keyDownEvent.key === 'F3') {
      keyDownEvent.preventDefault();
      nayaPayCheckoutRef?.current?.click();
    }
    if (keyDownEvent.key === 'F4') {
      creditCheckoutRef?.current?.click();
    }
    if (keyDownEvent.key === 'ArrowLeft') {
      backCatBtnRef?.current?.click();
    }
    if (keyDownEvent.key === 'ArrowUp') {
      keyDownEvent.preventDefault();
      if (listRef?.current?.id === 'lstPOSCatItems') {
        if (catListIndex > 0) {
          listRef.current?.childNodes[catListIndex - 1]?.focus();
          catListIndex--;
        }
      }
      if (itemListRef?.current?.id === 'lstPOSCatItemList') {
        if (itemListIndex > 0) {
          itemListRef.current?.childNodes[itemListIndex - 1]?.focus();
          itemListIndex--;
        }
      }
      if (productTableRef?.current?.id === 'posMainTable') {
        if (tableIndex > 0) {
          productTableRef?.current?.lastChild?.children
            ?.item(tableIndex - 1)
            ?.focus();
          tableIndex--;
        }
      }
      if (customerTableRef?.current?.id === 'posAddCustomerTable') {
        if (custTableIndex > 0) {
          customerTableRef?.current?.lastChild?.children
            ?.item(custTableIndex - 1)
            ?.focus();
          custTableIndex--;
        }
      }
      if (heldOrderTableRef?.current?.id === 'heldOrdersTable') {
        if (heldOTableIndex > 0) {
          heldOrderTableRef?.current?.lastChild?.children
            ?.item(heldOTableIndex - 1)
            ?.focus();
          heldOTableIndex--;
        }
      }
    }
    if (keyDownEvent.key === 'ArrowDown') {
      keyDownEvent.preventDefault();
      if (listRef?.current?.id === 'lstPOSCatItems') {
        if (catListIndex < listRef.current.childElementCount - 1) {
          listRef.current?.childNodes[catListIndex + 1]?.focus();
          catListIndex++;
        }
        if (listRef.current?.childElementCount === 1) {
          listRef.current?.firstChild?.focus();
        }
      }
      if (itemListRef?.current?.id === 'lstPOSCatItemList') {
        if (itemListIndex < itemListRef.current.childElementCount - 1) {
          itemListRef.current?.childNodes[itemListIndex + 1]?.focus();
          itemListIndex++;
        }
        if (itemListRef.current.childElementCount === 1) {
          itemListRef.current.firstChild.focus();
        }
      }
      if (
        productTableRef?.current?.id === 'posMainTable' &&
        !itemListRef?.current?.id &&
        !listRef?.current?.id
      ) {
        if (
          tableIndex <
          productTableRef.current?.lastChild?.children?.length - 1
        ) {
          productTableRef.current?.lastChild?.children
            ?.item(tableIndex + 1)
            ?.focus();
          tableIndex++;
        }
      }
      if (customerTableRef?.current?.id === 'posAddCustomerTable') {
        if (
          custTableIndex <
          customerTableRef.current?.lastChild?.children?.length - 1
        ) {
          customerTableRef.current?.lastChild?.children
            ?.item(custTableIndex + 1)
            ?.focus();
          custTableIndex++;
        }
      }
      if (heldOrderTableRef?.current?.id === 'heldOrdersTable') {
        if (
          heldOTableIndex <
          heldOrderTableRef.current?.lastChild?.children?.length - 1
        ) {
          heldOrderTableRef.current?.lastChild?.children
            ?.item(heldOTableIndex + 1)
            ?.focus();
          heldOTableIndex++;
        }
      }
    }
    if (keyDownEvent.key === '+') {
      keyDownEvent.preventDefault();
      productTableRef.current.lastChild.children
        .item(tableIndex)
        .getElementsByTagName('input')
        .item(0)
        .focus();
    }
    if (keyDownEvent.key === '-') {
      let val = productTableRef?.current?.lastChild?.children
        ?.item(tableIndex)
        ?.getElementsByTagName('input')
        ?.item(0).value;
      if (val > 0) --val;
      productTableRef.current.lastChild.children
        .item(tableIndex)
        .getElementsByTagName('input')
        .item(0).value = val;
    }
    if (keyDownEvent.key === 'Delete') {
      if (productTableRef?.current?.id === 'posMainTable') {
        if (productTableRef.current?.lastChild?.children?.length > 0) {
          productTableRef.current?.lastChild?.children
            ?.item(tableIndex)
            ?.lastChild?.firstChild?.click();
          if (
            productTableRef?.current?.lastChild?.children?.length <= tableIndex
          ) {
            tableIndex =
              productTableRef?.current?.lastChild?.children?.length - 1;
          }
          if (productTableRef?.current?.lastChild?.children?.length > 0)
            productTableRef?.current?.lastChild?.children
              ?.item(tableIndex < 0 ? 0 : tableIndex)
              ?.focus();
        }
      }
    }
    if (keyDownEvent.key === 'Insert') {
      if (customerTableRef?.current) {
        customerTableRef.current?.lastChild?.children
          ?.item(custTableIndex)
          ?.getElementsByTagName('button')
          ?.item(0)
          ?.click();
      }
    }
  }

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });
  const [
    salesReturnBtnDisableStatus,
    setSalesReturnBtnDisableStatus,
  ] = useState(true);
  const Check = JSON.parse(localStorage.getItem('Order'));
  if (!Check) {
    localStorage.setItem('Order', JSON.stringify(DataDump));
  }
  const discountinfo = JSON.parse(localStorage.getItem('_DiscountInfo'));

  let options = [
    { key: 'credit', label: 'CREDIT' },
    { key: 'cashondelivery', label: 'COD' },
  ];

  const signDiscount = useState(() => {
    if (discountinfo) {
      if (
        discountinfo.Discount[0].DiscountName &&
        discountinfo.Discount[0].DiscountLimit
      ) {
        return discountinfo.Discount[0].DiscountName === 'discountLimitAmount'
          ? 'Rs'
          : '%';
      } else {
        setDiscountBitFields(true);
        return 'Rs';
      }
    } else {
      setDiscountBitFields(true);
      return 'Rs';
    }
  });

  const handleClick = () => {
    if (options[selectedIndex].key === 'cashondelivery') {
      if (systemType === 'ol' && !customerinfo) {
        setNotify({
          isOpen: true,
          message: 'Customer did not attached.',
          type: 'warning',
          notificationTime: 3000,
        });
        return;
      } else payCashOnDelivery();
    } else if (options[selectedIndex].key === 'credit') {
      if (!customerinfo) {
        setNotify({
          isOpen: true,
          message: 'Customer did not attached.',
          type: 'warning',
          notificationTime: 3000,
        });
        return;
      } else payCredit();
    }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  async function getCustomerCurrentBalance(saleDetails) {
    await creditService
      .getCustomerBalance(saleDetails.customerRef)
      .then(({ data: dataCustomerBalance }) => {
        if (dataCustomerBalance && dataCustomerBalance.data.length) {
          debitSale(saleDetails, dataCustomerBalance.data[0]);
        } else {
          customerService
            .getCustomerById(saleDetails.customerRef)
            .then(({ data: dataCustomerID }) => {
              if (dataCustomerID.data) {
                debitSale(saleDetails, {
                  currentBalance: dataCustomerID.data.openingAmount,
                });
              }
            });
        }
      });
  }

  async function debitSale(saleData, customerBalance) {
    const payLoad = {
      customerRef: saleData.customerRef,
      debit: saleData.saleAmountTotal,
      saleRef: saleData._id,
      currentBalance:
        parseFloat(customerBalance.currentBalance) - saleData.saleAmountTotal,
      comment: '',
    };
    creditSale(payLoad);
  }

  async function creditSale(payLoad) {
    await creditService.insertCredit(payLoad).then((response) => {
      if (response.data) {
        return response;
      }
    });
  }

  const SyncDateTime = localStorage.getItem('_SyncDateTime');
  function updateSyncTime() {
    let newDate = new Date();
    let date =
      newDate.getFullYear() +
      '-' +
      (newDate.getMonth() + 1) +
      '-' +
      newDate.getDate() +
      ' ' +
      newDate.getHours() +
      ':' +
      newDate.getMinutes() +
      ':' +
      newDate.getSeconds();
    localStorage.setItem('_SyncDateTime', date);
    setSyncTime('Last Sync At: ' + localStorage.getItem('_SyncDateTime'));
  }
  if (!SyncDateTime) {
    updateSyncTime();
  }
  const handleOpen3 = () => {
    setOpen3(true);
  };
  const handleClose3 = () => {
    setOpen3(false);
  };
  const handleOpen4 = () => {
    setOpen4(true);
  };
  const handleClose4 = () => {
    setOpen4(false);
  };
  const handleOpenShortcutInfo = () => {
    setOpenShortcutInfo(true);
  };
  const handleCloseShortcutInfo = () => {
    setOpenShortcutInfo(false);
  };

  async function GetData() {
    return new Promise((resolve) => {
      APIService.GetExpectedAmts().then((value) => resolve(value));
    });
  }

  const handleOpen5 = () => {
    const ShiftData = JSON.parse(localStorage.getItem('_IdShift'));
    GetData().then((Response) => {
      if (Response.data) {
        const Data = Response.data.data;
        const amt = Data.length
          ? Data.length > 0
            ? parseFloat(
                parseFloat(sumofarryValue(Data)) +
                  parseFloat(ShiftData.openingAmount) -
                  totalExpense
              ).toFixed(2)
            : ShiftData.openingAmount
          : ShiftData.openingAmount - totalExpense;
        setExpectedAmount(amt);
        setEndShiftSaleDetail('block');
        setEndShiftSaleDetailTab('none');
        setIsLoaded(false);
        setOpen5(true);
        setEndShiftError({});
      }
      if (!Response.data) {
        if (Response.error.message === 'Failed to fetch') {
          setExpectedAmount('0');
          setEndShiftSaleDetail('block');
          setEndShiftSaleDetailTab('none');
          setIsLoaded(false);
          setOpen5(true);
          setNotify({
            isOpen: true,
            message: 'Shift end in offline mode!',
            type: 'warning',
            notificationTime: 3000,
          });
        }
      }
    });
  };

  function sumofarryValue(ArrayValue) {
    var sum = 0;
    ArrayValue.forEach((element) => {
      sum = sum + element.saleAmountTotal;
    });
    return sum.toFixed(2);
  }
  const handleClose5 = () => {
    setOpen5(false);
  };
  const openCustomerModal = () => {
    setOpenCustomer(true);
  };
  const handleCloseCustomerModal = () => {
    setOpenCustomer(false);
  };
  const openExpenseModal = () => {
    setOpenExpense(true);
  };
  const handleCloseExpenseModal = () => {
    setOpenExpense(false);
  };
  const modalOpenSalesReturn = () => {
    setOpenSalesReturn(true);
  };
  const modleCloseSalesReturn = () => {
    setSalesReturnList({ itemsSold: [] });
    setOpenSalesReturn(false);
  };
  const modleOpenNayaPay = () => {
    if (systemType === 'ol' && !customerinfo) {
      setNotify({
        isOpen: true,
        message: 'Customer did not attached.',
        type: 'warning',
        notificationTime: 3000,
      });
    } else setOpenNayaPay(true);
  };
  const modleCloseNayaPay = () => {
    setOpenNayaPay(false);
  };
  function clearProductFromMainBody() {
    setproductListInMainBody([]);
    setTotalItems(0);
    setAmountWithoutTax(0);
    setTax(0);
    setDiscount(0);
    setNetTotal(0);
    setCalValue(0);
    ResetEveryThing();
  }
  const handleChange9 = () => {
    setChecked((prev) => !prev);
  };
  const HOList = JSON.parse(localStorage.getItem('HoldOrder'));

  const [holdOrder, setHoldOrder] = useState(
    HOList === null ? [] : HOList.HoldOrders
  );
  function holdOrderFromMainBody() {
    if (productListInMainBody.length > 0) {
      let newDate = new Date();
      let date =
        newDate.getFullYear() +
        '' +
        (newDate.getMonth() + 1) +
        '' +
        newDate.getDate() +
        '' +
        newDate.getHours() +
        '' +
        newDate.getMinutes() +
        '' +
        newDate.getSeconds();

      const Order = {
        HoldOrders: [
          {
            Date: new Date(),
            HoldOrderNumber: `${date}`,
            Products: [productListInMainBody],
            TotalItems: totalItems,
            AmountWithoutTax: parseFloat(amountWithoutTax).toFixed(2),
            Tax: tax,
            Discount: parseFloat(discount).toFixed(2),
            NetTotal: parseFloat(netTotal).toFixed(2),
            customerInfo: customerinfo,
            miscellaneousCharges,
          },
        ],
      };

      const HoldOrderList = JSON.parse(localStorage.getItem('HoldOrder'));

      if (HoldOrderList === null) {
        localStorage.setItem('HoldOrder', JSON.stringify(Order));
        const temp = JSON.parse(localStorage.getItem('HoldOrder'));
        setHoldOrder(temp.HoldOrders);
      } else {
        HoldOrderList.HoldOrders.push(Order.HoldOrders[0]);
        localStorage.setItem('HoldOrder', JSON.stringify(HoldOrderList));
        const temp = JSON.parse(localStorage.getItem('HoldOrder'));
        setHoldOrder(temp.HoldOrders);
      }
      setproductListInMainBody([]);
      setCustomerinfo({});
      setIsCustomerAttached(false);
      setTotalItems(0);
      setAmountWithoutTax(0);
      setTax(0);
      setDiscount(0);
      setNetTotal(0);
      setMiscellaneousCharges(0);
    }
  }
  function HoldOrderReload(index) {
    let TempList = JSON.parse(localStorage.getItem('HoldOrder'));
    setproductListInMainBody(TempList.HoldOrders[index].Products[0]);
    setTotalItems(TempList.HoldOrders[index].TotalItems);
    setAmountWithoutTax(TempList.HoldOrders[index].AmountWithoutTax);
    setCustomerinfo(TempList.HoldOrders[index].customerInfo);
    if (
      TempList.HoldOrders[index].customerInfo &&
      Object.keys(TempList.HoldOrders[index].customerInfo).length
    ) {
      setIsCustomerAttached(true);
    }
    setTax(TempList.HoldOrders[index].Tax);
    setDiscount(TempList.HoldOrders[index].Discount);
    setNetTotal(TempList.HoldOrders[index].NetTotal);
    setMiscellaneousCharges(TempList.HoldOrders[index].miscellaneousCharges);
    TempList.HoldOrders.splice(index, 1);
    localStorage.setItem('HoldOrder', JSON.stringify(TempList));
    setHoldOrder(TempList.HoldOrders);
    handleClose3();
  }
  function HoldOrderRemove(index) {
    let TempList = JSON.parse(localStorage.getItem('HoldOrder'));
    TempList.HoldOrders.splice(index, 1);
    localStorage.setItem('HoldOrder', JSON.stringify(TempList));
    setHoldOrder(TempList.HoldOrders);
  }

  function payCredit() {
    const ShiftData = JSON.parse(localStorage.getItem('_IdShift'));
    const branch = JSON.parse(localStorage.getItem('Branch'));
    let ClientDateCreated = getDateTime();
    let date = getInvoiceNumber();

    const Order = {
      invoiceNumber: `${date}`,
      branchRef: branch ? branch._id : undefined,
      clientCreatedDate: ClientDateCreated,
      clientModifiedDate: ClientDateCreated,
      saleType: 'external',
      saleMode: 'credit',
      shiftNumber: ShiftData.shiftNumber,
      saleAmountSumOfItems: parseFloat(netTotal).toFixed(2),
      discount: parseFloat(discount).toFixed(2),
      overallSaleDiscountAmount: parseFloat(discountMain).toFixed(2),
      saleAmountTotal: (
        parseFloat(netTotal) +
        parseFloat(miscellaneousCharges) -
        discount -
        discountMain
      ).toFixed(2),
      miscellaneousCharges,
      TotalQuantity: totalItems,
      itemsSold: productListInMainBody,
      totalTaxAmount: tax,
      customerRef: customerinfo ? customerinfo._id : null,
      customerInfo: customerinfo,
      paidAmount: parseFloat(calValue).toFixed(2),
      returnAmount: (
        parseFloat(calValue) -
        (netTotal - discount - discountMain)
      ).toFixed(2),
    };
    if (systemType === 'pos') {
      const resultArray = JSON.parse(localStorage.getItem('Order'));
      resultArray.Order.push(Order);
      localStorage.setItem('Order', JSON.stringify(resultArray));
    } else {
      localStorage.removeItem('bookOrder');
      localStorage.setItem('bookOrder', JSON.stringify(Order));
    }
    sessionStorage.removeItem('Invoice');
    window.sessionStorage.setItem('Invoice', JSON.stringify(Order));
    ReactDOM.render(<Print />, document.getElementById('print-mount'));
    document.getElementById('print-button').click();
    ReactDOM.unmountComponentAtNode(document.getElementById('print-mount'));
    ResetEveryThing();
    if (systemType === 'pos') {
      InvoiceSync();
    } else {
      createOrder();
    }
  }

  function payCashOnDelivery() {
    const ShiftData = JSON.parse(localStorage.getItem('_IdShift'));
    const branch = JSON.parse(localStorage.getItem('Branch'));
    let ClientDateCreated = getDateTime();
    let date = getInvoiceNumber();

    const Order = {
      invoiceNumber: `${date}`,
      branchRef: branch ? branch._id : undefined,
      clientCreatedDate: ClientDateCreated,
      clientModifiedDate: ClientDateCreated,
      saleType: 'external',
      saleMode: 'cashondelivery',
      shiftNumber: ShiftData.shiftNumber,
      saleAmountSumOfItems: parseFloat(netTotal).toFixed(2),
      discount: parseFloat(discount).toFixed(2),
      overallSaleDiscountAmount: parseFloat(discountMain).toFixed(2),
      saleAmountTotal: (
        parseFloat(netTotal) +
        parseFloat(miscellaneousCharges) -
        discount -
        discountMain
      ).toFixed(2),
      miscellaneousCharges,
      TotalQuantity: totalItems,
      itemsSold: productListInMainBody,
      totalTaxAmount: tax,
      customerRef: customerinfo ? customerinfo._id : null,
      customerInfo: customerinfo,
    };
    if (systemType === 'pos') {
      const resultArray = JSON.parse(localStorage.getItem('Order'));
      resultArray.Order.push(Order);
      localStorage.setItem('Order', JSON.stringify(resultArray));
    } else {
      localStorage.removeItem('bookOrder');
      localStorage.setItem('bookOrder', JSON.stringify(Order));
    }
    sessionStorage.removeItem('Invoice');
    window.sessionStorage.setItem('Invoice', JSON.stringify(Order));
    ReactDOM.render(<Print />, document.getElementById('print-mount'));
    document.getElementById('print-button').click();
    ReactDOM.unmountComponentAtNode(document.getElementById('print-mount'));
    ResetEveryThing();
    createOrder();
  }

  function PayCreditCard() {
    const ShiftData = JSON.parse(localStorage.getItem('_IdShift'));
    const branch = JSON.parse(localStorage.getItem('Branch'));
    let ClientDateCreated = getDateTime();
    let date = getInvoiceNumber();

    const Order = {
      invoiceNumber: `${date}`,
      branchRef: branch ? branch._id : undefined,
      clientCreatedDate: ClientDateCreated,
      clientModifiedDate: ClientDateCreated,
      saleType: 'external',
      saleMode: 'creditcard',
      shiftNumber: ShiftData.shiftNumber,
      saleAmountSumOfItems: parseFloat(netTotal).toFixed(2),
      discount: parseFloat(discount).toFixed(2),
      overallSaleDiscountAmount: parseFloat(discountMain).toFixed(2),
      saleAmountTotal: (
        parseFloat(netTotal) +
        parseFloat(miscellaneousCharges) -
        discount -
        discountMain
      ).toFixed(2),
      miscellaneousCharges,
      TotalQuantity: totalItems,
      itemsSold: productListInMainBody,
      totalTaxAmount: tax,
      customerRef: customerinfo ? customerinfo._id : null,
      customerInfo: customerinfo,
      paidAmount: parseFloat(calValue).toFixed(2),
      returnAmount: (
        parseFloat(calValue) -
        (netTotal - discount - discountMain)
      ).toFixed(2),
    };
    if (systemType === 'pos') {
      const resultArray = JSON.parse(localStorage.getItem('Order'));
      resultArray.Order.push(Order);
      localStorage.setItem('Order', JSON.stringify(resultArray));
    } else {
      localStorage.removeItem('bookOrder');
      localStorage.setItem('bookOrder', JSON.stringify(Order));
    }
    sessionStorage.removeItem('Invoice');
    window.sessionStorage.setItem('Invoice', JSON.stringify(Order));
    ResetEveryThing();
  }
  function PayCash() {
    ReactDOM.render(<Print />, document.getElementById('print-mount'));
    document.getElementById('print-button').click();
    ReactDOM.unmountComponentAtNode(document.getElementById('print-mount'));
    if (systemType === 'pos') {
      InvoiceSync();
    } else {
      createOrder();
    }
  }

  function PrintEndShift() {
    if (
      expectedAmount !== '' &&
      closingAmount !== '' &&
      internalSaleAmount !== ''
    ) {
      options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      };
      const ShiftData = JSON.parse(localStorage.getItem('_IdShift'));
      const EndShiftData = {
        startDate: new Date(ShiftData.createdAt).toLocaleString(
          undefined,
          options
        ),
        endDate: new Date(ShiftData.updatedAt).toLocaleString(
          undefined,
          options
        ),
        expectedAmount: parseFloat(expectedAmount).toLocaleString('en', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        closingAmount: parseFloat(closingAmount).toLocaleString('en', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        internalSale: parseFloat(internalSaleAmount).toLocaleString('en', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        difference: (expectedAmount - closingAmount).toFixed(2),
        comments: comments,
      };
      localStorage.setItem('ShiftReport', JSON.stringify(EndShiftData));
      ReactDOM.render(
        <EndShiftPrint />,
        document.getElementById('print-mount')
      );
      document.getElementById('print-button').click();
      ReactDOM.unmountComponentAtNode(document.getElementById('print-mount'));
    } else {
      validateEndShift();
    }
  }

  let apiResponseDto = {
    data: [],
    error: null,
    loading: false,
    statusCode: 0,
  };

  function getInvoiceTemplate() {
    let dataPromise = (async () => {
      apiResponseDto = await SetupService.getSetupWithName('Invoice').then(
        (Response) => {
          return Response;
        }
      );
      return apiResponseDto;
    })();
    dataPromise.then((response) => {
      if (response.data && response.data.data.length) {
        let activeInvoices = response.data.data[0].data.filter(
          (invoice) => invoice.status === 'active'
        );
        localStorage.setItem(
          'invoiceTemplate',
          JSON.stringify(activeInvoices[0])
        );
      } else console.error('Error While Fetching Data: ', Response.error);
    });
  }

  function getMiscCharges() {
    SetupService.getSetupWithName('Miscellaneous Charges').then((response) => {
      if (response.data && response.data.data.length) {
        let miscCharges = response.data.data[0].data;
        localStorage.setItem('_Misc', JSON.stringify(miscCharges));
        setMiscChargesLimit(miscCharges);
      } else console.error('Error While Fetching Data: ', Response.error);
    });
  }

  function getShortcutKeys() {
    SetupService.getSetupWithName('Shortcut Keys').then((response) => {
      if (response.data && response.data.data.length) {
        let shortcuts = response.data.data[0].data;
        localStorage.setItem('_ShortKeys', JSON.stringify(shortcuts));
        setIsShortcutEnable(shortcuts);
        if (shortcuts.status) {
          window.addEventListener('keydown', keydownListener, true);
        }
      } else console.error('Error While Fetching Data: ', Response.error);
    });
  }

  async function createOrder() {
    let order = JSON.parse(localStorage.getItem('bookOrder'));
    var FinalLIST = [];
    order.itemsSold.forEach((item) => {
      FinalLIST.push({
        itemRef: item._id,
        quantity: item.Quantity,
        taxAmount: (item.saleFinalPrice - item.salePrice).toFixed(2),
        discountAmount: item.Tempdiscount,
        saleFinalPrice: item.unitSaleFinalPrice,
        saleAmountTotal: item.saleFinalPrice,
      });
    });
    const payLoad = {
      state: 'booked',
      paymentMode: order.saleMode,
      branchRef: order.branchRef,
      orderMode: 'posportal',
      saleDetails: {
        overallSaleDiscountAmount: order.overallSaleDiscountAmount,
        saleAmountSumOfItems: order.saleAmountSumOfItems,
        saleAmountTotal: order.saleAmountTotal,
        miscellaneousCharges,
        itemsSold: FinalLIST,
        saleType: order.saleType,
        saleMode: order.saleMode,
        shiftNumber: order.shiftNumber,
        overallSaleTaxAmount: order.totalTaxAmount,
      },
      customerRef: order.customerRef,
    };
    await OrderService.createOrder(payLoad).then((response) => {
      if (response.data) {
        localStorage.removeItem('bookOrder');
      }
    });
  }

  function Cash() {
    const ShiftData = JSON.parse(localStorage.getItem('_IdShift'));
    const branch = JSON.parse(localStorage.getItem('Branch'));
    let ClientDateCreated = getDateTime();
    let date = getInvoiceNumber();

    const resultArray = JSON.parse(localStorage.getItem('Order'));
    const Order = {
      invoiceNumber: `${date}`,
      branchRef: branch ? branch._id : undefined,
      clientCreatedDate: ClientDateCreated,
      clientModifiedDate: ClientDateCreated,
      saleType: 'external',
      saleMode: 'cash',
      shiftNumber: ShiftData.shiftNumber,
      saleAmountSumOfItems: parseFloat(netTotal).toFixed(2),
      discount: parseFloat(discount).toFixed(2),
      overallSaleDiscountAmount: discountMain,
      saleAmountTotal: (
        parseFloat(netTotal) +
        parseFloat(miscellaneousCharges) -
        discount -
        discountMain
      ).toFixed(2),
      miscellaneousCharges,
      TotalQuantity: totalItems,
      itemsSold: productListInMainBody,
      totalTaxAmount: tax,
      customerRef: customerinfo ? customerinfo._id : null,
      customerInfo: customerinfo,
      paidAmount: parseFloat(calValue).toFixed(2),
      returnAmount: (
        parseFloat(calValue) -
        (netTotal - discount - discountMain)
      ).toFixed(2),
    };
    resultArray.Order.push(Order);
    localStorage.setItem('Order', JSON.stringify(resultArray));
    sessionStorage.removeItem('Invoice');
    window.sessionStorage.setItem('Invoice', JSON.stringify(Order));
    ResetEveryThing();
  }

  async function InvoiceSyncRequest(Obj) {
    return APIService.InvoiceSyncRequest(Obj).then((response) => {
      if (response.data && response.data.data.saleMode === 'credit') {
        getCustomerCurrentBalance(response.data.data);
      }

      return response;
    });
  }
  async function InvoiceSync() {
    try {
      let TempList = JSON.parse(localStorage.getItem('Order'));
      let index = TempList.Order.length;
      if (index > 1) {
        try {
          let promise = InvoiceSyncRequest(TempList.Order[index - 1]);
          promise.then((response) => {
            if (response.data) {
              TempList.Order.splice(index - 1, 1);
              localStorage.setItem('Order', JSON.stringify(TempList));
              InvoiceSync();
            }
          });
        } catch (e) {
          //do nothing just exit from loop
        }
      }
      if (index <= 1) {
        updateSyncTime();
        ShiftSync();
        setNotify({
          isOpen: true,
          message: 'Data has been Successfully Syncronized',
          type: 'success',
          notificationTime: 3000,
        });
      }
    } catch (error) {
      //do nothing just exit from loop
    }
  }

  function getInvoiceNumber() {
    let newDate = new Date();
    return (
      '/0001/0020/' +
      newDate.getFullYear() +
      '' +
      (newDate.getMonth() + 1) +
      '' +
      newDate.getDate() +
      '' +
      newDate.getHours() +
      '' +
      newDate.getMinutes() +
      '' +
      newDate.getSeconds()
    );
  }

  function selectProductForMainBody(Product) {
    const val = productListInMainBody.findIndex((a) => a._id === Product._id);
    if (val === -1) {
      setproductListInMainBody((productListInMainBodyItem) => [
        {
          _id: Product._id,
          name: Product.name,
          category: Product.category,
          saleFinalPrice: (
            Product.saleFinalPrice - Product.Tempdiscount
          ).toFixed(2),
          unitSaleFinalPrice: parseFloat(Product.unitSaleFinalPrice).toFixed(2),
          barcode: Product.barcode,
          Quantity: Product.Quantity,
          taxPercentage: Product.taxPercentage,
          salePrice: parseFloat(Product.salePrice).toFixed(2),
          unitSalePrice: parseFloat(Product.unitSalePrice).toFixed(2),
          unitQuantity: Product.unitQuantity,
          discountPercentage: Product.discountPercentage
            ? parseFloat(Product.discountPercentage).toFixed(2)
            : (0).toFixed(2),
          unitType: Product.unitType,
          units: Product.units,
          minDiscountPercentage: parseFloat(
            Product.minDiscountPercentage
          ).toFixed(2),
          maxDiscountPercentage: parseFloat(
            Product.maxDiscountPercentage
          ).toFixed(2),
          Tempdiscount: parseFloat(Product.Tempdiscount).toFixed(2),
          img: Product.img,
          description: Product.description,
          sku: Product.sku,
        },
        ...productListInMainBodyItem,
      ]);
      setTotalItems(parseFloat(totalItems) + parseFloat(Product.unitQuantity));
    } else {
      let quantity =
        parseFloat(productListInMainBody[val].Quantity) +
        parseFloat(Product.unitQuantity);

      productListInMainBody[val].Quantity = quantity;
      let discountPercent = productListInMainBody[val].discountPercentage
        ? productListInMainBody[val].discountPercentage
        : 0;
      let itemDiscount =
        (discountPercent / 100) *
        productListInMainBody[val].unitSaleFinalPrice *
        quantity;

      productListInMainBody[val].Tempdiscount = itemDiscount.toFixed(2);

      productListInMainBody[val].saleFinalPrice = (
        productListInMainBody[val].unitSaleFinalPrice * quantity -
        itemDiscount
      ).toFixed(2);
      reCalculateFooter();
    }
  }

  const [activeCategory, changeActiveCategory] = useState(
    categories[0] ? categories[0]._id : ''
  );

  function RemoveItemFromMainBody(productList) {
    setTotalItems(parseFloat(totalItems) - 1);
    setNetTotal(
      (
        parseFloat(netTotal) -
        parseFloat(productList.unitSaleFinalPrice) *
          parseFloat(productList.Quantity)
      ).toFixed(2)
    );
    setAmountWithoutTax(
      (
        parseFloat(amountWithoutTax) -
        parseFloat(productList.unitSalePrice) * parseFloat(productList.Quantity)
      ).toFixed(2)
    );
    setTax(
      parseFloat(tax) -
        (
          parseFloat(productList.unitSaleFinalPrice) *
            parseFloat(productList.Quantity) -
          parseFloat(productList.unitSalePrice) *
            parseFloat(productList.Quantity)
        ).toFixed(2)
    );
    setDiscount(
      (parseFloat(discount) - parseFloat(productList.Tempdiscount)).toFixed(2)
    );
    setproductListInMainBody(
      productListInMainBody.filter((item) => item._id !== productList._id)
    );
    if (productListInMainBody.length <= 1) {
      ResetEveryThing();
    }
  }
  const [calValue, setCalValue] = useState('');
  function cal(value) {
    if (value === 'c') {
      setCalValue('');
    } else {
      setCalValue(calValue + value);
    }
  }
  function ResetEveryThing() {
    setproductListInMainBody([]);
    setTotalItems(0);
    setAmountWithoutTax(0);
    setTax(0);
    setDiscount(0);
    setNetTotal(0);
    setFinalDiscount(0);
    setDiscountMain(0);
    setMiscellaneousCharges(0);
    setCalValue('');
    handleClose5();
    setExpectedAmount('');
    setClosingAmount('');
    setInternalSaleAmount('');
    setComments('');
    setFinalDiscount(0);
    setCustomerinfo();
    setCustomerList([]);
    setIsCustomerAttached(false);
    setNayaPayId('');
    setInvoiceNo('');
    setChecked(false);
    setIsLoaded(false);
  }

  function handleOpeningAmount() {
    APIService.startshift(openingamount).then(
      () => {
        setShiftButton(true);
        setOpeningamount('');
        handleClose4();
        getInvoiceTemplate();
        setTotalExpense(0);
      },
      () => {
        //Offline Start
        setShiftButton(true);
        setOpeningamount('');
        handleClose4();
        setNotify({
          isOpen: true,
          message: 'Shift starts in offline mode!',
          type: 'warning',
          notificationTime: 3000,
        });
      }
    );
  }

  function handleShiftEnd() {
    const ShiftData = JSON.parse(localStorage.getItem('_IdShift'));
    if (
      expectedAmount !== '' &&
      closingAmount !== '' &&
      internalSaleAmount !== ''
    ) {
      APIService.endshift(
        expectedAmount,
        closingAmount,
        internalSaleAmount,
        comments,
        totalExpense
      ).then((res) => {
        if (res.data) {
          if (res.data.data._id) {
            localStorage.removeItem('_IdShift');
            sessionStorage.removeItem('SalesReturnInvoice');
            localStorage.removeItem('ShiftReport');
            localStorage.removeItem('invoiceTemplate');
            setTotalExpense(0);
          }
        }
        if (!res.data) {
          if (res.error.message === 'Failed to fetch') {
            const sh = {
              shiftNumber: ShiftData.shiftNumber,
              openingAmount: ShiftData.openingAmount,
              expectedClosingAmount: expectedAmount ? expectedAmount : 0,
              actualClosingAmount: closingAmount ? closingAmount : 0,
              internalSaleAmount: internalSaleAmount ? internalSaleAmount : 0,
              comments: comments ? comments : '',
              createdAt: ShiftData.createdAt,
              createdBy: ShiftData.createdBy,
              updatedAt: getDateTime(),
            };
            if (ShiftData._id) {
              sh._id = ShiftData._id;
            }
            let TempList = JSON.parse(localStorage.getItem('_StackShift'));
            if (TempList) {
              TempList.push(sh);
              localStorage.setItem('_StackShift', JSON.stringify(TempList));
            } else localStorage.setItem('_StackShift', JSON.stringify([sh]));
          }
        }
      });
      if (signout === true) {
        setsignout(false);
        localStorage.removeItem('Token');
        localStorage.removeItem('_IdShift');
        localStorage.removeItem('NayaPayActivation');
        localStorage.removeItem('role');
        localStorage.removeItem('_DiscountInfo');
        localStorage.removeItem('CompanyName');
        localStorage.removeItem('TemplateType');
        localStorage.removeItem('type');
        localStorage.removeItem('_Misc');
        localStorage.removeItem('_ShortKeys');

        history.push('/Signin');
      }
      setShiftButton(false);
      localStorage.removeItem('_IdShift');
      ResetEveryThing();
    } else {
      validateEndShift();
    }
  }

  function validateEndShift() {
    let temp = {};
    if (closingAmount === '') {
      temp = {
        ...temp,
        closingAmount: 'This field is required',
      };
    }
    if (internalSaleAmount === '') {
      temp = {
        ...temp,
        internalSaleAmount: 'This field is required',
      };
    }
    setEndShiftError(temp);
  }
  async function CustomerCreditInfo(saleResponse) {
    await creditService
      .getCustomerCreditInfo(saleResponse.customerRef)
      .then((response) => {
        if (response.data.data.length) {
          if (saleResponse.saleMode === 'credit') {
            let currentBalance =
              response.data.data[0].history[0].currentBalance;
            if (currentBalance < 0) {
              let totalBalance =
                currentBalance + saleResponse.returnedAmountSumOfItems;
              const payLoad = {
                customerRef: saleResponse.customerRef,
                credit: saleResponse.returnedAmountSumOfItems,
                saleRef: saleResponse._id,
                currentBalance: totalBalance,
                comment: 'transaction of sales return',
              };
              creditSale(payLoad);
            }
          }
        }
        return response;
      });
  }

  async function SalesReturnRequest(Obj) {
    await APIService.SalesReturnRequest(Obj).then((Response) => {
      if (salesReturnList.saleMode === 'credit') {
        CustomerCreditInfo(Response.data.data);
      }
      return Response;
    });
  }
  function saveSalesReturn() {
    try {
      let returnedQty = salesReturnList.itemsSold.filter((item) => {
        return item.returnQty > 0;
      });
      if (returnedQty.length) {
        SalesReturnRequest(salesReturnList).then((Response) => {
          SalesReturnReport();
          setNotify({
            isOpen: true,
            message: 'Sale Returned Successfully',
            type: 'success',
            notificationTime: 3000,
          });

          modleCloseSalesReturn();
          return Response;
        });
      } else {
        setNotify({
          isOpen: true,
          message: 'Items Returned Quantity Can Not Be Zero',
          type: 'warning',
          notificationTime: 3000,
        });
      }
    } catch (e) {
      setNotify({
        isOpen: true,
        message: 'Unable To Process Request: Sales Return Error',
        type: 'error',
        notificationTime: 3000,
      });
      //do nothing just exit from loop
      setSalesReturnBtnDisableStatus(false);
    }
    setSalesReturnBtnDisableStatus(false);
  }

  function SalesReturnReport() {
    ReactDOM.render(
      <SalesReturnPrint />,
      document.getElementById('print-mount')
    );
    document.getElementById('print-button').click();
    ReactDOM.unmountComponentAtNode(document.getElementById('print-mount'));
    InvoiceSync();
  }
  const [generateQr, setGenerateQr] = useState(false);
  const [generatePushInvoice, setGeneratePushInvoice] = useState(false);
  const [nayaPayId, setNayaPayId] = useState('');

  function nayaPay(invoiceNumber, a) {
    const ShiftData = JSON.parse(localStorage.getItem('_IdShift'));
    const branch = JSON.parse(localStorage.getItem('Branch'));
    let ClientDateCreated = getDateTime();

    const Order = {
      invoiceNumber: invoiceNumber,
      branchRef: branch ? branch._id : undefined,
      clientCreatedDate: ClientDateCreated,
      clientModifiedDate: ClientDateCreated,
      saleType: 'external',
      saleMode: 'nayapay',
      shiftNumber: ShiftData.shiftNumber,
      saleAmountSumOfItems: parseFloat(netTotal).toFixed(2),
      discount: parseFloat(discount).toFixed(2),
      overallSaleDiscountAmount: discountMain,
      saleAmountTotal: (
        parseFloat(netTotal) +
        parseFloat(miscellaneousCharges) -
        discount -
        discountMain
      ).toFixed(2),
      miscellaneousCharges,
      TotalQuantity: totalItems,
      itemsSold: productListInMainBody,
      totalTaxAmount: tax,
      customerRef: customerinfo ? customerinfo._id : null,
      customerInfo: customerinfo,
      QRCode: a,
      paidAmount: parseFloat(calValue).toFixed(2),
      returnAmount: (
        parseFloat(calValue) -
        (netTotal - discount - discountMain)
      ).toFixed(2),
    };
    if (systemType === 'pos') {
      const resultArray = JSON.parse(localStorage.getItem('Order'));
      resultArray.Order.push(Order);
      localStorage.setItem('Order', JSON.stringify(resultArray));
    } else {
      localStorage.removeItem('bookOrder');
      localStorage.setItem('bookOrder', JSON.stringify(Order));
    }
    sessionStorage.removeItem('Invoice');
    window.sessionStorage.setItem('Invoice', JSON.stringify(Order));
  }
  function GenerateNayaPayInvoice() {
    setGenerateQr(false);
    modleCloseNayaPay();
    ReactDOM.render(<Print />, document.getElementById('print-mount'));
    document.getElementById('print-button').click();
    ReactDOM.unmountComponentAtNode(document.getElementById('print-mount'));

    ResetEveryThing();
    if (systemType === 'pos') {
      InvoiceSync();
    } else {
      createOrder();
    }
  }
  const [jwt] = useState(
    JSON.parse(window.localStorage.getItem('NayaPayActivation'))
  );
  function NayaPayQRCodeGenerator() {
    let date = getInvoiceNumber();
    let cash = (netTotal - discount).toFixed(2);
    date = date.substring(date.length - 10, date.length);

    const a = generateRetailDynamicCode(
      jwt ? (jwt.merchantName ? jwt.merchantName : null) : null, //Merchant Name
      jwt ? (jwt.merchantID ? jwt.merchantID : null) : null, //Merchant ID
      date, //Invoice Number
      null, //Currency Code
      cash, //Total Amount
      false, //Tip Flag
      false, //Tip Flat Flag
      0, //Tip Value
      'terminal-001', //Terminal ID
      'nayapay77@testing.com' // NayaPayID
    );
    nayaPay(date, a);
    setNayaPayQRCode(a);
  }
  function handleGenerateQRCode() {
    NayaPayQRCodeGenerator();
    setGenerateQr(true);
  }
  async function NayaPayPushInvoice(invoiceId, consumer, amount) {
    return APIService.NayaPayPushInvoice(invoiceId, consumer, amount).then(
      (Response) => {
        return Response;
      }
    );
  }
  function handleNayaPayPushInvoice() {
    let cash = (netTotal - discount).toFixed(2);
    try {
      if (nayaPayId.length > 4) {
        NayaPayPushInvoice(invoiceNo, nayaPayId, cash).then((Response) => {
          if (Response.success) {
            setGeneratePushInvoice(false);
            nayaPay(invoiceNo, null);
            GenerateNayaPayInvoice();
            setNotify({
              isOpen: true,
              message: 'Invoice has been Pushed',
              type: 'success',
              notificationTime: 5000,
            });
          } else {
            setNotify({
              isOpen: true,
              message: `${Response.errors[0].error}`,
              type: 'error',
              notificationTime: 5000,
            });
          }
        });
      } else {
        setNotify({
          isOpen: true,
          message: `Enter Valid Naya Pay ID`,
          type: 'warning',
          notificationTime: 5000,
        });
      }
    } catch (e) {
      //do nothing
    }
  }
  function handleCallPushInvoice() {
    let date = getInvoiceNumber();
    date = date.substring(date.length - 10, date.length);
    setInvoiceNo(date);
    setGeneratePushInvoice(true);
  }
  async function fetchSalesforShift(
    page,
    rowsPerPage,
    sortDirection,
    sortColumnName
  ) {
    return APIService.getAllSales(
      page,
      rowsPerPage,
      sortDirection,
      sortColumnName
    );
  }
  const headCells = [
    { id: 'serialNo', label: 'S.No', align: 'left' },
    {
      id: 'createdAt',
      label: 'Date & Time',
      disableSorting: true,
      align: 'center',
    },
    {
      id: 'itemsSold',
      label: 'Items Sold',
      disableSorting: true,
      align: 'center',
    },
    {
      id: 'itemsReturned',
      label: 'Items Returned',
      disableSorting: true,
      align: 'center',
    },
    {
      id: 'saleAmountTotal',
      label: 'Net Sale Amount',
      disableSorting: true,
      align: 'center',
    },
  ];

  //prettier-ignore
  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTableServerSide(rows, rowsCount, headCells, fetchSales);

  function fetchSales(page, rowsPerPage, sortDirection, sortColumnName) {
    setDisplay('block');
    fetchSalesforShift(page, rowsPerPage, sortDirection, sortColumnName).then(
      (Response) => {
        if (Response.data) {
          var data = Response.data.data;
          setRows(data);
          setRowsCount(Response.data.count);
          setIsLoaded(true);
        }
      },
      () => {
        setIsLoaded(false);
      }
    );
  }

  function handleFetchSales() {
    fetchSales(0, 5, -1, 'updatedAt');
  }

  function EndShiftSalesDetails(obj) {
    setRecords(obj);
    setEndShiftSaleDetail('none');
    setEndShiftSaleDetailTab('block');
  }
  const [records, setRecords] = useState([]);
  function endShiftSaleDetailTabBack() {
    setEndShiftSaleDetail('block');
    setEndShiftSaleDetailTab('none');
  }
  function hideSales() {
    setEndShiftSaleDetail('block');
    setEndShiftSaleDetailTab('none');
    setIsLoaded(false);
  }
  function handleCategoryClick(index) {
    changeActiveCategory(index);
    setIsCategorySelected(false);
    catListIndex = 0;
  }
  function handleCategoryClickBack() {
    setIsCategorySelected(true);
    itemListIndex = 0;
  }
  function control(e, index) {
    const newsalesReturnList = { ...salesReturnList };
    let newQuantity = 0;
    if (e.target.value <= newsalesReturnList.itemsSold[index].quantity) {
      newQuantity = e.target.value;
    } else {
      newQuantity = newsalesReturnList.itemsSold[index].quantity;
      setNotify({
        isOpen: true,
        message: 'You are Trying to Exceed Max Limit',
        type: 'warning',
        notificationTime: 5000,
      });
    }
    newsalesReturnList.itemsSold[index].returnQty = newQuantity;
    newsalesReturnList.itemsSold[index].refundAmount = (
      newsalesReturnList.itemsSold[index].saleFinalPrice * newQuantity
    ).toFixed(2);
    setSalesReturnList(newsalesReturnList);
  }
  function controlDiscount(e, index) {
    const newproductListInMainBody = [...productListInMainBody];
    if (
      parseFloat(
        e.target.value
          ? e.target.value
          : productListInMainBody[index].minDiscountPercentage
      ) >= parseFloat(productListInMainBody[index].minDiscountPercentage) &&
      parseFloat(
        e.target.value
          ? e.target.value
          : productListInMainBody[index].minDiscountPercentage
      ) <= parseFloat(productListInMainBody[index].maxDiscountPercentage)
    ) {
      newproductListInMainBody[index].discountPercentage = e.target.value;
      let discountPercent = productListInMainBody[index].discountPercentage
        ? productListInMainBody[index].discountPercentage
        : 0;
      let itemDiscount =
        (discountPercent / 100) *
        productListInMainBody[index].unitSaleFinalPrice *
        productListInMainBody[index].Quantity;

      productListInMainBody[index].Tempdiscount = itemDiscount.toFixed(2);

      productListInMainBody[index].saleFinalPrice = (
        productListInMainBody[index].unitSaleFinalPrice *
          productListInMainBody[index].Quantity -
        itemDiscount
      ).toFixed(2);
      setproductListInMainBody(newproductListInMainBody);
    } else if (
      parseFloat(
        e.target.value
          ? e.target.value
          : productListInMainBody[index].minDiscountPercentage
      ) <= parseFloat(productListInMainBody[index].minDiscountPercentage)
    ) {
      newproductListInMainBody[index].discountPercentage =
        productListInMainBody[index].minDiscountPercentage;

      newproductListInMainBody[index].Tempdiscount =
        (newproductListInMainBody[index].discountPercentage *
          (productListInMainBody[index].Quantity *
            productListInMainBody[index].unitSaleFinalPrice)) /
        100;
      newproductListInMainBody[index].saleFinalPrice =
        productListInMainBody[index].Quantity *
          productListInMainBody[index].unitSaleFinalPrice -
        newproductListInMainBody[index].Tempdiscount;
      setproductListInMainBody(newproductListInMainBody);

      setNotify({
        isOpen: true,
        message: 'You are Trying to Go Below Limit',
        type: 'warning',
        notificationTime: 5000,
      });
    } else if (
      parseFloat(
        e.target.value
          ? e.target.value
          : productListInMainBody[index].minDiscountPercentage
      ) >= parseFloat(productListInMainBody[index].maxDiscountPercentage)
    ) {
      newproductListInMainBody[index].discountPercentage =
        productListInMainBody[index].maxDiscountPercentage;

      newproductListInMainBody[index].Tempdiscount =
        (newproductListInMainBody[index].discountPercentage *
          (productListInMainBody[index].Quantity *
            productListInMainBody[index].unitSaleFinalPrice)) /
        100;
      newproductListInMainBody[index].saleFinalPrice = (
        productListInMainBody[index].Quantity *
          productListInMainBody[index].unitSaleFinalPrice -
        newproductListInMainBody[index].Tempdiscount
      ).toFixed(2);
      setproductListInMainBody(newproductListInMainBody);

      setNotify({
        isOpen: true,
        message: 'You are Trying to Exceed Max Limit',
        type: 'warning',
        notificationTime: 5000,
      });
    } else {
      newproductListInMainBody[index].discountPercentage =
        productListInMainBody[index].maxDiscountPercentage;

      newproductListInMainBody[index].Tempdiscount =
        (newproductListInMainBody[index].discountPercentage *
          (productListInMainBody[index].Quantity *
            productListInMainBody[index].unitSaleFinalPrice)) /
        100;
      newproductListInMainBody[index].saleFinalPrice =
        productListInMainBody[index].Quantity *
          productListInMainBody[index].unitSaleFinalPrice -
        newproductListInMainBody[index].Tempdiscount;
      setproductListInMainBody(newproductListInMainBody);

      setNotify({
        isOpen: true,
        message: 'You are Trying to Exceed Max Limit',
        type: 'warning',
        notificationTime: 5000,
      });
    }
  }
  function controlQuantity(e, index) {
    const newproductListInMainBody = [...productListInMainBody];
    newproductListInMainBody[index].Quantity =
      e.target.value < 0 ? 1 : e.target.value;

    let discountPercent = productListInMainBody[index].discountPercentage
      ? productListInMainBody[index].discountPercentage
      : 0;
    let itemDiscount =
      (discountPercent / 100) *
      productListInMainBody[index].unitSaleFinalPrice *
      newproductListInMainBody[index].Quantity;

    productListInMainBody[index].Tempdiscount = itemDiscount.toFixed(2);

    productListInMainBody[index].saleFinalPrice = (
      productListInMainBody[index].unitSaleFinalPrice *
        newproductListInMainBody[index].Quantity -
      itemDiscount
    ).toFixed(2);

    setproductListInMainBody(newproductListInMainBody);
  }
  function reCalculateFooter() {
    var ttlItems = 0;
    var amtWithoutTaxs = 0;
    var taxs = 0;
    var ttldiscount = 0;
    var netTotals = 0;
    ttlItems = productListInMainBody.length;
    productListInMainBody.forEach(function (item) {
      amtWithoutTaxs =
        parseFloat(amtWithoutTaxs) +
        parseFloat(item.salePrice) * parseFloat(item.Quantity);
      taxs =
        taxs +
        (parseFloat(item.unitSaleFinalPrice) - parseFloat(item.unitSalePrice)) *
          parseFloat(item.Quantity);
      netTotals =
        parseFloat(netTotals) +
        parseFloat(item.unitSaleFinalPrice) * parseFloat(item.Quantity);
      ttldiscount = parseFloat(ttldiscount) + parseFloat(item.Tempdiscount);
    });
    setTotalItems(ttlItems);
    setAmountWithoutTax(amtWithoutTaxs.toFixed(2));
    setTax(taxs.toFixed(2));
    setDiscount(ttldiscount.toFixed(2));
    setNetTotal(netTotals.toFixed(2));
  }

  function controlMiscCharges(value) {
    if (parseFloat(value ? value : 0) <= parseFloat(miscChargesLimit.charges)) {
      setMiscellaneousCharges(value);
    } else {
      setMiscellaneousCharges(miscChargesLimit.charges);
      setNotify({
        isOpen: true,
        message: 'You are Trying to Exceed Max Limit',
        type: 'warning',
        notificationTime: 5000,
      });
    }
  }

  function controlOverAllDiscount(e) {
    if (discountinfo.Discount[0].DiscountName === 'discountLimitPercentage') {
      if (
        parseFloat(e ? e : 0) <=
        parseFloat(discountinfo.Discount[0].DiscountLimit)
      ) {
        setFinalDiscount(e);
        setDiscountMain(((amountWithoutTax * e) / 100).toFixed(2));
      } else {
        setFinalDiscount(discountinfo.Discount[0].DiscountLimit);
        setDiscountMain(
          (
            (amountWithoutTax * discountinfo.Discount[0].DiscountLimit) /
            100
          ).toFixed(2)
        );
        setNotify({
          isOpen: true,
          message: 'You are Trying to Exceed Max Limit',
          type: 'warning',
          notificationTime: 5000,
        });
      }
    } else if (
      discountinfo.Discount[0].DiscountName === 'discountLimitAmount'
    ) {
      if (
        parseFloat(e ? e : 0) <=
        parseFloat(discountinfo.Discount[0].DiscountLimit)
      ) {
        setFinalDiscount(e);
        setDiscountMain(e);
      } else {
        setFinalDiscount(
          parseFloat(discountinfo.Discount[0].DiscountLimit).toFixed(2)
        );
        setDiscountMain(
          parseFloat(discountinfo.Discount[0].DiscountLimit).toFixed(2)
        );

        setNotify({
          isOpen: true,
          message: 'You are Trying to Exceed Max Limit',
          type: 'warning',
          notificationTime: 5000,
        });
      }
    }
  }
  function keyPressDownForSearchCustomer(e) {
    if (e.keyCode === 13) {
      APIService.GetCustomerData(e.target.value).then((Response) => {
        if (Response.length > 0) {
          setCustomerList(Response);
        }
      });
      e.target.value = '';
    }
  }
  function handleCustomerAppend(customer) {
    setCustomerinfo(customer);
    setIsCustomerAttached(true);
    handleCloseCustomerModal();
  }
  function handleCustomerremove() {
    setCustomerinfo();
    setCustomerList([]);
    setIsCustomerAttached(false);
  }
  function keyPressDownForSalesReturn(e) {
    if (e.keyCode === 13) {
      APIService.GetSalesData(e.target.value).then((Response) => {
        if (Response.length > 0) {
          if (Response[0].itemsReturned.length) {
            setNotify({
              isOpen: true,
              message: 'Items Are Already Returned Against This Invoice Number',
              type: 'warning',
              notificationTime: 3000,
            });
          } else {
            Response[0].itemsSold.forEach((item) => {
              if (item.itemRef) {
                item['returnQty'] = 0;
                item['refundAmount'] = 0;
              }
            });
            setSalesReturnList(Response[0]);
            setSalesReturnBtnDisableStatus(false);
          }
        } else {
          setSalesReturnList({ itemsSold: [] });
        }
      });
      e.target.value = '';
    }
  }
  function findByBarcode(Barcode) {
    var result = null;
    const obj = categories;
    for (const inobj of obj) {
      for (const innerobj of inobj.products) {
        if (
          (Barcode && innerobj.barcode === Barcode) ||
          (Barcode && innerobj.barcode === Barcode.toString().toUpperCase()) ||
          (Barcode && innerobj.barcode === Barcode.toString().toLowerCase())
        ) {
          return innerobj;
        }
      }
    }
    return result;
  }

  function findBySKU(sku) {
    var result = null;

    const obj = categories;
    for (const inobj of obj) {
      for (const innerobj of inobj.products) {
        if (innerobj.sku === sku) {
          return innerobj;
        }
      }
    }
    return result;
  }

  function findByProductId(ProductId) {
    var result = null;

    const obj = categories;
    for (const inobj of obj) {
      for (const innerobj of inobj.products) {
        if (innerobj._id === ProductId) {
          return innerobj;
        }
      }
    }
    console.log({ obj }, 'in context-------', { ProductId }, { result });
    return result;
  }
  function SIGNOUT() {
    const Val = localStorage.getItem('_IdShift');
    setsignout(true);
    if (Val) {
      handleOpen5();
    } else {
      setsignout(false);
      localStorage.removeItem('Token');
      localStorage.removeItem('_IdShift');
      localStorage.removeItem('NayaPayActivation');
      localStorage.removeItem('role');
      localStorage.removeItem('_DiscountInfo');
      localStorage.removeItem('CompanyName');
      localStorage.removeItem('Company Logo');
      localStorage.removeItem('TemplateType');
      localStorage.removeItem('type');
      localStorage.removeItem('_Misc');
      localStorage.removeItem('_ShortKeys');
      localStorage.removeItem('_Commission');
      localStorage.removeItem('_HideFeature');
      history.push('/Signin');
    }
  }

  function handleBarCodeScan(data) {
    let found = findBySKU(data);
    if (found === null) {
      found = findByBarcode(data);
      if (found !== null) {
        selectProductForMainBody(found);
      }
    } else {
      selectProductForMainBody(found);
    }
  }

  function onChange(e) {
    const suggestions = localStorage.getItem('Search')
      ? JSON.parse(localStorage.getItem('Search'))
      : [];
    const userInput = e.currentTarget.value;

    const filteredSuggestions = suggestions.filter(
      (suggestion) =>
        suggestion.name.toLowerCase().indexOf(userInput.toLowerCase()) > -1 ||
        suggestion.barcode.toLowerCase().indexOf(userInput.toLowerCase()) >
          -1 ||
        (suggestion.sku &&
          suggestion.sku.toLowerCase().indexOf(userInput.toLowerCase()) > -1)
    );

    setRecordSets({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value,
    });
  }
  function onClick(e, id, name) {
    setRecordSets({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: name,
    });
    const found = findByProductId(id);
    if (found === null) {
      //do nothing just exit from loop
    } else {
      selectProductForMainBody(found);
    }
  }
  function handleClickClearText() {
    setRecordSets({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: '',
    });
  }
  function handleExpenseSave(data) {
    SalesAPIService.addExpense(data)
      .then((addExp) => {
        if (
          addExp &&
          addExp.data &&
          addExp.data.data &&
          addExp.data.data.amount
        ) {
          setTotalExpense(totalExpense + addExp.data.data.amount);
          setNotify({
            isOpen: true,
            message: 'Expense Added Successfully!',
            type: 'success',
            notificationTime: 3000,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setNotify({
          isOpen: true,
          message: 'Unable to Add Expense!',
          type: 'error',
          notificationTime: 3000,
        });
      });
  }
  useEffect(() => {
    (async () => {
      const expense = await SalesAPIService.getTotalExpense();
      let totalExpenseAmount = 0;
      if (
        expense &&
        expense.data &&
        expense.data.data &&
        expense.data.data.length
      ) {
        for (let exp of expense.data.data) {
          totalExpenseAmount += exp.amount;
        }
        setTotalExpense(totalExpenseAmount);
      }
    })();
  }, []);

  const [systemType, setSystemType] = React.useState('pos');

  const handleSystemType = (event, selectedType) => {
    if (selectedType !== null) {
      setSystemType(selectedType);
      if (selectedType === 'pos') {
        setSelectedIndex(0);
      }
    }
  };

  const [recordSets, setRecordSets] = useState({
    activeSuggestion: 0,
    filteredSuggestions: [],
    showSuggestions: false,
    userInput: '',
  });
  let suggestionsListComponent;
  if (recordSets.showSuggestions && recordSets.userInput) {
    if (recordSets.filteredSuggestions.length) {
      suggestionsListComponent = (
        <List className={classes.SearchListStyling}>
          {recordSets.filteredSuggestions
            .slice(0, offSet)
            .map((suggestion, index) => {
              return (
                <>
                  <ListItem
                    button
                    key={index}
                    id={'searchbaritems' + index}
                    onClick={(e) => onClick(e, suggestion._id, suggestion.name)}
                  >
                    <ListItemText
                      primary={suggestion.name}
                      secondary={
                        <div style={{ marginTop: 5 }}>
                          <p style={{ margin: 0 }}>
                            Barcode:{suggestion.barcode}
                          </p>
                          <p style={{ margin: 0 }}>SKU:{suggestion.sku}</p>
                        </div>
                      }
                    />
                  </ListItem>
                  <Divider />
                </>
              );
            })}
          {recordSets.filteredSuggestions.length > offSet ? (
            <button
              style={{
                width: '250px',
                height: '40px',
                fontWeight: 'bold',
                backgroundColor: '#1a76d2',
                color: '#ffffff',
                border: 'none',
                borderRadius: '5px',
              }}
              onClick={() => {
                setOffSet(offSet + 25);
              }}
            >
              Click here to load more products
            </button>
          ) : (
            <></>
          )}
          ;
        </List>
      );
    } else {
      suggestionsListComponent = (
        <List className={classes.SearchListStyling}>
          <ListItem key={'0'}>
            <ListItemText primary={<em>No suggestions available.</em>} />
          </ListItem>
        </List>
      );
    }
  }

  const history = useHistory();

  //Main Body Code Starts From Here
  /////////////////////////////////////MAIN BODY - STARTS FROM HERE/////////////////////////////////////
  return (
    <div>
      <div id="print-mount"></div>

      <CustomerModal
        openCustomer={openCustomer}
        handleCloseCustomerModal={handleCloseCustomerModal}
        classes={classes}
        modalStyle={modalStyle}
        keyPressDownForSearchCustomer={keyPressDownForSearchCustomer}
        handleCustomerAppend={handleCustomerAppend}
        customerList={customerList}
        customerTableRef={customerTableRef}
      />
      <ExpenseModal
        openExpense={openExpense}
        handleCloseExpenseModal={handleCloseExpenseModal}
        classes={classes}
        modalStyle={modalStyle}
        totalExpense={totalExpense}
        handleExpenseSave={handleExpenseSave}
      />
      <EndShiftModal
        open5={open5}
        handleClose5={handleClose5}
        classes={classes}
        modalStyle={modalStyle}
        endShiftSaleDetail={endShiftSaleDetail}
        expectedAmount={expectedAmount}
        closingAmount={closingAmount}
        setClosingAmount={setClosingAmount}
        internalSaleAmount={internalSaleAmount}
        setInternalSaleAmount={setInternalSaleAmount}
        comments={comments}
        setComments={setComments}
        handleShiftEnd={handleShiftEnd}
        Error={endShiftError}
        PrintEndShift={PrintEndShift}
        isLoaded={isLoaded}
        handleFetchSales={handleFetchSales}
        hideSales={hideSales}
        display={display}
        EndShiftSalesDetails={EndShiftSalesDetails}
        endShiftSaleDetailTab={endShiftSaleDetailTab}
        TblContainer={TblContainer}
        TblHead={TblHead}
        recordsAfterPagingAndSorting={recordsAfterPagingAndSorting}
        TblPagination={TblPagination}
        records={records}
        endShiftSaleDetailTabBack={endShiftSaleDetailTabBack}
        totalExpense={totalExpense}
      />
      <SalesReturnModal
        openSalesReturn={openSalesReturn}
        classes={classes}
        modalStyle={modalStyle}
        keyPressDownForSalesReturn={keyPressDownForSalesReturn}
        salesReturnList={salesReturnList}
        control={control}
        modleCloseSalesReturn={modleCloseSalesReturn}
        saveSalesReturn={saveSalesReturn}
        salesReturnBtnDisableStatus={salesReturnBtnDisableStatus}
      />
      <NayaPayModal
        openNayaPay={openNayaPay}
        modleCloseNayaPay={modleCloseNayaPay}
        classes={classes}
        modalStyle={modalStyle}
        generateQr={generateQr}
        generatePushInvoice={generatePushInvoice}
        handleGenerateQRCode={handleGenerateQRCode}
        handleCallPushInvoice={handleCallPushInvoice}
        nayaPayQRCode={nayaPayQRCode}
        setGenerateQr={setGenerateQr}
        GenerateNayaPayInvoice={GenerateNayaPayInvoice}
        nayaPayId={nayaPayId}
        setNayaPayId={setNayaPayId}
        setGeneratePushInvoice={setGeneratePushInvoice}
        handleNayaPayPushInvoice={handleNayaPayPushInvoice}
      />
      <HoldOrderModal
        open3={open3}
        handleClose3={handleClose3}
        classes={classes}
        modalStyle={modalStyle}
        holdOrder={holdOrder}
        HoldOrderReload={HoldOrderReload}
        HoldOrderRemove={HoldOrderRemove}
        heldOrderTableRef={heldOrderTableRef}
      />
      <StartShiftModal
        open4={open4}
        handleClose4={handleClose4}
        classes={classes}
        openingamount={openingamount}
        setOpeningamount={setOpeningamount}
        handleOpeningAmount={handleOpeningAmount}
      />
      <ShortcutsInfoModal
        openShortcutInfo={openShortcutInfo}
        handleCloseShortcutInfo={handleCloseShortcutInfo}
        classes={classes}
      />
      <BarcodeReader onScan={handleBarCodeScan} />
      <Header
        classes={classes}
        UserRole={UserRole}
        handleOpen4={handleOpen4}
        shiftButton={shiftButton}
        handleOpen5={handleOpen5}
        syncTime={syncTime}
        InvoiceSync={InvoiceSync}
        SIGNOUT={SIGNOUT}
        CompanyName={CompanyName}
        signoutRef={signoutRef}
        isShortcutEnable={isShortcutEnable}
      />
      <Grid container spacing={0}>
        <Grid item xs={12} className={`${shiftButton ? 'd-block' : 'd-none'}`}>
          <TopActionBar
            classes={classes}
            handleChange9={handleChange9}
            onChange={onChange}
            recordSets={recordSets}
            handleClickClearText={handleClickClearText}
            suggestionsListComponent={suggestionsListComponent}
            systemType={systemType}
            handleSystemType={handleSystemType}
            handleOpen3={handleOpen3}
            holdOrderFromMainBody={holdOrderFromMainBody}
            modalOpenSalesReturn={modalOpenSalesReturn}
            clearProductFromMainBody={clearProductFromMainBody}
            isCustomerAttached={isCustomerAttached}
            totalItems={totalItems}
            handleCustomerremove={handleCustomerremove}
            customerinfo={customerinfo}
            openCustomerModal={openCustomerModal}
            openExpenseModal={openExpenseModal}
            productListInMainBody={productListInMainBody}
            searchFocusRef={searchFocusRef}
            holdOrderRef={holdOrderRef}
            removeCustRef={removeCustRef}
            isShortcutEnable={isShortcutEnable}
            handleOpenShortcutInfo={handleOpenShortcutInfo}
          />
        </Grid>
        <Grid className="posMainDiv gridStyle" item xs={12}>
          <Grid
            item
            className={`mainCartGrid ${shiftButton ? 'd-block' : 'd-none'}`}
          >
            <MainCartBody
              classes={classes}
              productListInMainBody={productListInMainBody}
              controlQuantity={controlQuantity}
              controlDiscount={controlDiscount}
              RemoveItemFromMainBody={RemoveItemFromMainBody}
              productTableRef={productTableRef}
              quantityChangeRef={quantityChangeRef}
            />
          </Grid>
          <CheckoutSideBar
            classes={classes}
            shiftButton={shiftButton}
            cal={cal}
            Cash={Cash}
            PayCash={PayCash}
            PayCreditCard={PayCreditCard}
            productListInMainBody={productListInMainBody}
            systemType={systemType}
            setNotify={setNotify}
            setCalValue={setCalValue}
            modleOpenNayaPay={modleOpenNayaPay}
            anchorRef={anchorRef}
            customerinfo={customerinfo}
            handleClick={handleClick}
            calValue={calValue}
            discount={discount}
            options={options}
            selectedIndex={selectedIndex}
            handleToggle={handleToggle}
            discountMain={discountMain}
            netTotal={netTotal}
            miscellaneousCharges={miscellaneousCharges}
            miscellaneousChargesRef={miscellaneousChargesRef}
            controlMiscCharges={controlMiscCharges}
            miscChargesLimit={miscChargesLimit}
            handleClose={handleClose}
            handleMenuItemClick={handleMenuItemClick}
            discountBitFields={discountBitFields}
            controlOverAllDiscount={controlOverAllDiscount}
            finalDiscount={finalDiscount}
            signDiscount={signDiscount}
            open={open}
            recalculateTotals={reCalculateFooter}
            cashCheckoutRef={cashCheckoutRef}
            cCardCheckoutRef={cCardCheckoutRef}
            nayaPayCheckoutRef={nayaPayCheckoutRef}
            creditCheckoutRef={creditCheckoutRef}
            isShortcutEnable={isShortcutEnable}
            adjuestmentRef={adjuestmentRef}
            paidRef={paidRef}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            background: '#EEEEEE',
            bottom: '0',
            position: 'fixed',
            width: '100%',
          }}
        >
          <Paper className={styles.paper16} elevation={0} square>
            POWERED BY CONCAVE POS
          </Paper>
        </Grid>
        <ProductListSlider
          classes={classes}
          checked={checked}
          shiftButton={shiftButton}
          isCategorySelected={isCategorySelected}
          categories={categories}
          handleCategoryClick={handleCategoryClick}
          activeCategory={activeCategory}
          handleCategoryClickBack={handleCategoryClickBack}
          productSearchCatWise={productSearchCatWise}
          setProductSearchCatWise={setProductSearchCatWise}
          selectProductForMainBody={selectProductForMainBody}
          listRef={listRef}
          itemListRef={itemListRef}
          backCatBtnRef={backCatBtnRef}
        />
      </Grid>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
  /////////////////////////////////////MAIN BODY - ENDS HERE       /////////////////////////////////////
}
export default POS;
