import { decode } from 'jsonwebtoken';
import { insertActivityMonitoring } from './service';

export async function reCachedEverything() {
  console.log('Clearing cache and hard reloading...');
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then(function (names) {
      for (let name16 of names) caches.delete(name16);
    });
    const namesCached = await caches.keys();
    await Promise.all(namesCached.map((name32) => caches.delete(name32)));
  }
  // delete browser cache and hard reload
  window.location.reload();
}

export default async function activityMonitoring(page) {
  try {
    const Token = localStorage.getItem('Token');
    if (Token) {
      const decodedToken = decode(Token, { complete: true });
      if (decodedToken && decodedToken.payload && decodedToken.payload.data) {
        insertActivityMonitoring({
          page,
          organizationName: decodedToken.payload.data.CName,
          userEmail: decodedToken.payload.data.email,
        })
          .then((response) => {
            console.log(response, 'saved Data in Analytics DB');
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
    return true;
  } catch (error) {
    console.error(error);
  }
}
