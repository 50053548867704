import APIService from './SalesAPIService';
import { ValueNotNull } from '../../../Common/constants';
export async function ShiftSync() {
  try {
    let TempList = JSON.parse(localStorage.getItem('_StackShift'));
    let index = TempList.length;
    if (index > 0) {
      let data = TempList[index - 1];

      let expectedAmt = async function (d) {
        const expectedAmts = new Promise((resolve) => {
          APIService.GetTotalSaleAmount(
            d.shiftNumber,
            d.createdBy,
            d.createdAt,
            d.updatedAt
          ).then((value) => resolve(value));
        });
        return expectedAmts
          .then((response) => {
            return response;
          })
          .then(function (Data) {
            return ValueNotNull(Data)
              ? parseFloat(
                  parseFloat(sumofarryValue(Data)) + parseFloat(d.openingAmount)
                ).toFixed(2)
              : d.openingAmount;
          });
      };
      await expectedAmt(data).then(function (val) {
        data.ExpectedAmount = val;
        let promise = APIService.ShiftSyncRequest(data);
        promise.then((response) => {
          if (response) {
            TempList.splice(index - 1, 1);
            localStorage.setItem('_StackShift', JSON.stringify(TempList));
            ShiftSync();
          }
        });
      });
    }
    if (index <= 0) {
      //Indicator of Successfully Sync Shift
    }
  } catch (error) {
    //do nothing just exit from loop
  }
}
export function sumofarryValue(ArrayValue) {
  var sum = 0;
  ArrayValue.forEach((element) => {
    sum = sum + element.saleAmountTotal;
  });
  return sum.toFixed(2);
}
