import React, { useEffect, useState } from 'react';
import PageTitle from '../../Layout/PageTitle';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import {
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  TextField,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import Controls from '../../Utilities/Controls/Controls';
import SearchIcon from '@material-ui/icons/Search';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import { useHistory } from 'react-router-dom';
import useTableServerSide from '../../Utilities/ReuseableCommponents/useTableServerSide';
import useStyles from '../../../Style/AppStylesAdmin';
import { getAllBillings, getBillings, updateBilling } from './BillingService';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CircularLoader from '../../Utilities/ReuseableCommponents/CircularLoader';

const headCells = [
  { id: 'month', label: 'Month', align: 'left' },
  { id: 'companyName', label: 'Company Name', align: 'left' },
  { id: 'version', label: 'Version', align: 'left' },
  { id: 'status', label: 'Bill Status', align: 'left' },
  { id: 'monthlyCharges', label: 'Amount', align: 'left' },
  { id: 'action', label: 'Action', disableSorting: true, align: 'right' },
];
const monthNames = [
  { name: 'January', _id: 0 },
  { name: 'February', _id: 1 },
  { name: 'March', _id: 2 },
  { name: 'April' },
  { name: 'May' },
  { name: 'June' },
  { name: 'July' },
  { name: 'August' },
  { name: 'September' },
  { name: 'October' },
  { name: 'November' },
  { name: 'December' },
];
const featureList = [
  { name: 'EasyPos' },
  { name: 'Standered' },
  { name: 'Premium' },
];
const billStatus = [{ name: 'Paid' }, { name: 'Pending' }];

export default function Billing() {
  const classes = useStyles();
  const history = useHistory();
  const [records, setRecords] = useState([]);
  const [recordsCount] = useState(0);
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [monthForSearch, setMonthForSearch] = useState('');
  const [version, setVersion] = useState('');
  const [status, setStatus] = useState('');

  function getRecords() {
    setIsLoading(true);
    getAllBillings()
      .then((companies) => {
        console.log(companies.data.data);
        if (companies.data && companies.data.data) {
          setRecords(companies.data.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  }
  function handleSearch() {
    let searchString = '';
    if (monthForSearch) {
      searchString += `&month=${monthForSearch}`;
    }
    if (name) {
      searchString += `&companyName=${name}`;
    }
    if (version) {
      searchString += `&version=${version}`;
    }
    if (status) {
      searchString += `&status=${status}`;
    }
    getBillings(0, 5000, 1, '', searchString, 'companyRef')
      .then((billing) => {
        console.log(billing);
        if (billing && billing.data && billing.data.data) {
          setRecords(billing.data.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    try {
      console.log(monthForSearch);
      setIsLoading(true);
      getAllBillings()
        .then((companies) => {
          console.log(companies.data.data);
          if (companies.data && companies.data.data) {
            setRecords(companies.data.data);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(error);
        });
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }, []);
  const { TblContainer, TblHead, TblPagination } = useTableServerSide(
    records,
    recordsCount,
    headCells,
    getRecords
  );

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  return (
    <>
      <PageTitle
        title="Billing"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="billingHeading"
      />
      <Paper elevation={6} square className={classes.PaperMargin}>
        <Toolbar disableGutters>
          <Grid container>
            <Grid item xs={12} sm={3} md={3} lg={2}>
              <div className={classes.searchDropdown}>
                <Controls.EditableDropDown
                  name="month_id"
                  label="Month"
                  data={monthNames}
                  id="ddSearchVersion"
                  options={monthNames}
                  onChange={(event, selectedValue) => {
                    if (selectedValue && selectedValue.name) {
                      setMonthForSearch(selectedValue.name);
                    } else {
                      setMonthForSearch('');
                    }
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={2}>
              <TextField
                id="txtSearchCat"
                label="Name"
                className={classes.searchInput}
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={2}>
              <div className={classes.searchDropdown}>
                <Controls.EditableDropDown
                  name="version_id"
                  label="Version"
                  data={featureList}
                  id="ddSearchVersion"
                  options={featureList}
                  onChange={(event, selectedValue) => {
                    if (selectedValue && selectedValue.name) {
                      setVersion(selectedValue.name);
                    } else {
                      setVersion('');
                    }
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={2}>
              <div className={classes.searchDropdown}>
                <Controls.EditableDropDown
                  name="status_id"
                  label="Status"
                  data={billStatus}
                  id="ddSearchStatus"
                  options={billStatus}
                  onChange={(event, selectedValue) => {
                    if (selectedValue && selectedValue.name) {
                      setStatus(selectedValue.name);
                    } else {
                      setStatus('');
                    }
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={2}>
              <Controls.Button
                text="Search"
                variant="contained"
                color="primary"
                id="btnCatSearch"
                onClick={handleSearch}
                className={classes.searchButton}
                startIcon={<SearchIcon />}
              />
            </Grid>
          </Grid>
        </Toolbar>
        <CircularLoader isload={isLoading} />
        <TblContainer>
          <TblHead />
          <TableBody id="catListTbody">
            {records.map((item, index) => (
              <TableRow
                key={item._id}
                id={`catListTrow${index}`}
                name="catListTrow"
                onClick={(e) => {
                  if (!e.target.name) {
                    history.push({
                      pathname: '/BillingDetails',
                      state: {
                        pageHeaderTitile: 'View Billing History',
                        recordForEdit: item,
                      },
                    });
                  }
                }}
              >
                <TableCell id={`cityName${index}`}>{item.month}</TableCell>
                <TableCell id={`cityName${index}`}>
                  {item?.companyName}
                </TableCell>
                <TableCell id={`cityName${index}`}>
                  {item?.companyRef?.version}
                </TableCell>
                <TableCell id={`cityName${index}`}>{item.status}</TableCell>
                <TableCell id={`cityName${index}`}>
                  {item?.monthlyCharges}
                </TableCell>
                <TableCell align="right">
                  <IconButton id={`btnApproved${index}`}>
                    {item.status == 'pending' ? (
                      <CheckCircleOutlineIcon
                        fontSize="medium"
                        color="primary"
                        onClick={async (event) => {
                          event.stopPropagation();
                          await updateBilling({ ...item, status: 'paid' })
                            .then((response) => {
                              console.log(response);
                            })
                            .catch((error) => {
                              console.error(error);
                            });
                          getRecords();
                          setNotify({
                            isOpen: true,
                            message: 'Bill Paid for this Month ',
                            type: 'success',
                          });
                        }}
                      />
                    ) : (
                      <Typography style={{ color: 'green' }}>
                        {item.status}
                      </Typography>
                    )}
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        id_Yes="delCat"
        id_No="dontDelCat"
      />
    </>
  );
}
