import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

function PageTitle(props) {
  const { title, id } = props;
  return (
    <Paper elevation={6} square className="paperTitleMargin">
      <div className="pageHeader">
        <div className="pageTitle">
          <Typography variant="h6" component="div" id={id}>
            {title}
          </Typography>
        </div>
      </div>
    </Paper>
  );
}

PageTitle.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  id: PropTypes.string,
};
export default PageTitle;
