import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  FormControlLabel,
  Divider,
  TextField,
  Select,
  MenuItem,
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import CircularLoader from '../../../Utilities/ReuseableCommponents/CircularLoader';
import Notification from '../../../Utilities/ReuseableCommponents/Notification';
import * as SetupService from '../SetupService';
import Controls from '../../../Utilities/Controls/Controls';
import Switch from '@material-ui/core/Switch';
import SaveIcon from '@material-ui/icons/Save';
import {
  BarcodeCustomeInitialData,
  BarcodeInitialData,
} from '../../../../Common/constants';
import JsBarcode from 'jsbarcode';
import Paper from '@material-ui/core/Paper';
import Slider from '@material-ui/core/Slider';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import Checkbox from '../../../Utilities/Controls/Checkbox';

const useStyles = makeStyles((theme) => ({
  buttonColor: {
    backgroundColor: '#1976d2!important',
    color: '#fff!important',
    borderColor: '#1976d2',
  },
  paper: {
    display: 'flex',
    border: `1px solid ${theme.palette.divider}`,
    flexWrap: 'wrap',
    width: '40%',
    justifyContent: 'center',
  },
  divider: {
    margin: theme.spacing(1, 0.5),
  },
}));

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);

export default function BarcodeTab() {
  const styles = useStyles();
  const paddingValue = '0000000000000';
  const [valueBarcode, setValueBarcode] = useState([]);
  const [valueCustomizeBarcode, setValueCustomizeBarcode] = useState([]);
  const [setUpDefaultData, setsetUpDefaultData] = useState(false);
  const [priceBox, setPriceBox] = useState(false);
  const [productName, setProductName] = useState(false);
  const [companyName, setCompanyName] = useState(false);
  const [contactNumber, setContactNumber] = useState(false);
  const [barcodePrint, setBarcodePrint] = useState({
    example: '',
    prefix: '',
    suffix: '',
    sequenceStart: 0,
    centerPadding: '',
    format: 'CODE128',
    background: '#FFFFFF',
    lineColor: '#000000',
    fontSize: 20,
    height: 100,
    width: 2,
    margin: 10,
    textMargin: 0,
    displayValue: false,
    font: 'monospace',
    fontOptions: ['bold'],
    textAlign: 'center',
    productName: false,
    contactNumber: false,
    price: false,
    companyName: false,
  });
  const shortcutKeysArray = [
    'F',
    'f',
    'H',
    'h',
    'G',
    'g',
    'R',
    'r',
    'C',
    'c',
    'W',
    'w',
    'V',
    'v',
    'O',
    'o',
    'E',
    'e',
    'X',
    'x',
    'D',
    'd',
    'Q',
    'q',
    'P',
    'p',
    'T',
    't',
  ];
  const [isLoaded, setIsLoaded] = useState(false);
  const [sequenceStart, setSequenceStart] = useState(0);
  const handleBarcodeChange = (event, index) => {
    let newdata = { ...valueBarcode };
    newdata[0].data[index].value = event.target.checked;
    setValueBarcode([newdata[0]]);
  };

  useEffect(() => {
    setIsLoaded(true);
    SetupService.getAllSetups().then((Response) => {
      if (Response.data) {
        setIsLoaded(false);
        if (Response.data.count == 0) {
          setsetUpDefaultData(true);
          setNotify({
            isOpen: true,
            message: 'No Data Found',
            type: 'error',
          });
        } else {
          let Barcodedata = Response.data.data
            .filter((e) => e.name === 'Barcode')
            .map((e) => e);
          setValueBarcode(Barcodedata);
          let BarcodeFixesdata = Response.data.data
            .filter((e) => e.name === 'Customize Barcode')
            .map((e) => e);
          if (BarcodeFixesdata.length) {
            setValueCustomizeBarcode(BarcodeFixesdata);
            let printBarcodeResponse = BarcodeFixesdata[0].data[0];
            setProductName(printBarcodeResponse.productName);
            setCompanyName(printBarcodeResponse.companyName);
            setPriceBox(printBarcodeResponse.price);
            setContactNumber(printBarcodeResponse.contactNumber);
            setSequenceStart(printBarcodeResponse.sequenceStart);
            printBarcodeResponse.example = 'EXAMPLE';
            setBarcodePrint(printBarcodeResponse);
          }
        }
      } else console.error('Error While Fetching Data: ', Response.error);
    });
  }, [setUpDefaultData]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  function payloadReady(payload) {
    return {
      name: payload.name,
      data: payload.data,
    };
  }
  async function UpdateSetup(obj) {
    return SetupService.updateSetup(payloadReady(obj[0]), obj[0]._id).then(
      (res) => {
        return res;
      }
    );
  }
  async function BarcodeActivation() {
    var Resp = await SetupService.insertSetup(BarcodeCustomeInitialData).then(
      (res) => {
        return res;
      }
    );
    setValueCustomizeBarcode([Resp.data.data]);
    var Response = await SetupService.insertSetup(BarcodeInitialData).then(
      (res) => {
        setsetUpDefaultData(true);
        return res;
      }
    );
    setValueBarcode([Response.data.data]);
    return Response;
  }

  function handleActivationBarcode() {
    BarcodeActivation().then((Response) => {
      return Response;
    });
  }

  function handleSave() {
    //Customize Barcode
    let barcodePrintObject = barcodePrint;
    if (!barcodePrintObject.displayValue) {
      delete barcodePrintObject.fontOptions;
      delete barcodePrintObject.fontSize;
      delete barcodePrintObject.font;
      delete barcodePrintObject.textAlign;
      delete barcodePrintObject.textMargin;
    }
    barcodePrintObject.productName = productName;
    barcodePrintObject.contactNumber = contactNumber;
    barcodePrintObject.price = priceBox;
    barcodePrintObject.companyName = companyName;
    valueCustomizeBarcode[0].data = [];
    valueCustomizeBarcode[0].data.push(barcodePrintObject);

    setSequenceStart(barcodePrintObject.sequenceStart);
    UpdateSetup(valueCustomizeBarcode).then((Response) => {
      return Response;
    });
    //Barcode
    UpdateSetup(valueBarcode).then((Response) => {
      if (Response.data) {
        setNotify({
          isOpen: true,
          message: 'Data successfully updated',
          type: 'success',
        });
      } else {
        setNotify({
          isOpen: true,
          message: 'Error while updating data',
          type: 'error',
        });
      }
      return Response;
    });
  }

  function validateCode(valid) {
    if (valid) {
      //do something
    } else {
      if (document.getElementById('barcode') != null) {
        document.getElementById('barcode').innerHTML =
          '<p style="color:red;font-weight:bold;">Not a valid text!</p>';
      }
    }
  }

  useEffect(() => {
    JsBarcode(
      '#barcode',
      barcodePrint.prefix +
        paddingValue.slice(paddingValue.length - barcodePrint.centerPadding) +
        barcodePrint.example +
        barcodePrint.suffix,
      {
        format: barcodePrint.format || 'CODE128',
        background: barcodePrint.background || '#FFFFFF',
        lineColor: barcodePrint.lineColor || '#000000',
        fontSize: barcodePrint.fontSize || 20,
        height: parseInt(barcodePrint.height) || 100,
        width: parseInt(barcodePrint.width) || 2,
        margin: barcodePrint.margin || 10,
        textMargin: barcodePrint.textMargin || 0,
        displayValue: barcodePrint.displayValue || false,
        font: barcodePrint.font || 'monospace',
        fontOptions: barcodePrint.fontOptions
          ? barcodePrint.fontOptions.join(' ')
          : 'bold',
        textAlign: barcodePrint.textAlign || 'left',
        valid: validateCode,
      }
    );
  }, [barcodePrint]);

  function handleChange(event) {
    if (event.target.name === 'displayValue') {
      setBarcodePrint({
        ...barcodePrint,
        [event.target.name]: event.target.checked,
      });
    } else {
      setBarcodePrint({
        ...barcodePrint,
        [event.target.name]: event.target.value,
      });
    }
  }

  const handleChangeSlider = (name) => (e, value) => {
    if (value !== null) {
      setBarcodePrint({ ...barcodePrint, [name]: value });
    }
  };

  const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: theme.palette.primary.main,
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });
  return (
    <>
      <CircularLoader isload={isLoaded} />
      {
        <React.Fragment key={'setting'}>
          <Grid container spacing={2}>
            {valueBarcode.length != 0 ? (
              valueBarcode.map((item) => (
                <React.Fragment key={'Barcode'}>
                  <Grid item xs={2}>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      key={'barcode'}
                    >
                      {item.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    {item.data.map((subItem, index) => (
                      <React.Fragment key={'BarcodeSub' + index}>
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              checked={subItem.value}
                              onChange={(e) => handleBarcodeChange(e, index)}
                              name={subItem.name + index}
                            />
                          }
                          id={'sw-cl' + index}
                          key={'sw-cl' + index}
                          label={subItem.name}
                          labelPlacement="top"
                        />
                      </React.Fragment>
                    ))}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider variant="middle" />
                  </Grid>
                </React.Fragment>
              ))
            ) : (
              <React.Fragment key={'initialBarcode'}>
                <Grid item xs={12}>
                  <Controls.Button
                    text="Activate Barcode"
                    variant="contained"
                    color="primary"
                    id="btnactivatebarcode"
                    onClick={handleActivationBarcode}
                  />
                </Grid>
              </React.Fragment>
            )}

            {valueCustomizeBarcode.map((item) => (
              <React.Fragment key={'bCode'}>
                <Grid item xs={2}>
                  <Typography variant="subtitle2" gutterBottom>
                    {item.name}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <div style={{ textAlign: 'center' }}>
                    <svg id="barcode"></svg>
                  </div>
                  <React.Fragment key="custom-barcode">
                    <TextField
                      value={barcodePrint.prefix}
                      label="Prefix"
                      variant="outlined"
                      name="prefix"
                      style={{
                        margin: '5px',
                        marginBottom: '15px',
                        width: '48%',
                      }}
                      autoComplete="off"
                      onChange={(event) => {
                        if (
                          shortcutKeysArray.includes(
                            event.target.value.length === 1
                              ? event.target.value
                              : event.target.value.charAt(
                                  event.target.value.length - 1
                                )
                          )
                        ) {
                          setNotify({
                            isOpen: true,
                            message: 'Reserved Alphabet for shortcut key',
                            type: 'error',
                          });
                        } else {
                          handleChange(event);
                        }
                      }}
                      inputProps={{ maxLength: 5 }}
                    />
                    <TextField
                      value={barcodePrint.suffix}
                      label="Suffix"
                      variant="outlined"
                      name="suffix"
                      style={{
                        margin: '5px',
                        marginBottom: '15px',
                        width: '48%',
                      }}
                      autoComplete="off"
                      onChange={(event) => {
                        if (
                          shortcutKeysArray.includes(
                            event.target.value.length === 1
                              ? event.target.value
                              : event.target.value.charAt(
                                  event.target.value.length - 1
                                )
                          )
                        ) {
                          setNotify({
                            isOpen: true,
                            message: 'Reserved Alphabet for shortcut key',
                            type: 'error',
                          });
                        } else {
                          handleChange(event);
                        }
                      }}
                      inputProps={{ maxLength: 5 }}
                    />
                    <TextField
                      label="Sequence Start From"
                      variant="outlined"
                      value={barcodePrint.sequenceStart}
                      name="sequenceStart"
                      style={{
                        margin: '5px',
                        marginBottom: '15px',
                        width: '48%',
                      }}
                      disabled={sequenceStart > 0}
                      autoComplete="off"
                      type="number"
                      inputProps={{ min: 0 }}
                      onChange={handleChange}
                    />

                    <Select
                      name="format"
                      style={{
                        margin: '5px',
                        marginBottom: '15px',
                        width: '48%',
                      }}
                      label="Type"
                      variant="outlined"
                      value={barcodePrint.format}
                      onChange={handleChange}
                    >
                      <MenuItem key="1" value={'CODE128'}>
                        CODE128
                      </MenuItem>
                      <MenuItem key="2" value={'CODE39'}>
                        CODE39
                      </MenuItem>
                    </Select>
                    <div className="barcodeCheckboxContainer">
                      <Typography className="barcodeSettingCheckBox">
                        Price
                      </Typography>
                      <Checkbox
                        id="priceBox1"
                        checked={priceBox}
                        onChange={(e) => {
                          setPriceBox(e.target.value);
                        }}
                        name={'priceBox'}
                      ></Checkbox>
                      <Typography className="barcodeSettingCheckBox">
                        Product Name
                      </Typography>
                      <Checkbox
                        id="productBox1"
                        checked={productName}
                        onChange={(e) => {
                          setProductName(e.target.value);
                        }}
                        name={'productNameBox'}
                      ></Checkbox>
                      <Typography className="barcodeSettingCheckBox">
                        Company Name
                      </Typography>
                      <Checkbox
                        id="companyNameBox1"
                        checked={companyName}
                        onChange={(e) => {
                          setCompanyName(e.target.value);
                        }}
                        name={'companyNameBox'}
                      ></Checkbox>
                      <Typography className="barcodeSettingCheckBox">
                        Contact Number
                      </Typography>
                      <Checkbox
                        id="contactNumberBox1"
                        checked={contactNumber}
                        onChange={(e) => {
                          setContactNumber(e.target.value);
                        }}
                        name={'contactNumber1'}
                      ></Checkbox>
                    </div>
                    <Grid
                      container
                      xs={12}
                      style={{
                        margin: '5px',
                      }}
                    >
                      <Grid item xs={2}>
                        <Typography display="inline">Center Padding</Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Slider
                          key="centerPadding"
                          value={barcodePrint.centerPadding}
                          name="centerPadding"
                          style={{
                            width: '95%',
                          }}
                          onChange={handleChangeSlider('centerPadding')}
                          valueLabelDisplay="auto"
                          step={1}
                          min={0}
                          max={13}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Typography display="inline">
                          {barcodePrint.centerPadding}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      xs={12}
                      style={{
                        margin: '5px',
                      }}
                    >
                      <Grid item xs={2}>
                        <Typography display="inline">Width</Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Slider
                          key="width"
                          value={barcodePrint.width}
                          name="width"
                          style={{
                            width: '95%',
                          }}
                          onChange={handleChangeSlider('width')}
                          valueLabelDisplay="auto"
                          step={1}
                          min={1}
                          max={4}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Typography display="inline">
                          {barcodePrint.width}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      xs={12}
                      style={{
                        margin: '5px',
                      }}
                    >
                      <Grid item xs={2}>
                        <Typography>Height</Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Slider
                          key="height"
                          name="height"
                          value={barcodePrint.height}
                          style={{
                            width: '95%',
                          }}
                          onChange={handleChangeSlider('height')}
                          valueLabelDisplay="auto"
                          step={5}
                          min={10}
                          max={150}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Typography display="inline">
                          {barcodePrint.height}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      xs={12}
                      style={{
                        margin: '5px',
                      }}
                    >
                      <Grid item xs={2}>
                        <Typography>Margin</Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Slider
                          key="margin"
                          name="margin"
                          value={barcodePrint.margin}
                          onChange={handleChangeSlider('margin')}
                          valueLabelDisplay="auto"
                          style={{
                            width: '95%',
                          }}
                          step={1}
                          min={0}
                          max={25}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Typography display="inline">
                          {barcodePrint.margin}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      xs={12}
                      style={{
                        marginTop: '5px',
                        margin: '5px',
                      }}
                    >
                      <Grid item xs={2}>
                        <Typography>Show text</Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Switch
                          checked={barcodePrint.displayValue}
                          onChange={handleChange}
                          color="primary"
                          name="displayValue"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      </Grid>
                    </Grid>

                    {barcodePrint.displayValue ? (
                      <div>
                        <Grid
                          container
                          xs={12}
                          style={{
                            marginTop: '5px',
                            margin: '5px',
                          }}
                        >
                          <Grid item xs={2}>
                            <Typography style={{ marginTop: 10 }}>
                              Font
                            </Typography>
                          </Grid>
                          <Grid item xs={10}>
                            <Select
                              name="font"
                              variant="outlined"
                              style={{
                                marginBottom: '15px',
                                width: '95%',
                              }}
                              value={barcodePrint.font}
                              onChange={handleChange}
                            >
                              <MenuItem
                                key="monospace"
                                style={{ fontFamily: 'monospace' }}
                                value={'monospace'}
                              >
                                Monospace
                              </MenuItem>
                              <MenuItem
                                key="sans-serif"
                                style={{ fontFamily: 'sans-serif' }}
                                value={'sans-serif'}
                              >
                                Sans-serif
                              </MenuItem>
                              <MenuItem
                                key="serif"
                                style={{ fontFamily: 'serif' }}
                                value={'serif'}
                              >
                                Serif
                              </MenuItem>
                              <MenuItem
                                key="fantasy"
                                style={{ fontFamily: 'fantasy' }}
                                value={'fantasy'}
                              >
                                Fantasy
                              </MenuItem>
                              <MenuItem
                                key="cursive"
                                style={{ fontFamily: 'cursive' }}
                                value={'cursive'}
                              >
                                Cursive
                              </MenuItem>
                            </Select>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          xs={12}
                          style={{
                            marginTop: '5px',
                            margin: '5px',
                          }}
                        >
                          <Grid item xs={2}>
                            <Typography style={{ marginTop: 10 }}>
                              Text style
                            </Typography>
                          </Grid>
                          <Grid item xs={10}>
                            <Paper elevation={0} className={styles.paper}>
                              <StyledToggleButtonGroup
                                size="small"
                                value={barcodePrint.textAlign}
                                exclusive
                                onChange={handleChangeSlider('textAlign')}
                                aria-label="text alignment"
                              >
                                <ToggleButton
                                  value="left"
                                  aria-label="left aligned"
                                >
                                  <FormatAlignLeftIcon />
                                </ToggleButton>
                                <ToggleButton
                                  value="center"
                                  aria-label="centered"
                                >
                                  <FormatAlignCenterIcon />
                                </ToggleButton>
                                <ToggleButton
                                  value="right"
                                  aria-label="right aligned"
                                >
                                  <FormatAlignRightIcon />
                                </ToggleButton>
                              </StyledToggleButtonGroup>
                              <Divider
                                flexItem
                                orientation="vertical"
                                className={styles.divider}
                              />
                              <StyledToggleButtonGroup
                                size="small"
                                value={barcodePrint.fontOptions}
                                onChange={handleChangeSlider('fontOptions')}
                                aria-label="text formatting"
                              >
                                <ToggleButton value="bold" aria-label="bold">
                                  <FormatBoldIcon />
                                </ToggleButton>
                                <ToggleButton
                                  value="italic"
                                  aria-label="italic"
                                >
                                  <FormatItalicIcon />
                                </ToggleButton>
                              </StyledToggleButtonGroup>
                            </Paper>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          xs={12}
                          style={{
                            marginTop: '5px',
                            margin: '5px',
                          }}
                        >
                          <Grid item xs={2}>
                            <Typography>Font Size</Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <Slider
                              key="font-size"
                              name="fontSize"
                              value={barcodePrint.fontSize}
                              style={{
                                width: '95%',
                              }}
                              onChange={handleChangeSlider('fontSize')}
                              valueLabelDisplay="auto"
                              step={1}
                              min={8}
                              max={36}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <Typography display="inline">
                              {barcodePrint.fontSize}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          xs={12}
                          style={{
                            marginTop: '5px',
                            margin: '5px',
                          }}
                        >
                          <Grid item xs={2}>
                            <Typography>Text Margin</Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <Slider
                              key="text-margin"
                              name="textMargin"
                              value={barcodePrint.textMargin}
                              style={{
                                width: '95%',
                              }}
                              onChange={handleChangeSlider('textMargin')}
                              valueLabelDisplay="auto"
                              step={1}
                              min={-15}
                              max={40}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <Typography display="inline">
                              {barcodePrint.textMargin}
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    ) : (
                      <></>
                    )}
                  </React.Fragment>
                </Grid>
                <Grid item xs={12}>
                  <Divider variant="middle" />
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
          {valueBarcode.length != 0 && valueCustomizeBarcode.length != 0 ? (
            <Grid item xs={12}>
              <Controls.Button
                text="Save Changes"
                variant="contained"
                color="primary"
                id="btnProdSearch"
                startIcon={<SaveIcon />}
                onClick={handleSave}
              />
            </Grid>
          ) : (
            <></>
          )}
        </React.Fragment>
      }
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
