import { makeStyles } from '@material-ui/core/styles';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  rootList: {
    display: 'block',
    padding: '20px 10px',
  },
  invalid: {
    '&:invalid': {
      border: 'red solid 2px',
    },
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  pageHeader: {
    padding: theme.spacing(1),
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  pageTitle: {
    paddingLeft: theme.spacing(2),
    '& .MuiTypography-subtitle2': {
      opacity: '0.6',
    },
  },
  titleMargin: {
    margin: '0 8px 16px 8px',
  },
  PaperMargin2: {
    margin: '0 8px 16px 8px',
  },
  PaperMargin: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
    margin: '0 8px 16px 8px',
  },
  paper2: {
    position: 'absolute',
    width: '500px',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  dashboardCards: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '-15px',
    marginBottom: '20px',
  },
  pageContent: {
    padding: theme.spacing(3),
  },
  newButton: {
    float: 'right',
    marginRight: '0 !important',
    marginLeft: '0 !important',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      float: 'none',
    },
  },
  uploadButton: {
    position: 'absolute',
    right: '125px',
  },
  btnCancel: {
    margin: '8px 0 !important',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      float: 'none',
    },
  },
  btnSave: {
    margin: '8px 0 !important',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      float: 'none',
    },
  },
  ButtonsCotainerMargin: {
    marginTop: '20px',
  },
  searchInput: {
    width: '100%',
    paddingRight: '5px',
    margin: '8px 0 !important',
    [theme.breakpoints.down('xs')]: {
      paddingRight: '0 !important',
    },
  },
  searchDropdown: {
    width: '100%',
    paddingRight: '5px',
    margin: '8px 0 !important',
    [theme.breakpoints.down('xs')]: {
      paddingRight: '0 !important',
    },
    '& .MuiInputBase-root': {
      width: '100%',
    },
  },
  searchButton: {
    marginLeft: '0 !important',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  imgWrapper: { position: 'relative', display: 'inline-flex' },
  ITextArea: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    marginRight: -1,
  },
  floatButtonWrapper: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 1000,
  },
  inputRoot: {
    width: 190,
    '@media (max-width:  400px)': {
      width: 160,
    },
    '@media (max-width:  360px)': {
      width: 140,
    },
    '@media (max-width:  340px)': {
      width: 120,
    },
  },
  headLabel: { marginLeft: 10, marginTop: 15, marginBottom: 15, fontSize: 20 },

  img: {
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: theme.shape.borderRadius,
  },
  uploadText: {
    border: '2px dashed #ccc',
    borderRadius: '4px',
    height: '90%',
    width: '95%',
    padding: '15px',
    transition: theme.transitions.create(['color', 'box-shadow', 'border'], {
      duration: theme.transitions.duration.short,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  paperModal: {
    position: 'absolute',
    width: 'auto !important',
    height: 'auto !important',
  },
  pageDateFilter: {
    padding: theme.spacing(2),
  },
  Button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: 100,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: 230,
  },
  cardTitle: {
    color: '#999',
  },
  cardData: {
    color: '#1976d2',
    fontSize: '1.7rem',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  show: {
    display: 'block !important',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    marginTop: '65px',
    paddingBottom: '65px',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2, 1),
    marginTop: '65px',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  nested: {
    paddingLeft: theme.spacing(8),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  iconWidth: {
    minWidth: '40px',
  },
  CellRightAlign: {
    textAlign: 'right',
  },
  BottomButtons: {
    marginTop: '20px',
  },

  flex: {
    display: 'flex',
  },
  table: {
    '& thead th': {
      fontWeight: '500',
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
    },
    '& tbody td': {
      fontWeight: '300',
    },
    '& tbody tr:hover': {
      backgroundColor: '#f9f9f9',
      cursor: 'pointer',
    },
  },
}));

export default useStyles;
