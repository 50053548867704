import React, { useEffect } from 'react';
import authHeader from './../Services/auth-header';
import { BaseAPIURL, initStatePOS, API_VERSION } from '../Common/constants';
import PropTypes from 'prop-types';

export const CTX = React.createContext();

function reducer(state, action) {
  switch (action.type) {
    case 'OnSuccess':
      return { ...state, ...action.payload };
    case 'OnFailure':
      return { ...state };
    default:
      return { ...state };
  }
}

function Store(prop) {
  const [allProducts, dispatch] = React.useReducer(reducer, initStatePOS);
  useEffect(() => {
    var objectMapper = require('object-mapper');
    var FinalLIST = {
      data: {
        categories: [],
      },
    };
    var map = {
      'categoriesRef._id': 'data.categories.[]._id',
      'categoriesRef.name': 'data.categories.[].name',
      '[]._id': 'data.categories.[0].products.[]._id',
      '[]._Cid': 'data.categories.[0].products.[]._Cid',
      '[].category': 'data.categories.[0].products.[].category',
      '[].name': 'data.categories.[0].products.[].name',
      '[].unitSaleFinalPrice':
        'data.categories.[0].products.[].unitSaleFinalPrice',
      '[].salePrice': 'data.categories.[0].products.[].salePrice',
      '[].unitSalePrice': 'data.categories.[0].products.[].unitSalePrice',
      '[].saleFinalPrice': 'data.categories.[0].products.[].saleFinalPrice',
      '[].Quantity': 'data.categories.[0].products.[].Quantity',
      '[].units': 'data.categories.[0].products.[].units',
      '[].unitType': 'data.categories.[0].products.[].unitType',
      '[].taxPercentage': 'data.categories.[0].products.[].taxPercentage',
      '[].Tempdiscount': 'data.categories.[0].products.[].Tempdiscount',
      '[].discountPercentage':
        'data.categories.[0].products.[].discountPercentage',
      '[].unitQuantity': 'data.categories.[0].products.[].unitQuantity',
      '[].barcode': 'data.categories.[0].products.[].barcode',
      '[].minDiscountPercentage':
        'data.categories.[0].products.[].minDiscountPercentage',
      '[].maxDiscountPercentage':
        'data.categories.[0].products.[].maxDiscountPercentage',
      '[].img': 'data.categories.[0].products.[].img',
      '[].sku': 'data.categories.[0].products.[].sku',
      '[].description': 'data.categories.[0].products.[].description',
      '[].unitOfPurchase': 'data.categories.[0].products.[].unitOfPurchase',
      '[].unitOfSale': 'data.categories.[0].products.[].unitOfSale',
      '[].conversionUnit': 'data.categories.[0].products.[].conversionUnit',
    };
    async function fetchData() {
      try {
        const response = await fetch(
          BaseAPIURL +
            `/api/${API_VERSION}/items?populate=categoriesRef&$skip=0&$top=5000000&status=active`,
          {
            method: 'GET',
            headers: authHeader(),
          }
        );
        if (response.ok) {
          let result = await response.json();
          if (result.count > 0) {
            result.data = result.data.filter(function (e) {
              if (e.categoriesRef) {
                return (
                  e.categoriesRef.length > 0 &&
                  e.categoriesRef[0].status === 'active'
                );
              }
              return null;
            });
            result.data.forEach((category) => {
              category['unitQuantity'] = '1';
              category['Quantity'] = '1';
              category['discountPercentage'] = category.minDiscountPercentage;
              category['Tempdiscount'] =
                (category.saleFinalPrice *
                  (category.minDiscountPercentage
                    ? category.minDiscountPercentage
                    : 0)) /
                100;
              category['unitSaleFinalPrice'] = category.saleFinalPrice;
              category['unitSalePrice'] = category.salePrice;
              category['_Cid'] = category.categoriesRef[0]._id;
              category['category'] = category.categoriesRef[0].name;
              if (category.image) {
                category[
                  'img'
                ] = `data:${category.image.contentType};base64,${category.image.data}`;
              }
            });
            let mySet = new Set();
            result.data.map((category) =>
              mySet.add(category.categoriesRef[0]._id)
            );
            mySet.forEach(function (item) {
              var dest = objectMapper(
                result.data.filter((a) => a.categoriesRef[0]._id === item),
                map
              );
              FinalLIST.data.categories.push(dest.data.categories[0]);
            });
            localStorage.setItem('count', JSON.stringify(FinalLIST));
            localStorage.setItem('Search', JSON.stringify(result.data));
            dispatch({ type: 'OnSuccess', payload: FinalLIST });
          } else {
            localStorage.setItem('count', JSON.stringify(initStatePOS));
            localStorage.setItem('Search', JSON.stringify([]));
          }
        } else {
          dispatch({
            type: 'OnSuccess',
            payload: JSON.parse(localStorage.getItem('count')),
          });
        }
      } catch (e) {
        console.error(e);
        dispatch({
          type: 'OnSuccess',
          payload: JSON.parse(localStorage.getItem('count')),
        });
      }
    }
    fetchData();
  }, [dispatch]);
  return <CTX.Provider value={{ allProducts }}>{prop.children}</CTX.Provider>;
}
Store.propTypes = {
  children: PropTypes.any,
};

export default Store;
