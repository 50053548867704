export default function authHeader() {
  const token = localStorage.getItem('Token');
  if (token) {
    return {
      Authorization: 'Bearer ' + token,
    };
  } else {
    console.error('Unable To Get Authentication Token');
    return {};
  }
}
