import React, { useEffect, useState } from 'react';
import PageHeader from '../../Layout/PageTitle';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import { Paper, Toolbar, TextField } from '@material-ui/core';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import * as nayaPayService from './NayaPayActivationService';
import SearchIcon from '@material-ui/icons/Search';
import Controls from '../../Utilities/Controls/Controls';
import CircularLoader from '../../Utilities/ReuseableCommponents/CircularLoader';
import GenericTable from '../../Utilities/ReuseableCommponents/TableReport/GenericTable';
import activityMonitoring from '../../../Common/functions';

const headCells = [
  { id: 'paymentMethodName', label: 'Payment Method', type: 'string' },
  { id: 'transactionId', label: 'Transaction ID', type: 'string' },
  { id: 'consumer', label: 'Consumer', type: 'string' },
  { id: 'invoiceId', label: 'Invoice Number', type: 'string' },
  { id: 'amount', label: 'Amount', type: 'float' },
  { id: 'timeStamp', label: 'Time Stamp', type: 'string' },
];

export default function NayaPayTransactions() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [records, setRecords] = useState([]);
  const [recordsCount, setRecordsCount] = useState(0);
  const [transactionId, setTransactionId] = useState('');
  const [consumer, setConsumer] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState('');

  function getRecords(page, rowsPerPage, sortDirection, sortColumnName) {
    let searchString = '';
    setIsLoading(true);

    if (transactionId) {
      searchString += `&transactionId=${transactionId}`;
    }
    if (consumer) {
      searchString += `&consumer=${consumer}`;
    }
    if (invoiceNumber) {
      searchString += `&invoiceId=${invoiceNumber}`;
    }

    nayaPayService
      .GetAllNayaPayTransactions(
        page,
        rowsPerPage,
        sortDirection,
        sortColumnName,
        searchString
      )
      .then((response) => {
        setIsLoading(false);
        if (response.data && response.data.data) {
          setRecords(response.data.data);
          setRecordsCount(response.data.count);
        }
      });
  }

  function getFilteredRecords(e) {
    e.preventDefault();
    getRecords(0, 5, 1, 'createdAt');
  }

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });
  useEffect(() => {
    activityMonitoring('NayaPayTransactions');
  }, []);
  return (
    <>
      <PageHeader
        title="NayaPay Transactions"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="userListHeading"
      />

      <Paper elevation={6} square className="paperMargin">
        <Toolbar disableGutters>
          <form className="searchFormAlign" onSubmit={getFilteredRecords}>
            <TextField
              className="searchInput"
              name="transactionId"
              label="Transaction ID"
              id="txtSearchTransactionId"
              variant="outlined"
              onChange={(e) => setTransactionId(e.target.value)}
              autoComplete="off"
            />
            <TextField
              className="searchInput"
              name="consumer"
              label="Consumer"
              id="txtSearchConsumer"
              variant="outlined"
              onChange={(e) => setConsumer(e.target.value)}
              autoComplete="off"
            />
            <TextField
              className="searchInput"
              name="invoiceNumber"
              label="Invoice Number"
              id="txtSearchInvoiceNumber"
              variant="outlined"
              onChange={(e) => setInvoiceNumber(e.target.value)}
              autoComplete="off"
            />

            <Controls.Button
              text="Search"
              variant="contained"
              id="btnSearchUser"
              color="primary"
              type="submit"
              className="searchButton"
              startIcon={<SearchIcon />}
            ></Controls.Button>
          </form>
        </Toolbar>
        <CircularLoader isload={isLoading} />
        <GenericTable
          data={records}
          TableId={'nayapayTransactionsTable'}
          TableTitle={'NayaPay Transactions'}
          cellData={[
            { name: 'paymentMethodName', type: 'string' },
            { name: 'transactionId', type: 'string' },
            { name: 'consumer', type: 'string' },
            { name: 'invoiceId', type: 'string' },
            { name: 'amount', type: 'float' },
            { name: 'timeStamp', type: 'string' },
          ]}
          headCells={headCells}
          recordsCount={recordsCount}
          getRecordsFn={getRecords}
          isLoading={isLoading}
        />
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
