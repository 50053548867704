import React, { useState } from 'react';
import {
  Grid,
  Paper,
  Slide,
  ListItem,
  InputAdornment,
  TextField,
  IconButton,
  List,
  ListItemText,
  Avatar,
  ListItemAvatar,
} from '@material-ui/core';
import {
  ArrowBack as ArrowBackIcon,
  Search as SearchIcon,
} from '@material-ui/icons';
import Grocery from '../../../../Assets/Images/undefined.png';
import PropTypes from 'prop-types';
const ProductListSlider = ({
  classes,
  checked,
  shiftButton,
  isCategorySelected,
  categories,
  handleCategoryClick,
  activeCategory,
  handleCategoryClickBack,
  productSearchCatWise,
  setProductSearchCatWise,
  selectProductForMainBody,
  listRef,
  itemListRef,
  backCatBtnRef,
}) => {
  const [offSet, setOffSet] = useState(25);
  return (
    <Slide direction="right" in={checked} mountOnEnter unmountOnExit>
      <Paper
        elevation={4}
        className={classes.paper10}
        style={{ display: shiftButton ? 'block' : 'none' }}
      >
        <Grid container spacing={0}>
          {isCategorySelected ? (
            <>
              <Grid item xs={12}>
                <List
                  style={{
                    padding: '10px',
                    maxHeight: '450px',
                    overflowY: 'auto',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                  innerRef={listRef}
                  id="lstPOSCatItems"
                >
                  {categories
                    .filter((category) => category._id != '0')
                    .map((category, index) => (
                      <ListItem
                        key={category._id + index}
                        id={'lstPOSCatItem' + index}
                        tabIndex={0}
                        onClick={() => handleCategoryClick(index)}
                        button
                      >
                        <ListItemText primary={category.name} />
                      </ListItem>
                    ))}
                </List>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={0}
                  style={{
                    background: '#FAFAFA',
                    borderRadius: '10px 10px 0 0px',
                  }}
                >
                  <Grid item xs={2}>
                    <IconButton
                      aria-label="delete"
                      id="btnBackToCat"
                      ref={backCatBtnRef}
                      className={classes.margin}
                      onClick={() => handleCategoryClickBack()}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    style={{ marginTop: 'auto', marginBottom: 'auto' }}
                  >
                    {categories[activeCategory].name}
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      padding: '10px 10px 0 10px',
                      marginTop: '10px',
                    }}
                  >
                    <TextField
                      variant="outlined"
                      id="txtPOSSearchSubCat"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      value={productSearchCatWise}
                      onChange={(e) => setProductSearchCatWise(e.target.value)}
                      autoComplete="off"
                      label="Search"
                      style={{ width: '100%' }}
                    />
                  </Grid>
                </Grid>
                <List
                  style={{
                    maxHeight: '360px',
                    overflowY: 'auto',
                    padding: '10px',
                  }}
                  innerRef={itemListRef}
                  id="lstPOSCatItemList"
                >
                  {categories[activeCategory].products
                    .filter((product) =>
                      product.name
                        .toLowerCase()
                        .includes(productSearchCatWise.toLowerCase())
                    )
                    .slice(0, offSet)
                    .map((product, index) => (
                      <ListItem
                        key={product._id + index}
                        id={'lstPOSSubCatItem' + index}
                        onClick={() => selectProductForMainBody(product)}
                        button
                        style={{
                          paddingTop: '4px',
                          paddingBottom: '4px',
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            alt="Cindy Baker"
                            src={product.img ? product.img : Grocery}
                            style={{ height: '30px', width: '30px' }}
                          />
                        </ListItemAvatar>
                        <ListItemText primary={product.name} />
                      </ListItem>
                    ))}
                  {categories[activeCategory].products.filter((product) =>
                    product.name
                      .toLowerCase()
                      .includes(productSearchCatWise.toLowerCase())
                  ).length > offSet ? (
                    <button
                      style={{
                        width: '250px',
                        height: '40px',
                        fontWeight: 'bold',
                        backgroundColor: '#1a76d2',
                        color: '#ffffff',
                        border: 'none',
                        borderRadius: '5px',
                      }}
                      onClick={() => {
                        setOffSet(offSet + 25);
                      }}
                    >
                      Click here to load more products
                    </button>
                  ) : (
                    <></>
                  )}
                </List>
              </Grid>
            </>
          )}
        </Grid>
      </Paper>
    </Slide>
  );
};
ProductListSlider.propTypes = {
  classes: PropTypes.any,
  checked: PropTypes.any,
  shiftButton: PropTypes.any,
  isCategorySelected: PropTypes.any,
  categories: PropTypes.any,
  handleCategoryClick: PropTypes.any,
  activeCategory: PropTypes.any,
  handleCategoryClickBack: PropTypes.any,
  productSearchCatWise: PropTypes.any,
  setProductSearchCatWise: PropTypes.any,
  selectProductForMainBody: PropTypes.any,
  listRef: PropTypes.any,
  itemListRef: PropTypes.any,
  backCatBtnRef: PropTypes.any,
};
export default ProductListSlider;
