import React from 'react';
import PropTypes from 'prop-types';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';

const CartTotal = ({
  classes,
  amountWithoutTax,
  tax,
  orderMode,
  deliveryCharges,
  discount,
  setDeliveryCharges,
}) => {
  return (
    <List disablePadding>
      <ListItem classes={{ root: classes.txtCartCal }}>
        <ListItemText primary="Subtotal" />
        <ListItemSecondaryAction>
          <ListItemText primary={amountWithoutTax.toFixed(2)} />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem classes={{ root: classes.txtCartCal }}>
        <ListItemText primary="Tax" />
        <ListItemSecondaryAction>
          <ListItemText primary={tax.toFixed(2)} />
        </ListItemSecondaryAction>
      </ListItem>

      <ListItem
        style={{ display: orderMode != 2 ? 'none' : '' }}
        classes={{ root: classes.txtCartCal }}
      >
        <ListItemText primary="Delivery Charges" />
        <ListItemSecondaryAction
          style={{ display: orderMode != 2 ? 'none' : '' }}
        >
          <TextField
            id={'txtDeliveryCharges'}
            type="number"
            variant="outlined"
            margin="dense"
            style={{
              width: '7rem',
              padding: '0px',
              height: '45px',
              left: '22px',
            }}
            value={deliveryCharges}
            onChange={(ee) =>
              setDeliveryCharges(ee.target.value < 0 ? '' : ee.target.value)
            }
            autoComplete="off"
          />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem classes={{ root: classes.txtCartCal }}>
        <ListItemText primary="Discount" />
        <ListItemSecondaryAction>
          <ListItemText primary={discount.toFixed(2)} />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem classes={{ root: classes.txtCartCal }}>
        <ListItemText
          disableTypography
          primary="Payable"
          className={classes.total}
        />
        <ListItemSecondaryAction>
          <ListItemText
            disableTypography
            primary={(
              amountWithoutTax +
              tax +
              parseFloat(deliveryCharges || 0) -
              discount.toFixed(2)
            ).toFixed(2)}
            className={classes.total}
          />
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
};
CartTotal.propTypes = {
  classes: PropTypes.any,
  amountWithoutTax: PropTypes.any,
  tax: PropTypes.any,
  orderMode: PropTypes.any,
  deliveryCharges: PropTypes.any,
  netTotal: PropTypes.any,
  discount: PropTypes.any,
  setDeliveryCharges: PropTypes.any,
};
export default CartTotal;
