import React from 'react';
import styles from './App.module.css';
import POSFood from './POSFood';
import Store from './Store';
export default function App() {
  return (
    <div className={styles.App}>
      <Store>
        <POSFood />
      </Store>
    </div>
  );
}
