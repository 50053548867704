import React from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Paper,
  makeStyles,
} from '@material-ui/core';
import PageTitle from '../../Layout/PageTitle';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import GeneralTab from './Tabs/GeneralTab';
import NayaPayTab from './Tabs/NayaPayTab';
import BarcodeTab from './Tabs/BarcodeTab';
import ReceiptSettingsTab from './Tabs/ReceiptSettingsTab';
import CancelOrderReasonTab from './Tabs/CancelOrderReasonTab';
import CancelOrderTimeTab from './Tabs/CancelOrderTimeTab';
import ModifyOrderTime from './Tabs/ModifyOrderTimeTab';
import HideFeatureTab from './Tabs/HideFeatureTab';
import CustomerConfigTab from './Tabs/CustomerConfigTab';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 'auto',
    width: '100%',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function Settings() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const userRole = localStorage.getItem('role');
  const appType = localStorage.getItem('type');

  let hideFeatures = JSON.parse(localStorage.getItem('_HideFeature')) || [];
  let foundFeature = hideFeatures.find((hf) => hf.name === 'Barcode');
  const isPrintBarcode = foundFeature && foundFeature.status ? true : false;

  return (
    <React.Fragment key={'setting'}>
      <PageTitle
        title="Settings"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="customerListHeading"
      />
      <AppBar
        position="static"
        color="default"
        style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
      ></AppBar>
      <Paper elevation={6} square className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          orientation="vertical"
          aria-label="scrollable auto tabs"
          className={classes.tabs}
        >
          <Tab label="General" {...a11yProps(0)} />
          <Tab label="NayaPay" {...a11yProps(1)} />
          {appType === 'food' && (
            <Tab label="Cancel Order Reason" {...a11yProps(2)} />
          )}
          {appType === 'food' && (
            <Tab label="Cancel Order Time" {...a11yProps(3)} />
          )}
          {userRole === 'superadmin' && (
            <Tab
              label="Receipt"
              {...(appType === 'food'
                ? { ...a11yProps(2) }
                : { ...a11yProps(4) })}
            />
          )}
          {appType === 'food' && userRole === 'superadmin' && (
            <Tab label="Order Modify Time" {...a11yProps(5)} />
          )}
          {userRole === 'superadmin' && (
            <Tab
              label="Optional Features"
              {...(appType === 'food'
                ? { ...a11yProps(3) }
                : { ...a11yProps(6) })}
            />
          )}
          {userRole === 'superadmin' && (
            <Tab
              label="Customer"
              {...(appType === 'food'
                ? { ...a11yProps(4) }
                : { ...a11yProps(7) })}
            />
          )}
          {userRole === 'superadmin' && isPrintBarcode && (
            <Tab
              label="Barcode"
              {...(appType === 'food'
                ? { ...a11yProps(5) }
                : { ...a11yProps(8) })}
            />
          )}

          {/* Add Tab here For New */}
        </Tabs>
        <TabPanel style={{ width: '100%' }} value={value} index={0}>
          <GeneralTab />
        </TabPanel>
        <TabPanel style={{ width: '100%' }} value={value} index={1}>
          <NayaPayTab />
        </TabPanel>
        {appType === 'food' && (
          <TabPanel style={{ width: '100%' }} value={value} index={2}>
            <CancelOrderReasonTab />
          </TabPanel>
        )}
        {appType === 'food' && (
          <TabPanel style={{ width: '100%' }} value={value} index={3}>
            <CancelOrderTimeTab />
          </TabPanel>
        )}
        {userRole === 'superadmin' && (
          <TabPanel value={value} index={appType === 'food' ? 4 : 2}>
            <ReceiptSettingsTab />
          </TabPanel>
        )}
        {userRole === 'superadmin' && appType === 'food' && (
          <TabPanel value={value} index={5}>
            <ModifyOrderTime />
          </TabPanel>
        )}
        {userRole === 'superadmin' && (
          <TabPanel value={value} index={appType === 'food' ? 6 : 3}>
            <HideFeatureTab />
          </TabPanel>
        )}
        {userRole === 'superadmin' && (
          <TabPanel
            style={{ width: '100%' }}
            value={value}
            index={appType === 'food' ? 7 : 4}
          >
            <CustomerConfigTab />
          </TabPanel>
        )}
        {userRole === 'superadmin' && isPrintBarcode && (
          <TabPanel value={value} index={appType === 'food' ? 8 : 5}>
            <BarcodeTab />
          </TabPanel>
        )}
        {/* Add TabPanel here For New */}
      </Paper>
    </React.Fragment>
  );
}
