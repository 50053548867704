import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.4em',
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '0px solid slategrey',
    },
  },
  root: {
    padding: theme.spacing(2),
    margin: '5px',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  flex: {
    display: 'flex',
  },
  categoryWindow: {
    width: '15%',
    borderRight: '2px solid #ddd',
    overflowY: 'scroll',
  },
  productWindow: {
    width: '15%',
    borderRight: '2px solid #ddd',
    overflowY: 'scroll',
  },
  CalButton: {
    width: '90px',
    height: '70px',
    margin: '5px',
    fontSize: '20px !important',
  },
  resize: {
    fontSize: 50,
  },
  mainWindow: {
    width: '70%',
    overflowY: 'scroll',
  },
  table: {
    minWidth: 650,
  },
  mainTableNumberTextField: {
    width: '100px',
  },
  Cards: {
    marginLeft: '25px',
    marginRight: '25px',
    marginTop: '10px',
    marginBottom: '10px',
    textAlign: 'center',
  },
  button: {
    margin: theme.spacing(1),
  },
  paper2: {
    position: 'absolute',
    width: '500px',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowX: 'auto',
  },
  Cals: {
    margin: '10px',
    textAlign: 'center',
  },
  CalsDetails: {
    margin: '10px',
    width: '250px',
  },
  spanbody: {
    fontSize: '0.80em',
    width: '35px',
    marginInlineStart: '0px',
    marginInlineEnd: '0px',
    fontWeight: 'bold',
    display: 'inline-flex',
    paddingLeft: '2px',
  },
  TextFieldresize: {
    fontSize: 20,
  },
  tableContent: {
    padding: '20px',
    paddingTop: '0px',
  },
  SearchListStyling: {
    width: '100%',
    maxWidth: '350px',
    maxHeight: '300px',
    backgroundColor: theme.palette.background.paper,
    position: 'absolute',
    zIndex: '1',
    overflowY: 'scroll',
    marginTop: '45px',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
  },
  SearchListStylingFood: {
    width: '100%',
    maxWidth: '350px',
    maxHeight: '300px',
    backgroundColor: theme.palette.background.paper,
    position: 'absolute',
    zIndex: '1',
    overflowY: 'scroll',
    marginTop: '0',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
  },
  companyNameCSS: {
    marginLeft: 'auto',
    marginRight: 'auto',
    color: '#494949',
    textTransform: 'uppercase',
    display: 'flex',
  },
  toTable: {
    '& thead th': {
      fontWeight: '600',
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
    },
    '& tbody td': {
      fontWeight: '300',
    },
    '& tbody tr:hover': {
      backgroundColor: '#fffbf2',
      cursor: 'pointer',
    },
  },

  rootItem: {
    maxWidth: 200,
    position: 'relative',
    margin: 10,
  },
  TableRow: {},
  heading: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  total: {
    fontWeight: 'bold',
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  btnCheckout: {
    backgroundColor: green[700],
    color: '#ffffff',
    display: 'block',
    width: '100%',
    padding: '1rem 0',
    '&:hover': {
      backgroundColor: green[600],
      color: '#FFF',
    },
  },
  media: {
    height: 90,
    width: 120,
  },

  con1: {
    position: 'absolute',
    right: 2,
    top: '57px',
    color: '#fff',
    backgroundColor: '#000000ad',
    padding: 5,
  },
  con2: {
    padding: 5,
    minHeight: 50,
  },
  'txtCheckoutCal > ListItem': {
    paddingTop: 0,
    paddingBottom: 0,
  },
  textfieldOutlined: {
    [`& fieldset`]: {
      borderRadius: 0,
    },
  },
  btnClassCheckout: {
    margin: theme.spacing(0),
    backgroundColor: '#4caf50',
    color: '#ffffff',
    border: '0',
    height: '100%',

    '&:hover': {
      backgroundColor: '#388e3c',
    },
  },
  txtCartCal: {
    padding: '0 48px 0 16px',
  },

  warningColor: {
    backgroundColor: '#ff9800',
    color: '#fff',
  },

  posQty: {
    display: 'inline-flex',
    alignItems: 'center',
  },

  actionBtns: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: '87%',
    },
  },
  modalFooter: {
    bottom: '25px',
    position: 'fixed',
    width: '94%',
  },
  dialOptionList: {
    borderRight: '1px solid #ccc',
    borderLeft: '1px solid #ccc',
    height: '350px',
    '& li': {
      height: 'auto !important',
    },
  },
}));

export default useStyles;
