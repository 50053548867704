import React from 'react';
import PropTypes from 'prop-types';
import styles from './Chiclet.module.css';

export default function Chiclet(props) {
  let chicletStyle = styles.basic;

  if (props.chicletStyle === 'alert') {
    chicletStyle = styles.alert;
  }

  return <div className={chicletStyle}>{props.bodyText}</div>;
}

Chiclet.propTypes = {
  bodyText: PropTypes.string,
  chicletStyle: PropTypes.string,
};
