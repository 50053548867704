import React, { useEffect } from 'react';
import {
  Grid,
  Paper,
  Button,
  ButtonGroup,
  InputAdornment,
  TextField,
  Grow,
  Popper,
  MenuItem,
  MenuList,
  ClickAwayListener,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  ArrowDropDown as ArrowDropDownIcon,
  CreditCard,
  Money,
} from '@material-ui/icons';
const CheckoutSideBar = ({
  classes,
  shiftButton,
  cal,
  Cash,
  PayCash,
  PayCreditCard,
  productListInMainBody,
  systemType,
  setNotify,
  setCalValue,
  modleOpenNayaPay,
  anchorRef,
  customerinfo,
  handleClick,
  calValue,
  discount,
  options,
  selectedIndex,
  handleToggle,
  discountMain,
  netTotal,
  miscellaneousCharges,
  miscellaneousChargesRef,
  adjuestmentRef,
  controlMiscCharges,
  miscChargesLimit,
  handleClose,
  handleMenuItemClick,
  discountBitFields,
  controlOverAllDiscount,
  finalDiscount,
  signDiscount,
  open,
  recalculateTotals,
  cashCheckoutRef,
  cCardCheckoutRef,
  nayaPayCheckoutRef,
  creditCheckoutRef,
  isShortcutEnable,
  paidRef,
}) => {
  useEffect(() => {
    recalculateTotals();
  }, [recalculateTotals]);

  return (
    <Grid item style={{ display: shiftButton ? 'block' : 'none' }}>
      <Paper className={classes.posInnerDiv} elevation={0} square>
        <Grid container spacing={0}>
          <Grid
            container
            style={{
              flexWrap: 'nowrap',
              justifyContent: 'center',
              marginTop: '-5px',
            }}
          >
            <Grid item style={{ marginRight: '5px' }}>
              <Grid container style={{ flexDirection: 'column' }}>
                <Grid item style={{ display: 'inline-flex' }}>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      id="btnPOSPayCal7"
                      className={classes.CalButton}
                      onClick={() => cal('7')}
                    >
                      7
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      id="btnPOSPayCal8"
                      className={classes.CalButton}
                      onClick={() => cal('8')}
                    >
                      8
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      id="btnPOSPayCal9"
                      className={classes.CalButton}
                      onClick={() => cal('9')}
                    >
                      9
                    </Button>
                  </Grid>
                </Grid>
                <Grid item style={{ display: 'inline-flex' }}>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      id="btnPOSPayCal4"
                      className={classes.CalButton}
                      onClick={() => cal('4')}
                    >
                      4
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      id="btnPOSPayCal5"
                      className={classes.CalButton}
                      onClick={() => cal('5')}
                    >
                      5
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      id="btnPOSPayCal6"
                      className={classes.CalButton}
                      onClick={() => cal('6')}
                    >
                      6
                    </Button>
                  </Grid>
                </Grid>
                <Grid item style={{ display: 'inline-flex' }}>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      id="btnPOSPayCal1"
                      className={classes.CalButton}
                      onClick={() => cal('1')}
                    >
                      1
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      id="btnPOSPayCal2"
                      className={classes.CalButton}
                      onClick={() => cal('2')}
                    >
                      2
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      id="btnPOSPayCal3"
                      className={classes.CalButton}
                      onClick={() => cal('3')}
                    >
                      3
                    </Button>
                  </Grid>
                </Grid>
                <Grid item style={{ display: 'inline-flex' }}>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      id="btnPOSPayCalClear"
                      className={classes.CalButton}
                      onClick={() => cal('c')}
                    >
                      c
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      id="btnPOSPayCal0"
                      className={classes.CalButton}
                      onClick={() => cal('0')}
                    >
                      0
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      id="btnPOSPayCalDot"
                      className={classes.CalButton}
                      onClick={() => cal('.')}
                    >
                      .
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ display: 'flex', flexDirection: 'column' }}>
              <Button
                variant="contained"
                color="primary"
                style={{
                  height: '55px',
                  fontSize: '20px !important',
                  marginTop: '5px',
                  marginBottom: '5px',
                  marginLeft: '0px',
                  marginRight: '0px',
                  width: '110px',
                  borderRadius: '4px !important',
                  backgroundColor: '#FF7171',
                }}
                id="btnPOSPayCash"
                onClick={() => {
                  Cash();
                  PayCash();
                }}
                startIcon={<Money />}
                disabled={
                  productListInMainBody.length <= 0 || systemType === 'ol'
                }
                ref={cashCheckoutRef}
              >
                CASH
                {isShortcutEnable.status && '(F1)'}
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{
                  height: '55px',
                  fontSize: '20px !important',
                  marginTop: '5px',
                  marginBottom: '5px',
                  marginLeft: '0px',
                  marginRight: '0px',
                  width: '110px',
                  borderRadius: '4px !important',
                  backgroundColor: '#45AAF2',
                }}
                ref={cCardCheckoutRef}
                id="btnPOSPayCredit"
                onClick={() => {
                  if (systemType === 'ol' && !customerinfo) {
                    setNotify({
                      isOpen: true,
                      message: 'Customer did not attached.',
                      type: 'warning',
                      notificationTime: 3000,
                    });
                  } else {
                    PayCreditCard();
                    PayCash();
                  }
                }}
                disabled={productListInMainBody.length <= 0}
                startIcon={<CreditCard />}
              >
                CC
                {isShortcutEnable.status && '(F6)'}
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{
                  height: '55px',
                  fontSize: '20px !important',
                  marginTop: '5px',
                  marginBottom: '5px',
                  marginLeft: '0px',
                  marginRight: '0px',
                  width: '110px',
                  borderRadius: '4px !important',
                  backgroundColor: '#45AAF2',
                }}
                id="btnPOSPayNayaPay"
                onClick={modleOpenNayaPay}
                ref={nayaPayCheckoutRef}
                disabled={productListInMainBody.length <= 0}
              >
                Naya Pay
                <br />
                {isShortcutEnable.status && '(F3)'}
              </Button>
              <ButtonGroup
                variant="contained"
                color="primary"
                style={{
                  height: '55px',
                  fontSize: '20px !important',
                  marginTop: '5px',
                  marginBottom: '5px',
                  marginLeft: '0px',
                  marginRight: '0px',
                  width: '110px',
                }}
                ref={anchorRef}
                aria-label="split button"
                disabled={productListInMainBody.length <= 0}
              >
                <Button
                  id="btnGrpPOSPayCredit"
                  ref={creditCheckoutRef}
                  style={{
                    borderRadius: '4px !important',
                  }}
                  onClick={handleClick}
                >
                  {options[selectedIndex].label}
                  <br />
                  {isShortcutEnable.status && '(F4)'}
                </Button>
                <Button
                  id="btnGrpPOSPayArrowDown"
                  color="primary"
                  size="small"
                  style={{
                    borderRadius: '4px !important',
                  }}
                  aria-controls={open ? 'split-button-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  onClick={handleToggle}
                >
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>
              <Popper
                style={{ zIndex: '1000' }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="split-button-menu">
                          {options.map((option, index) => (
                            <MenuItem
                              id={'btnPOSPayBy' + option.key}
                              key={option.key}
                              disabled={
                                option.key === 'cashondelivery' &&
                                systemType === 'pos'
                              }
                              selected={index === selectedIndex}
                              onClick={(event) =>
                                handleMenuItemClick(event, index)
                              }
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          style={{
            flexDirection: 'column',
          }}
        >
          <Grid item style={{ display: 'inline-flex' }}>
            <Grid
              item
              xs={4}
              style={{
                marginTop: 'auto',
                marginBottom: '10px',
                marginRight: '10px',
                textAlign: 'right',
              }}
            >
              Amount + Tax
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="txtPOSPayAmountTax"
                value={(netTotal - discount).toFixed(2)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Rs</InputAdornment>
                  ),
                  classes: {
                    input: classes.TextFieldresizeChkout,
                  },
                }}
                autoComplete="off"
              />
            </Grid>
          </Grid>
          {miscChargesLimit && miscChargesLimit.status && (
            <Grid item style={{ display: 'inline-flex' }}>
              <Grid
                item
                xs={4}
                style={{
                  marginTop: 'auto',
                  marginBottom: '10px',
                  marginRight: '10px',
                  textAlign: 'right',
                }}
              >
                Misc. Charges
                {isShortcutEnable.status && ' (Shift + Q)'}
              </Grid>
              <Grid item xs={8}>
                <TextField
                  id="txtPOSPayMiscCharges"
                  value={miscellaneousCharges}
                  inputRef={miscellaneousChargesRef}
                  onChange={(e) => controlMiscCharges(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">Rs</InputAdornment>
                    ),
                    classes: {
                      input: classes.TextFieldresizeChkout,
                    },
                  }}
                  type="number"
                  autoComplete="off"
                />
              </Grid>
            </Grid>
          )}
          <Grid item style={{ display: 'inline-flex' }}>
            <Grid
              item
              xs={4}
              style={{
                marginTop: 'auto',
                marginBottom: '10px',
                marginRight: '10px',
                textAlign: 'right',
              }}
            >
              Adjustment <br /> {isShortcutEnable.status && ' (Shift + D)'}
            </Grid>
            <Grid item xs={8}>
              {discountBitFields ? (
                <TextField
                  id="txtPOSPayDisc"
                  disabled
                  value={finalDiscount}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">Rs</InputAdornment>
                    ),
                    classes: {
                      input: classes.TextFieldresizeChkout,
                    },
                    inputProps: { min: 0 },
                  }}
                  type="number"
                  autoComplete="off"
                />
              ) : (
                <TextField
                  id="txtPOSPayAdjustment"
                  inputRef={adjuestmentRef}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {signDiscount[0]}
                      </InputAdornment>
                    ),
                    classes: {
                      input: classes.TextFieldresizeChkout,
                    },
                    inputProps: { min: 0 },
                  }}
                  value={finalDiscount}
                  onChange={(e) => controlOverAllDiscount(e.target.value)}
                  autoComplete="off"
                  type="number"
                />
              )}
            </Grid>
          </Grid>
          <Grid item style={{ display: 'inline-flex' }}>
            <Grid
              item
              xs={4}
              style={{
                marginTop: 'auto',
                marginBottom: '10px',
                marginRight: '10px',
                textAlign: 'right',
              }}
            >
              Payable
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="txtPOSPayAmountPayable"
                value={(
                  parseFloat(netTotal) +
                  parseFloat(
                    miscellaneousCharges === '' ? 0 : miscellaneousCharges
                  ) -
                  discount -
                  parseFloat(discountMain === '' ? 0 : discountMain)
                ).toFixed(2)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Rs</InputAdornment>
                  ),
                  classes: {
                    input: classes.TextFieldresizeChkout,
                  },
                }}
                autoComplete="off"
              />
            </Grid>
          </Grid>
          <Grid item style={{ display: 'inline-flex' }}>
            <Grid
              item
              xs={4}
              style={{
                marginTop: 'auto',
                marginBottom: '10px',
                marginRight: '10px',
                textAlign: 'right',
              }}
            >
              Paid <br />
              {isShortcutEnable.status && ' (Shift + P)'}
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="txtPOSPayPaid"
                value={calValue}
                onChange={(e) => setCalValue(e.target.value)}
                type="number"
                inputRef={paidRef}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Rs</InputAdornment>
                  ),
                  classes: {
                    input: classes.TextFieldresizeChkout,
                  },
                }}
                autoComplete="off"
              />
            </Grid>
          </Grid>
          <Grid item style={{ display: 'inline-flex' }}>
            <Grid
              item
              xs={4}
              style={{
                marginTop: 'auto',
                marginBottom: '10px',
                marginRight: '10px',
                textAlign: 'right',
              }}
            >
              Return
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="txtPOSPayReturn"
                value={(
                  parseFloat(calValue === '' ? '0' : calValue) -
                  (
                    parseFloat(netTotal) +
                    parseFloat(
                      miscellaneousCharges === '' ? 0 : miscellaneousCharges
                    ) -
                    parseFloat(discount) -
                    parseFloat(discountMain === '' ? 0 : discountMain)
                  ).toFixed(2)
                ).toFixed(2)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Rs</InputAdornment>
                  ),
                  classes: {
                    input: classes.TextFieldresizeChkout,
                  },
                }}
                autoComplete="off"
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
CheckoutSideBar.propTypes = {
  classes: PropTypes.any,
  shiftButton: PropTypes.any,
  cal: PropTypes.any,
  Cash: PropTypes.any,
  PayCash: PropTypes.any,
  PayCreditCard: PropTypes.any,
  productListInMainBody: PropTypes.any,
  systemType: PropTypes.any,
  setNotify: PropTypes.any,
  setCalValue: PropTypes.any,
  modleOpenNayaPay: PropTypes.any,
  anchorRef: PropTypes.any,
  customerinfo: PropTypes.any,
  handleClick: PropTypes.any,
  calValue: PropTypes.any,
  discount: PropTypes.any,
  options: PropTypes.any,
  selectedIndex: PropTypes.any,
  handleToggle: PropTypes.any,
  discountMain: PropTypes.any,
  netTotal: PropTypes.any,
  miscellaneousCharges: PropTypes.any,
  controlMiscCharges: PropTypes.any,
  miscChargesLimit: PropTypes.any,
  handleClose: PropTypes.any,
  handleMenuItemClick: PropTypes.any,
  discountBitFields: PropTypes.any,
  controlOverAllDiscount: PropTypes.any,
  finalDiscount: PropTypes.any,
  signDiscount: PropTypes.any,
  open: PropTypes.any,
  recalculateTotals: PropTypes.any,
  cashCheckoutRef: PropTypes.any,
  cCardCheckoutRef: PropTypes.any,
  nayaPayCheckoutRef: PropTypes.any,
  creditCheckoutRef: PropTypes.any,
  isShortcutEnable: PropTypes.any,
  miscellaneousChargesRef: PropTypes.any,
  adjuestmentRef: PropTypes.any,
  paidRef: PropTypes.any,
};
export default CheckoutSideBar;
