import React, { useEffect, useState } from 'react';
import PageTitle from '../../Layout/PageTitle';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import { Paper, Toolbar, TextField, Grid } from '@material-ui/core';
import Controls from '../../Utilities/Controls/Controls';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import * as VendorService from './VendorService';
import { useHistory } from 'react-router-dom';
import CircularLoader from '../../Utilities/ReuseableCommponents/CircularLoader';
import useStyles from '../../../Style/AppStylesAdmin';
import GenericTable from '../../Utilities/ReuseableCommponents/TableReport/GenericTable';
import activityMonitoring from '../../../Common/functions';

const headCells = [
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'contactNumber', label: 'Contact Number', align: 'left' },
  { id: 'contactPerson', label: 'Contact Person', align: 'left' },
  { id: 'address', label: 'Address', align: 'left' },
  {
    id: 'openingBalance',
    label: 'Opening Balance',
    align: 'left',
    type: 'float',
  },
  { id: 'actions', label: 'Actions', disableSorting: true, align: 'right' },
];

let apiResponseDto = {
  data: [],
  error: null,
  loading: false,
  statusCode: 0,
};

export default function Vendor() {
  const classes = useStyles();
  const history = useHistory();
  const recordForEdit = null;
  const [records, setRecords] = useState([]);
  const [recordsCount, setRecordsCount] = useState(0);
  const [name, setName] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [isLoaded, setIsLoaded] = React.useState(false);
  const rowOnClick = {
    path: '/VendorDetails',
    header: 'View/Edit Vendor',
    property: ['recordForEdit'],
  };
  function getRecords(page, rowsPerPage, sortDirection, sortColumnName) {
    let searchString = '';
    let data = (async () => {
      setIsLoaded(true);
      if (name) {
        searchString += `&name=${name}`;
      }
      if (contactNumber) {
        searchString += `&contactNumber=${contactNumber}`;
      }
      apiResponseDto = await VendorService.getAllVendors(
        page,
        rowsPerPage,
        sortDirection,
        sortColumnName,
        searchString
      ).then((Response) => {
        return Response;
      });
      return apiResponseDto;
    })();
    data.then((Response) => {
      setIsLoaded(false);
      if (Response.data) {
        setRecords(Response.data.data);
        setRecordsCount(Response.data.count);
        if (Response.data.count == 0) {
          setNotify({
            isOpen: true,
            message: 'No Data Found',
            type: 'error',
          });
        }
      } else {
        setIsLoaded(false);
        console.error('Error While Fetching Data: ', Response.error);
      }
    });
  }

  const handleSearch = (e) => {
    e.preventDefault();
    getRecords(0, 5, -1, '');
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const onDelete = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    try {
      apiResponseDto.data = null;
      apiResponseDto.error = null;
      apiResponseDto = await VendorService.deleteVendor(id).then((Response) => {
        return Response;
      });
      if (apiResponseDto.data) {
        getRecords(0, 10, 1, 'name');
        setNotify({
          isOpen: true,
          message: 'Deleted Successfully',
          type: 'success',
        });
      }
      if (apiResponseDto.error) {
        if (apiResponseDto.statusCode === 403) {
          setNotify({
            isOpen: true,
            message:
              'Unable To Delete Category : Products are also Associated With This Category',
            type: 'error',
          });
        } else {
          setNotify({
            isOpen: true,
            message: 'Unable To Process Request',
            type: 'error',
          });
        }
      }
    } catch (e) {
      setNotify({
        isOpen: true,
        message: 'Unable To Process Request',
        type: 'error',
      });
    }
  };

  useEffect(() => {
    activityMonitoring('Vendors');
  }, []);
  return (
    <>
      <PageTitle
        title="Vendor"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="vendorListHeading"
      />
      <Paper elevation={6} square className={classes.PaperMargin}>
        <Toolbar disableGutters>
          <form
            onSubmit={handleSearch}
            style={{ width: '100%', display: 'flex', alignItems: 'center' }}
          >
            <Grid container>
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <TextField
                  id="txtSearchVendorName"
                  label="Name"
                  className={classes.searchInput}
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <TextField
                  id="txtSearchVendorNumber"
                  className={classes.searchInput}
                  label="Contact Number"
                  variant="outlined"
                  value={contactNumber}
                  onChange={(e) => setContactNumber(e.target.value)}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <Controls.Button
                  text="Search"
                  variant="contained"
                  color="primary"
                  id="btnSearchVendor"
                  className={classes.searchButton}
                  startIcon={<SearchIcon />}
                  type="submit"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={6}>
                <Controls.Button
                  text="Add New"
                  variant="contained"
                  color="primary"
                  id="btnAddVendor"
                  startIcon={<AddIcon />}
                  className={classes.newButton}
                  onClick={() => {
                    history.push({
                      pathname: '/VendorDetails',
                      state: {
                        pageHeaderTitile: 'Add New Vendor',
                        recordForEdit,
                      },
                    });
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </Toolbar>
        <CircularLoader isload={isLoaded} />
        <GenericTable
          data={records}
          TableId={'vendorsTable'}
          TableTitle={'Vendor Table'}
          cellData={[
            { name: 'name', type: 'string' },
            { name: 'contactNumber', type: 'string' },
            { name: 'contactPerson', type: 'string' },
            { name: 'address', type: 'string' },
            { name: 'openingBalance', type: 'float' },
            { name: 'remove', type: 'remove' },
          ]}
          headCells={headCells}
          recordsCount={recordsCount}
          getRecordsFn={getRecords}
          rowDeleteDialog={setConfirmDialog}
          rowDeletefn={onDelete}
          rowOnClick={rowOnClick}
          isLoading={isLoaded}
        />
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        id_Yes="btnDelVenYes"
        id_No="btnDelVenNo"
      />
    </>
  );
}
