import React from 'react';
import {
  makeStyles,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  shortcutInfoModal: {
    height: '500px ',
    position: 'absolute',
    width: 'auto',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowX: 'scroll',
  },
}));

const ShortcutsInfoModalBody = ({ classes }) => {
  const classList = useStyles();
  const shortcutList = [
    {
      shortcut: 'F2',
      description: 'Open Shortcut Info page.',
    },
    {
      shortcut: 'Space',
      description: 'Close Shortcut Info page.',
    },
    {
      shortcut: 'Shift + F',
      description: 'Search bar focus.',
    },
    {
      shortcut: 'Shift + H',
      description: 'Hold order.',
    },
    {
      shortcut: 'Shift + G',
      description: 'Held orders.',
    },
    {
      shortcut: 'Shift + R',
      description: 'Open sale return page.',
    },
    {
      shortcut: 'Shift + C',
      description: 'Clear cart.',
    },
    {
      shortcut: 'Shift + W',
      description: 'Open add customer page.',
    },
    {
      shortcut: 'Insert',
      description: 'Attach focused customer with order.',
    },
    {
      shortcut: 'Shift + V',
      description: 'Remove attached customer.',
    },
    {
      shortcut: 'Shift + O',
      description: 'Open start shift page.',
    },
    {
      shortcut: 'Shift + E',
      description: 'Open end shift page.',
    },
    {
      shortcut: 'Shift + X',
      description: 'Open categories left slider.',
    },
    {
      shortcut: 'Shift + D',
      description: 'Focus on adjustment input field.',
    },
    {
      shortcut: 'Shift + Q',
      description: 'Focus on misc charges input field.',
    },
    {
      shortcut: 'Shift + P',
      description: 'Focus on paid input field.',
    },
    {
      shortcut: 'Shift + T',
      description: 'Focus on product table first item.',
    },
    {
      shortcut: 'Shift + D',
      description: 'Delete focused order from held order list.',
    },
    {
      shortcut: 'Shift + V',
      description: 'Reterive focused order from held order list.',
    },
    {
      shortcut: 'Ctrl + X',
      description: 'Signout from POS page.',
    },
    {
      shortcut: 'F1',
      description: 'Checkout with cash.',
    },
    {
      shortcut: 'F6',
      description: 'Checkout with credit card.',
    },
    {
      shortcut: 'F3',
      description: 'Checkout with NayaPay.',
    },
    {
      shortcut: 'F4',
      description: 'Checkout with credit.',
    },
    {
      shortcut: 'Arrow Up',
      description: 'Move focus up side in lists.',
    },
    {
      shortcut: 'Arrow Down',
      description: 'Move focus down side in lists.',
    },
    {
      shortcut: 'Arrow Left',
      description: 'Go back to category list from product list in left slider.',
    },
    {
      shortcut: 'Plus(+)',
      description:
        'Increase focused product quantity in seleted products table.',
    },
    {
      shortcut: 'Minus(-)',
      description:
        'Decrease focused product quantity in seleted products table.',
    },
    {
      shortcut: 'Delete',
      description: 'Delete focused product from seleted products table.',
    },
  ];
  return (
    <>
      <div className={classList.shortcutInfoModal}>
        <h3 id="simple-modal-title">Shortcut Keys</h3>
        <div>
          <TableContainer
            component={Paper}
            style={{
              height: '400px',
              overflow: 'auto',
            }}
          >
            <Table
              id="posShortcutKeysTable"
              className={classes.table}
              aria-label="sticky table"
              stickyHeader
            >
              <TableHead varia>
                <TableRow>
                  <TableCell align="left">
                    <b>Shortcut Keys</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Description</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody id="posShortcutInfoTbody">
                {shortcutList.map((row, index) => (
                  <TableRow
                    id={'posShortcutInfoTrow' + index}
                    key={index}
                    focus={index === 0 ? true : false}
                  >
                    <TableCell align="left">
                      <b>{row.shortcut}</b>
                    </TableCell>
                    <TableCell align="left">{row.description}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};
ShortcutsInfoModalBody.propTypes = {
  classes: PropTypes.any,
};
export default ShortcutsInfoModalBody;
