/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { Typography, makeStyles, Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import APIService from '../Dashboard/DashboardService';

const CHART_TITLE = 'Sold Products';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    padding: theme.spacing(4),
  },
  cardTitle: {
    color: '#999',
  },
  cardData: {
    color: '#1976d2',
    fontSize: '1.7rem',
  },
}));

const chartData = {
  labels: [],
  datasets: [
    {
      label: CHART_TITLE,
      data: [],
      backgroundColor: '#1976d2',
      borderColor: '#1976d2',
      borderWidth: 1,
    },
  ],
};

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

export default function MostSoldItemsViz(props) {
  const [chartDataState, setChartDataState] = useState({});
  const classes = useStyles();

  useEffect(() => {
    APIService.FetchMostSellableItems(props.from, props.to).then(
      ({ data: { data } }) => {
        chartData.datasets[0].data = data.map((x) => x.sumOfQuantity);
        chartData.labels = data.map((x) => x.name);
        setChartDataState(chartData);
      }
    );
  }, [props.from, props.to, chartDataState]);
  return (
    <Grid item sm={6}>
      <div className={classes.pageContent} style={{ textAlign: 'left' }}>
        <Typography variant="h5" id={props.elementId} gutterBottom>
          {CHART_TITLE}
        </Typography>
        {props.isLoaded ? (
          <Bar
            data={chartDataState}
            options={options}
            height={props.height}
            width={props.width}
          />
        ) : (
          <CircularProgress variant="indeterminate" size={100} thickness={4} />
        )}
      </div>
    </Grid>
  );
}

MostSoldItemsViz.propTypes = {
  from: PropTypes.string,
  to: PropTypes.string,
  elementId: PropTypes.string,
  headLabel: PropTypes.string,
  isLoaded: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
};
