import React, { useEffect, useState } from 'react';
import PageHeader from '../../Layout/PageTitle';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import { Paper, Toolbar, TextField } from '@material-ui/core';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import * as forgetPasswordService from './ForgetPasswordServices';
import * as userService from '../User/UserService';
import SearchIcon from '@material-ui/icons/Search';
import Controls from '../../Utilities/Controls/Controls';
import { GetTokenValue } from '../../../Common/constants';
import CircularLoader from '../../Utilities/ReuseableCommponents/CircularLoader';
import GenericTable from '../../Utilities/ReuseableCommponents/TableReport/GenericTable';
import activityMonitoring from '../../../Common/functions';

const headCells = [
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'email', label: 'Email', align: 'left' },
  { id: 'role', label: 'Role', align: 'left' },
  { id: 'action', label: 'Action', align: 'left', type: 'decision' },
];

let apiResponseDto = {
  data: [],
  error: null,
  loading: false,
};

export default function User() {
  const [records, setRecords] = useState([]);
  const [recordsCount, setRecordsCount] = useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [filterName, setFilterName] = useState('');
  const [filterRole, setFilterRole] = useState('');

  function getRecords(page, rowsPerPage, sortDirection, sortColumnName) {
    let data = (async () => {
      setIsLoading(true);
      apiResponseDto = await forgetPasswordService
        .GetAllUsers(
          page,
          rowsPerPage,
          sortDirection,
          sortColumnName,
          filterName,
          filterRole
        )
        .then((Response) => {
          return Response;
        });
      return apiResponseDto;
    })();
    data.then((Response) => {
      setIsLoading(false);
      if (Response.data) {
        setRecords(Response.data.data);
        setRecordsCount(Response.data.count);
      } else {
        console.error('Error While Fetching Data: ', Response.error);
        setIsLoading(false);
      }
    });
  }

  function getFilteredRecords(e) {
    e.preventDefault();
    getRecords(0, 5, 1, 'createdAt');
  }

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });
  const decisionButtonProps = {
    getFilteredRecords: getFilteredRecords,
    setNotify: setNotify,
  };
  useEffect(() => {
    activityMonitoring('ForgetPassword');
  }, []);
  return (
    <>
      <PageHeader
        title="Change Password Request"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="userListHeading"
      />

      <Paper elevation={6} square className="paperMargin">
        <Toolbar disableGutters>
          <form className="searchFormAlign" onSubmit={getFilteredRecords}>
            <TextField
              className="searchInput"
              name="name"
              label="Name"
              id="txtSearchUsername"
              variant="outlined"
              onChange={(e) => {
                setFilterName(e.target.value);
              }}
              autoComplete="off"
            />
            <Controls.EditableDropDown
              name="role"
              label="Role"
              data={userService.getRole(GetTokenValue('role'))}
              id="ddSearchUserRole"
              onChange={(e, newValue) => {
                setFilterRole(newValue ? newValue.nameValue : '');
              }}
            />

            <Controls.Button
              text="Search"
              variant="contained"
              id="btnSearchUser"
              color="primary"
              type="submit"
              className="searchButton"
              startIcon={<SearchIcon />}
            ></Controls.Button>
          </form>
        </Toolbar>
        <CircularLoader isload={isLoading} />
        <GenericTable
          data={records}
          TableId={'forgetPasswordTable'}
          TableTitle={'Forget Password Table'}
          cellData={[
            { name: 'name', type: 'string' },
            { name: 'email', type: 'string' },
            { name: 'role', type: 'string' },
            { name: 'remove', type: 'decision' },
          ]}
          headCells={headCells}
          recordsCount={recordsCount}
          getRecordsFn={getRecords}
          isLoading={isLoading}
          decisionButtonProps={decisionButtonProps}
        />
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
