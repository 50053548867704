import { makeStyles, Paper, Toolbar, TextField } from '@material-ui/core';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import React, { useState, useEffect } from 'react';
import PageTitle from '../../Layout/PageTitle';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import * as productSaleService from './ProductSalesService';
import * as categoryService from '../Category/CategoryService';
import SearchIcon from '@material-ui/icons/Search';
import Controls from '../../Utilities/Controls/Controls';
import CircularLoader from '../../Utilities/ReuseableCommponents/CircularLoader';
import GenericTable from '../../Utilities/ReuseableCommponents/TableReport/GenericTable';
import { dateTimeZone as timeZone } from '../../../Common/constants';
import activityMonitoring from '../../../Common/functions';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: '20%',
    paddingRight: '5px',
  },
  newButton: {
    position: 'absolute',
    right: '0px',
  },
  PaperMargin: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
}));

const headCells = [
  {
    id: 'product',
    label: 'Product',
    type: 'string',
    disableSorting: true,
  },
  { id: 'category', label: 'Category', type: 'string', disableSorting: true },
  { id: 'barcode', label: 'Barcode', type: 'string', disableSorting: true },
  { id: 'sku', label: 'SKU', type: 'string', disableSorting: true },
  {
    id: 'quantitySold',
    label: 'Quantity Sold',
    type: 'integer',
  },
  {
    id: 'TotalSoldAmount',
    label: 'Total Sold Amount',
    type: 'float',
  },
];

export default function ProductSales() {
  const classes = useStyles();
  const [records, setRecords] = useState([]);
  const [recordsCount, setRecordsCount] = useState(0);
  const [categories, setCategories] = useState([]);
  const [productName, setProductName] = useState('');
  const [category, setCategory] = useState('');
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  function getFilteredRecords(e) {
    e.preventDefault();
    getRecords(0, 5, 1, 'updatedAt');
  }

  async function getRecords(page, rowsPerPage, sortDirection, sortColumnName) {
    let searchString = '';
    setIsLoading(true);
    if (dateFrom) {
      searchString += `&startDate=${dateFrom}T00:00:00${timeZone()}`;
    }
    if (dateTo) {
      searchString += `&endDate=${dateTo}T23:59:59${timeZone()}`;
    }
    if (productName) {
      searchString += `&itemName=${productName}`;
    }
    if (category) {
      searchString += `&categoryRef=${category._id}`;
    }
    productSaleService
      .getAllProductSales(
        page,
        rowsPerPage,
        sortDirection,
        sortColumnName,
        searchString
      )
      .then((Response) => {
        setIsLoading(false);
        if (Response.data && Response.data.data) {
          setRecords(Response.data.data);
          setRecordsCount(Response.data.count);
        } else {
          console.error('Error While Fetching Data: ', Response.error);
        }
      });
  }

  useEffect(() => {
    categoryService.getAllCategoriesForDropDown().then((Response) => {
      if (Response.data) {
        //Map data array with Generic List Object with Properties id,text
        //Dropdown only accepts list of Object with Properties id,text
        var result = Response.data.data.map((dataArrayRow) => ({
          _id: dataArrayRow._id,
          name: dataArrayRow.name,
        }));
        setCategories(result);
        if (Response.data.count === 0) {
          setNotify({
            isOpen: true,
            message: 'No Category Found',
            type: 'error',
          });
        }
      } else {
        console.error('Unable To Get Records');
      }
    });
  }, []);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  useEffect(() => {
    activityMonitoring('ProductSales');
  }, []);
  return (
    <>
      <PageTitle
        title="Product wise Sales Report"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="productSalesHeading"
      />

      <Paper elevation={6} square className={classes.PaperMargin}>
        <Toolbar style={{ padding: '0' }}>
          <form
            onSubmit={getFilteredRecords}
            style={{ width: '100%', display: 'flex', alignItems: 'center' }}
          >
            <TextField
              id="txtSearchProduct"
              label="Product"
              className={classes.searchInput}
              variant="outlined"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              autoComplete="off"
            />
            <Controls.EditableDropDown
              name="txtSearchCategory"
              label="Category"
              data={categories}
              onChange={(e, newValue) => setCategory(newValue)}
              options={categories}
              className={classes.searchInput}
              id="ddSearchCategory"
            />
            <TextField
              className={classes.searchInput}
              id="txtSearchProdSaleDateFrom"
              label="Date From"
              variant="outlined"
              type="date"
              onChange={(e) => setDateFrom(e.target.value)}
              value={dateFrom}
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
            />
            <TextField
              className={classes.searchInput}
              id="txtSearchProdSaleDateTo"
              label="Date To"
              variant="outlined"
              type="date"
              onChange={(e) => setDateTo(e.target.value)}
              value={dateTo}
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
            />
            <Controls.Button
              text="Search"
              variant="contained"
              id="btnSearchProdSale"
              color="primary"
              type="submit"
              className={classes.searchButton}
              startIcon={<SearchIcon />}
            ></Controls.Button>
          </form>
        </Toolbar>
        <CircularLoader isload={isLoading} />
        <GenericTable
          data={records}
          TableId={'productSalesTable'}
          TableTitle={'Product Wise Sale Report'}
          duration={`${dateFrom} ${dateTo ? 'To' : ''} ${dateTo}`}
          cellData={[
            { name: 'itemRef.name', type: 'string' },
            { name: 'categoriesRef.name', type: 'string' },
            { name: 'itemRef.barcode', type: 'string' },
            { name: 'itemRef.sku', type: 'string' },
            { name: 'quantitySold', type: 'integer' },
            { name: 'TotalSoldAmount', type: 'float' },
          ]}
          headCells={headCells}
          recordsCount={recordsCount}
          getRecordsFn={getRecords}
          isLoading={isLoading}
        />
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
