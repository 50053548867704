import React from 'react';
import { Button, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Online } from 'react-detect-offline';
import QRCode from 'qrcode.react';
const CustomerModalBody = ({
  classes,
  modalStyle,
  generateQr,
  generatePushInvoice,
  handleGenerateQRCode,
  handleCallPushInvoice,
  nayaPayQRCode,
  setGenerateQr,
  GenerateNayaPayInvoice,
  nayaPayId,
  setNayaPayId,
  setGeneratePushInvoice,
  handleNayaPayPushInvoice,
}) => {
  const QRCodex = (
    <>
      <QRCode value={nayaPayQRCode} size={250} />
      <div
        style={{
          display: 'flex',
          marginTop: '25px',
        }}
      >
        <Button
          variant="contained"
          onClick={() => setGenerateQr(false)}
          id="btnPOSGenerateQRCode"
          style={{
            marginRight: 'auto',
            marginLeft: '5px',
          }}
          color="primary"
        >
          Back
        </Button>
        <Button
          variant="contained"
          onClick={() => GenerateNayaPayInvoice()}
          id="btnPOSGenerateQRCode"
          style={{
            marginRight: '5px',
            marginLeft: 'auto',
          }}
          color="primary"
        >
          Print Invoice
        </Button>
      </div>
    </>
  );
  const PushInvoice = (
    <>
      <TextField
        id="txtnayapayid"
        label="NayaPay ID"
        variant="outlined"
        color="primary"
        value={nayaPayId}
        onChange={(e) => setNayaPayId(e.target.value)}
        style={{
          marginLeft: 'auto',
        }}
        autoComplete="off"
      />

      <div
        style={{
          display: 'flex',
          marginTop: '25px',
        }}
      >
        <Button
          variant="contained"
          onClick={() => setGeneratePushInvoice(false)}
          id="btnPOSGenerateQRCode"
          style={{
            marginRight: 'auto',
            marginLeft: '5px',
          }}
          color="primary"
        >
          Back
        </Button>
        <Button
          variant="contained"
          onClick={handleNayaPayPushInvoice}
          id="btnPOSOpeningAmount"
          style={{
            marginRight: '5px',
            marginLeft: 'auto',
          }}
          color="primary"
        >
          Push Invoice
        </Button>
      </div>
    </>
  );

  return (
    <>
      <div style={modalStyle} className={classes.paper6}>
        <h2 id="simple-modal-title">Payment Through Naya Pay</h2>
        {generateQr ? (
          QRCodex
        ) : generatePushInvoice ? (
          PushInvoice
        ) : (
          <>
            <div
              style={{
                margin: '10px',
              }}
            ></div>
            <div
              style={{
                margin: '10px',
              }}
            >
              <Button
                variant="contained"
                onClick={handleGenerateQRCode}
                id="btnPOSGenerateQRCode"
                style={{
                  display: 'flex',
                  marginRight: 'auto',
                  marginLeft: 'auto',
                  height: '100px',
                  width: '250px',
                }}
                color="primary"
              >
                Generate QR Code
              </Button>
            </div>
            <Online>
              <hr />
              <div
                style={{
                  margin: '10px',
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => handleCallPushInvoice()}
                  id="btnPOSGenerateQRCode"
                  style={{
                    display: 'flex',
                    marginRight: 'auto',
                    marginLeft: 'auto',
                    height: '100px',
                    width: '250px',
                  }}
                  color="primary"
                >
                  Push Invoice
                </Button>
              </div>
            </Online>
          </>
        )}
      </div>
    </>
  );
};
CustomerModalBody.propTypes = {
  classes: PropTypes.any,
  modalStyle: PropTypes.any,
  QRCodex: PropTypes.any,
  generateQr: PropTypes.any,
  generatePushInvoice: PropTypes.any,
  PushInvoice: PropTypes.any,
  handleGenerateQRCode: PropTypes.any,
  handleCallPushInvoice: PropTypes.any,
  nayaPayQRCode: PropTypes.any,
  setGenerateQr: PropTypes.any,
  GenerateNayaPayInvoice: PropTypes.any,
  nayaPayId: PropTypes.any,
  setNayaPayId: PropTypes.any,
  setGeneratePushInvoice: PropTypes.any,
  handleNayaPayPushInvoice: PropTypes.any,
};
export default CustomerModalBody;
