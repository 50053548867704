import authHeader from '../../../Services/auth-header';
import { callAPI } from '../../../Services/ApiCaller';
import { API_VERSION } from '../../../Common/constants';

export async function getAllAreaes(
  page,
  rowsPerPage,
  sortDirection,
  sortColumnName,
  searchString
) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  let rowsFrom = page * rowsPerPage;

  let sortOrderdir = sortDirection === 'asc' ? 1 : -1;
  return callAPI(
    `api/${API_VERSION}/areas`,
    null,
    headerObject,
    'GET',
    `?populate=cityRef&$orderBy=${sortColumnName}&$orderDir=${sortOrderdir}&$skip=${rowsFrom}&$top=${rowsPerPage}${searchString}`
  ).then((Responce) => {
    return Responce;
  });
}

export async function getAllAreaesForDropDown() {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  return callAPI(
    `api/${API_VERSION}/areas`,
    null,
    headerObject,
    'GET',
    `?$orderBy=name&$orderDir=1&$top=50000`
  ).then((Responce) => {
    return Responce;
  });
}

export async function insertArea(Area) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  var AreaData = {
    name: Area.name.trim(),
    status: 'active',
    startTime: Area.startTime,
    endTime: Area.endTime,
    cityRef: Area.citiesRef_id,
    branchMapping: [],
  };
  return callAPI(
    `api/${API_VERSION}/Areas`,
    AreaData,
    headerObject,
    'POST',
    ''
  ).then((Responce) => {
    return Responce;
  });
}

export async function deleteArea(_id) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  return callAPI(
    `api/${API_VERSION}/Areas/`,
    null,
    headerObject,
    'DELETE',
    _id
  ).then((Responce) => {
    return Responce;
  });
}

export async function updateArea(Area) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  var AreaUpdate = {
    name: Area.name.trim(),
    status: Area.status,
    startTime: Area.startTime,
    endTime: Area.endTime,
    cityRef: Area.citiesRef_id,
    branchMapping: Area.branchMapping,
  };
  return callAPI(
    `api/${API_VERSION}/Areas`,
    AreaUpdate,
    headerObject,
    'PUT',
    `/${Area._id}`
  ).then((Responce) => {
    return Responce;
  });
}

export async function patchArea(id, AreaObject) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  return callAPI(
    `api/${API_VERSION}/Areas`,
    AreaObject,
    headerObject,
    'PATCH',
    `/${id}`
  ).then((Responce) => {
    return Responce;
  });
}
