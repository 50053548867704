import authHeader from '../../../Services/auth-header';
import { callAPI } from '../../../Services/ApiCaller';
import { API_VERSION } from '../../../Common/constants';

export const getRole = (role) =>
  role === 'superadmin'
    ? [
        { _id: 'superadmin', name: 'Super Admin', nameValue: 'superadmin' },
        {
          _id: 'companyadmin',
          name: 'Company Admin',
          nameValue: 'companyadmin',
        },
        {
          _id: 'branchmanager',
          name: 'Branch Manager',
          nameValue: 'branchmanager',
        },
        { _id: 'cashier', name: 'Cashier', nameValue: 'cashier' },
      ]
    : role === 'companyadmin'
    ? [
        {
          _id: 'companyadmin',
          name: 'Company Admin',
          nameValue: 'companyadmin',
        },
        {
          _id: 'branchmanager',
          name: 'Branch Manager',
          nameValue: 'branchmanager',
        },
        { _id: 'cashier', name: 'Cashier', nameValue: 'cashier' },
      ]
    : role === 'branchadmin'
    ? [
        {
          _id: 'branchmanager',
          name: 'Branch Manager',
          nameValue: 'branchmanager',
        },
        { _id: 'cashier', name: 'Cashier', nameValue: 'cashier' },
      ]
    : [{ _id: 'cashier', name: 'Cashier', nameValue: 'cashier' }];

export async function GetAllUsers(
  page,
  rowsPerPage,
  sortDirection,
  sortColumnName,
  name,
  role
) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  // let rowsFrom = page * rowsPerPage;

  // let sortOrderdir = sortDirection === 'asc' ? 1 : -1;

  let filterQuery = '';

  if (name != '') {
    filterQuery = filterQuery + `&name=${name}`;
  }

  if (role != '') {
    filterQuery = filterQuery + `&role=${role}`;
  }

  let queryString = `?changeRequest=true`;
  queryString = queryString + filterQuery;

  if (!sortColumnName) {
    sortColumnName = 'name';
  }
  return callAPI(
    `api/${API_VERSION}/users`,
    null,
    headerObject,
    'GET',
    queryString
  ).then((Responce) => {
    return Responce;
  });
}

export async function approveChangePassword(userId, approveRequest) {
  var userUpdate = {
    _id: userId,
    approveRequest: approveRequest,
  };

  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  return callAPI(
    `api/${API_VERSION}/users/forgetPassword`,
    userUpdate,
    headerObject,
    'PUT',
    ``
  ).then((Responce) => {
    return Responce;
  });
}
