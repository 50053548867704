import { Paper, Toolbar, TextField } from '@material-ui/core';
import Controls from '../../Utilities/Controls/Controls';
import SearchIcon from '@material-ui/icons/Search';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import React, { useEffect, useState } from 'react';
import PageTitle from '../../Layout/PageTitle';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import APIService from './InventoryService';
import CircularLoader from '../../Utilities/ReuseableCommponents/CircularLoader';
import GenericTable from '../../Utilities/ReuseableCommponents/TableReport/GenericTable';
import { dateTimeZone as timeZone } from '../../../Common/constants';
import activityMonitoring from '../../../Common/functions';

const headCells = [
  { id: 'createdAt', label: 'Date & Time', type: 'date' },
  {
    id: 'expectedCountTotal',
    label: 'Total Expected Qty',
    disableSorting: true,
    type: 'integer',
  },
  {
    id: 'actualCountTotal',
    label: 'Total Actual Qty',
    disableSorting: true,
    type: 'integer',
  },
];

let apiResponseDto = {
  data: null,
  error: null,
  loading: false,
};

export default function Sales() {
  const [records, setRecords] = useState([]);
  const [recordsCount, setRecordsCount] = useState(0);
  const [dateFrom, setDateFrom] = React.useState('');
  const [dateTo, setDateTo] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  function getRecords(page, rowsPerPage, sortDirection, sortColumnName) {
    let searchString = '';
    let populate = 'items';
    let data = (async () => {
      setIsLoading(true);
      if (dateFrom) {
        searchString += `&filteredFrom=${dateFrom}T00:00:00${timeZone()}`;
      }
      if (dateTo) {
        searchString += `&filteredTo=${dateTo}T23:59:59${timeZone()}`;
      }
      apiResponseDto = await APIService.FetchInventoryList(
        page,
        rowsPerPage,
        sortDirection,
        sortColumnName,
        populate,
        searchString
      ).then((Response) => {
        return Response;
      });
      return apiResponseDto;
    })();
    data.then((Response) => {
      setIsLoading(false);
      if (Response.data) {
        if (Response.data.data) {
          setRecords(Response.data.data);
          setRecordsCount(Response.data.count);
        }
      } else {
        setIsLoading(false);
        console.error('Error While Fetching Data: ', Response.error);
      }
    });
  }

  const handleSearch = (e) => {
    e.preventDefault();
    getRecords(0, 5, -1, 'createdAt');
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  useEffect(() => {
    activityMonitoring('Inventory');
  }, []);
  return (
    <>
      <PageTitle
        title="Inventory"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="inventoryListHeading"
      />

      <Paper elevation={6} square className="paperMargin">
        <Toolbar disableGutters>
          <form className="searchFormAlign" onSubmit={handleSearch}>
            <TextField
              id="txtSearchInventoryDateFrom"
              label="Date From"
              variant="outlined"
              type="date"
              onChange={(e) => setDateFrom(e.target.value)}
              value={dateFrom}
              className="searchInput"
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
            />
            <TextField
              id="txtSearchInventoryDateTo"
              label="Date To"
              variant="outlined"
              type="date"
              onChange={(e) => setDateTo(e.target.value)}
              value={dateTo}
              className="searchInput"
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
            />
            <Controls.Button
              text="Search"
              variant="contained"
              color="primary"
              id="btnSearchInventory"
              startIcon={<SearchIcon />}
              className="searchButton"
              type="submit"
            />
          </form>
        </Toolbar>
        <CircularLoader isload={isLoading} />
        <GenericTable
          data={records}
          TableId={'inventoryTable'}
          TableTitle={'Inventory Report'}
          duration={`${dateFrom} ${dateTo ? 'To' : ''} ${dateTo}`}
          cellData={[
            {
              name: 'updatedAt',
              type: 'date',
              align: 'left',
            },
            { name: 'expectedCountTotal', type: 'integer' },
            { name: 'actualCountTotal', type: 'integer' },
          ]}
          headCells={headCells}
          recordsCount={recordsCount}
          getRecordsFn={getRecords}
          rowOnClick={{
            path: '/InventoryDetails',
            header: 'Inventory Details',
            property: ['data'],
          }}
          isLoading={isLoading}
        />
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
