import axios from 'axios';
import { callAPI } from '../../../Services/ApiCaller';
import {
  BaseAPIURL,
  API_VERSION,
  getDateTime,
} from '../../../Common/constants';
import * as setupService from '../Setups/SetupService';
import { getAPIrequestHeadersBasic } from '../../../Common/APICallingServiceConstants';

const API_URL = BaseAPIURL;

export async function getArea() {
  let apiResponseDto = {
    data: [],
    error: null,
    loading: false,
    statusCode: 0,
  };
  let data = (async () => {
    apiResponseDto = await setupService
      .getSetupWithName('Area')
      .then((Response) => {
        return Response;
      });

    return apiResponseDto;
  })();
  data.then((Response) => {
    if (Response.data) {
      let areaData = Response.data.data.filter((e) => e.name === 'Area');
      var areaList = [];
      var inc = 1;
      areaData[0].data.forEach((area) => {
        let areaForDD = {
          _id: inc,
          name: area.name,
          nameValue: area.name,
          deliveryCharges: area.deliveryCharges,
        };
        areaList.push(areaForDD);
        inc++;
      });
      return areaList;
    }
  });
}

export function getOrderSource() {
  return [
    {
      _id: '1',
      name: 'POS',
      nameValue: 'POS',
    },
    { _id: '2', name: 'Call Center', nameValue: 'Call Center' },
  ];
}

export function getArea1() {
  var data = [
    {
      _id: '1',
      name: 'Saddar',
      nameValue: 'Saddar',
    },
    { _id: '2', name: 'Gulshan e Iqbal', nameValue: 'Gulshan e Iqbal' },
    { _id: '3', name: 'DHA', nameValue: 'DHA' },
  ];
  return data;
}
export function getOrderMode(isOdms) {
  var data = [];
  if (!isOdms) {
    data = [
      {
        _id: '1',
        name: 'TakeAway',
        nameValue: 'takeAway',
      },
      { _id: '2', name: 'Delivery', nameValue: 'delivery' },
      { _id: '3', name: 'DineIn', nameValue: 'dineIn' },
    ];
    return data;
  } else {
    data = [
      {
        _id: '1',
        name: 'TakeAway',
        nameValue: 'takeAway',
      },
      { _id: '2', name: 'Delivery', nameValue: 'delivery' },
    ];
    return data;
  }
}
export function getBranch() {
  var data = [
    {
      _id: 1,
      name: 'DHA',
      nameValue: 'DHA',
    },
    {
      _id: 2,
      name: 'Clifton',
      nameValue: 'Clifton',
    },
  ];
  return data;
}
export function getRider() {
  var data = [
    {
      _id: 1,
      name: 'Aslam',
      nameValue: 'Aslam',
    },
    {
      _id: 2,
      name: 'Omair',
      nameValue: 'Omair',
    },
    {
      _id: 3,
      name: 'Saad',
      nameValue: 'Saad',
    },
    {
      _id: 4,
      name: 'Asad',
      nameValue: 'Asad',
    },
  ];
  return data;
}
export function getWaiter() {
  var data = [
    {
      _id: 1,
      name: 'Ali',
      nameValue: 'Ali',
    },
    {
      _id: 2,
      name: 'Uzair',
      nameValue: 'Uzair',
    },
    {
      _id: 3,
      name: 'Samad',
      nameValue: 'Samad',
    },
    {
      _id: 4,
      name: 'Wasif',
      nameValue: 'Wasif',
    },
  ];
  return data;
}
export function getTable() {
  var data = [
    {
      _id: 1,
      name: '1',
      nameValue: '1',
    },
    {
      _id: 2,
      name: '2',
      nameValue: '2',
    },
    {
      _id: 3,
      name: '3',
      nameValue: '3',
    },
    {
      _id: 4,
      name: '4',
      nameValue: '4',
    },
  ];
  return data;
}
export async function GetCustomerData(_id) {
  const headers = getAPIrequestHeadersBasic();
  return callAPI(
    `api/${API_VERSION}/customers`,
    null,
    headers,
    'GET',
    '?populate=cityRef,areaRef&contactNumber=' + _id
  ).then((Responce) => {
    return Responce.data.data;
  });
}
export function startshift(OpeningAmount) {
  var data = {
    shiftNumber: 'B1',
    reconcileStatus: 'start',
    openingAmount: OpeningAmount ? OpeningAmount : 0,
    createdAt: getDateTime(),
  };
  const headers = getAPIrequestHeadersBasic();
  return axios
    .post(API_URL + `/api/${API_VERSION}/cashReconciles`, data, {
      headers: headers,
    })
    .then((response) => {
      if (response.data.data._id) {
        localStorage.setItem('_IdShift', JSON.stringify(response.data.data));
      }

      return response.data;
    });
}
export function endshift(
  ExpectedAmount,
  ClosingAmount,
  InternalSaleAmount,
  Comments
) {
  const headerObject = getAPIrequestHeadersBasic();
  const ShiftData = JSON.parse(localStorage.getItem('_IdShift'));
  var data = {
    _id: ShiftData._id,
    shiftNumber: ShiftData.shiftNumber,
    openingAmount: ShiftData.openingAmount,
    expectedClosingAmount: ExpectedAmount ? ExpectedAmount : 0,
    actualClosingAmount: ClosingAmount ? ClosingAmount : 0,
    internalSaleAmount: InternalSaleAmount ? InternalSaleAmount : 0,
    comments: Comments ? Comments : '',
    createdAt: ShiftData.createdAt,
    updatedAt: getDateTime(),
    __v: ShiftData.__v + 1,
  };
  return axios
    .put(
      API_URL + `/api/${API_VERSION}/cashReconciles/` + ShiftData._id,
      data,
      {
        headers: headerObject,
      }
    )
    .then((response) => {
      if (response.data.data._id) {
        localStorage.removeItem('_IdShift');
      }

      return response.data;
    });
}
export function POSData() {
  const headerObject = getAPIrequestHeadersBasic();
  return axios
    .post(API_URL + `/api/${API_VERSION}/items?populate=categoriesRef`, {
      headers: headerObject,
    })
    .then((response) => {
      return response.data;
    });
}
export function Sales(SalesObj) {
  const headerObject = getAPIrequestHeadersBasic();
  var data = {
    SalesObj,
  };
  return axios
    .post(API_URL + `/api/${API_VERSION}/cashReconciles`, data, {
      headers: headerObject,
    })
    .then((response) => {
      return response.data;
    });
}
export async function ChangeStatusRequest(obj) {
  const headerObject = getAPIrequestHeadersBasic();

  return callAPI(
    `api/${API_VERSION}/orderManagement`,
    obj,
    headerObject,
    'POST',
    ''
  ).then((Responce) => {
    return Responce;
  });
}

export async function OrderSyncRequest(obj) {
  const headerObject = getAPIrequestHeadersBasic();
  var selectedOrderModeValue = getOrderMode().filter(
    (item) => item.name === obj.orderMode
  )[0].nameValue;

  var RequestType = 'POST';

  if (obj.orderIdOdms !== -1) {
    RequestType = 'PUT';
  }

  var FinalLIST = getItemSold(obj);

  var Branch = {
    _id: obj.branchRef,
    name: obj.branchName,
  };
  var SaleDetail = {
    saleType: obj.saleType,
    clientCreatedAt: obj.clientCreatedDate,
    clientUpdatedAt: obj.clientModifiedDate,
    overallSaleDiscountAmount: obj.overallSaleDiscountAmount,
    saleAmountSumOfItems: obj.saleAmountSumOfItems,
    saleAmountTotal: parseFloat(obj.saleAmountTotal).toFixed(2),
    itemsSold: FinalLIST,
    dealsSold: obj.dealsSold,
    deliveryCharges: obj.deliveryCharges,
  };
  let PayLoad = {
    state: 'booked',
    paymentMode: 'cash',
    customerRef: obj.customerRef,
    orderMode: 'ODMS',
    orderType: selectedOrderModeValue,
    saleDetails: SaleDetail,
    branchRef: Branch._id,
    deliveryTime: obj.deliveryTime,
    specialNote: obj.specialNote,
  };

  return callAPI(
    `api/${API_VERSION}/orderManagement`,
    PayLoad,
    headerObject,
    RequestType,
    ''
  ).then((Responce) => {
    return Responce;
  });
}
export async function getOrdersByStatus(status, searchString = '') {
  const headerObject = getAPIrequestHeadersBasic();

  return callAPI(
    `api/${API_VERSION}/orderManagement`,
    null,
    headerObject,
    'GET',
    '?populate=dealRef,customerRef&state=' + status + searchString
  ).then((Responce) => {
    return Responce;
  });
}
function getItemSold(obj) {
  var FinalLIST = [];
  obj.itemsSold.forEach((item) => {
    if (!item.isDeal) {
      FinalLIST.push({
        itemRef: item._id,
        quantity: item.Quantity,
        taxAmount: (item.saleFinalPrice - item.salePrice).toFixed(2),
        discountAmount: item.Tempdiscount,
        saleFinalPrice: item.unitSaleFinalPrice,
        saleAmountTotal: item.saleFinalPrice,
      });
    }
  });
  return FinalLIST;
}
export async function InvoiceSyncRequest(obj) {
  const headerObject = getAPIrequestHeadersBasic();
  var selectedOrderModeValue = getOrderMode().filter(
    (item) => item.name === obj.orderMode
  )[0].nameValue;

  var FinalLIST = getItemSold(obj);

  const PayLoad = {
    saleType: selectedOrderModeValue,
    saleMode: obj.saleMode,
    shiftNumber: obj.shiftNumber,
    invoiceNumber: obj.invoiceNumber,
    itemsSold: FinalLIST,
    dealsSold: obj.dealsSold,
    clientCreatedAt: obj.clientCreatedDate,
    clientUpdatedAt: obj.clientModifiedDate,
    overallSaleDiscountAmount: obj.overallSaleDiscountAmount,
    saleAmountSumOfItems: obj.saleAmountSumOfItems,
    saleAmountTotal: obj.saleAmountTotal,
    customerRef: obj.customerRef,
    orderMode: obj.orderMode,
    riderRef: obj.riderRef,
    waiterRef: obj.waiterRef,
    tableRef: obj.tableRef,
    deliveryCharges: obj.deliveryCharges,
    branchRef: obj.branchRef,
  };

  return callAPI(
    `api/${API_VERSION}/sales`,
    PayLoad,
    headerObject,
    'POST',
    '?populate=itemsSold'
  ).then((Responce) => {
    return Responce;
  });
}
export async function GetExpectedAmts() {
  const headerObject = getAPIrequestHeadersBasic();
  const ShiftData = JSON.parse(localStorage.getItem('_IdShift'));
  return callAPI(
    `api/${API_VERSION}/sales`,
    null,
    headerObject,
    'GET',
    '?saleType=external&$top=5000&saleMode=cash&shiftNumber=' +
      ShiftData.shiftNumber +
      '&updatedFrom=' +
      ShiftData.createdAt +
      '&updatedTo=' +
      getDateTime()
  ).then((Responce) => {
    return Responce;
  });
}
export async function GetSalesData(_id) {
  const headerObject = getAPIrequestHeadersBasic();
  return callAPI(
    `api/${API_VERSION}/sales`,
    null,
    headerObject,
    'GET',
    '?populate=itemsSold,itemsReturned&invoiceNumber=' + _id
  ).then((Responce) => {
    return Responce.data.data;
  });
}
export async function SalesReturnRequest(obj) {
  const headerObject = getAPIrequestHeadersBasic();
  var itemsReturneds = [];
  var itemsSolds = [];
  var returnedAmountSum = 0;
  obj.itemsSold.forEach((item) => {
    if (item.returnQty > 0) {
      itemsReturneds.push({
        itemRef: item.itemRef._id,
        quantity: item.returnQty,
        returnFinalPrice: item.saleFinalPrice,
        returnAmountTotal: item.refundAmount,
      });
      returnedAmountSum = returnedAmountSum + item.refundAmount;
    }
  });
  obj.itemsSold.forEach((item) => {
    itemsSolds.push({
      itemRef: item.itemRef._id,
      quantity: item.quantity,
      taxAmount: item.taxAmount,
      discountAmount: item.discountAmount,
      saleFinalPrice: item.saleFinalPrice,
      saleAmountTotal: item.saleAmountTotal,
    });
  });
  const PayLoad = {
    saleType: obj.saleType,
    saleMode: obj.saleMode,
    _id: obj._id,
    shiftNumber: obj.shiftNumber,
    invoiceNumber: obj.invoiceNumber,
    itemsSold: itemsSolds,
    itemsReturned: itemsReturneds,
    overallSaleDiscountAmount: obj.overallSaleDiscountAmount,
    saleAmountSumOfItems: obj.saleAmountSumOfItems,
    returnedAmountSumOfItems: returnedAmountSum,
    saleAmountTotal: obj.saleAmountTotal - returnedAmountSum,
    createdAt: obj.createdAt,
    updatedAt: getDateTime(),
    clientCreatedAt: obj.clientCreatedAt,
    clientUpdatedAt: getDateTime(),
    __v: obj.__v + 1,
  };
  return callAPI(
    `api/${API_VERSION}/sales`,
    PayLoad,
    headerObject,
    'PUT',
    '/' + obj._id
  ).then((Responce) => {
    return Responce;
  });
}
export async function GetSettings(Type) {
  const headers = getAPIrequestHeadersBasic();
  try {
    return callAPI(
      `api/${API_VERSION}/setup`,
      null,
      headers,
      'GET',
      '?name=' + Type
    ).then((Responce) => {
      if (Responce.data) {
        if (Responce.data.count > 0) {
          if (
            Type == 'ReceiptText' ||
            Type == 'Cancel Order Time' ||
            Type == 'CancelOrderReason' ||
            Type == 'Modify Order Time'
          ) {
            localStorage.setItem(
              Type,
              JSON.stringify(Responce.data.data[0].data)
            );
          } else {
            localStorage.setItem(Type, Responce.data.data[0].data);
          }

          return Responce.data.data;
        } else {
          return localStorage.setItem(Type, 'default');
        }
      } else {
        return localStorage.setItem(Type, 'default');
      }
    });
  } catch (e) {
    //do nothing
    console.error(e);
  }
}
export async function getAllSales() {
  const headerObject = getAPIrequestHeadersBasic();
  const ShiftData = JSON.parse(localStorage.getItem('_IdShift'));
  return callAPI(
    `api/${API_VERSION}/sales`,
    null,
    headerObject,
    'GET',
    `?populate=?populate=itemsSold,itemsReturned&updatedFrom=${ShiftData.createdAt}`
  ).then((Responce) => {
    return Responce;
  });
}
export async function NayaPayPushInvoice(invoiceId, consumer, amount) {
  const payload = {
    branchId: '602242f6789e8120594f4da7',
    invoiceId: invoiceId,
    merchantId: '60223e45789e8120594f4d33',
    consumer: consumer,
    amount: amount,
    currency: '586',
    dueDate: '14-06-2021 00:00:00',
    userId: 'nayapay77@testing.com',
    terminalId: 'terminal-001',
    apiKey: '5498f78c-c50d-4703-92f7-db0b162f2405',
  };
  const headers = getAPIrequestHeadersBasic();
  return callAPI(
    `api/${API_VERSION}/paymenttransaction/nayapay/PushInvoice`,
    payload,
    headers,
    'POST',
    ''
  ).then((Responce) => {
    return Responce.data.data;
  });
}
