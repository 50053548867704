import { BaseAPIURL } from '../Common/constants';
const Responce = {
  data: null,
  error: null,
  loading: false,
  messege: '',
  statusCode: 0,
};

export const callAPI = async (
  subUrl,
  payLoad,
  headerObject,
  requestMethodType,
  queryString
) => {
  const baseUrl = BaseAPIURL;
  // requestEnvelopeDetails without Body
  var requestEnvelopeDetails = {
    method: requestMethodType,
    headers: headerObject,
  };

  // Add Body if request type is POST
  if (requestMethodType !== 'GET' && payLoad) {
    requestEnvelopeDetails['body'] = JSON.stringify(payLoad);
  }

  try {
    Responce.loading = true;
    const response = await fetch(
      baseUrl + '/' + subUrl + '' + queryString,
      requestEnvelopeDetails
    );
    if (response.ok) {
      Responce.data = await response.json();
      Responce.error = null;
    } else {
      Responce.statusCode = response.status;
      switch (response.status) {
        case 400:
          break;
        case 401:
          break;
        case 403:
          break;
        case 409:
          break;
        case 500:
          break;
        default:
          break;
      }
      await response.json().then((API_Response) => {
        Responce.error = API_Response.error ? API_Response.error : response;
        Responce.messege = API_Response.message
          ? API_Response.message
          : 'Unable To Proces Request';
        Responce.data = null;
        Responce.loading = false;
      });
    }
  } catch (e) {
    console.error('Error While Executing API Request', e);
    Responce.data = null;
    Responce.error = e;
  } finally {
    Responce.loading = false;
  }

  return Responce;
};
