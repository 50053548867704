import React from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

function TimePicker(props) {
  const { name, label, value, error = null, onChange, ...other } = props;
  return (
    <TextField
      variant="outlined"
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      autoComplete="off"
      type="time"
      {...other}
      {...(error && { error: true, helperText: error })}
    />
  );
}
TimePicker.propTypes = {
  name: PropTypes.any,
  label: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.any,
  error: PropTypes.any,
};
export default TimePicker;
