import React from 'react';
import { Paper, TextField, Button } from '@material-ui/core';
import PageHeader from '../../Layout/PageTitle';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useLocation, useHistory } from 'react-router-dom';
import { getFormattedDateTime as ISOdateFormatter } from '../../../Common/constants';

function InventoryDetails() {
  const location = useLocation();
  const history = useHistory();

  return (
    <>
      <PageHeader
        title="Inventory"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="inventoryDetailsHeading"
      />
      <Paper elevation={6} square className="paperMargin">
        <TextField
          id="txtInventoryDetailsDate"
          label="Date"
          disabled={true}
          variant="outlined"
          value={ISOdateFormatter(location.state.data.updatedAt) || ''}
          className="searchInput"
          InputLabelProps={{
            shrink: true,
          }}
          autoComplete="off"
        />
      </Paper>
      <Paper elevation={6} square className="paperMargin">
        <TableContainer>
          <Table
            id="inventoryDetailsTable"
            className="table"
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell>S.No.</TableCell>
                <TableCell align="left">Product</TableCell>
                <TableCell align="left">Category</TableCell>
                <TableCell align="left">Barcode</TableCell>
                <TableCell align="left">SKU</TableCell>
                <TableCell align="center">Expected Qty</TableCell>
                <TableCell align="center">Current Qty</TableCell>
                <TableCell align="center">Inventory Type</TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody id="inventoryDetailsTbody">
              {location.state.data.items.map((row, index) => (
                <TableRow key={row._id} id={'inventoryDetailsTrow' + index}>
                  <TableCell component="th" scope="row">
                    {index + 1 || ''}
                  </TableCell>
                  <TableCell align="left">
                    {row.itemRef ? row.itemRef.name || '' : ''}
                  </TableCell>
                  <TableCell align="left">
                    {(row.itemRef &&
                      row.itemRef.categoriesRef[0] &&
                      row.itemRef.categoriesRef[0].name) ||
                      ''}
                  </TableCell>
                  <TableCell align="left">
                    {row.itemRef ? row.itemRef.barcode || '' : ''}
                  </TableCell>
                  <TableCell align="left">
                    {row.itemRef ? row.itemRef.sku || '' : ''}
                  </TableCell>
                  <TableCell align="center">
                    {row.expectedCount || ''}
                  </TableCell>
                  <TableCell align="center">{row.actualCount}</TableCell>
                  <TableCell align="center">
                    {row.inventoryType ? row.inventoryType : '-'}
                  </TableCell>
                  <TableCell align="left">
                    <TextField
                      disabled
                      value={row.comments}
                      variant="outlined"
                      autoComplete="off"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Button
            variant="contained"
            onClick={() => {
              history.push({
                pathname: '/Inventory',
              });
            }}
            className="newButton"
            id="btnBackInventoryDetails"
          >
            Back
          </Button>
        </TableContainer>
      </Paper>
    </>
  );
}

export default InventoryDetails;
