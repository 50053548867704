import { BaseAPIURL, API_VERSION } from '../../../../Common/constants';
import { callAPI } from '../../../../Services/ApiCaller';
import authHeader from '../../../../Services/auth-header';

const Responce = {
  data: null,
  error: null,
  loading: false,
};

export const getUserRoleCollection = () => [
  { _id: 'superadmin', name: 'Super Admin', nameValue: 'superadmin' },
  { _id: 'companyadmin', name: 'Company Admin', nameValue: 'companyadmin' },
  { _id: 'branchmanager', name: 'Branch Manager', nameValue: 'branchmanager' },
  { _id: 'cashier', name: 'Cashier', nameValue: 'cashier' },
  { _id: 'agent', name: 'Agent', nameValue: 'agent' },
];

export async function signUpUser(user) {
  var userRoles = getUserRoleCollection();
  var user_Role = userRoles.filter(function (ObjectRow) {
    if (ObjectRow._id == user.userRole_id) {
      return ObjectRow.nameValue;
    }
    return '';
  });

  var modifiedUser = {
    email: user.email,
    password: user.password,
    name: user.name,
    companyName: user.companyName,
    phonenumber: user.phonenumber,
    type: user.type,
    role: user_Role[0].nameValue,
  };

  async function fetchData() {
    try {
      Responce.loading = true;
      const response = await fetch(BaseAPIURL + `/${API_VERSION}/signup`, {
        method: 'POST',
        body: JSON.stringify(modifiedUser),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        Responce.data = await response.json();
        Responce.error = null;
        return Responce;
      }
      throw response;
    } catch (e) {
      Responce.data = null;
      Responce.error = e;
    } finally {
      Responce.loading = false;
    }
    return Responce;
  }

  return fetchData().then((Responce) => {
    return Responce;
  });
}

export async function insertCompanies(company) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  return callAPI(
    `api/${API_VERSION}/companies`,
    company,
    headerObject,
    'POST',
    ''
  ).then((Responce) => {
    return Responce;
  });
}
