import authHeader from '../../../Services/auth-header';
import { callAPI } from '../../../Services/ApiCaller';
import { API_VERSION } from '../../../Common/constants';
import { getAPIrequestHeadersBasic } from '../../../Common/APICallingServiceConstants';
class InventoryService {
  async createInventory(body) {
    const header = authHeader();
    const headerObject = {
      'Content-Type': 'application/json',
      Authorization: header.Authorization,
    };
    return callAPI(
      `api/${API_VERSION}/inventory`,
      body,
      headerObject,
      'POST',
      ''
    ).then((Response) => {
      return Response;
    });
  }

  async updateInventory(body, id) {
    const header = authHeader();
    const headerObject = {
      'Content-Type': 'application/json',
      Authorization: header.Authorization,
    };
    return callAPI(
      `api/${API_VERSION}/inventory/${id}`,
      body,
      headerObject,
      'PUT',
      ''
    ).then((Response) => {
      return Response;
    });
  }

  async FetchInventoryList(
    page,
    rowsPerPage,
    sortDirection,
    sortColumnName,
    populate,
    searchString
  ) {
    const header = authHeader();
    const headerObject = {
      'Content-Type': 'application/json',
      Authorization: header.Authorization,
    };
    let rowsFrom = page * rowsPerPage;

    let sortOrderdir = sortDirection === 'asc' ? 1 : -1;
    if (!sortColumnName) {
      sortColumnName = 'createdAt';
    }
    return callAPI(
      `api/${API_VERSION}/inventory`,
      null,
      headerObject,
      'GET',
      `?inventoryType=purchase&populate=${populate}&$orderBy=${sortColumnName}&$orderDir=${sortOrderdir}&$skip=${rowsFrom}&$top=${rowsPerPage}${searchString}`
    ).then((Response) => {
      return Response;
    });
  }

  async FetchUpdatedLatestInventory(
    page,
    rowsPerPage,
    sortDirection,
    searchString
  ) {
    const headerObject = getAPIrequestHeadersBasic();
    let rowsFrom = page * rowsPerPage;
    let sortOrderdir = sortDirection === 'asc' ? 1 : -1;

    return callAPI(
      `api/${API_VERSION}/inventory/fetchUpdate`,
      null,
      headerObject,
      'GET',
      `?$orderDir=${sortOrderdir}&$skip=${rowsFrom}&$top=${rowsPerPage}${searchString}`
    ).then((Response) => {
      return Response;
    });
  }

  async FetchUpdatedLatestPurchaseInventory(page, rowsPerPage, sortDirection) {
    const header = authHeader();
    const headerObject = {
      'Content-Type': 'application/json',
      Authorization: header.Authorization,
    };

    let rowsFrom = page * rowsPerPage;

    let sortOrderdir = sortDirection === 'asc' ? 1 : -1;
    return callAPI(
      `api/${API_VERSION}/inventory/fetchPurchaseInventory`,
      null,
      headerObject,
      'GET',
      `?$orderDir=${sortOrderdir}&$skip=${rowsFrom}&$top=${rowsPerPage}`
    ).then((Response) => {
      return Response;
    });
  }
}
export default new InventoryService();
