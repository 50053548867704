import React, { useEffect, useState } from 'react';
import { Paper, Toolbar, TextField, Grid } from '@material-ui/core';
import Controls from '../../Utilities/Controls/Controls';
import PageTitle from '../../Layout/PageTitle';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import * as customerSalesService from '../CustomerSales/CustomerSalesService';
import {
  getCustomerStatus,
  getCustomerType,
  dateTimeZone as timeZone,
} from '../../../Common/constants';
import CircularLoader from '../../Utilities/ReuseableCommponents/CircularLoader';
import GenericTable from '../../Utilities/ReuseableCommponents/TableReport/GenericTable';
import SearchIcon from '@material-ui/icons/Search';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import { getAllCitiesForDropDown } from '../City/CityService';
import activityMonitoring from '../../../Common/functions';

const headCells = [
  { id: 'customerId', label: 'Customer ID', type: 'string' },
  { id: 'startDate', label: 'Start Date', type: 'onlyDate' },
  { id: 'endDate', label: 'End Date', type: 'onlyDate' },
  { id: 'name', label: 'Customer Name', type: 'string' },
  {
    id: 'contactNumber',
    label: 'Contact Number',
    type: 'string',
  },
  { id: 'CustomerType', label: 'Type', type: 'string' },
  { id: 'city', label: 'City', type: 'string' },
  { id: 'totalSale', label: 'Total Sale', type: 'float' },
];

export default function CustomerSales() {
  const [records, setRecords] = useState([]);
  const [recordsCount, setRecordsCount] = useState(0);
  const [dateFrom, setDateFrom] = React.useState('');
  const [dateTo, setDateTo] = React.useState('');
  const [city, setCity] = useState('');
  const [customerType, setCustomerType] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [rangeFrom, setRangeFrom] = React.useState();
  const [rangeTo, setRangeTo] = React.useState();
  const [cities, setCities] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    getCityListBrnch();
  }, []);

  function getRecords(page, rowsPerPage, sortDirection, sortColumnName) {
    let searchString = '';

    setIsLoading(true);
    if (dateFrom) {
      searchString += `&startDate=${dateFrom}T00:00:00${timeZone()}`;
    }
    if (dateTo) {
      searchString += `&endDate=${dateTo}T23:59:59${timeZone()}`;
    }
    if (customerType && customerType !== '#') {
      searchString += `&customerType=${customerType}`;
    }
    if (city) {
      searchString += `&cityRef=${city}`;
    }
    if (rangeFrom) {
      searchString += `&saleRangeFrom=${rangeFrom}`;
    }
    if (rangeTo) {
      searchString += `&saleRangeTo=${rangeTo}`;
    }
    if (status) {
      searchString += `&status=${status}`;
    }
    return customerSalesService
      .getAllCustomerSales(
        page,
        rowsPerPage,
        sortDirection,
        sortColumnName,
        searchString
      )
      .then((Response) => {
        setIsLoading(false);

        if (Response.data && Response.data.data) {
          setRecords(Response.data.data);
          setRecordsCount(Response.data.count);
          if (Response.data.count == 0) {
            setNotify({
              isOpen: true,
              message: 'No Data Found',
              type: 'error',
            });
          }
        } else {
          console.error('Error While Fetching Data: ', Response.error);
        }
      });
  }

  const handleSearch = (e) => {
    e.preventDefault();
    getRecords(0, 5, -1, 'totalSale');
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });
  async function getCityListBrnch() {
    await getAllCitiesForDropDown().then((response) => {
      if (response.data) {
        //Map data array with Generic List Object with Properties id,text
        //Dropdown only accepts list of Object with Properties id,text
        var result = response.data.data.map((dataArrayRow) => ({
          _id: dataArrayRow._id,
          name: dataArrayRow.name,
        }));

        var cityList = [];
        result.forEach((cityItem) => {
          let cityForDD = {
            _id: cityItem._id,
            name: cityItem.name,
            nameValue: cityItem.name,
          };
          cityList.push(cityForDD);
        });
        setCities(cityList);
      } else {
        console.error('Unable To Get Records');
      }
    });
  }
  useEffect(() => {
    activityMonitoring('CustomerSales');
  }, []);
  return (
    <>
      <PageTitle
        title="Customer Sales Report"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="customerSalesHeading"
      />
      <Paper elevation={6} square className="paperMargin">
        <Toolbar>
          <form onSubmit={handleSearch} className="searchFormAlign">
            <Grid container direction="row">
              <Grid container direction="row">
                <Grid item sm={3}>
                  <TextField
                    id="txtSearchCustomerSalesDateFrom"
                    label="Date From"
                    variant="outlined"
                    fullWidth
                    type="date"
                    onChange={(e) => setDateFrom(e.target.value)}
                    value={dateFrom}
                    className="searchInput"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    autoComplete="off"
                  />
                </Grid>
                <Grid item sm={3}>
                  <TextField
                    id="txtSearchCustomerSalesDateTo"
                    label="Date To"
                    variant="outlined"
                    type="date"
                    onChange={(e) => setDateTo(e.target.value)}
                    value={dateTo}
                    className="searchInput"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    autoComplete="off"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={3}>
                  <TextField
                    id="txtSearchRangeFrom"
                    label="Sale Range From"
                    className="searchInput"
                    variant="outlined"
                    value={rangeFrom}
                    onChange={(e) => setRangeFrom(e.target.value)}
                    autoComplete="off"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={3}>
                  <TextField
                    id="txtSearchRangeTo"
                    label="Sale Range To"
                    className="searchInput"
                    variant="outlined"
                    value={rangeTo}
                    onChange={(e) => setRangeTo(e.target.value)}
                    autoComplete="off"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sm={3}>
                  <Controls.EditableDropDown
                    name="city"
                    label="City"
                    fullWidth
                    customWidth="98%"
                    data={cities}
                    onChange={(e, newValue) =>
                      setCity(newValue ? newValue._id : '')
                    }
                    id="ddSearchCustomerCity"
                  />
                </Grid>
                <Grid item sm={3}>
                  <Controls.EditableDropDown
                    name="ddSearchCustomerType"
                    label="Customer Type"
                    customWidth="98%"
                    data={getCustomerType()}
                    onChange={(e, newValue) => {
                      setCustomerType(newValue ? newValue.nameValue : '');
                    }}
                    id="ddSearchCustomerType"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={3}>
                  <Controls.EditableDropDown
                    name="ddSearchStatus"
                    label="Status"
                    customWidth="98%"
                    data={getCustomerStatus()}
                    onChange={(e, newValue) => {
                      setStatus(newValue ? newValue.nameValue : '');
                    }}
                    id="ddSearchStatus"
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item sm={2} align="center">
                  <Controls.Button
                    text="Search"
                    variant="contained"
                    color="primary"
                    id="btnSearchCustomerSales"
                    startIcon={<SearchIcon />}
                    className="searchButton"
                    type="submit"
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Toolbar>

        <CircularLoader isload={isLoading} />
        <GenericTable
          data={records}
          TableId={'customerSalesTable'}
          TableTitle={'Customer Sales Report'}
          duration={`${dateFrom} ${dateTo ? 'To' : ''} ${dateTo}`}
          cellData={[
            { name: 'customerId', type: 'string' },
            { name: 'startDate', type: 'onlyDate' },
            { name: 'endDate', type: 'onlyDate' },
            { name: 'name', type: 'string' },
            { name: 'contactNumber', type: 'string' },
            { name: 'CustomerType', type: 'string' },
            { name: 'city', type: 'string' },
            { name: 'totalSale', type: 'float' },
          ]}
          headCells={headCells}
          recordsCount={recordsCount}
          getRecordsFn={getRecords}
          rowOnClick={{
            path: '/CustomerSalesDetail',
            header: 'Customer Sales Detail',
            property: ['customer'],
          }}
          isLoading={isLoading}
        />
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
