import React from 'react';
class Unauthorized extends React.Component {
  render() {
    return (
      <>
        <h2 style={{ textAlign: 'center' }}>You Are </h2>
        <h1 style={{ textAlign: 'center' }}>U N - A U T H O R I Z E D</h1>
        <h2 style={{ textAlign: 'center' }}> to Access This Page!</h2>
        <h1 style={{ textAlign: 'center' }}>4 0 1</h1>
      </>
    );
  }
}

export default Unauthorized;
