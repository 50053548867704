import authHeader from '../../../Services/auth-header';
import { callAPI } from '../../../Services/ApiCaller';
import { API_VERSION } from '../../../Common/constants';

export async function getAllCities(
  page,
  rowsPerPage,
  sortDirection,
  sortColumnName,
  searchString
) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  let rowsFrom = page * rowsPerPage;

  let sortOrderdir = sortDirection === 'asc' ? 1 : -1;
  return callAPI(
    `api/${API_VERSION}/cities`,
    null,
    headerObject,
    'GET',
    `?$orderBy=${sortColumnName}&$orderDir=${sortOrderdir}&$skip=${rowsFrom}&$top=${rowsPerPage}${searchString}`
  ).then((Responce) => {
    return Responce;
  });
}

export async function getAllCitiesForDropDown() {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  return callAPI(
    `api/${API_VERSION}/cities`,
    null,
    headerObject,
    'GET',
    `?$orderBy=name&$orderDir=1&$top=50000`
  ).then((Responce) => {
    return Responce;
  });
}

export async function insertCity(city) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  var cityData = {
    name: city.name,
  };
  return callAPI(
    `api/${API_VERSION}/cities`,
    cityData,
    headerObject,
    'POST',
    ''
  );
}

export async function deleteCity(_id) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  return callAPI(
    `api/${API_VERSION}/cities/`,
    null,
    headerObject,
    'DELETE',
    _id
  ).then((Responce) => {
    return Responce;
  });
}

export async function updateCity(city) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  var cityUpdate = {
    name: city.name,
  };

  return callAPI(
    `api/${API_VERSION}/cities`,
    cityUpdate,
    headerObject,
    'PATCH',
    `/${city._id}`
  ).then((Responce) => {
    return Responce;
  });
}

export async function patchCity(id, CityObject) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  return callAPI(
    `api/${API_VERSION}/cities`,
    CityObject,
    headerObject,
    'PATCH',
    `/${id}`
  ).then((Responce) => {
    return Responce;
  });
}
