import React from 'react';
import { Modal } from '@material-ui/core';
import ShortcutsInfoModalBody from './ShortcutsInfoModalBody';
import PropTypes from 'prop-types';

const ShortcutsInfoModal = (props) => {
  return (
    <div>
      <Modal
        open={props.openShortcutInfo}
        onClose={props.handleCloseShortcutInfo}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div>
          <ShortcutsInfoModalBody {...props} />
        </div>
      </Modal>
    </div>
  );
};
ShortcutsInfoModal.propTypes = {
  openShortcutInfo: PropTypes.any,
  handleCloseShortcutInfo: PropTypes.any,
  props: PropTypes.any,
};
export default ShortcutsInfoModal;
