import { Paper, Typography, Grid, IconButton, Box } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import Controls from '../../Utilities/Controls/Controls';
import { useForm, Form } from '../../Utilities/ReuseableCommponents/useForm';
import * as categoryService from '../Category/CategoryService';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import PageHeader from '../../Layout/PageTitle';
import { useLocation, useHistory } from 'react-router-dom';
import * as productService from './ProductService';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import InputAdornment from '@material-ui/core/InputAdornment';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import ClearIcon from '@material-ui/icons/Clear';
import Dropzone from '../../Utilities/ReuseableCommponents/Dropzone';
import ImageCropper from '../../Utilities/ReuseableCommponents/ImageCropper';
import ImageCropperDialog from '../../Utilities/ReuseableCommponents/ImageCropperDialog';
import { GetCompanyType } from '../../.././Common/constants';
import NumberFormatCustom from '../../Utilities/ReuseableCommponents/NumberFormatCustom';
import Modal from '@material-ui/core/Modal';
import barcodeIcon from '../../../Assets/Images/barcode.svg';
import useStyles from '../../../Style/AppStylesAdmin';

function getModalStyle() {
  const top = 50;
  const left = 50;
  const height = 75;
  const width = 75;
  const overflow = 'auto';
  const borderRadius = 10;
  const borderColor = 'transparent';

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    height: `${height}%`,
    width: `${width}%`,
    overflow: `${overflow}`,
    borderRadius: `${borderRadius}px`,
    borderColor: `${borderColor}`,
  };
}

const initialFValues = {
  _id: 0,
  categoriesRef: [],
  status: true,
  name: '',
  salePrice: '',
  taxPercentage: 0,
  saleFinalPrice: '',
  barcode: '',
  unitOfPurchase: 'Pcs',
  unitOfSale: 'Pcs',
  conversionUnit: '',
  categoriesRef_id: '',
  minDiscountPercentage: 0,
  maxDiscountPercentage: 0,
  description: '',
  image: '',
  isRecipeItem: false,
  isSaleable: true,
  sku: '',
};

export default function ProductDetails() {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const companyType = GetCompanyType();

  const [records, setRecords] = useState([]);
  const [recordForEdit, setRecordForEdit] = useState(
    location.state.recordForEdit
  );

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const { values, setValues, handleInputChange, resetForm } = useForm(
    initialFValues
  );

  //Use Effect For Initial Rending - Initially Get Data From An API
  useEffect(() => {
    loadCategoriesForDropdown();
  }, []);

  useEffect(() => {
    if (recordForEdit) {
      loadCategoriesForDropdown();
      if (recordForEdit.image) {
        if (recordForEdit.image.contentType && recordForEdit.image.data) {
          var FileData = `data:${recordForEdit.image.contentType};base64,${recordForEdit.image.data}`;
          recordForEdit.image = FileData;
          setFiles([FileData]);
        }
      }
      setValues({
        ...recordForEdit,
      });
      setRecordForEdit(null);
    }
    if ((values.taxPercentage || values.salePrice) && recordForEdit === null) {
      const generateFinalSellingPrice = () => {
        let ReceivedSalePrice = parseFloat(values.salePrice) || 0;
        let ReceivedTaxPercentage = parseFloat(values.taxPercentage) || 0;
        let TaxAmount = (ReceivedSalePrice * ReceivedTaxPercentage) / 100;
        setValues({
          ...values,
          saleFinalPrice: ReceivedSalePrice + TaxAmount,
        });
      };
      generateFinalSellingPrice();
    }
  }, [values.taxPercentage, values.salePrice]); // eslint-disable-line react-hooks/exhaustive-deps

  function loadCategoriesForDropdown() {
    categoryService.getAllCategoriesForDropDown().then((response) => {
      if (response.data) {
        //Map data array with Generic List Object with Properties id,text
        //Dropdown only accepts list of Object with Properties id,text
        var result = response.data.data.map((dataArrayRow) => ({
          _id: dataArrayRow._id,
          name: dataArrayRow.name,
        }));
        setRecords(result);
        if (response.data.count === 0) {
          setNotify({
            isOpen: true,
            message: 'No Categories To Load',
            type: 'error',
          });
        }
      } else {
        console.error('Unable To Get Records');
      }
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    addOrEdit(values, resetForm);
  };

  const addOrEdit = async (product, formReset) => {
    try {
      if (imageDeleted) {
        product.image = '';
      }
      if (product._id == 0) {
        createProduct(product, formReset);
      } else if (
        product._id !== 0 &&
        product.name &&
        product.categoriesRef_id.length
      ) {
        await productService.updateProduct(product).then((response) => {
          if (response.data) {
            resetForm();
            setFiles([]);
            setNotify({
              isOpen: true,
              message: 'Product Detail Updated Successfully',
              type: 'success',
            });
          } else {
            setNotify({
              isOpen: true,
              message: 'Unable To Process Request',
              type: 'error',
            });
          }
        });
      }
    } catch (e) {
      console.error(e);
      setNotify({
        isOpen: true,
        message: 'Unable To Process Request',
        type: 'error',
      });
    }
  };

  function createProduct(product, formReset) {
    productService.insertProduct(product).then((productResponse) => {
      if (productResponse.data) {
        formReset();
        setFiles([]);
        setNotify({
          isOpen: true,
          message: 'Submitted Successfully',
          type: 'success',
        });
      } else if (productResponse.statusCode === 409) {
        setNotify({
          isOpen: true,
          message: 'Record Already Present, Please Try With Different Details',
          type: 'error',
        });
      } else {
        setNotify({
          isOpen: true,
          message: 'Unable To Process Request',
          type: 'error',
        });
      }
    });
  }
  const [imageDeleted, setImageDeleted] = useState(false);
  const [files, setFiles] = useState([]);
  const [cropperFile, setCropperFile] = useState(null);
  const acceptDrop = useCallback(
    (file) => {
      setFiles([file]);
    },
    [setFiles]
  );
  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles.length + rejectedFiles.length > 1) {
        setNotify({
          isOpen: true,
          message: 'You cannot upload more than one file at once',
          type: 'error',
        });
      } else if (acceptedFiles.length === 0) {
        setNotify({
          isOpen: true,
          message: 'The file you wanted to upload isn`t an image',
          type: 'error',
        });
      } else if (acceptedFiles.length === 1) {
        const file = acceptedFiles[0];
        file.preview = URL.createObjectURL(file);
        file.key = new Date().getTime();
        setCropperFile(file);
      }
    },
    [setCropperFile]
  );
  const deleteItem = useCallback(() => {
    setImageDeleted(true);
    setCropperFile(null);
    setFiles([]);
  }, [setCropperFile, setFiles]);
  const onCropperClose = useCallback(() => {
    setCropperFile(null);
  }, [setCropperFile]);

  const onCrop = useCallback(
    (dataUrl) => {
      const file = { ...cropperFile };
      file.preview = dataUrl;

      setValues({
        ...values,
        ['image']: dataUrl,
      });
      setFiles([dataUrl]);
      acceptDrop(file);
      setCropperFile(null);
      setImageDeleted(false);
    },
    [acceptDrop, cropperFile, setCropperFile] // eslint-disable-line react-hooks/exhaustive-deps
  );
  const [open, setOpen] = useState(false);
  const [modalStyle] = useState(getModalStyle);
  const openModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };
  const modalBody = (
    <div style={modalStyle} className={classes.paperModal}>
      <IconButton
        style={{
          top: '0px',
          right: '0px',
          position: 'absolute',
        }}
        color="inherit"
        aria-label="menu"
        onClick={handleCloseModal}
        id="closeEndShift"
      >
        <ClearIcon />
      </IconButton>
      <img
        alt="Display Image"
        style={{
          height: '500px',
        }}
        src={values.image ? values.image : null}
      />
    </div>
  );

  function recipeItemCheckbox() {
    return companyType == 'food' ? (
      <Grid container>
        <Typography variant="body2" className={classes.headLabel}>
          POS Settings
        </Typography>
        <Grid container xs={12} sm={12}>
          <Grid item xs={12} sm={3}>
            <Controls.Checkbox
              name="isRecipeItem"
              label="Serve As Recipe Item"
              value={values.isRecipeItem}
              onChange={handleInputChange}
              id="chkRecipeItem"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controls.Checkbox
              name="isSaleable"
              label="Saleable Item"
              value={values.isSaleable}
              onChange={handleInputChange}
              id="chkRecipeItem"
            />
          </Grid>
        </Grid>
      </Grid>
    ) : (
      <></>
    );
  }
  return (
    <>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBody}
      </Modal>
      <PageHeader
        title={location.state.pageHeaderTitile}
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="productDetailsHeading"
      />
      <Paper elevation={6} square className={classes.PaperMargin}>
        <Form onSubmit={handleSubmit}>
          <Typography variant="body2" className={classes.headLabel}>
            General
          </Typography>
          {/* Name, | Purchase Price, Tax Percentage, Selling Price */}
          <Grid container>
            <Grid container xs={12} sm={12}>
              <Grid item xs={12} sm={6} md={4}>
                <Controls.Input
                  name="name"
                  label="Product Name"
                  value={values.name}
                  onChange={handleInputChange}
                  id="txtProdName"
                  required
                  InputLabelProps={{
                    classes: {
                      asterisk: classes.asterisk,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Controls.Select
                  name="categoriesRef_id"
                  label="Category"
                  value={values.categoriesRef_id}
                  onChange={handleInputChange}
                  options={records}
                  id="ddProdCat"
                  required
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                style={{
                  display: 'flex',
                }}
              >
                <Controls.Input
                  name="barcode"
                  label="Barcode"
                  style={{
                    width: '100%',
                  }}
                  value={values.barcode}
                  onChange={handleInputChange}
                  disabled
                  placeholder="Barcode will generate automatically."
                  id="txtProdBarcode"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={barcodeIcon} height="35px" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid container xs={12} sm={12}>
              <Grid container xs={12} sm={12} md={12} lg={6}>
                <Grid item xs={12} sm={6} md={6}>
                  <Controls.Select
                    name="unitOfPurchase"
                    label="Unit Of Purchase"
                    value={values.unitOfPurchase}
                    onChange={handleInputChange}
                    options={productService.getUnitTypeCollection()}
                    className={classes.unitType}
                    id="ddUnitOfPurchase"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Controls.Select
                    name="unitOfSale"
                    label="Unit Of Sale"
                    value={values.unitOfSale}
                    onChange={handleInputChange}
                    options={productService.getUnitTypeCollection()}
                    className={classes.unitType}
                    id="ddUnitOfSale"
                  />
                </Grid>
              </Grid>
              <Grid container xs={12} sm={12} md={12} lg={6}>
                <Grid item xs={12} sm={6} md={4}>
                  <Controls.Input
                    name="conversionUnit"
                    label="Conv. Unit"
                    value={values.conversionUnit}
                    onChange={handleInputChange}
                    required
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    id="txtProdConversionUnit"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Controls.Input
                    name="sku"
                    label="SKU"
                    value={values.sku}
                    onChange={handleInputChange}
                    id="txtSku"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Controls.Checkbox
                    name="status"
                    label="Active"
                    value={values.status}
                    onChange={handleInputChange}
                    id="chkProdActive"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Typography variant="body2" className={classes.headLabel}>
              Price
            </Typography>
            <Grid container xs={12} sm={12}>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Controls.Input
                  name="salePrice"
                  label="Sale Price"
                  value={values.salePrice}
                  onChange={handleInputChange}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rs.</InputAdornment>
                    ),
                    inputComponent: NumberFormatCustom,
                  }}
                  id="txtProdSalePrice"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Controls.Input
                  name="taxPercentage"
                  label="Tax %"
                  value={values.taxPercentage}
                  onChange={handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                    inputComponent: NumberFormatCustom,
                    min: 0,
                    max: 100,
                  }}
                  id="txtProdTax"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Controls.Input
                  name="salePriceFinalPrice"
                  label="Sale Final Price"
                  value={values.saleFinalPrice}
                  onChange={handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rs.</InputAdornment>
                    ),
                    readOnly: true,
                    disabled: true,
                    bold: 'true',
                    inputComponent: NumberFormatCustom,
                  }}
                  id="txtProdSaleFinalPrice"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Controls.Input
                  name="minDiscountPercentage"
                  label="Min Discount"
                  value={values.minDiscountPercentage}
                  onChange={handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                    inputComponent: NumberFormatCustom,
                  }}
                  id="txtProdMinDiscount"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Controls.Input
                  name="maxDiscountPercentage"
                  label="Max Discount"
                  value={values.maxDiscountPercentage}
                  onChange={handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                    inputComponent: NumberFormatCustom,
                  }}
                  id="txtProdMaxDiscount"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Typography variant="body2" className={classes.headLabel}>
              Description
            </Typography>
            <Grid container xs={12} sm={12}>
              <Grid item xs={12} sm={8} lg={10}>
                <Controls.Input
                  name="description"
                  value={values.description}
                  onChange={handleInputChange}
                  id="txtProdDescription"
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item xs={12} sm={4} lg={2} style={{ margin: 'auto 0' }}>
                {ImageCropper && (
                  <ImageCropperDialog
                    open={cropperFile ? true : false}
                    ImageCropper={ImageCropper}
                    src={cropperFile ? cropperFile.preview : ''}
                    onCrop={onCrop}
                    onClose={onCropperClose}
                    aspectRatio={1}
                  />
                )}
                <Box mb={3} style={{ margin: 'auto 8px auto 8px' }}>
                  {files[0] && files.length > 0 ? (
                    <div className={classes.imgWrapper}>
                      <img
                        alt="uploaded item"
                        src={values.image}
                        className={classes.img}
                        style={{ height: 113, width: 'auto' }}
                        onClick={() => openModal()}
                      />
                      <div className={classes.floatButtonWrapper}>
                        <IconButton onClick={deleteItem}>
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </div>
                  ) : (
                    <Dropzone
                      accept="image/png, image/jpeg"
                      onDrop={onDrop}
                      style={{ height: 113, width: '100%' }}
                    >
                      <span className={classes.uploadText}>
                        Upload file <br /> here
                      </span>
                    </Dropzone>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>

          {companyType ? recipeItemCheckbox() : <></>}
          <Grid container className={classes.ButtonsCotainerMargin}>
            <br></br>
            <Grid item xs={6} sm={6} align="left">
              <Controls.Button
                text="Cancel"
                color="default"
                onClick={history.goBack}
                startIcon={<CloseIcon />}
                id="btnCancelProd"
              />
            </Grid>
            <Grid item xs={6} sm={6} align="right">
              <div>
                <Controls.Button
                  type="submit"
                  startIcon={<SaveIcon />}
                  text="Save"
                  id="btnSaveProd"
                />
              </div>
            </Grid>
          </Grid>
        </Form>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        id_Yes="btnConfirmYes"
        id_No="btnConfirmNo"
      />
    </>
  );
}
