import React, { useState } from 'react';
import PageTitle from '../../Layout/PageTitle';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import {
  Paper,
  makeStyles,
  // TableBody,
  // TableRow,
  // TableCell,
  Toolbar,
  TextField,
} from '@material-ui/core';
import Controls from '../../Utilities/Controls/Controls';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import * as riderService from './RiderService';
import { useHistory } from 'react-router-dom';
// import useTableServerSide from '../../Utilities/ReuseableCommponents/useTableServerSide';
// import HighlightOffIcon from '@material-ui/icons/HighlightOff';
// import Switch from '@material-ui/core/Switch';
import InputMask from 'react-input-mask';
import GenericTable from '../../Utilities/ReuseableCommponents/TableReport/GenericTable';
import CircularLoader from '../../Utilities/ReuseableCommponents/CircularLoader';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  newButton: {
    position: 'absolute',
    right: '0px',
  },

  searchInput: {
    width: '15%',
    paddingRight: '5px',
  },

  PaperMargin: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
}));

const headCells = [
  { id: 'name', label: 'Rider Name', align: 'left' },
  { id: 'cnic', label: 'CNIC', align: 'left' },
  { id: 'cellNo', label: 'Cell No', align: 'left' },
  { id: 'status', label: 'Status', type: 'status' },
  { id: 'actions', label: 'Actions', disableSorting: true, type: 'remove' },
];

let apiResponseDto = {
  data: [],
  error: null,
  loading: false,
  statusCode: 0,
};

export default function Riders() {
  const classes = useStyles();
  const history = useHistory();
  const recordForEdit = null;
  const [records, setRecords] = useState([]);
  const [recordsCount, setRecordsCount] = useState(0);
  const [name, setName] = useState('');
  const [cnic, setCnic] = useState('');
  const [cell_no, setCell_no] = useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const rowOnClick = {
    path: '/RiderDetails',
    header: 'View/Edit Rider',
    property: ['recordForEdit'],
  };
  function getRecords(page, rowsPerPage, sortDirection, sortColumnName) {
    let searchString = '';
    setIsLoading(true);
    let data = (async () => {
      if (name) {
        searchString += `&name=${name}`;
      }
      if (cnic) {
        const findExtraSpaceIndex = cnic.indexOf(' ');
        const CNIC = cnic.slice(0, findExtraSpaceIndex);
        searchString += `&cnic=${CNIC}`;
      }
      if (cell_no) {
        searchString += `&cellNo=${cell_no}`;
      }
      apiResponseDto = await riderService
        .getAllRider(
          page,
          rowsPerPage,
          sortDirection,
          sortColumnName,
          searchString
        )
        .then((Response) => {
          return Response;
        });
      return apiResponseDto;
    })();
    data.then((Response) => {
      setIsLoading(false);
      if (Response.data) {
        setRecords(Response.data.data);
        setRecordsCount(Response.data.count);
      } else {
        setIsLoading(false);
        console.error('Error While Fetching Data: ', Response.error);
      }
    });
  }

  const handleStatusChange = async (event, index) => {
    let cloneDatatableRecords = records;
    let id = cloneDatatableRecords[index]._id;
    let checkedStatus = event.target.checked;

    if (id) {
      try {
        let categoryStatusUpdate = {
          status: checkedStatus ? 'active' : 'disabled',
        };
        apiResponseDto.data = null;
        apiResponseDto.error = null;
        apiResponseDto = await riderService
          .patchRider(id, categoryStatusUpdate)
          .then((Responce) => {
            return Responce;
          });
        if (apiResponseDto.data) {
          let newStatusChangedRecord = {
            ...cloneDatatableRecords[index],
            status: checkedStatus ? 'active' : 'disabled',
          };
          cloneDatatableRecords[index] = newStatusChangedRecord;
          setRecords(cloneDatatableRecords);
          setNotify({
            isOpen: true,
            message: 'Rider Status Updated Successfully',
            type: 'success',
            notificationTime: 15000,
          });
        }
        if (apiResponseDto.error) {
          setNotify({
            isOpen: true,
            message: 'Unable To Process Request',
            type: 'error',
          });
        }
      } catch (e) {
        setNotify({
          isOpen: true,
          message: 'Unable To Process Request',
          type: 'error',
        });
      }
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    getRecords(0, 5, -1, '');
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const onDelete = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    try {
      apiResponseDto.data = null;
      apiResponseDto.error = null;
      apiResponseDto = await riderService.deleteRider(id).then((Response) => {
        return Response;
      });
      if (apiResponseDto.data) {
        getRecords(0, 10, 1, 'name');
        setNotify({
          isOpen: true,
          message: 'Deleted Successfully',
          type: 'success',
        });
      }
      if (apiResponseDto.error) {
        if (apiResponseDto.statusCode === 403) {
          setNotify({
            isOpen: true,
            message:
              'Unable To Delete Rider : Sales Are Associated With This Rider',
            type: 'error',
          });
        } else {
          setNotify({
            isOpen: true,
            message: 'Unable To Process Request',
            type: 'error',
          });
        }
      }
    } catch (e) {
      setNotify({
        isOpen: true,
        message: 'Unable To Process Request',
        type: 'error',
      });
    }
  };

  return (
    <>
      <PageTitle
        title="Rider"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="riderListHeading"
      />
      <Paper elevation={1} square className={classes.PaperMargin}>
        <Toolbar disableGutters>
          <form
            onSubmit={handleSearch}
            style={{ width: '100%', display: 'flex', alignItems: 'center' }}
          >
            <TextField
              id="txtSearchRidName"
              label="Name"
              className={classes.searchInput}
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoComplete="off"
            />
            <InputMask
              mask="99999-9999999-9"
              value={cnic}
              onChange={(e) => setCnic(e.target.value)}
              disabled={false}
              maskChar=" "
            >
              {() => (
                <TextField
                  variant="outlined"
                  label="CNIC"
                  className={classes.searchInput}
                  id="txtSearchRidCnic"
                  autoComplete="off"
                />
              )}
            </InputMask>

            <TextField
              inputProps={{
                maxLength: 11,
              }}
              id="txtSearchRidCellNo"
              label="Cell No"
              className={classes.searchInput}
              variant="outlined"
              value={cell_no}
              onChange={(e) => setCell_no(e.target.value)}
              autoComplete="on"
            />
            <Controls.Button
              text="Search"
              variant="contained"
              color="primary"
              id="btnRidSearch"
              startIcon={<SearchIcon />}
              type="submit"
            />
            <Controls.Button
              text="Add New"
              variant="contained"
              color="primary"
              id="btnAddRider"
              startIcon={<AddIcon />}
              className={classes.newButton}
              onClick={() => {
                history.push({
                  pathname: '/RiderDetails',
                  state: {
                    pageHeaderTitile: 'Add New Rider',
                    recordForEdit,
                  },
                });
              }}
            />
          </form>
        </Toolbar>
        <CircularLoader isload={isLoading} />
        <GenericTable
          data={records}
          TableId={'riderTable'}
          TableTitle={'Riders Table'}
          cellData={[
            { name: 'name', type: 'string' },
            { name: 'cnic', type: 'string' },
            { name: 'cellNo', type: 'string' },
            { name: 'status', type: 'status' },
            { name: 'remove', type: 'remove' },
          ]}
          headCells={headCells}
          recordsCount={recordsCount}
          getRecordsFn={getRecords}
          statusChangeFn={handleStatusChange}
          rowDeleteDialog={setConfirmDialog}
          rowDeletefn={onDelete}
          rowOnClick={rowOnClick}
          isLoading={isLoading}
        />
        {/* <TblContainer>
          <TblHead />
          <TableBody id="ridListTbody">
            {recordsAfterPagingAndSorting().map((item, index) => (
              <TableRow
                key={item._id}
                id={`ridListTrow${index}`}
                onClick={(e) => {
                  if (!e.target.name) {
                    history.push({
                      pathname: '/RiderDetails',
                      state: {
                        pageHeaderTitile: 'View/Edit Rider',
                        recordForEdit: item,
                      },
                    });
                  }
                }}
              >
                <TableCell id={`ridName${index}`}>{item.name}</TableCell>
                <TableCell id={`ridCNIC${index}`}>{item.cnic}</TableCell>
                <TableCell id={`ridCellNo${index}`}>{item.cellNo}</TableCell>
                <TableCell align="right">
                  <Switch
                    checked={
                      item.status
                        ? item.status === 'active'
                          ? true
                          : false
                        : false
                    }
                    name="switchStatus"
                    id={`chkRiderStatus${index}`}
                    onChange={(e) => {
                      e.stopPropagation();
                      handleStatusChange(e, index);
                    }}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </TableCell>

                <TableCell align="right">
                  <Controls.ActionButton
                    color="secondary"
                    id={`btnDelRid${index}`}
                    onClick={(event) => {
                      event.stopPropagation();
                      setConfirmDialog({
                        isOpen: true,
                        title: 'Are you sure you want to delete this record?',
                        subTitle: "You can't undo this operation",
                        onConfirm: () => {
                          onDelete(item._id);
                        },
                      });
                    }}
                  >
                    <HighlightOffIcon />
                  </Controls.ActionButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination /> */}
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        id_Yes="btnDelRidYes"
        id_No="btnDelRidNo"
      />
    </>
  );
}
