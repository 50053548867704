import React, { useEffect, useState } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import * as SetupService from '../Pages/Setups/SetupService';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import style from './Header.module.css';

export default function Header() {
  const history = useHistory();
  const [auth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [headerTitle, setHeaderTitle] = useState('');

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function handleAccount(evnt) {
    handleClose();
    if (evnt === 'settings') {
      history.push('/Settings');
    } else if (evnt === 'myaccount') {
      history.push('/BillingInfo');
    }
  }
  function SIGNOUT() {
    localStorage.removeItem('Token');
    localStorage.removeItem('_IdShift');
    localStorage.removeItem('NayaPayActivation');
    localStorage.removeItem('role');
    localStorage.removeItem('_DiscountInfo');
    localStorage.removeItem('CompanyName');
    localStorage.removeItem('Company Logo');
    localStorage.removeItem('CompanyContactNumber');
    localStorage.removeItem('TemplateType');
    localStorage.removeItem('type');
    localStorage.removeItem('_Misc');
    localStorage.removeItem('_ShortKeys');
    localStorage.removeItem('_Commission');
    localStorage.removeItem('_HideFeature');
    history.push('/Signin');
  }
  useEffect(() => {
    async function getOrganizationName() {
      const Response = await SetupService.getAllSetups();
      if (Response.data) {
        let organizationInfoData = Response.data.data.filter(
          (e) => e.name === 'Organization Info'
        );
        const displayName =
          organizationInfoData && organizationInfoData.length
            ? organizationInfoData[0].data.displayName
            : '';
        setHeaderTitle(displayName);
      }
    }
    getOrganizationName();
  }, []);

  return (
    <Grid container alignItems="center">
      <Grid item></Grid>
      <Grid item sm className={style.companyNameCSS}>
        <h2 id="headerBusinessName" className={style.companyNameCSS}>
          {headerTitle}
        </h2>
      </Grid>
      <Grid item>
        {auth && (
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              id="btnHeaderUser"
            >
              <PersonRoundedIcon style={{ color: 'slategray' }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                id="dmUserSettings"
                onClick={() => handleAccount('settings')}
              >
                Settings
              </MenuItem>
              <MenuItem
                id="dmUserMyAccount"
                onClick={() => handleAccount('myaccount')}
              >
                My account
              </MenuItem>
              <MenuItem id="dmUserSignOut" onClick={() => SIGNOUT()}>
                Sign Out
              </MenuItem>
            </Menu>
          </div>
        )}
      </Grid>
    </Grid>
  );
}
