import React from 'react';
import * as Icons from '@material-ui/icons';
// using hideFeature here
let hideFeatures = JSON.parse(localStorage.getItem('_HideFeature')) || [];
let commissionHideFeature = hideFeatures.find(
  (hf) => hf.name === 'CommissionReport'
);
let foundFeature = hideFeatures.find((hf) => hf.name === 'Barcode');
const isPrintBarcode = foundFeature && foundFeature.status ? true : false;
const isCommissionReport =
  commissionHideFeature && commissionHideFeature.status ? true : false;
const str = {
  data: [
    {
      name: 'Dashboard',
      url: '/',
      authorize: ['superadmin', 'companyadmin', 'branchmanager'],
      level: '1',
      type: ['retail', 'food'],
      icon: <Icons.Dashboard />,
      listItem: true,
    },
    {
      name: 'POS',
      url: '/POS',
      authorize: ['superadmin', 'companyadmin', 'branchmanager', 'cashier'],
      level: '1',
      type: ['retail'],
      icon: <Icons.ShoppingCart />,
      listItem: true,
    },
    {
      name: 'POS',
      url: '/POSFood',
      authorize: [
        'superadmin',
        'companyadmin',
        'branchmanager',
        'cashier',
        'agent',
      ],
      level: '1',
      type: ['food'],
      icon: <Icons.ShoppingCart />,
      listItem: true,
    },
    {
      name: 'Setup',
      authorize: ['superadmin', 'companyadmin', 'branchmanager'],
      level: '1',
      type: ['retail', 'food'],
      icon: <Icons.Settings />,
      listItem: true,
      children: [
        {
          name: 'Departments',
          url: '/Departments',
          authorize: ['superadmin', 'companyadmin', 'branchmanager'],
          type: ['food'],
          level: '2',
          listItem: false,
        },
        {
          name: 'Deals',
          url: '/Deals',
          authorize: ['superadmin', 'companyadmin'],
          type: ['food'],
          level: '2',
          listItem: true,
        },
        {
          name: 'Categories',
          url: '/Categories',
          authorize: ['superadmin', 'companyadmin', 'branchmanager'],
          type: ['retail', 'food'],
          level: '2',
          listItem: true,
        },
        {
          name: 'Products',
          url: '/Products',
          authorize: ['superadmin', 'companyadmin', 'branchmanager'],
          type: ['retail', 'food'],
          level: '2',
          listItem: true,
        },

        {
          name: 'Waiters',
          url: '/Waiters',
          authorize: ['superadmin', 'companyadmin', 'branchmanager'],
          type: ['food'],
          level: '2',
          listItem: true,
        },
        {
          name: 'Tables',
          url: '/Tables',
          authorize: ['superadmin', 'companyadmin', 'branchmanager'],
          type: ['food'],
          level: '2',
          listItem: true,
        },
        {
          name: 'Riders',
          url: '/Riders',
          authorize: ['superadmin', 'companyadmin', 'branchmanager'],
          type: ['food'],
          level: '2',
          listItem: true,
        },
        {
          name: 'Recipes',
          url: '/Recipes',
          authorize: ['superadmin', 'companyadmin'],
          type: ['food'],
          level: '2',
          listItem: true,
        },
        {
          name: 'Users',
          url: '/User',
          authorize: ['superadmin', 'companyadmin', 'branchmanager'],
          type: ['retail', 'food'],
          level: '2',
          listItem: true,
        },
        {
          name: 'NayaPay Activation',
          url: '/NayaPayActivation',
          authorize: ['superadmin', 'companyadmin'],
          type: ['retail'],
          level: '2',
          listItem: false,
        },
        {
          name: 'Billing Info',
          url: '/BillingInfo',
          authorize: ['superadmin', 'companyadmin'],
          type: ['retail', 'food'],
          level: '2',
          listItem: false,
        },
        {
          name: 'Settings',
          url: '/Settings',
          authorize: ['superadmin', 'companyadmin'],
          type: ['retail', 'food'],
          level: '2',
          listItem: false,
        },
        {
          name: 'Print Barcode',
          url: '/PrintBarcode',
          authorize: ['superadmin', 'companyadmin'],
          type: ['retail', 'food'],
          level: '2',
          listItem: isPrintBarcode,
        },
      ],
    },
    {
      name: 'Branch Mgmt',
      authorize: ['superadmin', 'companyadmin', 'branchmanager'],
      level: '1',
      type: ['food', 'retail'],
      icon: <Icons.Store />,
      listItem: true,
      children: [
        {
          name: 'City',
          url: '/City',
          authorize: ['superadmin', 'companyadmin'],
          type: ['food', 'retail'],
          level: '2',
          listItem: true,
        },
        {
          name: 'Branch',
          url: '/Branches',
          authorize: ['superadmin', 'companyadmin', 'agent'],
          type: ['food', 'retail'],
          level: '2',
          listItem: true,
        },
        {
          name: 'Area',
          url: '/Areas',
          authorize: ['superadmin', 'companyadmin'],
          type: ['food', 'retail'],
          level: '2',
          listItem: true,
        },
        {
          name: 'Branch Mapping',
          url: '/BranchMapping',
          authorize: ['superadmin', 'companyadmin'],
          type: ['food', 'retail'],
          level: '2',
          listItem: true,
        },
      ],
    },
    {
      name: 'Customer Mgmt',
      authorize: ['superadmin', 'companyadmin', 'branchmanager'],
      level: '1',
      type: ['retail', 'food'],
      icon: <Icons.PeopleAlt />,
      listItem: true,
      children: [
        {
          name: 'Customers',
          url: '/Customer',
          authorize: ['superadmin', 'companyadmin', 'branchmanager'],
          type: ['retail', 'food'],
          level: '2',
          listItem: true,
        },
        {
          name: 'Customers Receivable',
          url: '/CreditSales',
          authorize: ['superadmin', 'companyadmin', 'branchmanager'],
          type: ['retail'],
          level: '2',
          listItem: true,
        },
      ],
    },
    {
      name: 'Finance Management',
      authorize: ['financemanager'],
      level: '1',
      type: ['retail', 'food'],
      icon: <Icons.Money />,
      listItem: true,
      children: [
        {
          name: 'Billing',
          url: '/Billing',
          authorize: ['financemanager'],
          type: ['retail', 'food'],
          level: '2',
          listItem: true,
        },
        {
          name: 'Finance Billing Control',
          url: '/CompanyBillingControl',
          authorize: ['financemanager'],
          type: ['retail', 'food'],
          level: '2',
          listItem: true,
        },
      ],
    },
    {
      name: 'Vendor Mgmt',
      authorize: ['superadmin', 'companyadmin', 'branchmanager'],
      level: '1',
      type: ['retail'],
      icon: <Icons.Storefront />,
      listItem: true,
      children: [
        {
          name: 'Vendors',
          url: '/Vendor',
          authorize: ['superadmin', 'companyadmin', 'branchmanager'],
          type: ['retail'],
          level: '2',
          listItem: true,
        },
        {
          name: 'Vendor Payable',
          url: '/CreditSales',
          authorize: ['superadmin', 'companyadmin', 'branchmanager'],
          type: ['retail', 'food'],
          level: '2',
          listItem: false,
        },
      ],
    },
    {
      name: 'Inventory',
      authorize: ['superadmin', 'companyadmin', 'branchmanager'],
      level: '1',
      type: ['retail'],
      icon: <Icons.Widgets />,
      listItem: true,
      children: [
        {
          name: 'Purchase Inventory',
          url: '/PurchaseInventory',
          authorize: ['superadmin', 'companyadmin', 'branchmanager'],
          type: ['retail', 'food'],
          level: '2',
          listItem: true,
        },
        {
          name: 'Return Inventory',
          url: '/PurchaseReturnInventory',
          authorize: ['superadmin', 'companyadmin', 'branchmanager'],
          type: ['retail', 'food'],
          level: '2',
          listItem: true,
        },
        {
          name: 'Audit Inventory',
          url: '/AuditInventory',
          authorize: ['superadmin', 'companyadmin', 'branchmanager'],
          level: '2',
          type: ['retail', 'food'],
          listItem: true,
        },
      ],
    },
    {
      name: 'Reports',
      authorize: ['superadmin', 'companyadmin', 'branchmanager'],
      level: '1',
      type: ['retail', 'food'],
      icon: <Icons.BarChart />,
      listItem: true,
      children: [
        {
          name: 'Cash Reconciliation',
          url: '/CashReconciles',
          authorize: ['superadmin', 'companyadmin', 'branchmanager'],
          type: ['retail', 'food'],
          level: '2',
          listItem: true,
        },
        {
          name: 'Sales',
          url: '/Sales',
          authorize: ['superadmin', 'companyadmin', 'branchmanager'],
          level: '2',
          type: ['retail', 'food'],
          listItem: true,
        },
        {
          name: 'Product Sales',
          url: '/ProductSales',
          authorize: ['superadmin', 'companyadmin', 'branchmanager'],
          level: '2',
          type: ['retail', 'food'],
          listItem: true,
        },
        {
          name: 'Customer Sales',
          url: '/CustomerSales',
          authorize: ['superadmin', 'companyadmin', 'branchmanager'],
          level: '2',
          type: ['retail'],
          listItem: true,
        },
        {
          name: 'Vendor',
          url: '/VendorSales',
          authorize: ['superadmin', 'companyadmin', 'branchmanager'],
          level: '2',
          type: ['retail'],
          listItem: true,
        },
        {
          name: 'Inventory',
          url: '/Inventory',
          authorize: ['superadmin', 'companyadmin', 'branchmanager'],
          level: '2',
          type: ['retail'],
          listItem: true,
        },
        {
          name: 'Commission Report',
          url: '/CommissionReport',
          authorize: ['superadmin', 'companyadmin', 'branchmanager'],
          level: '2',
          type: ['retail'],
          listItem: isCommissionReport,
        },
        {
          name: 'Profit and Loss',
          url: '/ProfitAndLoss',
          authorize: ['superadmin', 'companyadmin'],
          level: '2',
          type: ['retail'],
          listItem: true,
        },
        {
          name: 'NayaPay Transactions',
          url: '/NayaPayTransactions',
          authorize: ['superadmin', 'companyadmin'],
          level: '2',
          type: ['retail'],
          listItem: true,
        },
      ],
    },
    {
      name: 'Password Mgmt',
      url: '/ForgotPasswordRequest',
      authorize: ['superadmin', 'companyadmin'],
      type: ['retail', 'food'],
      level: '1',
      icon: <Icons.LockOpen />,
      listItem: true,
    },
  ],
};

export default str;
