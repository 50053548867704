import React, { useState } from 'react';
import {
  Paper,
  Toolbar,
  TextField,
  Button,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Grid,
} from '@material-ui/core';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import PageTitle from '../../Layout/PageTitle';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasketRounded';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCartRounded';
import ReceiptIcon from '@material-ui/icons/ReceiptRounded';
import { Bar, Line, Pie } from 'react-chartjs-2';
import APIService from './DashboardService';
import useTableServerSide from '../../Utilities/ReuseableCommponents/useTableServerSide';
import * as salesService from '../Sales/SalesService';
import { getFormattedDateTime as ISOdateFormatter } from '../../../Common/constants';
import styles from './Dashboard.module.css';

import CustomChart, {
  CustomCards,
} from '../../Utilities/ReuseableCommponents/ChartsCards';
import MostSoldItemsViz from '../Visualizations/MostSoldItemsViz';
import activityMonitoring from '../../../Common/functions';

function Dashboard() {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [aggregatedSum, setAggregatedSum] = React.useState(0);
  const [totalItemsSold, setTotalItemsSold] = React.useState(0);
  const [salesBySourceOfOrderLabel, setSalesBySourceOfOrderLabel] = useState(
    []
  );
  const [label, setLabel] = React.useState([]);
  const [dataSet, setDataSet] = React.useState([]);
  const [records, setRecords] = useState([]);
  const [recordsCount, setRecordsCount] = useState(0);
  const [saleBySourceOfOrderDataSet, setSaleBySourceOfOrderDataSet] = useState(
    []
  );
  const [categoryWiseSaleLabels, setCategoryWiseSaleLabels] = useState([]);
  const [categoryWiseSaleData, setCategoryWiseSaleData] = useState([]);
  const [noOfSale, setNoOfSale] = React.useState(0);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });
  const [isClicked, setIsClicked] = useState(false);
  const [dateFrom, setDateFrom] = React.useState(() => {
    var date = new Date(
      new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60000
      ).setDate(new Date().getDate() - 7)
    );
    date = date.toISOString().substring(0, 10);
    return date;
  });
  const [dateTo, setDateTo] = React.useState(() => {
    var date = new Date(
      new Date().getTime() - new Date().getTimezoneOffset() * 60000
    );
    date = date.toISOString().substring(0, 10);
    return date;
  });
  const MOP = {
    cash: 'Cash',
    credit: 'Credit',
    creditcard: 'Credit Card',
    nayapay: 'NayaPay',
    cashondelivery: 'COD',
  };

  const headCells = [
    { id: 'cashier', label: 'Cashier' },
    { id: 'customer', label: 'Customer', align: 'center' },
    { id: 'saledate', label: 'SaleDate', align: 'center' },
    { id: 'status', label: 'Status', align: 'center' },
  ];
  const dataDailySaleSummary = {
    labels: label,
    datasets: [
      {
        label: 'Sale (Rs.)',
        backgroundColor: '#1976d2',
        borderColor: '#1976d2',
        borderWidth: 1,
        fill: false,
        hoverBackgroundColor: '#1976d2',
        hoverBorderColor: '#1976d2',
        barThickness: 70,
        data: dataSet,
      },
    ],
  };

  const dataCategoryWiseChart = {
    labels: categoryWiseSaleLabels || [],
    datasets: [
      {
        label: 'Sale (Rs.)',
        data: categoryWiseSaleData || [],
        backgroundColor: 'rgb(25,118,210)',
      },
    ],
  };

  const dataSalesByPaymentMethod = {
    labels: salesBySourceOfOrderLabel,
    datasets: [
      {
        label: 'Sales',
        data: saleBySourceOfOrderDataSet,
        backgroundColor: [
          '#1976D2',
          '#3A91E8',
          '#6BACEE',
          '#9CC8F3',
          '#CEE3F9',
        ],
        hoverOffset: 4,
      },
    ],
  };
  React.useEffect(() => {
    activityMonitoring('Dashboard');
  }, []);
  React.useEffect(() => {
    APIService.FetchDailySaleSummary(dateFrom, dateTo).then(
      (res) => {
        if (res.data) {
          const response = res.data;
          var L = [];
          var D = [];
          var sum = 0;
          response.data.forEach((item) => {
            L.push(
              ISOdateFormatter(new Date(item.date).toISOString()).slice(0, -9)
            );
            D.push(parseInt(item.saleAmountTotalSum));
            sum = parseInt(sum) + parseInt(item.saleAmountTotalSum);
          });
          setLabel(L);
          setDataSet(D);
          setIsLoaded(true);
          setAggregatedSum(sum);
        }
      },
      (error) => {
        console.log(error);
        setIsLoaded(false);
      }
    );

    APIService.FetchTotalItemsSold(dateFrom, dateTo).then(
      (response) => {
        if (response.data && response.data.data && response.data.data.length) {
          setTotalItemsSold(response.data.data[0].totalSoldItems);
        }
      },
      (error) => {
        console.log(error);
        setIsLoaded(false);
      }
    );

    APIService.FetchCountOfDailyOrders(dateFrom, dateTo).then(
      (res) => {
        if (res.data) {
          const response = res.data;
          var D = [];

          response.data.forEach((item) => {
            D.push(parseInt(item.countOfOrder));
          });
          if (D.length > 0) {
            let totalSalesCount = D.reduce(function (prev, current) {
              return prev + current;
            }, 0);
            setNoOfSale(totalSalesCount);
          }
        }
      },
      (error) => {
        console.log(error);
        setIsLoaded(false);
      }
    );

    APIService.FetchSalesBySourceOfOrder(dateFrom, dateTo).then(
      (res) => {
        if (res.data) {
          const response = res.data;
          var L = [];
          var D = [];
          response.data.forEach((item) => {
            L.push(MOP[item._id]);
            D.push(parseInt(item.countOfSaleMode));
          });
          setSalesBySourceOfOrderLabel(L);
          setSaleBySourceOfOrderDataSet(D);
        }
      },
      (error) => {
        console.log(error);
        setIsLoaded(false);
      }
    );

    APIService.fetchCategoryWiseSle(dateFrom, dateTo).then(
      (res) => {
        if (res.data) {
          const response = res.data;
          const lablesCWS = [];
          const dataCWS = [];
          response.data.forEach((item) => {
            lablesCWS.push(item.name);
            dataCWS.push(parseInt(item.saleAmountTotal));
          });
          setCategoryWiseSaleLabels([...lablesCWS]);
          setCategoryWiseSaleData([...dataCWS]);
        }
      },
      (error) => {
        console.log(error);
        setIsLoaded(false);
      }
    );

    getRecords(0, 10, -1, 'clientUpdatedAt');
    APIService.FetchMostSellableItems(dateFrom, dateTo).then(
      (res) => {
        if (res.data) {
          setIsLoaded(true);
        }
      },
      (error) => {
        console.log(error);
        setIsLoaded(false);
      }
    );
    setIsClicked(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClicked]);

  function getRecords(page, rowsPerPage, sortDirection, sortColumnName) {
    let searchString = '';
    let data = (async () => {
      if (dateFrom) {
        searchString += `&updatedFrom=${new Date(dateFrom)}`;
      }
      if (dateTo) {
        searchString += `&updatedTo=${new Date(dateTo)}`;
        return salesService
          .getAllSales(
            page,
            rowsPerPage,
            sortDirection,
            sortColumnName,
            searchString
          )
          .then((response) => {
            return response;
          });
      }
    })();
    data.then((response) => {
      if (response.data) {
        if (response.data.data) {
          setRecords(response.data.data);
          setRecordsCount(response.data.count);
          if (response.data.count == 0) {
            setNotify({
              isOpen: true,
              message: 'No Data Found',
              type: 'error',
            });
          }
        }
      } else console.error('Error While Fetching Data: ', response.error);
    });
  }

  const {
    TblContainer,
    TblHead,
    recordsAfterPagingAndSorting,
  } = useTableServerSide(records, recordsCount, headCells, getRecords);
  return (
    <>
      <PageTitle
        title="Dashboard"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="dashboardHeading"
      />
      <Grid item sm={12} className={styles.dashboardCards}>
        <CustomCards
          IconType={ShoppingCartIcon}
          cardLabel={'Total Sale (Rs.)'}
          value={aggregatedSum}
          textId="tyDbTextTotalSales"
          countId="tyDbCountTotalSales"
        />
        <CustomCards
          IconType={ShoppingBasketIcon}
          cardLabel={'Products Sold'}
          value={totalItemsSold}
          textId="tyDbTextTotalSold"
          countId="tyDbCountItemsSold"
        />
        <CustomCards
          IconType={ReceiptIcon}
          cardLabel={'No. of Sales'}
          value={noOfSale}
          elementId="tyDbTotalOrders"
        />
      </Grid>

      <Paper elevation={6} square className="paperTitleMargin">
        <Grid container>
          <Grid item sm={12} className={styles.dateFilter}>
            <Toolbar>
              <form noValidate className={styles.dateFilterBar}>
                <TextField
                  id="txtDashboardDateFrom"
                  label="Date From"
                  variant="outlined"
                  type="date"
                  onChange={(e) => setDateFrom(e.target.value)}
                  value={dateFrom}
                  className="searchInput"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  autoComplete="off"
                />
                <TextField
                  id="txtDashboardDateTo"
                  label="Date To"
                  variant="outlined"
                  type="date"
                  onChange={(e) => setDateTo(e.target.value)}
                  value={dateTo}
                  className="searchInput"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  autoComplete="off"
                />
                <Button
                  className="searchButton"
                  variant="contained"
                  id="btnDashboardSalesApply"
                  startIcon={<FilterListRoundedIcon />}
                  color="primary"
                  onClick={() => setIsClicked(true)}
                >
                  Apply
                </Button>
              </form>
            </Toolbar>
          </Grid>

          <CustomChart
            headLabel="Sales"
            elementId="tyDbChartSales"
            ChartType={Line}
            chartStyle={dataDailySaleSummary}
            isLoaded={isLoaded}
            options={{
              maintainAspectRatio: true,
              responsive: true,
            }}
          />

          <CustomChart
            headLabel="Sales By Method of Payment"
            elementId="tyDbChartSalesBySourceOfOrder"
            ChartType={Pie}
            chartStyle={dataSalesByPaymentMethod}
            isLoaded={isLoaded}
          />

          <CustomChart
            headLabel="Category Wise Sale"
            elementId="tyDbChartComparisonOfOrders"
            ChartType={Bar}
            chartStyle={dataCategoryWiseChart}
            isLoaded={isLoaded}
          />

          <MostSoldItemsViz
            width={500}
            height={250}
            from={dateFrom}
            to={dateTo}
            isLoaded={isLoaded}
            elementId="tyDbChartMostSoldItems"
          ></MostSoldItemsViz>
        </Grid>
      </Paper>
      <Paper elevation={6} square className="paperMargin">
        <Typography id="tyDbLastTenSales" variant="h5" gutterBottom>
          Last Ten Sales
        </Typography>
        <TblContainer>
          <TblHead />
          <TableBody id="dBListTbody">
            {recordsAfterPagingAndSorting().map((item, index) => (
              <TableRow key={item._id} id={`dBListTrow${index}`}>
                <TableCell align="center" id={`cashier${index}`}>
                  {item.createdBy.name}
                </TableCell>
                <TableCell align="center">
                  {item.customerRef ? item.customerRef.name : 'Walkin Customer'}
                </TableCell>
                <TableCell align="center" id={`salesDate${index}`}>
                  {item.clientUpdatedAt
                    ? ISOdateFormatter(item.clientUpdatedAt)
                    : null}
                </TableCell>
                <TableCell align="center" className={styles.complatedStyle}>
                  Completed
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}

export default Dashboard;
