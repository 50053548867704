import React from 'react';
import { Snackbar, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import MuiAlert from '@material-ui/lab/Alert';
const useStyles = makeStyles((theme) => ({
  root: {
    width: 'auto',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

function Notification(props) {
  const { notify, setNotify, notificationTime, ...other } = props;
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotify({
      ...notify,
      isOpen: false,
    });
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  return (
    <Snackbar
      className={classes.root}
      open={notify.isOpen}
      autoHideDuration={notificationTime ? notificationTime : 3000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      onClose={handleClose}
      id={notify.type}
      {...other}
    >
      <Alert onClose={handleClose} severity={notify.type}>
        {notify.message}
      </Alert>
    </Snackbar>
  );
}
Notification.propTypes = {
  notify: PropTypes.any,
  setNotify: PropTypes.any,
  notificationTime: PropTypes.number,
};
export default Notification;
