import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import Controls from '../../Utilities/Controls/Controls';
import { useForm, Form } from '../../Utilities/ReuseableCommponents/useForm';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import PageTitle from '../../Layout/PageTitle';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import { Paper } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import * as NayaPayService from './NayaPayActivationService';
import CircularLoader from '../../Utilities/ReuseableCommponents/CircularLoader';

const initialFValues = {
  _id: 0,
  merchantName: '',
  merchantID: '',
  branchID: '',
  apiKey: '',
  token: '',
};
let Responce = {
  data: null,
  error: null,
  loading: false,
};

let apiResponce = {
  data: null,
  error: null,
  loading: false,
  statusCode: 0,
};
function NayaPayActivation() {
  const [isLoaded, setIsLoaded] = React.useState(false);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ('name' in fieldValues)
      temp.name = fieldValues.name ? '' : 'This field is required.';
    setErrors({ ...temp });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === '');
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  } = useForm(initialFValues, true, validate);

  const addOrEdit = async (nayapay) => {
    if (nayapay._id === 0 && nayapay.merchantID && nayapay.merchantName) {
      try {
        Responce.data = null;
        Responce.error = null;
        Responce = await NayaPayService.insertNayaPayData(nayapay).then(
          (Responce) => {
            return Responce;
          }
        );

        if (Responce.data) {
          setNotify({
            isOpen: true,
            message: 'Submitted Successfully',
            type: 'success',
          });
        }

        if (Responce.error) {
          setNotify({
            isOpen: true,
            message: 'Unable To Process Request',
            type: 'error',
          });
        }
      } catch (e) {
        setNotify({
          isOpen: true,
          message: 'Unable To Process Request',
          type: 'error',
        });
      }
    }
    if (nayapay._id !== 0 && nayapay.merchantID && nayapay.merchantName) {
      try {
        apiResponce.data = null;
        apiResponce.error = null;
        apiResponce = await NayaPayService.updateNayaPayData(nayapay).then(
          (Responce) => {
            return Responce;
          }
        );
        if (apiResponce.data) {
          setNotify({
            isOpen: true,
            message: 'NayaPay Details Updated Successfully',
            type: 'success',
          });
        }
        if (apiResponce.error) {
          setNotify({
            isOpen: true,
            message: 'Unable To Process Request',
            type: 'error',
          });
        }
      } catch (e) {
        setNotify({
          isOpen: true,
          message: 'Unable To Process Request',
          type: 'error',
        });
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      addOrEdit(values, resetForm);
    }
  };

  useEffect(() => {
    async function fetchData() {
      setIsLoaded(true);
      apiResponce = await NayaPayService.getNayaPayData().then((Responce) => {
        setIsLoaded(false);
        return Responce;
      });
      if (apiResponce.data.count > 0) setValues(apiResponce.data.data[0]);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function handleGenerateToken() {
    const jwt = localStorage.getItem('Token');

    setValues({
      ...values,
      ['token']: jwt,
    });
  }

  return (
    <>
      <PageTitle
        title="Naya Pay Activation"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="nayapayActivationHeading"
      />
      <Paper elevation={6} square className="paperMargin">
        <CircularLoader isload={isLoaded} />
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid container>
              <Grid item xs={6} sm={6}>
                <Controls.Input
                  name="merchantName"
                  label="Merchant Name"
                  value={values.merchantName}
                  onChange={handleInputChange}
                  error={errors.merchantName}
                  id="txtmerchantName"
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <Controls.Input
                  name="merchantID"
                  label="Merchant ID"
                  value={values.merchantID}
                  onChange={handleInputChange}
                  error={errors.merchantID}
                  id="txtmerchantID"
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <Controls.Input
                  name="branchID"
                  label="Branch ID"
                  value={values.branchID}
                  onChange={handleInputChange}
                  error={errors.branchID}
                  id="txtbranchID"
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <Controls.Input
                  name="apiKey"
                  label="API Key"
                  value={values.apiKey}
                  onChange={handleInputChange}
                  error={errors.apiKey}
                  id="txtapiKey"
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Controls.Input
                  name="token"
                  label="Access Token"
                  multiline
                  row={3}
                  autoComplete="off"
                  value={values.token}
                  onChange={handleInputChange}
                  error={errors.token}
                  id="txttoken"
                />
              </Grid>
            </Grid>
            <Grid container className="actionButtonDiv">
              <Controls.Button
                onClick={handleGenerateToken}
                text="Generate Token"
                id="btnGenerateToken"
              />
              <Controls.Button
                type="submit"
                startIcon={<SaveIcon />}
                text={values._id === 0 ? 'Save' : 'Update'}
                id="btnSaveCus"
              />
            </Grid>
          </Grid>
        </Form>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}

export { NayaPayActivation as default };
