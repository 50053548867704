import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField } from '@material-ui/core';
const ExpenseModalBody = ({
  modalStyle,
  classes,
  handleExpenseSave,
  totalExpense,
}) => {
  const [expenseValue, setExpenseValue] = useState('');
  const ShiftData = JSON.parse(localStorage.getItem('_IdShift'));

  return (
    <>
      <div
        style={{
          ...modalStyle,
          width: '400px',
          height: '300px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        className={classes.paper4}
      >
        <h2 id="simple-modal-title">Bussines Expense</h2>
        <TextField
          type="input"
          label="Add Amount"
          variant="outlined"
          autoComplete="off"
          autoFocus
          style={{ marginTop: '20px', width: '275px' }}
          value={expenseValue}
          onChange={(event) => {
            setExpenseValue(event.target.value);
          }}
        ></TextField>
        <Button
          variant="contained"
          onClick={() => {
            handleExpenseSave({
              shiftNumber: ShiftData.shiftNumber,
              amount: expenseValue,
            });
            setExpenseValue(0);
          }}
          id={'btnAddExpense'}
          color="primary"
          style={{ marginTop: '20px', marginLeft: '10px' }}
        >
          Save
        </Button>
        <br />
        <TextField
          style={{ width: '350px', marginTop: '30px' }}
          label="Total Amount Spend In Current Shift"
          variant="outlined"
          autoComplete="off"
          value={totalExpense}
        ></TextField>
      </div>
    </>
  );
};
ExpenseModalBody.propTypes = {
  classes: PropTypes.any,
  modalStyle: PropTypes.any,
  totalExpense: PropTypes.any,
  handleExpenseSave: PropTypes.any,
};
export default ExpenseModalBody;
