import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Paper,
  TextField,
  Toolbar,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableHead,
  TableContainer,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PageHeader from '../../Layout/PageTitle';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import Controls from '../../Utilities/Controls/Controls';
import APIService from './InventoryService';
import { useHistory } from 'react-router-dom';
import * as VendorService from '../Vendor/VendorService';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import CircularLoader from '../../Utilities/ReuseableCommponents/CircularLoader';
import {
  getFormattedDateTime as ISOdateFormatter,
  dateTimeZone as timeZone,
} from '../../../Common/constants';
import ExportAndPrintReport from '../../Utilities/ReuseableCommponents/TableReport/ExportAndPrintReport';
import { evaluateValue } from '../../Utilities/ReuseableCommponents/TableReport/GenericTable';
import activityMonitoring from '../../../Common/functions';
const useStyles = makeStyles((theme) => ({
  PaperMargin: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  searchInput: {
    width: '15%',
    paddingRight: '5px',
  },
  textField: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: 350,
  },
  table: {
    '& thead th': {
      fontWeight: '500',
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
    },
    '& tbody td': {
      fontWeight: '300',
    },
    '& tbody tr:hover': {
      backgroundColor: '#f9f9f9',
      cursor: 'pointer',
    },
  },
}));
const headCells = [
  { id: 'billDate', label: 'Bill Date', align: 'left' },
  { id: 'createdAt', label: 'Created Date', align: 'left' },
  { id: 'billNumber', label: 'Bill No', align: 'left' },
  { id: 'vendor', label: 'Vendor', align: 'center' },
  { id: 'purchaseQty', label: 'Bill Items', align: 'center' },
  { id: 'totalAmount', label: 'Total Amount', align: 'center' },
];

export default function InventoryPurchase() {
  const classes = useStyles();
  const [isLoaded, setIsLoaded] = useState(false);
  const [rows, setRows] = useState([]);
  const [vendorsForDropdown, setVendorsForDropdown] = useState([]);
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [billNumber, setBillNumber] = useState('');
  const [vendor, setVendor] = useState('');
  const history = useHistory();
  const [recordsCount, setRecordsCount] = useState(0);

  const pages = [5, 10, 25, 50];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pages[0]);
  const [order, setOrder] = useState(1);
  const [orderBy, setOrderBy] = useState('');

  React.useEffect(() => {
    // code for vendor dropdown
    (async () => {
      return VendorService.getAllVendorsForDropDown().then((Responce) => {
        return Responce;
      });
    })().then((Response) => {
      if (Response) {
        if (Response.data) {
          //Map data array with Generic List Object with Properties id,text
          //Dropdown only accepts list of Object with Properties id,text
          var vendorList = Response.data.data.map((dataArrayRow) => ({
            _id: dataArrayRow._id,
            name: dataArrayRow.name,
          }));
          setVendorsForDropdown(vendorList);
          if (Response.data.count === 0) {
            setNotify({
              isOpen: true,
              message: 'No Vendor To Load',
              type: 'error',
            });
          }
        }
      }
    });
  }, []);

  // Fetch Latest Inventory
  function getRecords(pageParam, rowsPerPageParam, sortDirection) {
    setIsLoaded(true);
    let searchString = '';
    if (dateFrom) {
      searchString += `&billDateFrom=${dateFrom}T00:00:00${timeZone()}`;
    }
    if (dateTo) {
      searchString += `&billDateTo=${dateTo}T23:59:59${timeZone()}`;
    }
    if (billNumber) {
      searchString += `&billNumber=${billNumber}`;
    }
    if (vendor) {
      searchString += `&vendorRef=${vendor}`;
    }
    return APIService.FetchInventoryList(
      pageParam,
      rowsPerPageParam,
      sortDirection,
      'createdAt',
      'items,vendors',
      searchString
    ).then((Response) => {
      setIsLoaded(false);
      if (Response.data) {
        setRows(Response.data.data);

        setRecordsCount(Response.data.count);
        if (Response.data.count == 0) {
          setNotify({
            isOpen: true,
            message: 'No Data Found',
            type: 'error',
          });
        }
      } else console.error('Error While Fetching Data: ', Response.error);
    });
  }

  useEffect(() => {
    setOrder(1);
    setOrderBy('');
    getRecords(page, rowsPerPage, order);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, order, orderBy]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });

  function calculateTotal(row) {
    let total = 0;
    row.items.forEach((item) => {
      total += item.purchaseUnitPrice * (item.actualCount - item.expectedCount);
    });
    if (row.discount) total = total - (row.discount / 100) * total;
    row.totalAmount = total;
    return total;
  }
  useEffect(() => {
    activityMonitoring('PurchaseInventory');
  }, []);

  return (
    <>
      <PageHeader
        title="Purchase Inventory"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="purInvHeading"
      />

      <Paper elevation={6} square className={classes.PaperMargin}>
        <Toolbar style={{ padding: '0' }}>
          <TextField
            id="txtSearchPurRetInvDateFrom"
            label="Bill Date From"
            variant="outlined"
            type="date"
            value={dateFrom}
            onChange={(e) => setDateFrom(e.target.value)}
            className={classes.searchInput}
            InputLabelProps={{
              shrink: true,
            }}
            autoComplete="off"
          />
          <TextField
            id="txtSearchPurRetInvDateTo"
            label="Bill Date To"
            variant="outlined"
            type="date"
            value={dateTo}
            onChange={(e) => setDateTo(e.target.value)}
            className={classes.dateField}
            InputLabelProps={{
              shrink: true,
            }}
            autoComplete="off"
          />
          <TextField
            id="txtPurInvBillNumber"
            label="Bill Number"
            className={classes.searchInput}
            variant="outlined"
            value={billNumber}
            onChange={(e) => setBillNumber(e.target.value)}
            autoComplete="off"
          />
          <Controls.Select
            name="VendorRef_id"
            label="Vendor"
            className={classes.searchInput}
            style={{ width: 185 }}
            isNoneAllowed={false}
            value={vendor}
            onChange={(e) => setVendor(e.target.value)}
            options={vendorsForDropdown}
            id="ddPurInvVendor"
          />
          <Controls.Button
            text="Search"
            variant="contained"
            color="primary"
            id="btnPurInvSearch"
            onClick={() => {
              getRecords(0, 5, -1);
            }}
          />
          <Controls.Button
            text="Add New"
            variant="contained"
            color="primary"
            id="btnAddCategory"
            startIcon={<AddIcon />}
            style={{ left: 110 }}
            onClick={() => {
              history.push({
                pathname: '/PurchaseInventoryDetail',
              });
            }}
          />
        </Toolbar>
        <CircularLoader isload={isLoaded} />
        <TableContainer>
          <Table
            id="purInvListTable"
            className={classes.table}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                {headCells.map((element) => (
                  <TableCell key={element.id} align={element.align}>
                    {element.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody id="purInvListTbody">
              {rows.map((row, index) => (
                <TableRow
                  key={index}
                  id={'purInvTrow' + index}
                  onClick={() => {
                    row?.items?.forEach((it) => {
                      it.categoryRef = it?.itemRef?.categoriesRef[0] || {};
                    });
                    history.push({
                      pathname: '/PurchaseInventoryDetail',
                      state: {
                        item: row,
                      },
                    });
                  }}
                >
                  <TableCell id={'purInvDate' + index} align="left">
                    {ISOdateFormatter(row.billDate).slice(0, 11)}
                  </TableCell>
                  <TableCell id={'purInvDate' + index} align="left">
                    {ISOdateFormatter(row.createdAt)}
                  </TableCell>
                  <TableCell id={'purInvBillNo' + index} align="left">
                    {row.billNumber}
                  </TableCell>
                  <TableCell id={'purInvVendor' + index} align="center">
                    {row.vendorRef?.name}
                  </TableCell>
                  <TableCell id={'purInvQty' + index} align="center">
                    {row.items?.length}
                  </TableCell>
                  <TableCell id={'purInvTotalAmount' + index} align="center">
                    {calculateTotal(row)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          page={page}
          rowsPerPageOptions={pages}
          rowsPerPage={rowsPerPage}
          count={recordsCount}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        <ExportAndPrintReport
          TableTitle={'Purchase Inventory'}
          records={rows}
          headCells={headCells}
          cellData={[
            { name: 'billDate', type: 'onlyDate' },
            { name: 'createdAt', type: 'date' },
            { name: 'billNumber', type: 'string' },
            { name: 'vendorRef.name', type: 'string' },
            { name: 'items.length', type: 'integer' },
            { name: 'totalAmount', type: 'float' },
          ]}
          evaluateValue={evaluateValue}
        />
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
