import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import Controls from '../../Utilities/Controls/Controls';
import { Form } from '../../Utilities/ReuseableCommponents/useForm';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import PageTitle from '../../Layout/PageTitle';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import useTable from '../../Utilities/ReuseableCommponents/useTable';
import {
  InputAdornment,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
} from '@material-ui/core';
import { getFormattedDateTime as ISOdateFormatter } from '../../../Common/constants';

const headCells = [
  { id: 'name', label: 'Product' },
  { id: 'categoryName', label: 'Category' },
  { id: 'barcode', label: 'Barcode', align: 'center' },
  { id: 'quantitySold', label: 'Qty Sold', align: 'center' },
  { id: 'qtyReturned', label: 'Qty Returned', align: 'center' },
  { id: 'saleFinalPrice', label: 'Final Sale Price', align: 'right' },
  { id: 'discountAmount', label: 'Discount Price', align: 'right' },
];

export default function CustomerSaleItemsDetail() {
  const location = useLocation();
  const history = useHistory();
  const [records] = useState(() => {
    if (location.state)
      if (location.state.saleItem)
        if (!location.state.saleItem.itemsSold) return history.goBack();
        else return location.state.saleItem;
      else return history.goBack();
    else return history.goBack();
  });
  const [newItemsSold, setNewItemsSold] = useState([]);

  useEffect(() => {
    function fnnewItemSold(particularSale) {
      var newItemsSold = particularSale.itemsSold.map((particularItemSold) => {
        let particularItemSold_Id = particularItemSold.itemRef._id;
        var matchedReturnedItem = [];
        if (particularSale.itemsReturned.length > 0) {
          matchedReturnedItem = particularSale.itemsReturned.filter(
            (particularItemReturned) => {
              return (
                particularItemReturned.itemRef._id.toString() ==
                particularItemSold_Id.toString()
              );
            }
          );
        }
        var itemSaleInfo = {
          ...particularItemSold,
          qtyReturned:
            matchedReturnedItem.length > 0
              ? matchedReturnedItem[0].quantity
              : 0,
        };
        return itemSaleInfo;
      });

      return newItemsSold;
    }

    if (records.itemsSold.length > 0) {
      var newItemsSold = fnnewItemSold(records);
      setNewItemsSold(newItemsSold);
    }
  }, [records]);

  const [filterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(newItemsSold, headCells, filterFn);

  return (
    <>
      <PageTitle
        title={location.state.pageHeaderTitile}
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="custSaleItemsDetailsHeading"
      />
      <Paper elevation={6} square className="paperMargin">
        <Form>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <Controls.Input
                disabled
                name="shiftNumber"
                label="Shift Number"
                value={records.shiftNumber}
                InputProps={{
                  readOnly: true,
                  disabled: true,
                }}
                id="txtCustSaleItemsDetailsShiftNumber"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controls.Input
                disabled
                name="clientUpdatedAt"
                label="Date & Time"
                value={ISOdateFormatter(records.clientUpdatedAt)}
                InputProps={{
                  readOnly: true,
                  disabled: true,
                }}
                id="txtCustSaleItemsDetailsDateTime"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controls.Input
                disabled
                name="invoiceNumber"
                label="Invoice Number"
                value={records.invoiceNumber}
                InputProps={{
                  readOnly: true,
                  disabled: true,
                }}
                id="txtCustSaleItemsDetailsInvoiceNo"
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Controls.Input
                disabled
                name="itemsSold"
                label="Products Sold"
                value={records.itemsSold.length}
                InputProps={{
                  readOnly: true,
                  disabled: true,
                }}
                id="txtCustSaleItemsDetailsItemsSold"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controls.Input
                disabled
                name="itemsReturned"
                label="Products Returned"
                value={records.itemsReturned.length}
                InputProps={{
                  readOnly: true,
                  disabled: true,
                }}
                id="txtCustSaleItemsDetailsItemsReturned"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controls.Input
                disabled
                name="overallSaleDiscountAmount"
                label="Overall Discount"
                value={
                  records.overallSaleDiscountAmount
                    ? records.overallSaleDiscountAmount
                    : 0
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Rs.</InputAdornment>
                  ),
                  readOnly: true,
                  disabled: true,
                }}
                id="txtCustSaleItemsOverallDiscount"
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Controls.Input
                disabled
                name="saleAmountSumOfItems"
                label="Total Sale Amount"
                value={
                  records.saleAmountSumOfItems
                    ? records.saleAmountSumOfItems.toLocaleString('en')
                    : 0
                }
                InputProps={{
                  readOnly: true,
                  disabled: true,
                  startAdornment: (
                    <InputAdornment position="start">Rs.</InputAdornment>
                  ),
                }}
                id="txtCustSaleItemsTotalSaleAmount"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controls.Input
                disabled
                name="returnedAmountSumOfItems"
                label="Total Return Amount"
                value={
                  records.returnedAmountSumOfItems
                    ? records.returnedAmountSumOfItems.toLocaleString('en')
                    : 0
                }
                InputProps={{
                  readOnly: true,
                  disabled: true,
                  startAdornment: (
                    <InputAdornment position="start">Rs.</InputAdornment>
                  ),
                }}
                id="txtCustSaleItemsTotalReturnAmount"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controls.Input
                disabled
                name="netSaleTotal"
                label="Net Sale Amount"
                value={
                  records.saleAmountTotal
                    ? records.saleAmountTotal.toLocaleString('en')
                    : 0
                }
                InputProps={{
                  readOnly: true,
                  disabled: true,
                  startAdornment: (
                    <InputAdornment position="start">Rs.</InputAdornment>
                  ),
                }}
                id="txtCustSaleItemsNetSaleAmount"
              />
            </Grid>
          </Grid>
        </Form>
      </Paper>

      <Paper celevation={6} square className="paperMargin">
        <Toolbar>
          <h2>Products Sold</h2>
        </Toolbar>
        <TblContainer>
          <TblHead />
          <TableBody id="custSaleItemsDetailsListTbody">
            {recordsAfterPagingAndSorting().map((item, index) => (
              <TableRow
                key={item._id}
                id={`custSaleItemsDetailsListTrow${index}`}
              >
                <TableCell>{item.itemRef.name}</TableCell>
                <TableCell>{item.itemRef.categoriesRef[0].name}</TableCell>
                <TableCell align="center">{item.itemRef.barcode}</TableCell>
                <TableCell align="center">
                  {item.quantity.toLocaleString('en')}
                </TableCell>
                <TableCell align="center">
                  {item.qtyReturned ? item.qtyReturned.toLocaleString('en') : 0}
                </TableCell>
                <TableCell align="right">
                  Rs.{' '}
                  {item.saleFinalPrice
                    ? item.saleFinalPrice.toLocaleString('en')
                    : 0}
                </TableCell>
                <TableCell align="right">
                  Rs.{' '}
                  {item.discountAmount
                    ? item.discountAmount.toLocaleString('en')
                    : 0}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
        <Grid item xs={3}>
          <Controls.Button
            text="Back"
            color="default"
            onClick={history.goBack}
            id="btnBackSaleDetails"
          />
        </Grid>
      </Paper>
    </>
  );
}
