import React from 'react';
import { Button, Box } from '@material-ui/core';
import PropTypes from 'prop-types';

const CheckoutButton = ({ classes, kotList, checkoutButtonClick }) => {
  return (
    <Box m={2}>
      <Button
        variant="contained"
        className={classes.btnCheckout}
        size="large"
        onClick={() => {
          checkoutButtonClick();
        }}
        id="btnFoodCartCheckout"
        disabled={
          kotList.filter((item) => item.isKot === false).length > 0 ||
          kotList.length <= 0
        }
      >
        CHECKOUT
      </Button>
    </Box>
  );
};
CheckoutButton.propTypes = {
  classes: PropTypes.any,
  kotList: PropTypes.any,
  checkoutButtonClick: PropTypes.any,
};
export default CheckoutButton;
