import { Paper, Toolbar, TextField, Grid } from '@material-ui/core';
import Controls from '../../Utilities/Controls/Controls';
import SearchIcon from '@material-ui/icons/Search';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import React, { useEffect, useState } from 'react';
import PageTitle from '../../Layout/PageTitle';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import * as APICreditService from './CreditService';
import { getCreditLimit } from '../../../Common/constants';
import CircularLoader from '../../Utilities/ReuseableCommponents/CircularLoader';
import GenericTable from '../../Utilities/ReuseableCommponents/TableReport/GenericTable';
import activityMonitoring from '../../../Common/functions';

const headCells = [
  { id: 'createdAt', label: 'Date & Time', align: 'left', type: 'date' },
  {
    id: 'name',
    label: 'Customer Name',
    disableSorting: true,
    align: 'center',
  },
  {
    id: 'contactNumber',
    label: 'Contact No',
    disableSorting: true,
    align: 'center',
  },
  {
    id: 'creditLimit',
    label: 'Credit Limit',
    disableSorting: true,
    align: 'center',
  },
  {
    id: 'currentbalance',
    label: 'Current Balance',
    disableSorting: true,
    align: 'center',
  },
];

let apiResponseDto = {
  data: null,
  error: null,
  loading: false,
};

export default function Credit() {
  const [records, setRecords] = useState([]);
  const [recordsCount, setRecordsCount] = useState(0);
  const [customerName, setCustomerName] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [creditLimit, setCreditLimit] = useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const rowOnClick = {
    path: '/CreditSaleDetails',
    header: 'View/Edit Credit Sales',
    property: ['recordForEdit'],
  };

  function getRecords(page, rowsPerPage, sortDirection, sortColumnName) {
    let searchString = '';
    let data = (async () => {
      setIsLoading(true);
      if (customerName) {
        searchString += `&name=${customerName}`;
      }
      if (contactNumber) {
        searchString += `&contactNumber=${contactNumber}`;
      }
      if (creditLimit) {
        searchString += `&creditLimit=${creditLimit}`;
      }
      apiResponseDto = await APICreditService.getCredit(
        page,
        rowsPerPage,
        sortDirection,
        sortColumnName,
        searchString
      ).then((Response) => {
        return Response;
      });
      return apiResponseDto;
    })();
    data.then((Response) => {
      setIsLoading(false);
      if (Response.data) {
        if (Response.data.data) {
          setRecords(Response.data.data);
          setRecordsCount(Response.data.count);
          if (Response.data.count == 0) {
            setNotify({
              isOpen: true,
              message: 'No Data Found',
              type: 'error',
            });
          }
        }
      } else {
        setIsLoading(false);
        console.error('Error While Fetching Data: ', Response.error);
      }
    });
  }

  const handleSearch = (e) => {
    e.preventDefault();
    getRecords(0, 5, -1, 'createdAt');
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  useEffect(() => {
    activityMonitoring('CustomerReciveable');
  }, []);
  return (
    <>
      <PageTitle
        title="Credit Management"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="creditListHeading"
      />
      <Paper elevation={6} square className="PaperMargin">
        <Toolbar disableGutters>
          <form className="searchFormAlign" onSubmit={handleSearch}>
            <Grid container>
              <Grid item>
                <TextField
                  id="txtSearchCreditCustName"
                  label="Customer Name"
                  variant="outlined"
                  onChange={(e) => setCustomerName(e.target.value)}
                  value={customerName}
                  className="searchInput"
                  autoComplete="off"
                />
              </Grid>
              <Grid item>
                <TextField
                  id="txtSearchCreditContactNo"
                  label="Contact No"
                  variant="outlined"
                  onChange={(e) => setContactNumber(e.target.value)}
                  value={contactNumber}
                  className="searchInput"
                  autoComplete="off"
                />
              </Grid>
              <Grid item>
                <Controls.Select
                  name="creditLimit"
                  label="Credit Limit"
                  value={creditLimit}
                  options={getCreditLimit()}
                  className="searchInput"
                  onChange={(e) => {
                    setCreditLimit(e.target.value);
                  }}
                  id="ddCreditLimit"
                />
              </Grid>
              <Grid item>
                <Controls.Button
                  text="Search"
                  variant="contained"
                  color="primary"
                  id="btnSearchCredit"
                  startIcon={<SearchIcon />}
                  className="searchButton"
                  type="submit"
                />
              </Grid>
            </Grid>
          </form>
        </Toolbar>
        <CircularLoader isload={isLoading} />
        <GenericTable
          data={records}
          TableId={'creditSalesTable'}
          TableTitle={'Credit Sales Table'}
          cellData={[
            { name: 'createdAt', type: 'date' },
            { name: 'name', type: 'string' },
            { name: 'contactNumber', type: 'string' },
            { name: 'creditLimit', type: 'string' },
            { name: 'currentbalance', type: 'string' },
          ]}
          headCells={headCells}
          recordsCount={recordsCount}
          getRecordsFn={getRecords}
          rowDeleteDialog={setConfirmDialog}
          rowOnClick={rowOnClick}
          isLoading={isLoading}
        />
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
