import React, { useState, useEffect } from 'react';
import { Grid, Typography, Divider } from '@material-ui/core';
import CircularLoader from '../../../Utilities/ReuseableCommponents/CircularLoader';
import Notification from '../../../Utilities/ReuseableCommponents/Notification';
import * as SetupService from '../SetupService';
import Controls from '../../../Utilities/Controls/Controls';
import SaveIcon from '@material-ui/icons/Save';

let apiResponseDto = {
  data: [],
  error: null,
  loading: false,
  statusCode: 0,
};

export default function CancelOrderTimeTab() {
  const [valueCancelOrderTime, setValueCancelOrderTime] = useState([]);
  const [showData, setShowData] = useState(false);
  const [setUpDefaultData, setsetUpDefaultData] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  function handleActivationCancelOrderTime() {
    CancelOrderTimeActivation().then((Response) => {
      return Response;
    });
  }

  async function CancelOrderTimeActivation() {
    var payLoad = {
      name: 'Cancel Order Time',
      data: [
        {
          cancelOrderTime: '',
          status: 'active',
        },
      ],
    };
    var Response = await SetupService.insertSetup(payLoad).then(
      (ResponseInsert) => {
        return ResponseInsert;
      }
    );
    setValueCancelOrderTime([Response.data.data]);
    return Response;
  }
  function changeTFCancelOrderTime(cancelOrderTime, index) {
    let newdata = { ...valueCancelOrderTime };
    newdata[0].data[index].cancelOrderTime = cancelOrderTime;
    newdata[0].data[index].status = 'active';

    setValueCancelOrderTime([newdata[0]]);
  }

  useEffect(() => {
    setShowData(false);
    setIsLoaded(true);
    configureCancelOrderTimeTab();
  }, [setUpDefaultData]);

  function configureCancelOrderTimeTab() {
    let data = (async () => {
      apiResponseDto = await SetupService.getAllSetups().then((Response) => {
        setIsLoaded(false);
        return Response;
      });
      return apiResponseDto;
    })();
    data.then((Response) => {
      if (Response.data.count === 0) {
        setsetUpDefaultData(true);
        setNotify({
          isOpen: true,
          message: 'No Data Found',
          type: 'error',
        });
      } else {
        let CancelOrderTimeData = Response.data.data
          .filter((e) => e.name === 'Cancel Order Time')
          .map((e) => e);
        setValueCancelOrderTime(CancelOrderTimeData);

        setShowData(true);
      }
    });
  }
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  function payloadReady(payload) {
    return {
      name: payload.name,
      data: payload.data,
    };
  }
  async function UpdateSetup(obj) {
    return SetupService.updateSetup(payloadReady(obj[0]), obj[0]._id).then(
      (res) => {
        return res;
      }
    );
  }

  function handleSave() {
    //Receipt
    UpdateSetup(valueCancelOrderTime).then((Response) => {
      if (Response.data) {
        displayNotify('Data successfully updated', 'success');
      } else {
        displayNotify('Error while updating data', 'error');
      }
      return Response;
    });
  }

  function displayNotify(msg, displayType) {
    setNotify({
      isOpen: true,
      message: msg,
      type: displayType,
    });
  }

  {
    if (showData) {
      return (
        <>
          <CircularLoader isload={isLoaded} />
          <React.Fragment key={'setting'}>
            <Grid container>
              {valueCancelOrderTime.length !== 0 ? (
                valueCancelOrderTime.map((item) => (
                  <>
                    {item.data.map((subItem, index) => (
                      <>
                        <Grid item sm={8}>
                          <Controls.Input
                            id={'tf-cancelordertime' + index}
                            key={'tf-cancelordertime' + index}
                            value={subItem.cancelOrderTime}
                            variant="outlined"
                            style={{
                              margin: '5px',
                              width: '50%',
                            }}
                            onChange={(e) =>
                              changeTFCancelOrderTime(e.target.value, index)
                            }
                            label={'Cancel Order Time in Mins'}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controls.Button
                            text="Save Changes"
                            variant="contained"
                            color="primary"
                            id="btnProdSearch"
                            startIcon={<SaveIcon />}
                            onClick={handleSave}
                          />
                        </Grid>
                      </>
                    ))}
                  </>
                ))
              ) : (
                <React.Fragment key={'initialCancelOrderTime'}>
                  <Grid item xs={2}>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      key={'initialCancelOrderTimeTypo'}
                    >
                      {'Cancel Order Time'}
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Controls.Button
                      text="Activate Cancel Order Time"
                      variant="contained"
                      color="primary"
                      id="btnactivatecancelordertime"
                      onClick={handleActivationCancelOrderTime}
                    />
                  </Grid>
                </React.Fragment>
              )}

              <Grid item xs={12}>
                <Divider variant="middle" />
              </Grid>
            </Grid>
          </React.Fragment>
          <Notification notify={notify} setNotify={setNotify} />
        </>
      );
    } else {
      return (
        <>
          <CircularLoader isload={isLoaded} />
          <React.Fragment key={'initialReceiptText'}>
            <Grid item xs={2}>
              <Typography
                variant="subtitle2"
                gutterBottom
                key={'initialReceiptTextTypo'}
              >
                {'Cancel Order Time'}
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Controls.Button
                text="Activate Cancel Order Time"
                variant="contained"
                color="primary"
                id="btnactivatecancelordertime"
                onClick={handleActivationCancelOrderTime}
              />
            </Grid>
          </React.Fragment>
          <Notification notify={notify} setNotify={setNotify} />
        </>
      );
    }
  }
}
