import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Toolbar,
  TextField,
  LinearProgress,
  Modal,
  Grid,
} from '@material-ui/core';
import Controls from '../../../Utilities/Controls/Controls';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import Notification from '../../../Utilities/ReuseableCommponents/Notification';
import ConfirmDialog from '../../../Utilities/ReuseableCommponents/ConfirmDialog';
import * as CustomerService from '../CustomerService';
import { useHistory } from 'react-router-dom';
import CSVTable from '@material-ui/core/Table';
import CSVTableBody from '@material-ui/core/TableBody';
import CSVTableCell from '@material-ui/core/TableCell';
import CSVTableContainer from '@material-ui/core/TableContainer';
import CSVTableHead from '@material-ui/core/TableHead';
import CSVTableRow from '@material-ui/core/TableRow';
import CSVExportIcon from '../../../../Assets/Images/exportcsv.png';
import sampleFile from '../Assets/CustomerTestFile.csv';
import PublishIcon from '@material-ui/icons/Publish';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {
  getFormattedDateTime,
  GetCompanyType,
} from '../../../../Common/constants';
import CircularLoader from '../../../Utilities/ReuseableCommponents/CircularLoader';
import PageTitle from '../../../Layout/PageTitle';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import { getAllAreaes } from '../../Area/AreaService';
import styles from './CustomerComponent.module.css';
import GenericTable from '../../../Utilities/ReuseableCommponents/TableReport/GenericTable';
import activityMonitoring from '../../../../Common/functions';

const CustomerComponent = ({ openInPos }) => {
  const history = useHistory();
  const recordForEdit = null;
  const [records, setRecords] = useState([]);
  const [recordsCount, setRecordsCount] = useState(0);
  const [name, setName] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [openBulkUploadModal, setOpenBulkUploadModal] = useState(false);
  const [cSVData, setCSVData] = useState([]);
  const [showCSVTable, setShowCSVTable] = useState(false);
  const [isCSVLoaded, setIsCSVLoaded] = useState(false);
  const [csvFileStore, setcsvFileStore] = useState();
  const [showProgressbar, setShowProgressbar] = useState(false);
  const [isBulkDataComplete, setIsBulkDataComplete] = useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const companyType = GetCompanyType();
  const headCells = [
    { id: 'customerId', label: 'Customer ID', align: 'left' },
    { id: 'name', label: 'Name', align: 'left' },
    { id: 'contactNumber', label: 'Contact Number', align: 'left' },
    { id: 'city', label: 'City', align: 'left' },
    { id: 'email', label: 'Email', align: 'left' },
    { id: 'createdAt', label: 'Date', align: 'left' },
    { id: 'actions', label: 'Actions', disableSorting: true, align: 'right' },
  ];
  const rowOnClick = {
    path: '/CustomerDetails',
    header: 'View/Edit Customer',
    property: ['recordForEdit'],
  };
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  let apiResponseDto = {
    data: [],
    error: null,
    loading: false,
    statusCode: 0,
  };
  function getModalStyle() {
    const top = 50;
    const left = 50;
    const height = 65;
    const width = 70;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      height: `${height}%`,
      width: `${width}%`,
      textAlign: `center`,
    };
  }

  function CSVDataCancel() {
    setShowCSVTable(false);
    setIsCSVLoaded(false);
  }
  const handleBulkUploadModalClose = () => {
    setOpenBulkUploadModal(false);
  };
  const handleBulkUploadModalOpen = () => {
    setOpenBulkUploadModal(true);
  };
  const onDelete = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    try {
      apiResponseDto.data = null;
      apiResponseDto.error = null;
      apiResponseDto = await CustomerService.deleteCustomer(id).then(
        (Response) => {
          return Response;
        }
      );
      if (apiResponseDto.data) {
        getRecords(0, 10, 1, 'name');
        setNotify({
          isOpen: true,
          message: 'Deleted Successfully',
          type: 'success',
        });
      }
      if (apiResponseDto.error) {
        setNotify({
          isOpen: true,
          message: 'Unable To Process Request',
          type: 'error',
        });
      }
    } catch (e) {
      setNotify({
        isOpen: true,
        message: 'Unable To Process Request',
        type: 'error',
      });
    }
  };

  function FileuploadHandler(e) {
    setShowProgressbar(true);
    let files = e.target.files[0];
    setcsvFileStore(files);
    CustomerService.FileuploadHandler(files, 'validate')
      .then((res) => {
        if (res.status === 200) {
          setShowCSVTable(true);
          setIsCSVLoaded(true);
          setIsBulkDataComplete(true);
          setCSVData(res.data.data);

          res.data.data.forEach((row) => {
            for (var nameRow in row) {
              if (
                // eslint-disable-next-line no-prototype-builtins
                row.hasOwnProperty(nameRow) &&
                typeof row[nameRow] === 'boolean'
              ) {
                if (
                  row[nameRow] ||
                  nameRow === 'CustomerType' ||
                  nameRow === 'status' ||
                  nameRow === 'customerID'
                ) {
                  // do nothing
                } else {
                  setIsBulkDataComplete(false);
                  break;
                }
              }
            }
          });
        } else if (res.status !== 200) {
          setShowCSVTable(false);
          setIsCSVLoaded(false);
        }
      })
      .catch((error) => {
        console.error(error.response);
        setOpenBulkUploadModal(false);
        setNotify({
          isOpen: true,
          message: error.response.data.data,
          type: 'error',
        });
      });
    setShowProgressbar(false);
  }
  function getArea(areasList, cityId) {
    let areaArr = [];
    if (areasList != undefined) {
      for (const innerobj of areasList) {
        if (innerobj.cityRef[0] != null) {
          if (innerobj.cityRef[0]._id === cityId) {
            areaArr.push(innerobj);
          }
        }
      }
    }

    return areaArr;
  }
  function getRecords(page, rowsPerPage, sortDirection, sortColumnName) {
    let searchString = '';
    setIsLoading(true);
    let data = (async () => {
      if (name || contactNumber) {
        searchString += `&name=${name}&contactNumber=${contactNumber}`;
      }
      apiResponseDto = await CustomerService.getAllCustomers(
        page,
        rowsPerPage,
        sortDirection,
        sortColumnName,
        searchString
      );
      return apiResponseDto;
    })();
    data.then(async (Response) => {
      if (Response.data) {
        let CustData = Response.data.data;
        let CustCount = Response.data.count;
        Response.data.data.map((element) => {
          element.city = getCityName(element);
          element.createdAt = getFormattedDateTime(element.createdAt);
        });
        if (companyType == 'food') {
          let areaOptionsArr = await getAreaValues();

          CustData.forEach(function (item) {
            if (item.addresses != undefined) {
              item.addresses.forEach(function (add) {
                add.areaOptions = getArea(
                  areaOptionsArr,
                  (add.cityRef && add.cityRef._id) || ''
                );
              });
            }
          });
        }
        setIsLoading(false);
        setRecords(CustData);
        setRecordsCount(CustCount);
      } else {
        setIsLoading(false);
        console.error('Error While Fetching Data: ', Response.error);
      }
    });
  }
  function CSVDataUpload() {
    CustomerService.FileuploadHandler(csvFileStore, 'upload')
      .then((res) => {
        if (res.status === 200) {
          setShowCSVTable(false);
          setIsCSVLoaded(false);
          handleBulkUploadModalClose();
          setNotify({
            isOpen: true,
            message: 'Data has been uploaded Successfully!',
            type: 'success',
          });
        }
        if (res.status !== 200) {
          setNotify({
            isOpen: true,
            message: 'unable to process please verify your file!',
            type: 'error',
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const [modalStyle] = useState(getModalStyle);

  const handleSearch = (e) => {
    e.preventDefault();
    getRecords(0, 5, -1, '');
  };
  function displayBulkUploadButton() {
    if (isBulkDataComplete) {
      return (
        <Button
          variant="contained"
          color="primary"
          className={styles.bulkUploadButton}
          onClick={CSVDataUpload}
          id="btnBulkUploadSave"
        >
          Save
        </Button>
      );
    } else {
      return <></>;
    }
  }
  function getCellColor(value, isColor = true) {
    let colorName;

    if (value) {
      colorName = isColor ? 'green' : '';
    } else {
      colorName = 'red';
    }
    return colorName;
  }
  function getValueOrMsg(value) {
    let returnText = 'cannot be empty';

    if (value) {
      returnText = value;
    }
    return returnText;
  }
  function getCityName(item) {
    let cityname = '';

    if (item.addresses == undefined || item.addresses.length == 0) {
      cityname = item.city;
    } else if (item.addresses[0].cityRef == undefined) {
      cityname = item.addresses[0].city;
    } else {
      cityname = item.addresses[0].cityRef.name;
    }

    return cityname;
  }
  function CsvLoaded() {
    let returnString;
    if (isCSVLoaded) {
      returnString = (
        <div className={styles.rootBulkUpload}>
          <CSVTableContainer className={styles.tableContent}>
            <CSVTable
              id="bulkUploadTable"
              className="table"
              aria-label="simple table"
            >
              <CSVTableHead>
                <CSVTableRow>
                  <CSVTableCell>Name</CSVTableCell>
                  <CSVTableCell>Contact Number</CSVTableCell>
                  <CSVTableCell>City</CSVTableCell>
                  <CSVTableCell>Email</CSVTableCell>
                  <CSVTableCell>Billing Address</CSVTableCell>
                  <CSVTableCell>Shipping Address</CSVTableCell>
                  <CSVTableCell>Nearest LandMark</CSVTableCell>
                </CSVTableRow>
              </CSVTableHead>
              <CSVTableBody id="bulkUploadTbody">
                {cSVData.map((row, index) => (
                  <CSVTableRow key={index} id={'bulkUploadTrow' + index}>
                    <CSVTableCell style={{ color: row.name ? '' : 'red' }}>
                      {getValueOrMsg(row.name)}
                    </CSVTableCell>
                    {!row.isNewCustomer ? (
                      <CSVTableCell
                        style={{ color: getCellColor(row.contactNumber, true) }}
                      >
                        {getValueOrMsg(row.contactNumber)}
                      </CSVTableCell>
                    ) : (
                      <CSVTableCell
                        style={{ color: getCellColor(row.contactNumber, true) }}
                      >
                        {getValueOrMsg(row.contactNumber)}
                        **
                      </CSVTableCell>
                    )}
                    <CSVTableCell
                      style={{ color: getCellColor(row.city, false) }}
                    >
                      {getValueOrMsg(row.city)}
                    </CSVTableCell>
                    <CSVTableCell
                      style={{ color: getCellColor(row.email, false) }}
                    >
                      {getValueOrMsg(row.email)}
                    </CSVTableCell>
                    <CSVTableCell
                      style={{ color: getCellColor(row.billingAddress, false) }}
                    >
                      {getValueOrMsg(row.billingAddress)}
                    </CSVTableCell>
                    <CSVTableCell
                      style={{
                        color: getCellColor(row.shippingAddress, false),
                      }}
                    >
                      {getValueOrMsg(row.shippingAddress)}
                    </CSVTableCell>
                    <CSVTableCell
                      style={{
                        color: getCellColor(row.nearestLandMark, false),
                      }}
                    >
                      {getValueOrMsg(row.nearestLandMark)}
                    </CSVTableCell>
                  </CSVTableRow>
                ))}
              </CSVTableBody>
            </CSVTable>
          </CSVTableContainer>
          <div
            style={{
              bottom: '15px',
              position: 'fixed',
              width: '94%',
              display: 'flex',
            }}
          >
            <Button
              variant="contained"
              style={{
                marginRight: 'auto',
                marginLeft: '5px',
                height: '50px',
                width: '150px',
              }}
              onClick={CSVDataCancel}
              id="btnBulkUploadCancel"
            >
              Cancel
            </Button>
            {displayBulkUploadButton()}
          </div>
        </div>
      );
    } else {
      returnString = <></>;
    }
    return returnString;
  }
  const body2 = (
    <div style={modalStyle} className="paper2">
      <h2 id="simple-modal-title" className="modalTitle">
        Upload Customers List
      </h2>
      <IconButton
        className="modalCloseButton"
        color="inherit"
        aria-label="menu"
        onClick={handleBulkUploadModalClose}
        id="btnCloseModal"
      >
        <ClearIcon />
      </IconButton>
      {cSVData.filter((csvItem) => csvItem.isNewCustomer).length > 0 ? (
        <Typography
          variant="h6"
          gutterBottom
          style={{ color: 'green', textAlign: 'left' }}
        >
          {cSVData.filter((csvItem) => csvItem.isNewCustomer).length} Out Of{' '}
          {cSVData.length} Customer Records Are New
        </Typography>
      ) : (
        <></>
      )}

      {showCSVTable ? (
        CsvLoaded()
      ) : (
        <div>
          <div>
            <label className="custom-file-upload">
              <input
                id="btnBulkUploadChFile"
                type="file"
                name="Choose File"
                onChange={(e) => FileuploadHandler(e)}
              />
              <span style={{ right: '2px', top: '5px', position: 'relative' }}>
                <CloudUploadIcon />
              </span>

              <span>Choose File</span>
            </label>
          </div>

          <div>
            <a
              href={sampleFile}
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              <img variant="square" alt="CSV Export Icon" src={CSVExportIcon} />
            </a>
          </div>

          <div style={{ textAlign: 'left' }}>
            <Typography
              variant="body1"
              gutterBottom
              style={{ textDecorationLine: 'underline' }}
            >
              Note:
            </Typography>
            <Typography variant="body2" gutterBottom>
              1. Download the sample .CSV file from the icon above.
            </Typography>
            <Typography variant="body2" gutterBottom>
              2. Customer Name, Contact Number, Email, Billing address, Shipping
              address, Nearest LandMark & city are compulsory.
            </Typography>
            <Typography variant="body2" gutterBottom>
              3. Then upload the file from the above `Choose File`.
            </Typography>
          </div>
        </div>
      )}
      {showProgressbar ? <LinearProgress id="progressProdUpload" /> : null}
    </div>
  );
  async function getAreaValues() {
    var areaList = [];
    await getAllAreaes().then((response) => {
      if (response.data) {
        //Map data array with Generic List Object with Properties id,text
        //Dropdown only accepts list of Object with Properties id,text
        var result = response.data.data.map((dataArrayRow) => ({
          _id: dataArrayRow._id,
          name: dataArrayRow.name,
          cityRef: dataArrayRow.cityRef,
        }));

        result.forEach((area) => {
          let areaForDD = {
            _id: area._id,
            name: area.name,
            cityRef: [area.cityRef],
          };
          areaList.push(areaForDD);
        });
      }
    });

    return areaList;
  }

  useEffect(() => {
    activityMonitoring('Customers');
  }, []);
  return (
    <>
      <Modal
        open={openBulkUploadModal}
        onClose={handleBulkUploadModalClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body2}
      </Modal>
      <PageTitle
        title="Customers"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="customerListHeading"
      />
      <Paper elevation={6} square className="paperMargin">
        <Toolbar disableGutters>
          <form onSubmit={handleSearch} className="searchFormAlign">
            <Grid container className="listingPageControls">
              <Grid item className="flexStart">
                <TextField
                  id="txtSearchCustomerName"
                  label="Name"
                  className="searchInput"
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  autoComplete="off"
                />
                <TextField
                  id="txtSearchCustomerNumber"
                  label="Contact Number"
                  className="searchInput"
                  variant="outlined"
                  value={contactNumber}
                  onChange={(e) => setContactNumber(e.target.value)}
                  autoComplete="off"
                />
                <Controls.Button
                  text="Search"
                  variant="contained"
                  color="primary"
                  id="btnCustSearch"
                  className="searchButton"
                  startIcon={<SearchIcon />}
                  type="submit"
                />
              </Grid>
              <Grid item className="flexEnd">
                <Controls.Button
                  text="Add New"
                  variant="contained"
                  color="primary"
                  id="btnAddCustomer"
                  startIcon={<AddIcon />}
                  className="newButton"
                  onClick={() => {
                    history.push({
                      pathname: '/CustomerDetails',
                      state: {
                        pageHeaderTitile: 'Add New Customer',
                        recordForEdit,
                      },
                    });
                  }}
                />
                {!openInPos && (
                  <Controls.Button
                    text="Bulk Upload"
                    variant="contained"
                    color="primary"
                    id="btnUploadCustomer"
                    startIcon={<PublishIcon />}
                    className="newButton"
                    onClick={() => {
                      handleBulkUploadModalOpen();
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </form>
        </Toolbar>
        <CircularLoader isload={isLoading} />
        <GenericTable
          data={records}
          TableId={'branchTable'}
          TableTitle={'Customer Report'}
          cellData={[
            { name: 'customerId', type: 'string' },
            { name: 'name', type: 'string' },
            { name: 'contactNumber', type: 'string' },
            { name: 'city', type: 'string' },
            { name: 'email', type: 'string' },
            { name: 'createdAt', type: 'date' },
            { name: 'remove', type: 'remove' },
          ]}
          headCells={headCells}
          recordsCount={recordsCount}
          getRecordsFn={getRecords}
          rowDeleteDialog={setConfirmDialog}
          rowDeletefn={onDelete}
          rowOnClick={rowOnClick}
          isLoading={isLoading}
        />
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        id_Yes="btnDelCustYes"
        id_No="btnDelCustNo"
      />
    </>
  );
};

CustomerComponent.propTypes = {
  openInPos: PropTypes.any,
};

export default CustomerComponent;
