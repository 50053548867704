import React, { useEffect, useState } from 'react';
import PageTitle from '../../Layout/PageTitle';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import { Paper, Toolbar, TextField, Grid } from '@material-ui/core';
import Controls from '../../Utilities/Controls/Controls';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import * as BranchService from './BranchService';
import { useHistory } from 'react-router-dom';
import CircularLoader from '../../Utilities/ReuseableCommponents/CircularLoader';
import GenericTable from '../../Utilities/ReuseableCommponents/TableReport/GenericTable';
import useStyles from '../../../Style/AppStylesAdmin';
import activityMonitoring from '../../../Common/functions';

const headCells = [
  { id: 'name', label: 'Branch Name', align: 'left' },
  { id: 'cityRef_name', label: 'City Name', align: 'left' },
  { id: 'status', label: 'Status', align: 'right', type: 'status' },
  {
    id: 'actions',
    label: 'Actions',
    disableSorting: true,
    align: 'right',
    type: 'remove',
  },
];

let apiResponseDto = {
  data: [],
  error: null,
  loading: false,
  statusCode: 0,
};

export default function Branches() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState(false);
  const history = useHistory();
  const recordForEdit = null;
  const [records, setRecords] = useState([]);
  const [recordsCount, setRecordsCount] = useState(0);
  const [name, setName] = useState('');
  const rowOnClick = {
    path: '/BranchDetails',
    header: 'View/Edit Branches',
    property: ['recordForEdit'],
  };
  function getRecords(page, rowsPerPage, sortDirection, sortColumnName) {
    let searchString = '';
    let data = (async () => {
      setIsLoading(true);
      if (name) {
        searchString += `&name=${name}`;
      }
      apiResponseDto = await BranchService.getAllBranches(
        page,
        rowsPerPage,
        sortDirection,
        sortColumnName,
        searchString
      ).then((Response) => {
        return Response;
      });
      return apiResponseDto;
    })();
    data.then((Response) => {
      setIsLoading(false);
      if (Response.data) {
        Response.data.data.map((element) => {
          element.cityRef_name = element.cityRef.name;
          element.citiesRef_id = element.cityRef._id;
        });
        setRecords(Response.data.data);
        setRecordsCount(Response.data.count);
        if (Response.data.count == 0) {
          setNotify({
            isOpen: true,
            message: 'No Data Found',
            type: 'error',
          });
        }
      } else console.error('Error While Fetching Data: ', Response.error);
    });
  }

  const handleStatusChange = async (event, index) => {
    let cloneDatatableRecords = records;
    let id = cloneDatatableRecords[index]._id;
    let checkedStatus = event.target.checked;

    if (id) {
      try {
        let BranchStatusUpdate = {
          status: checkedStatus ? 'active' : 'disabled',
        };
        apiResponseDto.data = null;
        apiResponseDto.error = null;
        apiResponseDto = await BranchService.patchBranch(
          id,
          BranchStatusUpdate
        ).then((Responce) => {
          return Responce;
        });
        if (apiResponseDto.data) {
          let newStatusChangedRecord = {
            ...cloneDatatableRecords[index],
            status: checkedStatus ? 'active' : 'disabled',
          };
          cloneDatatableRecords[index] = newStatusChangedRecord;
          setRecords(cloneDatatableRecords);
          setNotify({
            isOpen: true,
            message: 'Branch Status Updated Successfully',
            type: 'success',
            notificationTime: 15000,
          });
        }
        if (apiResponseDto.error) {
          setNotify({
            isOpen: true,
            message: 'Unable To Process Request',
            type: 'error',
          });
        }
      } catch (e) {
        setNotify({
          isOpen: true,
          message: 'Unable To Process Request',
          type: 'error',
        });
      }
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    getRecords(0, 5, -1, '');
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const onDelete = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    try {
      apiResponseDto.data = null;
      apiResponseDto.error = null;
      apiResponseDto = await BranchService.deleteBranch(id).then((Response) => {
        return Response;
      });

      if (apiResponseDto.data) {
        getRecords(0, 10, 1, 'name');
        setNotify({
          isOpen: true,
          message: 'Deleted Successfully',
          type: 'success',
        });
      }
      if (apiResponseDto.error) {
        if (apiResponseDto.statusCode === 403) {
          setNotify({
            isOpen: true,
            message:
              'Unable To Delete Branch : Areas are mapped with this branch',
            type: 'error',
          });
        } else {
          setNotify({
            isOpen: true,
            message: 'Unable To Process Request',
            type: 'error',
          });
        }
      }
    } catch (e) {
      setNotify({
        isOpen: true,
        message: 'Unable To Process Request',
        type: 'error',
      });
    }
  };

  useEffect(() => {
    activityMonitoring('Branches');
  }, []);
  return (
    <>
      <PageTitle
        title="Branches"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="BranchListHeading"
      />
      <Paper elevation={6} square className={classes.PaperMargin}>
        <Toolbar disableGutters>
          <form onSubmit={handleSearch} style={{ width: '100%' }}>
            <Grid container>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  id="txtSearchCat"
                  label="Branch Name"
                  className={classes.searchInput}
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12} sm={3} md={2}>
                <Controls.Button
                  text="Search"
                  variant="contained"
                  color="primary"
                  id="btnCatSearch"
                  className={classes.searchButton}
                  startIcon={<SearchIcon />}
                  type="submit"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Controls.Button
                  text="Add New"
                  variant="contained"
                  color="primary"
                  id="btnAddBranch"
                  startIcon={<AddIcon />}
                  className={classes.newButton}
                  onClick={() => {
                    history.push({
                      pathname: '/BranchDetails',
                      state: {
                        pageHeaderTitile: 'Add New Branch',
                        recordForEdit,
                      },
                    });
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </Toolbar>
        <CircularLoader isload={isLoading} />
        <GenericTable
          data={records}
          TableId={'branchTable'}
          TableTitle={'Branch Table'}
          cellData={[
            { name: 'name', type: 'string' },
            { name: 'cityRef_name', type: 'string' },
            { name: 'status', type: 'status' },
            { name: 'remove', type: 'remove' },
          ]}
          headCells={headCells}
          recordsCount={recordsCount}
          getRecordsFn={getRecords}
          rowDeleteDialog={setConfirmDialog}
          statusChangeFn={handleStatusChange}
          rowDeletefn={onDelete}
          rowOnClick={rowOnClick}
          isLoading={isLoading}
        />
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        id_Yes="delCat"
        id_No="dontDelCat"
      />
    </>
  );
}
