import React from 'react';
import { Button, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import PanToolIcon from '@material-ui/icons/PanTool';

const KotButton = ({ classes, totalItems, sendKot, isOdms }) => {
  return (
    <>
      <Box m={2}>{ShowKotButton(sendKot, isOdms, totalItems, classes)}</Box>
    </>
  );
};
KotButton.propTypes = {
  classes: PropTypes.any,
  totalItems: PropTypes.any,
  sendKot: PropTypes.any,
  isOdms: PropTypes.any,
};

function ShowKotButton(sendKot, isOdms, totalItems, classes) {
  if (!isOdms) {
    return (
      <Button
        onClick={() => sendKot()}
        variant="contained"
        aria-label="contained primary button group"
        color="primary"
        id="btnFoodKOT"
        disabled={totalItems == 0 ? true : false}
        className={classes.btnCheckout}
      >
        <PanToolIcon />
        &nbsp;&nbsp;KOT
      </Button>
    );
  }
}
export default KotButton;
