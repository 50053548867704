import authHeader from '../../../Services/auth-header';
import { callAPI } from '../../../Services/ApiCaller';
import { API_VERSION } from '../../../Common/constants';

export const orderModes = () => [
  { _id: 'posportal', name: 'Walk-In', nameValue: 'posportal' },
  { _id: 'api', name: 'API', nameValue: 'api' },
  { _id: 'odms', name: 'Call-Center', nameValue: 'odms' },
];

export const orderStatuses = () => [
  { _id: 'booked', name: 'Booked', nameValue: 'booked' },
  { _id: 'inprocess', name: 'In Process', nameValue: 'inprocess' },
  { _id: 'dispatched', name: 'Dispatched', nameValue: 'dispatched' },
  { _id: 'cancelled', name: 'Cancelled', nameValue: 'cancelled' },
  { _id: 'completed', name: 'Completed', nameValue: 'completed' },
];

export async function getAllOrders(
  page,
  rowsPerPage,
  sortDirection,
  sortColumnName,
  searchString
) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  let rowsFrom = page * rowsPerPage;

  let sortOrderdir = sortDirection === 'asc' ? 1 : -1;

  if (!sortColumnName) {
    sortColumnName = 'clientUpdatedAt';
  }

  return callAPI(
    `api/${API_VERSION}/orderManagement`,
    null,
    headerObject,
    'GET',
    `?populate=customerRef,dealRef&$orderBy=${sortColumnName}&$orderDir=${sortOrderdir}&$skip=${rowsFrom}&$top=${rowsPerPage}${searchString}`
  ).then((Responce) => {
    return Responce;
  });
}

export async function createOrder(payLoad) {
  const header = authHeader();
  const headers = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  return callAPI(
    `api/${API_VERSION}/orderManagement`,
    payLoad,
    headers,
    'POST',
    ''
  ).then(async (Responce) => {
    return Responce;
  });
}
