import { Paper, Toolbar, TextField } from '@material-ui/core';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import React, { useState, useEffect } from 'react';
import PageTitle from '../../Layout/PageTitle';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import * as productMixService from './ProductMixService';
import * as categoryService from '../Category/CategoryService';
import * as productService from '../Products/ProductService';
import SearchIcon from '@material-ui/icons/Search';
import Controls from '../../Utilities/Controls/Controls';
import CircularLoader from '../../Utilities/ReuseableCommponents/CircularLoader';
import GenericTable from '../../Utilities/ReuseableCommponents/TableReport/GenericTable';
import { dateTimeZone as timeZone } from '../../../Common/constants';

const headCells = [
  {
    id: 'product',
    label: 'Product',
    type: 'string',
  },
  { id: 'category', label: 'Category', type: 'string' },
  { id: 'barcode', label: 'Barcode', type: 'string' },
  { id: 'sku', label: 'SKU', type: 'string' },
  {
    id: 'nonDealQuantity',
    label: 'Non-Deal Quantity',
    type: 'float',
  },
  {
    id: 'dealQuantity',
    label: 'Deal Quantity',
    type: 'float',
  },
  {
    id: 'totalQuantity',
    label: 'Total Quantity',
    type: 'float',
  },
];

export default function ProductMix() {
  const [records, setRecords] = useState([]);
  const [footerRecords, setFooterRecords] = useState({});
  const [recordsCount, setRecordsCount] = useState(0);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState({});
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({});

  function getFilteredRecords(e) {
    e.preventDefault();
    getRecords(0, 5);
  }

  async function getRecords(page, rowsPerPage) {
    let searchString = '';
    setIsLoading(true);
    if (dateFrom) {
      searchString += `&startDate=${dateFrom}T00:00:00${timeZone()}`;
    }
    if (dateTo) {
      searchString += `&endDate=${dateTo}T23:59:59${timeZone()}`;
    }
    if (category && Object.getOwnPropertyNames(category).length !== 0) {
      searchString += `&categoryRef=${category._id}`;
    }
    if (product && Object.getOwnPropertyNames(product).length !== 0) {
      searchString += `&itemRef=${product._id}`;
    }
    productMixService
      .getAllProductMix(page, rowsPerPage, searchString)
      .then((Response) => {
        setIsLoading(false);
        if (Response.data && Response.data.data) {
          setRecords(Response.data.data);
          setFooterRecords(Response.data.total);
          setRecordsCount(Response.data.count);
          if (Response.data.count == 0) {
            setNotify({
              isOpen: true,
              message: 'No Data Found',
              type: 'error',
            });
          }
        } else {
          setIsLoading(false);
          console.error('Error While Fetching Data: ', Response.error);
        }
      });
  }

  useEffect(() => {
    categoryService.getAllCategoriesForDropDown().then((Response) => {
      if (Response.data) {
        //Map data array with Generic List Object with Properties id,text
        //Dropdown only accepts list of Object with Properties id,text
        var result = Response.data.data.map((dataArrayRow) => ({
          _id: dataArrayRow._id,
          name: dataArrayRow.name,
        }));
        setCategories(result);
        if (Response.data.count === 0) {
          setNotify({
            isOpen: true,
            message: 'No Category Found',
            type: 'error',
          });
        }
      } else {
        console.error('Unable To Get Records');
      }
    });
    productService.getAllProducts().then((Response) => {
      if (Response.data) {
        //Map data array with Generic List Object with Properties id,text
        //Dropdown only accepts list of Object with Properties id,text
        var result = Response.data.data.map((dataArrayRow) => ({
          _id: dataArrayRow._id,
          name: dataArrayRow.name,
        }));
        setProducts(result);
        if (Response.data.count === 0) {
          setNotify({
            isOpen: true,
            message: 'No Products Found',
            type: 'error',
          });
        }
      } else {
        console.error('Unable To Get Records');
      }
    });
  }, []);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  return (
    <>
      <PageTitle
        title="Product Mix Report"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="productMixHeading"
      />

      <Paper elevation={6} square className="paperMargin">
        <Toolbar style={{ padding: '0' }}>
          <form onSubmit={getFilteredRecords} className="searchFormAlign">
            <Controls.EditableDropDown
              name="txtSearchProduct"
              label="Product"
              data={products}
              onChange={(e, newValue) => setProduct(newValue)}
              options={products}
              className="searchInput"
              id="ddSearchProduct"
            />
            <Controls.EditableDropDown
              name="txtSearchCategory"
              label="Category"
              data={categories}
              onChange={(e, newValue) => setCategory(newValue)}
              options={categories}
              className="searchInput"
              id="ddSearchCategory"
            />
            <TextField
              className="searchInput"
              id="txtSearchProdSaleDateFrom"
              label="Date From"
              variant="outlined"
              type="date"
              onChange={(e) => setDateFrom(e.target.value)}
              value={dateFrom}
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
            />
            <TextField
              className="searchInput"
              id="txtSearchProdSaleDateTo"
              label="Date To"
              variant="outlined"
              type="date"
              onChange={(e) => setDateTo(e.target.value)}
              value={dateTo}
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
            />
            <Controls.Button
              text="Search"
              variant="contained"
              id="btnSearchProdSale"
              color="primary"
              type="submit"
              className="searchButton"
              startIcon={<SearchIcon />}
            ></Controls.Button>
          </form>
        </Toolbar>
        <CircularLoader isload={isLoading} />
        <GenericTable
          data={records}
          TableId={'productMixTable'}
          TableTitle={'Product Mix Report'}
          duration={`${dateFrom} ${dateTo ? 'To' : ''} ${dateTo}`}
          cellData={[
            { name: 'name', type: 'string' },
            { name: 'category', type: 'string' },
            { name: 'barcode', type: 'string' },
            { name: 'sku', type: 'string' },
            { name: 'quantityIndividual', type: 'float' },
            { name: 'quantityInAllDeals', type: 'float' },
            { name: 'totalQuantity', type: 'float' },
          ]}
          headCells={headCells}
          recordsCount={recordsCount}
          getRecordsFn={getRecords}
          footerRecords={footerRecords}
          isLoading={isLoading}
          footerCellData={[
            { name: 'totalRevenue', type: 'float', align: 'right' },
            { name: 'totalCost', type: 'float', align: 'right' },
            { name: 'totalItemsIndividual', type: 'float', align: 'right' },
            { name: 'totalItemsInDeal', type: 'float', align: 'right' },
            { name: 'totalQuantity', type: 'float', align: 'right' },
          ]}
        />
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
