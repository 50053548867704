import React, { useState, useEffect } from 'react';
import { Grid, Typography, Divider, Switch } from '@material-ui/core';
import CircularLoader from '../../../Utilities/ReuseableCommponents/CircularLoader';
import Notification from '../../../Utilities/ReuseableCommponents/Notification';
import * as SetupService from '../SetupService';
import Controls from '../../../Utilities/Controls/Controls';
import SaveIcon from '@material-ui/icons/Save';

let apiResponseDto = {
  data: [],
  error: null,
  loading: false,
  statusCode: 0,
};

export default function HideFeatureTab() {
  const [setupHideFeatures, setSetupHideFeatures] = useState([]);
  const [setUpDefaultData, setsetUpDefaultData] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  function handleActivationHideFeature() {
    HideFeatureActivation().then((Response) => {
      return Response;
    });
  }

  async function HideFeatureActivation() {
    var payLoad = {
      name: 'Hide Features',
      data: [
        {
          name: 'Barcode',
          status: false,
        },
        {
          name: 'CommissionReport',
          status: false,
        },
        {
          name: 'Expense',
          status: false,
        },
      ],
    };
    var Response = await SetupService.insertSetup(payLoad).then(
      (ResponseInsert) => {
        return ResponseInsert;
      }
    );
    setSetupHideFeatures([Response?.data?.data]);
    return Response;
  }

  useEffect(() => {
    setIsLoaded(true);
    configureHideFeaturesTab();
  }, [setUpDefaultData]);

  function configureHideFeaturesTab() {
    let data = (async () => {
      apiResponseDto = await SetupService.getSetupWithName(
        'Hide Features'
      ).then((response) => {
        setIsLoaded(false);
        return response;
      });
      return apiResponseDto;
    })();
    data.then((response) => {
      if (response && response.data && response.data.data) {
        setSetupHideFeatures(response.data.data);
      } else {
        setsetUpDefaultData(true);
        setNotify({
          isOpen: true,
          message: 'No Data Found',
          type: 'error',
        });
      }
    });
  }

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  function payloadReady(payload) {
    return {
      name: payload.name,
      data: payload.data,
    };
  }
  async function UpdateSetup(obj) {
    return SetupService.updateSetup(payloadReady(obj[0]), obj[0]._id).then(
      (res) => {
        localStorage.setItem(
          '_HideFeature',
          JSON.stringify(res.data.data.data)
        );
        return res;
      }
    );
  }

  function handleSave() {
    UpdateSetup(setupHideFeatures).then((Response) => {
      if (Response.data) {
        displayNotify('Data successfully updated', 'success');
      } else {
        displayNotify('Error while updating data', 'error');
      }
      return Response;
    });
  }

  function displayNotify(msg, displayType) {
    setNotify({
      isOpen: true,
      message: msg,
      type: displayType,
    });
  }

  function handleHideFeatureStatus(e, index) {
    let copyHideFeature = { ...setupHideFeatures };
    copyHideFeature[0].data[index].status = e.target.checked;
    setSetupHideFeatures([copyHideFeature[0]]);
  }

  let ReasonHtml;

  if (setupHideFeatures.length > 0) {
    ReasonHtml = setupHideFeatures.map((feature) => (
      <>
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            <b>{feature.name}</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {feature.data.map((feat, index) => (
            <div key={index} style={{ display: 'flex' }}>
              <Grid item xs={3}>
                <Typography variant="subtitle2" style={{ marginTop: 10 }}>
                  {feat.name}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Switch
                  checked={feat.status}
                  onChange={(e) => handleHideFeatureStatus(e, index)}
                  color="primary"
                  name={'checkHideFeature' + index}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </Grid>
            </div>
          ))}
        </Grid>

        <Grid item xs={12}>
          <Controls.Button
            text="Save Changes"
            variant="contained"
            color="primary"
            id="btnProdSearch"
            startIcon={<SaveIcon />}
            onClick={handleSave}
          />
        </Grid>
      </>
    ));
  } else {
    ReasonHtml = (
      <>
        <React.Fragment key={'initialReason'}>
          <Grid item xs={10}>
            <Controls.Button
              text="Activate Hide Features"
              variant="contained"
              color="primary"
              id="btnactivatehide"
              onClick={handleActivationHideFeature}
            />
          </Grid>
        </React.Fragment>
        <Notification notify={notify} setNotify={setNotify} />
      </>
    );
  }

  return (
    <>
      <CircularLoader isload={isLoaded} />
      <React.Fragment key={'setting'}>
        <Grid container>
          {ReasonHtml}
          <Grid item xs={12}>
            <Divider variant="middle" />
          </Grid>
        </Grid>
      </React.Fragment>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
