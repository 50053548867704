import React from 'react';
import {
  Paper,
  TextField,
  Button,
  Toolbar,
  Modal,
  IconButton,
  Grid,
  Typography,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import PageHeader from '../../Layout/PageTitle';
import AddIcon from '@material-ui/icons/Add';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useLocation, useHistory } from 'react-router-dom';
import { getFormattedDateTime as ISOdateFormatter } from '../../../Common/constants';
import * as APICreditService from './CreditService';
import Controls from '../../Utilities/Controls/Controls';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

function CreditDetails() {
  function getModalStyle() {
    const top = 50;
    const left = 50;
    const height = 50;
    const width = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      height: `${height}%`,
      width: `${width}%`,
      textAlign: `center`,
    };
  }
  const [modalStyle] = React.useState(getModalStyle);
  const location = useLocation();
  const history = useHistory();
  const [openAddCashModal, setOpenAddCashModal] = React.useState(false);
  const [amount, setAmount] = React.useState('');
  const handleAddCashModalClose = () => {
    setOpenAddCashModal(false);
  };
  const handleAddCashModalOpen = () => {
    setOpenAddCashModal(true);
  };
  function handleAddCash() {
    if (amount.length > 0) {
      creditSale(
        location.state.recordForEdit._id,
        amount,
        location.state.recordForEdit.history.length > 0
          ? parseInt(location.state.recordForEdit.history[0].currentBalance) +
              parseInt(amount)
          : parseInt(0) + parseInt(amount)
      );
    }
  }
  async function creditSale(customerRef, creditAmount, balance) {
    const payLoad = {
      customerRef,
      credit: creditAmount,
      currentBalance: balance,
    };
    await APICreditService.insertCredit(payLoad).then((response) => {
      if (response.data) {
        handleAddCashModalClose();
        history.push({
          pathname: '/CreditSales',
        });
      }
    });
  }
  const body2 = (
    <div style={modalStyle} className="paper2">
      <h2 id="simple-modal-title" className="modalTitle">
        Add Cash
      </h2>
      <IconButton
        className="modalCloseButton"
        color="inherit"
        aria-label="menu"
        onClick={handleAddCashModalClose}
        id="btnCloseModal"
      >
        <ClearIcon />
      </IconButton>
      <div className="modalAddCash">
        <TextField
          id="txtCreditOpeningAmount"
          label="Opening Amount"
          variant="outlined"
          color="secondary"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          autoComplete="off"
        />
        <Button
          variant="contained"
          onClick={handleAddCash}
          id="btnCreditOpeningAmount"
          color="primary"
        >
          Add
        </Button>
      </div>
    </div>
  );
  return (
    <>
      <Modal
        open={openAddCashModal}
        onClose={handleAddCashModalClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body2}
      </Modal>
      <PageHeader
        title="Credit Management Detail"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="creditDetailsHeading"
      />
      <Paper elevation={6} className="PaperMargin">
        <Toolbar disableGutters>
          <Grid container>
            <Grid item>
              <TextField
                id="txtCreditDetailsCustName"
                label="Customer Name"
                disabled={true}
                variant="outlined"
                value={location.state.recordForEdit.name}
                className="searchInput"
                InputLabelProps={{
                  shrink: true,
                }}
                autoComplete="off"
              />
            </Grid>
            <Grid item>
              <TextField
                id="txtCreditDetailsContactNo"
                label="Contact No"
                disabled={true}
                variant="outlined"
                value={location.state.recordForEdit.contactNumber}
                className="searchInput"
                InputLabelProps={{
                  shrink: true,
                }}
                autoComplete="off"
              />
            </Grid>
            <Grid item>
              <TextField
                id="txtCreditDetailsCreditLimit"
                name="creditLimit"
                label="Credit Limit"
                disabled={true}
                variant="outlined"
                value={location.state.recordForEdit.creditLimit}
                className="searchInput"
                InputLabelProps={{
                  shrink: true,
                }}
                autoComplete="off"
              />
            </Grid>
          </Grid>
        </Toolbar>
      </Paper>
      <Paper elevation={6} square className="PaperMargin">
        <Toolbar disableGutters>
          <Grid item xs={6}>
            <Typography variant="body2" className="headLabel">
              Credit History
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Controls.Button
              text="Add Cash"
              variant="contained"
              color="primary"
              id="btnCreditAddCash"
              startIcon={<AddIcon />}
              className="newButton"
              onClick={() => {
                handleAddCashModalOpen();
              }}
            />
          </Grid>
        </Toolbar>
        <TableContainer className="tableContent">
          <Table
            id="creditDetailsTable"
            className="table"
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Date & Time</TableCell>
                <TableCell align="left">You Gave</TableCell>
                <TableCell align="left">You Got</TableCell>
                <TableCell align="center">Balance</TableCell>
              </TableRow>
            </TableHead>
            <TableBody id="creditDetailsTbody">
              {location.state.recordForEdit.history.map((row, index) => (
                <TableRow key={row._id} id={'creditDetailsTrow' + index}>
                  <TableCell component="th" scope="row">
                    {ISOdateFormatter(row.createdAt)}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ backgroundColor: '#f9d7d7', color: 'red' }}
                  >
                    {row.debit ? row.debit.toLocaleString() : '-'}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ backgroundColor: '#b2dfdb', color: 'green' }}
                  >
                    {row.credit ? row.credit.toLocaleString() : '-'}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: row.currentBalance >= 0 ? 'green' : 'red',
                    }}
                  >
                    {row.currentBalance
                      ? row.currentBalance.toLocaleString()
                      : '-'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Grid container className="buttonsCotainerMargin">
            <Controls.Button
              text="Back"
              color="default"
              variant="contained"
              id="btnBackCreditDetails"
              startIcon={<ArrowBackIcon />}
              className="newButton"
              onClick={() => {
                history.push({
                  pathname: '/CreditSales',
                });
              }}
            />
          </Grid>
        </TableContainer>
      </Paper>
    </>
  );
}

export default CreditDetails;
