import React from 'react';
import { decode } from 'jsonwebtoken';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import authHeader from '../Services/auth-header';
import { callAPI } from '../Services/ApiCaller';
import mp3 from '../Assets/Sounds/swiftly.mp3';

export const PAGE_TEST_URL =
  process.env.REACT_APP_FE_HOST_URL || 'http://localhost:3000';

export const ORGANIZATION_NAME = process.env.REACT_APP_E2E_TEST_ORGANIZATION;
export const LOGIN_USERNAME =
  process.env.REACT_APP_E2E_TEST_COMPANY_ADMIN_USERNAME;
export const LOGIN_PASSWORD =
  process.env.REACT_APP_E2E_TEST_COMPANY_ADMIN_PASSWORD;
export const API_USERNAME = process.env.REACT_APP_E2E_TEST_SUPER_ADMIN_USERNAME;
export const API_PASSWORD = process.env.REACT_APP_E2E_TEST_SUPER_ADMIN_PASSWORD;
export const FOOD_ORGANIZATION_NAME =
  process.env.REACT_APP_E2E_TEST_FOOD_ORGANIZATION;
export const FOOD_LOGIN_USERNAME =
  process.env.REACT_APP_E2E_TEST_FOOD_COMPANY_ADMIN_USERNAME;
export const FOOD_LOGIN_PASSWORD =
  process.env.REACT_APP_E2E_TEST_FOOD_COMPANY_ADMIN_PASSWORD;
export const FOOD_API_USERNAME =
  process.env.REACT_APP_E2E_TEST_FOOD_SUPER_ADMIN_USERNAME;
export const FOOD_API_PASSWORD =
  process.env.REACT_APP_E2E_TEST_FOOD_SUPER_ADMIN_PASSWORD;

export const BaseAPIURL =
  (process.env.REACT_APP_BE_HOST_URL || 'https://api-dev.theposfactory.com') +
  '';

export const API_VERSION = '1.0';
export const initStatePOS = {
  data: {
    categories: [
      {
        name: '',
        _Cid: '0',
        _id: '0',
        products: [
          {
            _id: '0',
            name: '',
            productPrice: '0',
            barcode: '0',
            _Cid: '0',
          },
        ],
      },
    ],
  },
};

export function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      {new Date().getFullYear()}{' '}
      <Link color="inherit" href="https://concavepos.com/" id="lnkCopyright">
        Concave POS
      </Link>
    </Typography>
  );
}

export const CustomerInitialData = {
  contactNumber: '',
  name: '',
};
export const ConstNayaPayURL =
  'https://api-middleware-dev.nayapay.com/nayapay-middleware-0.0.1/merchant-api/create-payment-request';
export const CommissionInitialData = {
  name: 'Commission',
  data: [
    {
      name: 'Category',
      value: true,
      status: 'active',
    },
  ],
};
export const AreaInitialData = {
  name: 'Area',
  data: [
    {
      name: '',
      value: true,
      status: 'active',
      deliveryCharges: 0,
    },
  ],
};
export const tempCityData = {
  name: '',
  value: '',
  status: 'active',
};
export const BarcodeInitialData = {
  name: 'Barcode',
  data: [
    {
      name: 'EAN/UPC',
      value: true,
      status: 'active',
    },
    {
      name: 'Customize',
      value: true,
      status: 'active',
    },
  ],
};
export const BarcodeCustomeInitialData = {
  name: 'Customize Barcode',
  data: [
    {
      prefix: '',
      suffix: '',
      sequenceStart: 0,
      centerPadding: 0,
      userInput: '',
      format: 'CODE128',
      background: '#FFFFFF',
      lineColor: '#000000',
      fontSize: 20,
      height: 100,
      width: 2,
      margin: 10,
      textMargin: 0,
      displayValue: true,
      font: 'monospace',
      fontOptions: ['bold'],
      textAlign: 'left',
    },
  ],
};

export const OrgNameInit = {
  name: 'Organization Info',
  data: { displayName: 'abc', value: 'abc' },
};

export const PhoneNumInit = {
  data: [
    {
      name: '000-0000000',
    },
  ],
  name: 'CompanyContactNumber',
};
export const MiscellaneousChargesInitData = {
  name: 'Miscellaneous Charges',
  data: {
    charges: 0,
    status: false,
  },
};
export const ShortcutKeysInitData = {
  name: 'Shortcut Keys',
  data: {
    status: false,
  },
};

export const DataDump = {
  Order: [
    {
      invoiceNumber: 'Dummy Data',
      saleType: 'Dummy Data',
      saleMode: 'Dummy Data',
      shiftNumber: 'Dummy Data',
      saleAmountSumOfItems: 0,
      overallSaleDiscountAmount: 0,
      saleAmountTotal: 0,
      TotalQuantity: 0,
      itemsSold: [
        {
          _id: 'Dummy Data',
          name: 'Dummy Data',
          saleFinalPrice: 0,
          unitSaleFinalPrice: 0,
          barcode: 'Dummy Data',
          Quantity: '0',
          taxPercentage: 0,
          salePrice: 0,
          unitSalePrice: 0,
          unitQuantity: '0',
          discountPercentage: 0,
          unitType: 'Dummy Data',
          units: 'Dummy Data',
        },
      ],
    },
  ],
};

export const initValueHoldOrder = {
  HoldOrders: [
    {
      Date: '',
      HoldOrderNumber: '',
    },
  ],
};

export const initValueKotOrder = {
  KotOrders: [
    {
      Date: '',
      HoldOrderNumber: '',
    },
  ],
};

export function getDateTime() {
  var date = new Date();
  return date.toISOString();
}

export function getDifferenceInMinutes(date1, date2) {
  const diffInMs = Math.abs(date2 - date1);
  return diffInMs / (1000 * 60);
}

export function getFormattedDateTime(toISOStringDate) {
  function isIsoDate(str) {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
    var d = new Date(str);
    return d.toISOString() === str;
  }
  if (isIsoDate(toISOStringDate)) {
    let today = new Date(toISOStringDate);
    let options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour12: true,
      hour: '2-digit',
      minute: '2-digit',
    };
    const formattedDate = today.toLocaleDateString('en-GB', options);
    return formattedDate;
  } else {
    console.error('Invalid ISO Date Format');
    return '';
  }
}
export const getType = () => [
  { _id: 'retail', name: 'Retail POS', nameValue: 'retail' },
  { _id: 'food', name: 'Food POS', nameValue: 'food' },
];
export const getInventoryTypeCollection = () => [
  { _id: 'purchase', name: 'Purchase (+)', nameValue: 'purchase' },
  { _id: 'salesreturn', name: 'Sales Return (+)', nameValue: 'salesreturn' },
  { _id: 'stockgain', name: 'Stock Gain (+)', nameValue: 'stockgain' },
  {
    _id: 'purchasereturn',
    name: 'Purchase Return (-)',
    nameValue: 'purchasereturn',
  },
  { _id: 'sales', name: 'Sales (-)', nameValue: 'sales' },
  { _id: 'stockloss', name: 'Stock Loss (-)', nameValue: 'stockloss' },
];
export const getCustomerType = () => [
  { _id: 'walkin', name: 'Walk In', nameValue: 'walkin' },
];

export const getCreditLimit = () => [
  { _id: 'silver', name: 'Silver', nameValue: 'silver' },
  { _id: 'gold', name: 'Gold', nameValue: 'gold' },
  { _id: 'platinum', name: 'Platinum', nameValue: 'platinum' },
];

export const getCustomerStatus = () => [
  { _id: 'active', name: 'Active', nameValue: 'active' },
  { _id: 'disabled', name: 'Disabled', nameValue: 'disabled' },
];

export function convertNumberToWords(amount) {
  var words = new Array();
  words[0] = '';
  words[1] = 'One';
  words[2] = 'Two';
  words[3] = 'Three';
  words[4] = 'Four';
  words[5] = 'Five';
  words[6] = 'Six';
  words[7] = 'Seven';
  words[8] = 'Eight';
  words[9] = 'Nine';
  words[10] = 'Ten';
  words[11] = 'Eleven';
  words[12] = 'Twelve';
  words[13] = 'Thirteen';
  words[14] = 'Fourteen';
  words[15] = 'Fifteen';
  words[16] = 'Sixteen';
  words[17] = 'Seventeen';
  words[18] = 'Eighteen';
  words[19] = 'Nineteen';
  words[20] = 'Twenty';
  words[30] = 'Thirty';
  words[40] = 'Forty';
  words[50] = 'Fifty';
  words[60] = 'Sixty';
  words[70] = 'Seventy';
  words[80] = 'Eighty';
  words[90] = 'Ninety';
  amount = amount.toString();
  var atemp = amount.split('.');
  var number = atemp[0].split(',').join('');
  var n_length = number.length;
  var words_string = '';
  var i, j;
  if (n_length <= 9) {
    var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
    var received_n_array = new Array();
    for (i = 0; i < n_length; i++) {
      received_n_array[i] = number.substr(i, 1);
    }
    for (i = 9 - n_length, j = 0; i < 9; i++, j++) {
      n_array[i] = received_n_array[j];
    }
    for (i = 0, j = 1; i < 9; i++, j++) {
      if (i == 0 || i == 2 || i == 4 || i == 7) {
        if (n_array[i] == 1) {
          n_array[j] = 10 + parseInt(n_array[j]);
          n_array[i] = 0;
        }
      }
    }
    var value = '';
    for (i = 0; i < 9; i++) {
      if (i == 0 || i == 2 || i == 4 || i == 7) {
        value = n_array[i] * 10;
      } else {
        value = n_array[i];
      }
      if (value != 0) {
        words_string += words[value] + ' ';
      }
      if (
        (i == 1 && value != 0) ||
        (i == 0 && value != 0 && n_array[i + 1] == 0)
      ) {
        words_string += 'Crores ';
      }
      if (
        (i == 3 && value != 0) ||
        (i == 2 && value != 0 && n_array[i + 1] == 0)
      ) {
        words_string += 'Lakhs ';
      }
      if (
        (i == 5 && value != 0) ||
        (i == 4 && value != 0 && n_array[i + 1] == 0)
      ) {
        words_string += 'Thousand ';
      }
      if (i == 6 && value != 0 && n_array[i + 1] != 0 && n_array[i + 2] != 0) {
        words_string += 'Hundred And ';
      } else if (i == 6 && value != 0) {
        words_string += 'Hundred ';
      }
    }
    words_string = words_string.split('  ').join(' ');
  }
  return words_string;
}

export function GetTokenValue(params) {
  const jwt = localStorage.getItem('Token');
  if (jwt) {
    var decodedToken = decode(jwt, { complete: true });
    if (params === 'CompanyName') {
      return decodedToken.payload.data.companyName;
    } else if (params === 'DataBaseName') {
      return decodedToken.payload.data.CName;
    } else if (params === 'role') {
      return decodedToken.payload.data.role;
    } else if (params === 'userId') {
      return decodedToken.payload.data.id;
    }
  }
}

export function GetCompanyType() {
  let companyType = localStorage.getItem('type');

  return companyType ? (companyType == 'retail' ? 'retail' : 'food') : 'retail';
}

/**
 * Function To Get Searched Products/Items From BackEnd API Based On Product Name.
 * Default Max Rows Are 1000
 * @param {string} searchString arg A string with the name of Product/Item.
 * @param {number} rows value to get max number of rows, Default Value is 1000
 */
export async function getAllSeachedProductsAginstName(
  searchString,
  rows = 1000
) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  return callAPI(
    `api/${API_VERSION}/items`,
    null,
    headerObject,
    'GET',
    `?populate=categoriesRef&$orderBy=name&$orderDir=${-1}&$skip=${0}&$top=${rows}&name=${searchString}`
  ).then((Responce) => {
    return Responce;
  });
}

export function json2csv(json, ReportTitle, ReportDuration) {
  let array = typeof json != 'object' ? JSON.parse(json) : json;
  let str = '';
  str += 'Report Title: ' + ReportTitle + '\r\n\r\n\r\n';
  str += 'Duration: ' + ReportDuration + '\r\n\r\n\r\n';
  for (let value of array) {
    str += commaSeparatedLine(value) + '\r\n';
  }
  let blob = new Blob([str], { type: 'text/csv;charset=utf-8;' });
  let link = document.createElement('a');
  if (link.download !== undefined) {
    // feature detection
    // Browsers that support HTML5 download attribute
    let url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `report_${new Date().getTime()}.csv`);
    link.style.visibility = 'hidden';
    return link;
  }
}

function commaSeparatedLine(value) {
  let line = '';
  for (let index in value) {
    if (line != '') line += ',';
    if (isNaN(parseFloat(value[index]))) {
      line += value[index];
    } else {
      line += parseFloat(value[index] ? value[index].replace(',', '') : 0);
    }
  }
  return line;
}

export function playAudio() {
  const myAudio = new Audio(mp3);
  myAudio.play();
}

export function getIsOdms() {
  let odms = false;
  const Val = localStorage.getItem('role');
  if (Val === 'agent') {
    odms = true;
  }
  return odms;
}

/**
 * @function
 * @name randomNumberGenerator
 * @description Methode to get the random number
 * @global
 * @public
 * @yields {number} - Random Number
 */
export function randomNumberGenerator() {
  const crypto = window.crypto || window.msCrypto;
  var array = new Uint32Array(1);
  return crypto.getRandomValues(array)[0];
}

export function ValueNotNull(data) {
  let returnValue = false;
  if (data) {
    if (data.length > 0) {
      returnValue = true;
    }
  }
  return returnValue;
}
export function AndCondition(
  a,
  b,
  c = true,
  d = true,
  e = true,
  f = true,
  g = true
) {
  let retValue = false;
  if (a && b && c && d && e && f && g) retValue = true;
  return retValue;
}

export function dateTimeZone() {
  let offsetMinuts = new Date().getTimezoneOffset();
  let sign = '';
  if (offsetMinuts < 0) {
    sign = '%2B'; // + sign
  } else {
    sign = '%2D'; // - sign
  }
  let hours = Math.floor(Math.abs(offsetMinuts) / 60);
  let minutes = Math.abs(offsetMinuts) % 60;
  return `${sign}${hours < 10 ? 0 : ''}${hours}:${
    minutes < 10 ? 0 : ''
  }${minutes}`;
}
