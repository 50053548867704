import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import Controls from '../../Utilities/Controls/Controls';
import { useForm, Form } from '../../Utilities/ReuseableCommponents/useForm';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import PageTitle from '../../Layout/PageTitle';
import { useLocation } from 'react-router-dom';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import { Paper } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import * as VendorService from './VendorService';
import { useHistory } from 'react-router-dom';
import useStyles from '../../../Style/AppStylesAdmin';

const initialFValues = {
  _id: 0,
  company: '',
  NTN: '',
  email: '',
  openingDate: '',
  endDate: '',
  comments: '',
  accountingSoftwareID: '',
  name: '',
  contactNumber: '',
  contactPerson: '',
  address: '',
  openingBalance: 0,
  active: true,
};
let Responce = {
  data: null,
  error: null,
  loading: false,
};

let apiResponce = {
  data: null,
  error: null,
  loading: false,
  statusCode: 0,
};
function VendorDetails() {
  const history = useHistory();
  const classes = useStyles();

  const location = useLocation();

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const { values, setValues, handleInputChange, resetForm } = useForm(
    initialFValues
  );

  const addOrEdit = async (vendor, resetForm) => {
    if (vendor._id === 0 && vendor.name && vendor.contactNumber) {
      try {
        Responce.data = null;
        Responce.error = null;
        Responce = await VendorService.insertVendor(vendor).then((Responce) => {
          return Responce;
        });

        if (Responce.data) {
          resetForm();

          setNotify({
            isOpen: true,
            message: 'Submitted Successfully',
            type: 'success',
          });
        }

        if (Responce.error) {
          setNotify({
            isOpen: true,
            message: 'Unable To Process Request',
            type: 'error',
          });
        }
      } catch (e) {
        setNotify({
          isOpen: true,
          message: 'Unable To Process Request',
          type: 'error',
        });
      }
    }
    if (vendor._id !== 0 && vendor.name && vendor.contactNumber) {
      try {
        apiResponce.data = null;
        apiResponce.error = null;
        apiResponce = await VendorService.updateVendor(vendor).then(
          (Responce) => {
            return Responce;
          }
        );
        if (apiResponce.data) {
          resetForm();
          setNotify({
            isOpen: true,
            message: 'Vendor Detail Updated Successfully',
            type: 'success',
          });
        }
        if (apiResponce.error) {
          setNotify({
            isOpen: true,
            message: 'Unable To Process Request 1',
            type: 'error',
          });
        }
      } catch (e) {
        setNotify({
          isOpen: true,
          message: 'Unable To Process Request',
          type: 'error',
        });
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    addOrEdit(values, resetForm);
  };

  useEffect(() => {
    if (location.state.recordForEdit != null)
      setValues({ ...location.state.recordForEdit });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state.recordForEdit]);

  return (
    <>
      <PageTitle
        title={location.state.pageHeaderTitile}
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="vendorDetailsHeading"
      />
      <Paper elevation={6} square className={classes.PaperMargin}>
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid container>
              <Grid item xs={12} sm={6} md={4}>
                <Controls.Input
                  name="company"
                  label="Vendor Company"
                  value={values.company}
                  onChange={handleInputChange}
                  id="txtVendorCompany"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Controls.Input
                  name="NTN"
                  label="Vendor NTN"
                  value={values.NTN}
                  onChange={handleInputChange}
                  id="txtVendorNTN"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}></Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6} md={4}>
                <Controls.Input
                  name="name"
                  label="Vendor Name"
                  value={values.name}
                  onChange={handleInputChange}
                  id="txtVendorName"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Controls.Input
                  name="contactPerson"
                  label="Contact Person"
                  value={values.contactPerson}
                  onChange={handleInputChange}
                  id="txtVendorContactPerson"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Controls.Input
                  name="contactNumber"
                  label="Contact Number"
                  value={values.contactNumber}
                  onChange={handleInputChange}
                  id="txtVendorContactNumber"
                  inputProps={{
                    maxLength: 11,
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Controls.Input
                  name="email"
                  label="Email"
                  value={values.email}
                  onChange={handleInputChange}
                  required
                  id="txtVendorEmail"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <Controls.Input
                  name="address"
                  label="Address"
                  multiline
                  row={3}
                  value={values.address}
                  onChange={handleInputChange}
                  id="txtVendorAddress"
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6} md={4}>
                <Controls.Input
                  name="openingDate"
                  label="Opening Date"
                  value={values.openingDate}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  autoComplete="off"
                  onChange={handleInputChange}
                  id="txtVendorOpeningDate"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Controls.Input
                  name="openingBalance"
                  label="Opening Balance"
                  value={values.openingBalance}
                  onChange={handleInputChange}
                  id="txtVendorOpeningBalance"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Controls.Input
                  name="endDate"
                  label="End Date"
                  value={values.endDate}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  autoComplete="off"
                  onChange={handleInputChange}
                  id="txtVendorEndDate"
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6} md={4}>
                <Controls.Input
                  name="accountingSoftwareID"
                  label="Accounting Software ID"
                  value={values.accountingSoftwareID}
                  onChange={handleInputChange}
                  id="txtVendorAccountingSoftwareId"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Controls.Checkbox
                  name="active"
                  label="Active"
                  value={values.active}
                  onChange={handleInputChange}
                  id="chkVendorActive"
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <Controls.Input
                  name="comments"
                  label="Comments"
                  multiline
                  row={3}
                  value={values.comments}
                  onChange={handleInputChange}
                  id="txtVendorComments"
                />
              </Grid>
            </Grid>
            <Grid container className={classes.ButtonsCotainerMargin}>
              <br></br>
              <Grid item xs={6} sm={6} align="left">
                <Controls.Button
                  text="Cancel"
                  color="default"
                  onClick={history.goBack}
                  startIcon={<CloseIcon />}
                  id="btnVendorCancel"
                />
              </Grid>
              <Grid item xs={6} sm={6} align="right">
                <div>
                  <Controls.Button
                    type="submit"
                    startIcon={<SaveIcon />}
                    text="Save"
                    id="btnVendorSave"
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}

export { VendorDetails as default };
