import React, { useState, useEffect } from 'react';
import PageTitle from '../../Layout/PageTitle';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Typography,
} from '@material-ui/core';

import Notification from '../../Utilities/ReuseableCommponents/Notification';
import * as SetupService from './SetupService';
import useTableServerSide from '../../Utilities/ReuseableCommponents/useTableServerSide';
import CircularLoader from '../../Utilities/ReuseableCommponents/CircularLoader';
import { GetTokenValue } from '../../../Common/constants';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  newButton: {
    position: 'absolute',
    right: '0px',
  },
  PaperMargin: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
}));

const headCells = [
  { id: 'billingmonth', label: 'Billing Month', align: 'left' },
  { id: 'status', label: 'Status', align: 'left' },
  { id: 'monthlyCharges', label: 'Monthly Charges', align: 'left' },
];

export default function UserAnalyticsAndBillingInfo() {
  const classes = useStyles();
  const [records, setRecords] = useState([]);
  const [recordsCount, setRecordsCount] = useState(0);
  const [appVersion, setAppVersion] = useState('0.0.0');
  const [appType] = useState(localStorage.getItem('type'));
  const [orgName, setOrgName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  let companyName = GetTokenValue('CompanyName');
  function getRecords() {
    try {
      if (companyName) {
        setIsLoading(true);
        SetupService.getCompanyBillingHistoryByName(
          companyName.replace(/\s/g, '').toLowerCase()
        )
          .then((companies) => {
            if (companies.data && companies.data.data) {
              setRecords(companies.data.data);
              setIsLoading(false);
            } else {
              setIsLoading(false);
            }
          })
          .catch((error) => {
            console.error(error);
            setIsLoading(false);
          });
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    try {
      if (companyName) {
        SetupService.getCompanyBillingHistoryByName(
          companyName.replace(/\s/g, '').toLowerCase()
        )
          .then((companies) => {
            if (companies.data && companies.data.data) {
              setRecords(companies.data.data);
              setRecordsCount(companies.data.count);
              setIsLoading(false);
            }
          })
          .catch((error) => {
            setIsLoading(false);
            console.error(error);
          });
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    fetch('meta.json')
      .then((response) => response.json())
      .then((json) => {
        setAppVersion(json.version);
      });

    async function getOrganizationName() {
      const Response = await SetupService.getAllSetups();
      if (Response.data) {
        console.log(Response.data, '----setups');
        let organizationInfoData = Response.data.data.filter(
          (e) => e.name === 'Organization Info'
        );
        if (
          organizationInfoData &&
          organizationInfoData[0] &&
          organizationInfoData[0].data &&
          organizationInfoData[0].data.displayName
        ) {
          let displayName = organizationInfoData[0].data.displayName;
          setOrgName(displayName);
        }
      }
    }
    getOrganizationName();
  }, []);

  const { TblContainer, TblHead, TblPagination } = useTableServerSide(
    records,
    recordsCount,
    headCells,
    getRecords
  );

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  return (
    <>
      <PageTitle
        title="User Analytics & Billing Info"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="userAccInfoHeading"
      />
      <Paper elevation={1} square className={classes.PaperMargin}>
        <Grid container spacing={0}>
          <Grid item xs={2}>
            <Typography variant="subtitle2" gutterBottom>
              Company Name
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="subtitle1" gutterBottom>
              {orgName}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle2" gutterBottom>
              Version
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography
              id="userAccInfoAppVersion"
              variant="subtitle1"
              gutterBottom
            >
              Software Version ({appType.toUpperCase()} v{appVersion})
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle2" gutterBottom>
              Payment History
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <CircularLoader isload={isLoading} />
            <TblContainer>
              <TblHead />
              <TableBody id="userAccInfoListTbody">
                {records.map((item, index) => (
                  <TableRow key={item._id}>
                    <TableCell id={`billingId${index}`}>
                      {item.month} - {item.year}
                    </TableCell>
                    <TableCell>{item.status}</TableCell>
                    <TableCell>{item.monthlyCharges}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TblContainer>
            <TblPagination />
          </Grid>
        </Grid>
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
