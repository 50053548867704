import React from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles, Grid, Card } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    padding: theme.spacing(4),
  },
  cardTitle: {
    color: '#999',
  },
  cardData: {
    color: '#1976d2',
    fontSize: '1.7rem',
  },
}));

export default function CustomChart(props) {
  const classes = useStyles();
  const {
    headLabel,
    ChartType,
    chartStyle,
    isLoaded,
    elementId,
    ...others
  } = props;
  let data = chartStyle;
  if (ChartType.name == 'Bar' && !('datasets' in chartStyle)) {
    data = {
      labels: chartStyle.xAxisLabel,
      datasets: [
        {
          label: chartStyle.legend,
          borderWidth: 10,
          backgroundColor: '#1976d2',
          borderColor: '#1976d2',
          fill: false,
          hoverBackgroundColor: '#1976d2',
          hoverBorderColor: '#1976d2',
          barThickness: 35,
          data: chartStyle.data,
        },
      ],
    };
  }
  return (
    <Grid item sm={6}>
      <div className={classes.pageContent} style={{ textAlign: 'left' }}>
        <Typography variant="h5" id={elementId} gutterBottom>
          {headLabel}
        </Typography>
        {isLoaded ? (
          <ChartType data={data} width={500} height={250} {...others} />
        ) : (
          <CircularProgress variant="indeterminate" size={100} thickness={4} />
        )}
      </div>
    </Grid>
  );
}

export function CustomCards(props) {
  const classes = useStyles();
  const { IconType, cardLabel, value, textId, countId } = props;
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={6}
      lg={4}
      xl={3}
      style={{
        padding: '0 8px',
      }}
    >
      <Card
        elevation={6}
        square
        className={classes.root}
        style={{
          backgroundColor: 'white',
          width: '100%',
          height: '100px',
          padding: '20px',
          marginTop: '40px',
          display: 'flex',
        }}
      >
        <Grid item>
          <Card
            style={{
              backgroundColor: '#1976d2',
              width: '95px',
              height: '95px',
              marginTop: '-40px',
              position: 'absolute',
            }}
          >
            <IconType
              style={{
                color: 'white',
                width: '50px',
                height: '50px',
                margin: 'auto',
                marginTop: '25px',
                display: 'flex',
              }}
            />
          </Card>
        </Grid>
        <Grid
          item
          md={12}
          style={{
            textAlign: 'right',
            width: '100%',
          }}
        >
          <Typography id={textId} className={classes.cardTitle}>
            {cardLabel}
          </Typography>
          <Typography id={countId} className={classes.cardData} variant="h4">
            {value.toLocaleString('en')}
          </Typography>
        </Grid>
      </Card>
    </Grid>
  );
}

CustomChart.propTypes = {
  ChartType: PropTypes.func,
  data: PropTypes.object,
  headLabel: PropTypes.string,
  isLoaded: PropTypes.bool,
  chartStyle: PropTypes.object,
  elementId: PropTypes.string,
};

CustomCards.propTypes = {
  IconType: PropTypes.object,
  cardLabel: PropTypes.string,
  value: PropTypes.number,
  textId: PropTypes.string,
  countId: PropTypes.string,
};
