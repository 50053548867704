import React from 'react';
import Controls from '../../../Utilities/Controls/Controls';
import ReactDOM from 'react-dom';
import Print from '../Report/print';
import { json2csv } from '../../../../Common/constants';
import PrintIcon from '@material-ui/icons/Print';
import GetAppIcon from '@material-ui/icons/GetApp';
import PropTypes from 'prop-types';

export default function ExportAndPrintReport(props) {
  const {
    headCells,
    records,
    cellData,
    duration,
    footerCellData,
    footerRecords,
    evaluateValue,
    TableTitle,
  } = props;
  function MountData() {
    const BodyCells = [];

    records.forEach((item) => {
      let obj = {};
      cellData.forEach(
        (elem, ind) => (obj['a' + ind] = evaluateValue(item, elem))
      );
      BodyCells.push(obj);
    });

    let footCells = [];
    if (footerCellData) {
      let obj = { headDate: 'Total' };
      footerCellData.forEach(
        (elem, ind) => (obj['b' + ind] = evaluateValue(footerRecords, elem))
      );
      footCells.push(obj);
    }
    return {
      ReportTitle: TableTitle,
      ReportFilters: null,
      ReportHeader: headCells,
      ReportBody: BodyCells,
      ReportDuration: duration ? duration : null,
      ReportFooter: footerCellData ? footCells : null,
    };
  }
  function handlePrintReport() {
    let data = MountData();
    ReactDOM.render(
      <Print Data={data} />,
      document.getElementById('print-mount')
    );

    document.getElementById('print-button').click();
    ReactDOM.unmountComponentAtNode(document.getElementById('print-mount'));
  }
  function handleExport() {
    let data = MountData();
    var formattedItems = data.ReportBody ? data.ReportBody : [];
    formattedItems.unshift(
      data.ReportHeader.reduce(
        (returnedObject, current) => ({
          ...returnedObject,
          [current.id]: current.label,
        }),
        {}
      )
    );
    var link = json2csv(formattedItems, data.ReportTitle, data.ReportDuration);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          marginTop: '20px',
        }}
      >
        <Controls.Button
          text="Print Report"
          variant="contained"
          color="primary"
          id="btnPrintInvoice"
          onClick={handlePrintReport}
          startIcon={<PrintIcon />}
        />
        <Controls.Button
          text="Export Report"
          variant="contained"
          color="primary"
          id="btnExportReport"
          onClick={handleExport}
          startIcon={<GetAppIcon />}
        />
      </div>
      <div id="print-mount"></div>
    </>
  );
}

ExportAndPrintReport.propTypes = {
  records: PropTypes.any,
  headCells: PropTypes.array,
  cellData: PropTypes.array,
  footerRecords: PropTypes.any,
  duration: PropTypes.any,
  footerCellData: PropTypes.any,
  evaluateValue: PropTypes.func,
  TableTitle: PropTypes.string,
};
