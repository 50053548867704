import React, { useState } from 'react';
import PageTitle from '../../Layout/PageTitle';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import { Paper, makeStyles, Toolbar, TextField, Grid } from '@material-ui/core';
import Controls from '../../Utilities/Controls/Controls';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import * as recipeService from './RecipeService';
import { useHistory } from 'react-router-dom';
import GenericTable from '../../Utilities/ReuseableCommponents/TableReport/GenericTable';
import CircularLoader from '../../Utilities/ReuseableCommponents/CircularLoader';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    padding: theme.spacing(3),
  },
  newButton: {
    float: 'right',
    marginLeft: 0,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      float: 'none',
    },
  },
  searchInput: {
    width: '100%',
    paddingRight: '5px',
    margin: '8px 0',
  },

  searchButton: {
    marginLeft: 0,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  PaperMargin: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
}));

const headCells = [
  { id: 'name', label: 'Recipe Name', align: 'left' },
  {
    id: 'actions',
    label: 'Actions',
    disableSorting: true,
    align: 'center',
    type: 'remove',
  },
];

let apiResponseDto = {
  data: [],
  error: null,
  loading: false,
  statusCode: 0,
};

export default function Categories() {
  const classes = useStyles();
  const history = useHistory();
  const recordForEdit = null;
  const [records, setRecords] = useState([]);
  const [recordsCount, setRecordsCount] = useState(0);
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const rowOnClick = {
    path: '/RecipeDetails',
    header: 'View/Edit Recipes',
    property: ['recordForEdit'],
  };
  function getRecords(page, rowsPerPage, sortDirection, sortColumnName) {
    let searchString = '';
    setIsLoading(true);
    let data = (async () => {
      if (name) {
        searchString += `&name=${name}`;
      }
      apiResponseDto = await recipeService
        .getAllRecipes(
          page,
          rowsPerPage,
          sortDirection,
          sortColumnName,
          searchString
        )
        .then((Response) => {
          return Response;
        });
      return apiResponseDto;
    })();
    data.then((Response) => {
      setIsLoading(false);
      if (Response.data) {
        setRecords(Response.data.data);
        setRecordsCount(Response.data.count);
        if (Response.data.count == 0) {
          setNotify({
            isOpen: true,
            message: 'No Data Found',
            type: 'error',
          });
        }
      } else {
        setIsLoading(false);
        console.error('Error While Fetching Data: ', Response.error);
      }
    });
  }

  const handleSearch = (e) => {
    e.preventDefault();
    getRecords(0, 5, -1, '');
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const onDelete = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    try {
      apiResponseDto.data = null;
      apiResponseDto.error = null;
      apiResponseDto = await recipeService.deleteRecipe(id).then((Response) => {
        return Response;
      });
      if (apiResponseDto.data) {
        getRecords(0, 10, 1, 'name');
        setNotify({
          isOpen: true,
          message: 'Deleted Successfully',
          type: 'success',
        });
      }
      if (apiResponseDto.error) {
        if (apiResponseDto.statusCode === 403) {
          setNotify({
            isOpen: true,
            message:
              'Unable To Delete Recipe : Products are also Associated With This Recipe',
            type: 'error',
          });
        } else {
          setNotify({
            isOpen: true,
            message: 'Unable To Process Request',
            type: 'error',
          });
        }
      }
    } catch (e) {
      setNotify({
        isOpen: true,
        message: 'Unable To Process Request',
        type: 'error',
      });
    }
  };

  return (
    <>
      <PageTitle
        title="Recipes"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="recipeListHeading"
      />
      <Paper elevation={1} square className={classes.PaperMargin}>
        <Toolbar disableGutters>
          <form
            onSubmit={handleSearch}
            style={{ width: '100%', display: 'flex', alignItems: 'center' }}
          >
            <Grid container>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  id="txtSearchRecipeName"
                  label="Name"
                  className={classes.searchInput}
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <Controls.Button
                  text="Search"
                  variant="contained"
                  color="primary"
                  id="btnRecipeSearch"
                  className={classes.searchButton}
                  startIcon={<SearchIcon />}
                  type="submit"
                />
              </Grid>
              <Grid item xs={12} md={4}></Grid>
              <Grid item xs={12} md={2}>
                <Controls.Button
                  text="Add New"
                  variant="contained"
                  color="primary"
                  id="btnAddRecipe"
                  startIcon={<AddIcon />}
                  className={classes.newButton}
                  onClick={() => {
                    history.push({
                      pathname: '/RecipeDetails',
                      state: {
                        pageHeaderTitile: 'Add New Recipe',
                        recordForEdit,
                      },
                    });
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </Toolbar>
        <CircularLoader isload={isLoading} />
        <GenericTable
          data={records}
          TableId={'recipeTable'}
          TableTitle={'Recipe Table'}
          cellData={[
            { name: 'name', type: 'string' },
            { name: 'remove', type: 'remove' },
          ]}
          headCells={headCells}
          recordsCount={recordsCount}
          getRecordsFn={getRecords}
          rowDeleteDialog={setConfirmDialog}
          rowDeletefn={onDelete}
          rowOnClick={rowOnClick}
          isLoading={isLoading}
        />
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        id_Yes="btnDelRecipeYes"
        id_No="btnDelRecipeNo"
      />
    </>
  );
}
