import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';

const CircularLoader = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(props.isload);
  }, [props.isload]);

  return (
    <div
      style={{
        height: '5px',
        textAlign: 'center',
      }}
    >
      {isLoading && (
        <LinearProgress
          color="primary"
          variant="indeterminate"
          size={100}
          thickness={4}
        />
      )}
    </div>
  );
};

CircularLoader.propTypes = {
  isload: PropTypes.bool.isRequired,
};

export default CircularLoader;
