import React from 'react';
import './end-shift-report.css';
import { getFormattedDateTime } from '../../../../Common/constants';

class ComponentToPrint extends React.Component {
  render() {
    const companyName = localStorage.getItem('CompanyName');
    const cashierName = localStorage.getItem('username');
    const currentDate = getFormattedDateTime(new Date().toISOString());
    const data = JSON.parse(localStorage.getItem('ShiftReport'));

    return (
      <div>
        <h1 style={{ textAlign: 'center', textTransform: 'uppercase' }}>
          {companyName}
        </h1>
        <table>
          <tbody>
            <tr>
              <td style={{ padding: '0px 10px 10px' }}>
                Cashier: {cashierName}
              </td>
            </tr>
            <tr>
              <td style={{ padding: '0px 10px 10px' }}>Date: {currentDate}</td>
            </tr>
          </tbody>
        </table>
        <p
          style={{
            textAlign: 'center',
            borderBottom: '1px solid black',
            padding: '0px 0px 5px',
            fontSize: '16px',
            fontWeight: 'bold',
          }}
        >
          END SHIFT CASH MANAGEMENT
        </p>
        <table>
          <tbody>
            <tr
              style={{ padding: '0px 0px 4px', lineHeight: '30px' }}
              key={'ssd'}
            >
              <td style={{ padding: '0px 10px 10px' }}>Shift Start Date</td>
              <td style={{ padding: '0px 10px 5px', textAlign: 'right' }}>
                {data.startDate}
              </td>
            </tr>
            <tr
              style={{ padding: '0px 0px 4px', lineHeight: '30px' }}
              key={'sed'}
            >
              <td style={{ padding: '0px 10px 10px' }}>Shift End Date</td>
              <td style={{ padding: '0px 10px 5px', textAlign: 'right' }}>
                {currentDate}
              </td>
            </tr>
            <tr
              style={{ padding: '0px 0px 4px', lineHeight: '30px' }}
              key={'ea'}
            >
              <td style={{ padding: '0px 10px 10px' }}>Expected Amount</td>
              <td style={{ padding: '0px 10px 5px', textAlign: 'right' }}>
                Rs. {data.expectedAmount}
              </td>
            </tr>
            <tr
              style={{ padding: '0px 0px 4px', lineHeight: '30px' }}
              key={'ca'}
            >
              <td style={{ padding: '0px 10px 10px' }}>Closing Amount</td>
              <td style={{ padding: '0px 10px 5px', textAlign: 'right' }}>
                Rs. {data.closingAmount}
              </td>
            </tr>
            <tr
              style={{ padding: '0px 0px 4px', lineHeight: '30px' }}
              key={'iss'}
            >
              <td style={{ padding: '0px 10px 10px' }}>Internal Sales</td>
              <td style={{ padding: '0px 10px 5px', textAlign: 'right' }}>
                Rs. {data.internalSale}
              </td>
            </tr>
            <tr
              style={{ padding: '0px 0px 4px', lineHeight: '30px' }}
              key={'diff'}
            >
              <td style={{ padding: '0px 10px 10px' }}>Difference</td>
              <td style={{ padding: '0px 10px 5px', textAlign: 'right' }}>
                Rs. {data.difference}
              </td>
            </tr>
          </tbody>
        </table>
        <p style={{ padding: '0px 10px 10px' }}>Comments: {data.comments}</p>
        <br />
        <br />
        <p
          style={{
            textAlign: 'center',
            borderTop: '1px solid black',
            padding: '10px 0px 0px',
          }}
        >
          Software provided by: ConcavePOS
        </p>
      </div>
    );
  }
}

export default ComponentToPrint;
