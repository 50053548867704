import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Paper,
  TextField,
  Button,
  Grid,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TableContainer,
  TablePagination,
} from '@material-ui/core';
import PageHeader from '../../Layout/PageTitle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { CTX } from '../../../Actions/Store';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import Controls from '../../Utilities/Controls/Controls';
import NumberFormatCustom from '../../Utilities/ReuseableCommponents/NumberFormatCustom';
import APIService from './InventoryService';
import { useHistory, useLocation } from 'react-router-dom';
import { getDateTime } from '../../../Common/constants';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import * as categoryService from '../Category/CategoryService';
import * as VendorService from '../Vendor/VendorService';
import * as ProductService from '../Products/ProductService';
import CircularLoader from '../../Utilities/ReuseableCommponents/CircularLoader';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  PaperMargin: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  searchInput: {
    width: '15%',
    paddingRight: '5px',
  },
  textField: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: 350,
  },
  table: {
    '& thead th': {
      fontWeight: '500',
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
    },
    '& tbody td': {
      fontWeight: '300',
    },
    '& tbody tr:hover': {
      backgroundColor: '#f9f9f9',
      cursor: 'pointer',
    },
  },
}));
const headCells = [
  { id: 'categoriesRef', label: 'Category', align: 'left' },
  { id: 'name', label: 'Product', align: 'left' },
  { id: 'barcode', label: 'Barcode', align: 'center' },
  { id: 'sku', label: 'SKU', align: 'center' },
  { id: 'unitOfPurchase', label: 'Purchase Unit', align: 'center' },
  { id: 'purchaseQty', label: 'Purchase Qty', align: 'center' },
  { id: 'unitPrice', label: 'Unit Price', align: 'center' },
  { id: 'totalPrice', label: 'Total Price', align: 'center' },
  { id: 'comments', label: 'Comments', align: 'left' },
  { id: 'action', label: 'Actions', align: 'center' },
];

function InventoryPurchaseDetail() {
  function isEditMode() {
    return location.state && location.state?.item;
  }
  const classes = useStyles();
  const [isLoaded, setIsLoaded] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const { allProducts } = React.useContext(CTX);
  const categoriesForSearch = allProducts.data.categories;
  const [inventoryItem, setInventoryItem] = React.useState(
    isEditMode() ? location.state.item : { items: [] }
  );
  const [latestInventory, setLatestInventory] = useState([]);
  const [categories, setCategories] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });
  const [page, setPage] = useState(0);
  const [netTotal, setNetTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [discountTotal, setDiscountTotal] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const pages = [5, 10, 25, 50];

  React.useEffect(() => {
    // split data of category for inventoryItem
    if (isEditMode())
      inventoryItem?.items.map((it) => {
        const fields = {
          actualCount: it.actualCount - it.expectedCount,
          oldExpected: it.expectedCount,
          expectedCount: it.expectedCount,
          searchingItems: [],
          barcode: it?.itemRef?.barcode,
          sku: it?.itemRef?.sku,
          unitOfPurchase: it?.itemRef?.unitOfPurchase,
          unitOfSale: it?.itemRef?.unitOfSale,
          conversionUnit: it?.itemRef?.conversionUnit,
          unitOfPurchaseForCalculation: it?.itemRef?.unitOfPurchase,
          conversionText: '',
        };
        return Object.assign(it, fields);
      });
    APIService.FetchUpdatedLatestPurchaseInventory(0, 50000, -1).then(
      (response) => {
        if (response.data) {
          setLatestInventory(response.data.data);
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function controlActualCounts(e, index) {
    let copyInventoryItem = { ...inventoryItem };
    let selectedItem = copyInventoryItem.items[index];
    copyInventoryItem.items[index].actualCount = e.target.value;
    if (selectedItem.unitOfPurchase !== selectedItem.unitOfSale) {
      if (
        selectedItem.unitOfPurchaseForCalculation ===
        selectedItem.unitOfPurchase
      ) {
        selectedItem.conversionText = `${selectedItem.actualCount} x ${selectedItem.conversionUnit}`;
      }
    }
    setInventoryItem(copyInventoryItem);
  }

  function controlPurchaseUnitPrice(e, index) {
    let copyInventoryItem = { ...inventoryItem };
    copyInventoryItem.items[index].purchaseUnitPrice = e.target.value;
    setInventoryItem(copyInventoryItem);
  }

  function controlUnitOfPurchaseForCalculation(e, index) {
    let copyInventoryItem = { ...inventoryItem };
    let selectedItem = copyInventoryItem.items[index];
    selectedItem.unitOfPurchaseForCalculation = e.target.value;
    if (selectedItem.unitOfPurchase !== selectedItem.unitOfSale) {
      if (
        selectedItem.unitOfPurchaseForCalculation === selectedItem.unitOfSale ||
        selectedItem.unitOfPurchaseForCalculation === '#'
      ) {
        selectedItem.conversionText = '';
      } else if (
        selectedItem.unitOfPurchaseForCalculation ===
        selectedItem.unitOfPurchase
      ) {
        selectedItem.conversionText = `${selectedItem.actualCount} x ${selectedItem.conversionUnit}`;
      }
    }
    setInventoryItem(copyInventoryItem);
  }

  function controlComments(e, index) {
    let copyInventoryItem = { ...inventoryItem };
    copyInventoryItem.items[index].comments = e.target.value;
    setInventoryItem(copyInventoryItem);
  }

  function saveInventory() {
    setIsLoaded(true);
    var finalItemList = [];
    var actualCountSum = 0;
    var expectedCountSum = 0;
    inventoryItem.items.forEach((item) => {
      let foundItem = latestInventory.find(
        (inv) => inv._id === item?.itemRef?._id
      );
      if (
        parseInt(item.actualCount) > 0 &&
        parseFloat(item.purchaseUnitPrice) > 0
      ) {
        if (
          item.unitOfSale !== item.unitOfPurchase &&
          item.unitOfPurchaseForCalculation === item.unitOfPurchase
        ) {
          item.purchasedQty = item.actualCount * foundItem.conversionUnit;
          item.unitPrice =
            (item.purchaseUnitPrice * item.actualCount) /
            (item.actualCount * item.conversionUnit);
          if (isEditMode()) {
            actualCountSum =
              actualCountSum +
              (parseInt(checkInventoryForUpdate(foundItem)) +
                parseInt(item.purchasedQty));
            expectedCountSum =
              expectedCountSum + parseInt(checkInventoryForUpdate(foundItem));
            finalItemList.push({
              itemRef: item?.itemRef?._id,
              expectedCount: parseInt(checkInventoryForUpdate(foundItem)),
              actualCount:
                parseInt(checkInventoryForUpdate(foundItem)) +
                parseInt(item.purchasedQty),
              comments: item.comments,
              inventoryType: item.inventoryType,
              purchaseUnitPrice: item.purchaseUnitPrice,
              vendorRef: item?.vendorRef?._id,
            });
          } else {
            actualCountSum =
              actualCountSum +
              (parseInt(checkInventory(foundItem)) +
                parseInt(item.purchasedQty));
            expectedCountSum =
              expectedCountSum + parseInt(checkInventory(foundItem));
            finalItemList.push({
              itemRef: item?.itemRef?._id,
              expectedCount: parseInt(checkInventory(foundItem)),
              actualCount:
                parseInt(checkInventory(foundItem)) +
                parseInt(item.purchasedQty),
              comments: item.comments,
              inventoryType: item.inventoryType,
              purchaseUnitPrice: item.purchaseUnitPrice,
              vendorRef: item?.vendorRef?._id,
            });
          }
        } else {
          if (isEditMode()) {
            actualCountSum =
              actualCountSum +
              (parseInt(checkInventoryForUpdate(foundItem)) +
                parseInt(item.actualCount));
            expectedCountSum =
              expectedCountSum + parseInt(checkInventoryForUpdate(foundItem));
            finalItemList.push({
              itemRef: item?.itemRef?._id,
              expectedCount: parseInt(checkInventoryForUpdate(foundItem)),
              actualCount:
                parseInt(checkInventoryForUpdate(foundItem)) +
                parseInt(item.actualCount),
              comments: item.comments,
              inventoryType: item.inventoryType,
              purchaseUnitPrice: item.purchaseUnitPrice,
              vendorRef: inventoryItem?.vendorRef?._id,
            });
          } else {
            actualCountSum =
              actualCountSum +
              (parseInt(checkInventory(foundItem)) +
                parseInt(item.actualCount));
            expectedCountSum =
              expectedCountSum + parseInt(checkInventory(foundItem));
            finalItemList.push({
              itemRef: item?.itemRef?._id,
              expectedCount: parseInt(checkInventory(foundItem)),
              actualCount:
                parseInt(checkInventory(foundItem)) +
                parseInt(item.actualCount),
              comments: item.comments,
              inventoryType: item.inventoryType,
              purchaseUnitPrice: item.purchaseUnitPrice,
              vendorRef: inventoryItem?.vendorRef?._id,
            });
          }
        }
      }
    });
    const inventoryPayload = {
      items: finalItemList,
      expectedCountTotal: expectedCountSum,
      actualCountTotal: actualCountSum,
      createdAt: getDateTime(),
      updatedAt: getDateTime(),
      billNumber: inventoryItem.billNumber,
      billDate: inventoryItem.billDate,
      vendorRef: inventoryItem?.vendorRef?._id,
      discount: inventoryItem.discount ? inventoryItem.discount : 0,
    };
    if (inventoryPayload.items.length) {
      if (isEditMode()) {
        APIService.updateInventory(inventoryPayload, inventoryItem._id).then(
          (response) => {
            if (response && response.data) {
              setIsLoaded(false);
              setNotify({
                isOpen: true,
                message: 'Inventory Successfully Updated',
                type: 'success',
              });
            }
          }
        );
      } else {
        APIService.createInventory(inventoryPayload).then((response) => {
          if (response && response.data) {
            setIsLoaded(false);
            setNotify({
              isOpen: true,
              message: 'Inventory Successfully Created',
              type: 'success',
            });
          }
        });
      }
      history.push({
        pathname: '/PurchaseInventory',
      });
    } else {
      setIsLoaded(false);
      setNotify({
        isOpen: true,
        message: 'Please, Add Atleast One Item With Data',
        type: 'error',
      });
    }
  }

  function checkInventory(obj) {
    if (obj && obj.Inventory.length > 0) {
      if (obj.Inventory[0].items.length > 0) {
        if (
          obj.Inventory[0].items[0].itemRef.toString() === obj._id.toString()
        ) {
          return obj.Inventory[0].items[0].actualCount;
        } else return 0;
      } else return 0;
    } else return 0;
  }

  function checkInventoryForUpdate(obj) {
    if (obj && obj.Inventory.length > 0) {
      if (obj.Inventory[0].items.length > 0) {
        if (
          obj.Inventory[0].items[0].itemRef.toString() === obj._id.toString()
        ) {
          return obj.Inventory[0].items[0].expectedCount;
        } else return 0;
      } else return 0;
    } else return 0;
  }

  React.useEffect(() => {
    (async () => {
      return categoryService.getAllCategoriesForDropDown().then((Response) => {
        return Response;
      });
    })().then((Response) => {
      if (Response.data) {
        var result = Response.data.data.map((dataArrayRow) => ({
          _id: dataArrayRow._id,
          name: dataArrayRow.name,
        }));
        setCategories(result);
        if (Response.data.count === 0) {
          setNotify({
            isOpen: true,
            message: 'No Categories To Load',
            type: 'error',
          });
        }
      }
    });

    // code for vendor dropdown
    (async () => {
      return VendorService.getAllVendorsForDropDown().then((Responce) => {
        return Responce;
      });
    })().then((Response) => {
      if (Response) {
        if (Response.data) {
          //Map data array with Generic List Object with Properties id,text
          //Dropdown only accepts list of Object with Properties id,text
          var vendorList = Response.data.data.map((dataArrayRow) => ({
            _id: dataArrayRow._id,
            name: dataArrayRow.name,
          }));
          setVendors(vendorList);
          if (Response.data.count === 0) {
            setNotify({
              isOpen: true,
              message: 'No Vendor To Load',
              type: 'error',
            });
          }
        }
      }
    });
  }, []);

  function deleteItemFromRow(e, index) {
    let copyInventoryItem = { ...inventoryItem };
    copyInventoryItem.items.splice(index, 1);
    setInventoryItem(copyInventoryItem);
  }

  function getCategoryItems(categoryId, index) {
    ProductService.getAllProducts(
      0,
      50000,
      -1,
      'name',
      `&categoryRef=${categoryId}`
    ).then((response) => {
      if (response && response.data) {
        let copyInventoryItem = { ...inventoryItem };
        copyInventoryItem.items[index].searchingItems =
          response.data.data || [];
        setInventoryItem(copyInventoryItem);
      }
    });
  }

  function fetchItemWithBarcode(barcode, index) {
    let foundItem = findByBarcode(barcode);
    let copyInventoryItem = { ...inventoryItem };
    let found = copyInventoryItem.items.find((itm) => itm.barcode === barcode);
    if (found) {
      setNotify({
        isOpen: true,
        message: 'Item Already Added',
        type: 'error',
      });
    } else {
      if (foundItem) {
        copyInventoryItem.items[index].categoryRef = {
          _id: foundItem?._Cid,
          name: foundItem?.category,
        };
        if (foundItem._Cid) getCategoryItems(foundItem._Cid, index);
        copyInventoryItem.items[index].itemRef = {
          _id: foundItem?._id,
          name: foundItem?.name,
        };
        copyInventoryItem.items[index].sku = foundItem.sku;
        copyInventoryItem.items[index].barcode = foundItem.barcode;
        copyInventoryItem.items[index].unitOfPurchase =
          foundItem.unitOfPurchase;
        copyInventoryItem.items[index].unitOfSale = foundItem.unitOfSale;
        setInventoryItem(copyInventoryItem);
      }
    }
  }

  function findByBarcode(Barcode) {
    var result = null;
    const obj = categoriesForSearch;
    for (const inobj of obj) {
      for (const innerobj of inobj.products) {
        if (innerobj.barcode === Barcode) {
          return innerobj;
        }
      }
    }
    return result;
  }

  function fetchItemWithSKU(sku, index) {
    let foundItem = findBySKU(sku);
    let copyInventoryItem = { ...inventoryItem };
    let found = copyInventoryItem.items.find((itm) => itm.sku === sku);
    if (found) {
      setNotify({
        isOpen: true,
        message: 'Item Already Added',
        type: 'error',
      });
    } else {
      if (foundItem) {
        copyInventoryItem.items[index].categoryRef = {
          _id: foundItem?._Cid,
          name: foundItem?.category,
        };
        if (foundItem._Cid) getCategoryItems(foundItem._Cid, index);
        copyInventoryItem.items[index].itemRef = {
          _id: foundItem?._id,
          name: foundItem?.name,
        };
        copyInventoryItem.items[index].sku = foundItem.sku;
        copyInventoryItem.items[index].barcode = foundItem.barcode;
        copyInventoryItem.items[index].unitOfPurchase =
          foundItem.unitOfPurchase;
        copyInventoryItem.items[index].unitOfSale = foundItem.unitOfSale;
        setInventoryItem(copyInventoryItem);
      }
    }
  }

  function findBySKU(sku) {
    var result = null;
    const obj = categoriesForSearch;
    for (const inobj of obj) {
      for (const innerobj of inobj.products) {
        if (innerobj.sku === sku) {
          return innerobj;
        }
      }
    }
    return result;
  }

  function handleSelectItem(id, index) {
    let copyInventoryItem = { ...inventoryItem };
    let selectedItem = copyInventoryItem?.items[index]?.searchingItems?.find(
      (i) => i._id === id
    );
    let found = copyInventoryItem.items.find((itm) => itm.itemRef === id);
    if (found) {
      setNotify({
        isOpen: true,
        message: 'Item Already Added',
        type: 'error',
      });
    } else {
      copyInventoryItem.items[index].sku = selectedItem.sku;
      copyInventoryItem.items[index].barcode = selectedItem.barcode;
      copyInventoryItem.items[index].unitOfPurchase =
        selectedItem.unitOfPurchase;
      copyInventoryItem.items[index].unitOfSale = selectedItem.unitOfSale;
      setInventoryItem(copyInventoryItem);
    }
  }
  useEffect(() => {
    let total = 0;
    for (let it of inventoryItem.items) {
      total += it.actualCount * it.purchaseUnitPrice;
    }
    let discount = (inventoryItem.discount / 100) * total || 0;
    setDiscountTotal(discount);
    setNetTotal(total - discount || 0);
    setGrandTotal(
      total.toLocaleString('en', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  }, []);

  function handleChangePage(event, newPage) {
    event.preventDefault();
    setPage(newPage);
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function addRowToTable() {
    setInventoryItem({
      ...inventoryItem,
      items: [
        ...inventoryItem.items,
        {
          categoryRef: {},
          itemRef: { categoriesRef: [] },
          actualCount: 0,
          comments: '',
          expectedCount: 0,
          inventoryType: 'purchase',
          purchaseUnitPrice: 0,
          vendorRef: inventoryItem.vendorRef,
          searchingItems: [],
          unitOfPurchase: '',
          unitOfSale: '',
          conversionText: '',
          conversionUnit: '',
          unitOfPurchaseForCalculation: '',
          billDate: '',
        },
      ],
    });
  }
  return (
    <>
      <PageHeader
        title="Purchase Inventory"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="purInvHeading"
      />

      <Paper elevation={6} square className={classes.PaperMargin}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            id="txtPurInvBillNumber"
            label="Bill No"
            className={classes.searchInput}
            style={{ marginBottom: 20 }}
            variant="outlined"
            value={inventoryItem.billNumber}
            onChange={(e) =>
              setInventoryItem({
                ...inventoryItem,
                billNumber: e.target.value,
              })
            }
            autoComplete="off"
          />
          <Controls.ControllableStateEditableDropDown
            name="VendorRef_id"
            label="Vendor"
            className={classes.searchInput}
            value={{
              _id: inventoryItem.vendorRef?._id,
              name: inventoryItem.vendorRef?.name,
            }}
            customWidth={180}
            onChange={(e, newValue) => {
              if (newValue !== null)
                setInventoryItem({
                  ...inventoryItem,
                  vendorRef: newValue,
                });
            }}
            data={vendors}
            autoComplete="off"
            id="ddPurInvVendor"
          />
          <TextField
            id="txtPurInvCreatedAt"
            label="Bill Date"
            type="date"
            className={classes.searchInput}
            variant="outlined"
            value={inventoryItem.billDate?.split('T')[0]}
            onChange={(e) =>
              setInventoryItem({
                ...inventoryItem,
                billDate: e.target.value,
              })
            }
            InputLabelProps={{
              shrink: true,
            }}
            autoComplete="off"
          />
          <TextField
            id="txtPurInvDiscount"
            label="Discount (%)"
            className={classes.searchInput}
            variant="outlined"
            value={inventoryItem.discount}
            onChange={(e) =>
              setInventoryItem({
                ...inventoryItem,
                discount: e.target.value,
              })
            }
            autoComplete="off"
          />
        </div>
        <CircularLoader isload={isLoaded} />
        <TableContainer>
          <Table
            id="purInvListTable"
            className={classes.table}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                {headCells.map((element) => (
                  <TableCell key={element.id} align={element.align}>
                    {element.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody id="purInvListTbody">
              {inventoryItem &&
                inventoryItem.items &&
                inventoryItem.items
                  .slice(
                    page ? page * rowsPerPage : 0,
                    page ? (page + 1) * rowsPerPage : rowsPerPage
                  )
                  .map((row, index) => (
                    <TableRow key={index} id={'purInvTrow' + index}>
                      <TableCell id={'purInvProdcat' + index} align="left">
                        {
                          <Controls.ControllableStateEditableDropDown
                            name="itemCategoryRef"
                            label=""
                            customWidth={150}
                            isNoneAllowed={false}
                            value={row.categoryRef}
                            onChange={(e, newValue) => {
                              if (newValue !== null) {
                                row.categoryRef = newValue;
                                getCategoryItems(newValue._id, index);
                              }
                            }}
                            data={categories}
                            id="ddPurInvVendor"
                          />
                        }
                      </TableCell>
                      <TableCell id={'purInvProdName' + index} align="left">
                        {
                          <Controls.ControllableStateEditableDropDown
                            name="itemName"
                            label=""
                            customWidth={150}
                            isNoneAllowed={false}
                            value={row.itemRef}
                            data={row.searchingItems || []}
                            id="ddPurInvVendor"
                            onChange={(e, newValue) => {
                              if (newValue !== null) {
                                row.itemRef = newValue;
                                handleSelectItem(newValue._id, index);
                              }
                            }}
                          />
                        }
                      </TableCell>
                      <TableCell
                        id={'purInvProdBarcode' + index}
                        align="center"
                      >
                        <TextField
                          name="itemBarcode"
                          variant="outlined"
                          label=""
                          value={row.barcode}
                          id="ddPurInvVendor"
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              fetchItemWithBarcode(e.target.value, index);
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell id={'purInvProdSKU' + index} align="center">
                        <TextField
                          name="itemSku"
                          variant="outlined"
                          label=""
                          value={row.sku}
                          data={vendors}
                          id="ddPurInvVendor"
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              fetchItemWithSKU(e.target.value, index);
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <Controls.Select
                          name="UnitOfPurchaseForCalculation"
                          fullWidth
                          value={row.unitOfPurchaseForCalculation}
                          options={ProductService.getUnitTypeCollection().filter(
                            (unit) =>
                              unit._id === row.unitOfPurchase ||
                              unit._id === row.unitOfSale
                          )}
                          onChange={(e) =>
                            controlUnitOfPurchaseForCalculation(e, index)
                          }
                          id={'ddPurInvUnitOfPurchase' + index}
                        />
                      </TableCell>
                      <TableCell align="center" width="10%">
                        <Controls.Input
                          id={'txtPurInvPurchasedQty' + index}
                          value={row.actualCount}
                          onChange={(e) => controlActualCounts(e, index)}
                          variant="outlined"
                          helperText={row.conversionText}
                          autoComplete="off"
                          style={{ textAlign: 'center' }}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <Controls.Input
                          id={'txtPurInvUnitPrice' + index}
                          onChange={(e) => controlPurchaseUnitPrice(e, index)}
                          value={row.purchaseUnitPrice}
                          variant="outlined"
                          helperText={row.conversionText}
                          autoComplete="off"
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                          style={{ textAlign: 'center' }}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          id={'txtPurInvTotalPrice' + index}
                          style={{ width: '70px', textAlign: 'center' }}
                          autoComplete="off"
                          variant="outlined"
                          disabled
                          value={row.purchaseUnitPrice * row.actualCount}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          id={'txtPurInvComments' + index}
                          label=""
                          autoComplete="off"
                          value={row.comments}
                          onChange={(e) => controlComments(e, index)}
                          variant="outlined"
                        />
                      </TableCell>
                      <TableCell align="left">
                        <Controls.ActionButton
                          color="secondary"
                          id={`btnDelCat${index}`}
                          name="btnDelete"
                          onClick={(e) => deleteItemFromRow(e, index)}
                        >
                          <HighlightOffIcon />
                        </Controls.ActionButton>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
            <TablePagination
              page={page}
              rowsPerPageOptions={pages}
              rowsPerPage={rowsPerPage}
              count={inventoryItem.items.length}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Table>
          {isEditMode() ? (
            <></>
          ) : (
            <Button
              style={{ float: 'right' }}
              variant="contained"
              color="primary"
              onClick={addRowToTable}
            >
              <AddIcon />
            </Button>
          )}
        </TableContainer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
          }}
        >
          <p style={{ fontWeight: 'bold', fontSize: '20', paddingRight: 100 }}>
            <table>
              <tr
                style={{
                  borderWidth: '1px',
                  borderColor: '#aaaaaa',
                  borderStyle: 'solid',
                }}
              >
                <td
                  style={{
                    borderWidth: '1px',
                    borderColor: '#aaaaaa',
                    borderStyle: 'solid',
                  }}
                >
                  Grand Total :
                </td>
                <td>{grandTotal}</td>
              </tr>
              <tr
                style={{
                  borderWidth: '1px',
                  borderColor: '#aaaaaa',
                  borderStyle: 'solid',
                }}
              >
                <td
                  style={{
                    borderWidth: '1px',
                    borderColor: '#aaaaaa',
                    borderStyle: 'solid',
                  }}
                >
                  Discount (Rs.) :{' '}
                </td>
                <td>
                  {discountTotal.toLocaleString('en', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
              </tr>
              <tr
                style={{
                  borderWidth: '1px',
                  borderColor: '#aaaaaa',
                  borderStyle: 'solid',
                }}
              >
                <td
                  style={{
                    borderWidth: '1px',
                    borderColor: '#aaaaaa',
                    borderStyle: 'solid',
                  }}
                >
                  Total Amount :{' '}
                </td>
                <td>
                  {netTotal.toLocaleString('en', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
              </tr>
            </table>
          </p>
        </div>
      </Paper>
      <Grid container>
        <Grid item sm={3}>
          <Button
            variant="contained"
            onClick={() => {
              history.push({
                pathname: '/PurchaseInventory',
              });
            }}
            id="btnCancelPurInv"
          >
            Cancel
          </Button>
        </Grid>
        <Grid item sm={6}></Grid>
        <Grid item sm={3} align="right">
          <Button
            className={classes.newButton}
            color="primary"
            variant="contained"
            onClick={saveInventory}
            id="btnSavePurInv"
          >
            Save
          </Button>
        </Grid>
      </Grid>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}

export default InventoryPurchaseDetail;
