import authHeader from '../../../Services/auth-header';
import { callAPI } from '../../../Services/ApiCaller';
import { API_VERSION } from '../../../Common/constants';

export async function getBillingInfo(
  page,
  rowsPerPage,
  sortDirection,
  sortColumnName,
  searchString
) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  let rowsFrom = page * rowsPerPage;

  let sortOrderdir = sortDirection === 'asc' ? 1 : -1;
  return callAPI(
    `api/${API_VERSION}/billing`,
    null,
    headerObject,
    'GET',
    `?$orderBy=${sortColumnName}&$orderDir=${sortOrderdir}&$skip=${rowsFrom}&$top=${rowsPerPage}${searchString}`
  ).then((Response) => {
    return Response;
  });
}

export async function getAllSetups() {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  return callAPI(
    `api/${API_VERSION}/setup`,
    null,
    headerObject,
    'GET',
    `?$top=50`
  ).then((Response) => {
    return Response;
  });
}

export async function insertSetup(payLoadObject) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  return callAPI(
    `api/${API_VERSION}/setup`,
    payLoadObject,
    headerObject,
    'POST',
    ``
  ).then((Response) => {
    return Response;
  });
}

export async function updateSetup(payLoadObject, id) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  return callAPI(
    `api/${API_VERSION}/setup`,
    payLoadObject,
    headerObject,
    'PUT',
    `/${id}`
  ).then((Response) => {
    return Response;
  });
}

export async function getSetupWithName(name) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  return callAPI(
    `api/${API_VERSION}/setup`,
    null,
    headerObject,
    'GET',
    `?name=${name}`
  ).then((Response) => {
    return Response;
  });
}

export async function initializeData() {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  return callAPI(
    `api/${API_VERSION}/setup`,
    null,
    headerObject,
    'GET',
    `/init`
  ).then((Response) => {
    return Response;
  });
}

export async function setCompanyLogo(img) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  var payLoad = {
    name: 'Company Logo',
    data: img,
  };
  return callAPI(
    `api/${API_VERSION}/setup`,
    payLoad,
    headerObject,
    'POST',
    ``
  ).then((Response) => {
    return Response;
  });
}

export async function getCompanyBillingHistoryByName(dbName) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  return callAPI(
    `api/${API_VERSION}/billing?companyName=${dbName}&populate=companyRef`,
    null,
    headerObject,
    'GET',
    ``
  ).then((Responce) => {
    return Responce;
  });
}

export async function getCompanyByName(name) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  return callAPI(
    `api/${API_VERSION}/companies`,
    null,
    headerObject,
    'GET',
    `?companyName=${name}`
  ).then((Responce) => {
    return Responce;
  });
}
