import React from 'react';
import { IconButton, ButtonGroup } from '@material-ui/core';
import {
  Clear as ClearIcon,
  Replay as ReplayIcon,
  HighlightOff as HighlightOffIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import { getFormattedDateTime as ISOdateFormatter } from '../../../../../Common/constants';
const HoldOrderModalBody = ({
  classes,
  modalStyle,
  handleClose3,
  holdOrder,
  HoldOrderReload,
  HoldOrderRemove,
  heldOrderTableRef,
}) => {
  return (
    <>
      <div style={modalStyle} className={classes.paper2}>
        <h2 id="simple-modal-title">Held Orders</h2>
        <IconButton
          style={{
            top: '0px',
            right: '0px',
            position: 'absolute',
          }}
          color="inherit"
          aria-label="menu"
          onClick={handleClose3}
          id="closeHoldOrderList"
        >
          <ClearIcon />
        </IconButton>
        <div style={{ height: '350px', overflowY: 'scroll' }}>
          <table
            style={{ width: '100%' }}
            ref={heldOrderTableRef}
            id="heldOrdersTable"
          >
            <thead>
              <tr>
                <th>S.No</th>
                <th style={{ textAlign: 'center' }}>Date</th>
                <th>Order Number</th>
                <th style={{ textAlign: 'center' }}>Actions</th>
              </tr>
            </thead>
            <tbody id="posHoldListTbody">
              {holdOrder.length > 0 ? (
                holdOrder.map(function (item, index) {
                  return (
                    <tr
                      id={'posHoldListTrow' + index}
                      key={index}
                      style={{ textAlign: 'center' }}
                      tabIndex={index}
                      className={classes.posHeldOTableRow}
                    >
                      <td>{index + 1}</td>
                      <td style={{ width: '40%' }}>
                        {ISOdateFormatter(new Date(item.Date).toISOString())}
                      </td>
                      <td>{item.HoldOrderNumber}</td>
                      <td>
                        <ButtonGroup
                          variant="outlined"
                          color="primary"
                          aria-label="contained primary button group"
                          style={{
                            marginRight: '5px',
                            marginLeft: 'auto',
                          }}
                        >
                          <IconButton
                            onClick={() => HoldOrderReload(index)}
                            id={'btnPOSRecallOrder' + index}
                          >
                            <ReplayIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => HoldOrderRemove(index)}
                            id={'btnPOSDelOrder' + index}
                          >
                            <HighlightOffIcon />
                          </IconButton>
                        </ButtonGroup>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td>Hold Order List is empty</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
HoldOrderModalBody.propTypes = {
  classes: PropTypes.any,
  modalStyle: PropTypes.any,
  handleClose3: PropTypes.any,
  holdOrder: PropTypes.any,
  HoldOrderReload: PropTypes.any,
  HoldOrderRemove: PropTypes.any,
  heldOrderTableRef: PropTypes.any,
};
export default HoldOrderModalBody;
