import React, { useEffect, useState } from 'react';
import PageTitle from '../../Layout/PageTitle';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import {
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  TextField,
  Grid,
} from '@material-ui/core';
import Controls from '../../Utilities/Controls/Controls';
import SearchIcon from '@material-ui/icons/Search';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import { useHistory } from 'react-router-dom';
import useTableServerSide from '../../Utilities/ReuseableCommponents/useTableServerSide';
import useStyles from '../../../Style/AppStylesAdmin';
import {
  generateUserBill,
  getAllCompanies,
  getCompanies,
  updateCompanyBilling,
} from './BillingService';
import CircularLoader from '../../Utilities/ReuseableCommponents/CircularLoader';

const headCells = [
  { id: 'customerName', label: 'Customer Name', align: 'left' },
  { id: 'Mode', label: 'Version', align: 'left' },
  { id: 'action', label: 'Action', disableSorting: true, align: 'right' },
];
const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const featureList = [
  { name: 'EasyPos' },
  { name: 'Standered' },
  { name: 'Premium' },
];
export default function CompanyBillingControl() {
  const classes = useStyles();
  const history = useHistory();
  const [recordsCount] = useState(0);
  const [name, setName] = useState('');
  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [version, setVersion] = useState('');

  function getRecords() {
    try {
      setIsLoading(true);
      getAllCompanies()
        .then((companies) => {
          if (companies.data && companies.data.data) {
            setRecords(companies.data.data);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(error);
        });
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }
  function handleSearch() {
    let searchString = '';
    if (name) {
      searchString += `&companyName=${name}`;
    }
    if (version) {
      searchString += `&version=${version}`;
    }

    getCompanies(0, 5000, 1, '', searchString, 'companyRef')
      .then((billing) => {
        console.log(billing);
        if (billing && billing.data && billing.data.data) {
          setRecords(billing.data.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const { TblContainer, TblHead, TblPagination } = useTableServerSide(
    records,
    recordsCount,
    headCells,
    getRecords
  );

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  function generateBill(item) {
    setIsLoading(true);
    let date = new Date(Date.now());
    let billTemplate = {
      companyRef: item._id,
      companyName: item.companyName,
      month: monthNames[date.getMonth()],
      status: 'pending',
      billNumber: item.companyName + monthNames[date.getMonth()],
      monthlyCharges: item.isFirstMonth
        ? item.firstMonthBill
        : item?.monthlyCharges,
      year: date.getFullYear(),
    };
    generateUserBill(billTemplate)
      .then((bill) => {
        if (bill.data) {
          setIsLoading(false);
          setNotify({
            isOpen: true,
            message: 'Successfully Generated',
            type: 'success',
          });
        } else {
          setIsLoading(false);
          setNotify({
            isOpen: true,
            message: 'Unable To Process Request',
            type: 'error',
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setNotify({
          isOpen: true,
          message: 'Unable To Process Request',
          type: 'error',
        });
        console.error(error);
      });
    const updateBillingDetailsObj = {
      _id: item._id,
      version: item?.version,
      firstMonthBill: item?.firstMonthBill,
      monthlyCharges: item?.monthlyCharges,
      companyName: item?.companyName,
      data: [
        { name: 'barcode', checked: item?.data[0]?.checked },
        { name: 'comission', checked: item?.companyRef?.data[1]?.checked },
        { name: 'companyRole', checked: item?.companyRef?.data[2]?.checked },
        { name: 'casiher', checked: item?.companyRef?.data[3]?.checked },
      ],
      isFirstMonth: false,
    };
    updateCompanyBilling(updateBillingDetailsObj)
      .then((bill) => {
        console.log(bill);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    try {
      setIsLoading(true);
      getAllCompanies()
        .then((companies) => {
          console.log(companies.data.data);
          if (companies.data && companies.data.data) {
            setRecords(companies.data.data);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(error);
        });
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }, []);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  return (
    <>
      <PageTitle
        title="Company Billing Management"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="billingReportHeading"
      />
      <Paper elevation={6} square className={classes.PaperMargin}>
        <Toolbar disableGutters>
          <Grid container>
            <Grid item xs={12} sm={3} md={3} lg={2}>
              <TextField
                id="txtSearchCat"
                label="Name"
                className={classes.searchInput}
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={2}>
              <div className={classes.searchDropdown}>
                <Controls.EditableDropDown
                  name="version_id"
                  label="Version"
                  data={featureList}
                  options={featureList}
                  onChange={(event, selectedValue) => {
                    if (selectedValue && selectedValue.name) {
                      setVersion(selectedValue.name);
                    } else {
                      setVersion('');
                    }
                  }}
                  id="ddSearchVersion"
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={2}>
              <Controls.Button
                text="Search"
                variant="contained"
                color="primary"
                id="btnCatSearch"
                className={classes.searchButton}
                startIcon={<SearchIcon />}
                onClick={handleSearch}
              />
            </Grid>
          </Grid>
        </Toolbar>
        <CircularLoader isload={isLoading} />

        <TblContainer>
          <TblHead />
          <TableBody id="catListTbody">
            {records.map((item, index) => (
              <TableRow
                key={item._id}
                id={`catListTrow${index}`}
                name="catListTrow"
                onClick={(e) => {
                  if (!e.target.name) {
                    history.push({
                      pathname: '/CompanyBillingControlDetail',
                      state: {
                        pageHeaderTitile: 'View/Edit Billing Management',
                        recordForEdit: item,
                      },
                    });
                  }
                }}
              >
                <TableCell id={`billName${index}`}>
                  {item.companyName}
                </TableCell>
                <TableCell id={`billVersion${index}`}>{item.version}</TableCell>
                <TableCell align="right">
                  <Controls.Button
                    text={'Generate Bill'}
                    onClick={(event) => {
                      event.stopPropagation();
                      generateBill(item);
                    }}
                  ></Controls.Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        id_Yes="delCat"
        id_No="dontDelCat"
      />
    </>
  );
}
