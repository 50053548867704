import authHeader from '../../../Services/auth-header';
import { callAPI } from '../../../Services/ApiCaller';
import { API_VERSION } from '../../../Common/constants';

export async function getAllProductMix(page, rowsPerPage, searchString) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  let rowsFrom = page * rowsPerPage;

  return callAPI(
    `api/${API_VERSION}/sales/productMixReport`,
    null,
    headerObject,
    'GET',
    `?$skip=${rowsFrom}&$top=${rowsPerPage}${searchString}`
  ).then((Response) => {
    return Response;
  });
}
