import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  AppBar,
  Toolbar,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { PowerSettingsNew as PowerSettingsNewIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import Logo from '../../../../Assets/Images/ConcaveLogo.png';
import { Offline, Online } from 'react-detect-offline';
import SyncIcon from '@material-ui/icons/Sync';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
const Header = ({
  classes,
  UserRole,
  handleOpen4,
  shiftButton,
  handleOpen5,
  syncTime,
  InvoiceSync,
  SIGNOUT,
  CompanyName,
  IsOdms,
}) => {
  const history = useHistory();
  const handleHeaderBack = () => history.push('/Orders');

  return (
    <AppBar position="static">
      <Toolbar
        style={{
          backgroundColor: 'white',
          borderBottom: '3px solid slategrey',
        }}
      >
        {UserRole !== 'cashier' && (
          <IconButton
            variant="contained"
            color="primary"
            aria-label="contained Secondary button"
            edge="start"
            onClick={UserRole === 'agent' ? handleHeaderBack : history.goBack}
            id="btnFoodBack"
          >
            <ArrowBackIosRoundedIcon />
          </IconButton>
        )}
        <img
          variant="square"
          alt="Cindy Baker"
          src={Logo}
          style={{ height: '40px' }}
        />
        <h2 id="tyFoodCompanyName" className={classes.companyNameCSS}>
          {CompanyName}
        </h2>

        {ShowStartEndShiftButton(IsOdms, handleOpen4, shiftButton, handleOpen5)}

        <Online>
          <Tooltip title="Online">
            <div>
              <span id="statusFoodOnline" className="indicator online"></span>
            </div>
          </Tooltip>
        </Online>
        <Offline>
          <Tooltip title="Offline">
            <div>
              <span id="statusFoodOffline" className="indicator offline"></span>
            </div>
          </Tooltip>
        </Offline>

        <Tooltip title={syncTime}>
          <IconButton
            edge="start"
            style={{
              color: 'blue',
              marginLeft: '5px',
            }}
            color="inherit"
            aria-label="menu"
            onClick={InvoiceSync}
            id="btnFoodManualSync"
          >
            <SyncIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Sign Out">
          <IconButton
            onClick={SIGNOUT}
            edge="start"
            style={{
              color: 'white',
            }}
            color="inherit"
            aria-label="menu"
            id="btnFoodSignOut"
          >
            <PowerSettingsNewIcon fontSize="small" style={{ color: 'red' }} />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
};
Header.propTypes = {
  classes: PropTypes.any,
  UserRole: PropTypes.any,
  handleOpen4: PropTypes.any,
  shiftButton: PropTypes.any,
  handleOpen5: PropTypes.any,
  syncTime: PropTypes.any,
  InvoiceSync: PropTypes.any,
  SIGNOUT: PropTypes.any,
  CompanyName: PropTypes.any,
  IsOdms: PropTypes.any,
};
function ShowStartEndShiftButton(
  isOdms,
  handleOpen4,
  shiftButton,
  handleOpen5
) {
  if (!isOdms) {
    return (
      <>
        <Button
          onClick={handleOpen4}
          variant="contained"
          color="primary"
          aria-label="contained Secondary button"
          edge="start"
          style={{
            marginRight: '10px',
            display: shiftButton ? 'none' : 'block',
          }}
          id="btnFoodStartShift"
        >
          Start Shift
        </Button>
        <Button
          onClick={handleOpen5}
          variant="contained"
          color="primary"
          aria-label="contained Secondary button"
          edge="start"
          style={{
            marginRight: '10px',
            display: shiftButton ? 'block' : 'none',
          }}
          id="btnFoodEndShift"
        >
          End Shift
        </Button>
      </>
    );
  }
}
export default Header;
