import React from 'react';
import {
  Button,
  Paper,
  InputAdornment,
  TextField,
  IconButton,
  Badge,
} from '@material-ui/core';
import {
  FilterListSharp as FilterListSharpIcon,
  Clear as ClearIcon,
  Reorder as ReorderIcon,
  PanTool as PanToolIcon,
  Repeat as RepeatIcon,
  RemoveShoppingCartRounded as RemoveShoppingCartRoundedIcon,
  PersonAddDisabledRounded as PersonAddDisabledRoundedIcon,
  PersonAddRounded as PersonAddRoundedIcon,
  ShoppingCart as ShoppingCartIcon,
  Info as InfoIcon,
  Money,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
const TopActionBar = ({
  classes,
  handleChange9,
  onChange,
  recordSets,
  handleClickClearText,
  suggestionsListComponent,
  // systemType,
  // handleSystemType,
  handleOpen3,
  holdOrderFromMainBody,
  modalOpenSalesReturn,
  clearProductFromMainBody,
  isCustomerAttached,
  totalItems,
  handleCustomerremove,
  customerinfo,
  openCustomerModal,
  openExpenseModal,
  searchFocusRef,
  handleOpenShortcutInfo,
  holdOrderRef,
  removeCustRef,
  isShortcutEnable,
}) => {
  const customerName = customerinfo ? customerinfo.name : '';
  const customerNumber = customerinfo ? customerinfo.contactNumber : '';
  let hideFeatures = JSON.parse(localStorage.getItem('_HideFeature')) || [];
  let expenseHideFeature = hideFeatures.find((hf) => hf.name === 'Expense');
  const isExpense =
    expenseHideFeature && expenseHideFeature.status ? true : false;
  return (
    <Paper className={classes.paper5} elevation={0} square>
      <div
        className={classes.flex}
        style={{ flexWrap: 'wrap', paddingRight: '60px' }}
      >
        <IconButton
          color="primary"
          aria-label="category list"
          id="btnOpenProdCat"
          component="span"
          size="medium"
          onClick={handleChange9}
          style={{ marginTop: '10px' }}
        >
          <FilterListSharpIcon />
        </IconButton>
        <TextField
          id="txtPOSSearch"
          style={{ width: '280px', height: '48px', marginTop: '10px' }}
          size="small"
          inputRef={searchFocusRef}
          label={`Search ${isShortcutEnable.status ? '(Shift + F)' : ''}`}
          variant="outlined"
          onChange={onChange}
          value={recordSets.userInput}
          autoComplete="off"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {recordSets.userInput.length > 0 ? (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickClearText}
                  >
                    <ClearIcon />
                  </IconButton>
                ) : (
                  <></>
                )}
              </InputAdornment>
            ),
          }}
        />
        {suggestionsListComponent}
        {/* <ToggleButtonGroup
          value={systemType}
          exclusive
          onChange={handleSystemType}
          aria-label="system type group"
          style={{
            marginLeft: '5px',
            backgroundColor: '#1976d2',
            height: '48px',
            marginTop: '10px',
          }}
        >
          <ToggleButton
            id="btnTypePOS"
            style={{
              color: '#FFFFFF',
            }}
            value="pos"
            aria-label="pos"
          >
            POS
          </ToggleButton>
          <ToggleButton
            id="btnTypeOM"
            style={{
              color: '#FFFFFF',
            }}
            value="ol"
            aria-label="ol"
          >
            OM
          </ToggleButton>
        </ToggleButtonGroup> */}
        <Button
          onClick={handleOpen3}
          variant="contained"
          color="primary"
          aria-label="contained primary button group"
          style={{ marginLeft: '5px', height: '48px', marginTop: '10px' }}
          id="btnPOSHoldOrderList"
        >
          <ReorderIcon />
          <span className={classes.displayDesktop}>
            &nbsp;&nbsp;&nbsp;HELD ORDERS
            <br />
            {isShortcutEnable.status && '(Shift + G)'}
          </span>
        </Button>
        <Button
          onClick={() => {
            console.log('in on click hold=====');
            holdOrderFromMainBody();
          }}
          variant="contained"
          aria-label="contained primary button group"
          style={{
            marginLeft: '5px',
            height: '48px',
            marginTop: '10px',
            backgroundColor: '#EB9640',
            color: '#FFFFFF',
          }}
          id="btnPOSHoldOrder"
          ref={holdOrderRef}
        >
          <PanToolIcon />
          <span className={classes.displayDesktop}>
            &nbsp;&nbsp;&nbsp;HOLD ORDER
            <br />
            {isShortcutEnable.status && '(Shift + H)'}
          </span>
        </Button>
        <Button
          onClick={modalOpenSalesReturn}
          variant="contained"
          color="primary"
          aria-label="contained primary button group"
          style={{ marginLeft: '5px', height: '48px', marginTop: '10px' }}
          id="btnPOSalesReturn"
        >
          <RepeatIcon />
          <span className={classes.displayDesktop}>
            &nbsp;&nbsp;&nbsp;SALES RETURN
            <br />
            {isShortcutEnable.status && '(Shift + R)'}
          </span>
        </Button>

        <Button
          onClick={() => clearProductFromMainBody()}
          variant="contained"
          color="primary"
          aria-label="contained primary button group"
          style={{ marginLeft: '5px', height: '48px', marginTop: '10px' }}
          id="btnPOSClear"
        >
          <RemoveShoppingCartRoundedIcon />
          <span className={classes.displayDesktop}>
            &nbsp;&nbsp;&nbsp;CLEAR CART
            <br />
            {isShortcutEnable.status && '(Shift + C)'}
          </span>
        </Button>
        {isCustomerAttached ? (
          <>
            <Button
              variant="contained"
              color="primary"
              style={{
                marginLeft: '5px',
                height: '48px',
                marginTop: '10px',
              }}
              ref={removeCustRef}
              onClick={handleCustomerremove}
              id="btnPOSRemoveCustomer"
            >
              <PersonAddDisabledRoundedIcon />
              <span className={classes.displayDesktop}>
                &nbsp;&nbsp;&nbsp;REMOVE
                <br />
                {isShortcutEnable.status && '(Shift + V)'}
              </span>
            </Button>
            <p
              style={{
                marginLeft: '5px',
                textAlign: 'left',
                height: '48px',
                marginTop: '10px',
              }}
              className={classes.RemoveMargin}
            >
              <span style={{ fontWeight: '700' }}>{customerName}</span>
              <br></br>
              {customerNumber}
            </p>
          </>
        ) : (
          <Button
            variant="contained"
            color="primary"
            style={{
              marginLeft: '5px',
              height: '48px',
              marginTop: '10px',
            }}
            onClick={openCustomerModal}
            aria-label="contained primary button group"
            id="btnPOSCustomer"
          >
            <PersonAddRoundedIcon />
            <span className={classes.displayDesktop}>
              &nbsp;&nbsp;&nbsp;CUSTOMER
              <br />
              {isShortcutEnable.status && '(Shift + W)'}
            </span>
          </Button>
        )}
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          size="medium"
          style={{
            marginLeft: '5px',
            marginTop: '10px',
            right: '15px',
            position: 'absolute',
            cursor: 'context-menu',
          }}
        >
          <Badge badgeContent={totalItems} color="primary">
            <ShoppingCartIcon />
          </Badge>
        </IconButton>
        {isShortcutEnable.status && (
          <Button
            variant="outlined"
            color="primary"
            style={{
              marginLeft: '5px',
              marginTop: '10px',
              right: '60px',
              position: 'absolute',
            }}
            onClick={handleOpenShortcutInfo}
            id="btnPOSCustomer"
          >
            <InfoIcon />
            <span className={classes.displayDesktop}>
              &nbsp;&nbsp;&nbsp;Shortcuts
              <br />
              {isShortcutEnable.status && '(F2)'}
            </span>
          </Button>
        )}
        {isExpense && (
          <Button
            variant="contained"
            color="primary"
            style={{
              marginLeft: '5px',
              height: '48px',
              marginTop: '10px',
            }}
            onClick={openExpenseModal}
            aria-label="contained primary button group"
            id="btnPOSCustomer"
          >
            <Money />
            <span className={classes.displayDesktop}>
              &nbsp;&nbsp;&nbsp;ADD EXPENSE
              <br />
              {isShortcutEnable.status && '(Shift + N)'}
            </span>
          </Button>
        )}
      </div>
    </Paper>
  );
};
TopActionBar.propTypes = {
  classes: PropTypes.any,
  handleChange9: PropTypes.any,
  onChange: PropTypes.any,
  recordSets: PropTypes.any,
  handleClickClearText: PropTypes.any,
  suggestionsListComponent: PropTypes.any,
  systemType: PropTypes.any,
  handleSystemType: PropTypes.any,
  handleOpen3: PropTypes.any,
  holdOrderFromMainBody: PropTypes.any,
  modalOpenSalesReturn: PropTypes.any,
  clearProductFromMainBody: PropTypes.any,
  isCustomerAttached: PropTypes.any,
  totalItems: PropTypes.any,
  handleCustomerremove: PropTypes.any,
  customerinfo: PropTypes.any,
  openCustomerModal: PropTypes.any,
  searchFocusRef: PropTypes.any,
  holdOrderRef: PropTypes.any,
  removeCustRef: PropTypes.any,
  isShortcutEnable: PropTypes.any,
  openExpenseModal: PropTypes.any,
  handleOpenShortcutInfo: PropTypes.any,
};
export default TopActionBar;
