import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 0,
    margin: theme.spacing(0.5),
    padding: '5px !important',
    borderRadius: '50px',
  },
  secondary: {
    backgroundColor: 'transparent',
    '& .MuiButton-label': {
      color: '#fc4800',
    },
    '&:hover': {
      backgroundColor: '#fc4800',
      color: '#ffffff',
    },
    '& .MuiButton-label:hover': {
      color: '#ffffff',
    },
  },
  primary: {
    backgroundColor: theme.palette.primary.light,
    '& .MuiButton-label': {
      color: theme.palette.primary.main,
    },
  },
}));

function ActionButton(props) {
  const { color, children, onClick, ...other } = props;
  const classes = useStyles();

  return (
    <Button
      className={`${classes.root} ${classes[color]}`}
      onClick={onClick}
      {...other}
    >
      {children}
    </Button>
  );
}

ActionButton.propTypes = {
  color: PropTypes.any,
  children: PropTypes.any,
  onClick: PropTypes.any,
};
export default ActionButton;
