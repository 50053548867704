import React from 'react';
import Store from '../../../Actions/Store';
import InventoryPurchaseDetail from './InventoryPurchaseDetail';
export default function InventoryBase() {
  return (
    <div>
      <Store>
        <InventoryPurchaseDetail />
      </Store>
    </div>
  );
}
