import React from 'react';
import { Button, Box } from '@material-ui/core';
import PropTypes from 'prop-types';

const SaveOrderButton = ({ classes, totalItems, bookCallCenterOrder }) => {
  return (
    <Box m={2}>
      <Button
        variant="contained"
        className={classes.btnCheckout}
        size="large"
        onClick={() => {
          bookCallCenterOrder();
        }}
        id="btnFoodCartSaveOrder"
        disabled={totalItems <= 0 ? true : false}
      >
        PLACE ORDER
      </Button>
    </Box>
  );
};
SaveOrderButton.propTypes = {
  classes: PropTypes.any,
  totalItems: PropTypes.any,
  bookCallCenterOrder: PropTypes.any,
};
export default SaveOrderButton;
