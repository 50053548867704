import authHeader from '../../../Services/auth-header';
import { callAPI } from '../../../Services/ApiCaller';
import { API_VERSION } from '../../../Common/constants';

export async function getAllBranches(
  page,
  rowsPerPage,
  sortDirection,
  sortColumnName,
  searchString
) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  let rowsFrom = page * rowsPerPage;

  let sortOrderdir = sortDirection === 'asc' ? 1 : -1;
  return callAPI(
    `api/${API_VERSION}/Branches`,
    null,
    headerObject,
    'GET',
    `?populate=cityRef&$orderBy=${sortColumnName}&$orderDir=${sortOrderdir}&$skip=${rowsFrom}&$top=${rowsPerPage}${searchString}`
  ).then((Responce) => {
    return Responce;
  });
}

export async function getAllBranchesForDropDown(searchString) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  return callAPI(
    `api/${API_VERSION}/Branches`,
    null,
    headerObject,
    'GET',
    `?$orderBy=name&$orderDir=1&$top=50000&${searchString}`
  ).then((Responce) => {
    return Responce;
  });
}

export async function insertBranch(Branch) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  let BranchData = {
    name: Branch.name,
    status: 'active',
    cityRef: Branch.citiesRef_id,
  };

  return callAPI(
    `api/${API_VERSION}/Branches`,
    BranchData,
    headerObject,
    'POST',
    ''
  ).then((Responce) => {
    return Responce;
  });
}

export async function deleteBranch(_id) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  return callAPI(
    `api/${API_VERSION}/Branches/`,
    null,
    headerObject,
    'DELETE',
    _id
  ).then((Responce) => {
    return Responce;
  });
}

export async function updateBranch(Branch) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  var BranchUpdate = {
    name: Branch.name,
    status: 'active',
    cityRef: Branch.citiesRef_id,
  };

  return callAPI(
    `api/${API_VERSION}/Branches`,
    BranchUpdate,
    headerObject,
    'PATCH',
    `/${Branch._id}`
  ).then((Responce) => {
    return Responce;
  });
}

export async function patchBranch(id, BranchObject) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  return callAPI(
    `api/${API_VERSION}/Branches`,
    BranchObject,
    headerObject,
    'PATCH',
    `/${id}`
  ).then((Responce) => {
    return Responce;
  });
}
