import React from 'react';
import { Modal } from '@material-ui/core';
import PropTypes from 'prop-types';
import ExpenseModalBody from './ExpenseModalBody';
const ExpenseModal = (props) => {
  return (
    <div>
      <Modal
        open={props.openExpense}
        onClose={props.handleCloseExpenseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '10px',
          borderColor: 'transparent',
        }}
      >
        <ExpenseModalBody {...props} />
      </Modal>
    </div>
  );
};
ExpenseModal.propTypes = {
  openExpense: PropTypes.any,
  handleCloseExpenseModal: PropTypes.any,
};
export default ExpenseModal;
