import React, { useEffect, useState } from 'react';
import PageHeader from '../../Layout/PageTitle';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import { Paper, Toolbar, TextField, Grid } from '@material-ui/core';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import * as userService from './UserService';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import Controls from '../../Utilities/Controls/Controls';
import { GetTokenValue } from '../../../Common/constants';
import CircularLoader from '../../Utilities/ReuseableCommponents/CircularLoader';
import useStyles from '../../../Style/AppStylesAdmin';
import GenericTable from '../../Utilities/ReuseableCommponents/TableReport/GenericTable';
import activityMonitoring from '../../../Common/functions';

const headCells = [
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'email', label: 'Email', align: 'left' },
  { id: 'role', label: 'Role', align: 'left' },
];

let apiResponseDto = {
  data: [],
  error: null,
  loading: false,
};

export default function User() {
  const classes = useStyles();
  const history = useHistory();
  const [recordForEdit] = useState(null);
  const [records, setRecords] = useState([]);
  const [recordsCount, setRecordsCount] = useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [filterName, setFilterName] = useState('');
  const [filterRole, setFilterRole] = useState('');
  const rowOnClick = {
    path: '/UserDetails',
    header: 'Edit User',
    property: ['recordForEdit'],
  };
  function getRecords(page, rowsPerPage, sortDirection, sortColumnName) {
    let data = (async () => {
      setIsLoading(true);
      apiResponseDto = await userService
        .GetAllUsers(
          page,
          rowsPerPage,
          sortDirection,
          sortColumnName,
          filterName,
          filterRole
        )
        .then((Response) => {
          return Response;
        });
      return apiResponseDto;
    })();
    data.then((Response) => {
      setIsLoading(false);
      if (Response.data) {
        setRecords(Response.data.data);
        setRecordsCount(Response.data.count);
      } else {
        console.error('Error While Fetching Data: ', Response.error);
      }
    });
  }

  function getFilteredRecords(e) {
    e.preventDefault();
    getRecords(0, 5, 1, 'createdAt');
  }

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });
  useEffect(() => {
    activityMonitoring('Users');
  }, []);
  return (
    <>
      <PageHeader
        title="Users"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="userListHeading"
      />

      <Paper elevation={6} square className={classes.PaperMargin}>
        <Toolbar disableGutters>
          <form
            onSubmit={getFilteredRecords}
            style={{ width: '100%', display: 'flex', alignItems: 'center' }}
          >
            <Grid container>
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <TextField
                  className={classes.searchInput}
                  name="name"
                  label="Name"
                  id="txtSearchUserName"
                  variant="outlined"
                  onChange={(e) => {
                    setFilterName(e.target.value);
                  }}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <div className={classes.searchDropdown}>
                  <Controls.EditableDropDown
                    name="role"
                    label="Role"
                    data={userService.getRole(GetTokenValue('role'))}
                    id="ddSearchUserRole"
                    onChange={(e, newValue) => {
                      setFilterRole(newValue ? newValue.nameValue : '');
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <Controls.Button
                  text="Search"
                  variant="contained"
                  id="btnSearchUser"
                  color="primary"
                  type="submit"
                  className={classes.searchButton}
                  startIcon={<SearchIcon />}
                ></Controls.Button>
              </Grid>
              {localStorage.getItem('role') === 'superadmin' ? (
                <Grid item xs={12} sm={12} md={3} lg={6}>
                  <Controls.Button
                    text="Add New"
                    variant="contained"
                    color="primary"
                    id="btnAddUser"
                    startIcon={<AddIcon />}
                    className={classes.newButton}
                    onClick={() => {
                      history.push({
                        pathname: '/UserDetails',
                        state: {
                          pageHeaderTitile: 'Add User',
                          recordForEdit,
                        },
                      });
                    }}
                  />
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </form>
        </Toolbar>
        <CircularLoader isload={isLoading} />

        <GenericTable
          data={records}
          TableId={'usersTable'}
          TableTitle={'User Table'}
          cellData={[
            { name: 'name', type: 'string' },
            { name: 'email', type: 'string' },
            { name: 'role', type: 'string' },
          ]}
          headCells={headCells}
          recordsCount={recordsCount}
          getRecordsFn={getRecords}
          rowOnClick={rowOnClick}
          isLoading={isLoading}
        />
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
