import React from 'react';
import ReactToPrint from 'react-to-print';
import ReceiptFormatReport from '../../Utilities/ReuseableCommponents/Report/ReceiptFormatReport';

class PrintShiftSales extends React.Component {
  render() {
    return (
      <div>
        <ReactToPrint
          trigger={() => (
            <div style={{ display: 'none' }}>
              <button id="print-button">Print</button>
            </div>
          )}
          content={() => React.componentRef}
        />
        <div style={{ display: 'none' }}>
          <ReceiptFormatReport ref={(el) => (React.componentRef = el)} />
        </div>
      </div>
    );
  }
}

export default PrintShiftSales;
