import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PageTitle from '../../Layout/PageTitle';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import { makeStyles, Paper, Toolbar, TextField, Grid } from '@material-ui/core';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import * as productService from '../Products/ProductService';
import * as setupService from '../Setups/SetupService';
import CircularLoader from '../../Utilities/ReuseableCommponents/CircularLoader';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Controls from '../../Utilities/Controls/Controls';
import PrintIcon from '@material-ui/icons/Print';
import './print-barcode.module.css';
import PrintBarcodeHelper from './PrintBarcodeHelper';
import GenericTable from '../../Utilities/ReuseableCommponents/TableReport/GenericTable';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import activityMonitoring from '../../../Common/functions';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    padding: theme.spacing(3),
  },
  newButton: {
    float: 'right',
    marginLeft: 0,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      float: 'none',
    },
  },
  searchInput: {
    width: '100%',
    paddingRight: '5px',
    margin: '8px 0',
  },

  searchButton: {
    marginLeft: 0,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  PaperMargin: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
    margin: '0 8px 16px 8px',
  },

  clearItemIcon: {
    color: '#FF0000',
  },
  inputRoot: {
    width: '300px',
    height: '39px',
    marginRight: '6px',
    margin: '5px',
  },
  input: {
    padding: '0px!important',
    paddingLeft: '10px!important',
  },
  table: {
    marginTop: theme.spacing(1),
    '& thead th': {
      fontWeight: '500',
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
    },
    '& tbody td': {
      fontWeight: '300',
    },
    '& tbody tr:hover': {
      backgroundColor: '#f9f9f9',
      cursor: 'pointer',
    },
  },
}));
const headCells = [
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'category', label: 'Category', align: 'left' },
  { id: 'barcode', label: 'Barcode', align: 'left', type: 'float' },
  { id: 'barcode_quantity', label: 'Barcode Quantity', align: 'left' },
  { id: 'actions', label: 'Actions', type: 'remove', disableSorting: true },
];

export default function PrintBarcode() {
  const classes = useStyles();
  const [productName] = useState('');
  const [productList, setProductList] = useState([]);
  const [selectedProductList, setSelectedProductList] = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });
  const [values, setValues] = useState([]);

  useEffect(() => {
    getRecords(0, 50000, 1, 'updatedAt');
    getCustomizeBarcodeSetting();

    // Component will unmount and it will run this function
    return () => localStorage.removeItem('barcode-setting');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getRecords(page, rowsPerPage, sortDirection, sortColumnName) {
    let searchString = '';
    setIsLoading(true);

    if (productName) {
      searchString += `&itemName=${productName}`;
    }

    productService
      .getAllProducts(
        page,
        rowsPerPage,
        sortDirection,
        sortColumnName,
        searchString
      )
      .then((Response) => {
        setIsLoading(false);
        if (Response.data && Response.data.data) {
          Response.data.data.map((element) => {
            const { name } = element.categoriesRef[0];
            element.categoriesRef_name = name;
          });
          setProductList(Response.data.data);
        } else {
          console.error('Error While Fetching Data: ', Response.error);
        }
      });
  }

  function getCustomizeBarcodeSetting() {
    setupService.getSetupWithName('Customize Barcode').then((response) => {
      if (response.data && response.data.data.length) {
        let barcodeSetting = response.data.data[0].data[0];
        localStorage.setItem('barcode-setting', JSON.stringify(barcodeSetting));
      } else console.error('Error While Fetching Data: ', Response.error);
    });
  }

  function handleChange(_, value) {
    if (value !== null) {
      let items = [...selectedProductList];
      items.push(value);
      setSelectedProductList(items);
    }
  }

  function removeItemFromList(index) {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    let items = [...selectedProductList];
    let removedItem = items.splice(index, 1);
    delete removedItem[0].printCount;
    setSelectedProductList(items);
  }

  function handlePrintBarcode() {
    localStorage.setItem('print-barcode', JSON.stringify(selectedProductList));
    ReactDOM.render(
      <PrintBarcodeHelper />,
      document.getElementById('print-mount')
    );
    document.getElementById('print-button').click();
    ReactDOM.unmountComponentAtNode(document.getElementById('print-mount'));
    localStorage.removeItem('print-barcode');

    selectedProductList.forEach((product) => {
      delete product.printCount;
    });
    setSelectedProductList([]);
    setValues([]);
  }

  useEffect(() => {
    activityMonitoring('PrintBarcode');
  }, []);
  return (
    <>
      <div id="print-mount"></div>
      <PageTitle
        title="Print Barcode"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="printBarcodeListHeading"
      />
      <Paper elevation={6} square className={classes.PaperMargin}>
        <Toolbar style={{ padding: '0' }}>
          <Grid container>
            <Grid item xs={12} sm={6} md={4}>
              <Autocomplete
                classes={{
                  inputRoot: classes.inputRoot,
                  input: classes.input,
                }}
                className={classes.inputFieldPadding}
                id="ddSearchProd"
                freeSolo
                options={productList}
                getOptionLabel={(option) => (option.name ? option.name : '')}
                onChange={handleChange}
                value={values}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className={classes.inputFieldPadding}
                    label="Product"
                    margin="normal"
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </Grid>
          </Grid>
        </Toolbar>
        <CircularLoader isload={isLoading} />
        <GenericTable
          data={selectedProductList}
          TableId={'printBarcodeTable'}
          TableTitle={'Print Barcode Table'}
          cellData={[
            { name: 'name', type: 'string' },
            { name: 'categoriesRef_name', type: 'string' },
            { name: 'barcode', type: 'float' },
            { name: 'barcode_quantity', type: 'input' },
            { name: 'remove', type: 'remove' },
          ]}
          headCells={headCells}
          getRecordsFn={getRecords}
          rowDeleteDialog={setConfirmDialog}
          rowDeletefn={removeItemFromList}
          isLoading={isLoading}
        />
        <Grid container>
          <Grid item xs={10}></Grid>
          <Grid item style={{ marginLeft: 'auto' }} xs={2}>
            <Controls.Button
              text="Print Barcodes"
              variant="contained"
              color="primary"
              id="btnPrintBarcode"
              className={classes.searchButton}
              startIcon={<PrintIcon />}
              onClick={handlePrintBarcode}
            />
          </Grid>
        </Grid>
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        id_Yes="delCat"
        id_No="dontDelCat"
      />
    </>
  );
}
