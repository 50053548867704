import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import Controls from '../../Utilities/Controls/Controls';
import { Form } from '../../Utilities/ReuseableCommponents/useForm';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import PageTitle from '../../Layout/PageTitle';
import { useLocation } from 'react-router-dom';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import { Paper } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';
import useStyles from '../../../Style/AppStylesAdmin';
import { updateCompanyBilling } from './BillingService';

function CompanyBillingControlDetails() {
  const history = useHistory();
  const classes = useStyles();
  const [version, setVersion] = useState('');
  const [company, setCompany] = useState('');
  const [initalPrice, setInitalPrice] = useState('');
  const [recurring, setRecurring] = useState('');
  const [comission, setComission] = useState(false);
  const [customBarcode, setCustomBarcode] = useState(false);
  const [companyRole, setCompanyRole] = useState(false);
  const [casiher, setCasiher] = useState(false);
  const [isFirstMonth, setIsFirstMonth] = useState(true);
  const [id, setId] = useState('');

  const versionsList = [
    { name: 'EasyPos', value: 'EasyPos', _id: 1 },
    { name: 'Standered', value: 'EasyPos', _id: 2 },
    { name: 'Premium', value: 'EasyPos', _id: 3 },
  ];
  const location = useLocation();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      let versionName = '';
      versionsList.map((value) => {
        if (value._id == version) {
          versionName = value.name;
          return;
        }
      });
      const updateBillingDetailsObj = {
        _id: id,
        version: versionName,
        firstMonthBill: initalPrice,
        monthlyCharges: recurring,
        companyName: company,
        data: [
          { name: 'barcode', checked: customBarcode },
          { name: 'comission', checked: comission },
          { name: 'companyRole', checked: companyRole },
          { name: 'casiher', checked: casiher },
        ],
        isFirstMonth: isFirstMonth,
      };

      updateCompanyBilling(updateBillingDetailsObj)
        .then(() => {
          setNotify({
            isOpen: true,
            message: 'Updated Successfully',
            type: 'success',
          });
        })
        .catch((error) => {
          console.error(error);
          setNotify({
            isOpen: true,
            message: 'Unable To Process Request',
            type: 'error',
          });
        });
      console.log(updateBillingDetailsObj);
    } catch (error) {
      console.error(error);
      setNotify({
        isOpen: true,
        message: 'Unable To Process Request',
        type: 'error',
      });
    }
  };

  useEffect(() => {
    try {
      if (location.state.recordForEdit && location.state.recordForEdit._id) {
        let companyBillObj = location.state.recordForEdit;
        let versionValue = 0;
        versionsList.map((value) => {
          if (value.name == companyBillObj.version) {
            versionValue = value._id;
            return;
          }
        });
        setId(companyBillObj._id);
        setVersion(versionValue);
        setCompany(companyBillObj.companyName);
        setInitalPrice(companyBillObj.firstMonthBill);
        setRecurring(companyBillObj.monthlyCharges);
        setIsFirstMonth(companyBillObj.isFirstMonth);
        if (companyBillObj.data.length) {
          companyBillObj.data.map((feature) => {
            if (feature.name == 'barcode' && feature.checked) {
              setCustomBarcode(true);
            }
            if (feature.name == 'comission' && feature.checked) {
              setComission(true);
            }
            if (feature.name == 'casiher' && feature.checked) {
              setCasiher(true);
            }
            if (feature.name == 'companyRole' && feature.checked) {
              setCompanyRole(true);
            }
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, []);
  return (
    <>
      <PageTitle
        title={location.state.pageHeaderTitile}
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="categoryDetailsHeading"
      />
      <Paper
        elevation={6}
        square
        className={classes.PaperMargin}
        style={{
          borderRadius: '10px',
        }}
      >
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12} sm={3} md={3} lg={2}>
              <div className={classes.searchDropdown}>
                <Controls.Select
                  name="version_id"
                  label="Version"
                  value={version}
                  options={versionsList}
                  onChange={(event) => {
                    setVersion(event.target.value);
                  }}
                  id="ddSearchVersion"
                />
              </div>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={3} md={3} lg={2}>
                <div className={classes.searchDropdown}>
                  <Controls.Input
                    name="company_id"
                    label="Company Name"
                    value={company}
                    id="ddCompanyName"
                    disabled={true}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container>
              <Typography variant="body2" className={classes.headLabel}>
                Add Features
              </Typography>
              <Grid container>
                <Controls.Checkbox
                  name={'customBarcode'}
                  label={'Custom Barcode'}
                  value={customBarcode}
                  onChange={(event) => {
                    setCustomBarcode(event.target.value);
                  }}
                />
              </Grid>
              <Grid container>
                <Controls.Checkbox
                  name={'comission'}
                  label={'Comission'}
                  value={comission}
                  onChange={(event) => {
                    setComission(event.target.value);
                  }}
                ></Controls.Checkbox>
              </Grid>
              <Typography variant="body2" className={classes.headLabel}>
                Add Roles
              </Typography>
              <Grid container>
                <Controls.Checkbox
                  name={'customBarcode'}
                  label={'Company Role'}
                  value={companyRole}
                  onChange={(event) => {
                    setCompanyRole(event.target.value);
                  }}
                />
              </Grid>
              <Grid container>
                <Controls.Checkbox
                  name={'casiher'}
                  label={'Casiher'}
                  value={casiher}
                  onChange={(event) => {
                    setCasiher(event.target.value);
                  }}
                ></Controls.Checkbox>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={3} md={3} lg={2}>
                <div className={classes.searchDropdown}>
                  <Controls.Input
                    name="initalPrice_id"
                    label="Inital Price"
                    value={initalPrice}
                    id="ddInitialPrice"
                    onChange={(event) => {
                      setInitalPrice(event.target.value);
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={2}>
                <div className={classes.searchDropdown}>
                  <Controls.Input
                    name="recurringPrice_id"
                    label="Recurring Price"
                    value={recurring}
                    id="ddrecurringPrice"
                    onChange={(event) => {
                      setRecurring(event.target.value);
                    }}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container className={classes.ButtonsCotainerMargin}>
              <br></br>
              <Grid item xs={12} sm={6} align="left">
                <Controls.Button
                  text="Cancel"
                  color="default"
                  onClick={history.goBack}
                  startIcon={<CloseIcon />}
                  className={classes.btnCancel}
                  id="btnCancelCat"
                />
              </Grid>
              <Grid item xs={12} sm={6} align="right">
                <div>
                  <Controls.Button
                    type="submit"
                    startIcon={<SaveIcon />}
                    text="Save"
                    className={classes.btnSave}
                    id="btnSaveCat"
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}

export { CompanyBillingControlDetails as default };
