import React from 'react';
import ReactToPrint from 'react-to-print';
import ComponentToPrint from '../InvoiceTemplates/rembrandssalesInvoice';

class RembrandsPrint extends React.Component {
  render() {
    return (
      <div>
        <ReactToPrint
          trigger={() => (
            <div style={{ display: 'none' }}>
              <button id="print-button">Print</button>
            </div>
          )}
          content={() => this.componentRef}
        />
        <div style={{ display: 'none' }}>
          <ComponentToPrint ref={(el) => (this.componentRef = el)} />
        </div>
      </div>
    );
  }
}

export default RembrandsPrint;
