import React, { useState } from 'react';
import PageTitle from '../../Layout/PageTitle';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import { Paper, Toolbar, TextField, makeStyles } from '@material-ui/core';
import Controls from '../../Utilities/Controls/Controls';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import * as waiterService from './WaiterService';
import { useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';
import GenericTable from '../../Utilities/ReuseableCommponents/TableReport/GenericTable';
import CircularLoader from '../../Utilities/ReuseableCommponents/CircularLoader';
const headCells = [
  { id: 'name', label: 'Waiter Name', align: 'left' },
  { id: 'cnic', label: 'CNIC', align: 'left' },
  { id: 'status', label: 'Status', type: 'status' },
  { id: 'actions', label: 'Actions', disableSorting: true, type: 'remove' },
];

let apiResponseDto = {
  data: [],
  error: null,
  loading: false,
  statusCode: 0,
};

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  newButton: {
    position: 'absolute',
    right: '0px',
  },
  PaperMargin: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
}));

export default function Waiters() {
  const classes = useStyles();
  const history = useHistory();
  const recordForEdit = null;
  const [records, setRecords] = useState([]);
  const [recordsCount, setRecordsCount] = useState(0);
  const [name, setName] = useState('');
  const [cnic, setCnic] = useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const rowOnClick = {
    path: '/WaiterDetails',
    header: 'View/Edit Waiter',
    property: ['recordForEdit'],
  };
  function getRecords(page, rowsPerPage, sortDirection, sortColumnName) {
    let searchString = '';
    setIsLoading(true);
    let data = (async () => {
      if (name) {
        searchString += `&name=${name}`;
      }
      if (cnic) {
        const findExtraSpaceIndex = cnic.indexOf(' ');
        const CNIC = cnic.slice(0, findExtraSpaceIndex);
        searchString += `&cnic=${CNIC}`;
      }
      apiResponseDto = await waiterService
        .getAllWaiter(
          page,
          rowsPerPage,
          sortDirection,
          sortColumnName,
          searchString
        )
        .then((Response) => {
          return Response;
        });
      return apiResponseDto;
    })();
    data.then((Response) => {
      setIsLoading(false);
      if (Response.data) {
        setRecords(Response.data.data);
        setRecordsCount(Response.data.count);
        if (Response.data.count == 0) {
          setNotify({
            isOpen: true,
            message: 'No Data Found',
            type: 'error',
          });
        }
      } else {
        setIsLoading(false);
        console.error('Error While Fetching Data: ', Response.error);
      }
    });
  }

  const handleStatusChange = async (event, index) => {
    let cloneDatatableRecords = records;
    let id = cloneDatatableRecords[index]._id;
    let checkedStatus = event.target.checked;

    if (id) {
      try {
        let categoryStatusUpdate = {
          status: checkedStatus ? 'active' : 'disabled',
        };
        apiResponseDto.data = null;
        apiResponseDto.error = null;
        apiResponseDto = await waiterService
          .patchWaiter(id, categoryStatusUpdate)
          .then((Responce) => {
            return Responce;
          });
        if (apiResponseDto.data) {
          let newStatusChangedRecord = {
            ...cloneDatatableRecords[index],
            status: checkedStatus ? 'active' : 'disabled',
          };
          cloneDatatableRecords[index] = newStatusChangedRecord;
          setRecords(cloneDatatableRecords);
          setNotify({
            isOpen: true,
            message: 'Waiter Status Updated Successfully',
            type: 'success',
            notificationTime: 15000,
          });
        }
        if (apiResponseDto.error) {
          setNotify({
            isOpen: true,
            message: 'Unable To Process Request',
            type: 'error',
          });
        }
      } catch (e) {
        setNotify({
          isOpen: true,
          message: 'Unable To Process Request',
          type: 'error',
        });
      }
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    getRecords(0, 5, -1, '');
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const onDelete = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    try {
      apiResponseDto.data = null;
      apiResponseDto.error = null;
      apiResponseDto = await waiterService.deleteWaiter(id).then((Response) => {
        return Response;
      });
      if (apiResponseDto.data) {
        getRecords(0, 10, 1, 'name');
        setNotify({
          isOpen: true,
          message: 'Deleted Successfully',
          type: 'success',
        });
      }
      if (apiResponseDto.error) {
        if (apiResponseDto.statusCode === 403) {
          setNotify({
            isOpen: true,
            message:
              'Unable To Delete Waiter : Sales are Associated With This Waiter',
            type: 'error',
          });
        } else {
          setNotify({
            isOpen: true,
            message: 'Unable To Process Request',
            type: 'error',
          });
        }
      }
    } catch (e) {
      setNotify({
        isOpen: true,
        message: 'Unable To Process Request',
        type: 'error',
      });
    }
  };

  return (
    <>
      <PageTitle
        title="Waiter"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="waiterListHeading"
      />
      <Paper elevation={1} square className="paperMargin">
        <Toolbar disableGutters>
          <form onSubmit={handleSearch} className="searchFormAlign">
            <TextField
              id="txtSearchWaiterName"
              label="Name"
              className="searchInput"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoComplete="off"
            />

            <InputMask
              mask="99999-9999999-9"
              value={cnic}
              onChange={(e) => setCnic(e.target.value)}
              disabled={false}
              maskChar=" "
            >
              {() => (
                <TextField
                  variant="outlined"
                  label="CNIC"
                  id="txtSearchWaiterCnic"
                  autoComplete="off"
                />
              )}
            </InputMask>

            <Controls.Button
              text="Search"
              variant="contained"
              color="primary"
              id="btnWaiterSearch"
              startIcon={<SearchIcon />}
              className={classes.searchInput}
              type="submit"
            />
            <Controls.Button
              text="Add New"
              variant="contained"
              color="primary"
              id="btnAddWaiter"
              startIcon={<AddIcon />}
              className={classes.newButton}
              onClick={() => {
                history.push({
                  pathname: '/WaiterDetails',
                  state: {
                    pageHeaderTitile: 'Add New Waiter',
                    recordForEdit,
                  },
                });
              }}
            />
          </form>
        </Toolbar>
        <CircularLoader isload={isLoading} />
        <GenericTable
          data={records}
          TableId={'waitersTable'}
          TableTitle={'Waiters Table'}
          cellData={[
            { name: 'name', type: 'string' },
            { name: 'cnic', type: 'string' },
            { name: 'status', type: 'status' },
            { name: 'remove', type: 'remove' },
          ]}
          headCells={headCells}
          recordsCount={recordsCount}
          getRecordsFn={getRecords}
          statusChangeFn={handleStatusChange}
          rowDeleteDialog={setConfirmDialog}
          rowDeletefn={onDelete}
          rowOnClick={rowOnClick}
          isLoading={isLoading}
        />
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        id_Yes="btnDelWaiterYes"
        id_No="btnDelWaiterNo"
      />
    </>
  );
}
