import { API_VERSION } from '../../../Common/constants';
import { callAPI } from '../../../Services/ApiCaller';
import authHeader from '../../../Services/auth-header';

export async function getAllCompanies() {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  return callAPI(
    `api/${API_VERSION}/companies`,
    null,
    headerObject,
    'GET',
    `?$orderBy=name&$orderDir=1&$skip=0&$top=5000`
  ).then((Responce) => {
    return Responce;
  });
}

export async function getCompanyBillingHistoryByName(dbName) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  return callAPI(
    `api/${API_VERSION}/billing?companyName=${dbName}&populate=companyRef`,
    null,
    headerObject,
    'GET',
    ``
  ).then((Responce) => {
    return Responce;
  });
}

export async function getCompanies(
  page,
  rowsPerPage,
  sortDirection,
  sortColumnName,
  searchString,
  populate
) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  let rowsFrom = page * rowsPerPage;

  let sortOrderdir = sortDirection === 'asc' ? 1 : -1;
  return callAPI(
    `api/${API_VERSION}/companies`,
    null,
    headerObject,
    'GET',
    `?$orderBy=${sortColumnName}&$orderDir=${sortOrderdir}&$skip=${rowsFrom}&$top=${rowsPerPage}${searchString}&populate=${populate}`
  ).then((Responce) => {
    return Responce;
  });
}

export async function getAllBillings() {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  return callAPI(
    `api/${API_VERSION}/billing`,
    null,
    headerObject,
    'GET',
    `?$orderBy=createAt&$orderDir=-1&$skip=0&$top=5000&populate=companyRef`
  ).then((Responce) => {
    return Responce;
  });
}

export async function getBillings(
  page,
  rowsPerPage,
  sortDirection,
  sortColumnName,
  searchString,
  populate
) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  let rowsFrom = page * rowsPerPage;

  let sortOrderdir = sortDirection === 'asc' ? 1 : -1;
  return callAPI(
    `api/${API_VERSION}/billing`,
    null,
    headerObject,
    'GET',
    `?$orderBy=${sortColumnName}&$orderDir=${sortOrderdir}&$skip=${rowsFrom}&$top=${rowsPerPage}${searchString}&populate=${populate}`
  ).then((Responce) => {
    return Responce;
  });
}

export async function updateBilling(billing) {
  console.log(billing, 'in put call');
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  return callAPI(
    `api/${API_VERSION}/billing`,
    billing,
    headerObject,
    'PUT',
    `/${billing._id}`
  ).then((Responce) => {
    return Responce;
  });
}

export async function generateUserBill(bill) {
  console.log(bill, 'in put call');
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  return callAPI(
    `api/${API_VERSION}/billing`,
    bill,
    headerObject,
    'POST',
    ''
  ).then((Responce) => {
    return Responce;
  });
}

export async function updateCompanyBilling(companyObj) {
  console.log(companyObj, 'in put call');
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  return callAPI(
    `api/${API_VERSION}/companies`,
    companyObj,
    headerObject,
    'PUT',
    `/${companyObj._id}`
  ).then((Responce) => {
    return Responce;
  });
}
