import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  TableHead,
  TableSortLabel,
} from '@material-ui/core';
import { GenericTableContext } from './GenericTable';

export default function TblHead(props) {
  const { order, orderBy, handleSortRequest } = useContext(GenericTableContext);
  const { headCells, getCellAlignment, classStyle } = props;
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          const sortDirection = orderBy === headCell.id ? order : 'desc';

          return (
            <TableCell
              align={headCell.type ? getCellAlignment(headCell.type) : 'left'}
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
              className={classStyle}
            >
              {headCell.disableSorting ? (
                headCell.label
              ) : (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={sortDirection}
                  onClick={() => {
                    handleSortRequest(headCell.id);
                  }}
                >
                  {headCell.label}
                </TableSortLabel>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

TblHead.propTypes = {
  headCells: PropTypes.array,
  getCellAlignment: PropTypes.func,
  classStyle: PropTypes.any,
};
