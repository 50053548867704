import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import Controls from '../../../Utilities/Controls/Controls';
import { useForm, Form } from '../../../Utilities/ReuseableCommponents/useForm';
import Notification from '../../../Utilities/ReuseableCommponents/Notification';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import * as signUpService from './signUpService';
import AuthService from '../SignIn/AuthService';
import { getType } from '../../../../Common/constants';
import { initializeData } from '../../Setups/SetupService';
import style from './SignUp.module.css';

const initialFValues = {
  email: '',
  password: '',
  name: '',
  companyName: '',
  phonenumber: '',
  userRole_id: 'superadmin',
  type: 'retail',
};

export default function SignUp() {
  const history = useHistory();

  const [isProcessing, setIsProcessing] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  let Responce = {
    data: null,
    error: null,
    loading: false,
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ('name' in fieldValues)
      temp.name = fieldValues.name ? '' : 'This field is required.';

    if ('email' in fieldValues)
      temp.email = /$^|.+@.+..+/.test(fieldValues.email)
        ? ''
        : 'Email is not valid.';

    if ('companyName' in fieldValues)
      temp.companyName = fieldValues.companyName
        ? ''
        : 'This field is required.';

    if ('phonenumber' in fieldValues)
      temp.phonenumber =
        fieldValues.phonenumber.length === 11
          ? ''
          : 'Phone Number Must Be 11-Digits';

    if ('userRole_id' in fieldValues)
      temp.userRole_id = fieldValues.userRole_id
        ? ''
        : 'This field is required.';

    if ('password' in fieldValues)
      temp.password = fieldValues.password ? '' : 'This field is required.';

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == '');
  };

  const { values, errors, setErrors, handleInputChange, resetForm } = useForm(
    initialFValues,
    true,
    validate
  );

  const handleSubmit = (e) => {
    setIsProcessing(true);
    e.preventDefault();
    if (validate()) {
      AuthService.tenantExists(values.companyName).then(
        () => {
          let temp = { ...errors };
          temp.companyName = 'Company Name Already exist';
          setErrors({
            ...temp,
          });
        },
        () => {
          addOrEdit(values, resetForm);
        }
      );
    }
    setIsProcessing(false);
  };

  const addOrEdit = async (user, resetForm) => {
    try {
      Responce.data = null;
      Responce.error = null;
      Responce = await signUpService.signUpUser(user).then((Responce) => {
        return Responce;
      });
      if (Responce.data) {
        if (Responce.data.token) {
          localStorage.setItem('type', user.type ? user.type : 'retail');
          localStorage.setItem('Token', Responce.data.token);
          try {
            initializeData().then(
              (res) => {
                if (res.statusCode === 500) {
                  initializeData().then(
                    () => {},
                    () => {}
                  );
                }
              },
              () => {}
            );
            const companiesInitData = {
              monthlyCharges: 0,
              companyName: user.companyName.toLowerCase(),
              version: 'EasyPos',
              isFirstMonth: true,
            };
            signUpService
              .insertCompanies(companiesInitData)
              .then((companyForBilling) => {
                console.log(companyForBilling);
              })
              .catch((error) => {
                console.error(error);
              });
          } catch (e) {
            console.error(e);
            //do Nothing
          }
          resetForm();
          history.push('/');
        } else {
          console.error('Error While SignUp User', Responce.error);
          setIsProcessing(false);
          setNotify({
            isOpen: true,
            message: 'Unable To Process Request',
            type: 'error',
          });
        }
      }
      if (Responce.error) {
        console.error('Error While SignUp User', Responce.error);
        setIsProcessing(false);
        setNotify({
          isOpen: true,
          message: 'Unable To Process Request',
          type: 'error',
        });
      }
    } catch (e) {
      console.error('Error While SignUp User', e);
      setIsProcessing(false);
      setNotify({
        isOpen: true,
        message: 'Unable To Process Request',
        type: 'error',
      });
    }
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={style.paper}>
          <Avatar className={style.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Form className={style.form} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controls.Input
                  name="name"
                  label="Full Name"
                  required
                  fullWidth
                  value={values.name}
                  onChange={handleInputChange}
                  error={errors.name}
                  id="txtSignUpName"
                />
              </Grid>

              <Grid item xs={12}>
                <Controls.Input
                  name="companyName"
                  label="Company Name"
                  required
                  fullWidth
                  value={values.companyName}
                  onChange={handleInputChange}
                  error={errors.companyName}
                  id="txtSignUpCompanyName"
                />
              </Grid>
              <Grid item xs={12}>
                <Controls.Select
                  name="userRole_id"
                  label="User Role"
                  fullWidth
                  value={values.userRole_id}
                  options={signUpService
                    .getUserRoleCollection()
                    .filter((e) => e._id == 'superadmin')}
                  error={errors.userRole_id}
                  id="ddSignUpUserRole"
                />
              </Grid>
              <Grid item xs={12}>
                <Controls.Select
                  name="type"
                  label="Type"
                  fullWidth
                  value={values.type}
                  options={getType()}
                  onChange={handleInputChange}
                  error={errors.type}
                  id="ddtype"
                />
              </Grid>
              <Grid item xs={12}>
                <Controls.Input
                  name="phonenumber"
                  label="Phone Number (03031xxxxxx)"
                  fullWidth
                  value={values.phonenumber}
                  onChange={handleInputChange}
                  error={errors.phonenumber}
                  id="txtSignUpPhonenumber"
                />
              </Grid>
              <Grid item xs={12}>
                <Controls.Input
                  name="email"
                  label="Email Address"
                  type="email"
                  fullWidth
                  required
                  value={values.email}
                  onChange={handleInputChange}
                  error={errors.email}
                  id="txtSignUpEmail"
                />
              </Grid>
              <Grid item xs={12}>
                <Controls.Input
                  name="password"
                  label="Password"
                  type="password"
                  fullWidth
                  required
                  value={values.password}
                  onChange={handleInputChange}
                  error={errors.password}
                  id="txtSignUpPassword"
                />
              </Grid>
              <Grid item xs={12}>
                <div>
                  <Controls.Button
                    type="submit"
                    text="Sign Up"
                    fullWidth
                    className={style.submit}
                    variant="contained"
                    disabled={isProcessing}
                  />
                </div>
              </Grid>
            </Grid>
          </Form>
        </div>
      </Container>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
