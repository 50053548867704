import Input from './Input';
import RadioGroup from './RadioGroup';
import Select from './Select';
import Checkbox from './Checkbox';
import Button from './Button';
import ActionButton from './ActionButton';
import MultiSelect from './MultiSelect';
import EditableDropDown from './EditableDropDown';
import TimePicker from './TimePicker';
import ControllableStateEditableDropDown from './ControllableStateEditableDropDown';

const Controls = {
  Input,
  RadioGroup,
  Select,
  Checkbox,
  Button,
  ActionButton,
  MultiSelect,
  EditableDropDown,
  ControllableStateEditableDropDown,
  TimePicker,
};

export default Controls;
