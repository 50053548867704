import React from 'react';
import {
  FormControl,
  FormControlLabel,
  Checkbox as MuiCheckbox,
  FormHelperText,
} from '@material-ui/core';
import PropTypes from 'prop-types';
function Checkbox(props) {
  const { name, label, value, onChange, error = null, ...other } = props;

  const convertToDefEventPara = (name, value) => ({
    target: {
      name,
      value,
    },
  });

  return (
    <FormControl>
      <FormControlLabel
        control={
          <MuiCheckbox
            name={name}
            color="primary"
            checked={value}
            onChange={(e) =>
              onChange(convertToDefEventPara(name, e.target.checked))
            }
            {...other}
          />
        }
        label={label}
      />
      <FormHelperText error={true}>{error}</FormHelperText>
    </FormControl>
  );
}

Checkbox.propTypes = {
  name: PropTypes.any,
  label: PropTypes.any,
  value: PropTypes.any,
  error: PropTypes.any,
  onChange: PropTypes.any,
};
export default Checkbox;
