import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Application from './Components/Application';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { hotjar } from 'react-hotjar';
import './Assets/Fonts/SEGOEUI.woff';

ReactDOM.render(<Application />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (window.location.hostname.startsWith('app.')) {
  hotjar.initialize(2628534, 6);
}
