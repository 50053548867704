import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as SetupService from '../../../Pages/Setups/SetupService';

import {
  Button,
  AppBar,
  Toolbar,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import {
  ExitToAppRounded as ExitToAppRoundedIcon,
  PlayArrowRounded as PlayArrowRoundedIcon,
  SyncRounded as SyncRoundedIcon,
  PowerSettingsNew as PowerSettingsNewIcon,
  Stop as StopIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import Logo from '../../../../Assets/Images/ConcaveLogo.png';
import { Offline, Online } from 'react-detect-offline';
const Header = ({
  classes,
  UserRole,
  handleOpen4,
  shiftButton,
  handleOpen5,
  syncTime,
  InvoiceSync,
  SIGNOUT,
  signoutRef,
  isShortcutEnable,
}) => {
  const history = useHistory();
  const [headerTitle, setheaderTitle] = useState('');
  useEffect(() => {
    async function getOrganizationName() {
      const Response = await SetupService.getAllSetups();
      if (Response.data) {
        let organizationInfoData = Response.data.data.filter(
          (e) => e.name === 'Organization Info'
        );
        const displayName =
          organizationInfoData && organizationInfoData.length
            ? organizationInfoData[0].data.displayName
            : '';
        setheaderTitle(displayName);
      }
    }
    getOrganizationName();
  }, []);
  return (
    <AppBar position="static">
      <Toolbar
        style={{
          backgroundColor: 'white',
        }}
      >
        {UserRole !== 'cashier' ? (
          <IconButton
            variant="contained"
            color="primary"
            aria-label="contained Secondary button"
            edge="start"
            onClick={history.goBack}
            id="btnPOSBack"
          >
            <ExitToAppRoundedIcon />
          </IconButton>
        ) : (
          <></>
        )}

        <img
          variant="square"
          alt="Cindy Baker"
          src={Logo}
          style={{ height: '40px' }}
        />
        <h2 id="posBusinessName" className={classes.companyNameCSS}>
          {headerTitle}
        </h2>
        <Tooltip title="Start Shift">
          <Button
            onClick={handleOpen4}
            variant="contained"
            color="primary"
            aria-label="contained primary button group"
            style={{
              marginRight: '20px',
              display: shiftButton ? 'none' : 'flex',
            }}
            id="btnPOSStartShift"
          >
            <PlayArrowRoundedIcon />
            <span
              className={classes.displayDesktop}
              style={{ marginLeft: '5px' }}
            >
              START SHIFT
              <br />
              {isShortcutEnable.status && '(Shift + O)'}
            </span>
          </Button>
        </Tooltip>
        <Tooltip title="End Shift">
          <Button
            onClick={handleOpen5}
            variant="contained"
            color="primary"
            aria-label="contained primary button group"
            style={{
              marginRight: '20px',
              display: shiftButton ? 'flex' : 'none',
            }}
            id="btnPOSEndShift"
          >
            <StopIcon />
            <span
              className={classes.displayDesktop}
              style={{ marginLeft: '5px' }}
            >
              END SHIFT
              <br />
              {isShortcutEnable.status && '(Shift + E)'}
            </span>
          </Button>
        </Tooltip>
        <Online>
          <Tooltip title="Online">
            <div>
              <span id="statusPOSOnline" className="indicator online"></span>
            </div>
          </Tooltip>
        </Online>
        <Offline>
          <Tooltip title="Offline">
            <div>
              <span id="statusPOSOffline" className="indicator offline"></span>
            </div>
          </Tooltip>
        </Offline>
        <Tooltip title={syncTime}>
          <IconButton
            style={{
              color: '#1976D2',
              marginLeft: '10px',
            }}
            color="inherit"
            aria-label="menu"
            onClick={InvoiceSync}
            id="btnPOSSync"
          >
            <SyncRoundedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Sign Out">
          <IconButton
            onClick={SIGNOUT}
            style={{
              color: 'white',
            }}
            color="inherit"
            aria-label="menu"
            id="btnPOSSignOut"
            ref={signoutRef}
          >
            <PowerSettingsNewIcon fontSize="small" style={{ color: 'red' }} />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
};
Header.propTypes = {
  classes: PropTypes.any,
  UserRole: PropTypes.any,
  handleOpen4: PropTypes.any,
  shiftButton: PropTypes.any,
  handleOpen5: PropTypes.any,
  syncTime: PropTypes.any,
  InvoiceSync: PropTypes.any,
  SIGNOUT: PropTypes.any,
  CompanyName: PropTypes.any,
  signoutRef: PropTypes.any,
  isShortcutEnable: PropTypes.any,
};
export default Header;
