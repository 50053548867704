import React from 'react';
import PropTypes from 'prop-types';
import styles from './ReceiptReportFormat.module.css';

class ReceiptFormatReport extends React.Component {
  calculateReportData(val, i) {
    if (val.heading) {
      return <HeadingFragment val={val} i={i} />;
    } else if (val.subHeading) {
      return <SubHeadingFragment val={val} i={i} />;
    } else if (val.data) {
      return <DataFragment val={val} i={i} />;
    } else return {};
  }

  render() {
    const record = JSON.parse(localStorage.getItem('ShiftSalesReceiptReport'));

    return (
      <div className={styles.mainContainer}>
        {record.length === 0 ? (
          <h2>No Data Found</h2>
        ) : (
          <>
            <div id="receipt" className={styles.receipt}>
              {record.map((val, i) => {
                return this.calculateReportData(val, i);
              })}
            </div>
          </>
        )}
      </div>
    );
  }
}

const DataFragment = ({ val, i }) => {
  return (
    <React.Fragment key={i}>
      <table style={{ width: '100%' }}>
        <tbody>
          {Object.keys(val.data).map((key, ind) => (
            <tr key={ind}>
              <td>
                <span className={styles.colText}>{key}</span>
              </td>
              <td className={styles.right}>
                <span className={styles.colText}>{val.data[key]}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <hr />
    </React.Fragment>
  );
};

const SubHeadingFragment = ({ val, i }) => {
  return (
    <React.Fragment key={i}>
      <div className={styles.center}>
        <p className={styles.subHeading}>{val.subHeading.name}</p>
      </div>
      <hr />
    </React.Fragment>
  );
};

const HeadingFragment = ({ val, i }) => {
  return (
    <React.Fragment key={i}>
      <span className={styles.heading}>{val.heading.companyName}</span>
      <span className={styles.info}>{val.heading.receiptName}</span>
      <span className={styles.info}>{val.heading.branchName}</span>
      <hr />
    </React.Fragment>
  );
};

DataFragment.propTypes = {
  val: PropTypes.any,
  i: PropTypes.any,
};
SubHeadingFragment.propTypes = {
  val: PropTypes.any,
  i: PropTypes.any,
};
HeadingFragment.propTypes = {
  val: PropTypes.any,
  i: PropTypes.any,
};

export default ReceiptFormatReport;
