import React from 'react';
import CustomerDetailsComponent from '../Customer/CustomerComponents/CustomerDetailsComponent';
import PropTypes from 'prop-types';

const CustomerDetails = ({
  recordForEdit,
  openInPos,
  setCustomerResponse = null,
}) => {
  return (
    <>
      <CustomerDetailsComponent
        pageHeaderTitile={'Add New Customer'}
        openInPos={openInPos}
        recordForEdit={recordForEdit}
        setCustomerResponse={setCustomerResponse}
      />
    </>
  );
};

CustomerDetails.propTypes = {
  recordForEdit: PropTypes.any,
  openInPos: PropTypes.any,
  setCustomerResponse: PropTypes.any,
};
export default CustomerDetails;
