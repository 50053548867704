import { Paper, Toolbar, TextField } from '@material-ui/core';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import React, { useState, useEffect } from 'react';
import PageTitle from '../../Layout/PageTitle';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import * as CashReconcileService from './CashReconcileService';
import * as UserService from '../User/UserService';
import SearchIcon from '@material-ui/icons/Search';
import Controls from '../../Utilities/Controls/Controls';
import CircularLoader from '../../Utilities/ReuseableCommponents/CircularLoader';
import GenericTable from '../../Utilities/ReuseableCommponents/TableReport/GenericTable';
import { dateTimeZone as timeZone } from '../../../Common/constants';
import activityMonitoring from '../../../Common/functions';

const headCells = [
  {
    id: 'shiftNumber',
    label: 'Shift Number',
    type: 'string',
    disableSorting: true,
  },
  { id: 'name', label: 'Username', type: 'string', disableSorting: true },
  { id: 'createdAt', label: 'Opening Time', type: 'date' },
  { id: 'createdAt', label: 'Closing Time', type: 'date' },
  {
    id: 'openingAmount',
    label: 'Opening Amount',
    type: 'float',
    disableSorting: true,
  },
  {
    id: 'actualClosingAmount',
    label: 'Closing Amount',
    type: 'float',
    disableSorting: true,
  },
];

export default function CashReconciles() {
  const [records, setRecords] = useState([]);
  const [recordsCount, setRecordsCount] = useState(0);
  const [cashiers, setCashiers] = useState([]);
  const [shiftNumber, setShiftNumber] = useState('');
  const [cashier, setCashier] = useState('');
  const [dateFrom, setDateFrom] = React.useState('');
  const [dateTo, setDateTo] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  function getFilteredRecords(e) {
    e.preventDefault();
    getRecords(0, 5, -1, 'updatedAt');
  }

  function getRecords(page, rowsPerPage, sortDirection, sortColumnName) {
    let searchString = '';
    setIsLoading(true);
    if (dateFrom) {
      searchString += `&filteredFrom=${dateFrom}T00:00:00${timeZone()}`;
    }
    if (dateTo) {
      searchString += `&filteredTo=${dateTo}T23:59:59${timeZone()}`;
    }
    if (shiftNumber) {
      searchString += `&shiftNumber=${shiftNumber}`;
    }
    if (cashier) {
      searchString += `&createdBy=${cashier._id}`;
    }

    CashReconcileService.getFilteredCashReconciles(
      page,
      rowsPerPage,
      sortDirection,
      sortColumnName,
      searchString
    ).then((Response) => {
      setIsLoading(false);
      if (Response.data) {
        if (Response.data.data) {
          setRecords(Response.data.data);
          setRecordsCount(Response.data.count);
        }
      } else {
        console.error('Error While Fetching Data: ', Response.error);
      }
    });
  }

  useEffect(() => {
    UserService.getAllUsersForDropdown().then((Response) => {
      if (Response.data) {
        //Map data array with Generic List Object with Properties id,text
        //Dropdown only accepts list of Object with Properties id,text
        var result = Response.data.data.map((dataArrayRow) => ({
          _id: dataArrayRow._id,
          name: dataArrayRow.name,
        }));
        setCashiers(result);
        if (Response.data.count === 0) {
          setNotify({
            isOpen: true,
            message: 'No Cashier Found',
            type: 'error',
          });
        }
      } else {
        console.error('Unable To Get Records');
      }
    });
  }, []);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  useEffect(() => {
    activityMonitoring('CashReconcile');
  }, []);
  return (
    <>
      <PageTitle
        title="Cash Reconciliation Reports"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="cashReconcileHeading"
      />

      <Paper elevation={6} square className="paperMargin">
        <Toolbar disableGutters>
          <form
            onSubmit={getFilteredRecords}
            style={{ width: '100%', display: 'flex', alignItems: 'center' }}
          >
            <TextField
              id="txtSearchCashReconcileShiftNumber"
              label="Shift No."
              className="searchInput"
              variant="outlined"
              value={shiftNumber}
              onChange={(e) => setShiftNumber(e.target.value)}
              autoComplete="off"
            />
            <Controls.EditableDropDown
              name="cashierRef_id"
              label="Username"
              data={cashiers}
              value={cashier}
              onChange={(e, newValue) => setCashier(newValue)}
              className="searchInput"
              id="ddSearchCashReconcileCashier"
            />
            <TextField
              className="searchInput"
              id="txtSearchCashReconcileDateFrom"
              label="Date From"
              variant="outlined"
              type="date"
              onChange={(e) => setDateFrom(e.target.value)}
              value={dateFrom}
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
            />
            <TextField
              className="searchInput"
              id="txtSearchCashReconcileDateTo"
              label="Date To"
              variant="outlined"
              type="date"
              onChange={(e) => setDateTo(e.target.value)}
              value={dateTo}
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
            />
            <Controls.Button
              text="Search"
              variant="contained"
              id="btnSearchCashReconcile"
              color="primary"
              type="submit"
              className="searchButton"
              startIcon={<SearchIcon />}
            ></Controls.Button>
          </form>
        </Toolbar>
        <CircularLoader isload={isLoading} />
        <GenericTable
          data={records}
          TableId={'cashReconcileTable'}
          TableTitle={'Cash Reconcile Report'}
          duration={`${dateFrom} ${dateTo ? 'To' : ''} ${dateTo}`}
          cellData={[
            { name: 'shiftNumber', type: 'string' },
            { name: 'createdBy.name', type: 'string' },
            { name: 'createdAt', type: 'date' },
            { name: 'updatedAt', type: 'date' },
            { name: 'openingAmount', type: 'float' },
            { name: 'actualClosingAmount', type: 'float' },
          ]}
          headCells={headCells}
          recordsCount={recordsCount}
          getRecordsFn={getRecords}
          rowOnClick={{
            path: '/CashReconcileDetails',
            header: 'Cash Reconciliation Details',
            property: ['recordForEdit'],
          }}
        />
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
