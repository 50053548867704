import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import Controls from '../../../Utilities/Controls/Controls';
import IconButton from '@material-ui/core/IconButton';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import ClearIcon from '@material-ui/icons/Clear';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import SaveIcon from '@material-ui/icons/Save';
import * as setupService from '../SetupService';
import Notification from '../../../Utilities/ReuseableCommponents/Notification';
let apiResponseDTO = {
  data: [],
  error: null,
  loading: false,
  statusCode: 0,
};

const initFieldList = [
  {
    name: 'Customer Name',
    isRequired: true,
    isVisible: true,
  },
  {
    name: 'Contact Number',
    isRequired: true,
    isVisible: true,
  },
  {
    name: 'Alternate Contact',
    isRequired: false,
    isVisible: false,
  },
  {
    name: 'Email',
    isRequired: false,
    isVisible: false,
  },
  {
    name: 'Customer Type',
    isRequired: false,
    isVisible: false,
  },
  {
    name: 'Opening Amount',
    isRequired: false,
    isVisible: false,
  },
  {
    name: 'Credit Limit',
    isRequired: false,
    isVisible: false,
  },
  {
    name: 'Address Label',
    isRequired: false,
    isVisible: false,
  },
  {
    name: 'City',
    isRequired: false,
    isVisible: false,
  },
  {
    name: 'Area',
    isRequired: false,
    isVisible: false,
  },
  {
    name: 'Nearest Landmark',
    isRequired: false,
    isVisible: false,
  },
  {
    name: 'Address Details',
    isRequired: false,
    isVisible: false,
  },
];

export default function CustomerConfigTab() {
  const [dynamicFields, setDynamicFields] = useState([]);
  const [fieldList, setFieldList] = useState(initFieldList);
  const [config, setConfig] = useState({});
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  useEffect(() => {
    let data = (async () => {
      apiResponseDTO = await setupService
        .getSetupWithName('CustomerConfig')
        .then((Response) => {
          return Response;
        });
      return apiResponseDTO;
    })();
    data.then((response) => {
      if (response && response.data && response.data.data.length) {
        let customerFieldsConfig =
          response.data.data[0].data.customerFieldsConfig || [];
        setConfig(response.data.data[0]);
        [...fieldList].forEach((f) => {
          if (customerFieldsConfig && customerFieldsConfig.length) {
            let index = customerFieldsConfig.findIndex(
              (cfc) => cfc.name === f.name
            );
            if (index < 0) {
              customerFieldsConfig.push(f);
            }
          }
        });
        setFieldList(customerFieldsConfig);
        setDynamicFields(response.data.data[0].data.dynamicFields);
      } else {
        if (response.statusCode === 0) {
          setNotify({
            isOpen: true,
            message: 'Set Customer Configurations',
            type: 'info',
          });
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChangeConfig(event, index) {
    const { name, value } = event.target;
    let cloneFieldList = [...fieldList];

    cloneFieldList[index][name] = value;
    if (value && name === 'isRequired') {
      cloneFieldList[index]['isVisible'] = value;
    }
    if (!value && name === 'isVisible') {
      cloneFieldList[index]['isRequired'] = value;
    }
    // City check area auto checked
    if (cloneFieldList[index]['name'] === 'City') {
      let areaIndex = cloneFieldList.findIndex(
        (field) => field.name === 'Area'
      );
      if (areaIndex > -1) {
        cloneFieldList = handleDependentChange(
          cloneFieldList,
          areaIndex,
          name,
          value
        );
      }
    }
    // Area check city auto check
    if (cloneFieldList[index]['name'] === 'Area') {
      let cityIndex = cloneFieldList.findIndex(
        (field) => field.name === 'City'
      );
      if (cityIndex > -1) {
        cloneFieldList = handleDependentChange(
          cloneFieldList,
          cityIndex,
          name,
          value
        );
      }
    }
    setFieldList(cloneFieldList);
  }

  function handleDependentChange(cloneFieldList, index, name, value) {
    if (name === 'isRequired') {
      cloneFieldList[index][name] = value;
      if (value) cloneFieldList[index]['isVisible'] = value;
    }
    if (name === 'isVisible') {
      cloneFieldList[index][name] = value;
      if (!value) cloneFieldList[index]['isRequired'] = value;
    }
    return cloneFieldList;
  }

  function addCustomField() {
    setDynamicFields([
      ...dynamicFields,
      {
        id: Date.now().toString(36) + Math.random().toString(36).substr(2),
        label: '',
        isRequired: false,
      },
    ]);
  }

  function handleChange(event, index) {
    const { name, value } = event.target;
    const cloneFieldList = [...dynamicFields];

    cloneFieldList[index][name] = value;
    setDynamicFields(cloneFieldList);
  }

  function handleRemoveField(index) {
    const cloneFieldList = [...dynamicFields];
    cloneFieldList.splice(index, 1);
    setDynamicFields(cloneFieldList);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    let customerConfig = {
      customerFieldsConfig: fieldList || [],
      dynamicFields: dynamicFields || [],
    };
    var payload = {
      name: 'CustomerConfig',
      data: customerConfig,
    };
    await setupService.insertSetup(payload).then((response) => {
      if (response) {
        setNotify({
          isOpen: true,
          message: 'Customer Configuration Submit Successfully',
          type: 'success',
        });
      }
    });
  }

  async function handleUpdate(e) {
    e.preventDefault();
    let customerConfig = {
      customerFieldsConfig: fieldList || [],
      dynamicFields: dynamicFields || [],
    };
    var payload = {
      name: config.name,
      data: customerConfig,
    };
    await setupService.updateSetup(payload, config._id).then((response) => {
      if (response) {
        setNotify({
          isOpen: true,
          message: 'Customer Configuration Update Successfully',
          type: 'success',
        });
      }
    });
  }

  function isDisabled(fieldName) {
    return fieldName === 'Customer Name' || fieldName === 'Contact Number';
  }

  return (
    <div>
      <form onSubmit={config._id ? handleUpdate : handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">Fields Config</Typography>
            <table>
              <thead>
                <tr>
                  <th>Field Name</th>
                  <th>Required</th>
                  <th>Visible</th>
                </tr>
              </thead>
              <tbody>
                {fieldList.map((field, index) => (
                  <tr key={index}>
                    <td>{field.name}</td>
                    <td>
                      <Controls.Checkbox
                        name="isRequired"
                        value={field.isRequired}
                        onChange={(e) => {
                          handleChangeConfig(e, index);
                        }}
                        disabled={isDisabled(field.name)}
                      />
                    </td>
                    <td>
                      <Controls.Checkbox
                        name="isVisible"
                        value={field.isVisible}
                        onChange={(e) => {
                          handleChangeConfig(e, index);
                        }}
                        disabled={isDisabled(field.name)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Dynamic Fields</Typography>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {dynamicFields.length <= 0 && (
                <Typography variant="subtitle2">
                  Click{' '}
                  <AddCircleRoundedIcon fontSize="small" color="primary" />{' '}
                  button to add custom field.
                </Typography>
              )}
              {dynamicFields.map((field, index) => (
                <Card key={index} style={{ maxWidth: 400, marginBottom: 10 }}>
                  <CardHeader
                    action={
                      <IconButton
                        aria-label="clear"
                        onClick={() => {
                          handleRemoveField(index);
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    }
                    title={
                      <Typography
                        variant="body1"
                        style={{ fontSize: 18, fontWeight: 'bold' }}
                      >
                        Custom field
                      </Typography>
                    }
                  />
                  <CardContent>
                    <Controls.Input
                      label="Type"
                      name="fieldType"
                      value="String"
                      disabled
                      style={{ width: 90 }}
                    />
                    <Controls.Input
                      label="Label"
                      name="label"
                      value={field.label}
                      onChange={(e) => handleChange(e, index)}
                      required
                    />
                    <Controls.Checkbox
                      label="Required?"
                      name="isRequired"
                      value={field.isRequired}
                      onChange={(e) => handleChange(e, index)}
                    />
                  </CardContent>
                </Card>
              ))}
              <div style={{ textAlign: 'right', maxWidth: 400 }}>
                <IconButton
                  color="primary"
                  aria-label="add new field"
                  onClick={addCustomField}
                >
                  <AddCircleRoundedIcon fontSize="large" />
                </IconButton>
              </div>
              <div>
                <Controls.Button
                  type="submit"
                  startIcon={<SaveIcon />}
                  text="Save"
                  id="btnCustSave"
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </form>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
