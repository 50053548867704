import React from 'react';
import ReactToPrint from 'react-to-print';
import GenericReportTemplate from './GenericReportTemplate';
import PropTypes from 'prop-types';
class print extends React.Component {
  render() {
    return (
      <div>
        <ReactToPrint
          trigger={() => (
            <div style={{ display: 'none' }}>
              <button id="print-button">Print</button>
            </div>
          )}
          content={() => this.componentRef}
        />
        <div style={{ display: 'none' }}>
          <GenericReportTemplate
            Data={this.props.Data}
            ref={(el) => (this.componentRef = el)}
          />
        </div>
      </div>
    );
  }
}
print.propTypes = {
  Data: PropTypes.any,
};
export default print;
