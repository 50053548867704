import React, { useState } from 'react';
import {
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Grid,
} from '@material-ui/core';
import Controls from '../../Utilities/Controls/Controls';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import PageTitle from '../../Layout/PageTitle';
import { useLocation, useHistory } from 'react-router-dom';
import useTableServerSide from '../../Utilities/ReuseableCommponents/useTableServerSide';
import { getFormattedDateTime as ISOdateFormatter } from '../../../Common/constants';
import * as salesService from '../Sales/SalesService';

const headCells = [
  { id: 'dateAndTime', label: 'Date & Time' },
  { id: 'invoiceId', label: 'Invoice ID', align: 'center' },
  { id: 'itemsSold', label: 'Products Sold', align: 'center' },
  { id: 'paymetMode', label: 'Payment Mode', align: 'center' },
  { id: 'amount', label: 'Amount', align: 'right' },
];

export default function CustomerSalesDetail() {
  const location = useLocation();
  const history = useHistory();
  const [recordsCount, setRecordsCount] = useState(0);
  const [records, setRecords] = useState([]);
  const [customer] = useState(() => {
    if (location.state)
      if (!location.state.customer) return history.goBack();
      else return location.state.customer.customerRef;
    else return history.goBack();
  });

  let apiResponseDto = {
    data: null,
    error: null,
    loading: false,
  };

  function getRecords(page, rowsPerPage, sortDirection, sortColumnName) {
    let searchString = `&customerRef=${customer._id}`;
    let data = (async () => {
      apiResponseDto = await salesService
        .getAllSales(
          page,
          rowsPerPage,
          sortDirection,
          sortColumnName,
          searchString
        )
        .then((Response) => {
          return Response;
        });
      return apiResponseDto;
    })();
    data.then((Response) => {
      if (Response.data) {
        if (Response.data.data) {
          setRecords(Response.data.data);
          setRecordsCount(Response.data.count);
        }
      } else console.error('Error While Fetching Data: ', Response.error);
    });
  }

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTableServerSide(records, recordsCount, headCells, getRecords);

  return (
    <>
      <PageTitle
        title={location.state.pageHeaderTitile}
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="custSalesDetailsHeading"
      />
      <Paper elevation={6} square className="paperMargin">
        <Controls.Input
          id="txtCustSalesDetailCustomerName"
          label="Customer Name"
          disabled={true}
          variant="outlined"
          value={customer.name}
          className="formTextField"
          InputLabelProps={{
            shrink: true,
          }}
          autoComplete="off"
        />
        <Controls.Input
          id="txtCustSalesDetailContactNo"
          label="Contact No"
          disabled={true}
          variant="outlined"
          value={customer.contactNumber}
          className="formTextField"
          InputLabelProps={{
            shrink: true,
          }}
          autoComplete="off"
        />
        <Controls.Input
          id="txtCustSalesDetailCreditLimit"
          name="creditLimit"
          label="Credit Limit"
          disabled={true}
          variant="outlined"
          value={customer.creditLimit}
          className="formTextField"
          InputLabelProps={{
            shrink: true,
          }}
          autoComplete="off"
        />
      </Paper>
      <Paper elevation={6} square className="paperMargin">
        <Toolbar>
          <h2>Customer Sales</h2>
        </Toolbar>
        <TblContainer>
          <TblHead />
          <TableBody id="custSalesDetailLstTbody">
            {recordsAfterPagingAndSorting().map((item, index) => (
              <TableRow
                key={item._id}
                id={`custSalesDetailLstTrow${index}`}
                onClick={() => {
                  history.push({
                    pathname: '/CustomerSaleItemsDetail',
                    state: {
                      pageHeaderTitile: 'Sale Items Detail',
                      saleItem: item,
                    },
                  });
                }}
              >
                <TableCell id={`salesDate${index}`}>
                  {ISOdateFormatter(item.clientCreatedAt)}
                </TableCell>
                <TableCell align="center">{item.invoiceNumber}</TableCell>
                <TableCell align="center">
                  {item.itemsSold.length.toLocaleString('en')}
                </TableCell>
                <TableCell align="center">{item.saleMode}</TableCell>
                <TableCell align="right">
                  Rs.{' '}
                  {item.saleAmountTotal
                    ? item.saleAmountTotal.toLocaleString('en')
                    : 0}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
        <Grid item xs={3}>
          <Controls.Button
            text="Back"
            color="default"
            onClick={history.goBack}
            id="btnBackSaleDetails"
          />
        </Grid>
      </Paper>
    </>
  );
}
