import React, { useEffect, useState } from 'react';
import {
  Grid,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Modal,
  IconButton,
  Link,
  Typography,
  InputAdornment,
  Paper,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import useTable from '../../Utilities/ReuseableCommponents/useTable';
import Controls from '../../Utilities/Controls/Controls';
import { useForm, Form } from '../../Utilities/ReuseableCommponents/useForm';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import PageTitle from '../../Layout/PageTitle';
import { useLocation, useHistory } from 'react-router-dom';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import NumberFormatCustom from '../../Utilities/ReuseableCommponents/NumberFormatCustom';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import * as categoryService from '../Category/CategoryService';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  getAllSeachedProductsAginstName,
  randomNumberGenerator,
} from '../../../Common/constants';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import styles from './DealDetails.module.css';
import * as DealService from './DealService.js';

const headCells = [
  { id: 'name', label: 'Deal Option Name' },
  { id: 'dealItems', label: 'Deal Items' },
  { id: 'selectonMaxLimit', label: 'Selecton Max Limit' },
  {
    id: 'deleteDealOption',
    label: 'Action',
    disableSorting: true,
    align: 'right',
  },
];

function getModalStyle() {
  const top = 50;
  const left = 50;
  const height = 80;
  const width = 70;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    height: `${height}%`,
    width: `${width}%`,
  };
}

let apiResponseDto = {
  data: [],
  error: null,
  messege: '',
  loading: false,
  statusCode: 0,
};

const initialFValues = {
  _id: 0,
  //Name Of The Deal
  name: '',
  dealPrice: 0,
  dealTaxPercentage: 0,
  /**dealFinalPrice = Tax On Deal Price - Deal Price */
  dealFinalPrice: 0,
  categoriesRef: '',
  dealOptions: [],
  __v: 0,
};

const defaultDealOptionForEdit = {
  index: 0,
  dealOption: {
    dealOptionName: '',
    maxItemLimit: 0,
    items: [],
  },
  errors: {},
};

let Responce = {
  data: null,
  error: null,
  loading: false,
};

let apiResponce = {
  data: null,
  error: null,
  loading: false,
  statusCode: 0,
};

function DealDetails() {
  const history = useHistory();
  const [modalStyle] = useState(getModalStyle);

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  } = useForm(initialFValues);

  const location = useLocation();
  const [filterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  /** Records to hold Category Drodown Options */
  const [records, setRecords] = useState([]);

  const [openDealModal, setOpenDealModal] = useState(false);
  const handleCloseDealOptionsModal = () => {
    setOpenDealModal(false);
  };

  const [dealOptionForModal, setdealOptionForModal] = useState(
    defaultDealOptionForEdit
  );

  const validateModalDealOptions = (
    fieldValues = dealOptionForModal.dealOption
  ) => {
    let temp = { ...dealOptionForModal.errors };

    var ErrMsg_TextEmpty = 'This field is required.';
    var ErrMsg_NumericMustBeNonZero = 'Must be greater than zero';

    if ('dealOptionName' in fieldValues) {
      temp.dealOptionName = fieldValues.dealOptionName
        ? values.dealOptions.filter(
            (everyDealOption) =>
              everyDealOption.dealOptionName == fieldValues.dealOptionName
          ).length == 0
          ? ''
          : /**Check For the Editing Of Existing Deal Option Name */
          dealOptionForModal.dealOption.dealOptionName ==
            fieldValues.dealOptionName
          ? ''
          : 'Duplicate Deal Option Name, Please Choose Different Deal Option Name '
        : ErrMsg_TextEmpty;

      values.dealOptions.filter(
        (everyDealOption) =>
          everyDealOption.dealOptionName == fieldValues.dealOptionName
      ).length > 0;
    }

    if ('maxItemLimit' in fieldValues)
      temp.maxItemLimit =
        fieldValues.maxItemLimit > 0 ? '' : ErrMsg_NumericMustBeNonZero;

    if ('additionalPrice' in fieldValues)
      temp.additionalPrice =
        fieldValues.additionalPrice < 0 ? 'Cannot be a negative value' : '';

    if ('items' in fieldValues) {
      temp.items =
        fieldValues.items.length > 0
          ? ''
          : 'Must Add Items Against Deal Option';
    }

    let cloned_dealOptionForEdit = { ...dealOptionForModal };
    cloned_dealOptionForEdit.errors = { ...dealOptionForModal.errors, ...temp };

    setdealOptionForModal(cloned_dealOptionForEdit);
    if (fieldValues === dealOptionForModal.dealOption)
      return Object.values(temp).every((x) => x === '');
  };

  /**Auto Complete Related States */

  //Searched Items against seach string
  const [searchedDealItems, setSearchedDealItems] = React.useState([]);
  const [dealItemsOptions, setDealItemsOptions] = useState([]);
  const [valueForDeal, setValueForDeal] = React.useState([]);
  const [inputValueForDeal, setInputValueForDeal] = React.useState('');

  //Main Methode for getting results from an API
  function onChange(e) {
    if (e.target.value) {
      //IIFE
      (async () => {
        apiResponseDto = await getAllSeachedProductsAginstName(
          e.target.value
        ).then((Response) => {
          return Response;
        });
        return apiResponseDto;
      })().then((apiResponseDto) => {
        if (apiResponseDto.data) {
          if (apiResponseDto.data.data && apiResponseDto.data.count) {
            //On every search store the result of searched Collection
            // When we will select any particular item, the details of an items could be fetched by the collection
            // After selecting an item - the details of an item will be added against item in  deal Item list
            setSearchedDealItems(apiResponseDto.data.data);

            apiResponce = apiResponseDto.data.data.map((apiData) => {
              return { id: apiData._id, name: apiData.name };
            });
            setDealItemsOptions(apiResponce);
          } else {
            setDealItemsOptions([]);
          }
        }
      });
    } else {
      setDealItemsOptions([]);
    }
  }

  const onChangeDealOptionItems = (index) => (e) => {
    if (e.target.name == 'additionalPrice' && !(parseInt(e.target.value) < 0)) {
      let oldValuesForModification = { ...dealOptionForModal };

      oldValuesForModification.dealOption.items[index][e.target.name] =
        e.target.value;
      setdealOptionForModal(oldValuesForModification);
    }
  };
  const onChangeDealptionForEdit = (e) => {
    let oldValuesForModification = { ...dealOptionForModal };

    oldValuesForModification.dealOption[e.target.name] = e.target.value;
    setdealOptionForModal(oldValuesForModification);
    validateModalDealOptions();
  };

  //Use Effect For Initial Rending - Initially Get Data From An API
  useEffect(() => {
    (async () => {
      let Response = await categoryService
        .getAllCategoriesForDropDown()
        .then((Responce) => {
          return Responce;
        });
      return Response;
    })().then((Response) => {
      if (Response.data) {
        //Map data array with Generic List Object with Properties id,text
        //Dropdown only accepts list of Object with Properties id,text
        var result = Response.data.data.map((dataArrayRow) => ({
          _id: dataArrayRow._id,
          name: dataArrayRow.name,
        }));
        setRecords(result);
        if (Response.data.count === 0) {
          setNotify({
            isOpen: true,
            message: 'No Categories To Load',
            type: 'error',
          });
        }
      } else {
        console.error('Unable To Get Records');
      }
    });
  }, []);

  useEffect(() => {
    if (values.dealTaxPercentage || values.dealPrice) {
      const generateDealFinalSellingPrice = () => {
        let ReceivedDealPrice = parseFloat(values.dealPrice) || 0;
        let ReceivedDealTaxPercentage =
          parseFloat(values.dealTaxPercentage) || 0;

        let TaxAmount = parseFloat(
          (ReceivedDealPrice * ReceivedDealTaxPercentage) / 100
        );

        let DealFinalPrice = parseFloat(ReceivedDealPrice + TaxAmount);

        setValues({
          ...values,
          ['dealFinalPrice']: Math.round(DealFinalPrice),
        });
      };
      generateDealFinalSellingPrice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.dealTaxPercentage, values.dealPrice]);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const addOrEdit = async (deal, resetForm) => {
    if (deal._id === 0 && deal.name && deal.dealOptions.length > 0) {
      try {
        Responce.data = null;
        Responce.error = null;
        Responce = await DealService.insertDeal(deal).then((Responce) => {
          return Responce;
        });

        if (Responce.data) {
          resetForm();

          setNotify({
            isOpen: true,
            message: 'Submitted Successfully',
            type: 'success',
          });
        }

        if (Responce.error) {
          setNotify({
            isOpen: true,
            message: Responce.messege
              ? Responce.messege
              : 'Unable To Process Request',
            type: 'error',
          });
        }
      } catch (e) {
        setNotify({
          isOpen: true,
          message: 'Unable To Process Request',
          type: 'error',
        });
      }
    }
    if (deal._id !== 0 && deal.name) {
      try {
        apiResponce.data = null;
        apiResponce.error = null;
        apiResponce = await DealService.updateDeal(deal).then((Responce) => {
          return Responce;
        });
        if (apiResponce.data) {
          resetForm();
          setNotify({
            isOpen: true,
            message: 'Deal Detail Updated Successfully',
            type: 'success',
          });
        }
        if (apiResponce.error) {
          setNotify({
            isOpen: true,
            message: 'Unable To Process Request',
            type: 'error',
          });
        }
      } catch (e) {
        setNotify({
          isOpen: true,
          message: 'Unable To Process Request',
          type: 'error',
        });
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    addOrEdit(values, resetForm);
  };

  const onDeleteDealOption = (index) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    let clonedDeal = { ...values };
    let clonedDealOptionsForSplicing = [...clonedDeal.dealOptions];
    clonedDealOptionsForSplicing.splice(index, 1);

    clonedDeal.dealOptions = clonedDealOptionsForSplicing;
    setValues(clonedDeal);
  };

  useEffect(() => {
    if (location.state.recordForEdit != null)
      setValues({ ...location.state.recordForEdit });
  }, [location.state.recordForEdit]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(values.dealOptions, headCells, filterFn);

  const addNewDealOption = () => {
    /**This count will be the next(new) At index */
    let previousValuesDealOptionCount = values.dealOptions.length;

    let newDealOption = {};
    newDealOption.dealOption = {
      dealOptionName: '',
      maxItemLimit: 0,
      items: [],
    };

    newDealOption.index = previousValuesDealOptionCount;
    newDealOption.errors = {};

    setdealOptionForModal(newDealOption);
    setOpenDealModal(true);
  };

  const showDealOptionsModal = (item, index) => {
    let old_dealOptionsItem = { ...values.dealOptions[index] };
    let newDealOptionForEdit = {};
    newDealOptionForEdit.dealOption = old_dealOptionsItem;

    newDealOptionForEdit.index = index;
    newDealOptionForEdit.errors = {};
    setdealOptionForModal(newDealOptionForEdit);
    setOpenDealModal(true);
  };

  const onDeleteDealOptionItem = (index) => () => {
    let old_dealOptionForEdit = { ...dealOptionForModal };

    let clonedDealOptionList = { ...dealOptionForModal.dealOption };
    let itemsUnderDealOptions = [...clonedDealOptionList.items];

    itemsUnderDealOptions.splice(index, 1);
    old_dealOptionForEdit.dealOption.items = itemsUnderDealOptions;

    setdealOptionForModal(old_dealOptionForEdit);
  };

  const saveDealOptions = () => {
    let currentDealOptionsForEdit = { ...dealOptionForModal };
    let currentDealOptions = { ...currentDealOptionsForEdit.dealOption };
    let currentIndex = currentDealOptionsForEdit.index;

    if (validateModalDealOptions()) {
      let currentValues = { ...values };
      let currentDealOptionInsideValues = [...currentValues.dealOptions];

      currentDealOptionInsideValues[currentIndex] = currentDealOptions;

      currentValues.dealOptions = currentDealOptionInsideValues;

      setValues(currentValues);
      setOpenDealModal(false);
      setErrors({ ...errors, dealOptions: '' });
    }
  };

  const modalBodyDealParticularOption = (
    <div style={modalStyle} className={styles.paper4}>
      <h2 id="simple-modal-title">Add/Edit Deal Options</h2>
      <IconButton
        style={{
          top: '0px',
          right: '0px',
          position: 'absolute',
        }}
        color="inherit"
        aria-label="menu"
        onClick={handleCloseDealOptionsModal}
        id="closeDealOptionModal"
      >
        <ClearIcon />
      </IconButton>
      <Form>
        <Grid container>
          <Grid item xs={12} sm={10}>
            <Controls.Input
              name="dealOptionName"
              label="Deal Option Name"
              value={dealOptionForModal.dealOption.dealOptionName}
              onChange={onChangeDealptionForEdit}
              error={dealOptionForModal.errors.dealOptionName}
              id="txtDealOptionName"
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Controls.Input
              name="maxItemLimit"
              label="Max Limit"
              value={dealOptionForModal.dealOption.maxItemLimit}
              onChange={onChangeDealptionForEdit}
              error={dealOptionForModal.errors.maxItemLimit}
              id="txtDealOptionMaxLimit"
              type="Number"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              style={{ width: '800px' }}
              size="large"
              value={valueForDeal}
              onChange={(event, newValue) => {
                setValueForDeal(newValue);

                /**On Selection Change Event */

                //Only Work if Search Deal Option Item textbox has value
                if (newValue) {
                  // Get id and name from options as per searched Deal name
                  let selectedValue = dealItemsOptions.filter((eachOption) => {
                    return eachOption.name === newValue;
                  });

                  //check if the serched Item is already present in list or not
                  let itemPresentInList = dealOptionForModal.dealOption.items.filter(
                    (eachPreviousDealOptItem) =>
                      eachPreviousDealOptItem.itemRef === selectedValue[0].id
                  );

                  //it's ok this item isn't present already
                  if (itemPresentInList.length == 0) {
                    // Get Complete Detail Of selected Item from searchedDealItems List to fetch following details
                    // Get the purchaseUnit , saleUnit and conversion unit
                    let itemDetails = searchedDealItems.filter((eachItem) => {
                      return eachItem.name == selectedValue[0].name;
                    });

                    let dealOptionItem = {
                      itemRef: itemDetails[0]._id,
                      name: itemDetails[0].name,
                      additionalPrice: 0, //Default additionalPrice
                    };

                    let oldDealptonItem = { ...dealOptionForModal };

                    let newModifiedDealptonItemsArray = [
                      ...oldDealptonItem.dealOption.items, // All Previous Items
                      dealOptionItem,
                    ];

                    oldDealptonItem.dealOption.items = newModifiedDealptonItemsArray;
                    setdealOptionForModal(oldDealptonItem);

                    //After Adding the Deal Option Item Clear the Search Text Box
                    setValueForDeal('');
                    validateModalDealOptions();
                  }
                }

                setValueForDeal('');
              }}
              inputValue={inputValueForDeal}
              onInputChange={(event, newInputValue) => {
                setInputValueForDeal(newInputValue);
              }}
              id="txtDealOptionItems"
              options={dealItemsOptions.map((option) => option.name)}
              fullWidth
              renderInput={(params) => (
                <Controls.Input
                  {...params}
                  fullWidth
                  label="Search Items For Including In Deal Option"
                  variant="outlined"
                  onChange={onChange}
                  error={dealOptionForModal.errors.items}
                  id="dealItemsSearchTextBox"
                />
              )}
            />
          </Grid>

          <Grid item sm={12}>
            {dealOptionForModal.dealOption.items.map((attribute, index) => {
              return (
                <Grid container key={index}>
                  <Grid container xs={12} sm={4}>
                    <Controls.Input
                      variant="outlined"
                      name="name"
                      label="Deal Item Name"
                      placeholder="Deal Item Name"
                      disabled
                      value={
                        attribute.itemRef && attribute.name
                          ? attribute.name
                          : ''
                      }
                      onChange={onChangeDealOptionItems(index)}
                      fullWidth
                      id={`txtDealItemName${index}`}
                    />
                  </Grid>
                  <Grid container xs={12} sm={2}>
                    <Controls.Input
                      variant="outlined"
                      name="additionalPrice"
                      label="Additional Price"
                      value={attribute.additionalPrice}
                      onChange={onChangeDealOptionItems(index)}
                      error={
                        attribute.errors
                          ? attribute.errors.additionalPrice
                            ? attribute.errors.additionalPrice
                            : ''
                          : ''
                      }
                      type="number"
                      id={`txtDealItemAdditionalPrice${index}`}
                    />
                  </Grid>
                  <Grid container xs={4} sm={3}>
                    <IconButton
                      aria-label="delete"
                      id={`btnDealItemDel${index}`}
                      color="secondary"
                      onClick={onDeleteDealOptionItem(index)}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Form>
      <Grid container>
        <Grid item xs={3} sm={4} align="left">
          <Controls.Button
            text="Cancel"
            color="default"
            onClick={handleCloseDealOptionsModal}
            startIcon={<CloseIcon />}
            id="btnCancelDealOption"
          />
        </Grid>
        <Grid item xs={6} sm={6}></Grid>
        <Grid item xs={1} sm={2} align="right">
          <div>
            <Controls.Button
              startIcon={<SaveIcon />}
              text="Save"
              id="btnSaveDealOption"
              onClick={saveDealOptions}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <>
      <PageTitle
        title={location.state.pageHeaderTitile}
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="dealDetailsHeading"
      />
      <Form onSubmit={handleSubmit}>
        <Paper elevation={0} square className="paperMargin">
          <Grid container>
            <Grid item xs={10} sm={6}>
              <Controls.Input
                name="name"
                label="Deal Name"
                value={values.name}
                onChange={handleInputChange}
                error={errors.name}
                required
                id="txtDealName"
              />
            </Grid>{' '}
            <Grid item xs={10} sm={3}>
              <Controls.Select
                name="categoriesRef"
                label="Category"
                value={values.categoriesRef}
                onChange={handleInputChange}
                options={records}
                error={errors.categoriesRef}
                required
                id="ddDealCat"
              />
            </Grid>
            <Grid item xs={10} sm={3}></Grid>
            <Grid item xs={10} sm={3}>
              <Controls.Input
                name="dealPrice"
                label="Deal Price"
                value={values.dealPrice}
                onChange={handleInputChange}
                error={errors.dealPrice}
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Rs.</InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                }}
                id="txtDealPrice"
              />
            </Grid>
            <Grid item xs={10} sm={3}>
              <Controls.Input
                name="dealTaxPercentage"
                label="Deal Tax Percentage"
                value={values.dealTaxPercentage}
                onChange={handleInputChange}
                error={errors.dealTaxPercentage}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                  min: 0,
                  max: 100,
                }}
                id="txtDealTax"
              />
            </Grid>
            <Grid item xs={10} sm={3}>
              <Controls.Input
                name="dealFinalPrice"
                label="Deal Final Price"
                value={values.dealFinalPrice}
                onChange={handleInputChange}
                error={errors.dealFinalPrice}
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Rs.</InputAdornment>
                  ),
                  readOnly: true,
                  disabled: true,
                  bold: 'true',
                  inputComponent: NumberFormatCustom,
                  min: 0,
                }}
                id="txtDealFinalPrice"
              />
            </Grid>
          </Grid>
          <Grid container></Grid>
          <Grid container>
            <Grid item xs={6} sm={12}>
              <Toolbar disableGutters>
                <h2>Deal Item Options</h2>
              </Toolbar>
              <TblContainer>
                <TblHead />
                <TableBody id="dealDetailsListTbody">
                  {recordsAfterPagingAndSorting().map((item, index) => (
                    <TableRow
                      key={randomNumberGenerator() * 100}
                      id={`dealDetailsListTrow${index}`}
                      onClick={() => {
                        showDealOptionsModal(item, index);
                      }}
                    >
                      <TableCell id={`dealOptionName${index}`}>
                        {item.dealOptionName}
                      </TableCell>
                      <TableCell id={`dealOptionTotalItems${index}`}>
                        {item.items.length}
                      </TableCell>
                      <TableCell id={`dealOptionMaxItemsSelection${index}`}>
                        {item.maxItemLimit}
                      </TableCell>
                      <TableCell align="right">
                        <Controls.ActionButton
                          color="secondary"
                          id={`btnDelDealOption${index}`}
                          name="btnDelete"
                          onClick={(event) => {
                            event.stopPropagation();
                            setConfirmDialog({
                              isOpen: true,
                              title:
                                'Are you sure you want to delete this deal Option?',
                              subTitle: "You can't undo this operation",
                              onConfirm: () => {
                                onDeleteDealOption(index);
                              },
                            });
                          }}
                        >
                          <HighlightOffIcon />
                        </Controls.ActionButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </TblContainer>
              <TblPagination />
            </Grid>

            <Grid item xs={12} sm={4}></Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} align="left">
              <Link
                component="button"
                variant="body2"
                onClick={addNewDealOption}
                id="btnAddNewDealOption"
              >
                Add New Deal Option
              </Link>
            </Grid>
            <Grid item xs={12} sm={12} align="left">
              <Typography
                id="errDealOptionEmpty"
                color="secondary"
                align="left"
                variant="caption"
              >
                {errors.dealOptions}
              </Typography>
            </Grid>
          </Grid>
        </Paper>

        <Grid container className="buttonsCotainerMargin">
          <br></br>
          <Grid item xs={3} sm={4} align="left">
            <Controls.Button
              text="Cancel"
              color="default"
              onClick={history.goBack}
              startIcon={<CloseIcon />}
              id="btnCancelDeal"
            />
          </Grid>
          <Grid item xs={6} sm={6}></Grid>
          <Grid item xs={1} sm={2} align="right">
            <div>
              <Controls.Button
                startIcon={<SaveIcon />}
                text="Save"
                id="btnSaveDeal"
                type="submit"
              />
            </div>
          </Grid>
        </Grid>
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Modal
        open={openDealModal}
        onClose={handleCloseDealOptionsModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBodyDealParticularOption}
      </Modal>
    </>
  );
}

export { DealDetails as default };
