import React from 'react';
import { getFormattedDateTime } from '../../../../Common/constants';
import styles from './sales-return.module.css';

class ComponentToPrint extends React.Component {
  render() {
    const companyName = localStorage.getItem('CompanyName');
    const companyLogo = localStorage.getItem('Company Logo');
    const salesReturnData = JSON.parse(
      window.sessionStorage.getItem('SalesReturnInvoice')
    );

    return (
      <div>
        {companyLogo && (
          <div style={{ textAlign: 'center', marginTop: '10px' }}>
            <img
              variant="square"
              className="reportCompanyLogo"
              alt="CSV Export Icon"
              src={companyLogo}
            />
          </div>
        )}
        <h1 className="reportCompanyName">{companyName}</h1>
        <p className={styles.padding2}>
          Sale Return#: STRN/{salesReturnData.invoiceNumber}
        </p>
        <p className={styles.padding3}>
          Order#: {salesReturnData.invoiceNumber}
        </p>
        <p className={styles.padding4}>
          <span>Sales</span>
          <span className={styles.floatRight}>
            {getFormattedDateTime(salesReturnData.createdAt)}
          </span>
        </p>
        <table>
          <tbody>
            <tr className={styles.trStyle}>
              <th className={styles.padding5}>Item</th>
              <th className="alignCenter">Quantity</th>
              <th className="reportTableRowRight">Amount</th>
            </tr>
            {salesReturnData.itemsSold.map((number) => (
              <tr className={styles.trStyle2} key={number._id}>
                <td className={styles.padding5}>{number.name}</td>
                <td className={styles.padding6}>{number.quantity}</td>
                <td className={styles.padding7}>
                  {parseFloat(number.saleFinalPrice).toFixed(2)}
                </td>
              </tr>
            ))}
            <tr className={styles.padding8}>
              <td />
              <td className={styles.padding9}>Total:</td>
              <td className={styles.padding10}>
                {parseFloat(salesReturnData.saleAmountSumOfItems).toFixed(2)}
              </td>
            </tr>
          </tbody>
        </table>
        {/* code if return items in data */}
        {salesReturnData.itemsReturned &&
        salesReturnData.itemsReturned.length > 0 ? (
          <div>
            <p className={styles.padding4}>
              <span>Sale Return - 1</span>
              <span className={styles.floatRight}>
                {getFormattedDateTime(salesReturnData.updatedAt)}
              </span>
            </p>
            <table>
              <tbody>
                <tr className={styles.trStyle}>
                  <th className={styles.padding5}>Item</th>
                  <th className={styles.alignCenter}>Quantity</th>
                  <th className={styles.padding7}>Amount</th>
                </tr>
                {salesReturnData.itemsReturned.map((number) => (
                  <tr className={styles.trStyle2} key={number._id}>
                    <td className={styles.padding5}>{number.name}</td>
                    <td className={styles.padding6}>-{number.quantity}</td>
                    <td className={styles.padding7}>
                      -{parseFloat(number.returnFinalPrice).toFixed(2)}
                    </td>
                  </tr>
                ))}
                <tr className={styles.padding8}>
                  <td />
                  <td className={styles.padding9}>Total:</td>
                  <td className={styles.padding10}>
                    -
                    {parseFloat(
                      salesReturnData.returnedAmountSumOfItems
                    ).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <></>
        )}

        <br />
        <br />
        <br />
        <br />
        <p className={styles.footerStyle}>
          Thank You For Your Visit. Please Come Again.
        </p>
        <p className={styles.alignCenter}>Software provided by: ConcavePOS</p>
      </div>
    );
  }
}

export default ComponentToPrint;
