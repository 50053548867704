import { Paper, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Controls from '../../Utilities/Controls/Controls';
import { useForm, Form } from '../../Utilities/ReuseableCommponents/useForm';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import PageHeader from '../../Layout/PageTitle';
import { useHistory, useLocation } from 'react-router-dom';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import * as userService from '../User/UserService';
import salesService from '../../Pages/POS/SalesAPIService';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { GetTokenValue } from '../../../Common/constants';
import useStyles from '../../../Style/AppStylesAdmin';

export default function UserDetails() {
  const initialFValues = {
    _id: 0,
    name: '',
    role: '',
    type: localStorage.getItem('type'),
    email: '',
    password: '',
    repeatPassword: '',
    companyName: GetTokenValue('CompanyName'),
    DataBaseName: GetTokenValue('DataBaseName'),
    discountTypeId: '1',
    discountLimitAmount: '',
    discountLimitPercentage: '',
    maxDiscLimit: '0',
    phoneNumber: '',
  };
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });
  const [isEmailDisabled, setIsEmailDisabled] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  useEffect(() => {
    if (location.state.recordForEdit) {
      location.state.recordForEdit.password = '';
      setDiscountTypeId(
        location.state.recordForEdit.discountLimitAmount ? 1 : 2
      );
      setValues({
        ...location.state.recordForEdit,
      });
      setIsEmailDisabled(true);
    }
  }, [location.state.recordForEdit]); // eslint-disable-line react-hooks/exhaustive-deps
  const [disabled, setDisabled] = useState(true);
  const [pswdTxt, setPswdTxt] = useState('');
  const [pswdTxtRpt, setPswdTxtRpt] = useState('');
  const [viewPswd, setViewPswd] = useState('password');
  const [viewPswdRpt, setViewPswdRpt] = useState('password');
  const [isError, setisError] = useState(false);
  const [discountTypeId, setDiscountTypeId] = useState(1);
  const [maxDiscLimit, setMaxDiscLimit] = useState((0).toFixed(2));
  const [isNew, setNew] = useState(true);
  let repeatPasswordRequiredFlag = false;

  useEffect(() => {
    if (
      values.discountLimitAmount === undefined &&
      values.discountLimitPercentage === undefined
    ) {
      setMaxDiscLimit((0).toFixed(2));
      values.discountLimitAmount = values.discountLimitPercentage = (0).toFixed(
        2
      );
    } else if (
      (values.discountLimitAmount === null ||
        values.discountLimitAmount === undefined) &&
      values.discountLimitPercentage >= 0
    ) {
      setMaxDiscLimit(values.discountLimitPercentage);
      values.discountLimitAmount = null;
    } else if (
      values.discountLimitAmount >= 0 &&
      (values.discountLimitPercentage === null ||
        values.discountLimitPercentage === undefined)
    ) {
      setMaxDiscLimit(values.discountLimitAmount);
      values.discountLimitPercentage = null;
    } else {
      setMaxDiscLimit(
        values.discountLimitAmount || values.discountLimitPercentage
      );
    }
    values._id !== 0 ? setNew(false) : setNew(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { values, setValues, handleInputChange, resetForm } = useForm(
    initialFValues
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    var isPasswordChange = pswdTxt == '' ? false : true;
    var userUpdate = {};

    if (!isNew) {
      if (pswdTxt) {
        if (pswdTxtRpt === pswdTxt) {
          if (discountTypeId == 1) {
            userUpdate = {
              _id: values._id,
              role: values.role,
              type: values.type ? values.type : localStorage.getItem('type'),
              email: values.email,
              password: pswdTxt,
              name: values.name,
              companyName: values.companyName,
              DataBaseName: GetTokenValue('DataBaseName'),
              discountLimitAmount: maxDiscLimit,
              phoneNumber: values.phoneNumber,
            };
          } else {
            userUpdate = {
              _id: values._id,
              role: values.role,
              type: values.type ? values.type : localStorage.getItem('type'),
              email: values.email,
              password: pswdTxt,
              name: values.name,
              companyName: values.companyName,
              DataBaseName: GetTokenValue('DataBaseName'),
              discountLimitPercentage: maxDiscLimit,
              phoneNumber: values.phoneNumber,
            };
          }
          insertEdit(userUpdate, resetForm);
        } else {
          setNotify({
            isOpen: true,
            message: 'Password and repeat password not matched.',
            type: 'error',
          });
          return;
        }
      } else {
        setNotify({
          isOpen: true,
          message: 'Must enter Password and repeat password.',
          type: 'error',
        });
        return;
      }
    } else {
      if (isPasswordChange) {
        if (pswdTxtRpt === pswdTxt) {
          if (discountTypeId == 1) {
            userUpdate = {
              _id: values._id,
              role: values.role,
              type: values.type ? values.type : localStorage.getItem('type'),
              email: values.email,
              password: values.password,
              name: values.name,
              companyName: values.companyName,
              DataBaseName: GetTokenValue('DataBaseName'),
              discountLimitAmount: maxDiscLimit,
              phoneNumber: values.phoneNumber,
            };
          } else {
            userUpdate = {
              _id: values._id,
              role: values.role,
              type: values.type ? values.type : localStorage.getItem('type'),
              email: values.email,
              password: values.password,
              name: values.name,
              companyName: values.companyName,
              DataBaseName: GetTokenValue('DataBaseName'),
              discountLimitPercentage: maxDiscLimit,
              phoneNumber: values.phoneNumber,
            };
          }
          insertEdit(userUpdate, resetForm);
        } else {
          setNotify({
            isOpen: true,
            message: 'Password and repeat password not matched.',
            type: 'error',
          });
          return;
        }
      } else {
        if (discountTypeId == 1) {
          userUpdate = {
            _id: values._id,
            role: values.role,
            type: values.type ? values.type : localStorage.getItem('type'),
            email: values.email,
            name: values.name,
            companyName: values.companyName,
            DataBaseName: GetTokenValue('DataBaseName'),
            discountLimitAmount: maxDiscLimit,
            phoneNumber: values.phoneNumber,
          };
        } else {
          userUpdate = {
            _id: values._id,
            role: values.role,
            type: values.type ? values.type : localStorage.getItem('type'),
            email: values.email,
            name: values.name,
            companyName: values.companyName,
            DataBaseName: GetTokenValue('DataBaseName'),
            discountLimitPercentage: maxDiscLimit,
            phoneNumber: values.phoneNumber,
          };
        }
        insertEdit(userUpdate, resetForm);
      }
    }
  };

  const handleInputChangeMaxDiscLimit = (e) => {
    setMaxDiscLimit(e.target.value);

    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleInputCheckChange = (e) => {
    setDisabled(!e.target.value);
    if (!e.target.value) {
      values.password = '';
      values.repeatPassword = '';
    }
  };

  const handleInputDropDownChange = (e) => {
    if (e.target.name != 'role') {
      setDiscountTypeId(e.target.value);
    }

    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleClickShowPassword = () => {
    if (!disabled || isNew) {
      if (viewPswd == 'password') {
        setViewPswd('text');
      } else {
        setViewPswd('password');
      }
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPasswordRepeat = () => {
    if (!disabled || isNew) {
      if (viewPswdRpt == 'password') {
        setViewPswdRpt('text');
      } else {
        setViewPswdRpt('password');
      }
    }
  };

  const handleInputChangePswd = (e) => {
    handleInputChange(e);
    if (e.target.name === 'password') {
      setPswdTxt(e.target.value);
      if (e.target.value != pswdTxtRpt) {
        setisError(true);
      } else {
        setisError(false);
      }
    }
    if (e.target.name === 'repeatPassword') {
      setPswdTxtRpt(e.target.value);
      if (e.target.value != pswdTxt) {
        setisError(true);
      } else {
        setisError(false);
      }
    }
  };

  const handleMouseDownPasswordRepeat = (event) => {
    event.preventDefault();
    event.preventDefault();
  };
  async function createUser(user, formReset) {
    await userService.insertUser(user).then((response) => {
      if (response.data) {
        formReset();
        salesService.GetUserDiscount();
        setNotify({
          isOpen: true,
          message: 'User Created Successfully.',
          type: 'success',
        });
      } else if (response.statusCode === 409) {
        setNotify({
          isOpen: true,
          message: 'Record Already Present, Please Try With Different Details',
          type: 'error',
        });
      } else {
        setNotify({
          isOpen: true,
          message: 'Unable To Process Request.',
          type: 'error',
        });
      }
    });
  }
  async function updateUser(user) {
    await userService.updateUser(user).then((response) => {
      if (response.data) {
        salesService.GetUserDiscount();
        setNotify({
          isOpen: true,
          message: 'User Detail Updated Successfully.',
          type: 'success',
        });
      } else {
        setNotify({
          isOpen: true,
          message: 'Unable To Process Request.',
          type: 'error',
        });
      }
    });
  }
  const insertEdit = async (user, formReset) => {
    if (user._id == 0) {
      delete user._id;
      repeatPasswordRequiredFlag = true;
      createUser(user, formReset);
    } else if (user._id !== 0) {
      updateUser(user);
    }

    if (discountTypeId === 1) {
      const Discount = {
        Discount: [
          {
            DiscountName: 'discountLimitAmount',
            DiscountLimit: maxDiscLimit,
          },
        ],
      };

      localStorage.setItem('_DiscountInfo', JSON.stringify(Discount));
    } else {
      const Discount = {
        Discount: [
          {
            DiscountName: 'discountLimitPercentage',
            DiscountLimit: maxDiscLimit,
          },
        ],
      };

      localStorage.setItem('_DiscountInfo', JSON.stringify(Discount));
    }
  };

  return (
    <>
      <PageHeader
        id="userAddHeading"
        title={location.state.pageHeaderTitile}
        icon={<ViewListOutlinedIcon fontSize="large" />}
      />
      <Paper elevation={6} square className={classes.PaperMargin}>
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12} sm={6} md={4}>
              <Controls.Input
                name="name"
                label="Name"
                value={values.name}
                autoComplete="off"
                onChange={handleInputChange}
                id="txtUserName"
                required
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Controls.Input
                name={
                  values.discountLimitAmount === undefined ||
                  values.discountLimitAmount === null
                    ? 'discountLimitPercentage'
                    : 'discountLimitAmount'
                }
                label="Max Adjustment"
                value={
                  values.discountLimitAmount === undefined ||
                  values.discountLimitAmount === null
                    ? values.discountLimitPercentage
                    : values.discountLimitAmount
                }
                id="txtUserMaxAdjustment"
                onChange={handleInputChangeMaxDiscLimit}
              />
            </Grid>

            <Grid item xs={0} md={4}></Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Controls.Input
                name="companyName"
                label="Company Name"
                value={values.companyName}
                id="txtUserCompanyName"
                disabled
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Controls.Select
                name="ddlDiscountTypeId"
                label="Limit Type"
                value={discountTypeId}
                onChange={handleInputDropDownChange}
                options={userService.getDiscountType()}
                id="ddUserLimitType"
              />
            </Grid>

            <Grid item xs={0} md={4}></Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Controls.Input
                name="email"
                label="Email"
                type="email"
                autoComplete="off"
                value={values.email}
                id="txtUserEmail"
                helperText="example@domain.com"
                disabled={isEmailDisabled}
                onChange={handleInputChange}
                required
              />
            </Grid>

            {isNew ? (
              <Grid item xs={12} sm={6} md={4}>
                <Controls.Input
                  name="password"
                  label="Password"
                  autoComplete="off"
                  type={viewPswd}
                  value={values.password}
                  helperText={'Minimum 8 characters required'}
                  id="txtUserPassword"
                  disabled={!isNew}
                  onChange={handleInputChangePswd}
                  error={isError}
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {viewPswd == 'password' ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            ) : (
              <Grid item xs={12} sm={6} md={4}>
                <Controls.Checkbox
                  name="changePassword"
                  label="Change Password"
                  onChange={handleInputCheckChange}
                  id="chkUserChangePassword"
                />
              </Grid>
            )}

            <Grid item xs={0} md={4}></Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Controls.Select
                name="role"
                label="Role"
                value={values.role}
                onChange={handleInputDropDownChange}
                options={userService.getRole(GetTokenValue('role'))}
                id="ddUserRole"
                required
                InputLabelProps={{
                  classes: {
                    asterisk: classes.asterisk,
                  },
                }}
              />
            </Grid>

            {isNew ? (
              <Grid item xs={12} sm={6} md={4}>
                <Controls.Input
                  name="repeatPassword"
                  label="Repeat Password"
                  type={viewPswdRpt}
                  value={values.repeatPassword}
                  id="txtUserRPassword"
                  disabled={!isNew}
                  helperText={'Minimum 8 characters required'}
                  onChange={handleInputChangePswd}
                  error={isError}
                  autoComplete="off"
                  required={repeatPasswordRequiredFlag}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPasswordRepeat}
                          onMouseDown={handleMouseDownPasswordRepeat}
                        >
                          {viewPswdRpt == 'password' ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            ) : (
              <Grid item xs={12} sm={6} md={4}>
                <Controls.Input
                  name="password"
                  label="Password"
                  type={viewPswd}
                  helperText={'Minimum 8 characters required'}
                  value={values.password}
                  id="txtUserPassword"
                  autoComplete="off"
                  disabled={disabled}
                  onChange={handleInputChangePswd}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {viewPswd == 'password' ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}

            <Grid item xs={0} md={4}></Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Controls.Input
                name="phoneNumber"
                label="Phone Number"
                inputProps={{
                  className: classes.invalid,
                  pattern: '^[0][0-9]{10}$|^[1-9]{9}$',
                }}
                helperText={'Mobile: 03XXYYYYYYY Landline: 0XXYYYYYYY'}
                value={values.phoneNumber}
                id="txtUserPhone"
                onChange={handleInputChange}
              />
            </Grid>

            {isNew ? (
              <Grid item xs={0} md={4}></Grid>
            ) : (
              <Grid item xs={12} md={4}>
                <Controls.Input
                  name="repeatPassword"
                  helperText={'Minimum 8 characters required'}
                  label="Repeat Password"
                  type={viewPswdRpt}
                  value={values.repeatPassword}
                  id="txtUserRPassword"
                  autoComplete="off"
                  disabled={disabled}
                  onChange={handleInputChangePswd}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPasswordRepeat}
                          onMouseDown={handleMouseDownPasswordRepeat}
                        >
                          {viewPswdRpt == 'password' ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}

            <Grid item xs={0} sm={6} md={4}></Grid>
            <Grid item>
              <Controls.Button
                text="Cancel"
                color="default"
                onClick={history.goBack}
                id="btnCancelUser"
                startIcon={<CloseIcon />}
              />
            </Grid>
            <Grid item style={{ marginLeft: 'auto' }}>
              <div>
                <Controls.Button
                  type="submit"
                  text="Save"
                  id="btnSaveUser"
                  startIcon={<SaveIcon />}
                />
              </div>
            </Grid>
          </Grid>
        </Form>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
