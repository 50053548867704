import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

export default function ControllableStateEditableDropDown(props) {
  const {
    data,
    label,
    id,
    onChange,
    others,
    value,
    customWidth = null,
    customMargin = null,
    required = false,
  } = props;
  const useStyles = makeStyles(() => ({
    inputRoot: {
      width: customWidth || '220px',
      height: '39px',
      marginRight: '6px',
      margin: customMargin,
    },
    asterisk: {
      color: 'red',
    },
    input: {
      padding: '0px!important',
      paddingLeft: '10px!important',
    },
  }));
  const [inputValue, setInputValue] = React.useState('');

  const classes = useStyles();
  return (
    <Autocomplete
      value={value}
      classes={{
        inputRoot: classes.inputRoot,
        input: classes.input,
      }}
      onChange={onChange}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      id={id}
      options={data}
      getOptionLabel={(option) => (option.name ? option.name : '')}
      {...others}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          required={required}
          InputLabelProps={{
            classes: {
              asterisk: classes.asterisk,
            },
          }}
        />
      )}
    />
  );
}

ControllableStateEditableDropDown.propTypes = {
  data: PropTypes.array,
  field: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.any,
  customWidth: PropTypes.string,
  customMargin: PropTypes.string,
  others: PropTypes.object,
  required: PropTypes.bool,
};
