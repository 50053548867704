/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import { useHistory } from 'react-router-dom';
import AuthService from './AuthService';
import Logo from '../../../../Assets/Images/ConcaveLogo.png';
import Notification from '../../../Utilities/ReuseableCommponents/Notification';
import styles from './Signin.module.css';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Copyright } from '../../../../Common/constants';
import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import Link from '@material-ui/core/Link';
import { getSetupWithName } from '../../Setups/SetupService';
import activityMonitoring from '../../../../Common/functions';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: 'auto',
  },
  flex: {
    display: 'flex',
  },
  paper2: {
    position: 'absolute',
    width: 'auto',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowX: 'scroll',
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const [changePasswordEmail, setChangePasswordEmail] = useState('');
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [password, setPassword] = useState('');
  const [errorConfirmPassword, setErrorConfirmPassword] = useState(null);
  const history = useHistory();
  const [isVerifiedTenant, setIsVerifiedTenant] = useState(false);
  const [organizationName, setOrganizationName] = useState('');
  const [errors, setErrors] = useState(null);
  const [viewPswd, setViewPswd] = useState('password');
  const [openForgotPasswordModal, setOpenForgotPasswordModal] = React.useState(
    false
  );
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [isFetching, setIsFetching] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    setIsFetching(true);
    AuthService.login(email, password, organizationName)
      .then(async (res) => {
        setIsFetching(false);
        if (res === 'OK') {
          await getSetupWithName('CompanyContactNumber').then((res) => {
            if (
              res &&
              res.data &&
              res.data.data &&
              res.data.data[0] &&
              res.data.data[0].data &&
              res.data.data[0].data[0] &&
              res.data.data[0].data[0].name
            ) {
              localStorage.setItem(
                'CompanyContactNumber',
                res.data.data[0].data[0].name
              );
            } else {
              localStorage.setItem('CompanyContactNumber', '03xx-xxxxxxx');
            }
          });

          await getSetupWithName('Commission').then((response) => {
            if (response && response.data && response.data?.data?.length > 0) {
              localStorage.setItem(
                '_Commission',
                response.data.data[0].data[0].value
              );
            } else {
              localStorage.setItem('_Commission', false);
            }
          });

          await getSetupWithName('Hide Features').then((response) => {
            if (response && response.data && response.data?.data?.length > 0) {
              localStorage.setItem(
                '_HideFeature',
                JSON.stringify(response.data.data[0].data)
              );
            }
          });

          AuthService.getOrganizationName();
          const userInfo = await AuthService.explodeTokenAndSaveExtraInfo();
          if (userInfo.role === 'cashier' || userInfo.role === 'agent') {
            if (userInfo.type === 'food') {
              history.push('/POSFood');
            } else {
              activityMonitoring('SignIn');
              history.push('/POS');
            }
          } else {
            activityMonitoring('SignIn');
            history.push('/');
          }
        } else {
          setNotify({
            isOpen: true,
            message: 'Invalid email and password combination',
            type: 'error',
          });
          event.preventDefault();
        }
      })
      .catch((err) => console.error(err));
  }

  function handleTenant(e) {
    e.preventDefault();
    setIsFetching(true);
    AuthService.tenantExists(organizationName).then(
      (response) => {
        setIsFetching(false);
        setOrganizationName(response.tanent);
        setIsVerifiedTenant(true);
      },
      () => {
        setIsFetching(false);
        setErrors('Organization does not exist');
      }
    );
  }

  function handlePasswordMatch() {
    if (confirmNewPassword !== newPassword) {
      setErrorConfirmPassword("Passwords don't match.");
    } else {
      setErrorConfirmPassword(null);
    }
  }

  function handleChangePassword() {
    if (errorConfirmPassword === null) {
      AuthService.forgetPassword(
        organizationName,
        changePasswordEmail,
        newPassword
      ).then(
        (status) => {
          if (status === 201) {
            setChangePasswordEmail('');
            setNewPassword('');
            setConfirmNewPassword('');
            setOpenForgotPasswordModal(false);
            setNotify({
              isOpen: true,
              message: 'Change password request sent successfully',
              type: 'success',
            });
          }
        },
        () => {
          setNotify({
            isOpen: true,
            message: 'Change password request not sent',
            type: 'error',
          });
        }
      );
    }
  }

  function handleCancel() {
    setChangePasswordEmail('');
    setNewPassword('');
    setConfirmNewPassword('');
    setOpenForgotPasswordModal(false);
  }

  function handleCloseModal() {
    setOpenForgotPasswordModal(false);
  }

  const handleClickShowPassword = () => {
    if (viewPswd == 'password') {
      setViewPswd('text');
    } else {
      setViewPswd('password');
    }
  };

  const modalBodyForgetPassword = (
    <div className={classes.paper2}>
      <h2 id="simple-modal-title">Change Password</h2>
      <IconButton
        style={{
          top: '0px',
          right: '0px',
          position: 'absolute',
        }}
        color="inherit"
        aria-label="menu"
        onClick={handleCancel}
        id="btnCloseModal"
      >
        <ClearIcon />
      </IconButton>
      <div
        style={{
          textAlign: 'center',
          marginTop: '50px',
        }}
      >
        <TextField
          id="txtChPassEmail"
          label="Email"
          variant="outlined"
          color="secondary"
          required
          value={changePasswordEmail}
          onChange={(e) => setChangePasswordEmail(e.target.value)}
          style={{
            marginLeft: 'auto',
            width: '60%',
          }}
          autoComplete="off"
        />
        <TextField
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          variant="outlined"
          margin="normal"
          required
          style={{ width: '60%' }}
          name="password"
          label="New Password"
          type="password"
          id="txtChPassNewPassword"
          autoComplete="current-password"
        />
        <TextField
          value={confirmNewPassword}
          onKeyUp={handlePasswordMatch}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
          variant="outlined"
          margin="normal"
          required
          style={{ width: '60%' }}
          name="password"
          label="Confirm New Password"
          type="password"
          id="txtChPassConfirmNewPassword"
          autoComplete="current-password"
          {...(errorConfirmPassword && {
            error: true,
            helperText: errorConfirmPassword,
          })}
        />
        <div
          className={classes.flex}
          style={{
            position: 'relative',
          }}
        >
          <Button
            variant="contained"
            onClick={handleCancel}
            id="btnChPassCancel"
            style={{
              height: '40px',
              width: 'auto',
              top: '10px',
              position: 'relative',
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => handleChangePassword()}
            id="btnChPassOk"
            style={{
              height: '40px',
              width: 'auto',
              top: '10px',
              position: 'relative',
              marginLeft: 'auto',
            }}
            color="primary"
          >
            OK
          </Button>
        </div>
      </div>
    </div>
  );
  return (
    <div className={styles.container}>
      <div>
        <div className={styles.signinContainer}>
          <div className={styles.signinElementsBox}>
            <img
              className={styles.imgContainer}
              alt="Concave POS logo"
              src={Logo}
            />
            {isFetching ? (
              <LinearProgress
                className={styles.isFetchingProgressBar}
              ></LinearProgress>
            ) : (
              <></>
            )}
            <Modal
              open={openForgotPasswordModal}
              onClose={handleCloseModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              {modalBodyForgetPassword}
            </Modal>

            {isVerifiedTenant ? (
              <form onSubmit={handleSubmit}>
                <TextField
                  value={organizationName}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  disabled
                  autoComplete="off"
                  name="OrganizationNameVerified"
                  label="Organization Name"
                  id="txtSignInOrganizationVerified"
                />
                <TextField
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="txtSignInEmail"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={viewPswd}
                  id="txtSignInPassword"
                  autoComplete="current-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {viewPswd == 'password' ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  type="submit"
                  fullWidth
                  id="btnSignIn"
                  variant="contained"
                  color="primary"
                >
                  Sign In
                </Button>
                <Link
                  component="button"
                  variant="body2"
                  id="btnForgotPassword"
                  onClick={() => setOpenForgotPasswordModal(true)}
                >
                  Forgot password?
                </Link>
              </form>
            ) : (
              <form onSubmit={handleTenant}>
                <TextField
                  value={organizationName}
                  onChange={(e) => setOrganizationName(e.target.value)}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  autoComplete="off"
                  name="OrganizationName"
                  label="Organization Name"
                  id="txtSignInOrganization"
                  {...(errors && { error: true, helperText: errors })}
                />
                <Button
                  type="submit"
                  fullWidth
                  id="btnSignIn"
                  variant="contained"
                  color="primary"
                  className={styles.formElement}
                >
                  Next
                </Button>
              </form>
            )}
          </div>
        </div>
        <div className={styles.copyrightbox}>
          <Copyright />
        </div>
      </div>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
