import React from 'react';
import { Paper, TextField, Button } from '@material-ui/core';
import PageHeader from '../../Layout/PageTitle';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import {
  getDateTime,
  getFormattedDateTime as ISOdateFormatter,
} from '../../../Common/constants';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import APIService from './InventoryService';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useLocation, useHistory } from 'react-router-dom';

function PurchaseReturnInventoryDetails() {
  const location = useLocation();
  const history = useHistory();
  const [rows, setRows] = React.useState(location.state.Data.items);

  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: '',
    type: '',
  });

  function controlReturnQty(e, index, maxValue) {
    if (e.target.value > maxValue) {
      e.target.value = maxValue;
    }
    let newRows = [...rows];
    newRows[index].returnQty = e.target.value;
    setRows(newRows);
  }

  async function SendInventoryUpdates(dataSet) {
    return APIService.createInventory(dataSet);
  }

  function saveInventory() {
    var FinalLIST = [];
    var sum = 0;
    var expectedCountSum = 0;
    rows.forEach((item) => {
      if (item.actualCount > 0 && item.purchaseUnitPrice > '0') {
        sum = sum + (parseInt(item.actualCount) - parseInt(item.returnQty));
        expectedCountSum = expectedCountSum + parseInt(item.actualCount);
        FinalLIST.push({
          itemRef: item.itemRef._id,
          expectedCount: parseInt(item.actualCount),
          actualCount: parseInt(item.actualCount) - parseInt(item.returnQty),
          comments: item.comments,
          inventoryType: 'purchasereturn',
          vendorRef: item?.vendorRef?._id,
        });
      }
    });
    const dataSet = {
      items: FinalLIST,
      expectedCountTotal: expectedCountSum,
      actualCountTotal: sum,
      createdAt: getDateTime(),
      updatedAt: getDateTime(),
    };
    SendInventoryUpdates(dataSet).then((Response) => {
      if (Response.data) {
        setNotify({
          isOpen: true,
          message: 'Inventory Successfully Updated',
          type: 'success',
        });
      }
    });
  }

  return (
    <>
      <PageHeader
        title="Return Inventory"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="purRetInvDetailsHeading"
      />
      <Paper elevation={6} square className="paperMargin">
        <TextField
          id="txtPurRetInvDetailsDetailsDate"
          label="Date"
          disabled={true}
          variant="outlined"
          value={ISOdateFormatter(location.state.Data.updatedAt)}
          className="searchInput"
          InputLabelProps={{
            shrink: true,
          }}
          autoComplete="off"
        />
      </Paper>
      <Paper elevation={6} square className="paperMargin">
        <TableContainer>
          <Table
            id="purRetInvDetailsTable"
            className="table"
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">Product</TableCell>
                <TableCell align="left">Category</TableCell>
                <TableCell align="center">Barcode</TableCell>
                <TableCell align="center">Vendor</TableCell>
                <TableCell align="center">Unit Of Sale</TableCell>
                <TableCell align="center">Purchased Qty</TableCell>
                <TableCell align="center">Unit Price</TableCell>
                <TableCell align="center">Total Price</TableCell>
                <TableCell align="center">Return Qty</TableCell>
                <TableCell align="left">Comments</TableCell>
              </TableRow>
            </TableHead>
            <TableBody id="purRetInvDetailsTbody">
              {rows.map((row, index) => (
                <TableRow key={index} id={'purRetInvDetailsTrow' + index}>
                  <TableCell align="left" id={'itemName' + index}>
                    {row.itemRef !== null
                      ? row.itemRef.name || ''
                      : 'Not Available (Item Deleted)'}
                  </TableCell>
                  <TableCell align="center" id={'itemCat' + index}>
                    {row.itemRef !== null
                      ? row.itemRef.categoriesRef[0].name || ''
                      : '--'}
                  </TableCell>
                  <TableCell align="center" id={'itemBarcode' + index}>
                    {row.itemRef !== null ? row.itemRef.barcode || '' : '--'}
                  </TableCell>
                  <TableCell align="left" id={'vendorName' + index}>
                    {row.vendorRef && row.itemRef !== null
                      ? row.vendorRef.name || ''
                      : '--'}
                  </TableCell>
                  <TableCell align="left">
                    {row.itemRef !== null ? row.itemRef.unitOfSale || '' : '--'}
                  </TableCell>
                  <TableCell align="center" id={'purchasedQty' + index}>
                    {row.actualCount - row.expectedCount}
                  </TableCell>
                  <TableCell align="center" id={'unitPrice' + index}>
                    {row.purchaseUnitPrice}
                  </TableCell>
                  <TableCell align="center" id={'totalPrice' + index}>
                    {row.purchaseUnitPrice *
                      (row.actualCount - row.expectedCount)}
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      id={'txtReturnQty' + index}
                      value={row.returnQty}
                      onChange={(e) =>
                        controlReturnQty(
                          e,
                          index,
                          row.actualCount - row.expectedCount
                        )
                      }
                      variant="outlined"
                      autoComplete="off"
                      disabled={row.itemRef !== null ? false : true}
                    />
                  </TableCell>
                  <TableCell align="left">
                    {row.itemRef !== null ? row.comments : '--'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div className="actionButtonDiv">
            <Button
              variant="contained"
              onClick={() => {
                history.push({
                  pathname: '/PurchaseReturnInventory',
                });
              }}
              className="actionBtnMargin"
              id="btnBackPurRetInvDetails"
            >
              Back
            </Button>
            <Button
              className="actionBtnMargin"
              color="primary"
              variant="contained"
              onClick={saveInventory}
              id="btnSavePurRetInvDetails"
            >
              Save
            </Button>
          </div>
        </TableContainer>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}

export default PurchaseReturnInventoryDetails;
