const NAYAPAY_ISSUER_ID = 'NPAYPK';
const DEFAULT_ISO_CURRENCY = '586';
const DEFAULT_NAYAPAY_MERCHANT_NAME = 'STORMFIBER';
const DEFAULT_NAYAPAY_MERCHANT_ID = '5f083838789e8152390485a4';

/**
 * function to generate payload for dynamic retail QR code
 * @param merchantName
 * merchant name. null for default
 * @param merchantID
 * merchant ID. null for default
 * @param invoiceID
 * merchant given invoice ID
 * @param currency
 * 3-digit ISO currency code. null for default
 * @param amount
 * amount to be paid
 * @param tipFlag
 * flag to enable tip
 * @param tipFlatFlag
 * true for flat. false for percentage
 * @param tipValue
 * value of tip. -1 to ask consumer
 * @param terminalID
 * merchant terminalID
 * @param userID
 * merchant userID or user CNIC
 * @return
 * EMV complaint QR code string
 */
export default function generateRetailDynamicCode(
  merchantName,
  merchantID,
  invoiceID,
  currency,
  amount,
  tipFlag,
  tipFlatFlag,
  tipValue,
  terminalID,
  userID
) {
  if (merchantName == null || merchantName.trim().length == 0)
    merchantName = DEFAULT_NAYAPAY_MERCHANT_NAME;
  else merchantName = merchantName.trim();

  if (merchantID == null || merchantID.trim().length == 0)
    merchantID = DEFAULT_NAYAPAY_MERCHANT_ID;
  else merchantID = merchantID.trim();

  invoiceID = invoiceID.trim();
  if (invoiceID.length == 0) return null;

  if (
    currency == null ||
    currency.trim().length == 0 ||
    currency.trim().length != 3
  )
    currency = DEFAULT_ISO_CURRENCY;
  else currency = currency.trim();

  if (amount < 0) return null;

  if (tipFlag && tipValue != -1 && tipValue < 0) return null;

  terminalID = terminalID.trim();
  userID = userID.trim();

  var root =
    '00' +
    addPayloadLength('01') + //EMV version 1.0
    '01' +
    addPayloadLength('12') + //dynamic
    '53' +
    addPayloadLength(currency) + //currency
    '54' +
    addPayloadLength(parseInt(amount)) + //amount
    '59' +
    addPayloadLength(merchantName); //merchant name

  if (tipFlag) {
    if (tipFlatFlag) {
      root += '55' + addPayloadLength('56');
      if (tipValue == -1) root += '56' + addPayloadLength('***');
      else root += '56' + addPayloadLength(parseInt(tipValue).toString());
    } else {
      root += '55' + addPayloadLength('57');
      if (tipValue == -1) root += '57' + addPayloadLength('***');
      else root += '57' + addPayloadLength(parseInt(tipValue).toString());
    }
  }

  //ID:26
  var routing =
    '26' +
    addPayloadLength(
      '00' +
        addPayloadLength('com.nayapay') +
        '01' +
        addPayloadLength(NAYAPAY_ISSUER_ID) +
        '02' +
        addPayloadLength(DEFAULT_NAYAPAY_MERCHANT_ID)
    );

  //ID:62
  var dataField =
    '00' + addPayloadLength(getUUID()) + '01' + addPayloadLength(invoiceID);

  if (terminalID.length != 0) dataField += '07' + addPayloadLength(terminalID);

  //ID:62 -> ID:50
  var custom = '00' + addPayloadLength(getUUID());

  if (userID.length != 0) custom += '01' + addPayloadLength(userID);

  custom = '50' + addPayloadLength(custom);

  var payload =
    root + routing + '62' + addPayloadLength(dataField + custom) + '6304';

  return payload + generateChecksum(payload);
}
function addPayloadLength(payload) {
  var len = payload.toString().length;
  if (len < 10) return '0' + len + payload;
  return len + payload;
}
function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;

    return v.toString(16);
  });
}
function getUUID() {
  return uuidv4().replaceAll('-', '');
}
function generateChecksum(payload) {
  var crc = 0xffff;
  var polynomial = 0x1021;
  var bytes = payload.split('').map(function (s) {
    return s.charCodeAt(0);
  });
  for (var index8710 = 0; index8710 < bytes.length; index8710++) {
    var b = bytes[index8710];
    {
      for (var i = 0; i < 8; i++) {
        {
          var bit = ((b >> (7 - i)) & 1) === 1;
          var c15 = ((crc >> 15) & 1) === 1;
          crc <<= 1;
          if (c15 !== bit) crc ^= polynomial;
        }
      }
    }
  }
  crc &= 0xffff;
  return parseInt(crc).toString(16).toUpperCase();
}
