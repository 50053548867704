import React from 'react';
import { Button, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  Add,
  ClearOutlined,
  RemoveShoppingCart,
  PersonAdd,
  Edit,
} from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

function AddCustomerButtonDisable(shiftButton, IsOdms) {
  let isDisable;
  if (!shiftButton && !IsOdms) {
    isDisable = true;
  } else if (!shiftButton && IsOdms) {
    isDisable = false;
  }
  return isDisable;
}

function NewOrderButtonDisable(shiftButton, IsOdms, totalItems) {
  let isDisable;
  if (!shiftButton && !IsOdms) {
    isDisable = true;
  } else if (!shiftButton && IsOdms) {
    isDisable = false;
  }
  if (totalItems > 0) {
    isDisable = true;
  }
  return isDisable;
}

const CartHeaderButtons = ({
  newOrderButtonClick,
  shiftButton,
  isCustomerAttached,
  customerinfo,
  handleCustomerremove,
  openCustomerModal,
  totalItems,
  IsOdms,
  ResetCart,
  setOpenNewCustomerComp,
  setCustomerInformation,
  custInfo,
}) => {
  return (
    <Box
      p={1}
      mx={2}
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Button
        onClick={() => {
          newOrderButtonClick();
        }}
        style={{
          margin: '0 5px',
        }}
        color="primary"
        variant="contained"
        aria-label="contained primary button group"
        id="btnFoodNewOrder"
        disabled={NewOrderButtonDisable(shiftButton, IsOdms, totalItems)}
      >
        <Add />
        &nbsp;NEW ORDER
      </Button>

      {isCustomerAttached ? (
        <>
          <div
            style={{
              display: 'inline-flex',
              border: '1px solid blue',
              borderRadius: 4,
              padding: '5px 10px',
            }}
          >
            <div>
              <Typography
                style={{ lineHeight: 'unset', marginBottom: 0 }}
                variant="overline"
                display="block"
                id="tyFoodCustNo"
                gutterBottom
              >
                {customerinfo.contactNumber}
              </Typography>

              <Typography
                style={{
                  lineHeight: 'unset',
                  fontSize: '1rem',
                  width: '85px',
                  overflowWrap: 'break-word',
                }}
                variant="h5"
                component="h5"
                id="tyFoodCustName"
              >
                {customerinfo.name}
              </Typography>
            </div>

            <IconButton
              size="small"
              color="secondary"
              aria-label="add an alarm"
              onClick={() => {
                handleCustomerremove();
              }}
            >
              <ClearOutlined />
            </IconButton>
            <IconButton
              size="small"
              color="secondary"
              aria-label="add an alarm"
              onClick={() => {
                setOpenNewCustomerComp(true);
                setCustomerInformation(custInfo);
              }}
            >
              <Edit />
            </IconButton>
          </div>
        </>
      ) : (
        <Button
          onClick={() => {
            openCustomerModal();
          }}
          style={{
            margin: '0 5px',
          }}
          color="primary"
          variant="contained"
          aria-label="contained primary button group"
          id="btnFoodCustomer"
          disabled={AddCustomerButtonDisable(shiftButton, IsOdms)}
        >
          <PersonAdd />
          &nbsp;ADD
        </Button>
      )}

      {totalItems > 0 ? (
        <Button
          onClick={() => {
            ResetCart();
          }}
          style={{
            margin: '0 5px',
          }}
          color="primary"
          variant="contained"
          aria-label="contained primary button group"
          id="btnPOSClearCart"
        >
          <RemoveShoppingCart />
          &nbsp;CLEAR CART
        </Button>
      ) : (
        <></>
      )}
    </Box>
  );
};

CartHeaderButtons.propTypes = {
  newOrderButtonClick: PropTypes.any,
  shiftButton: PropTypes.any,
  isCustomerAttached: PropTypes.any,
  customerinfo: PropTypes.any,
  handleCustomerremove: PropTypes.any,
  openCustomerModal: PropTypes.any,
  totalItems: PropTypes.any,
  IsOdms: PropTypes.any,
  ResetCart: PropTypes.any,
  setOpenNewCustomerComp: PropTypes.any,
  setCustomerInformation: PropTypes.any,
  custInfo: PropTypes.any,
};
export default CartHeaderButtons;
