import { makeStyles, Paper, Toolbar, TextField } from '@material-ui/core';
import Controls from '../../Utilities/Controls/Controls';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect, useState } from 'react';
import PageTitle from '../../Layout/PageTitle';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import * as salesService from '../Sales/SalesService';
import CircularLoader from '../../Utilities/ReuseableCommponents/CircularLoader';
import GenericTable from '../../Utilities/ReuseableCommponents/TableReport/GenericTable';
import {
  GetCompanyType,
  dateTimeZone as timeZone,
} from '../../.././Common/constants';
import activityMonitoring from '../../../Common/functions';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  newButton: {
    position: 'absolute',
    right: 10,
  },
  PaperMargin: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  CellRightAlign: {
    textAlign: 'right',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  searchInput: {
    paddingRight: '5px',
  },
}));

export default function Sales() {
  const classes = useStyles();
  const [records, setRecords] = useState([]);
  const [recordsCount, setRecordsCount] = useState(0);
  const [shiftNumber, setShiftNumber] = useState('');
  const [dateFrom, setDateFrom] = React.useState('');
  const [dateTo, setDateTo] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const companyType = GetCompanyType();
  const miscChargesLimit = JSON.parse(localStorage.getItem('_Misc'));
  const headCells = [
    { id: 'shiftNumber', label: 'Shift No.', type: 'string' },
    { id: 'clientUpdatedAt', label: 'Date & Time', type: 'date' },
    { id: 'itemsSold', label: 'Items Sold', type: 'integer' },
    { id: 'itemsReturned', label: 'Items Returned', type: 'integer' },
    { id: 'overallSaleDiscountAmount', label: 'Adjustment', type: 'float' },
    ...(companyType === 'retail' && miscChargesLimit && miscChargesLimit.status
      ? [
          {
            id: 'miscellaneousCharges',
            label: 'Misc. Charges',
            type: 'float',
          },
        ]
      : []),
    ...(companyType === 'food'
      ? [{ id: 'deliveryCharges', label: 'Delivery Charges', type: 'float' }]
      : []),
    { id: 'saleAmountTotal', label: 'Net Sale Amount', type: 'float' },
  ];

  function getRecords(page, rowsPerPage, sortDirection, sortColumnName) {
    let searchString = '';
    setIsLoading(true);
    if (shiftNumber) {
      searchString += `&shiftNumber=${shiftNumber}`;
    }
    if (dateFrom) {
      searchString += `&updatedFrom=${dateFrom}T00:00:00${timeZone()}`;
    }
    if (dateTo) {
      searchString += `&updatedTo=${dateTo}T23:59:59${timeZone()}`;
    }
    salesService
      .getAllSales(
        page,
        rowsPerPage,
        sortDirection,
        sortColumnName,
        searchString
      )
      .then((Response) => {
        setIsLoading(false);
        if (Response.data && Response.data.data) {
          let recordData = Response.data.data;
          if (companyType === 'food') {
            recordData = recordData.map((item) => ({
              ...item,
              netSaleAmount: item.deliveryCharges + item.saleAmountTotal,
            }));
          }
          setRecords(recordData);
          setRecordsCount(Response.data.count);
        } else {
          console.error('Error While Fetching Data: ', Response.error);
        }
      });
  }

  const handleSearch = (e) => {
    e.preventDefault();
    getRecords(0, 5, -1, 'clientUpdatedAt');
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  useEffect(() => {
    activityMonitoring('Sales');
  }, []);
  return (
    <>
      <PageTitle
        title="Sales Report"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="salesHeading"
      />
      <Paper elevation={6} square className={classes.PaperMargin}>
        <Toolbar disableGutters>
          <form
            onSubmit={handleSearch}
            style={{ width: '100%', display: 'flex', alignItems: 'center' }}
          >
            <TextField
              id="txtSearchSalesShiftNumber"
              label="Shift No."
              className={classes.searchInput}
              variant="outlined"
              value={shiftNumber}
              onChange={(e) => setShiftNumber(e.target.value)}
              autoComplete="off"
            />
            <TextField
              id="txtSearchSalesDateFrom"
              label="Date From"
              variant="outlined"
              type="date"
              onChange={(e) => setDateFrom(e.target.value)}
              value={dateFrom}
              className={classes.searchInput}
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
            />
            <TextField
              id="txtSearchSalesDateTo"
              label="Date To"
              variant="outlined"
              type="date"
              onChange={(e) => setDateTo(e.target.value)}
              value={dateTo}
              className={classes.searchInput}
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
            />
            <Controls.Button
              text="Search"
              variant="contained"
              color="primary"
              id="btnSearchSales"
              startIcon={<SearchIcon />}
              className={classes.searchButton}
              type="submit"
            />
          </form>
        </Toolbar>
        <CircularLoader isload={isLoading} />
        <GenericTable
          data={records}
          TableId={'salesTable'}
          TableTitle={'Sales Report'}
          duration={`${dateFrom} ${dateTo ? 'To' : ''} ${dateTo}`}
          cellData={[
            { name: 'shiftNumber', type: 'string' },
            { name: 'clientUpdatedAt', type: 'date' },
            { name: 'itemsSold.length', type: 'integer' },
            { name: 'itemsReturned.length', type: 'integer' },
            { name: 'overallSaleDiscountAmount', type: 'float' },
            ...(companyType === 'retail' &&
            miscChargesLimit &&
            miscChargesLimit.status
              ? [
                  {
                    name: 'miscellaneousCharges',
                    type: 'float',
                  },
                ]
              : []),
            ...(companyType === 'food'
              ? [
                  {
                    name: 'deliveryCharges',
                    type: 'float',
                  },
                ]
              : []),
            {
              name:
                companyType === 'retail' ? 'saleAmountTotal' : 'netSaleAmount',
              type: 'float',
            },
          ]}
          headCells={headCells}
          recordsCount={recordsCount}
          getRecordsFn={getRecords}
          rowOnClick={{
            path: '/SalesDetails',
            header: 'Sales Details',
            property: ['recordForEdit'],
          }}
          isLoading={isLoading}
        />
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
