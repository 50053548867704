import React from 'react';
import { Modal } from '@material-ui/core';
import EndShiftModalBody from './EndShiftModalBody';
import PropTypes from 'prop-types';

const EndShiftModal = (props) => {
  return (
    <div>
      <Modal
        open={props.open5}
        onClose={props.handleClose5}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div>
          <EndShiftModalBody {...props} />
        </div>
      </Modal>
    </div>
  );
};
EndShiftModal.propTypes = {
  open5: PropTypes.any,
  handleClose5: PropTypes.any,
  props: PropTypes.any,
};
export default EndShiftModal;
