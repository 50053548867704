import React, { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import EJS from 'ejs';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import noImageAvailable from '../../../../Assets/Images/undefined.png';
import * as SetupService from '../SetupService';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Switch from '@material-ui/core/Switch';
import Notification from '../../../Utilities/ReuseableCommponents/Notification';
import ConcaveCraftQR from '../../../../Assets/Images/CCQR.jpg';

let apiResponseDto = {
  data: [],
  error: null,
  loading: false,
  statusCode: 0,
};

let apiResponse = {
  data: null,
  error: null,
  loading: false,
  statusCode: 0,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexDirection: 'column',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  textField: {
    fontSize: 13,
    fontStyle: 'italic',
  },
  paper: {
    position: 'absolute',
    width: '90%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function ReceiptSettingsTab() {
  const classes = useStyles();
  const [invoices, setInvoices] = useState({ data: [] });
  const [dialog, setDialog] = useState({
    open: false,
    openDelete: false,
    invoiceCount: null,
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });
  const [templateState, setTemplateState] = useState(`
  
  `);

  let templateJSON = {
    companyLogo: noImageAvailable,
    companyName: 'ABC Company',
    userName: 'Cashier',
    date: '	7/9/2021, 11:52:30 AM',
    options: {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    qrCode: ConcaveCraftQR,
    invoiceNumber: '/0001/0020/202177174324',
    orderMode: 'Delivery',
    waiter: 'Waiter name',
    table: 'Table number',
    rider: 'Rider name',
    customerInfo: {
      CustomerType: 'walkin',
      city: 'Karachi',
      status: 'active',
      name: 'Customer',
      contactNumber: '03XXXXXXXXX',
      email: 'e@mail.com',
    },
    billingAddress: 'Customer billing address',
    shippingAddress: 'Customer shipping address',
    itemsSold: [
      {
        name: 'Item 1',
        Quantity: 1,
        img: noImageAvailable,
        barcode: '123232',
        category: 'Category1',
        description: 'Green:::dress shirts',
        Tempdiscount: 0,
        unitSaleFinalPrice: 100,
        unitSalePrice: 100,
        saleFinalPrice: 100,
      },
      {
        name: 'Item 2',
        Quantity: 2,
        sku: '1254232',
        description: 'Red:::casual shirts',
        img: noImageAvailable,
        category: 'Category1',
        unitSaleFinalPrice: 50,
        unitSalePrice: 50,
        Tempdiscount: '0.00',
        saleFinalPrice: 100,
      },
      {
        name: 'Item 3',
        category: 'Category2',
        sku: '1254322',
        img: noImageAvailable,
        Quantity: 1,
        unitSaleFinalPrice: 20,
        unitSalePrice: 20,
        Tempdiscount: '0.00',
        saleFinalPrice: 20,
      },
    ],
    totalCount: 3,
    subTotal: 220,
    taxAmount: 0,
    totalTaxAmount: 0,
    overallSaleDiscountAmount: 0,
    miscellaneousCharges: 0,
    deliveryCharges: 30,
    saleAmountTotal: 220,
    saleMode: 'cash',
    paidAmount: 200,
    returnAmount: 0,
  };

  let labelJSON = {
    Header: [
      { primary: 'Company Logo', secondary: 'companyLogo' },
      { primary: 'Company Name', secondary: 'companyName' },
      { primary: 'Cashier', secondary: 'userName' },
    ],
    Order: [
      { primary: 'Order Number/Invoice', secondary: 'orderNumber' },
      { primary: 'Order Date', secondary: 'date' },
      { primary: 'Order Mode', secondary: 'orderMode' },
      { primary: 'Rider', secondary: 'rider' },
    ],
    Customer: [
      { primary: 'Name', secondary: 'customerInfo.name' },
      { primary: 'Contact', secondary: 'customerInfo.contactNumber' },
      { primary: 'Billing Address', secondary: 'billingAddress' },
      { primary: 'Shipping Address', secondary: 'shippingAddress' },
    ],
    Item: [
      { primary: 'Name', secondary: 'itemsSold[n].name' },
      { primary: 'Qty', secondary: 'itemsSold[n].Quantity' },
      { primary: 'Unit Price', secondary: 'itemsSold[n].unitSalePrice' },
      {
        primary: 'Unit Price + Tax',
        secondary: 'itemsSold[n].unitSaleFinalPrice',
      },
      { primary: 'Disc', secondary: 'itemsSold[n].Tempdiscount' },
      { primary: 'Total', secondary: 'itemsSold[n].saleFinalPrice' },
      { primary: 'Total Items', secondary: 'totalCount' },
    ],
    Payment: [
      { primary: 'Subtotal', secondary: 'subTotal' },
      { primary: 'Tax', secondary: 'taxAmount' },
      { primary: 'Delivery Charges', secondary: 'deliveryCharges' },
      { primary: 'Misc. Charges', secondary: 'miscellaneousCharges' },
      { primary: 'Payment Mode/MOP', secondary: 'saleMode' },
      { primary: 'Paid Amount', secondary: 'paidAmount' },
      { primary: 'Return Amount', secondary: 'returnAmount' },
    ],
  };

  //prettier-ignore
  const handleOpen =
    (invoiceCount = -1) =>
    () => {
      setDialog({ open: true, openDelete: false, invoiceCount: invoiceCount });
      if (invoiceCount >= 0) {
        setTemplateState(invoices.data[invoiceCount].templateString);
      } else {
        setTemplateState('');
      }
    };

  //prettier-ignore
  const handleOpenDelete =
    (invoiceCount = -1) =>
    () => {
      setDialog({ open: false, openDelete: true, invoiceCount: invoiceCount });
    };

  const handleClose = () => setDialog({ open: false });
  const handleCloseDelete = () => setDialog({ openDelete: false });

  useEffect(() => {
    let data = (async () => {
      apiResponseDto = await SetupService.getSetupWithName('Invoice').then(
        (Response) => {
          return Response;
        }
      );
      return apiResponseDto;
    })();
    data.then((response) => {
      if (response.data.data.length) {
        setInvoices(response.data.data[0]);
      } else console.error('Error While Fetching Data: ', Response.error);
    });
  }, []);

  async function addTemplate() {
    invoices.data.push({
      name: 'Receipt',
      status: 'disabled',
      templateString: templateState,
      type: 'sale',
    });
    let requestBody = {
      name: invoices.name,
      data: invoices.data,
    };
    apiResponse = await SetupService.updateSetup(requestBody, invoices._id)
      .then((response) => {
        if (response.data) {
          handleClose();
          notification('success', 'Template created successfully.');
          return response;
        } else {
          notification(
            'error',
            'Somthing went wrong durring template creation.'
          );
          console.error(response.error);
        }
      })
      .catch((error) => {
        notification('error', 'Somthing went wrong durring template creation.');
        console.error(error);
      });
  }

  async function updateTemplate(index) {
    invoices.data[index].templateString = templateState;
    let requestBody = {
      name: invoices.name,
      data: invoices.data,
    };
    apiResponse = await SetupService.updateSetup(requestBody, invoices._id)
      .then((response) => {
        if (response.data) {
          handleClose();
          notification('success', 'Template updated successfully.');
          return response;
        } else {
          notification('error', 'Somthing went wrong during update template.');
          console.error(response.error);
        }
      })
      .catch((error) => {
        notification('error', 'Somthing went wrong during update template.');
        console.error(error);
      });
  }

  async function deleteTemplate(index) {
    invoices.data.splice(index, 1);
    let requestBody = {
      name: invoices.name,
      data: invoices.data,
    };
    apiResponse = await SetupService.updateSetup(requestBody, invoices._id)
      .then((response) => {
        if (response.data) {
          handleCloseDelete();
          notification('success', 'Template deleted successfully.');
          return response;
        } else {
          notification('error', 'Somthing went wrong during delete template.');
          console.error(response.error);
        }
      })
      .catch((error) => {
        notification('error', 'Somthing went wrong during delete template.');
        console.error(error);
      });
  }

  async function handleStatus(index) {
    if (invoices.data[index].status === 'active') {
      return;
    } else {
      invoices.data.map((invoice) => (invoice.status = 'disabled'));
      invoices.data[index].status = 'active';
      let requestBody = {
        name: invoices.name,
        data: invoices.data,
      };
      apiResponse = await SetupService.updateSetup(requestBody, invoices._id)
        .then((response) => {
          if (response.data) {
            notification('success', 'Template active successfully.');
            return response;
          } else {
            notification(
              'error',
              'Somthing went wrong during activate template.'
            );
            console.error(response.error);
          }
        })
        .catch((error) => {
          notification(
            'error',
            'Somthing went wrong during activate template.'
          );
          console.error(error);
        });
      if (apiResponse.data) {
        setInvoices(apiResponse.data.data);
      }
    }
  }

  function notification(type, message) {
    setNotify({
      isOpen: true,
      type,
      message,
      notificationTime: 3000,
    });
  }

  function groupBy(objectArray, property) {
    return objectArray.reduce(function (acc, obj) {
      let key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }

  templateJSON.byCategory = groupBy(templateJSON.itemsSold, 'category');

  function createHtmlString(templateString) {
    try {
      return ReactHtmlParser(EJS.render(templateString, templateJSON));
    } catch (error) {
      return error.message;
    }
  }

  const creatModal = (
    <Modal
      open={dialog.open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={classes.paper}>
        <h2 id="simple-modal-title">
          {dialog.invoiceCount >= 0 ? 'Update' : 'New'} Template
        </h2>
        <div id="simple-modal-description">
          <Paper
            elevation={3}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              padding: '2rem',
            }}
          >
            <Paper elevation={3} style={{ width: '69%' }}>
              <TextField
                id="ejs-basic"
                InputProps={{
                  classes: {
                    input: classes.textField,
                  },
                }}
                value={templateState}
                style={{ width: '100%', fontSize: '14px' }}
                multiline
                rows={25}
                label="Script"
                variant="outlined"
                onChange={(e) => setTemplateState(e.target.value)}
              />
            </Paper>
            <Paper
              elevation={3}
              style={{
                width: '30%',
                height: '512px',
                overflowX: 'hidden',
                overflowY: 'scroll',
                float: 'right',
              }}
            >
              <div style={{ fontFamily: 'Arial, serif' }}>
                {createHtmlString(templateState)}
              </div>
            </Paper>
          </Paper>
          <div style={{ marginTop: 20, textAlign: 'center' }}>
            {dialog.invoiceCount >= 0 ? (
              <Button
                color="primary"
                variant="contained"
                onClick={() => updateTemplate(dialog.invoiceCount)}
              >
                Update
              </Button>
            ) : (
              <Button color="primary" variant="contained" onClick={addTemplate}>
                Save
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );

  const deleteModal = (
    <Modal
      open={dialog.openDelete}
      onClose={handleCloseDelete}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        className={classes.paper}
        style={{
          width: '40%',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <h2 id="simple-modal-title">Delete Template</h2>
        <p id="simple-modal-description">
          <Paper
            elevation={3}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              width: '100%',
              padding: '2rem',
            }}
          >
            <h2>Are you sure you want to delete?</h2>
            <div style={{ textAlign: 'center' }}>
              <Button
                onClick={() => deleteTemplate(dialog.invoiceCount)}
                color="primary"
                variant="contained"
              >
                Okay
              </Button>
            </div>
          </Paper>
        </p>
      </div>
    </Modal>
  );

  return (
    <div className={classes.root}>
      {creatModal}
      {deleteModal}
      <h2>Labels which user can display on receipt.</h2>
      <Paper elevation={3} style={{ width: '100%', padding: '2rem' }}>
        <Button variant="contained" color="primary" onClick={handleOpen(-1)}>
          New Invoice
        </Button>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          {Object.keys(labelJSON).map(function (key) {
            return (
              <List key={key} dense={false}>
                <ListSubheader disableSticky>{key}</ListSubheader>
                {labelJSON[key].map((label, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={label.primary}
                      secondary={label.secondary}
                    />
                  </ListItem>
                ))}
              </List>
            );
          })}
        </div>
      </Paper>

      {invoices.data.map((invoice, index) => (
        <Paper
          key={index}
          elevation={3}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            padding: '2rem',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <Paper
              elevation={5}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                width: '20%',
                borderRadius: 25,
                marginBottom: 20,
              }}
              square={true}
            >
              <Switch
                checked={invoice.status === 'active' ? true : false}
                onChange={() => {
                  handleStatus(index);
                }}
                color="primary"
                name="checkedB"
                style={{ alignSelf: 'center' }}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
              <IconButton
                color="primary"
                onClick={handleOpen(index)}
                aria-label="edit receipt template"
              >
                <EditIcon />
              </IconButton>
              <IconButton
                color="secondary"
                onClick={handleOpenDelete(index)}
                aria-label="delete receipt template"
              >
                <DeleteIcon />
              </IconButton>
            </Paper>
            <div
              style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
            >
              <Paper elevation={3} style={{ width: '69%' }}>
                <TextField
                  id="ejs-basic"
                  InputProps={{
                    classes: {
                      input: classes.textField,
                    },
                  }}
                  value={invoice.templateString}
                  style={{ width: '100%', fontSize: '14px' }}
                  multiline
                  disabled
                  rows={25}
                  label="Script"
                  variant="outlined"
                  onChange={(e) => setInvoices(e.target.value)}
                />
              </Paper>
              <Paper
                elevation={3}
                style={{
                  width: '30%',
                  height: '512px',
                  overflowX: 'hidden',
                  overflowY: 'scroll',
                  float: 'right',
                }}
              >
                <div style={{ fontFamily: 'Arial, serif' }}>
                  {createHtmlString(invoice.templateString)}
                </div>
              </Paper>
            </div>
          </div>
        </Paper>
      ))}
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
