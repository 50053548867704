import React from 'react';
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function MultiSelect(props) {
  const { name, label, value, error = null, onChange, options } = props;

  return (
    <FormControl variant="outlined" {...(error && { error: true })}>
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        labelId="demo-mutiple-checkbox-label"
        id="demo-mutiple-checkbox"
        multiple
        value={value}
        name={name}
        onChange={onChange}
        input={<Input />}
        renderValue={(selected) => {
          //
          var result = selected.map((e) => e.name).join(',');
          return result;
        }}
        MenuProps={MenuProps}
      >
        {options.map((item) => (
          <MenuItem key={item._id} value={item}>
            <Checkbox
              checked={(() => {
                var checkStatus =
                  value.findIndex((x) => x._id === item._id) > -1;
                return checkStatus;
              })()}
            />
            <ListItemText primary={item.name} />
          </MenuItem>
        ))}
      </MuiSelect>

      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
MultiSelect.propTypes = {
  name: PropTypes.any,
  label: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.any,
  error: PropTypes.any,
  options: PropTypes.any,
};
export default MultiSelect;
