/*eslint-disable no-unused-vars*/
import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  TableBody,
  TableCell,
  TableRow,
  Switch,
  IconButton,
} from '@material-ui/core';
import * as forgetPasswordService from '../../../Pages/ForgotPassword/ForgetPasswordServices';
import Skeleton from '@material-ui/lab/Skeleton';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import TblHead from './TblHead';
import TblContainer from './TblContainer';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ExportAndPrintReport from './ExportAndPrintReport';
import TblPagination from './TblPagination';
import { getFormattedDateTime as ISOdateFormatter } from '../../../../Common/constants';
import Controls from '../../Controls/Controls';
import styles from './GenericTable.module.css';
import Chiclet from '../../../../Common/Chiclet';

export const GenericTableContext = createContext();

export const ActionButton = (props) => {
  const { index, rowDeleteDialog, rowDeletefn, item } = props;
  return (
    <Controls.ActionButton
      color="secondary"
      id={`btnDelCat${index}`}
      name="btnDelete"
      onClick={(event) => {
        event.stopPropagation();
        rowDeleteDialog({
          isOpen: true,
          title: 'Are you sure you want to delete this record?',
          subTitle: "You can't undo this operation",
          onConfirm: () => {
            rowDeletefn(item._id);
          },
        });
      }}
    >
      <HighlightOffIcon />
    </Controls.ActionButton>
  );
};
export const SwitchButton = (props) => {
  const { isChecked, index, statusChangeFn } = props;
  return (
    <Switch
      checked={isChecked}
      name="switchStatus"
      id={`chkCatStatus${index}`}
      onChange={(e) => {
        e.stopPropagation();
        statusChangeFn(e, index);
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
      color="primary"
      inputProps={{ 'aria-label': 'primary checkbox' }}
    />
  );
};

export const DecisionButton = (props) => {
  const {
    index,
    itemId,
    message,
    type,
    flag,
    getFilteredRecords,
    approveButton,
    setNotify,
  } = props;
  return (
    <IconButton
      id={`btnApproved${index}`}
      onClick={async (e) => {
        await forgetPasswordService.approveChangePassword(itemId, flag);
        setNotify({
          isOpen: true,
          message: message,
          type: type,
        });
        getFilteredRecords(e);
      }}
    >
      {approveButton ? (
        <CheckCircleOutlineIcon fontSize="medium" color="primary" />
      ) : (
        <CancelIcon fontSize="medium" color="secondary" />
      )}
    </IconButton>
  );
};

export function evaluateValue(
  item,
  cellValues,
  index,
  statusChangeFn,
  rowDeleteDialog,
  rowDeletefn,
  decisionButtonProps
) {
  const value = cellValues.name.split('.').reduce((o, i) => o[i], item) || '';
  switch (cellValues.type) {
    case 'string':
      return value;

    case 'integer':
      if (value === 0) {
        return 0;
      }

      return value.toLocaleString();

    case 'percentage':
      if (value == 0) {
        return 0;
      }

      return `${value.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}%`;

    case 'date':
      if (!value) {
        return ''; // added by sami since this case was missing in earlier code, needs to be checked if it works
      }

      return ISOdateFormatter(new Date(value).toISOString());

    case 'remove':
      return (
        <ActionButton
          index={index}
          rowDeleteDialog={rowDeleteDialog}
          rowDeletefn={rowDeletefn}
          item={item}
        ></ActionButton>
      );

    case 'decision': {
      return (
        <>
          <DecisionButton
            index={index}
            itemId={item._id}
            flag={true}
            message={'Request Approved'}
            type={'success'}
            getFilteredRecords={decisionButtonProps.getFilteredRecords}
            approveButton={true}
            setNotify={decisionButtonProps.setNotify}
          />
          <DecisionButton
            index={index}
            itemId={item._id}
            flag={false}
            message={'Request Disapproved'}
            type={'error'}
            getFilteredRecords={decisionButtonProps.getFilteredRecords}
            approveButton={false}
            setNotify={decisionButtonProps.setNotify}
          />
        </>
      );
    }

    case 'status': {
      if (!value) {
        return ''; // added by sami since this case was missing in earlier code, needs to be checked if it works
      }

      let isChecked = false;

      if (item.status && item.status === 'active') {
        isChecked = true;
      }

      return (
        <SwitchButton
          isChecked={isChecked}
          index={index}
          statusChangeFn={statusChangeFn}
        ></SwitchButton>
      );
    }
    case 'state':
      return value === 'booked' ? (
        <Chiclet bodyText="Booked" chicletStyle="alert"></Chiclet>
      ) : (
        <Chiclet bodyText={value} chicletStyle="basic"></Chiclet>
      );

    case 'input':
      return (
        <Controls.Input
          name="name"
          label="Quantity"
          value={item.printCount}
          onChange={(e) => (item.printCount = e.target.value)}
          className="searchInput"
          id={`txtPrintCount${index}`}
          required
        />
      );

    case 'onlyDate':
      if (!value) {
        return '';
      }

      return ISOdateFormatter(new Date(value).toISOString()).slice(0, -10);

    case 'float':
      if (value === 0) {
        return 0;
      }

      return value.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
  }
}

function getCellAlignment(cellType) {
  switch (cellType) {
    case 'integer':
    case 'float':
    case 'date':
    case 'onlyDate':
    case 'percentage':
      return 'right';

    case 'status':
    case 'remove':
      return 'center';
    case 'decision':
      return 'center';

    default:
      return 'left';
  }
}

function FooterTable(props) {
  const { footerRecords, footerCellData, TableId } = props;

  if (!footerRecords) {
    return <></>;
  }

  return (
    <TblContainer>
      <TableBody>
        <TableRow style={{ backgroundColor: '#EEEEEE' }}>
          <TableCell
            style={{
              fontSize: 15,
              fontWeight: 'bold',
              color: 'black',
              width: '230px',
            }}
          >
            Total
          </TableCell>
          {footerCellData.map((elem, ind) => (
            <TableCell
              style={{
                fontSize: 15,
                color: 'black',
                textAlign: elem.align || 'right',
              }}
              key={`${TableId + 'footer' + ind}`}
              id={TableId + 'footer' + ind}
            >
              {evaluateValue(footerRecords, elem)}
            </TableCell>
          ))}
        </TableRow>
      </TableBody>
    </TblContainer>
  );
}

function returnLoadingSkeletonBlock(numberOfColumns) {
  return Array(5) //show a max of 5 rows of skeletons only
    .fill()
    .map((_, row) => (
      <TableRow key={`placeholderRow${row}`}>
        {Array(numberOfColumns) //make sure we have the same number of cells as in the header
          .fill()
          .map((_, col) => (
            <TableCell key={`placeholderRow${row}Col${col}`}>
              <Skeleton />
            </TableCell>
          ))}
      </TableRow>
    ));
}

function returnNoDataFoundBlock(numberOfColumns) {
  return (
    <TableRow>
      <TableCell colSpan={numberOfColumns} rowSpan={5}>
        <div
          style={{
            width: '100%',
            height: '150px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{ width: '100px', height: '20px' }}>
            <h4 id="tyEmptyTable">No data found</h4>
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
}

function DynamicTableBody(props) {
  const {
    cellData,
    data,
    statusChangeFn,
    rowDeleteDialog,
    rowDeletefn,
    rowOnClick,
    history,
    TableId,
    isLoading,
    headCells,
    decisionButtonProps,
  } = props;

  let tableToRender = null;

  if (isLoading) {
    tableToRender = returnLoadingSkeletonBlock(headCells.length);
  } else if (!isLoading && data.length === 0) {
    tableToRender = returnNoDataFoundBlock(headCells.length);
  } else {
    tableToRender = data.map((item, index) => (
      <TableRow
        key={index}
        id={`${TableId + index}`}
        onClick={() => {
          if (rowOnClick) {
            const temp = {};
            temp[`${rowOnClick.property[0]}`] = item;
            history.push({
              pathname: rowOnClick.path,
              state: {
                pageHeaderTitile: rowOnClick.header,
                ...temp,
              },
            });
          }
        }}
      >
        {cellData.map((cell, ind) => (
          <TableCell
            key={`${TableId + index + ind}`}
            align={getCellAlignment(cell.type)}
            id={TableId + index + ind}
            className={styles.tableCellGray}
          >
            {evaluateValue(
              item,
              cell,
              index,
              statusChangeFn,
              rowDeleteDialog,
              rowDeletefn,
              decisionButtonProps
            )}
          </TableCell>
        ))}
      </TableRow>
    ));
  }

  return tableToRender;
}
function MainTable(props) {
  const {
    cellData,
    headCells,
    classStyle,
    TableId,
    data,
    statusChangeFn,
    rowDeleteDialog,
    rowDeletefn,
    rowOnClick,
    history,
    isLoading,
    decisionButtonProps,
  } = props;

  return (
    <TblContainer>
      <TblHead
        headCells={headCells}
        getCellAlignment={getCellAlignment}
        classStyle={classStyle}
      />
      <TableBody id={TableId}>
        <DynamicTableBody
          headCells={headCells}
          cellData={cellData}
          data={data}
          statusChangeFn={statusChangeFn}
          rowDeleteDialog={rowDeleteDialog}
          rowDeletefn={rowDeletefn}
          rowOnClick={rowOnClick}
          history={history}
          TableId={TableId}
          isLoading={isLoading}
          decisionButtonProps={decisionButtonProps}
        />
      </TableBody>
    </TblContainer>
  );
}

export default function GenericTable(props) {
  const {
    data,
    cellData,
    headCells,
    recordsCount,
    getRecordsFn,
    statusChangeFn,
    rowDeleteDialog,
    rowDeletefn,
    rowOnClick,
    TableId,
    footerRecords,
    duration,
    footerCellData,
    TableTitle,
    isLoading,
    showPagination,
    showReportActions,
    decisionButtonProps,
  } = props;

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState(1);
  const [orderBy, setOrderBy] = useState('');
  const history = useHistory();

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(cellId);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getRecordsFn(page, rowsPerPage, order, orderBy);
  }, [page, rowsPerPage, order, orderBy]); // eslint-disable-line react-hooks/exhaustive-deps

  //TODO: handle this in the component itself later and remove from here
  let durationProp = duration;
  if (!duration) {
    durationProp = false;
  }
  let footerCellDataProp = footerCellData;
  if (!footerCellData) {
    footerCellDataProp = false;
  }
  // end of TODO

  return (
    <GenericTableContext.Provider
      value={{
        rowsPerPage,
        page,
        order,
        orderBy,
        handleChangePage,
        handleChangeRowsPerPage,
        handleSortRequest,
      }}
    >
      <MainTable
        cellData={cellData}
        headCells={headCells}
        classStyle={styles.tableCellGray}
        TableId={TableId}
        data={data}
        statusChangeFn={statusChangeFn}
        rowDeleteDialog={rowDeleteDialog}
        rowDeletefn={rowDeletefn}
        rowOnClick={rowOnClick}
        history={history}
        isLoading={isLoading}
        decisionButtonProps={decisionButtonProps}
      />

      {showPagination ? <TblPagination recordsCount={recordsCount} /> : <></>}

      <FooterTable
        footerRecords={footerRecords}
        footerCellData={footerCellData}
        TableId={TableId}
      />

      {showReportActions ? (
        <ExportAndPrintReport
          records={data}
          headCells={headCells}
          cellData={cellData}
          duration={durationProp}
          footerCellData={footerCellDataProp}
          footerRecords={footerRecords}
          evaluateValue={evaluateValue}
          TableTitle={TableTitle}
        />
      ) : (
        <></>
      )}
    </GenericTableContext.Provider>
  );
}

GenericTable.defaultProps = {
  showReportActions: true,
  showPagination: true,
};

GenericTable.propTypes = {
  data: PropTypes.any,
  TblContainer: PropTypes.any,
  TblHead: PropTypes.any,
  cellData: PropTypes.array,
  headCells: PropTypes.array,
  recordsCount: PropTypes.number,
  getRecordsFn: PropTypes.func,
  statusChangeFn: PropTypes.func,
  rowDeleteDialog: PropTypes.func,
  rowDeletefn: PropTypes.func,
  rowOnClick: PropTypes.object,
  TableId: PropTypes.string,
  footerRecords: PropTypes.any,
  duration: PropTypes.string,
  footerCellData: PropTypes.array,
  TableTitle: PropTypes.string,
  isLoading: PropTypes.bool,
  showReportActions: true,
  showPagination: true,
  decisionButtonProps: PropTypes.object,
};

ActionButton.propTypes = {
  index: PropTypes.any,
  rowDeleteDialog: PropTypes.func,
  rowDeletefn: PropTypes.func,
  item: PropTypes.any,
};

SwitchButton.propTypes = {
  isChecked: PropTypes.any,
  index: PropTypes.any,
  statusChangeFn: PropTypes.func,
};

MainTable.propTypes = {
  cellData: PropTypes.array,
  headCells: PropTypes.array,
  classStyle: PropTypes.string,
  TableId: PropTypes.string,
  data: PropTypes.array,
  statusChangeFn: PropTypes.func,
  rowDeleteDialog: PropTypes.func,
  rowDeletefn: PropTypes.func,
  rowOnClick: PropTypes.object,
  history: PropTypes.object,
  isLoading: PropTypes.bool,
  decisionButtonProps: PropTypes.object,
};

FooterTable.propTypes = {
  footerRecords: PropTypes.object,
  footerCellData: PropTypes.array,
  TableId: PropTypes.string,
};

DecisionButton.propTypes = {
  index: PropTypes.number,
  itemId: PropTypes.string,
  message: PropTypes.string,
  type: PropTypes.string,
  flag: PropTypes.bool,
  getFilteredRecords: PropTypes.func,
  approveButton: PropTypes.bool,
  setNotify: PropTypes.func,
};
