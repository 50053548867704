import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Grid } from '@material-ui/core';
import Controls from '../../Utilities/Controls/Controls';
import { Form } from '../../Utilities/ReuseableCommponents/useForm';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import PageTitle from '../../Layout/PageTitle';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import * as CashReconcileService from './CashReconcileService';
import { InputAdornment, Paper } from '@material-ui/core';
import { getFormattedDateTime as ISOdateFormatter } from '../../../Common/constants';
import './CashReconcileDetails.module.css';
import ShiftSalesProcedure from './ShiftSalesProcedure';
import PrintShiftSales from '../CommissionReport/PrintShiftSales';

const initialFValues = {
  _id: '',
  shiftNumber: '',
  openingAmount: 0,
  createdBy: '',
  actualClosingAmount: 0,
  comments: '',
  expectedClosingAmount: 0,
  internalSaleAmount: 0,
};

export default function CashReconcileDetails() {
  const location = useLocation();
  const history = useHistory();
  const [records, setRecords] = useState(initialFValues);
  let hideFeatures = JSON.parse(localStorage.getItem('_HideFeature')) || [];
  let expenseFeature = hideFeatures.find((hf) => hf.name === 'Expense');
  const isExpenseFeature =
    expenseFeature && expenseFeature.status ? true : false;
  const [recordForEdit] = useState(() => {
    if (location.state)
      if (location.state.recordForEdit) return location.state.recordForEdit;
      else return history.goBack();
    else return history.goBack();
  });

  useEffect(() => {
    if (recordForEdit) {
      (async () => {
        let Response = await CashReconcileService.getSingleCashReconcileDetail(
          recordForEdit._id
        ).then((Responce) => {
          return Responce;
        });
        return Response;
      })().then((Response) => {
        if (Response.data) {
          setRecords(Response.data.data);
          ShiftSalesProcedure(recordForEdit);
        } else {
          console.error('Unable To Get Records');
        }
      });
    }
  }, [recordForEdit]);

  const printShiftSales = (e) => {
    let abc = JSON.parse(localStorage.getItem('ShiftSalesReceiptReport'));
    if (abc === []) {
      e.preventDefault();
    } else {
      ReactDOM.render(
        <PrintShiftSales />,
        document.getElementById('print-mount')
      );
      document.getElementById('print-button').click();
      ReactDOM.unmountComponentAtNode(document.getElementById('print-mount'));
    }
  };

  return (
    <>
      <div id="print-mount"></div>
      <PageTitle
        title={location.state.pageHeaderTitile}
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="cashReconcileDetailsHeading"
      />
      <Paper elevation={6} square className="paperMargin">
        <Form>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <Controls.Input
                name="shiftNumber"
                label="Shift Number"
                value={records.shiftNumber}
                InputProps={{
                  readOnly: true,
                  disabled: true,
                }}
                id="txtCashReconcileShiftNumber"
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={4}>
              <Controls.Input
                name="openingAmount"
                label="Opening Amount"
                value={records.openingAmount}
                InputProps={{
                  readOnly: true,
                  disabled: true,
                  startAdornment: (
                    <InputAdornment position="start">Rs.</InputAdornment>
                  ),
                }}
                id="txtCashReconcileOpeningAmount"
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Controls.Input
                name="createdAt"
                label="Date & Time"
                value={
                  recordForEdit.createdAt
                    ? ISOdateFormatter(recordForEdit.createdAt)
                    : null
                }
                InputProps={{
                  readOnly: true,
                  disabled: true,
                }}
                id="txtcashReconcileDateTime"
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={4}>
              <Controls.Input
                name="expectedClosingAmount"
                label="Expected Amount"
                value={records.expectedClosingAmount}
                InputProps={{
                  readOnly: true,
                  disabled: true,
                  startAdornment: (
                    <InputAdornment position="start">Rs.</InputAdornment>
                  ),
                }}
                id="txtCashReconcileExpectedAmount"
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Controls.Input
                name="createdBy"
                label="Cashier Name"
                value={
                  recordForEdit.createdBy ? recordForEdit.createdBy.name : null
                }
                InputProps={{
                  readOnly: true,
                  disabled: true,
                }}
                id="txtcashReconcileCashierName"
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>

            {isExpenseFeature && (
              <>
                <Grid item xs={12} sm={4}>
                  <Controls.Input
                    name="ExpenseAmount"
                    label="Total Expense Amount"
                    value={records.totalExpense}
                    InputProps={{
                      readOnly: true,
                      disabled: true,
                      startAdornment: (
                        <InputAdornment position="start">Rs.</InputAdornment>
                      ),
                    }}
                    id="txtCashReconcileExpenseAmount"
                  />
                </Grid>
                <Grid item xs={12} sm={4}></Grid>
                <Grid item xs={12} sm={4}></Grid>
              </>
            )}
            <Grid item xs={12} sm={4}>
              <Controls.Input
                name="actualClosingAmount"
                label="Actual Closing Amount"
                value={records.actualClosingAmount}
                InputProps={{
                  readOnly: true,
                  disabled: true,
                  startAdornment: (
                    <InputAdornment position="start">Rs.</InputAdornment>
                  ),
                }}
                id="txtCashReconcileActualClosingAmount"
              />
            </Grid>

            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={4}>
              <Controls.Input
                name="internalSaleAmount"
                label="Internal Sale Amount"
                value={records.internalSaleAmount}
                InputProps={{
                  readOnly: true,
                  disabled: true,
                  startAdornment: (
                    <InputAdornment position="start">Rs.</InputAdornment>
                  ),
                }}
                id="txtCashReconcileInternalSaleAmount"
              />
            </Grid>

            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={2}></Grid>
            <Grid item xs={12} sm={6}>
              <Controls.Input
                name="comments"
                label="Comments"
                value={records.comments}
                className="commentButton"
                InputProps={{
                  readOnly: true,
                  disabled: true,
                }}
                id="txtCashReconcileComments"
              />
            </Grid>

            <Grid item xs={3}>
              <Controls.Button
                text="Back"
                color="default"
                //onClick={resetForm}
                onClick={history.goBack}
                id="btnBackCashReconcile"
              />
              <Controls.Button
                text="Shift Sales Report"
                color="primary"
                //onClick={resetForm}
                onClick={printShiftSales}
                id="btnPrintShiftSales"
              />
            </Grid>
          </Grid>
        </Form>
      </Paper>
    </>
  );
}
