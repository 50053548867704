import authHeader from '../../../Services/auth-header';
import { callAPI } from '../../../Services/ApiCaller';
import { API_VERSION } from '../../../Common/constants';
import { getDateTime } from '../../../Common/constants';
export async function getAllRecipes(
  page,
  rowsPerPage,
  sortDirection,
  sortColumnName,
  searchString
) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  let rowsFrom = page * rowsPerPage;

  let sortOrderdir = sortDirection === 'asc' ? 1 : -1;
  return callAPI(
    `api/${API_VERSION}/recipes`,
    null,
    headerObject,
    'GET',
    `?populate=itemRef&$orderBy=${sortColumnName}&$orderDir=${sortOrderdir}&$skip=${rowsFrom}&$top=${rowsPerPage}${searchString}`
  ).then((Responce) => {
    return Responce;
  });
}

export async function getAllRecipeItem(
  page,
  rowsPerPage,
  sortDirection,
  sortColumnName,
  searchString
) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  let rowsFrom = page * rowsPerPage;

  let sortOrderdir = sortDirection === 'asc' ? 1 : -1;

  if (!sortColumnName) {
    sortColumnName = 'updatedAt';
  }

  return callAPI(
    `api/${API_VERSION}/items`,
    null,
    headerObject,
    'GET',
    `?populate=categoriesRef&$orderBy=${sortColumnName}&$orderDir=${sortOrderdir}&$skip=${rowsFrom}&$top=${rowsPerPage}&name=${searchString}&isRecipeItem=true`
  ).then((Responce) => {
    return Responce;
  });
}
export async function getProductsForRecipe(
  page,
  rowsPerPage,
  sortDirection,
  sortColumnName,
  searchString
) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  let rowsFrom = page * rowsPerPage;

  let sortOrderdir = sortDirection === 'asc' ? 1 : -1;

  if (!sortColumnName) {
    sortColumnName = 'updatedAt';
  }

  return callAPI(
    `api/${API_VERSION}/items`,
    null,
    headerObject,
    'GET',
    `?populate=categoriesRef&$orderBy=${sortColumnName}&$orderDir=${sortOrderdir}&$skip=${rowsFrom}&$top=${rowsPerPage}&name=${searchString}`
  ).then((Responce) => {
    return Responce;
  });
}

export async function insertRecipe(recipe) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  var recipetAdd = {
    name: recipe.name,
    itemRef: recipe.itemRef,
    valueForProduct: recipe.valueForProduct,
    recipeItems: recipe.recipeItems,

    clientUpdatedAt: getDateTime(),
    updatedAt: getDateTime(),
  };

  return callAPI(
    `api/${API_VERSION}/recipes`,
    recipetAdd,
    headerObject,
    'POST',
    ''
  ).then((Responce) => {
    return Responce;
  });
}

export async function deleteRecipe(_id) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  return callAPI(
    `api/${API_VERSION}/recipes/`,
    null,
    headerObject,
    'DELETE',
    _id
  ).then((Responce) => {
    return Responce;
  });
}

export async function updateRecipe(recipe) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  var recipetUpdate = {
    _id: recipe._id,
    name: recipe.name,
    itemRef: recipe.itemRef,
    valueForProduct: recipe.valueForProduct,
    recipeItems: recipe.recipeItems,

    clientUpdatedAt: getDateTime(),
    updatedAt: getDateTime(),
  };

  return callAPI(
    `api/${API_VERSION}/recipes`,
    recipetUpdate,
    headerObject,
    'PUT',
    `/${recipe._id}`
  ).then((Responce) => {
    return Responce;
  });
}
