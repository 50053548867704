import { Paper, Toolbar, TextField } from '@material-ui/core';
import Controls from '../../Utilities/Controls/Controls';
import SearchIcon from '@material-ui/icons/Search';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import React, { useEffect, useState } from 'react';
import PageTitle from '../../Layout/PageTitle';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import APIService from './InventoryService';
import { dateTimeZone as timeZone } from '../../../Common/constants';
import CircularLoader from '../../Utilities/ReuseableCommponents/CircularLoader';
import GenericTable from '../../Utilities/ReuseableCommponents/TableReport/GenericTable';
import activityMonitoring from '../../../Common/functions';

const headCells = [
  { id: 'createdAt', label: 'Date & Time', align: 'left', type: 'date' },
  {
    id: 'expectedCountTotal',
    label: 'Total Expected Qty',
    disableSorting: true,
    align: 'center',
    type: 'float',
  },
  {
    id: 'actualCountTotal',
    label: 'Total Actual Qty',
    disableSorting: true,
    align: 'center',
    type: 'float',
  },
];

let apiResponseDto = {
  data: null,
  error: null,
  loading: false,
};

export default function PurchaseReturnInventory() {
  const [records, setRecords] = useState([]);
  const [recordsCount, setRecordsCount] = useState(0);
  const [dateFrom, setDateFrom] = React.useState('');
  const [dateTo, setDateTo] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const rowOnClick = {
    path: '/PurchaseReturnInventoryDetails',
    header: 'Return Inventory Table',
    property: ['Data'],
  };

  function getRecords(page, rowsPerPage, sortDirection, sortColumnName) {
    let searchString = '';
    let populate = 'items,vendors';
    let data = (async () => {
      setIsLoading(true);
      if (dateFrom) {
        searchString += `&filteredFrom=${dateFrom}T00:00:00${timeZone()}`;
      }
      if (dateTo) {
        searchString += `&filteredTo=${dateTo}T23:59:59${timeZone()}`;
      }
      searchString += '&inventoryType=purchase';
      apiResponseDto = await APIService.FetchInventoryList(
        page,
        rowsPerPage,
        sortDirection,
        sortColumnName,
        populate,
        searchString
      ).then((Response) => {
        return Response;
      });
      return apiResponseDto;
    })();
    data.then((Response) => {
      setIsLoading(false);
      if (Response.data) {
        if (Response.data.data) {
          var data = Response.data.data;
          data.forEach((item) => {
            if (item) {
              item.items.forEach((it) => {
                it['returnQty'] = 0;
              });
            }
          });
          setRecords(data);
          setRecordsCount(Response.data.count);
        }
      } else {
        setIsLoading(false);
        console.error('Error While Fetching Data: ', Response.error);
      }
    });
  }

  const handleSearch = (e) => {
    e.preventDefault();
    getRecords(0, 5, -1, 'createdAt');
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  useEffect(() => {
    activityMonitoring('PurchaseReturnInventory');
  }, []);

  return (
    <>
      <PageTitle
        title="Return Inventory"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="purRetInvListHeading"
      />

      <Paper elevation={6} square className="paperMargin">
        <Toolbar disableGutters>
          <form className="searchFormAlign" onSubmit={handleSearch}>
            <TextField
              id="txtSearchPurRetInvDateFrom"
              label="Date From"
              variant="outlined"
              type="date"
              onChange={(e) => setDateFrom(e.target.value)}
              value={dateFrom}
              className="searchInput"
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
            />
            <TextField
              id="txtSearchPurRetInvDateTo"
              label="Date To"
              variant="outlined"
              type="date"
              onChange={(e) => setDateTo(e.target.value)}
              value={dateTo}
              className="searchInput"
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
            />
            <Controls.Button
              text="Search"
              variant="contained"
              color="primary"
              id="btnSearchPurRetInv"
              startIcon={<SearchIcon />}
              className="searchButton"
              type="submit"
            />
          </form>
        </Toolbar>
        <CircularLoader isload={isLoading} />
        <GenericTable
          data={records}
          TableId={'purchaseInventoryTable'}
          TableTitle={'Purchase Inventory Table'}
          cellData={[
            { name: 'createdAt', type: 'date' },
            { name: 'expectedCountTotal', type: 'float' },
            { name: 'actualCountTotal', type: 'float' },
          ]}
          headCells={headCells}
          recordsCount={recordsCount}
          getRecordsFn={getRecords}
          rowOnClick={rowOnClick}
          isLoading={isLoading}
        />
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
