import authHeader from '../../../Services/auth-header';
import { callAPI } from '../../../Services/ApiCaller';
import { API_VERSION } from '../../../Common/constants';
class DashboardService {
  async FetchDailySaleSummary(From, To) {
    const header = authHeader();
    const headerObject = {
      'Content-Type': 'application/json',
      Authorization: header.Authorization,
    };
    return callAPI(
      `api/${API_VERSION}/sales/summary`,
      null,
      headerObject,
      'GET',
      `?startDate=${From}&endDate=${To}&timezone=${
        Intl.DateTimeFormat().resolvedOptions().timeZone
      }`
    ).then((Responce) => {
      return Responce;
    });
  }

  async FetchMostSellableItems(From, To) {
    const header = authHeader();

    const headerObject = {
      'Content-Type': 'application/json',
      Authorization: header.Authorization,
    };
    return callAPI(
      `api/${API_VERSION}/sales/mostSellableItems`,
      null,
      headerObject,
      'GET',
      `?startDate=${From}&endDate=${To}`
    ).then((Responce) => {
      return Responce;
    });
  }

  async FetchSalesBySourceOfOrder(From, To) {
    const header = authHeader();

    const headerObject = {
      'Content-Type': 'application/json',
      Authorization: header.Authorization,
    };
    return callAPI(
      `api/${API_VERSION}/sales/salesBySaleMode`,
      null,
      headerObject,
      'GET',
      `?startDate=${From}&endDate=${To}`
    ).then((Responce) => {
      return Responce;
    });
  }

  async FetchCountOfDailyOrders(From, To) {
    const header = authHeader();

    const headerObject = {
      'Content-Type': 'application/json',
      Authorization: header.Authorization,
    };
    return callAPI(
      `api/${API_VERSION}/sales/ordersDashboard`,
      null,
      headerObject,
      'GET',
      `?startDate=${From}&endDate=${To}`
    ).then((Responce) => {
      return Responce;
    });
  }

  async FetchTotalItemsSold(From, To) {
    const header = authHeader();

    const headerObject = {
      'Content-Type': 'application/json',
      Authorization: header.Authorization,
    };
    return callAPI(
      `api/${API_VERSION}/sales/getTotalSoldItems`,
      null,
      headerObject,
      'GET',
      `?startDate=${From}&endDate=${To}`
    ).then((Responce) => {
      return Responce;
    });
  }

  async fetchCategoryWiseSle(From, To) {
    const header = authHeader();

    const headerObject = {
      'Content-Type': 'application/json',
      Authorization: header.Authorization,
    };
    return callAPI(
      `api/${API_VERSION}/sales/categoryWiseSales`,
      null,
      headerObject,
      'GET',
      `?startDate=${From}&endDate=${To}`
    ).then((Responce) => {
      return Responce;
    });
  }
}
export default new DashboardService();
