import React from 'react';
import {
  InputAdornment,
  TextField,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Paper,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import clsx from 'clsx';
const SalesReturnModalBody = ({
  classes,
  modalStyle,
  keyPressDownForSalesReturn,
  salesReturnList,
  control,
  modleCloseSalesReturn,
  saveSalesReturn,
  salesReturnBtnDisableStatus,
}) => {
  return (
    <>
      <div style={modalStyle} className={classes.paper4}>
        <h2 id="simple-modal-title">Sales Return</h2>
        <TextField
          label="Order Number"
          margin="normal"
          onKeyDown={keyPressDownForSalesReturn}
          id="txtPOSSalesReturnSearch"
          className={clsx(classes.margin, classes.textField)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          autoComplete="off"
          autoFocus
        />
        <TableContainer component={Paper}>
          <Table
            id="posSalesReturnTable"
            className={classes.table}
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell>ITEM</TableCell>
                <TableCell align="center">QTY SOLD</TableCell>
                <TableCell align="center">SUB TOTAL</TableCell>
                <TableCell align="center">DISCOUNT</TableCell>
                <TableCell align="center">UNIT PRICE</TableCell>
                <TableCell align="center">PRICE</TableCell>
                <TableCell align="center">QTY RETURNED</TableCell>
                <TableCell align="center">REFUND AMOUNT</TableCell>
              </TableRow>
            </TableHead>
            <TableBody id="posSalesReturnTbody">
              {salesReturnList.itemsSold.map((row, index) => (
                <TableRow
                  id={'posSalesReturnTrow' + index}
                  key={row.itemRef.name}
                >
                  <TableCell component="th" scope="row">
                    {row.itemRef.name}
                  </TableCell>
                  <TableCell align="center">{row.quantity}</TableCell>
                  <TableCell align="center">
                    {parseFloat(row.saleAmountTotal).toFixed(2)}
                  </TableCell>
                  <TableCell align="center">
                    {parseFloat(row.discountAmount).toFixed(2)}
                  </TableCell>
                  <TableCell align="center">
                    {parseFloat(row.saleFinalPrice).toFixed(2)}
                  </TableCell>
                  <TableCell align="center">
                    {parseFloat(row.saleAmountTotal).toFixed(2)}
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      size="small"
                      id={'txtSalesReturnQtyReturned' + index}
                      variant="outlined"
                      style={{ width: '75px' }}
                      value={row.returnQty}
                      onChange={(e) => control(e, index)}
                      autoComplete="off"
                    />
                  </TableCell>
                  <TableCell align="center">
                    {parseFloat(row.refundAmount).toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div
          className={classes.flex}
          style={{
            bottom: '0px',
            position: 'fixed',
            width: '95%',
          }}
        >
          <Button
            variant="contained"
            onClick={modleCloseSalesReturn}
            id="btnSalesReturnCancel"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{
              marginLeft: 'auto',
              color: '#fff',
            }}
            onClick={saveSalesReturn}
            id="btnSalesReturnSave"
            disabled={salesReturnBtnDisableStatus}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};
SalesReturnModalBody.propTypes = {
  classes: PropTypes.any,
  modalStyle: PropTypes.any,
  keyPressDownForSalesReturn: PropTypes.any,
  salesReturnList: PropTypes.any,
  control: PropTypes.any,
  modleCloseSalesReturn: PropTypes.any,
  saveSalesReturn: PropTypes.any,
  salesReturnBtnDisableStatus: PropTypes.any,
};
export default SalesReturnModalBody;
