import authHeader from '../../../Services/auth-header';
import { callAPI } from '../../../Services/ApiCaller';
import { API_VERSION } from '../../../Common/constants';

export async function getAllVendors(
  page,
  rowsPerPage,
  sortDirection,
  sortColumnName,
  searchString
) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  let rowsFrom = page * rowsPerPage;

  let sortOrderdir = sortDirection === 'asc' ? 1 : -1;
  return callAPI(
    `api/${API_VERSION}/vendors`,
    null,
    headerObject,
    'GET',
    `?$orderBy=${sortColumnName}&$orderDir=${sortOrderdir}&$skip=${rowsFrom}&$top=${rowsPerPage}${searchString}`
  ).then((Responce) => {
    return Responce;
  });
}
export async function getAllVendorsForDropDown() {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  return callAPI(
    `api/${API_VERSION}/vendors`,
    null,
    headerObject,
    'GET',
    `?$orderBy=name&$orderDir=1&$top=10000`
  ).then((Responce) => {
    return Responce;
  });
}
export async function insertVendor(vendor) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  var vendorInsert = {
    name: vendor.name,
    contactNumber: vendor.contactNumber,
    contactPerson: vendor.contactPerson,
    address: vendor.address,
    openingBalance: vendor.openingBalance,
    active: vendor.active,
    company: vendor.company,
    NTN: vendor.NTN,
    email: vendor.email,
    openingDate: vendor.openingDate,
    endDate: vendor.endDate,
    comments: vendor.comments,
    accountingSoftwareID: vendor.accountingSoftwareID,
  };
  return callAPI(
    `api/${API_VERSION}/vendors`,
    vendorInsert,
    headerObject,
    'POST',
    ''
  ).then((Responce) => {
    return Responce;
  });
}

export async function deleteVendor(_id) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  return callAPI(
    `api/${API_VERSION}/vendors/`,
    null,
    headerObject,
    'DELETE',
    _id
  ).then((Responce) => {
    return Responce;
  });
}

export async function updateVendor(vendor) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  var vendorUpdate = {
    name: vendor.name,
    contactNumber: vendor.contactNumber,
    contactPerson: vendor.contactPerson,
    address: vendor.address,
    openingBalance: vendor.openingBalance,
    active: vendor.active,
    company: vendor.company,
    NTN: vendor.NTN,
    email: vendor.email,
    openingDate: vendor.openingDate,
    endDate: vendor.endDate,
    comments: vendor.comments,
    accountingSoftwareID: vendor.accountingSoftwareID,
  };

  return callAPI(
    `api/${API_VERSION}/vendors`,
    vendorUpdate,
    headerObject,
    'PUT',
    `/${vendor._id}`
  ).then((Responce) => {
    return Responce;
  });
}
