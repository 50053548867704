import authHeader from '../../../Services/auth-header';
import { callAPI } from '../../../Services/ApiCaller';
import { API_VERSION } from '../../../Common/constants';

export async function getSingleCashReconcileDetail(_id) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  return callAPI(
    `api/${API_VERSION}/cashReconciles/`,
    null,
    headerObject,
    'GET',
    _id
  ).then((Responce) => {
    return Responce;
  });
}

export async function getAllCashReconciles(
  page,
  rowsPerPage,
  sortDirection,
  sortColumnName
) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  let rowsFrom = page * rowsPerPage;

  let sortOrderdir = sortDirection === 'asc' ? 1 : -1;

  if (!sortColumnName) {
    sortColumnName = 'actualClosingAmount,createdAt';
  }
  return callAPI(
    `api/${API_VERSION}/cashReconciles`,
    null,
    headerObject,
    'GET',
    `?populate=createdBy&$orderDir=${sortOrderdir}&$orderBy=${sortColumnName}&$skip=${rowsFrom}&$top=${rowsPerPage}`
  ).then((Responce) => {
    return Responce;
  });
}

export async function getFilteredCashReconciles(
  page,
  rowsPerPage,
  sortDirection,
  sortColumnName,
  searchString
) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  let rowsFrom = page * rowsPerPage;

  let sortOrderdir = sortDirection === 'asc' ? 1 : -1;

  if (!sortColumnName) {
    sortColumnName = 'updatedAt';
  }
  return callAPI(
    `api/${API_VERSION}/cashReconciles`,
    null,
    headerObject,
    'GET',
    `?populate=createdBy&$orderDir=${sortOrderdir}&$orderBy=${sortColumnName}&$skip=${rowsFrom}&$top=${rowsPerPage}${searchString}`
  ).then((Responce) => {
    return Responce;
  });
}
