import React from 'react';
import { Modal } from '@material-ui/core';
import HoldOrderModalBody from './HoldOrderModalBody';

import PropTypes from 'prop-types';
const HoldOrderModal = (props) => {
  return (
    <div>
      <Modal
        open={props.open3}
        onClose={props.handleClose3}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div>
          <HoldOrderModalBody {...props} />
        </div>
      </Modal>
    </div>
  );
};
HoldOrderModal.propTypes = {
  open3: PropTypes.any,
  handleClose3: PropTypes.any,
  props: PropTypes.any,
};
export default HoldOrderModal;
