import authHeader from '../../../Services/auth-header';
import { callAPI } from '../../../Services/ApiCaller';
import { API_VERSION } from '../../../Common/constants';

export async function getNayaPayData() {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  return callAPI(
    `api/${API_VERSION}/nayapay`,
    null,
    headerObject,
    'GET',
    ``
  ).then((Responce) => {
    return Responce;
  });
}

export async function GetAllNayaPayTransactions(
  page,
  rowsPerPage,
  sortDirection,
  sortColumnName,
  searchString
) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  let rowsFrom = page * rowsPerPage;

  let sortOrderdir = sortDirection === 'asc' ? 1 : -1;

  let queryString = `?$orderBy=${sortColumnName}&$orderDir=${sortOrderdir}&$skip=${rowsFrom}&$top=${rowsPerPage}${searchString}`;

  if (!sortColumnName) {
    sortColumnName = 'name';
  }
  return callAPI(
    `api/${API_VERSION}/paymenttransaction`,
    null,
    headerObject,
    'GET',
    queryString
  ).then((Responce) => {
    return Responce;
  });
}

export async function insertNayaPayData(nayapay) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  var nayapayInsert = {
    merchantName: nayapay.merchantName,
    merchantID: nayapay.merchantID,
    branchID: nayapay.branchID,
    apiKey: nayapay.apiKey,
    token: nayapay.token,
  };
  return callAPI(
    `api/${API_VERSION}/nayapay`,
    nayapayInsert,
    headerObject,
    'POST',
    ''
  ).then((Responce) => {
    return Responce;
  });
}

export async function updateNayaPayData(nayapay) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  var nayapayUpdate = {
    merchantName: nayapay.merchantName,
    merchantID: nayapay.merchantID,
    branchID: nayapay.branchID,
    apiKey: nayapay.apiKey,
    token: nayapay.token,
  };

  return callAPI(
    `api/${API_VERSION}/nayapay`,
    nayapayUpdate,
    headerObject,
    'PUT',
    `/${nayapay._id}`
  ).then((Responce) => {
    return Responce;
  });
}
