import authHeader from '../../../Services/auth-header';
import { callAPI } from '../../../Services/ApiCaller';
import { API_VERSION } from '../../../Common/constants';

export async function insertCredit(creditInfo) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  return callAPI(
    `api/${API_VERSION}/creditManagement`,
    creditInfo,
    headerObject,
    'POST',
    ''
  ).then((response) => {
    return response;
  });
}
export async function getCredit(
  page,
  rowsPerPage,
  sortDirection,
  sortColumnName,
  searchString
) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  let rowsFrom = page * rowsPerPage;

  let sortOrderdir = sortDirection === 'asc' ? 1 : -1;
  if (!sortColumnName) {
    sortColumnName = 'createdAt';
  }
  return callAPI(
    `api/${API_VERSION}/creditManagement/creditdata`,
    '',
    headerObject,
    'GET',
    `?$orderBy=${sortColumnName}&$orderDir=${sortOrderdir}&$skip=${rowsFrom}&$top=${rowsPerPage}${searchString}`
  ).then((response) => {
    return response;
  });
}

export async function getCustomerCreditInfo(customerRef) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  return callAPI(
    `api/${API_VERSION}/creditManagement/creditdata`,
    '',
    headerObject,
    'GET',
    `?customerRef=${customerRef}`
  ).then((response) => {
    return response;
  });
}

export async function getCustomerBalance(customerId) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  return callAPI(
    `api/${API_VERSION}/creditManagement`,
    null,
    headerObject,
    'GET',
    `?$orderBy=createdAt&$orderDir=-1&customerRef=${customerId}`
  ).then((response) => {
    return response;
  });
}
