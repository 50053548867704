/*eslint-disable no-unused-vars*/
import axios from 'axios';
import authHeader from '../../../../Services/auth-header';
import { callAPI } from '../../../../Services/ApiCaller';
import { API_VERSION, BaseAPIURL } from '../../../../Common/constants';
import { decode } from 'jsonwebtoken';
import * as SetupService from '../../../Pages/Setups/SetupService';

/**/

class AuthService {
  async explodeTokenAndSaveExtraInfo() {
    const jwt = localStorage.getItem('Token');
    const decodedToken = decode(jwt, { complete: true });
    const response = await this.GetUserInfo(decodedToken.payload.data.id);
    if (
      !response ||
      !response.data ||
      !response.data.data ||
      response.data.data.length == 0
    ) {
      return 'ERROR';
    }
    const userInfo = response.data.data[0];

    localStorage.setItem('username', userInfo.name);
    localStorage.setItem('type', userInfo.type ? userInfo.type : 'retail');
    if (
      !isNaN(userInfo.discountLimitPercentage) ||
      !isNaN(userInfo.discountLimitAmount)
    ) {
      if (userInfo.discountLimitPercentage !== null) {
        const Discount = {
          Discount: [
            {
              DiscountName: 'discountLimitPercentage',
              DiscountLimit: userInfo.discountLimitPercentage,
            },
          ],
        };

        localStorage.setItem('_DiscountInfo', JSON.stringify(Discount));
      } else if (userInfo.discountLimitAmount !== null) {
        const Discount = {
          Discount: [
            {
              DiscountName: 'discountLimitAmount',
              DiscountLimit: userInfo.discountLimitAmount,
            },
          ],
        };

        localStorage.setItem('_DiscountInfo', JSON.stringify(Discount));
      } else {
        localStorage.removeItem('_DiscountInfo');
      }
    }

    return {
      role: decodedToken.payload.data.role,
      type: userInfo.type,
    };
  }

  async getOrganizationName() {
    const Response = await SetupService.getAllSetups();
    if (Response.data) {
      let organizationInfoData = Response.data.data.filter(
        (e) => e.name === 'Organization Info'
      );
      let displayName =
        organizationInfoData && organizationInfoData.length
          ? organizationInfoData[0].data.displayName
          : '';
      localStorage.setItem('CompanyName', displayName);
    }
  }

  /*
  Send tenant name, email and password - if successful, set JWT token in local storage
  */
  async login(email, password, tenantName) {
    const response = await fetch(BaseAPIURL + `/${API_VERSION}/signin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
        organizationName: tenantName,
      }),
    });
    if (response && response.status == 200) {
      const data = await response.json();
      if (data && data.token) {
        localStorage.setItem('Token', data.token);
        return 'OK';
      }
    } else if (response && response.status) {
      return response.status;
    }
  }
  forgetPassword(companyName, email, newPassword) {
    return axios
      .put(BaseAPIURL + `/${API_VERSION}/forgetPassword`, {
        companyName,
        email,
        newPassword,
      })
      .then((response) => {
        return response.status;
      });
  }
  tenantExists(tenant) {
    return axios
      .post(BaseAPIURL + `/${API_VERSION}/tenants`, {
        tenantName: tenant,
      })
      .then((response) => {
        return response.data;
      });
  }
  GetUserInfo(id) {
    const header = authHeader();
    const headerObject = {
      'Content-Type': 'application/json',
      Authorization: header.Authorization,
    };
    return callAPI(
      `api/${API_VERSION}/users`,
      null,
      headerObject,
      'GET',
      `?id=${id}`
    ).then((Responce) => {
      return Responce;
    });
  }
}

export default new AuthService();
