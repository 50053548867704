import React from 'react';
import EJS from 'ejs';
import ReactHtmlParser from 'react-html-parser';
import ConcaveCraftQR from '../../../Assets/Images/CCQR.jpg';
import QRCode from 'qrcode.react';
import styles from './invoice.module.css';

class ComponentToPrint extends React.Component {
  render() {
    const invoiceTemplate = JSON.parse(localStorage.getItem('invoiceTemplate'));
    let data = JSON.parse(window.sessionStorage.getItem('Invoice'));
    const companyName = localStorage.getItem('CompanyName');
    const userName = localStorage.getItem('username');
    const companyLogo = localStorage.getItem('Company Logo');
    const date = new Date().toLocaleString();
    const billingAddress =
      data.customerInfo &&
      data.customerInfo.addresses &&
      data.customerInfo.addresses.find(
        (address) => address.isPrimary && address.addressType === 'billing'
      );
    const shippingAddress =
      data.customerInfo &&
      data.customerInfo.addresses &&
      data.customerInfo.addresses.find(
        (address) => address.isPrimary && address.addressType === 'shipping'
      );
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };
    let htmlString;

    let templateData = {
      companyLogo,
      companyName,
      userName,
      date,
      options,
      qrCode: ConcaveCraftQR,
      invoiceNumber: data.invoiceNumber,
      data,
      orderMode: data.orderMode,
      waiter: data.waiter,
      table: data.table,
      rider: data.rider,
      customerInfo: data.customerInfo,
      billingAddress: billingAddress ? billingAddress.addressDetails : '',
      shippingAddress: shippingAddress ? shippingAddress.addressDetails : '',
      itemsSold: data.itemsSold,
      totalCount: data.itemsSold
        .map((item) => parseFloat(item.Quantity))
        .reduce((prev, next) => prev + next),
      subTotal: data.subTotal,
      taxAmount: data.taxAmount ? data.taxAmount : data.totalTaxAmount,
      overallSaleDiscountAmount: data.overallSaleDiscountAmount,
      miscellaneousCharges: data.miscellaneousCharges,
      deliveryCharges: data.deliveryCharges,
      saleAmountTotal: data.saleAmountTotal,
      saleMode: data.saleMode,
      paidAmount: data.paidAmount,
      returnAmount: data.returnAmount,
    };
    let items = templateData.itemsSold;
    function groupBy(objectArray, property) {
      return objectArray.reduce(function (acc, obj) {
        let key = obj[property];
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});
    }
    let groupedData = groupBy(items, 'category');
    templateData.byCategory = groupedData;
    try {
      htmlString = EJS.render(invoiceTemplate.templateString, templateData);
    } catch (error) {
      console.error(error.message);
    }
    return (
      <div style={{ fontFamily: 'Arial, serif' }}>
        {ReactHtmlParser(htmlString)}
        <div className={styles.qrDisplay}>
          {!!data.QRCode && <QRCode value={data.QRCode} size={200} />}
        </div>
      </div>
    );
  }
}

export default ComponentToPrint;
