import React from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from '../../../Style/AppStylesAdmin';

function Input(props) {
  const { name, label, value, error = null, onChange, ...other } = props;
  const classes = useStyles();

  return (
    <TextField
      variant="outlined"
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      autoComplete="off"
      InputLabelProps={{
        classes: {
          asterisk: classes.asterisk,
        },
      }}
      {...other}
      {...(error && { error: true, helperText: error })}
    />
  );
}
Input.propTypes = {
  name: PropTypes.any,
  label: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.any,
  error: PropTypes.any,
};
export default Input;
