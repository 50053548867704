import authHeader from '../../../Services/auth-header';
import AuthService from '../Authorization/SignIn/AuthService';
import { callAPI } from '../../../Services/ApiCaller';
import {
  API_VERSION,
  getDateTime,
  ConstNayaPayURL,
  GetTokenValue,
} from '../../../Common/constants';
import * as NayaPayService from '../NayaPayOnboarding/NayaPayActivationService';
import { decode } from 'jsonwebtoken';
class SalesAPIService {
  async getNayaPayData() {
    try {
      var apiResponce = await NayaPayService.getNayaPayData().then(
        (Responce) => {
          return Responce;
        }
      );
      if (apiResponce.data.count > 0) {
        localStorage.setItem(
          'NayaPayActivation',
          JSON.stringify(apiResponce.data.data[0])
        );
      }
    } catch (e) {
      //Do nothing
    }
  }
  async creditSaleData(customerRef, credit, currentBalance, saleRef) {
    try {
      const payload = {
        customerRef: customerRef,
        credit: credit,
        comments: 'Credit Purchase',
        currentBalance: currentBalance,
        saleRef: saleRef,
      };
      const header = authHeader();
      const headers = {
        'Content-Type': 'application/json',
        Authorization: header.Authorization,
      };
      return callAPI(
        `api/${API_VERSION}/creditManagement`,
        payload,
        headers,
        'POST',
        ''
      ).then((Responce) => {
        return Responce.data.data;
      });
    } catch (e) {
      //Do nothing
    }
  }
  GetUserDiscount() {
    const jwt = localStorage.getItem('Token');
    var decodedToken = decode(jwt, { complete: true });
    try {
      AuthService.GetUserInfo(decodedToken.payload.data.id).then(
        (Response) => {
          if (Response.data) {
            const userInfo = Response.data.data[0];
            if (userInfo.discountLimitPercentage) {
              if (userInfo.discountLimitPercentage !== null) {
                const Discount = {
                  Discount: [
                    {
                      DiscountName: 'discountLimitPercentage',
                      DiscountLimit: userInfo.discountLimitPercentage,
                    },
                  ],
                };

                localStorage.setItem('_DiscountInfo', JSON.stringify(Discount));
              } else if (userInfo.discountLimitAmount) {
                if (userInfo.discountLimitAmount !== null) {
                  const Discount = {
                    Discount: [
                      {
                        DiscountName: 'discountLimitAmount',
                        DiscountLimit: userInfo.discountLimitAmount,
                      },
                    ],
                  };

                  localStorage.setItem(
                    '_DiscountInfo',
                    JSON.stringify(Discount)
                  );
                }
              }
            } else if (userInfo.discountLimitAmount) {
              if (userInfo.discountLimitAmount !== null) {
                const Discount = {
                  Discount: [
                    {
                      DiscountName: 'discountLimitAmount',
                      DiscountLimit: userInfo.discountLimitAmount,
                    },
                  ],
                };

                localStorage.setItem('_DiscountInfo', JSON.stringify(Discount));
              } else if (userInfo.discountLimitPercentage) {
                if (userInfo.discountLimitPercentage !== null) {
                  const Discount = {
                    Discount: [
                      {
                        DiscountName: 'discountLimitPercentage',
                        DiscountLimit: userInfo.discountLimitPercentage,
                      },
                    ],
                  };

                  localStorage.setItem(
                    '_DiscountInfo',
                    JSON.stringify(Discount)
                  );
                }
              } else localStorage.removeItem('_DiscountInfo');
            }
          }
        },
        () => {
          localStorage.removeItem('_DiscountInfo');
        }
      );
    } catch (e) {
      //Do nothing
    }
  }

  startshift(OpeningAmount) {
    var payload = {
      shiftNumber: 'B1',
      reconcileStatus: 'start',
      openingAmount: OpeningAmount ? OpeningAmount : 0,
      createdAt: getDateTime(),
      createdBy: GetTokenValue('userId'),
    };
    localStorage.setItem('_IdShift', JSON.stringify(payload));
    var header = authHeader();
    const headers = {
      'Content-Type': 'application/json',
      Authorization: header.Authorization,
    };
    return callAPI(
      `api/${API_VERSION}/cashReconciles`,
      payload,
      headers,
      'POST',
      ''
    ).then((Response) => {
      if (Response.data.data._id) {
        localStorage.setItem('_IdShift', JSON.stringify(Response.data.data));
      }
      return Response.data.data;
    });
  }
  endshift(
    ExpectedAmount,
    ClosingAmount,
    InternalSaleAmount,
    Comments,
    totalExpense
  ) {
    const ShiftData = JSON.parse(localStorage.getItem('_IdShift'));
    const header = authHeader();
    const headers = {
      'Content-Type': 'application/json',
      Authorization: header.Authorization,
    };
    var payload = {
      shiftNumber: ShiftData.shiftNumber,
      openingAmount: ShiftData.openingAmount,
      expectedClosingAmount: ExpectedAmount ? ExpectedAmount : 0,
      actualClosingAmount: ClosingAmount ? ClosingAmount : 0,
      internalSaleAmount: InternalSaleAmount ? InternalSaleAmount : 0,
      comments: Comments ? Comments : '',
      createdAt: ShiftData.createdAt,
      createdBy: ShiftData.createdBy,
      updatedAt: getDateTime(),
      totalExpense: totalExpense,
    };
    if (ShiftData._id) {
      payload._id = ShiftData._id;
      return callAPI(
        `api/${API_VERSION}/cashReconciles`,
        payload,
        headers,
        'PUT',
        `/${ShiftData._id}`
      ).then((Response) => {
        return Response;
      });
    } else {
      return callAPI(
        `api/${API_VERSION}/cashReconciles`,
        payload,
        headers,
        'POST',
        ``
      ).then((Response) => {
        return Response;
      });
    }
  }
  async NayaPayPushInvoice(invoiceId, consumer, amount) {
    const payload = {
      branchId: '602242f6789e8120594f4da7',
      invoiceId: invoiceId,
      merchantId: '60223e45789e8120594f4d33',
      consumer: consumer,
      amount: amount,
      currency: '586',
      dueDate: '14-06-2021 00:00:00',
      userId: 'nayapay77@testing.com',
      terminalId: 'terminal-001',
      apiKey: '5498f78c-c50d-4703-92f7-db0b162f2405',
      NayaPayURL: ConstNayaPayURL,
    };
    const header = authHeader();
    const headers = {
      'Content-Type': 'application/json',
      Authorization: header.Authorization,
    };
    return callAPI(
      `api/${API_VERSION}/paymenttransaction/nayapay/pushInvoice`,
      payload,
      headers,
      'POST',
      ''
    ).then((Responce) => {
      return Responce.data.data;
    });
  }
  async InvoiceSyncRequest(obj) {
    var FinalLIST = [];
    obj.itemsSold.forEach((item) => {
      FinalLIST.push({
        itemRef: item._id,
        quantity: item.Quantity,
        taxAmount: (
          item.salePrice *
          item.Quantity *
          (item.taxPercentage / 100)
        ).toFixed(2),
        discountAmount: item.Tempdiscount,
        saleFinalPrice: item.unitSaleFinalPrice,
        saleAmountTotal: item.saleFinalPrice,
      });
    });
    const PayLoad = {
      saleType: obj.saleType,
      saleMode: obj.saleMode,
      shiftNumber: obj.shiftNumber,
      invoiceNumber: obj.invoiceNumber,
      itemsSold: FinalLIST,
      clientCreatedAt: obj.clientCreatedDate,
      clientUpdatedAt: obj.clientModifiedDate,
      overallSaleDiscountAmount: obj.overallSaleDiscountAmount,
      miscellaneousCharges: obj.miscellaneousCharges,
      saleAmountSumOfItems: obj.saleAmountSumOfItems,
      saleAmountTotal: obj.saleAmountTotal,
      customerRef: obj.customerRef,
    };
    const header = authHeader();
    const headers = {
      'Content-Type': 'application/json',
      Authorization: header.Authorization,
    };
    return callAPI(
      `api/${API_VERSION}/sales`,
      PayLoad,
      headers,
      'POST',
      '?populate=itemsSold'
    );
  }
  async GetExpectedAmts() {
    const ShiftData = JSON.parse(localStorage.getItem('_IdShift'));
    const header = authHeader();
    const headers = {
      'Content-Type': 'application/json',
      Authorization: header.Authorization,
    };
    return callAPI(
      `api/${API_VERSION}/sales`,
      null,
      headers,
      'GET',
      '?saleType=external&$top=5000&saleMode=cash&shiftNumber=' +
        ShiftData.shiftNumber +
        '&createdBy=' +
        ShiftData.createdBy +
        '&updatedFrom=' +
        ShiftData.createdAt +
        '&updatedTo=' +
        getDateTime()
    ).then((Responce) => {
      return Responce;
    });
  }
  async ShiftSyncRequest(data) {
    const header = authHeader();
    const headers = {
      'Content-Type': 'application/json',
      Authorization: header.Authorization,
    };
    var payload = {
      shiftNumber: data.shiftNumber,
      openingAmount: data.openingAmount,
      expectedClosingAmount: data.ExpectedAmount ? data.ExpectedAmount : 0,
      actualClosingAmount: data.actualClosingAmount
        ? data.actualClosingAmount
        : 0,
      internalSaleAmount: data.internalSaleAmount ? data.internalSaleAmount : 0,
      comments: data.comments ? data.comments : '',
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
      createdBy: data.createdBy,
    };

    if (data._id) {
      payload._id = data._id;
      return callAPI(
        `api/${API_VERSION}/cashReconciles`,
        payload,
        headers,
        'PUT',
        `/${data._id}`
      ).then((Response) => {
        return Response.data.data;
      });
    } else {
      return callAPI(
        `api/${API_VERSION}/cashReconciles`,
        payload,
        headers,
        'POST',
        ``
      ).then((Response) => {
        return Response.data.data;
      });
    }
  }
  async GetTotalSaleAmount(shiftNumber, createdBy, createdAt, updatedAt) {
    const header = authHeader();
    const headers = {
      'Content-Type': 'application/json',
      Authorization: header.Authorization,
    };
    return callAPI(
      `api/${API_VERSION}/sales`,
      null,
      headers,
      'GET',
      '?saleType=external&saleMode=cash&shiftNumber=' +
        shiftNumber +
        '&createdBy=' +
        createdBy +
        '&updatedFrom=' +
        createdAt +
        '&updatedTo=' +
        updatedAt
    ).then((Response) => {
      return Response;
    });
  }
  async GetSalesData(_id) {
    const header = authHeader();
    const headers = {
      'Content-Type': 'application/json',
      Authorization: header.Authorization,
    };
    return callAPI(
      `api/${API_VERSION}/sales`,
      null,
      headers,
      'GET',
      '?populate=itemsSold,itemsReturned&invoiceNumber=' + _id
    ).then((Responce) => {
      return Responce.data.data;
    });
  }
  async GetCustomerData(_id) {
    const header = authHeader();
    const headers = {
      'Content-Type': 'application/json',
      Authorization: header.Authorization,
    };
    return callAPI(
      `api/${API_VERSION}/customers`,
      null,
      headers,
      'GET',
      '?contactNumber=' + _id
    ).then((Responce) => {
      return Responce.data.data;
    });
  }
  async GetInvoiceTemplateType(Type) {
    const header = authHeader();
    const headers = {
      'Content-Type': 'application/json',
      Authorization: header.Authorization,
    };
    try {
      return callAPI(
        `api/${API_VERSION}/setup`,
        null,
        headers,
        'GET',
        `?name=${Type}`
      ).then((Responce) => {
        if (Responce.data) {
          if (Responce.data.count > 0) {
            var tempData = Responce.data.data[0].data.filter(
              (e) => e.status == 'active'
            );
            localStorage.setItem('TemplateType', tempData[0].value);
            return Responce.data.data;
          } else {
            return localStorage.setItem('TemplateType', 'Default');
          }
        } else {
          return localStorage.setItem('TemplateType', 'Default');
        }
      });
    } catch (e) {
      //do nothing
      console.error(e);
    }
  }
  async GetSettings(Type) {
    const header = authHeader();
    const headers = {
      'Content-Type': 'application/json',
      Authorization: header.Authorization,
    };
    try {
      return callAPI(
        `api/${API_VERSION}/setup`,
        null,
        headers,
        'GET',
        '?name=' + Type
      ).then((Responce) => {
        if (Responce.data) {
          if (Responce.data.count > 0) {
            localStorage.setItem(Type, Responce.data.data[0].data);
            return Responce.data.data;
          } else {
            return localStorage.setItem(Type, 'default');
          }
        } else {
          return localStorage.setItem(Type, 'default');
        }
      });
    } catch (e) {
      //do nothing
      console.error(e);
    }
  }
  async SalesReturnRequest(obj) {
    const header = authHeader();
    const headers = {
      'Content-Type': 'application/json',
      Authorization: header.Authorization,
    };
    var itemsReturneds = [];
    var itemsSolds = [];
    var returnedAmountSum = 0;
    obj.itemsSold.forEach((item) => {
      if (item.returnQty > 0) {
        itemsReturneds.push({
          itemRef: item.itemRef._id,
          name: item.itemRef.name,
          quantity: item.returnQty,
          returnFinalPrice: item.saleFinalPrice,
          returnAmountTotal: item.refundAmount,
        });
        returnedAmountSum = returnedAmountSum + parseFloat(item.refundAmount);
      }
    });
    obj.itemsSold.forEach((item) => {
      itemsSolds.push({
        itemRef: item.itemRef._id,
        name: item.itemRef.name,
        quantity: item.quantity,
        taxAmount: item.taxAmount,
        discountAmount: item.discountAmount,
        saleFinalPrice: item.saleFinalPrice,
        saleAmountTotal: item.saleAmountTotal,
      });
    });
    const PayLoad = {
      saleType: obj.saleType,
      saleMode: obj.saleMode,
      _id: obj._id,
      shiftNumber: obj.shiftNumber,
      invoiceNumber: obj.invoiceNumber,
      itemsSold: itemsSolds,
      itemsReturned: itemsReturneds,
      overallSaleDiscountAmount: obj.overallSaleDiscountAmount,
      miscellaneousCharges: obj.miscellaneousCharges,
      saleAmountSumOfItems: obj.saleAmountSumOfItems,
      returnedAmountSumOfItems: returnedAmountSum,
      saleAmountTotal: obj.saleAmountTotal - returnedAmountSum,
      createdAt: obj.createdAt,
      updatedAt: getDateTime(),
      clientCreatedAt: obj.clientCreatedAt,
      clientUpdatedAt: getDateTime(),
      customerRef: obj.customerRef,
      __v: obj.__v + 1,
    };
    window.sessionStorage.setItem(
      'SalesReturnInvoice',
      JSON.stringify(PayLoad)
    );
    return callAPI(
      `api/${API_VERSION}/sales`,
      PayLoad,
      headers,
      'PUT',
      '/' + obj._id
    );
  }
  async getAllSales(page, rowsPerPage, sortDirection, sortColumnName) {
    const header = authHeader();
    const headers = {
      'Content-Type': 'application/json',
      Authorization: header.Authorization,
    };
    const ShiftData = JSON.parse(localStorage.getItem('_IdShift'));
    let rowsFrom = page * rowsPerPage;

    let sortOrderdir = sortDirection === 'asc' ? 1 : -1;
    if (!sortColumnName) {
      sortColumnName = 'updatedAt';
    }
    return callAPI(
      `api/${API_VERSION}/sales`,
      null,
      headers,
      'GET',
      `?populate=?populate=itemsSold,itemsReturned&updatedFrom=${ShiftData.createdAt}&createdBy=${ShiftData.createdBy}&$orderBy=${sortColumnName}&$orderDir=${sortOrderdir}&$skip=${rowsFrom}&$top=${rowsPerPage}`
    ).then((Responce) => {
      return Responce;
    });
  }

  async getTotalExpense() {
    const header = authHeader();
    const headers = {
      'Content-Type': 'application/json',
      Authorization: header.Authorization,
    };
    const ShiftData = JSON.parse(localStorage.getItem('_IdShift'));
    return callAPI(
      `api/${API_VERSION}/expenses`,
      null,
      headers,
      'GET',
      `?filteredFrom=${
        ShiftData ? ShiftData.updatedAt : ''
      }&filteredTo=${getDateTime()}`
    ).then((Responce) => {
      return Responce;
    });
  }
  async addExpense(data) {
    const header = authHeader();
    const headers = {
      'Content-Type': 'application/json',
      Authorization: header.Authorization,
    };
    return callAPI(
      `api/${API_VERSION}/expenses`,
      data,
      headers,
      'POST',
      ''
    ).then((Responce) => {
      return Responce;
    });
  }
}

export default new SalesAPIService();
