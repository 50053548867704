import React, { useState, forwardRef } from 'react';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import Header from './Header';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItemText from '@material-ui/core/ListItemText';
import Logo from '../../Assets/Images/ConcaveLogo.png';
import { Collapse, ListItem } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import menuItems from '../Pages/sideBarItems';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import styles from './MasterLayout.module.css';

function MasterLayout({ children }, props) {
  const pathName = window.location.pathname;
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [menu, setMenu] = useState({});
  const { ...rest } = props;
  const handleClick = (item) => {
    let newData = { ...menu, [item]: !menu[item] };
    setMenu(newData);
  };
  const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref} style={{ flexGrow: 1 }}>
      <RouterLink {...props} />
    </div>
  ));
  CustomRouterLink.displayName = 'CustomRouterLink';
  const handleMenu = (children) => {
    let hideFeatures = JSON.parse(localStorage.getItem('_HideFeature')) || [];
    let commissionHideFeature = hideFeatures.find(
      (hf) => hf.name === 'CommissionReport'
    );
    const isCommissionReport =
      commissionHideFeature && commissionHideFeature.status ? true : false;
    return children
      .filter((e) => e.listItem == true)
      .map(({ children, name, url, authorize, level, type, icon }) => {
        if (authorize.includes(window.localStorage.getItem('role'))) {
          if (type.includes(window.localStorage.getItem('type'))) {
            if (
              window.localStorage.getItem('type') === 'food' ||
              (window.localStorage.getItem('type') === 'retail' &&
                name !== 'Branch Mgmt') ||
              window.localStorage.getItem('role') == 'superadmin'
            ) {
              if (
                !(url === '/CommissionReport') ||
                (isCommissionReport &&
                  (localStorage.getItem('role') === 'superadmin' ||
                    localStorage.getItem('role') === 'companyadmin'))
              ) {
                if (!children) {
                  return (
                    <List component="div" disablePadding key={name}>
                      {level === '1' ? (
                        <ListItem
                          key={name}
                          button
                          component={CustomRouterLink}
                          to={url}
                          id={name}
                          selected={url === pathName}
                          className={styles.sidebarLink}
                        >
                          <ListItemIcon className={styles.iconWidth}>
                            {icon}
                          </ListItemIcon>
                          <ListItemText primary={name} />
                        </ListItem>
                      ) : (
                        <ListItem
                          key={name}
                          button
                          component={CustomRouterLink}
                          to={url}
                          id={name}
                          className={styles.nested}
                          selected={url === pathName}
                        >
                          <ListItemText primary={name} />
                        </ListItem>
                      )}
                    </List>
                  );
                }
                return (
                  <div key={name}>
                    {level === '1' ? (
                      <ListItem
                        key={name}
                        id={name}
                        onClick={() => handleClick(name)}
                        button
                        selected={url === pathName}
                      >
                        <ListItemIcon className={styles.iconWidth}>
                          {icon}
                        </ListItemIcon>
                        <ListItemText primary={name} />
                        {menu[name] ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                    ) : (
                      <ListItem
                        key={name}
                        id={name}
                        onClick={() => handleClick(name)}
                        className={styles.nested}
                        button
                        selected={url === pathName}
                      >
                        <ListItemText primary={name} />
                        {menu[name] ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                    )}

                    <Collapse
                      in={menu[name] ? true : false}
                      timeout="auto"
                      unmountOnExit
                    >
                      {handleMenu(children)}
                    </Collapse>
                  </div>
                );
              }
            }
          }
        } else {
          return <></>;
        }
      });
  };

  return (
    <>
      <div className={styles.root}>
        <CssBaseline />
        <AppBar position="fixed" className={styles.appBar}>
          <Toolbar
            style={{
              backgroundColor: 'white',
            }}
          >
            <IconButton
              onClick={handleDrawerClose}
              edge="start"
              aria-label="close drawer"
              id="btnCloseDrawer"
              className={clsx(
                styles.menuButton,
                close && styles.hide,
                open && styles.show
              )}
            >
              {theme.direction === 'ltr' ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(styles.menuButton, open && styles.hide)}
              id="btnOpenDrawer"
            >
              <ChevronRightIcon style={{ color: 'slategrey' }} />
            </IconButton>
            <img
              variant="square"
              alt="Cindy Baker"
              src={Logo}
              style={{ height: '40px' }}
            />
            <Header />
          </Toolbar>
        </AppBar>
        <Drawer
          className={styles.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: styles.drawerPaper,
          }}
        >
          <List {...rest} className={styles.rootList}>
            {handleMenu(menuItems.data)}
          </List>
        </Drawer>
        <main
          className={clsx(styles.content, {
            [styles.contentShift]: open,
          })}
        >
          {children}
        </main>
      </div>
    </>
  );
}

MasterLayout.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any,
  props: PropTypes.any,
  ref: PropTypes.any,
};
export default MasterLayout;
