import React, { useState } from 'react';
import PageTitle from '../../Layout/PageTitle';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import { Paper, Toolbar, TextField, Grid } from '@material-ui/core';
import Controls from '../../Utilities/Controls/Controls';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import * as dealService from './DealService';
import { useHistory } from 'react-router-dom';
import GenericTable from '../../Utilities/ReuseableCommponents/TableReport/GenericTable';
import CircularLoader from '../../Utilities/ReuseableCommponents/CircularLoader';
import useStyles from '../../../Style/AppStylesAdmin';

const headCells = [
  { id: 'name', label: 'Deal Name', align: 'left' },
  { id: 'dealPrice', label: 'Deal Price', type: 'float' },
  {
    id: 'dealTaxPercentage',
    label: 'Deal Tax Pecentage',
    type: 'float',
  },
  { id: 'dealFinalPrice', label: 'Deal Final Price', type: 'float' },
  { id: 'createdAt', label: 'Created On', type: 'date' },
  { id: 'actions', label: 'Actions', disableSorting: true, align: 'right' },
];

let apiResponseDto = {
  data: [],
  error: null,
  loading: false,
  statusCode: 0,
};

export default function Deals() {
  const history = useHistory();
  const recordForEdit = null;
  const classes = useStyles();
  const [records, setRecords] = useState([]);
  const [recordsCount, setRecordsCount] = useState(0);
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const rowOnClick = {
    path: '/DealDetails',
    header: 'View/Edit Deals',
    property: ['recordForEdit'],
  };
  function getRecords(page, rowsPerPage, sortDirection, sortColumnName) {
    let searchString = '';
    let data = (async () => {
      setIsLoading(true);
      if (name) {
        searchString += `&name=${name}`;
      }
      apiResponseDto = await dealService
        .getAllDeals(
          page,
          rowsPerPage,
          sortDirection,
          sortColumnName,
          searchString
        )
        .then((Response) => {
          return Response;
        });
      return apiResponseDto;
    })();
    data.then((Response) => {
      setIsLoading(false);
      if (Response.data) {
        setRecords(Response.data.data);
        setRecordsCount(Response.data.count);
        if (Response.data.count == 0) {
          setNotify({
            isOpen: true,
            message: 'No Data Found',
            type: 'error',
          });
        }
      } else {
        setIsLoading(false);
        console.error('Error While Fetching Data: ', Response.error);
      }
    });
  }

  const handleSearch = (e) => {
    e.preventDefault();
    getRecords(0, 5, -1, '');
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const onDelete = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    try {
      apiResponseDto.data = null;
      apiResponseDto.error = null;
      apiResponseDto = await dealService.deleteDeal(id).then((Response) => {
        return Response;
      });
      if (apiResponseDto.data) {
        getRecords(0, 10, 1, 'name');
        setNotify({
          isOpen: true,
          message: 'Deleted Successfully',
          type: 'success',
        });
      }
      if (apiResponseDto.error) {
        if (apiResponseDto.statusCode === 403) {
          setNotify({
            isOpen: true,
            message:
              'Unable To Delete Recipe : Products are also Associated With This Recipe',
            type: 'error',
          });
        } else {
          setNotify({
            isOpen: true,
            message: 'Unable To Process Request',
            type: 'error',
          });
        }
      }
    } catch (e) {
      setNotify({
        isOpen: true,
        message: 'Unable To Process Request',
        type: 'error',
      });
    }
  };

  return (
    <>
      <PageTitle
        title="Deals"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="dealsListHeading"
      />
      <Paper elevation={1} square className="paperMargin">
        <Toolbar disableGutters>
          <form onSubmit={handleSearch} className="searchFormAlign">
            <Grid container style={{ display: 'flex' }}>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  id="txtSearchDealName"
                  label="Name"
                  className={classes.searchInput}
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <Controls.Button
                  text="Search"
                  variant="contained"
                  color="primary"
                  id="btnDealSearch"
                  className="searchButton"
                  startIcon={<SearchIcon />}
                  type="submit"
                />
              </Grid>
              <Grid item xs={12} md={4}></Grid>
              <Grid item xs={12} md={2}>
                <Controls.Button
                  text="Add New"
                  variant="contained"
                  color="primary"
                  id="btnAddDeal"
                  startIcon={<AddIcon />}
                  className="newButton"
                  onClick={() => {
                    history.push({
                      pathname: '/DealDetails',
                      state: {
                        pageHeaderTitile: 'Add New Deal',
                        recordForEdit,
                      },
                    });
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </Toolbar>
        <CircularLoader isload={isLoading} />
        <GenericTable
          data={records}
          TableId={'dealsTable'}
          TableTitle={'Deals Table'}
          cellData={[
            { name: 'name', type: 'string' },
            { name: 'dealPrice', type: 'float' },
            { name: 'dealTaxPercentage', type: 'float' },
            { name: 'dealFinalPrice', type: 'float' },
            { name: 'createdAt', type: 'date' },
            { name: 'actions', type: 'remove' },
          ]}
          headCells={headCells}
          recordsCount={recordsCount}
          getRecordsFn={getRecords}
          rowDeleteDialog={setConfirmDialog}
          rowDeletefn={onDelete}
          rowOnClick={rowOnClick}
          isLoading={isLoading}
        />
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        id_Yes="btnDelDealYes"
        id_No="btnDelDealNo"
      />
    </>
  );
}
