import { Paper, Toolbar, TextField } from '@material-ui/core';
import Controls from '../../Utilities/Controls/Controls';
import SearchIcon from '@material-ui/icons/Search';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import React, { useState } from 'react';
import PageTitle from '../../Layout/PageTitle';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import * as commissionService from './CommissionReportService';
import CircularLoader from '../../Utilities/ReuseableCommponents/CircularLoader';
import GenericTable from '../../Utilities/ReuseableCommponents/TableReport/GenericTable';
import { dateTimeZone as timeZone } from '../../../Common/constants';

const headCells = [
  {
    id: 'categoryName',
    label: 'Category Name',
    disableSorting: true,
    align: 'left',
    type: 'string',
  },
  {
    id: 'commissionPercent',
    label: 'Merchant',
    disableSorting: true,
    align: 'right',
    type: 'float',
  },
  {
    id: 'categoryPercent',
    label: 'Category',
    disableSorting: true,
    align: 'right',
    type: 'float',
  },
  {
    id: 'quantity',
    label: 'Quantity',
    disableSorting: true,
    align: 'right',
    type: 'float',
  },
  {
    id: 'discount',
    label: 'Discount',
    disableSorting: true,
    align: 'right',
    type: 'float',
  },
  {
    id: 'beforeDiscountSale',
    label: 'Sales Before Disc.',
    disableSorting: true,
    align: 'right',
    type: 'float',
  },
  {
    id: 'afterDiscountSale',
    label: 'Sales After Disc.',
    disableSorting: true,
    align: 'right',
    type: 'float',
  },
];

function buildSearchString(categoryName, dateFrom, dateTo) {
  let searchString = '';
  if (categoryName) {
    searchString += `&categoryName=${categoryName}`;
  }
  if (dateFrom) {
    searchString += `&startDate=${dateFrom}T00:00:00${timeZone()}`;
  }
  if (dateTo) {
    searchString += `&endDate=${dateTo}T23:59:59${timeZone()}`;
  }

  return searchString;
}

function CommissionReport() {
  const [records, setRecords] = useState([]);
  const [recordsCount, setRecordsCount] = useState(0);
  const [categoryName, setCategoryName] = useState('');
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [footerRecords, setFooterRecords] = useState({});

  function getRecords(page, rowsPerPage, sortDirection, sortColumnName) {
    const records = (async () => {
      setIsLoading(true);

      const searchString = buildSearchString(categoryName, dateFrom, dateTo);

      return await commissionService.getCommissionReportOnSale(
        page,
        rowsPerPage,
        sortDirection,
        sortColumnName,
        searchString
      );
    })();

    records
      .then(({ data }) => {
        setIsLoading(false);
        if (data.count === 0) {
          return;
        }
        setRecords(data.data);
        setFooterRecords(data.total);
        setRecordsCount(data.count);
      })
      .catch((e) => console.error('Error While Fetching Data: ', e));
  }

  const handleSearch = (e) => {
    e.preventDefault();
    getRecords(0, 5, -1, 'createdAt');
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  return (
    <>
      <div id="print-mount"></div>
      <PageTitle
        title="Commission Report"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="commissionReportListHeading"
      />

      <Paper elevation={6} square className="PaperMargin">
        <Toolbar disableGutters>
          <form className="searchFormAlign " onSubmit={handleSearch}>
            <Controls.Input
              name="txtSearchCat"
              label="Category Name"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
              className="searchInput"
              id="txtSearchCatName"
            />
            <TextField
              id="txtSearchCommReportDateFrom"
              label="Date From"
              variant="outlined"
              type="date"
              onChange={(e) => setDateFrom(e.target.value)}
              value={dateFrom}
              className="searchInput"
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
            />
            <TextField
              id="txtSearchCommReportDateTo"
              label="Date To"
              variant="outlined"
              type="date"
              onChange={(e) => setDateTo(e.target.value)}
              value={dateTo}
              className="dateField"
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
            />
            <Controls.Button
              text="Search"
              variant="contained"
              color="primary"
              id="btnSearchCommReport"
              startIcon={<SearchIcon />}
              className="searchButton"
              type="submit"
            />
          </form>
        </Toolbar>
        <CircularLoader isload={isLoading} />
        <GenericTable
          data={records}
          TableId={'commissionReport'}
          TableTitle={'Commission Report'}
          duration={`${dateFrom} ${dateTo ? 'To' : ''} ${dateTo}`}
          cellData={[
            { name: 'categoryRef.name', type: 'string' },
            { name: 'commissionPercentage', type: 'float' },
            { name: 'categoryPercentage', type: 'float' },
            { name: 'saleQuantity', type: 'float' },
            { name: 'saleDiscount', type: 'float' },
            { name: 'saleBeforeDiscount', type: 'float' },
            { name: 'saleAfterDiscount', type: 'float' },
          ]}
          headCells={headCells}
          recordsCount={recordsCount}
          getRecordsFn={getRecords}
          footerRecords={footerRecords}
          footerCellData={[
            { name: 'commissionPercentage', type: 'float', align: 'right' },
            { name: 'categoryPercentage', type: 'float', align: 'right' },
            { name: 'saleQuantity', type: 'float', align: 'right' },
            { name: 'saleDiscount', type: 'float', align: 'right' },
            { name: 'saleBeforeDiscount', type: 'float', align: 'right' },
            { name: 'saleAfterDiscount', type: 'float', align: 'right' },
          ]}
          isLoading={isLoading}
        />
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}

export default CommissionReport;
