import authHeader from '../../../Services/auth-header';
import { callAPI } from '../../../Services/ApiCaller';
import {
  BaseAPIURL,
  API_VERSION,
  getDateTime,
} from '../../../Common/constants';
import axios from 'axios';
const companyType = localStorage.getItem('type');

export const getUnitTypeCollection = () => [
  { _id: 'ml', name: 'ml - Milliliters', nameValue: 'ml' },
  { _id: 'ltr', name: 'ltr - Liters', nameValue: 'ltr' },
  { _id: 'gm', name: 'gm - Grams', nameValue: 'gm' },
  { _id: 'kg', name: 'kg - Kilo Grams', nameValue: 'kg' },
  { _id: 'Pcs', name: 'Pcs - Pieces/Packs', nameValue: 'Pcs' },

  { _id: 'big', name: 'Big - 14"', nameValue: 'big' },
  { _id: 'bigger', name: 'Bigger - 18"', nameValue: 'bigger' },
  { _id: 'biggest', name: 'Biggest - 30"', nameValue: 'biggest' },

  { _id: 'bigSlice', name: 'Big Slice - 15"', nameValue: 'bigSlice' },
  { _id: 'biggerSlice', name: 'Bigger Slice - 30"', nameValue: 'biggerSlice' },

  { _id: 'bowl', name: 'Bowl', nameValue: 'bowl' },

  { _id: '2Pcs', name: '2 Pcs', nameValue: '2Pcs' },
  { _id: '4Pcs', name: '4 Pcs', nameValue: '4Pcs' },
  { _id: '6Pcs', name: '6 Pcs', nameValue: '6Pcs' },
];

export const getDiscountLimitTypeCollection = () => [
  {
    _id: '1',
    name: 'Discount In Percentage',
    nameValue: 'DiscountInPercentage',
  },
  { _id: '2', name: 'Discount In Amount', nameValue: 'DiscountInAmount' },
];

function findUnit(unitValue) {
  var unitResult = getUnitTypeCollection().find(function (ObjectRow) {
    if (ObjectRow._id == unitValue) {
      return ObjectRow._id;
    }
    return '';
  });

  return unitResult.nameValue;
}

export async function getAllProducts(
  page,
  rowsPerPage,
  sortDirection,
  sortColumnName,
  searchString
) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  let rowsFrom = page * rowsPerPage;

  let sortOrderdir = sortDirection === 'asc' ? 1 : -1;

  if (!sortColumnName) {
    sortColumnName = 'updatedAt';
  }

  let sortBy = sortColumnName;
  if (!sortColumnName) {
    sortBy = 'updatedAt';
  } else {
    switch (sortColumnName) {
      case 'category':
        sortBy = 'categoriesRef';
        break;
      case '':
        sortBy = 'updatedAt';
        break;
      default:
        sortBy = sortColumnName;
        break;
    }
  }

  return callAPI(
    `api/${API_VERSION}/items`,
    null,
    headerObject,
    'GET',
    `?populate=categoriesRef&$orderBy=${
      (sortColumnName, sortBy)
    }&$orderDir=${sortOrderdir}&$skip=${rowsFrom}&$top=${rowsPerPage}${searchString}`
  ).then((Responce) => {
    return Responce;
  });
}
export async function getCustomizeBarcode() {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  return callAPI(
    `api/${API_VERSION}/items`,
    null,
    headerObject,
    'GET',
    `/barcode`
  ).then((Responce) => {
    return Responce;
  });
}
export async function insertProduct(product) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  let foodObjectValue = {};
  if (companyType == 'food') {
    foodObjectValue = {
      isSaleable: product.isSaleable,
      isRecipeItem: product.isRecipeItem,
    };
  }
  var categoriesRef = [];
  categoriesRef.push(product.categoriesRef_id);

  var productAdd = {
    name: product.name,
    categoriesRef: categoriesRef,
    salePrice: product.salePrice,
    taxPercentage: product.taxPercentage,
    saleFinalPrice: product.saleFinalPrice,
    unitOfPurchase: findUnit(product.unitOfPurchase),
    unitOfSale: findUnit(product.unitOfSale),
    conversionUnit: product.conversionUnit,
    status: product.status ? 'active' : 'disabled',
    barcode: product.barcode,
    minDiscountPercentage: product.minDiscountPercentage,
    maxDiscountPercentage: product.maxDiscountPercentage,
    description: product.description,
    clientUpdatedAt: getDateTime(),
    updatedAt: getDateTime(),
    ...foodObjectValue,
  };
  if (product.sku) {
    productAdd.sku = product.sku;
  }
  if (product.image) {
    var data = product.image.replace('data:', '');
    var bufferData = data.split(',');
    var contentType = bufferData[0].split(';');

    productAdd.image = {
      data: bufferData[1],
      contentType: contentType[0],
    };
  }
  return callAPI(
    `api/${API_VERSION}/items`,
    productAdd,
    headerObject,
    'POST',
    ''
  ).then((Responce) => {
    return Responce;
  });
}

export async function updateProduct(product) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  let foodObjectValue = {};
  if (companyType == 'food') {
    foodObjectValue = {
      isSaleable: product.isSaleable,
      isRecipeItem: product.isRecipeItem,
    };
  }

  var categoriesRef = [];
  categoriesRef.push(product.categoriesRef_id);
  var productUpdate = {
    _id: product._id,
    categoriesRef: categoriesRef,
    status: product.status ? 'active' : 'disabled',
    name: product.name,
    purchasePrice: product.purchasePrice,
    salePrice: product.salePrice,
    taxPercentage: product.taxPercentage,
    saleFinalPrice: product.saleFinalPrice,
    unitOfPurchase: findUnit(product.unitOfPurchase),
    unitOfSale: findUnit(product.unitOfSale),
    conversionUnit: product.conversionUnit,
    barcode: product.barcode,
    minDiscountPercentage: product.minDiscountPercentage,
    maxDiscountPercentage: product.maxDiscountPercentage,
    description: product.description,
    ...foodObjectValue,
    clientUpdatedAt: getDateTime(),
    updatedAt: getDateTime(),
  };

  if (product.sku) {
    productUpdate.sku = product.sku;
  }

  if (product.image) {
    var data = product.image.replace('data:', '');
    var bufferData = data.split(',');
    var contentType = bufferData[0].split(';');

    productUpdate.image = {
      data: bufferData[1],
      contentType: contentType[0],
    };
  }

  return callAPI(
    `api/${API_VERSION}/items`,
    productUpdate,
    headerObject,
    'PUT',
    `/${product._id}`
  ).then((Responce) => {
    return Responce;
  });
}

export async function deleteProduct(_id) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  return callAPI(
    `api/${API_VERSION}/items/`,
    null,
    headerObject,
    'DELETE',
    _id
  ).then((Responce) => {
    return Responce;
  });
}

export function FileuploadHandler(files, MethodType) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'multipart/form-data',
    Authorization: header.Authorization,
  };
  const formData = new FormData();
  formData.append('file', files, files.name);
  return axios({
    method: 'post',
    url: `${BaseAPIURL}/api/${API_VERSION}/items/${MethodType}`,
    data: formData,
    headers: headerObject,
  }).then((response) => {
    return response;
  });
}

export async function patchProduct(id, productObject) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  return callAPI(
    `api/${API_VERSION}/items`,
    productObject,
    headerObject,
    'PATCH',
    `/${id}`
  ).then((Responce) => {
    return Responce;
  });
}
