import authHeader from '../../../Services/auth-header';
import { callAPI } from '../../../Services/ApiCaller';
import { API_VERSION, GetCompanyType } from '../../../Common/constants';

export const getDiscountType = () => [
  { _id: '1', name: 'Amount (Rs.)', nameValue: 'amount' },
  { _id: '2', name: 'Percentage (%)', nameValue: 'percent' },
];

export const getRole = (role) => {
  const companyType = GetCompanyType();

  if (role === 'superadmin') {
    return [
      { _id: 'superadmin', name: 'Super Admin', nameValue: 'superadmin' },
      {
        _id: 'companyadmin',
        name: 'Company Admin',
        nameValue: 'companyadmin',
      },
      {
        _id: 'branchmanager',
        name: 'Branch Manager',
        nameValue: 'branchmanager',
      },
      { _id: 'cashier', name: 'Cashier', nameValue: 'cashier' },
      ...(companyType === 'food'
        ? [{ _id: 'agent', name: 'Agent', nameValue: 'agent' }]
        : []),
    ];
  } else if (role === 'companyadmin') {
    return [
      {
        _id: 'companyadmin',
        name: 'Company Admin',
        nameValue: 'companyadmin',
      },
      {
        _id: 'branchmanager',
        name: 'Branch Manager',
        nameValue: 'branchmanager',
      },
      { _id: 'cashier', name: 'Cashier', nameValue: 'cashier' },
      ...(companyType === 'food'
        ? [{ _id: 'agent', name: 'Agent', nameValue: 'agent' }]
        : []),
    ];
  } else if (role === 'branchadmin') {
    return [
      {
        _id: 'branchmanager',
        name: 'Branch Manager',
        nameValue: 'branchmanager',
      },
      { _id: 'cashier', name: 'Cashier', nameValue: 'cashier' },
      ...(companyType === 'food'
        ? [{ _id: 'agent', name: 'Agent', nameValue: 'agent' }]
        : []),
    ];
  } else if (role === 'cashier') {
    return [{ _id: 'cashier', name: 'Cashier', nameValue: 'cashier' }];
  } else {
    return [{ _id: 'agent', name: 'Agent', nameValue: 'agent' }];
  }
};
export async function GetAllUsers(
  page,
  rowsPerPage,
  sortDirection,
  sortColumnName,
  name,
  role
) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  let rowsFrom = page * rowsPerPage;

  let sortOrderdir = sortDirection === 'asc' ? 1 : -1;

  let filterQuery = '';

  if (name != '') {
    filterQuery = filterQuery + `&name=${name}`;
  }

  if (role != '') {
    filterQuery = filterQuery + `&role=${role}`;
  }

  let queryString = `?$orderBy=${sortColumnName}&$orderDir=${sortOrderdir}&$skip=${rowsFrom}&$top=${rowsPerPage}`;
  queryString = queryString + filterQuery;

  if (!sortColumnName) {
    sortColumnName = 'name';
  }
  return callAPI(
    `api/${API_VERSION}/users`,
    null,
    headerObject,
    'GET',
    queryString
  ).then((Responce) => {
    return Responce;
  });
}

export async function getAllUsersForDropdown() {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  return callAPI(
    `api/${API_VERSION}/users`,
    null,
    headerObject,
    'GET',
    `?$orderBy=name&$orderDir=1&$top=10000`
  ).then((Responce) => {
    return Responce;
  });
}

export async function _updateUser(user) {
  var userUpdate = {
    _id: user._id,
    role: user.role,
    email: user.email,
    password: user.password,
    name: user.name,
    companyName: user.companyName,
    discountLimitAmount: user.discountLimitAmount,
    discountLimitPercent: user.discountLimitPercent,
  };

  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  return callAPI(
    `api/${API_VERSION}/users`,
    userUpdate,
    headerObject,
    'PUT',
    `/${user._id}`
  ).then((Responce) => {
    return Responce;
  });
}

export async function updateUser(userUpdate) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  return callAPI(
    `api/${API_VERSION}/users`,
    userUpdate,
    headerObject,
    'PUT',
    `/${userUpdate._id}`
  ).then((Responce) => {
    return Responce;
  });
}

export async function insertUser(user) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  return callAPI(
    `api/${API_VERSION}/users`,
    user,
    headerObject,
    'POST',
    ``
  ).then((Responce) => {
    return Responce;
  });
}
