import React from 'react';
import './invoice.module.css';

class ComponentToPrint extends React.Component {
  render() {
    const data = JSON.parse(window.sessionStorage.getItem('Invoice'));
    const companyName = localStorage.getItem('CompanyName');
    var d = new Date().toLocaleString();
    let SubHeader = '';
    let DiscountHtml = '';
    let DeliveryChargesHtml = '';
    let address = [];
    let totalDiscount = 0;
    let SpecialNoteHtml = '';
    let TaxHtml = '';

    if (data.customerInfo && data.customerInfo.addresses) {
      address =
        data.customerInfo.addresses.filter(
          (item) => item.isPrimary && item.addressType === 'shipping'
        )[0] || [];
      if (address === undefined) {
        address = data.customerInfo.addresses[0];
      }
    }

    if (data.overallSaleDiscountAmount > 0 || data.discount > 0) {
      totalDiscount =
        parseFloat(data.overallSaleDiscountAmount || 0) +
        parseFloat(data.discount || 0);
      DiscountHtml = (
        <tr>
          <td>
            <span className="cls_002">Discount:</span>
          </td>
          <td style={{ textAlign: 'right' }}>
            <span className="cls_003">{totalDiscount}</span>
          </td>
        </tr>
      );
    }

    if (data.deliveryCharges > 0 && data.deliveryCharges !== undefined) {
      DeliveryChargesHtml = (
        <tr>
          <td>
            <span className="cls_002">Delivery Charges:</span>
          </td>
          <td style={{ textAlign: 'right' }}>
            <span className="cls_003">{data.deliveryCharges}</span>
          </td>
        </tr>
      );
    }
    if (data.taxAmount > 0 && data.taxAmount !== undefined) {
      TaxHtml = (
        <tr>
          <td>
            <span className="cls_002">Tax:</span>
          </td>
          <td style={{ textAlign: 'right' }}>
            <span className="cls_003">{data.taxAmount || 0}</span>
          </td>
        </tr>
      );
    }

    if (
      data.deliveryInstruction !== '' &&
      data.deliveryInstruction !== undefined
    ) {
      SpecialNoteHtml = (
        <>
          <tr>
            <td>
              <span className="cls_002">Special Note:</span>
            </td>

            <td style={{ textAlign: 'left' }}>
              <span className="cls_003">{data.deliveryInstruction}</span>
            </td>
          </tr>
        </>
      );
    }

    if (data.orderMode === 'DineIn') {
      SubHeader = (
        <>
          <tr>
            <td>
              <span className="cls_002">Waiter:</span>
            </td>
            <td style={{ textAlign: 'left' }}>
              <span className="cls_003">{data.waiter}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span className="cls_002">Table:</span>
            </td>
            <td style={{ textAlign: 'left' }}>
              <span className="cls_003">{data.table}</span>
            </td>
          </tr>
        </>
      );
    } else if (data.orderMode === 'Delivery') {
      SubHeader = (
        <>
          <tr>
            <td>
              <span className="cls_002">Rider:</span>
            </td>
            <td style={{ textAlign: 'left' }}>
              <span className="cls_003">{data.rider}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span className="cls_002">Customer:</span>
            </td>
            <td style={{ textAlign: 'left' }}>
              <span className="cls_003">{data.customerInfo.name}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span className="cls_002">Contact #:</span>
            </td>
            <td style={{ textAlign: 'left' }}>
              <span className="cls_003">{data.customerInfo.contactNumber}</span>
            </td>
          </tr>
          <tr
            style={{
              display: address.areaRef ? '' : 'none',
            }}
          >
            <td>
              <span className="cls_002">Area:</span>
            </td>
            <td className="alignLeft">
              <span className="cls_003">
                {address.areaRef && address.areaRef.name
                  ? address.areaRef.name
                  : ''}
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span className="cls_002">Address:</span>
            </td>
            <td style={{ textAlign: 'left' }}>
              <span className="cls_003">
                {address != undefined ? address.addressDetails : ''}
              </span>
            </td>
          </tr>
        </>
      );
    }

    return (
      <div style={{ width: '100%' }}>
        <div style={{ textAlign: 'center' }}>
          <span
            className="cls_002"
            style={{
              textAlign: 'center',
              fontSize: '24px',
              fontWeight: 'bold',
            }}
          >
            {companyName}
          </span>
        </div>
        <hr />
        <div className="alignRight">
          <p
            className="cls_002"
            style={{
              textAlign: 'center',
            }}
          >
            PRE PAYMENT BILL
          </p>
        </div>
        <hr />
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td>
                <span className="cls_002">Order Number:</span>
              </td>
              <td>
                <span className="cls_003">{data.invoiceNumber}</span>
              </td>
            </tr>
            <tr>
              <td>
                <span className="cls_002">Order Date:</span>
              </td>
              <td>
                <span className="cls_003">{d}</span>
              </td>
            </tr>
            <tr>
              <td>
                <span className="cls_002">Order Mode:</span>
              </td>
              <td style={{ textAlign: 'left' }}>
                <span className="cls_003">{data.orderMode}</span>
              </td>
            </tr>
            {SpecialNoteHtml}
            {SubHeader}
          </tbody>
        </table>
        <hr />
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <th>
                <span className="cls_002">Product</span>
              </th>
              <th style={{ textAlign: 'right' }}>
                <span className="cls_002">Qty</span>
              </th>
              <th style={{ textAlign: 'right' }}>
                <span className="cls_002">Total</span>
              </th>
            </tr>

            {data.itemsSold.map((number) => (
              <tr key={number._id}>
                <td>
                  <span className="cls_004">{number.name}</span>
                  {number.dealItems &&
                    number.dealItems.map((deal) => (
                      <p
                        style={{
                          marginLeft: 10,
                          marginTop: 2,
                          marginBottom: 2,
                          fontSize: 12,
                        }}
                        key={deal.name}
                        className="cls_004"
                      >
                        {deal.name}
                      </p>
                    ))}
                </td>
                <td style={{ textAlign: 'right' }}>
                  <span className="cls_003">{number.Quantity}</span>
                </td>
                <td style={{ textAlign: 'right' }}>
                  <span className="cls_003">
                    {parseFloat(number.saleFinalPrice)}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <hr />
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td>
                <span className="cls_002">Total Items:</span>
              </td>
              <td style={{ textAlign: 'right' }}>
                <span className="cls_003">
                  {data.itemsSold
                    .map((item) => item.Quantity)
                    .reduce(
                      (prev, next) => parseFloat(prev) + parseFloat(next)
                    )}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span className="cls_002">Subtotal:</span>
              </td>
              <td style={{ textAlign: 'right' }}>
                <span className="cls_003">{data.subTotal}</span>
              </td>
            </tr>

            {TaxHtml}

            {DiscountHtml}

            {DeliveryChargesHtml}

            <tr>
              <td>
                <span className="cls_002">Total:</span>
              </td>
              <td style={{ textAlign: 'right' }}>
                <span className="cls_003">
                  {parseFloat(data.subTotal) +
                    parseFloat(
                      data.taxAmount === 0 || data.taxAmount === undefined
                        ? 0
                        : data.taxAmount
                    ) +
                    parseFloat(
                      data.deliveryCharges === 0 ||
                        data.deliveryCharges === undefined
                        ? 0
                        : data.deliveryCharges
                    ) -
                    totalDiscount}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="alignRight">
          <p
            className="cls_005"
            style={{
              textAlign: 'center',
            }}
          >
            Thanks for visiting us.
          </p>
        </div>
        <hr />
        <div className="alignRight">
          <p
            className="cls_005"
            style={{
              textAlign: 'center',
            }}
          >
            Powered by Concave POS
          </p>
        </div>
      </div>
    );
  }
}

export default ComponentToPrint;
