import React from 'react';
import './print-barcode.module.css';
var Barcode = require('react-barcode');
const defaultValues = {
  format: 'CODE128',
  background: '#FFFFFF',
  lineColor: '#000000',
  fontSize: 20,
  height: 100,
  width: 2,
  margin: 10,
  textMargin: 0,
  displayValue: true,
  font: 'monospace',
  fontOptions: 'bold',
  textAlign: 'center',
  productName: false,
  contactNumber: false,
  price: false,
  companyName: false,
};

class ComponentToPrintBarcode extends React.Component {
  render() {
    const companyName = localStorage.getItem('CompanyName');
    const contactNumber = localStorage.getItem('CompanyContactNumber');
    const data = JSON.parse(localStorage.getItem('print-barcode'));
    let barcodeSetting = JSON.parse(localStorage.getItem('barcode-setting'));
    barcodeSetting.fontOptions = barcodeSetting.fontOptions.join(' ');
    barcodeSetting = barcodeSetting ? barcodeSetting : defaultValues;
    function renderBarcodeDetails(preText, text, check) {
      if (check) {
        return (
          <div
            name={text}
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
              fontSize: barcodeSetting.fontSize,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {preText + text ? preText + text : <br></br>}
          </div>
        );
      }
    }
    function getFirstHalfOfString(item) {
      if (item && item.length >= 20) {
        return item.substring(0, 20);
      }
      return item;
    }
    function getItemNameSplice(params) {
      let secondPart = '';
      if (params && params.length >= 20) {
        secondPart = params.substring(20, 40);
      }
      return secondPart;
    }
    return (
      <div>
        {data.map((item, index) => (
          <div key={index}>
            {item.printCount ? (
              <div>
                {[...Array(parseInt(item.printCount))].map((x, countIndex) => (
                  <div id="break-code" key={countIndex}>
                    {barcodeSetting.companyName
                      ? renderBarcodeDetails(
                          '',
                          companyName,
                          barcodeSetting.companyName
                        )
                      : renderBarcodeDetails('', '', true)}
                    {barcodeSetting.contactNumber
                      ? renderBarcodeDetails(
                          '',
                          contactNumber,
                          barcodeSetting.contactNumber
                        )
                      : renderBarcodeDetails('', '', true)}
                    {barcodeSetting.productName
                      ? renderBarcodeDetails(
                          '',
                          getFirstHalfOfString(item.name),
                          barcodeSetting.productName
                        )
                      : renderBarcodeDetails('', '', true)}
                    {getItemNameSplice(item.name)
                      ? renderBarcodeDetails(
                          '',
                          getItemNameSplice(item.name),
                          barcodeSetting.productName
                        )
                      : renderBarcodeDetails('', '', true)}
                    <Barcode
                      key={countIndex}
                      value={item.barcode}
                      {...barcodeSetting}
                    />
                    {barcodeSetting.price
                      ? renderBarcodeDetails(
                          'Rs. ',
                          item.saleFinalPrice,
                          barcodeSetting.price
                        )
                      : renderBarcodeDetails('', '', true)}

                    <br></br>
                  </div>
                ))}
              </div>
            ) : (
              <></>
            )}
          </div>
        ))}
      </div>
    );
  }
}

export default ComponentToPrintBarcode;
