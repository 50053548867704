import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { decode } from 'jsonwebtoken';
import PropTypes from 'prop-types';
import menuItems from '../Pages/sideBarItems';
import { AndCondition } from '../../Common/constants';
var authorize = false;
var Rerouteable = false;
var Role = 'cashier';
function IsLoggedIn(props) {
  if (props.location.pathname.toLowerCase() === '/unauthorized') {
    return false;
  }
  const jwt = localStorage.getItem('Token');
  if (jwt) {
    var decodedToken = decode(jwt, { complete: true });
    if (!decodedToken) {
      localStorage.removeItem('Token');
      return false;
    }
    let role = decodedToken.payload.data.role;
    window.localStorage.setItem('role', role);
    Role = role;
    if (props.location.pathname.toLowerCase() === '/signin') {
      Role = role;
      Rerouteable = true;
      return false;
    }
    menuItems.data.forEach(function (item) {
      if (!item.children) {
        if (
          item.url === props.location.pathname &&
          item.authorize.includes(window.localStorage.getItem('role')) &&
          item.type.includes(window.localStorage.getItem('type'))
        ) {
          authorize = true;
        }
      } else {
        item.children.forEach(function (i) {
          if (!i.children) {
            if (
              i.url === props.location.pathname &&
              i.authorize.includes(window.localStorage.getItem('role')) &&
              i.type.includes(window.localStorage.getItem('type'))
            ) {
              authorize = true;
            }
          }
        });
      }
    });
    return true;
  } else {
    return false;
  }
}

function AppRoute({
  component: Component,
  layout: Layout,
  redirectcomponent: RedirectComponent,
  redirectlayout: RedirectLayout,
  ...rest
}) {
  const renderComponent = (
    fnIsLoggedIn,
    fnauthorize,
    fnRerouteable,
    fnRole,
    props
  ) => {
    if (AndCondition(fnIsLoggedIn, fnauthorize)) {
      return (
        <Layout>
          <Component {...props}></Component>
        </Layout>
      );
    }
    if (AndCondition(fnIsLoggedIn, !fnauthorize)) {
      if (
        AndCondition(
          fnRole === 'financemanager',
          window.localStorage.getItem('type') === 'retail'
        )
      ) {
        return <Redirect to="/Billing" />;
      } else if (
        AndCondition(
          fnRole === 'cashier',
          window.localStorage.getItem('type') === 'retail'
        )
      ) {
        return <Redirect to="/POS" />;
      } else if (
        AndCondition(
          fnRole === 'cashier' || fnRole === 'agent',
          window.localStorage.getItem('type') === 'food'
        )
      ) {
        return <Redirect to="/POSFood" />;
      } else {
        return <Redirect to="/unauthorized" />;
      }
    }
    if (AndCondition(!fnIsLoggedIn, fnRerouteable)) {
      if (fnRole === 'cashier') return <Redirect to="/POS" />;
      else return <Redirect to="/" />;
    }
    if (AndCondition(!fnIsLoggedIn, !fnRerouteable)) {
      return (
        <RedirectLayout>
          <RedirectComponent></RedirectComponent>
        </RedirectLayout>
      );
    }
  };
  return (
    <Route
      {...rest}
      render={(props) =>
        renderComponent(IsLoggedIn(props), authorize, Rerouteable, Role, props)
      }
    />
  );
}
AppRoute.propTypes = {
  component: PropTypes.any,
  layout: PropTypes.any,
  redirectcomponent: PropTypes.any,
  redirectlayout: PropTypes.any,
};
export default AppRoute;
