import authHeader from '../../../Services/auth-header';
import { callAPI } from '../../../Services/ApiCaller';
import { API_VERSION } from '../../../Common/constants';
import { getDateTime } from '../../../Common/constants';

export async function getAllDeals(
  page,
  rowsPerPage,
  sortDirection,
  sortColumnName,
  searchString
) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  let rowsFrom = page * rowsPerPage;

  let sortOrderdir = sortDirection === 'asc' ? 1 : -1;

  return callAPI(
    `api/${API_VERSION}/deals`,
    null,
    headerObject,
    'GET',
    `?populate=dealRef&$orderBy=${sortColumnName}&$orderDir=${sortOrderdir}&$skip=${rowsFrom}&$top=${rowsPerPage}${searchString}`
  ).then((Responce) => {
    return Responce;
  });
}

export async function insertDeal(deal) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  var recipetAdd = {
    ...deal,

    clientUpdatedAt: getDateTime(),
    updatedAt: getDateTime(),
  };

  delete recipetAdd._id;

  return callAPI(
    `api/${API_VERSION}/deals`,
    recipetAdd,
    headerObject,
    'POST',
    ''
  ).then((Responce) => {
    return Responce;
  });
}

export async function deleteDeal(_id) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  return callAPI(
    `api/${API_VERSION}/deals/`,
    null,
    headerObject,
    'DELETE',
    _id
  ).then((Responce) => {
    return Responce;
  });
}

export async function updateDeal(deal) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  var dealUpdate = {
    ...deal,
    clientUpdatedAt: getDateTime(),
    updatedAt: getDateTime(),
  };

  return callAPI(
    `api/${API_VERSION}/deals`,
    dealUpdate,
    headerObject,
    'PUT',
    `/${deal._id}`
  ).then((Responce) => {
    return Responce;
  });
}
