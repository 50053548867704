import React, { useState } from 'react';
import PageTitle from '../../Layout/PageTitle';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import { Paper, makeStyles, Toolbar, TextField } from '@material-ui/core';
import Controls from '../../Utilities/Controls/Controls';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import * as tableService from './TableService';
import { useHistory } from 'react-router-dom';
import GenericTable from '../../Utilities/ReuseableCommponents/TableReport/GenericTable';
import CircularLoader from '../../Utilities/ReuseableCommponents/CircularLoader';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  newButton: {
    position: 'absolute',
    right: '0px',
  },
  PaperMargin: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
}));

const headCells = [
  { id: 'name', label: 'Table Name', align: 'left' },
  { id: 'status', label: 'Status', type: 'status' },
  { id: 'actions', label: 'Actions', disableSorting: true, type: 'remove' },
];

let apiResponseDto = {
  data: [],
  error: null,
  loading: false,
  statusCode: 0,
};

export default function Tables() {
  const classes = useStyles();
  const history = useHistory();
  const recordForEdit = null;
  const [records, setRecords] = useState([]);
  const [recordsCount, setRecordsCount] = useState(0);
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const rowOnClick = {
    path: '/TableDetails',
    header: 'View/Edit Table',
    property: ['recordForEdit'],
  };

  function getRecords(page, rowsPerPage, sortDirection, sortColumnName) {
    let searchString = '';
    setIsLoading(true);
    let data = (async () => {
      if (name) {
        searchString += `&name=${name}`;
      }
      apiResponseDto = await tableService
        .getAllTable(
          page,
          rowsPerPage,
          sortDirection,
          sortColumnName,
          searchString
        )
        .then((Response) => {
          return Response;
        });
      return apiResponseDto;
    })();
    data.then((Response) => {
      setIsLoading(false);
      if (Response.data) {
        setRecords(Response.data.data);
        setRecordsCount(Response.data.count);
        if (Response.data.count == 0) {
          setNotify({
            isOpen: true,
            message: 'No Data Found',
            type: 'error',
          });
        }
      } else {
        setIsLoading(false);
        console.error('Error While Fetching Data: ', Response.error);
      }
    });
  }

  const handleStatusChange = async (event, index) => {
    let cloneDatatableRecords = records;
    let id = cloneDatatableRecords[index]._id;
    let checkedStatus = event.target.checked;

    if (id) {
      try {
        let categoryStatusUpdate = {
          status: checkedStatus ? 'active' : 'disabled',
        };
        apiResponseDto.data = null;
        apiResponseDto.error = null;
        apiResponseDto = await tableService
          .patchTable(id, categoryStatusUpdate)
          .then((Responce) => {
            return Responce;
          });
        if (apiResponseDto.data) {
          let newStatusChangedRecord = {
            ...cloneDatatableRecords[index],
            status: checkedStatus ? 'active' : 'disabled',
          };
          cloneDatatableRecords[index] = newStatusChangedRecord;
          setRecords(cloneDatatableRecords);
          setNotify({
            isOpen: true,
            message: 'Table Status Updated Successfully',
            type: 'success',
            notificationTime: 15000,
          });
        }
        if (apiResponseDto.error) {
          setNotify({
            isOpen: true,
            message: 'Unable To Process Request',
            type: 'error',
          });
        }
      } catch (e) {
        setNotify({
          isOpen: true,
          message: 'Unable To Process Request',
          type: 'error',
        });
      }
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    getRecords(0, 5, -1, '');
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const onDelete = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    try {
      apiResponseDto.data = null;
      apiResponseDto.error = null;
      apiResponseDto = await tableService.deleteTable(id).then((Response) => {
        return Response;
      });
      if (apiResponseDto.data) {
        getRecords(0, 10, 1, 'name');
        setNotify({
          isOpen: true,
          message: 'Deleted Successfully',
          type: 'success',
        });
      }
      if (apiResponseDto.error) {
        if (apiResponseDto.statusCode === 403) {
          setNotify({
            isOpen: true,
            message:
              'Unable To Delete Table : Sales are Associated With This Table',
            type: 'error',
          });
        } else {
          setNotify({
            isOpen: true,
            message: 'Unable To Process Request',
            type: 'error',
          });
        }
      }
    } catch (e) {
      setNotify({
        isOpen: true,
        message: 'Unable To Process Request',
        type: 'error',
      });
    }
  };

  return (
    <>
      <PageTitle
        title="Table"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="tableListHeading"
      />
      <Paper elevation={1} square className={classes.PaperMargin}>
        <Toolbar disableGutters>
          <form
            onSubmit={handleSearch}
            style={{ width: '100%', display: 'flex', alignItems: 'center' }}
          >
            <TextField
              id="txtSearchTableName"
              label="Name"
              className={classes.searchInput}
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoComplete="off"
            />

            <Controls.Button
              text="Search"
              variant="contained"
              color="primary"
              id="btnTableSearch"
              startIcon={<SearchIcon />}
              type="submit"
            />
            <Controls.Button
              text="Add New"
              variant="contained"
              color="primary"
              id="btnAddTable"
              startIcon={<AddIcon />}
              className={classes.newButton}
              onClick={() => {
                history.push({
                  pathname: '/TableDetails',
                  state: {
                    pageHeaderTitile: 'Add New Table',
                    recordForEdit,
                  },
                });
              }}
            />
          </form>
        </Toolbar>
        <CircularLoader isload={isLoading} />
        <GenericTable
          data={records}
          TableId={'tablesTable'}
          TableTitle={'Tables Table'}
          cellData={[
            { name: 'name', type: 'string' },
            { name: 'status', type: 'status' },
            { name: 'remove', type: 'remove' },
          ]}
          headCells={headCells}
          recordsCount={recordsCount}
          getRecordsFn={getRecords}
          statusChangeFn={handleStatusChange}
          rowDeleteDialog={setConfirmDialog}
          rowDeletefn={onDelete}
          rowOnClick={rowOnClick}
          isLoading={isLoading}
        />
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        id_Yes="btnDelTableYes"
        id_No="btnDelTableNo"
      />
    </>
  );
}
