import React, { useContext } from 'react';
import { TablePagination } from '@material-ui/core';
import PropTypes from 'prop-types';
import { GenericTableContext } from './GenericTable';
export default function TblPagination(props) {
  const {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useContext(GenericTableContext);

  const { recordsCount } = props;
  const pages = [5, 10, 25, 50];
  return (
    <TablePagination
      component="div"
      page={page}
      rowsPerPageOptions={pages}
      rowsPerPage={rowsPerPage}
      count={recordsCount}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );
}
TblPagination.propTypes = {
  recordsCount: PropTypes.any,
};
