import React, { useState, useEffect } from 'react';
import {
  Paper,
  TextField,
  Button,
  Grid,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableHead,
  TableContainer,
  Toolbar,
  makeStyles,
} from '@material-ui/core';
import PageHeader from '../../Layout/PageTitle';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import Controls from '../../Utilities/Controls/Controls';
import APIService from './InventoryService';
import { useHistory } from 'react-router-dom';
import * as CategoryService from '../Category/CategoryService';
import {
  getInventoryTypeCollection,
  getDateTime,
} from '../../../Common/constants';
import NumberFormatCustom from '../../Utilities/ReuseableCommponents/NumberFormatCustom';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import CircularLoader from '../../Utilities/ReuseableCommponents/CircularLoader';
import activityMonitoring from '../../../Common/functions';

const useStyles = makeStyles((theme) => ({
  PaperMargin: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  textField: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: 350,
  },
  searchInput: {
    width: '15%',
    paddingRight: '5px',
  },
  table: {
    '& thead th': {
      fontWeight: '500',
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
    },
    '& tbody td': {
      fontWeight: '300',
    },
    '& tbody tr:hover': {
      backgroundColor: '#f9f9f9',
      cursor: 'pointer',
    },
  },
  newButton: {
    marginTop: theme.spacing(1),
  },
}));

function InventoryUpdate() {
  const classes = useStyles();
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: '',
    type: '',
  });

  const [recordsCount, setRecordsCount] = useState(0);
  const history = useHistory();
  const pages = [5, 10, 25, 50];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pages[0]);
  const [order, setOrder] = useState(1);
  const [orderBy, setOrderBy] = useState('');
  const [categoriesForDropdown, setCategoriesForDropdownForDropdown] = useState(
    []
  );
  const [productName, setProductName] = useState('');
  const [productCategory, setProductCategory] = useState('');

  // Fetch Latest Inventory
  function getRecords(pageParam, rowsPerPageParam, sortDirection) {
    setIsLoaded(true);
    let searchString = '';

    if (productName) {
      searchString += `&name=${productName}`;
    }
    if (productCategory) {
      searchString += `&categoriesRef=${productCategory}`;
    }
    return APIService.FetchUpdatedLatestInventory(
      pageParam,
      rowsPerPageParam,
      sortDirection,
      searchString
    ).then((response) => {
      setIsLoaded(false);
      if (response && response.data && response.data.data) {
        response.data.data?.items?.forEach((item) => {
          item['actualCount'] = item.expectedCount;
          item['comments'] = '';
          item['inventoryType'] = 'stockgain';
        });
        setRows(response.data.data.items);
        setRecordsCount(response.data.count);
        if (response.data.count == 0) {
          setNotify({
            isOpen: true,
            message: 'No Data Found',
            type: 'error',
          });
        }
      } else console.error('Error While Fetching Data: ', response.error);
    });
  }

  useEffect(() => {
    setOrder(1);
    setOrderBy('');
    getRecords(page, rowsPerPage, order);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, order, orderBy]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function onChangeInventoryDetails(e, index) {
    let newRows = [...rows];
    let newObj = { ...newRows[index] };

    newObj[e.target.name] = e.target.value;
    newRows[index] = newObj;

    setRows(newRows);
  }

  function saveInventory() {
    var FinalLIST = [];
    var sum = 0;
    rows.forEach((item) => {
      sum = sum + parseInt(item.actualCount);
      FinalLIST.push({
        itemRef: item.itemRef,
        expectedCount: item.expectedCount,
        actualCount: item.actualCount,
        comments: item.comments,
        inventoryType: item.inventoryType,
      });
    });
    const dataSet = {
      items: FinalLIST,
      expectedCountTotal: rows.expectedCountTotal,
      actualCountTotal: sum,
      createdAt: getDateTime(),
      updatedAt: getDateTime(),
    };
    SendInventoryUpdates(dataSet).then((Response) => {
      if (Response.data) {
        getRecords(page, rowsPerPage, order);
        setNotify({
          isOpen: true,
          message: 'Save Successfully!',
          type: 'success',
        });
      }
    });
  }
  async function SendInventoryUpdates(dataSet) {
    setIsLoaded(true);
    return APIService.createInventory(dataSet).then((Response) => {
      setIsLoaded(false);
      return Response;
    });
  }

  React.useEffect(() => {
    // code for categories dropdown
    CategoryService.getAllCategoriesForDropDown().then((Response) => {
      if (Response) {
        if (Response.data) {
          //Map data array with Generic List Object with Properties id,text
          //Dropdown only accepts list of Object with Properties id,text
          var categoryList = Response.data.data.map((dataArrayRow) => ({
            _id: dataArrayRow._id,
            name: dataArrayRow.name,
          }));
          setCategoriesForDropdownForDropdown(categoryList);
          if (Response.data.count === 0) {
            setNotify({
              isOpen: true,
              message: 'No Category To Load',
              type: 'error',
            });
          }
        }
      }
    });
  }, []);
  useEffect(() => {
    activityMonitoring('AuditInventory');
  }, []);
  return (
    <>
      <PageHeader
        title="Audit Inventory"
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="inventoryUpdateHeading"
      />

      <Paper elevation={6} square className={classes.PaperMargin}>
        <Toolbar style={{ padding: '0' }}>
          <TextField
            id="txtProductName"
            label="Product"
            className={classes.searchInput}
            variant="outlined"
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
            autoComplete="off"
          />
          <Controls.Select
            name="CategoryRef_id"
            label="Category"
            className={classes.searchInput}
            style={{ width: 185 }}
            isNoneAllowed={false}
            value={productCategory}
            onChange={(e) => setProductCategory(e.target.value)}
            options={categoriesForDropdown}
            id="ddPurInvCategory"
          />
          <Controls.Button
            text="Search"
            variant="contained"
            color="primary"
            id="btnPurInvSearch"
            onClick={() => {
              getRecords(0, 5, -1);
            }}
          />
        </Toolbar>
        <CircularLoader isload={isLoaded} />

        <TableContainer>
          <Table
            id="inventoryUpdateListTable"
            className="table"
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell>S.No.</TableCell>
                <TableCell align="left">Product</TableCell>
                <TableCell align="left">Category</TableCell>
                <TableCell align="center">Expected Qty</TableCell>
                <TableCell align="center">Current Qty</TableCell>
                <TableCell align="center">Inventory Type</TableCell>
                <TableCell align="left">Comments</TableCell>
              </TableRow>
            </TableHead>
            <TableBody id="inventoryUpdateListTbody">
              {rows &&
                rows.map((row, index) => (
                  <TableRow
                    key={'inventoryUpdate' + index}
                    id={'inventoryUpdateListTrow' + index}
                  >
                    <TableCell>{index + 1}</TableCell>

                    <TableCell
                      id={'txtInventoryUpdateItemName' + index}
                      align="left"
                    >
                      {row.itemName}
                    </TableCell>

                    <TableCell
                      align="left"
                      id={'txtInventoryUpdateCat' + index}
                    >
                      {row.categoryName}
                    </TableCell>
                    <TableCell
                      id={'txtInventoryUpdateExpectedQty' + index}
                      align="center"
                    >
                      {row.expectedCount}
                    </TableCell>
                    <TableCell
                      align="center"
                      id={'txtInventoryUpdateActualCountCell' + index}
                    >
                      <Controls.Input
                        label="Current Qty"
                        value={row.actualCount}
                        onChange={(e) => onChangeInventoryDetails(e, index)}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        name="actualCount"
                        id={'txtInventoryUpdateCurrentQty' + index}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      id={'txtInventoryInventoryTypeCell' + index}
                    >
                      <Controls.Select
                        name={'inventoryType'}
                        label="Inventory Type"
                        fullWidth
                        value={row.inventoryType}
                        onChange={(e) => onChangeInventoryDetails(e, index)}
                        options={getInventoryTypeCollection().filter(
                          (e) =>
                            e._id != 'purchase' &&
                            e._id != 'salesreturn' &&
                            e._id != 'purchasereturn' &&
                            e._id != 'sales'
                        )}
                        id={'ddInventoryUpdateType' + index}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      id={'txtInventoryUpdateCommentsCell' + index}
                    >
                      <TextField
                        name={'comments'}
                        id={'txtInventoryUpdateComments' + index}
                        label="Comments"
                        autoComplete="off"
                        value={row.comments}
                        onChange={(e) => onChangeInventoryDetails(e, index)}
                        variant="outlined"
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          page={page}
          rowsPerPageOptions={pages}
          rowsPerPage={rowsPerPage}
          count={recordsCount}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        <Grid container>
          <Grid item sm={3}>
            <Button
              variant="contained"
              onClick={() => {
                history.push({
                  pathname: '/Inventory',
                });
              }}
              id="btnCancelInventoryUpdate"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item sm={6}></Grid>
          <Grid item sm={3} align="right">
            <Button
              className="newButton"
              color="primary"
              variant="contained"
              onClick={saveInventory}
              id="btnSaveInventoryUpdate"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}

export default InventoryUpdate;
