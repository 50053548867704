import React from 'react';
import {
  TableCell,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TextField,
  IconButton,
  TableRow,
  Typography,
  Avatar,
  TableBody,
} from '@material-ui/core';
import clsx from 'clsx';
import { Error as ErrorIcon, Clear as ClearIcon } from '@material-ui/icons';
import Grocery from '../../../../Assets/Images/undefined.png';
import PropTypes from 'prop-types';
const MainCartBody = ({
  classes,
  productListInMainBody,
  controlQuantity,
  controlDiscount,
  RemoveItemFromMainBody,
  productTableRef,
  quantityChangeRef,
}) => {
  return (
    <Paper className={classes.paper5} elevation={0} square>
      <TableContainer style={{ maxHeight: '75vh' }}>
        <Table
          className={clsx(classes.table, 'RetailPOSMainTable')}
          aria-label="simple table"
          id="posMainTable"
          stickyHeader
          size="small"
          innerRef={productTableRef}
        >
          <TableHead>
            <TableRow>
              <TableCell align="right" width="30px"></TableCell>
              <TableCell align="left" width="250px">
                <Typography variant="button" display="block" gutterBottom>
                  Item Name
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="button" display="block" gutterBottom>
                  Quantity
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="button" display="block" gutterBottom>
                  Sub Total
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="button" display="block" gutterBottom>
                  Discount (%)
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="button" display="block" gutterBottom>
                  Price
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="button" display="block" gutterBottom>
                  Action
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody id="posMainTbody">
            {productListInMainBody.map((productList, index) => (
              <TableRow
                key={productList._id}
                id={'posMainTrow' + index}
                tabIndex={index}
                style={{ padding: '2px' }}
                hover
                className={classes.posTableBRow}
              >
                <TableCell style={{ padding: '2px' }}>
                  <Avatar
                    alt="Cindy Baker"
                    src={productList.img ? productList.img : Grocery}
                    style={{
                      height: '30px',
                      width: '30px',
                      margin: 'auto',
                    }}
                  />
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ padding: '2px' }}
                  align="left"
                >
                  <span>{productList.name}</span>
                  <br />
                  <span style={{ fontSize: '12px' }}>
                    Rs. {productList.unitSaleFinalPrice}
                  </span>
                </TableCell>
                <TableCell align="center" style={{ padding: '2px' }}>
                  <TextField
                    className={classes.mainTableNumberTextField}
                    id={'txtPOSProductQty' + index}
                    value={productList.Quantity}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      style: {
                        textAlign: 'center',
                        height: '10px',
                      },
                      min: 0,
                    }}
                    ref={quantityChangeRef}
                    variant="outlined"
                    onChange={(e) => {
                      e.preventDefault();
                      controlQuantity(e, index);
                    }}
                    type="number"
                    autoComplete="off"
                  />
                </TableCell>
                <TableCell align="center" style={{ padding: '2px' }}>
                  {(
                    productList.unitSaleFinalPrice *
                    parseFloat(productList.Quantity)
                  ).toFixed(2)}
                </TableCell>
                <TableCell align="center" style={{ padding: '2px' }}>
                  <TextField
                    size="small"
                    id={'txtPOSProductDisc' + index}
                    variant="outlined"
                    style={{
                      width: '75px',
                      marginLeft:
                        productList.minDiscountPercentage !==
                        productList.maxDiscountPercentage
                          ? '21px'
                          : '',
                      textAlign: 'center',
                    }}
                    inputProps={{
                      style: {
                        textAlign: 'center',
                        height: '10px',
                      },
                    }}
                    value={productList.discountPercentage}
                    onChange={(e) => controlDiscount(e, index)}
                    type="number"
                    autoComplete="off"
                  />
                  {productList.minDiscountPercentage !==
                  productList.maxDiscountPercentage ? (
                    <ErrorIcon
                      style={{
                        fontSize: '1rem',
                        color: '#707070',
                        marginLeft: '5px',
                        verticalAlign: '-webkit-baseline-middle',
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </TableCell>
                <TableCell align="center" style={{ padding: '2px' }}>
                  <TextField
                    className={classes.mainTableNumberTextField}
                    id={'txtPOSProductPrice' + index}
                    value={parseFloat(productList.saleFinalPrice).toFixed(2)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      style: {
                        textAlign: 'center',
                        height: '10px',
                      },
                    }}
                    variant="outlined"
                    autoComplete="off"
                  />
                </TableCell>
                <TableCell align="center" style={{ padding: '2px' }}>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    id={'btnPOSDelProduct' + index}
                    className={classes.clearItemIcon}
                    onClick={() => RemoveItemFromMainBody(productList)}
                    size="medium"
                  >
                    <ClearIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
MainCartBody.propTypes = {
  classes: PropTypes.any,
  productListInMainBody: PropTypes.any,
  controlQuantity: PropTypes.any,
  controlDiscount: PropTypes.any,
  RemoveItemFromMainBody: PropTypes.any,
  productTableRef: PropTypes.any,
  quantityChangeRef: PropTypes.any,
};
export default MainCartBody;
