import authHeader from '../../../Services/auth-header';
import { callAPI } from '../../../Services/ApiCaller';
import { BaseAPIURL, API_VERSION } from '../../../Common/constants';
import axios from 'axios';

export async function getAllCustomers(
  page,
  rowsPerPage,
  sortDirection,
  sortColumnName,
  searchString
) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  let rowsFrom = page * rowsPerPage;

  let sortOrderdir = sortDirection === 'asc' ? 1 : -1;
  return callAPI(
    `api/${API_VERSION}/customers`,
    null,
    headerObject,
    'GET',
    `?populate=cityRef,areaRef&$orderBy=${sortColumnName}&$orderDir=${sortOrderdir}&$skip=${rowsFrom}&$top=${rowsPerPage}${searchString}`
  ).then((Responce) => {
    return Responce;
  });
}
export async function getCustomerById(customerId) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  return callAPI(
    `api/${API_VERSION}/customers/${customerId}`,
    null,
    headerObject,
    'GET',
    ''
  ).then((Responce) => {
    return Responce;
  });
}
export async function insertCustomer(customerInsert) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  return callAPI(
    `api/${API_VERSION}/customers`,
    customerInsert,
    headerObject,
    'POST',
    ''
  ).then((Responce) => {
    return Responce;
  });
}

export async function deleteCustomer(_id) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  return callAPI(
    `api/${API_VERSION}/customers/`,
    null,
    headerObject,
    'DELETE',
    _id
  ).then((Responce) => {
    return Responce;
  });
}

export async function updateCustomer(customer) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  return callAPI(
    `api/${API_VERSION}/customers`,
    customer,
    headerObject,
    'PUT',
    `/${customer._id}`
  ).then((Responce) => {
    return Responce;
  });
}
export function FileuploadHandler(files, MethodType) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'multipart/form-data',
    Authorization: header.Authorization,
  };
  const formData = new FormData();
  formData.append('file', files, files.name);
  return axios({
    method: 'post',
    url: `${BaseAPIURL}/api/${API_VERSION}/customers/${MethodType}`,
    data: formData,
    headers: headerObject,
  }).then((response) => {
    return response;
  });
}
