import React, { useEffect, useState } from 'react';
import Controls from '../../Utilities/Controls/Controls';
import { useForm, Form } from '../../Utilities/ReuseableCommponents/useForm';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import PageTitle from '../../Layout/PageTitle';
import { useLocation, useHistory } from 'react-router-dom';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import { Grid, makeStyles, Paper, IconButton } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as RecipeService from './RecipeService.js';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: '75%',
  },
  newButton: {
    position: 'absolute',
    right: '0',
  },
  PaperMargin: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  asterisk: {
    color: 'red',
  },
}));

const initialFValues = {
  _id: 0,
  //Name Of The Recipe
  name: '',
  //Item Associated With Recipe
  itemRef: '',
  //Item Name (For Validating the Search Textbox Only)
  valueForProduct: '',
  //Recipe Ingrediants List
  recipeItems: [],

  __v: 0,
};
let Responce = {
  data: null,
  error: null,
  loading: false,
};

let apiResponce = {
  data: null,
  error: null,
  loading: false,
  statusCode: 0,
};

let apiResponseDto = {
  data: [],
  error: null,
  messege: '',
  loading: false,
  statusCode: 0,
};

function CategoryDetails() {
  const history = useHistory();
  const classes = useStyles();

  const [recipeIngrediantsOptions, setRecipeIngrediantsOptions] = useState([]);
  const [itemsOptions, setItemsOptions] = useState([]);
  const [valueForRecipe, setValueForRecipe] = React.useState([]);
  const [inputValueForRecipe, setInputValueForRecipe] = React.useState('');
  const [inputValueForProduct, setInputValueForProduct] = React.useState('');
  //Searched Items against seach string whith isRecipeItem Flag
  const [searchedRecipeItems, setSearchedRecipeItems] = React.useState([]);

  //Searched Items against seach string - For which we are going to create a recipe
  const [searchedItems, setSearchedItems] = React.useState([]);

  const location = useLocation();

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const { values, setValues, handleInputChange, resetForm } = useForm(
    initialFValues
  );

  const addOrEdit = async (recipe, resetForm) => {
    if (recipe._id === 0 && recipe.name && recipe.recipeItems.length > 0) {
      try {
        Responce.data = null;
        Responce.error = null;
        Responce = await RecipeService.insertRecipe(recipe).then((Responce) => {
          return Responce;
        });

        if (Responce.data) {
          resetForm();

          setNotify({
            isOpen: true,
            message: 'Submitted Successfully',
            type: 'success',
          });
        }

        if (Responce.error) {
          setNotify({
            isOpen: true,
            message: Responce.messege
              ? Responce.messege
              : 'Unable To Process Request',
            type: 'error',
          });
        }
      } catch (e) {
        setNotify({
          isOpen: true,
          message: 'Unable To Process Request',
          type: 'error',
        });
      }
    }
    if (recipe._id !== 0 && recipe.name) {
      try {
        apiResponce.data = null;
        apiResponce.error = null;
        apiResponce = await RecipeService.updateRecipe(recipe).then(
          (Responce) => {
            return Responce;
          }
        );
        if (apiResponce.data) {
          resetForm();
          setNotify({
            isOpen: true,
            message: 'Recipe Detail Updated Successfully',
            type: 'success',
          });
        }
        if (apiResponce.error) {
          setNotify({
            isOpen: true,
            message: 'Unable To Process Request',
            type: 'error',
          });
        }
      } catch (e) {
        setNotify({
          isOpen: true,
          message: 'Unable To Process Request',
          type: 'error',
        });
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    //Manual Validation For the Array In Values

    if (values.recipeItems.length > 0) {
      // Validate all Objects and show error messeges
      values.recipeItems.map((eachRecipeItem, index) => {
        ArrayValidator_recipeItemsList_ErrorMesage(index);
      });

      //Filter All Objects who has error messeges
      let objectsWithErrors = values.recipeItems.filter((eachObject) => {
        return Object.keys(eachObject.errors).some(
          (k) => eachObject.errors[k].toLowerCase().length > 0
        );
      });

      if (objectsWithErrors.length == 0) {
        //check if the Referencing Product(For Which We are generating Recipe) is present in Recipe Ingredient List
        let sameProduct = values.recipeItems.filter((eachObject) => {
          return eachObject.itemRef == values.itemRef;
        });

        if (sameProduct.length) {
          setNotify({
            isOpen: true,
            message:
              'Validation Error: Product And Recipe Item Can Not Be Same, Please Try Adding Other Different Item As A Recipe Item',
            type: 'error',
          });
        } else {
          addOrEdit(values, resetForm);
        }
      }
    } else {
      setNotify({
        isOpen: true,
        message: 'Must Add Recipe Items',
        type: 'error',
      });
    }
  };

  useEffect(() => {
    if (location.state.recordForEdit != null)
      setValues({ ...location.state.recordForEdit });
  }, [location.state.recordForEdit]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeRecipeDetails = (index) => (e) => {
    let oldValuesForModification = { ...values };

    if (e.target.name == 'recipeUnitChoosen') {
      //Get the detail of recipe Option Object
      //As Object as two properties (name and orignamlName)
      //We are showing name at frontend but will pass orignamlName at Backend
      let recipeOptionDtails = oldValuesForModification.recipeItems[
        index
      ].recipeUnits.options.filter((eachOption) => {
        return eachOption.name == e.target.value;
      });
      oldValuesForModification.recipeItems[index][e.target.name] =
        recipeOptionDtails[0].orignalName; // replace e.target.value with whatever you want to change it to
      oldValuesForModification.recipeItems[index].recipeUnitChoosen =
        recipeOptionDtails[0].recipeUnitType +
        recipeOptionDtails[0].orignalName;

      //set recepieConversion = oigal Conversion If Purhase unit is selected otherwse "1"
      oldValuesForModification.recipeItems[
        index
      ].recepieConversion = e.target.value.includes('Purchase')
        ? oldValuesForModification.recipeItems[index].recipeUnits
            .conversionUnitOrignal
        : '1';
    } else {
      oldValuesForModification.recipeItems[index][e.target.name] =
        e.target.value; // replace e.target.value with whatever you want to change it to
    }

    setValues(oldValuesForModification);

    ArrayValidator_recipeItemsList_ErrorMesage(index);
  };

  const onDeleteRecipeItem = (index) => () => {
    let oldValues = { ...values };
    let clonedRecipeList = [...oldValues.recipeItems];
    clonedRecipeList.splice(clonedRecipeList[index], 1);
    oldValues.recipeItems = clonedRecipeList;

    setValues(oldValues);
  };

  // Will Update the State Array to show the Proper Error Messege
  const ArrayValidator_recipeItemsList_ErrorMesage = (index) => {
    const temp = {};
    var ErrMsg_NumericValidInput = 'Must enter valid positive numeric input.';
    var ErrMsg_NumericMustBeNonZero = 'Must be greater than zero';

    let oldValuesObj = { ...values };

    let objectInsideArray = { ...oldValuesObj.recipeItems[index] };

    for (const property in objectInsideArray) {
      if (property == 'quantity') {
        temp.quantity = !isNaN(objectInsideArray[property])
          ? ''
          : ErrMsg_NumericValidInput;
        temp.quantity =
          objectInsideArray[property] > 0 ? '' : ErrMsg_NumericMustBeNonZero;
      }
    }
    oldValuesObj.recipeItems[index].errors = { ...temp };
    setValues({ ...oldValuesObj });
  };

  //Main Methode for getting results from an API
  function onChange(e) {
    if (e.target.value) {
      //Get Main Product From API Against Search String
      if (e.target.name == 'valueForProduct') {
        //IIFE
        (async () => {
          apiResponseDto = await RecipeService.getProductsForRecipe(
            0,
            100,
            1,
            'name',
            e.target.value
          ).then((Response) => {
            return Response;
          });
          return apiResponseDto;
        })().then((apiResponseDto) => {
          if (apiResponseDto.data) {
            if (apiResponseDto.data.count) {
              //Save All Products(fetched Against search query)
              // will get the selected product info and send in the final recipe Payload
              setSearchedItems(apiResponseDto.data.data);
              apiResponce = apiResponseDto.data.data.map((apiData) => {
                return { id: apiData._id, name: apiData.name };
              });

              setItemsOptions(apiResponce);
            } else {
              setSearchedItems([]);
            }
          }
        });
      }

      //Get Recipe Items From API Against Search String
      if (e.target.name == 'recipeItemsSearchTextBox') {
        //IIFE
        (async () => {
          apiResponseDto = await RecipeService.getAllRecipeItem(
            0,
            100,
            1,
            'name',
            e.target.value
          ).then((Response) => {
            return Response;
          });
          return apiResponseDto;
        })().then((apiResponseDto) => {
          if (apiResponseDto.data) {
            if (apiResponseDto.data.data && apiResponseDto.data.count) {
              //On every search store the result of searched Collection
              // When we will select any particular item, the details of an items could be fetched by the collection
              // After selecting an item - the details of an item will be added against item in  recipe Item list
              setSearchedRecipeItems(apiResponseDto.data.data);

              apiResponce = apiResponseDto.data.data.map((apiData) => {
                return { id: apiData._id, name: apiData.name };
              });
              setRecipeIngrediantsOptions(apiResponce);
            } else {
              setRecipeIngrediantsOptions([]);
            }
          }
        });
      }
    } else {
      setRecipeIngrediantsOptions([]);
      setItemsOptions([]);
    }
  }

  const setProductDetails = (productName) => {
    // Get id and name from options as per searched Recipe name
    let selectedItem = searchedItems.filter((eachItem) => {
      return eachItem.name === productName;
    });

    let clonedRecipe = values; //Old State Object
    //Add Or Update The Property In State Object
    clonedRecipe.itemRef = selectedItem[0]._id;
    setValues(clonedRecipe);

    //After Adding the Recipe Item Clear the Search Text Box
    let oldValues = { ...values };
    oldValues.valueForProduct = productName;
    setValues(oldValues);
  };

  return (
    <>
      <PageTitle
        title={location.state.pageHeaderTitile}
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="recipeDetailsHeading"
      />
      <Paper elevation={0} square className={classes.PaperMargin}>
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={10} sm={4}>
              <Controls.Input
                name="name"
                label="Recipe Name"
                value={values.name}
                onChange={handleInputChange}
                id="txtRecipeName"
                required
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} sm={4}>
              <Autocomplete
                style={{ width: '96.5%' }}
                size="small"
                value={values.valueForProduct}
                onChange={(event, newValue) => {
                  let oldValues = { ...values };
                  oldValues.valueForProduct = newValue;
                  setValues(oldValues);
                  //Only Work if Search Recipe textbox has value
                  if (newValue) {
                    setProductDetails(newValue);
                  }
                }}
                inputValue={inputValueForProduct}
                onInputChange={(event, newInputValue) => {
                  setInputValueForProduct(newInputValue);
                }}
                id="txtRecipeProducts"
                options={itemsOptions.map((option) => option.name)}
                fullWidth
                renderInput={(params) => (
                  <Controls.Input
                    {...params}
                    variant="outlined"
                    name="valueForProduct"
                    label="Search Product"
                    fullWidth
                    onChange={onChange}
                    id="txtProdConversionUnit"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Autocomplete
                style={{ width: '96.5%' }}
                size="small"
                value={valueForRecipe}
                onChange={(event, newValue) => {
                  setValueForRecipe(newValue);

                  //Only Work if Search Recipe textbox has value
                  if (newValue) {
                    // Get id and name from options as per searched Recipe name
                    let selectedValue = recipeIngrediantsOptions.filter(
                      (eachOption) => {
                        return eachOption.name === newValue;
                      }
                    );

                    //check if the serched Recipe Item is already present in list or not
                    let recipePresent = values.recipeItems.filter(
                      (eachPreviousRecipeItem) =>
                        eachPreviousRecipeItem.name === newValue
                    );

                    //it's ok this recipe isn't present already
                    if (recipePresent.length == 0) {
                      // Get Complete Detail Of selected Recipe Item from searchedRecipeItems List to fetch following details
                      // Get the purchaseUnit , saleUnit and conversion unit
                      let itemDetails = searchedRecipeItems.filter(
                        (eachItem) => {
                          return eachItem.name == selectedValue[0].name;
                        }
                      );

                      let purchaseUnitType = 'Purchase Unit : ';
                      let saleUnitType = 'Sale Unit : ';

                      let recipeItem = {
                        itemRef: itemDetails[0]._id,
                        name: itemDetails[0].name,
                        quantity: 1, //Default quantity
                        //recipeUnitChoosen: Default Choosen Unit - purchase Unit
                        // Modified Value('purchaseUnitType'+unitOfPurchase) = Purchase Unit : kg
                        // Modified Because of Better User Experience
                        recipeUnitChoosen:
                          purchaseUnitType + itemDetails[0].unitOfPurchase,
                        //recepieConversion: Default Conversion (1), due to Purchase Unit as a default Conversion
                        recepieConversion: '1',
                        //Item Orignal Info At the time of Recipe Creation
                        recipeUnits: {
                          unitOfPurchase: itemDetails[0].unitOfPurchase,
                          unitOfSale: itemDetails[0].unitOfSale,
                          conversionUnitOrignal: itemDetails[0].conversionUnit,
                          //**********OPTION.NAME MUST BE SAME AS PROPERTY( recipeUnitChoosen ) ABOVE*/
                          options: [
                            {
                              _id:
                                purchaseUnitType +
                                itemDetails[0].unitOfPurchase,
                              name:
                                purchaseUnitType +
                                itemDetails[0].unitOfPurchase,
                              orignalName: itemDetails[0].unitOfPurchase,
                              recipeUnitType: purchaseUnitType,
                            },
                            {
                              _id: saleUnitType + itemDetails[0].unitOfSale,
                              name: saleUnitType + itemDetails[0].unitOfSale,
                              orignalName: itemDetails[0].unitOfSale,
                              recipeUnitType: saleUnitType,
                            },
                          ],
                        },
                      };

                      let oldValuesForModification = values;
                      let newrecipeItemsList = [
                        ...oldValuesForModification.recipeItems,
                        recipeItem,
                      ];
                      oldValuesForModification.recipeItems = newrecipeItemsList;
                      setValues(oldValuesForModification);

                      //After Adding the Recipe Item Clear the Search Text Box
                      setValueForRecipe('');
                    }
                  }

                  setValueForRecipe('');
                }}
                inputValue={inputValueForRecipe}
                onInputChange={(event, newInputValue) => {
                  setInputValueForRecipe(newInputValue);
                }}
                id="txtRecipeIngrediants"
                options={recipeIngrediantsOptions.map((option) => option.name)}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Search Recipe Ingrediants"
                    variant="outlined"
                    onChange={onChange}
                    name="recipeItemsSearchTextBox"
                  />
                )}
              />
            </Grid>

            <Grid item sm={12}>
              {values.recipeItems.map((attribute, index) => {
                return (
                  <Grid container key={index}>
                    <Grid container xs={12} sm={4}>
                      <TextField
                        variant="outlined"
                        label="Recipe Item Name"
                        placeholder="Name"
                        id="txtRecipeItemName"
                        name="name"
                        disabled
                        value={attribute.name}
                        onChange={onChangeRecipeDetails(index)}
                        fullWidth
                      />
                    </Grid>
                    <Grid container xs={12} sm={2}>
                      <Controls.Input
                        variant="outlined"
                        name="quantity"
                        label="Recipe Item Quantity"
                        value={attribute.quantity}
                        onChange={onChangeRecipeDetails(index)}
                        error={
                          attribute.errors
                            ? attribute.errors.quantity
                              ? attribute.errors.quantity
                              : ''
                            : ''
                        }
                        type="number"
                        id="txtRecipeItemQty"
                      />
                    </Grid>
                    <Grid container xs={12} sm={3}>
                      <Controls.Select
                        name="recipeUnitChoosen"
                        label="RecipeUnit"
                        value={attribute.recipeUnitChoosen}
                        onChange={onChangeRecipeDetails(index)}
                        options={attribute.recipeUnits.options}
                        isNoneAllowed={false}
                        id="ddRecipeUnit"
                        fullWidth
                      />
                    </Grid>

                    <Grid container xs={4} sm={3}>
                      <IconButton
                        aria-label="delete"
                        id="btnRecipeItemDel"
                        color="secondary"
                        onClick={onDeleteRecipeItem(index)}
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>

            <Grid container className={classes.ButtonsCotainerMargin}>
              <br></br>
              <Grid item xs={3} sm={4} align="left">
                <Controls.Button
                  text="Cancel"
                  color="default"
                  onClick={history.goBack}
                  startIcon={<CloseIcon />}
                  id="btnCancelRecipe"
                />
              </Grid>
              <Grid item xs={6} sm={6}></Grid>
              <Grid item xs={1} sm={2} align="right">
                <div>
                  <Controls.Button
                    type="submit"
                    startIcon={<SaveIcon />}
                    text="Save"
                    id="btnSaveRecipe"
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}

export { CategoryDetails as default };
