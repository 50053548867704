import React, { useState, useEffect, useCallback } from 'react';
import CircularLoader from '../../../Utilities/ReuseableCommponents/CircularLoader';
import {
  makeStyles,
  Grid,
  Typography,
  FormControlLabel,
  TextField,
  Divider,
  Box,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Notification from '../../../Utilities/ReuseableCommponents/Notification';
import * as SetupService from '../SetupService';
import Controls from '../../../Utilities/Controls/Controls';
import Switch from '@material-ui/core/Switch';
import Dropzone from '../../../Utilities/ReuseableCommponents/Dropzone';
import ImageCropper from '../../../Utilities/ReuseableCommponents/ImageCropper';
import ImageCropperDialog from '../../../Utilities/ReuseableCommponents/ImageCropperDialog';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';

import {
  PhoneNumInit,
  CommissionInitialData,
  MiscellaneousChargesInitData,
  GetCompanyType,
  OrgNameInit,
  ShortcutKeysInitData,
} from '../../../../Common/constants';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  newButton: {
    position: 'absolute',
    right: '0px',
  },
  PaperMargin: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(0),
  },
  img: {
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: theme.shape.borderRadius,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  floatButtonWrapper: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 1000,
  },
  uploadText: {
    transition: theme.transitions.create(['color', 'box-shadow', 'border'], {
      duration: theme.transitions.duration.short,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  imgWrapper: { position: 'relative', display: 'inline-flex' },
}));
let apiResponseDto = {
  data: [],
  error: null,
  loading: false,
  statusCode: 0,
};

export default function GeneralTab() {
  const classes = useStyles();
  const [valueCreditLimit, setValueCreditLimit] = useState([]);
  const [valueCommission, setValueCommission] = useState([]);
  const [miscellaneousCharges, setMiscellaneousCharges] = useState([]);
  const [shortcutKeys, setShortcutKeys] = useState([]);
  const [showData, setShowData] = useState(false);
  const [setUpDefaultData, setsetUpDefaultData] = useState(false);
  const [img, setImg] = useState('');
  const [imgId, setImgId] = useState('');
  const [files, setFiles] = useState([]);
  const [valueCompanyName, setValueCompanyName] = useState();
  const [valuePhoneNumber, setValuePhoneNumber] = useState();
  const [idPhoneNumber, setIdPhoneNumber] = useState();
  const [idCompanyName, setIdCompanyName] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const handleCommissionChange = (event, index) => {
    let newdata = { ...valueCommission };
    newdata[0].data[index].value = event.target.checked;
    setValueCommission([newdata[0]]);
  };
  const [cropperFile, setCropperFile] = useState(null);

  useEffect(() => {
    setShowData(false);
    setIsLoaded(true);
    let data = (async () => {
      apiResponseDto = await SetupService.getAllSetups().then((Response) => {
        setIsLoaded(false);
        return Response;
      });
      return apiResponseDto;
    })();

    data.then((Response) => {
      if (Response.data) {
        if (Response.data.count == 0) {
          setsetUpDefaultData(true);
          setNotify({
            isOpen: true,
            message: 'No Data Found',
            type: 'error',
          });
        } else {
          let organizationInfoData = Response.data.data.filter(
            (e) => e.name === 'Organization Info'
          );
          let organizationName =
            organizationInfoData && organizationInfoData.length
              ? organizationInfoData[0].data.displayName
              : '';
          if (organizationName) {
            let organizationId = organizationInfoData[0]._id;
            setIdCompanyName(organizationId);
            setValueCompanyName(organizationName);
          } else {
            SetupService.insertSetup(OrgNameInit).then((res) => {
              return res;
            });
          }
          let companyContactNumber = Response.data.data
            .filter((e) => e.name === 'CompanyContactNumber')
            .map((e) => e);
          if (companyContactNumber.length) {
            setIdPhoneNumber(companyContactNumber[0]._id);
            setValuePhoneNumber(companyContactNumber[0].data[0].name);
          } else {
            SetupService.insertSetup(PhoneNumInit).then((res) => {
              return res;
            });
          }

          let Commissiondata = Response.data.data
            .filter((e) => e.name === 'Commission')
            .map((e) => e);
          setValueCommission(Commissiondata);
          localStorage.setItem(
            '_Commission',
            Commissiondata[0]?.data[0]?.value
          );

          let CompanyLogoData = Response.data.data
            .filter((e) => e.name === 'Company Logo')
            .map((e) => e);
          if (CompanyLogoData && CompanyLogoData.length > 0) {
            setImgId(CompanyLogoData[0]._id);
            setFiles([CompanyLogoData[0].data]);
            setImg(CompanyLogoData[0].data);
          }

          let CreditLimitdata = Response.data.data
            .filter((e) => e.name === 'Credit Limit')
            .map((e) => e);
          setValueCreditLimit(CreditLimitdata);
          setShowData(true);

          let miscCharges = Response.data.data
            .filter((e) => e.name === 'Miscellaneous Charges')
            .map((e) => e);
          setMiscellaneousCharges(miscCharges);

          let shortcuts = Response.data.data
            .filter((e) => e.name === 'Shortcut Keys')
            .map((e) => e);
          setShortcutKeys(shortcuts);
        }
      } else console.error('Error While Fetching Data: ', Response.error);
    });
  }, [setUpDefaultData]);

  function changeTFCreditLimit(value, index) {
    let newdata = { ...valueCreditLimit };
    newdata[0].data[index].value = value;
    setValueCreditLimit([newdata[0]]);
  }

  function changeMiscCharges(value) {
    let copyMiscCharges = { ...miscellaneousCharges };
    copyMiscCharges[0].data.charges = value;
    setMiscellaneousCharges([copyMiscCharges[0]]);
  }

  function handleStatusMiscCharges(e) {
    let copyMiscStatus = { ...miscellaneousCharges };
    copyMiscStatus[0].data.status = e.target.checked;
    setMiscellaneousCharges([copyMiscStatus[0]]);
  }

  function handleShortcutKeys(e) {
    let copyShortcuts = { ...shortcutKeys };
    copyShortcuts[0].data.status = e.target.checked;
    setShortcutKeys([copyShortcuts[0]]);
  }

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  function payloadReady(payload) {
    return {
      name: payload.name,
      data: payload.data,
    };
  }

  async function UpdateSetup(setup) {
    return SetupService.updateSetup(payloadReady(setup[0]), setup[0]._id).then(
      (res) => {
        if (setup[0].name === 'Organization Info') {
          localStorage.setItem('CompanyName', setup[0].data.displayName);
        } else if (setup[0].name === 'CompanyContactNumber') {
          localStorage.setItem('CompanyContactNumber', setup[0].data[0].name);
        } else if (setup[0].name === 'Commission') {
          localStorage.setItem('_Commission', setup[0].data[0].value);
        }
        return res;
      }
    );
  }
  async function Initialize() {
    return SetupService.initializeData().then((res) => {
      return res;
    });
  }
  async function CommissionActivation() {
    var Response = await SetupService.insertSetup(CommissionInitialData).then(
      (res) => {
        return res;
      }
    );
    setValueCommission([Response.data.data]);
    return Response;
  }
  function changeOrganisationName(value) {
    let newdata = value;
    setValueCompanyName(newdata);
  }
  async function miscellaneousChargesActivate() {
    var Response = await SetupService.insertSetup(
      MiscellaneousChargesInitData
    ).then((res) => {
      return res;
    });
    if (Response.data) setMiscellaneousCharges([Response.data.data]);
    return Response;
  }

  async function shortcutKeysActivate() {
    var Response = await SetupService.insertSetup(ShortcutKeysInitData).then(
      (res) => {
        return res;
      }
    );
    if (Response.data) setShortcutKeys([Response.data.data]);
    return Response;
  }

  function handlePushInitialData() {
    Initialize().then((Response) => {
      setsetUpDefaultData(false);
      return Response;
    });
  }
  function handleActivationCommision() {
    CommissionActivation().then((Response) => {
      return Response;
    });
  }

  function handleSave() {
    //Organization
    let OrgNameObj = [
      {
        data: {
          displayName: valueCompanyName,
        },
        _id: idCompanyName,
        name: 'Organization Info',
      },
    ];
    let PhoneNumObj = [
      {
        data: [
          {
            name: valuePhoneNumber,
          },
        ],
        _id: idPhoneNumber,
        name: 'CompanyContactNumber',
      },
    ];
    UpdateSetup(OrgNameObj).then((Response) => {
      return Response;
    });

    UpdateSetup(PhoneNumObj).then((Response) => {
      return Response;
    });

    //Commission
    if (valueCommission.length > 0)
      UpdateSetup(valueCommission).then((Response) => {
        return Response;
      });
    //Company Logo
    if (img !== '') {
      if (imgId === '') {
        SetupService.setCompanyLogo(img).then((Response) => {
          return Response;
        });
      } else {
        var payLoad = {
          name: 'Company Logo',
          data: img,
        };
        SetupService.updateSetup(payLoad, imgId).then((Response) => {
          return Response;
        });
      }
    }

    //Miscellaneous Charges
    if (miscellaneousCharges.length > 0) {
      UpdateSetup(miscellaneousCharges).then((Response) => {
        if (Response)
          localStorage.setItem(
            '_Misc',
            JSON.stringify(Response.data.data.data)
          );
        return Response;
      });
    }

    //Shortcut Keys
    if (shortcutKeys.length > 0) {
      UpdateSetup(shortcutKeys).then((Response) => {
        if (Response)
          localStorage.setItem(
            '_ShortKeys',
            JSON.stringify(Response.data.data.data)
          );
        return Response;
      });
    }
    setNotify({
      isOpen: true,
      message: 'Setups updated successfully.',
      type: 'success',
    });
  }

  const acceptDrop = useCallback(
    (file) => {
      setFiles([file]);
    },
    [setFiles]
  );
  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles.length + rejectedFiles.length > 1) {
        setNotify({
          isOpen: true,
          message: 'You cannot upload more than one file at once',
          type: 'error',
        });
      } else if (acceptedFiles.length === 0) {
        setNotify({
          isOpen: true,
          message: 'The file you wanted to upload isn`t an image',
          type: 'error',
        });
      } else if (acceptedFiles.length === 1) {
        const file = acceptedFiles[0];
        file.preview = URL.createObjectURL(file);
        file.key = new Date().getTime();
        setCropperFile(file);
      }
    },
    [setCropperFile]
  );
  const deleteItem = useCallback(() => {
    setCropperFile(null);
    setFiles([]);
  }, [setCropperFile, setFiles]);
  const onCropperClose = useCallback(() => {
    setCropperFile(null);
  }, [setCropperFile]);

  const onCrop = useCallback(
    (dataUrl) => {
      const file = { ...cropperFile };
      file.preview = dataUrl;
      setImg(dataUrl);
      setFiles([dataUrl]);
      acceptDrop(file);
      setCropperFile(null);
    },
    [acceptDrop, cropperFile, setCropperFile]
  );
  const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: theme.palette.primary.main,
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

  const miscChargesHTML = (
    <>
      {miscellaneousCharges.length ? (
        <>
          <Grid item xs={2}>
            <Typography variant="subtitle2" style={{ marginTop: 10 }}>
              {miscellaneousCharges[0].name}
            </Typography>
          </Grid>
          <Grid item xs={9} style={{ margin: 10 }}>
            <Switch
              checked={miscellaneousCharges[0].data.status}
              onChange={handleStatusMiscCharges}
              color="primary"
              name="checkMiscCharges"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <TextField
              id="misc"
              key="misc"
              type="number"
              label="Max Amount Allowed"
              value={miscellaneousCharges[0].data.charges}
              variant="outlined"
              style={{
                marginLeft: '35px',
              }}
              onChange={(e) => changeMiscCharges(e.target.value)}
            />
          </Grid>
        </>
      ) : (
        <Controls.Button
          text="Activate Misc. Charges"
          variant="contained"
          color="primary"
          id="btnactivateMiscCharges"
          onClick={miscellaneousChargesActivate}
        />
      )}
    </>
  );

  const shortcutsHTML = (
    <>
      {shortcutKeys.length ? (
        <>
          <Grid item xs={2}>
            <Typography variant="subtitle2" style={{ marginTop: 10 }}>
              {shortcutKeys[0].name}
            </Typography>
          </Grid>
          <Grid item xs={9} style={{ margin: 10 }}>
            <Switch
              checked={shortcutKeys[0].data.status}
              onChange={handleShortcutKeys}
              color="primary"
              name="checkMiscCharges"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </Grid>
        </>
      ) : (
        <Controls.Button
          text="Activate Shortcuts"
          variant="contained"
          color="primary"
          id="btnactivateShortcuts"
          onClick={shortcutKeysActivate}
        />
      )}
    </>
  );

  const companyImgHTML =
    files.length > 0 ? (
      <div className={classes.imgWrapper}>
        <img
          alt="uploaded item"
          src={img}
          className={classes.img}
          style={{ height: 200, width: 200 }}
        />
        <div className={classes.floatButtonWrapper}>
          <IconButton onClick={deleteItem}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    ) : (
      <Dropzone
        accept="image/png, image/jpeg"
        onDrop={onDrop}
        style={{ height: 200, width: 200 }}
      >
        <span className={classes.uploadText}>
          Upload file <br /> here
        </span>
      </Dropzone>
    );

  return (
    <>
      <CircularLoader isload={isLoaded} />
      {showData ? (
        <React.Fragment key={'setting'}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Typography variant="subtitle2" gutterBottom>
                {'Company Logo'}
              </Typography>
            </Grid>
            <Grid item xs={10}>
              {ImageCropper && (
                <ImageCropperDialog
                  open={cropperFile ? true : false}
                  ImageCropper={ImageCropper}
                  src={cropperFile ? cropperFile.preview : ''}
                  onCrop={onCrop}
                  onClose={onCropperClose}
                  aspectRatio={1}
                />
              )}
              <Box mb={3} style={{ margin: 'auto' }}>
                {companyImgHTML}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider variant="middle" />
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle2" gutterBottom>
                {'Company Name'}
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                variant="outlined"
                label="Organisation Name"
                value={valueCompanyName}
                disabled={false}
                style={{
                  margin: '5px',
                }}
                onChange={(e) => changeOrganisationName(e.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle2" gutterBottom>
                {'Phone Number'}
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                variant="outlined"
                label="Phone Number"
                value={valuePhoneNumber}
                disabled={false}
                style={{
                  margin: '5px',
                }}
                onChange={(e) => setValuePhoneNumber(e.target.value)}
              />
            </Grid>

            {valueCreditLimit.map((item, index) => (
              <React.Fragment key={'CreditLimitMain' + index}>
                <Grid item xs={2}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    key={'creditLimit' + index}
                  >
                    {item.name}
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  {item.data.map((subItem, index) => (
                    <React.Fragment key={'CreditLimitSub' + index}>
                      <TextField
                        id={'tf-cl' + index}
                        key={'tf-cl' + index}
                        label={subItem.name}
                        value={subItem.value}
                        onChange={(e) =>
                          changeTFCreditLimit(e.target.value, index)
                        }
                        variant="outlined"
                        style={{
                          margin: '10px',
                          marginLeft: '0px',
                        }}
                      />
                      <br />
                    </React.Fragment>
                  ))}
                </Grid>
              </React.Fragment>
            ))}
            <Grid item xs={12}>
              <Divider variant="middle" />
            </Grid>
            {localStorage.getItem('role') === 'superadmin' &&
              (valueCommission.length != 0 ? (
                valueCommission.map((item) => (
                  <React.Fragment key={'Commission'}>
                    <Grid item xs={2}>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        key={'Commissionkey'}
                      >
                        {item.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      {item.data.map((subItem, index) => (
                        <React.Fragment key={'CommissionSub' + index}>
                          <FormControlLabel
                            control={
                              <IOSSwitch
                                checked={subItem.value}
                                onChange={(e) =>
                                  handleCommissionChange(e, index)
                                }
                                name={subItem.name + index}
                              />
                            }
                            id={'sw-cl' + index}
                            key={'sw-cl' + index}
                            label={subItem.name}
                            labelPlacement="top"
                          />
                          <br />
                        </React.Fragment>
                      ))}
                    </Grid>
                  </React.Fragment>
                ))
              ) : (
                <React.Fragment key={'initialCommission'}>
                  <Grid item xs={2}>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      key={'initialCommissionTypo'}
                    >
                      {'Commission'}
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Controls.Button
                      text="Activate Commission"
                      variant="contained"
                      color="primary"
                      id="btnactivatecommission"
                      onClick={handleActivationCommision}
                    />
                  </Grid>
                </React.Fragment>
              ))}
            <Grid item xs={12}>
              <Divider variant="middle" />
            </Grid>
            {GetCompanyType() === 'retail' && (
              <>
                {miscChargesHTML}
                <Grid item xs={12}>
                  <Divider variant="middle" />
                </Grid>
              </>
            )}
            {GetCompanyType() === 'retail' && (
              <>
                {shortcutsHTML}
                <Grid item xs={12}>
                  <Divider variant="middle" />
                </Grid>
              </>
            )}
          </Grid>

          <Grid item xs={12}>
            <Controls.Button
              text="Save Changes"
              variant="contained"
              color="primary"
              id="btnProdSearch"
              startIcon={<SaveIcon />}
              onClick={handleSave}
            />
          </Grid>
        </React.Fragment>
      ) : (
        <>
          <Grid
            container
            spacing={2}
            direction="column"
            alignItems="center"
            justify="center"
          >
            <Grid item xs={12}>
              {setUpDefaultData ? (
                <Controls.Button
                  text="Setup Default Data"
                  variant="contained"
                  color="primary"
                  id="btnsetupdefaultdata"
                  onClick={handlePushInitialData}
                />
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </>
      )}

      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
