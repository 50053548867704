import PrintIcon from '@material-ui/icons/Print';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Print from './print';
import PrintKot from './printKot';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import { Tabs, Tab } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ClearIcon from '@material-ui/icons/Clear';
import ReplayIcon from '@material-ui/icons/Replay';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Divider from '@material-ui/core/Divider';
import Modal from '@material-ui/core/Modal';
import useStyles from '../../../Style/AppStylesFood';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import * as APIService from './SalesAPIService';
import { Online } from 'react-detect-offline';
import BarcodeReader from 'react-barcode-reader';
import {
  DataDump,
  getFormattedDateTime as ISOdateFormatter,
  getDateTime,
  getDifferenceInMinutes,
  playAudio,
} from '../../../Common/constants';
import './POS.css';
import CreditCard from '@material-ui/icons/CreditCard';
import Money from '@material-ui/icons/Money';
import Controls from '../../Utilities/Controls/Controls';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import Notification from '../../../Components/Utilities/ReuseableCommponents/Notification';
import { CTX } from './Store';
import PrintPrePaymentBill from './printPrePaymentBill';
import generateRetailDynamicCode from '../../../Common/NayaPay_QRCode';
import QRCode from 'qrcode.react';
import * as CustomerService from '../Customer/CustomerService';
import * as SetupService from '../Setups/SetupService';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import SendIcon from '@material-ui/icons/Send';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CancelIcon from '@material-ui/icons/Cancel';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from '@material-ui/core/Collapse';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Footer from './POSComponents/Footer';
import Header from './POSComponents/Header';
import CheckoutButton from './POSComponents/CheckoutButton';
import CartTotal from './POSComponents/CartTotal';
import CartHeaderButtons from './POSComponents/CartHeaderButtons';
import EndShiftPrint from './PrintActions/EndShiftPrint';
import SaveOrderButton from './POSComponents/SaveOrderButton';
import KotButton from './POSComponents/KotButton';
import CustomerDetails from '../Customer/CustomerDetails';
import Switch from '@material-ui/core/Switch';
import { Edit } from '@material-ui/icons';

function getModalStyle() {
  const top = 50;
  const left = 50;
  const height = 80;
  const width = 70;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    height: `${height}%`,
    width: `${width}%`,
  };
}

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function POSFood() {
  const isOdms = getIsOdms();
  const { allProducts } = React.useContext(CTX);
  const [modalStyle] = React.useState(getModalStyle);
  const [CompanyName] = React.useState(localStorage.getItem('CompanyName'));
  const classes = useStyles();
  const classRowStyle = useRowStyles();
  const categories = allProducts.data.categories;
  const [active, setActive] = useState(categories[0].name);
  const [activeCategory, changeActiveCategory] = useState(categories[0]._id);
  const [kotList, setKotList] = useState([]);
  const [productListInMainBody, setproductListInMainBody] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [netTotal, setNetTotal] = useState(0);
  const [amountWithoutTax, setAmountWithoutTax] = useState(0);
  const [tax, setTax] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [open4, setOpen4] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);
  const [open6, setOpen6] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [queuedOrders, setQueuedOrders] = React.useState(false);
  const [openingamount, setOpeningamount] = useState('');
  const [expectedAmount, setExpectedAmount] = useState('');
  const [shiftButton, setShiftButton] = React.useState(getShiftButtonData());
  const [closingAmount, setClosingAmount] = useState('');
  const [internalSaleAmount, setInternalSaleAmount] = useState('0.00');
  const [finalDiscount, setFinalDiscount] = useState(0);
  const [comments, setComments] = useState('');
  const [signout, setsignout] = React.useState(false);
  const [syncTime, setSyncTime] = useState(
    'Last Sync At: ' + localStorage.getItem('_SyncDateTime')
  );
  const [endShiftSaleDetail, setEndShiftSaleDetail] = React.useState('block');
  const [endShiftSaleDetailTab, setEndShiftSaleDetailTab] = React.useState(
    'none'
  );
  const [discountBitFields, setDiscountBitFields] = useState(false);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [display, setDisplay] = React.useState('none');
  const [rows, setRows] = React.useState([]);
  const [records, setRecords] = React.useState([]);
  const [discountMain, setDiscountMain] = useState(0);
  let [searchData, setSearchData] = useState(
    JSON.parse(localStorage.getItem('Search'))
  );

  let [customerList, setCustomerList] = useState([]);
  let [customerinfo, setCustomerinfo] = useState();
  const [isCustomerAttached, setIsCustomerAttached] = useState(false);
  const [openCustomer, setOpenCustomer] = useState(false);
  const [openRider, setOpenRider] = useState(false);
  const [openDealManagement, setOpenDealManagement] = useState(false);
  const [openWaiter, setOpenWaiter] = useState(false);
  const [openTable, setOpenTable] = useState(false);
  const [openNewCustomer, setOpenNewCustomer] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });
  const [isNewOrder, setNewOrder] = useState(false);
  const [processVia, setProcessVia] = useState('checkout');
  const [openNayaPay, setOpenNayaPay] = useState(false);
  const [generateQr, setGenerateQr] = useState(false);
  const [generatePushInvoice, setGeneratePushInvoice] = useState(false);
  const [nayaPayId, setNayaPayId] = useState('');
  const [nayaPayQRCode, setNayaPayQRCode] = useState('');
  const [invoiceNo, setInvoiceNo] = useState('');
  const [UserRole] = useState(localStorage.getItem('role'));
  const [customerName, setCustomerName] = useState('');
  const [customerContactNumber, setCustomerContactNumber] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerAddress, setCustomerAddress] = useState('');
  const [customerLandmark, setCustomerLandmark] = useState('');
  const [area, setArea] = useState('#');
  const [areaValues, setAreaValues] = useState([]);
  const [deliveryCharges, setDeliveryCharges] = useState(0);
  const [openCancelOrder, setCancelOrder] = useState(false);
  const [openSpecialNote, setOpenSpecialNote] = useState(false);
  const [specialNote, setSpecialNote] = useState('');
  const [cancelReason, setCancelReason] = useState('');
  const [dealManagement2ndBodyItem, setDealManagement2ndBodyItem] = useState(
    []
  );
  const [dealItemListing, setDealItemListing] = useState([]);
  const [dealLimitCheck, setDealLimitCheck] = useState([]);
  const [open, setOpen] = React.useState(-1);
  APIService.GetSettings('Company Logo');
  APIService.GetSettings('ReceiptText');
  APIService.GetSettings('Cancel Order Time');
  APIService.GetSettings('CancelOrderReason');
  APIService.GetSettings('Modify Order Time');

  let cancelOrderReasonList = localStorage.getItem('CancelOrderReason');

  const [CancelReasonData, setCancelReasonData] = useState([]);

  const [value, setValue] = useState('');
  const [inputValue, setInputValue] = React.useState('');
  const [customerSearchText, setCustomerSearchText] = useState('');
  const [orderIdOdms, setOrderIdOdms] = useState('');

  const [cityData] = useState(JSON.parse(localStorage.getItem('City')) || []);

  const [branchData] = useState(
    JSON.parse(localStorage.getItem('Branch')) || []
  );

  const [branchAreaData] = useState(
    JSON.parse(localStorage.getItem('Area')) || []
  );
  const [city, setCity] = useState('#');
  const [branch, setBranch] = useState(!isOdms ? getBranch() : '#');
  const [deliveryTime, setDeliveryTime] = useState(0);

  const [openNewCustomerComp, setOpenNewCustomerComp] = useState(false);
  const [openSearchCustomerComp, setOpenSearchCustomerComp] = useState(false);

  const [customerInformation, setCustomerInformation] = useState(null);
  const [customerResponse, setCustomerResponse] = useState(null);

  useEffect(() => {
    getInvoiceTemplate();
  }, [getInvoiceTemplate]);

  useEffect(() => {
    CheckCti();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getIsOdms() {
    let odms = false;
    const Val = localStorage.getItem('role');
    if (Val === 'agent') {
      odms = true;
    }
    return odms;
  }

  function GetOrderModifyTime() {
    let time = 0;

    let orderDisplayTime =
      localStorage.getItem('Modify Order Time') === null ||
      localStorage.getItem('Modify Order Time') === 'default'
        ? []
        : JSON.parse(localStorage.getItem('Modify Order Time'));

    if (orderDisplayTime.length > 0) {
      time = orderDisplayTime[0].ModifyOrderTime;
    }
    return time;
  }

  function GetRiders() {
    const RiderList = JSON.parse(localStorage.getItem('Riders'));
    if (RiderList !== null) {
      const RiderData = [];

      RiderList.forEach(function (item) {
        if (item.status === 'active') {
          const Rider = {
            _id: item._id,
            name: item.name,
            nameValue: item.name,
          };
          RiderData.push(Rider);
        }
      });

      return RiderData;
    } else {
      return APIService.getRider();
    }
  }

  function GetDealManagement(selectedDealId) {
    const dealManagementLocalStorage = JSON.parse(
      localStorage.getItem('Deals')
    );
    if (dealManagementLocalStorage !== null) {
      setDealManagementValues(
        dealManagementLocalStorage.filter((elem) => elem._id === selectedDealId)
      );
    } else {
      setNotify({
        isOpen: true,
        message: 'Deals not found',
        type: 'error',
      });
    }
  }
  function GetWaiters() {
    const WaiterList = JSON.parse(localStorage.getItem('Waiters'));
    if (WaiterList !== null) {
      const WaiterData = [];

      WaiterList.forEach(function (item) {
        if (item.status === 'active') {
          const Waiter = {
            _id: item._id,
            name: item.name,
            nameValue: item.name,
          };
          WaiterData.push(Waiter);
        }
      });

      return WaiterData;
    } else {
      return APIService.getWaiter();
    }
  }
  function GetTables() {
    const TableList = JSON.parse(localStorage.getItem('Tables'));
    const HoldOrders = JSON.parse(localStorage.getItem('HoldOrder'));

    if (TableList !== null) {
      const TableData = [];
      var currDineInOrders = 0;

      TableList.forEach(function (item) {
        if (HoldOrders == null || HoldOrders.HoldOrders.length == 0) {
          if (currDineInOrders === 0) {
            if (item.status === 'active') {
              const tableData = {
                _id: item._id,
                name: item.name,
                nameValue: item.name,
              };
              TableData.push(tableData);
            }
          }
        } else {
          currDineInOrders = HoldOrders.HoldOrders.filter(
            (itemHold) =>
              itemHold.OrderModeName === 'DineIn' &&
              itemHold.TableNo === item.name
          ).length;
          if (currDineInOrders === 0) {
            if (item.status === 'active') {
              const tableData = {
                _id: item._id,
                name: item.name,
                nameValue: item.name,
              };
              TableData.push(tableData);
            }
          }
        }
      });

      return TableData;
    } else {
      return APIService.getTable();
    }
  }
  function Kot() {
    if (kotList.filter((item) => item.isKot === false).length > 0) {
      ReactDOM.render(<PrintKot />, document.getElementById('print-mount'));
      document.getElementById('print-button').click();
      ReactDOM.unmountComponentAtNode(document.getElementById('print-mount'));
    }
  }
  const HOList = JSON.parse(localStorage.getItem('HoldOrder'));

  const [holdOrder, setHoldOrder] = useState(
    HOList === null ? [] : HOList.HoldOrders
  );

  const QList = getListOfQueuedOrders();

  const [qOrder] = useState(QList === null ? [] : QList);

  const handleOpen4 = () => {
    setOpen4(true);
  };
  const handleClose4 = () => {
    setOpen4(false);
  };
  const [alertOpen, setAlertOpen] = useState(false);
  //type: error, warning, info, success
  const [alertBodyAndType, setAlertBodyAndType] = useState({
    message: '',
    type: '',
  });
  const [createdDateCallCenterOrder, setCreatedDateCallCenterOrder] = useState(
    getDateTime()
  );
  const [callCenterOrderNumber, setCallCenterOrderNumber] = useState(-1);
  const [selectedOrderIndex, setSelectedOrderIndex] = useState(-1);
  const [currentOrderNumber, setCurrentOrderNumber] = useState(0);
  const [orderModeName, setOrderModeName] = useState('TakeAway');
  const [orderMode, setOrderMode] = useState(1);
  const orderModeValues = APIService.getOrderMode(isOdms);
  const holdOrderCount = HOList === null ? 0 : holdOrder.length;
  const [qOrderCount, setQOrderCount] = useState(qOrder.length || 0);
  const [riderName, setRiderName] = useState();
  const [waiterName, setWaiterName] = useState();
  const [tableName, setTableName] = useState();
  const [dealManagementValues, setDealManagementValues] = useState([
    { name: '', dealOptions: [] },
  ]);

  const riderValues = GetRiders();
  const waiterValues = GetWaiters();
  const tableValues = GetTables();

  useEffect(() => {
    const timer = setInterval(() => {
      getQueuedOrders();
    }, 10000);

    return () => {
      clearTimeout(timer);
    };
  });

  useEffect(() => {
    const timer = setInterval(() => {
      directKotPrint();
    }, 20000);

    return () => {
      clearTimeout(timer);
    };
  });

  useEffect(() => {
    if (!isOdms) {
      const timer = setInterval(() => {
        if (qOrderCount > 0) {
          playAudio();
        }
      }, 10000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [qOrderCount, isOdms]);

  function CheckCti() {
    if (isOdms) {
      const a = document.location.search.split('=');

      if (a[0] === '?contactNumber') {
        const contactNumber = a[1];

        if (contactNumber.length > 10 && contactNumber.length < 13) {
          openCustomerModal();
          setCustomerSearchText(contactNumber);
          searchCustomer(contactNumber);
          newOrderButtonClick();
        }
      }
    }
  }

  function OdmsOrderExecution() {
    let isExecution = true;

    if (callCenterOrderNumber !== -1) {
      let time = GetOrderModifyTime();
      let diffInMinutesCC = getDifferenceInMinutes(
        new Date(createdDateCallCenterOrder),
        new Date()
      );

      if ((time || 0) < diffInMinutesCC) {
        isExecution = false;
      }
    }
    return isExecution;
  }

  const onChangeArea = (e) => {
    setArea(e.target.value);
    if (e.target.value != '#' && orderMode === '2') {
      let filteredArea = areaValues.filter(
        (item) => item._id === e.target.value
      )[0];
      setDeliveryCharges(filteredArea.deliveryCharges);
      setDeliveryTime(filteredArea.deliveryTime);
    } else {
      setDeliveryCharges(0);
      setDeliveryTime(0);
    }
  };

  function checkBranchEnable(branchId) {
    let isEnable = false;
    let isActive = branchData.filter((item) => item._id === branchId);
    if (isActive[0].status === 'active') {
      isEnable = true;
    }
    return isEnable;
  }

  const onChangeCity = (e) => {
    setCity(e.target.value);
    if (e.target.value != '#') {
      let ar = branchAreaData.filter(
        (item) => item.cityRef === e.target.value && item.status === 'active'
      );

      var areaList = [];
      ar.forEach((areaD) => {
        let delCharges = 0;
        var inc = 0;
        let map = areaD.branchMapping;

        if (map !== null && map.length > 0) {
          map.forEach((branchMapping) => {
            if (branchMapping !== null) {
              if (checkBranchEnable(branchMapping.branchRef)) {
                if (inc === 0) {
                  delCharges = branchMapping.deliveryCharges;

                  inc = inc + 1;

                  let areaForDD = {};
                  areaForDD = {
                    _id: areaD._id,
                    name: areaD.name,
                    nameValue: areaD.name,
                    deliveryCharges: delCharges || 0,
                    deliveryTime: areaD.deliveryTime || 0,
                  };
                  areaList.push(areaForDD);
                }
              }
            }
          });
        }
      });

      setAreaValues(areaList);
    }
  };

  const onChangeBranch = (e) => {
    setBranch(e.target.value);
  };

  const handleInputDropDownChange = (e) => {
    if (currentOrderNumber == 0) {
      setOrderMode(e.target.value);
      setOrderModeName(
        orderModeValues.filter((item) => item._id === e.target.value)[0].name
      );
    }
  };

  function getBranch() {
    let brnch = '#';
    if (branchData.length > 0) {
      brnch = branchData[0]._id;
    }
    return brnch;
  }

  function selectRider(rider) {
    handleCloseRiderModal();
    setRiderName(rider);
    let holdOrders = JSON.parse(localStorage.getItem('HoldOrder'));
    holdOrders.HoldOrders[selectedOrderIndex].Rider = rider;
    localStorage.setItem('HoldOrder', JSON.stringify(holdOrders));
    if (+orderMode === 2) {
      PrePaymentBill(rider);
    }
  }
  function selectWaiter(waiter) {
    handleCloseWaiterModal();
    setOpenTable(true);
    openTableModal();
    setWaiterName(waiter);
  }
  function selectTable(table) {
    handleCloseTableModal();
    if (processVia === 'checkout') {
      if (productListInMainBody.length > 0) {
        if (kotList.filter((a) => a.isKot == false).length == 0) {
          setOpen6(true);
        }
      }
    }
    setTableName(table);
  }
  function findByProductId(ProductId) {
    var result = null;

    const obj = categories.filter((a) => a._id != 0);
    for (const inobj of obj) {
      for (const innerobj of inobj.products) {
        if (innerobj._id === ProductId) {
          return innerobj;
        }
      }
    }
    return result;
  }
  function handleClickClearText() {
    setRecordSets({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: '',
    });
  }
  const [recordSets, setRecordSets] = useState({
    activeSuggestion: 0,
    filteredSuggestions: [],
    showSuggestions: false,
    userInput: '',
  });
  let suggestionsListComponent;
  if (recordSets.showSuggestions && recordSets.userInput) {
    if (recordSets.filteredSuggestions.length) {
      suggestionsListComponent = (
        <List className={classes.SearchListStylingFood}>
          {recordSets.filteredSuggestions
            .filter((a) => a.status == 'active')
            .map((suggestion, index) => {
              return (
                <>
                  <ListItem
                    button
                    key={index}
                    onClick={(e) => onClick(e, suggestion._id, suggestion.name)}
                  >
                    <ListItemText
                      id={`lstFoodSearchProd${index}`}
                      primary={suggestion.name}
                      secondary={suggestion.barcode}
                    />
                  </ListItem>
                  <Divider />
                </>
              );
            })}
        </List>
      );
    } else {
      suggestionsListComponent = (
        <List className={classes.SearchListStylingFood}>
          <ListItem>
            <ListItemText primary={<em>No suggestions available.</em>} />
          </ListItem>
        </List>
      );
    }
  }
  const history = useHistory();
  function SIGNOUT() {
    if (!isOdms) {
      const Val = localStorage.getItem('_IdShift');
      setsignout(true);
      if (Val) {
        handleOpen5();
      } else {
        setsignout(false);
        localStorage.removeItem('Token');
        history.push('/Signin');
      }
    } else if (isOdms) {
      setsignout(false);
      localStorage.removeItem('Token');
      history.push('/Signin');
    }
  }
  function onChange(e) {
    const userInput = e.currentTarget.value;
    let filteredSuggestions = [];
    let suggestions = null;
    if (searchData === null) {
      setSearchData(JSON.parse(localStorage.getItem('Search')));
      suggestions = JSON.parse(localStorage.getItem('Search'));
    } else {
      suggestions = searchData;
    }

    filteredSuggestions = suggestions.filter(
      (suggestion) =>
        suggestion.name.toLowerCase().indexOf(userInput.toLowerCase()) > -1 ||
        (suggestion.barcode
          ? suggestion.barcode.toLowerCase().indexOf(userInput.toLowerCase()) >
            -1
          : false)
    );

    setRecordSets({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value,
    });
  }
  function onClick(e, id, name) {
    setRecordSets({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: name,
    });
    const found = findByProductId(id);
    if (found === null) {
      //do nothing just exit from loop
    } else {
      if (found.isDeal) {
        GetDealManagement(found._id);
        setOpenDealManagement(true);
      } else {
        selectProductForMainBody(found);
      }
    }
  }
  async function GetData() {
    return new Promise((resolve) => {
      APIService.GetExpectedAmts().then((ResponceData) =>
        resolve(ResponceData)
      );
    });
  }
  const handleOpen5 = () => {
    GetData().then((Response) => {
      if (Response.data !== null) {
        const Data = Response.data.data;
        setExpectedAmount(() => {
          return Data ? sumofarryValue(Data) : '0';
        });
        setOpen5(true);
      } else {
        setOpen5(true);
      }
    });
  };

  const handleOpen3 = () => {
    setOpen3(true);
  };
  const handleClose3 = () => {
    setOpen3(false);
  };
  const handleCloseQueuedOrders = () => {
    setQueuedOrders(false);
  };
  const handleOpenQueuedOrders = () => {
    ResetEveryThing();
    setQueuedOrders(true);
    getQueuedOrders();
  };
  function hideSales() {
    setEndShiftSaleDetail('block');
    setEndShiftSaleDetailTab('none');
    setIsLoaded(false);
  }
  async function getQueuedOrders() {
    let searchString = '';
    if (localStorage.getItem('role') !== 'agent') {
      searchString = '&trackOrderVisibility=true';
    }

    let Res = await APIService.getOrdersByStatus('booked', searchString);

    if (Res && Res.data && Res.data.data) {
      localStorage.removeItem('QueuedOrders');
      localStorage.setItem('QueuedOrders', JSON.stringify(Res.data.data));

      setQOrderCount(Res.data.data.length);
    }
  }

  function KotOrderSet(KotOrder) {
    localStorage.setItem('KotOrder', JSON.stringify(KotOrder));
    window.sessionStorage.setItem('KotOrder', JSON.stringify(KotOrder));
  }

  function isDirectKOTEnabled() {
    return JSON.parse(localStorage.getItem('directKOTState'));
  }

  function directKotPrint() {
    if (isDirectKOTEnabled()) {
      const listOfQueuedOrders = getListOfQueuedOrders();
      if (listOfQueuedOrders && listOfQueuedOrders.length) {
        listOfQueuedOrders.forEach(function (item, index) {
          sendKotDirect(listOfQueuedOrders[index]);
        });
      }
    }
  }

  function sendKotDirect(obj) {
    let orderModeFiltered = orderModeValues.filter(
      (item) => item.nameValue === obj.saleDetails.saleType
    )[0];

    let ProductList = [];
    let DealList = [];
    let kotArr = [];

    obj.saleDetails.dealsSold.map((item) => {
      item.dealItems.map((itemD, indx) => {
        DealList.push({
          additionalPrice: itemD.additionalPrice,
          _id: itemD._id,
          itemRef: itemD.itemRef || itemD.itemRef._id,
          name: itemD.itemRef.name || '',
          dealIndex: indx,
        });
      });

      kotArr.push({
        _id: item._id,
        name: item.dealRef.name || '',
        barcode: '',
        quantity: 1,
        isKot: false,
        isLess: false,
        isDeal: true,
        dealName: item.dealRef.name || '',
        dealItems: item.dealItems,
      });

      ProductList.push({
        _id: item.dealRef,
        name: item.dealRef.name,
        saleFinalPrice: item.dealFinalPrice,
        unitSaleFinalPrice: item.dealFinalPrice,
        barcode: '',
        Quantity: item.quantity,
        taxPercentage: item.taxAmount || 0,
        salePrice: item.dealPrice || 0,
        unitSalePrice: item.dealPrice || 0,
        unitQuantity: item.quantity,
        discountPercentage: '0.00',
        minDiscountPercentage: '0.00',
        maxDiscountPercentage: '0.00',
        Tempdiscount: 0,
        description: '',
        isDeal: true,
        dealItems: DealList,
      });
    });

    obj.saleDetails.itemsSold.map((item) => {
      ProductList.push({
        _id: item.itemRef._id || item._id,
        name: item.itemRef.name || item.name,
        saleFinalPrice: item.saleFinalPrice,
        unitSaleFinalPrice: item.unitSaleFinalPrice || item.saleFinalPrice,
        barcode: item.barcode,
        Quantity: item.quantity,
        taxPercentage: item.itemRef.taxPercentage || 0,
        salePrice: item.itemRef.salePrice || item.saleFinalPrice,
        unitSalePrice: item.itemRef.salePrice || item.saleFinalPrice,
        unitQuantity: item.quantity,
        discountPercentage: '0.00',
        minDiscountPercentage: '0.00',
        maxDiscountPercentage: '0.00',
        Tempdiscount: 0,
        description: '',
        isDeal: false,
        dealItems: [],
      });
    });

    let sumDiscountAmount = obj.saleDetails.itemsSold.reduce(function (
      prev,
      current
    ) {
      return prev + current.discountAmount;
    },
    0);

    ProductList.forEach((item) => {
      if (!item.isDeal) {
        var objProd = {
          _id: item._id,
          name: item.name,
          barcode: item.barcode,
          quantity: item.Quantity || item.quantity,
          isKot: false,
          isLess: false,
        };
        kotArr.push(objProd);
      }
    });

    handleCloseQueuedOrders();

    if (obj.branchRef !== '#') {
      if (kotArr.length > 0) {
        const Order = {
          HoldOrders: [
            {
              Date: new Date().toLocaleString(),
              HoldOrderNumber: obj._id,
              Products: [ProductList],
              TotalItems: ProductList.length,
              AmountWithoutTax: obj.saleDetails.saleAmountSumOfItems,
              Tax: 0,
              Discount: sumDiscountAmount,
              NetTotal: obj.saleDetails.saleAmountTotal,
              OrderModeName: orderModeFiltered.name,
              OrderMode: orderModeFiltered._id,
              TableNo: tableName,
              Waiter: waiterName,
              Rider: riderName,
              Customer: obj.customerRef,
              CustomerAttached: true,
              DeliveryCharges: obj.saleDetails.deliveryCharges || 0,
              specialNote: localStorage.getItem('_note') || '',
              orderId: obj._id || '',
              branchRef: obj.branchRef,
            },
          ],
        };

        const HoldOrderList = JSON.parse(localStorage.getItem('HoldOrder'));

        if (HoldOrderList === null || HoldOrderList === []) {
          localStorage.setItem('HoldOrder', JSON.stringify(Order));
          const temp = JSON.parse(localStorage.getItem('HoldOrder'));
          setHoldOrder(temp.HoldOrders);
        } else {
          HoldOrderList.HoldOrders.push(Order.HoldOrders[0]);
          localStorage.setItem('HoldOrder', JSON.stringify(HoldOrderList));
          const temp = JSON.parse(localStorage.getItem('HoldOrder'));
          setHoldOrder(temp.HoldOrders);
        }
        localStorage.removeItem('_note');

        if (obj._id !== '') {
          changeOrderStatus(obj._id, obj.customerRef, 'kot');
        }
      }
      if (kotArr.length > 0) {
        const KotOrder = {
          KotOrders: [
            {
              Date: new Date().toLocaleString(),
              HoldOrderNumber: obj._id,
              Products: kotArr,
              OrderModeName: orderModeFiltered.name,
              branchRef: obj.branchRef,
            },
          ],
        };

        const KotOrderList = JSON.parse(localStorage.getItem('KotOrder'));

        if (KotOrderList === null) {
          localStorage.setItem('KotOrder', JSON.stringify(KotOrder));
          window.sessionStorage.setItem('KotOrder', JSON.stringify(KotOrder));
        } else if (
          KotOrderList.KotOrders.length === 0 &&
          KotOrderList !== null
        ) {
          KotOrderSet(KotOrder);
        } else {
          KotOrderList.KotOrders.push(KotOrder.KotOrders[0]);
          localStorage.setItem('KotOrder', JSON.stringify(KotOrderList));
          window.sessionStorage.setItem('KotOrder', JSON.stringify(KotOrder));
        }

        if (kotArr.filter((item) => item.isKot === false).length > 0) {
          ReactDOM.render(<PrintKot />, document.getElementById('print-mount'));
          document.getElementById('print-button').click();
          ReactDOM.unmountComponentAtNode(
            document.getElementById('print-mount')
          );
        }

        let TempListKot = JSON.parse(localStorage.getItem('KotOrder'));

        TempListKot.KotOrders.splice(TempListKot.KotOrders.length - 1, 1);

        kotArr.forEach(function (item) {
          if (item.isKot === false) {
            item.isKot = true;
          }
        });

        const KotOrderAfter = {
          KotOrders: [
            {
              Date: new Date().toLocaleString(),
              HoldOrderNumber: obj._id,
              Products: kotArr,
              OrderModeName: orderModeFiltered.name,
              branchRef: obj.branchRef,
            },
          ],
        };

        if (TempListKot.KotOrders.length === 0) {
          localStorage.setItem('KotOrder', JSON.stringify(KotOrderAfter));
          window.sessionStorage.setItem(
            'KotOrder',
            JSON.stringify(KotOrderAfter)
          );
        } else {
          TempListKot.KotOrders.push(KotOrderAfter.KotOrders[0]);
          localStorage.setItem('KotOrder', JSON.stringify(TempListKot));
          window.sessionStorage.setItem(
            'KotOrder',
            JSON.stringify(KotOrderAfter)
          );
        }
      }
      ResetEveryThing();
      getQueuedOrders();
    }
  }

  function sendKot() {
    if (branch !== '#') {
      if (selectedOrderIndex > -1) {
        let TempList = JSON.parse(localStorage.getItem('HoldOrder'));
        TempList.HoldOrders.splice(selectedOrderIndex, 1);
        localStorage.setItem('HoldOrder', JSON.stringify(TempList));

        let TempListKot = JSON.parse(localStorage.getItem('KotOrder'));
        if (TempListKot != null) {
          TempListKot.KotOrders.splice(selectedOrderIndex, 1);
          localStorage.setItem('KotOrder', JSON.stringify(TempListKot));
        }
      }

      let date = getInvoiceNumber();

      if (productListInMainBody.length > 0) {
        const Order = {
          HoldOrders: [
            {
              Date: new Date().toLocaleString(),
              HoldOrderNumber: `${
                currentOrderNumber === 0 ? date : currentOrderNumber
              }`,
              Products: [productListInMainBody],
              TotalItems: totalItems,
              AmountWithoutTax: amountWithoutTax,
              Tax: tax,
              Discount: discount,
              NetTotal: netTotal,
              OrderModeName: orderModeName,
              OrderMode: orderMode,
              TableNo: tableName,
              Waiter: waiterName,
              Rider: riderName,
              Customer: customerinfo,
              CustomerAttached: isCustomerAttached,
              DeliveryCharges: deliveryCharges || 0,
              specialNote: specialNote,
              orderId: orderIdOdms || '',
              branchRef: branch,
            },
          ],
        };

        const HoldOrderList = JSON.parse(localStorage.getItem('HoldOrder'));

        if (HoldOrderList === null) {
          localStorage.setItem('HoldOrder', JSON.stringify(Order));
          const temp = JSON.parse(localStorage.getItem('HoldOrder'));
          setHoldOrder(temp.HoldOrders);
        } else {
          HoldOrderList.HoldOrders.push(Order.HoldOrders[0]);
          localStorage.setItem('HoldOrder', JSON.stringify(HoldOrderList));
          const temp = JSON.parse(localStorage.getItem('HoldOrder'));
          setHoldOrder(temp.HoldOrders);
        }
        setproductListInMainBody([]);
        setTotalItems(0);
        setAmountWithoutTax(0);
        setTax(0);
        setDiscount(0);
        setNetTotal(0);
        localStorage.removeItem('_note');

        if (orderIdOdms !== '') {
          changeOrderStatus(orderIdOdms, customerinfo, 'kot');
        }
      }

      if (kotList.length > 0) {
        const KotOrder = {
          KotOrders: [
            {
              Date: new Date().toLocaleString(),
              HoldOrderNumber: `${
                currentOrderNumber === 0 ? date : currentOrderNumber
              }`,
              Products: kotList,
              OrderModeName: orderModeName,
              branchRef: branch,
            },
          ],
        };

        const KotOrderList = JSON.parse(localStorage.getItem('KotOrder'));

        if (KotOrderList === null) {
          localStorage.setItem('KotOrder', JSON.stringify(KotOrder));
          window.sessionStorage.setItem('KotOrder', JSON.stringify(KotOrder));
        } else if (
          KotOrderList.KotOrders.length === 0 &&
          KotOrderList !== null
        ) {
          KotOrderSet(KotOrder);
        } else {
          KotOrderList.KotOrders.push(KotOrder.KotOrders[0]);
          localStorage.setItem('KotOrder', JSON.stringify(KotOrderList));
          window.sessionStorage.setItem('KotOrder', JSON.stringify(KotOrder));
        }

        Kot();

        let TempListKot = JSON.parse(localStorage.getItem('KotOrder'));

        setKotList(
          TempListKot.KotOrders[TempListKot.KotOrders.length - 1].Products
        );

        TempListKot.KotOrders.splice(TempListKot.KotOrders.length - 1, 1);

        kotList.forEach(function (item) {
          if (item.isKot === false) {
            item.isKot = true;
          }
        });

        const KotOrderAfter = {
          KotOrders: [
            {
              Date: new Date().toLocaleString(),
              HoldOrderNumber: `${date}`,
              Products: kotList,
              OrderModeName: orderModeName,
              branchRef: branch,
            },
          ],
        };

        if (TempListKot.KotOrders.length === 0) {
          localStorage.setItem('KotOrder', JSON.stringify(KotOrderAfter));
          window.sessionStorage.setItem(
            'KotOrder',
            JSON.stringify(KotOrderAfter)
          );
        } else {
          TempListKot.KotOrders.push(KotOrderAfter.KotOrders[0]);
          localStorage.setItem('KotOrder', JSON.stringify(TempListKot));
          window.sessionStorage.setItem(
            'KotOrder',
            JSON.stringify(KotOrderAfter)
          );
        }
        setKotList([]);
      }
      ResetEveryThing();
      getQueuedOrders();
    } else {
      setNotify({
        isOpen: true,
        message: 'Please select a branch',
        type: 'warning',
        notificationTime: 5000,
      });
    }
  }

  function sumofarryValue(ArrayValue) {
    var sum = 0;
    const ShiftData = JSON.parse(localStorage.getItem('_IdShift'));
    ArrayValue.forEach((element) => {
      sum = sum + element.saleAmountTotal;
    });
    return (sum + ShiftData.openingAmount).toFixed(2);
  }
  const handleClose5 = () => {
    setOpen5(false);
  };
  function handleOpeningAmount() {
    APIService.startshift(openingamount).then(
      () => {
        setShiftButton(true);
        setOpeningamount('');
        handleClose4();
        getInvoiceTemplate();
      },
      () => {
        triggeredAlert(
          'error',
          'An Error Occure while Starting Shift, Please Try Again'
        );
      }
    );
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function getInvoiceTemplate() {
    getQueuedOrders();

    if (!isOdms) {
      let dataPromise = (async () => {
        apiResponseDto = await SetupService.getSetupWithName('Invoice').then(
          (Response) => {
            return Response;
          }
        );
        return apiResponseDto;
      })();
      dataPromise.then((response) => {
        if (response.data.data.length) {
          let activeInvoices = response.data.data[0].data.filter(
            (invoice) => invoice.status === 'active'
          );
          localStorage.setItem(
            'invoiceTemplate',
            JSON.stringify(activeInvoices[0])
          );
        } else console.error('Error While Fetching Data: ', Response.error);
      });
    }
  }

  const handleOpen6 = () => {
    setOpen6(true);
  };
  const handleClose6 = () => {
    setOpen6(false);
  };
  function fetchSalesforShift() {
    return APIService.getAllSales();
  }
  function fetchSales() {
    setDisplay('block');
    fetchSalesforShift().then(
      (Response) => {
        if (Response.data) {
          var data = Response.data.data;
          setRows(data);
          setIsLoaded(true);
        }
      },
      () => {
        setIsLoaded(false);
      }
    );
  }

  function QueueOrderReload(obj) {
    let taxAmount = 0;
    let orderModeFiltered = orderModeValues.filter(
      (item) => item.nameValue === obj.saleDetails.saleType
    )[0];

    let ProductList = [];
    let DealList = [];
    let kotArr = [];

    obj.saleDetails.dealsSold.map((item) => {
      DealList = [];
      item.dealItems.map((itemD, indx) => {
        DealList.push({
          additionalPrice: itemD.additionalPrice,
          _id: itemD._id,
          itemRef: itemD.itemRef || itemD.itemRef._id,
          name: itemD.itemRef.name || '',
          dealIndex: indx,
        });
      });

      kotArr.push({
        _id: item._id,
        name: item.dealRef.name || '',
        barcode: '',
        quantity: 1,
        isKot: false,
        isLess: false,
        isDeal: true,
        dealName: item.dealRef.name || '',
        dealItems: item.dealItems,
      });

      ProductList.push({
        _id: item.dealRef,
        name: item.dealRef.name,
        saleFinalPrice: item.dealFinalPrice,
        unitSaleFinalPrice: item.dealFinalPrice,
        barcode: '',
        Quantity: item.quantity,
        taxPercentage: item.taxAmount || 0,
        salePrice: item.dealPrice || 0,
        unitSalePrice: item.dealPrice || 0,
        unitQuantity: item.quantity,
        discountPercentage: '0.00',
        minDiscountPercentage: '0.00',
        maxDiscountPercentage: '0.00',
        Tempdiscount: 0,
        description: '',
        isDeal: true,
        dealItems: DealList,
      });
    });

    obj.saleDetails.itemsSold.map((item) => {
      let discPercent =
        (item.discountAmount * 100) /
        (item.saleFinalPrice - item.taxAmount) /
        item.quantity;

      taxAmount = taxAmount + (item.taxAmount || 0);

      ProductList.push({
        _id: item.itemRef._id || item._id,
        name: item.itemRef.name || item.name,
        saleFinalPrice: item.saleAmountTotal || item.saleFinalPrice,
        unitSaleFinalPrice: item.unitSaleFinalPrice || item.saleFinalPrice,
        barcode: item.barcode,
        Quantity: item.quantity,
        taxPercentage: item.itemRef.taxPercentage || 0,
        salePrice: item.itemRef.salePrice || item.saleFinalPrice,
        unitSalePrice: item.itemRef.salePrice || item.saleFinalPrice,
        unitQuantity: item.quantity,
        discountPercentage: discPercent,
        minDiscountPercentage: '0.00',
        maxDiscountPercentage: '0.00',
        Tempdiscount: 0,
        description: '',
        isDeal: false,
        dealItems: [],
      });
    });

    let sumDiscountAmount = obj.saleDetails.itemsSold.reduce(function (
      prev,
      current
    ) {
      return prev + current.discountAmount;
    },
    0);

    ProductList.forEach((item) => {
      selectProductForMainBody(item);

      if (!item.isDeal) {
        var objProd = {
          _id: item._id,
          name: item.name,
          barcode: item.barcode,
          quantity: item.Quantity || item.quantity,
          isKot: false,
          isLess: false,
        };
        kotArr.push(objProd);
      }
    });

    setKotList(kotArr);

    handleCloseQueuedOrders();

    setproductListInMainBody(ProductList);
    setTotalItems(ProductList.length);
    setAmountWithoutTax(obj.saleDetails.saleAmountSumOfItems - taxAmount);
    setTax(taxAmount);
    setDiscount(sumDiscountAmount);
    setNetTotal(obj.saleDetails.saleAmountTotal);
    setIsCustomerAttached(true);
    setCustomerinfo(obj.customerRef);
    setSpecialNote(obj.specialNote || '');
    setDeliveryCharges(obj.saleDetails.deliveryCharges);
    if (orderModeFiltered !== undefined) {
      setOrderMode(orderModeFiltered._id);
      setOrderModeName(orderModeFiltered.name);
    }
    setOrderIdOdms(obj._id);
    setCurrentOrderNumber(obj._id);
    setDeliveryTime(obj.deliveryTime);
    setBranch(obj.branchRef);
    if (isOdms) {
      setCallCenterOrderNumber(obj._id);
      setCreatedDateCallCenterOrder(obj.History[0].createdAt);
    }
  }
  function HoldOrderReload(index) {
    ResetEveryThing();
    setNewOrder(true);
    let TempList = JSON.parse(localStorage.getItem('HoldOrder'));

    LoadHoldOrderStates(TempList, index);
    localStorage.setItem('HoldOrder', JSON.stringify(TempList));
    setHoldOrder(TempList.HoldOrders);

    let TempListKot = JSON.parse(localStorage.getItem('KotOrder'));

    LoadKotOrderStates(TempListKot, index);
    localStorage.setItem('KotOrder', JSON.stringify(TempListKot));

    handleClose3();
  }
  function LoadKotOrderStates(TempListKot, index) {
    setKotList(TempListKot.KotOrders[index].Products);
  }

  function LoadHoldOrderStates(TempList, index) {
    ResetEveryThing();
    setNewOrder(true);

    setproductListInMainBody(TempList.HoldOrders[index].Products[0]);
    setTotalItems(TempList.HoldOrders[index].TotalItems);
    setAmountWithoutTax(TempList.HoldOrders[index].AmountWithoutTax);
    setTax(TempList.HoldOrders[index].Tax);
    setDiscount(TempList.HoldOrders[index].Discount);
    setNetTotal(TempList.HoldOrders[index].NetTotal);
    setOrderMode(TempList.HoldOrders[index].OrderMode);
    setOrderModeName(TempList.HoldOrders[index].OrderModeName);
    setCurrentOrderNumber(TempList.HoldOrders[index].HoldOrderNumber);
    setOrderIdOdms(TempList.HoldOrders[index].HoldOrderNumber);
    setSelectedOrderIndex(index);
    setWaiterName(TempList.HoldOrders[index].Waiter);
    setTableName(TempList.HoldOrders[index].TableNo);
    setRiderName(TempList.HoldOrders[index].Rider);
    setIsCustomerAttached(TempList.HoldOrders[index].CustomerAttached);
    setCustomerinfo(TempList.HoldOrders[index].Customer);
    setSpecialNote(TempList.HoldOrders[index].specialNote);
    setDeliveryCharges(
      TempList.HoldOrders[index].DeliveryCharges == undefined
        ? 0
        : TempList.HoldOrders[index].DeliveryCharges || 0
    );
    setBranch(TempList.HoldOrders[index].branchRef);
  }

  function endShiftSaleDetailTabBack() {
    setEndShiftSaleDetail('block');
    setEndShiftSaleDetailTab('none');
  }
  function getShiftButtonData() {
    const Val = localStorage.getItem('_IdShift');
    return Val ? true : false;
  }

  const Check = JSON.parse(localStorage.getItem('Order'));
  if (!Check) {
    localStorage.setItem('Order', JSON.stringify(DataDump));
  }
  const discountinfo = JSON.parse(window.localStorage.getItem('_DiscountInfo'));
  const signDiscount = useState(() => {
    if (discountinfo) {
      if (
        discountinfo.Discount[0].DiscountName &&
        discountinfo.Discount[0].DiscountLimit
      ) {
        return discountinfo.Discount[0].DiscountName === 'discountLimitAmount'
          ? 'Rs'
          : '%';
      } else {
        setDiscountBitFields(true);
        return 'Rs';
      }
    } else {
      setDiscountBitFields(true);
      return 'Rs';
    }
  });
  const SyncDateTime = localStorage.getItem('_SyncDateTime');
  function updateSyncTime() {
    let newDate = new Date();
    let date =
      newDate.getFullYear() +
      '-' +
      (newDate.getMonth() + 1) +
      '-' +
      newDate.getDate() +
      ' ' +
      newDate.getHours() +
      ':' +
      newDate.getMinutes() +
      ':' +
      newDate.getSeconds();
    localStorage.setItem('_SyncDateTime', date);
    setSyncTime('Last Sync At: ' + localStorage.getItem('_SyncDateTime'));
  }
  if (!SyncDateTime) {
    updateSyncTime();
  }
  const handleAlertClick = () => {
    setAlertOpen(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOpen(false);
  };
  function triggeredAlert(typeVar, messageVar) {
    setAlertBodyAndType({
      message: messageVar,
      type: typeVar,
    });
    handleAlertClick();
  }
  async function InvoiceSyncRequest(Obj) {
    return await APIService.InvoiceSyncRequest(Obj).then((Responce) => {
      return Responce;
    });
  }
  function InvoiceSync() {
    {
      try {
        let TempList = JSON.parse(localStorage.getItem('Order'));
        let index = TempList.Order.length;
        //ToDo Sync API
        if (index > 1) {
          try {
            let promise = InvoiceSyncRequest(TempList.Order[index - 1]);
            promise.then((response) => {
              if (response.data) {
                TempList.Order.splice(index - 1, 1);
                localStorage.setItem('Order', JSON.stringify(TempList));
                InvoiceSync();
              }
            });
          } catch (e) {
            //do nothing just exit from loop
          }
        }
        if (index <= 1) {
          updateSyncTime();
          triggeredAlert('success', 'Data has been Successfully Syncronized');
        }
      } catch (error) {
        //do nothing just exit from loop
      }
    }
  }
  function setKotValues(Product, Less) {
    const valKot = kotList.findIndex((a) => a._id === Product._id);
    if (Product.isDeal) {
      setKotList(() => [
        ...kotList,
        {
          _id: Product._id,
          name: Product.name,
          barcode: null,
          quantity: 1,
          isLess: Less,
          isKot: false,
          isDeal: true,
          dealName: Product.name,
          dealItems: Product.dealItems,
        },
      ]);
    } else if (valKot === -1) {
      setKotList(() => [
        ...kotList,
        {
          _id: Product._id,
          name: Product.name,
          barcode: Product.barcode,
          quantity: Product.Quantity || Product.quantity,
          isKot: false,
          isLess: false,
        },
      ]);
    } else {
      if (kotList[valKot].isKot === false) {
        if (Less) {
          kotList[valKot].quantity =
            parseFloat(kotList[valKot].quantity) - parseFloat(Product.Quantity);
        } else {
          kotList[valKot].quantity =
            parseFloat(kotList[valKot].quantity) + parseFloat(Product.Quantity);
        }
      } else {
        setKotList(() => [
          ...kotList,
          {
            _id: Product._id,
            name: Product.name,
            barcode: Product.barcode,
            quantity: Product.Quantity || Product.quantity,
            isKot: false,
            isLess: Less,
          },
        ]);
      }
    }
  }

  const initializeProudctInMainBody = (Product) =>
    setproductListInMainBody(() => [
      ...productListInMainBody,
      {
        _id: Product._id,
        name: Product.name,
        saleFinalPrice: (Product.saleFinalPrice - Product.Tempdiscount).toFixed(
          2
        ),
        unitSaleFinalPrice: parseFloat(Product.unitSaleFinalPrice).toFixed(2),
        barcode: Product.barcode,
        Quantity: Product.Quantity,
        taxPercentage: Product.taxPercentage,
        salePrice: parseFloat(Product.salePrice).toFixed(2) || 0,
        unitSalePrice: parseFloat(Product.unitSalePrice).toFixed(2) || 0,
        unitQuantity: Product.unitQuantity || 0,
        discountPercentage: Product.discountPercentage
          ? parseFloat(Product.discountPercentage).toFixed(2)
          : (0).toFixed(2) || 0,
        minDiscountPercentage:
          parseFloat(Product.minDiscountPercentage).toFixed(2) || 0,
        maxDiscountPercentage:
          parseFloat(Product.maxDiscountPercentage).toFixed(2) || 0,
        Tempdiscount: Product.Tempdiscount.toFixed(2) || 0,
        description: Product.description,
        isDeal: Product.isDeal || false,
        dealItems: Product.dealItems || null,
      },
    ]);

  function selectProductForMainBody(Product) {
    setKotValues(Product, false);
    const val = productListInMainBody.findIndex((a) => a._id === Product._id);
    if (val === -1 || Product.isDeal) {
      initializeProudctInMainBody(Product);
    } else {
      productListInMainBody[val].Quantity =
        parseFloat(productListInMainBody[val].Quantity) +
        parseFloat(Product.unitQuantity);

      const Tempdt =
        productListInMainBody[val].Quantity *
        productListInMainBody[val].unitSaleFinalPrice;
      const Tempdp = (
        (((productListInMainBody[val].discountPercentage
          ? productListInMainBody[val].discountPercentage
          : 0) *
          productListInMainBody[val].unitSalePrice) /
          100) *
        productListInMainBody[val].Quantity
      ).toFixed(2);
      productListInMainBody[val].saleFinalPrice = (Tempdt - Tempdp).toFixed(2);

      let discPercentProduct =
        productListInMainBody[val].discountPercentage || 0;
      let unitPrice = productListInMainBody[val].unitSalePrice || 0;
      let unitDisc = (unitPrice * discPercentProduct) / 100 || 0;
      let unitSalePriceAfterDiscount = unitPrice - unitDisc;

      productListInMainBody[val].Tempdiscount = (
        productListInMainBody[val].Quantity * unitDisc
      ).toFixed(2);

      productListInMainBody[val].saleFinalPrice = (
        productListInMainBody[val].Quantity * unitSalePriceAfterDiscount
      ).toFixed(2);
    }
    setTotalItems(parseFloat(totalItems) + parseFloat(Product.unitQuantity));
    setNetTotal(parseFloat(netTotal) + parseFloat(Product.saleFinalPrice));
    setAmountWithoutTax(
      parseFloat(amountWithoutTax) + parseFloat(Product.salePrice)
    );
    setTax(
      parseFloat(tax) +
        (parseFloat(Product.saleFinalPrice) - parseFloat(Product.salePrice))
    );

    let tempDisc = 0;

    if (
      productListInMainBody != undefined &&
      productListInMainBody.length > 0
    ) {
      productListInMainBody.map((item) => {
        tempDisc = tempDisc + parseFloat(item.Tempdiscount);
      });
    }
    if (val == -1) {
      tempDisc = tempDisc + (Product.Tempdiscount || 0);
    }

    setDiscount(parseFloat(tempDisc));
  }
  function handleShiftEnd() {
    if (
      expectedAmount !== '' &&
      closingAmount !== '' &&
      internalSaleAmount !== ''
    ) {
      APIService.endshift(
        expectedAmount,
        closingAmount,
        internalSaleAmount,
        comments
      ).then(
        () => {
          localStorage.removeItem('invoiceTemplate');
        },
        () => {}
      );
      if (signout === true) {
        setsignout(false);
        localStorage.removeItem('Token');
        history.push('/Signin');
      }
      setShiftButton(false);
      ResetEveryThing();
    } else {
      setNotify({
        isOpen: true,
        message: 'Please enter the required fields',
        type: 'warning',
        notificationTime: 5000,
      });
    }
  }
  const [calValue, setCalValue] = React.useState('');
  function cal(currValue) {
    if (currValue === 'c') {
      setCalValue('');
    } else {
      setCalValue(calValue + currValue);
    }
  }
  function ResetCart() {
    setproductListInMainBody([]);
    setTotalItems(0);
    setAmountWithoutTax(0);
    setTax(0);
    setDiscount(0);
    setNetTotal(0);
  }
  function ResetEveryThing() {
    setproductListInMainBody([]);
    setTotalItems(0);
    setAmountWithoutTax(0);
    setTax(0);
    setDiscount(0);
    setNetTotal(0);
    setCalValue('');
    handleClose6();
    handleClose5();
    setExpectedAmount('');
    setClosingAmount('');
    setInternalSaleAmount('0.00');
    setComments('');
    setFinalDiscount(0);
    setIsCustomerAttached(false);
    setCustomerinfo();
    setCustomerList([]);
    setKotList([]);
    setRiderName();
    setWaiterName();
    setTableName();
    setCurrentOrderNumber(0);
    setSelectedOrderIndex(-1);
    setNewOrder(false);
    setProcessVia('checkout');
    setCustomerName('');
    setCustomerContactNumber('');
    setCustomerEmail('');
    setCustomerAddress('');
    setCustomerLandmark('');
    setArea('#');
    setDeliveryCharges(0);
    setDiscountMain(0);
    setSpecialNote('');
    setCancelReason('');
    localStorage.removeItem('_note');
    setDealManagement2ndBodyItem([]);
    setDealItemListing([]);
    setDealLimitCheck([]);
    setDealManagementValues([{ name: '', dealOptions: [] }]);
    setCustomerSearchText('');
    setOrderIdOdms('');
    setCallCenterOrderNumber(-1);
    setCity('#');
    setDeliveryTime(0);
  }
  function controlOverAllDiscount(e) {
    if (discountinfo.Discount[0].DiscountName === 'discountLimitPercentage') {
      if (
        parseFloat(e ? e : 0) <=
        parseFloat(discountinfo.Discount[0].DiscountLimit)
      ) {
        setFinalDiscount(e);
        setDiscountMain((amountWithoutTax * e) / 100);
      } else {
        setFinalDiscount(discountinfo.Discount[0].DiscountLimit);
        setDiscountMain(
          (amountWithoutTax * discountinfo.Discount[0].DiscountLimit) / 100
        );
        triggeredAlert('warning', 'You Trying to Exceed Max Limit');
      }
    } else if (
      discountinfo.Discount[0].DiscountName === 'discountLimitAmount'
    ) {
      if (
        parseFloat(e ? e : 0) <=
        parseFloat(discountinfo.Discount[0].DiscountLimit)
      ) {
        setFinalDiscount(e);
        setDiscountMain(e);
      } else {
        setFinalDiscount(discountinfo.Discount[0].DiscountLimit);
        setDiscountMain(discountinfo.Discount[0].DiscountLimit);
        triggeredAlert('warning', 'You Trying to Exceed Max Limit');
      }
    }
  }
  const toggleCollapse = (index) => {
    index === open ? setOpen(-1) : setOpen(index);
  };
  const enterDiscount = (e, index) => {
    const newproductListInMainBody = [...productListInMainBody];
    newproductListInMainBody[index].discountPercentage = e.target.value;
    setproductListInMainBody(newproductListInMainBody);
  };
  function controlDiscount(e, index) {
    const newproductListInMainBody = [...productListInMainBody];
    if (
      parseFloat(
        e.target.value
          ? e.target.value
          : productListInMainBody[index].minDiscountPercentage
      ) >= parseFloat(productListInMainBody[index].minDiscountPercentage) &&
      parseFloat(
        e.target.value
          ? e.target.value
          : productListInMainBody[index].minDiscountPercentage
      ) <= parseFloat(productListInMainBody[index].maxDiscountPercentage)
    ) {
      newproductListInMainBody[index].discountPercentage = e.target.value;
      newproductListInMainBody[index].Tempdiscount =
        (newproductListInMainBody[index].discountPercentage *
          (productListInMainBody[index].Quantity *
            productListInMainBody[index].unitSalePrice)) /
        100;
      newproductListInMainBody[index].saleFinalPrice = (
        productListInMainBody[index].Quantity *
          productListInMainBody[index].unitSalePrice -
        newproductListInMainBody[index].Tempdiscount
      ).toFixed(2);
      setproductListInMainBody(newproductListInMainBody);
    } else if (
      parseFloat(
        e.target.value
          ? e.target.value
          : productListInMainBody[index].minDiscountPercentage
      ) <= parseFloat(productListInMainBody[index].minDiscountPercentage)
    ) {
      newproductListInMainBody[index].discountPercentage =
        productListInMainBody[index].minDiscountPercentage;

      newproductListInMainBody[index].Tempdiscount =
        (newproductListInMainBody[index].discountPercentage *
          (productListInMainBody[index].Quantity *
            productListInMainBody[index].unitSalePrice)) /
        100;
      newproductListInMainBody[index].saleFinalPrice =
        productListInMainBody[index].Quantity *
          productListInMainBody[index].unitSalePrice -
        newproductListInMainBody[index].Tempdiscount;
      setproductListInMainBody(newproductListInMainBody);

      setNotify({
        isOpen: true,
        message: 'You are Trying to Go Below Limit',
        type: 'warning',
        notificationTime: 5000,
      });
    } else if (
      parseFloat(
        e.target.value
          ? e.target.value
          : productListInMainBody[index].minDiscountPercentage
      ) >= parseFloat(productListInMainBody[index].maxDiscountPercentage)
    ) {
      newproductListInMainBody[index].discountPercentage =
        productListInMainBody[index].maxDiscountPercentage;

      newproductListInMainBody[index].Tempdiscount =
        (newproductListInMainBody[index].discountPercentage *
          (productListInMainBody[index].Quantity *
            productListInMainBody[index].unitSalePrice)) /
        100;
      newproductListInMainBody[index].saleFinalPrice = (
        productListInMainBody[index].Quantity *
          productListInMainBody[index].unitSalePrice -
        newproductListInMainBody[index].Tempdiscount
      ).toFixed(2);
      setproductListInMainBody(newproductListInMainBody);

      setNotify({
        isOpen: true,
        message: 'You are Trying to Exceed Max Limit',
        type: 'warning',
        notificationTime: 5000,
      });
    } else {
      newproductListInMainBody[index].discountPercentage =
        productListInMainBody[index].maxDiscountPercentage;

      newproductListInMainBody[index].Tempdiscount =
        (newproductListInMainBody[index].discountPercentage *
          (productListInMainBody[index].Quantity *
            productListInMainBody[index].unitSaleFinalPrice)) /
        100;
      newproductListInMainBody[index].saleFinalPrice =
        productListInMainBody[index].Quantity *
          productListInMainBody[index].unitSalePrice -
        newproductListInMainBody[index].Tempdiscount;
      setproductListInMainBody(newproductListInMainBody);

      setNotify({
        isOpen: true,
        message: 'You are Trying to Exceed Max Limit',
        type: 'warning',
        notificationTime: 5000,
      });
    }
    reCalculateFooter();
  }
  function controlQuantity(isAdd, index, _qty) {
    const newproductListInMainBody = [...productListInMainBody];
    const currQty = newproductListInMainBody[index].Quantity;
    const newQty =
      isAdd === true ? parseInt(currQty) + 1 : parseInt(currQty) - 1;
    const ProductKot = {
      _id: newproductListInMainBody[index]._id,
      name: newproductListInMainBody[index].name,
      barcode: newproductListInMainBody[index].barcode,
      Quantity: _qty,
    };
    if (isAdd) {
      selectProductForMainBody(
        findByProductId(newproductListInMainBody[index]._id)
      );
    } else {
      setKotValues(ProductKot, isAdd === true ? false : true);
    }

    if (newQty <= 0) {
      RemoveItemFromMainBody(newproductListInMainBody[index], index);
      setKotValues(newproductListInMainBody[index], true);
    } else {
      newproductListInMainBody[index].Quantity = newQty;
      if (productListInMainBody[index].ExternalDiscountApplied === 0) {
        productListInMainBody[index].Tempdiscount = (
          ((productListInMainBody[index].unitSaleFinalPrice *
            (productListInMainBody[index].discountPercentage
              ? productListInMainBody[index].discountPercentage
              : 0)) /
            100) *
          productListInMainBody[index].Quantity
        ).toFixed(2);
      }

      let discPercentProduct =
        productListInMainBody[index].discountPercentage || 0;
      let unitPrice = productListInMainBody[index].unitSalePrice || 0;
      let unitDisc = (unitPrice * discPercentProduct) / 100 || 0;
      let unitSalePriceAfterDiscount = unitPrice - unitDisc;
      productListInMainBody[index].Tempdiscount = (
        productListInMainBody[index].Quantity * unitDisc
      ).toFixed(2);
      productListInMainBody[index].saleFinalPrice = (
        productListInMainBody[index].Quantity * unitSalePriceAfterDiscount
      ).toFixed(2);

      setproductListInMainBody(newproductListInMainBody);
      reCalculateFooter();
    }
  }
  function getInvoiceNumber() {
    let newDate = new Date();

    let date =
      newDate.getFullYear() +
      '' +
      (newDate.getMonth() + 1) +
      '' +
      newDate.getDate() +
      '' +
      newDate.getHours() +
      '' +
      newDate.getMinutes() +
      '' +
      newDate.getSeconds();

    if (orderIdOdms !== '') {
      date = orderIdOdms;
    }

    return date;
  }
  function removeOrderLocalStorage() {
    let TempList = JSON.parse(localStorage.getItem('HoldOrder'));
    TempList.HoldOrders.splice(selectedOrderIndex, 1);
    localStorage.setItem('HoldOrder', JSON.stringify(TempList));
    setHoldOrder(TempList.HoldOrders);

    let TempListKot = JSON.parse(localStorage.getItem('KotOrder'));
    TempListKot.KotOrders.splice(selectedOrderIndex, 1);
    localStorage.setItem('KotOrder', JSON.stringify(TempListKot));
    setHoldOrder(TempListKot.KotOrders);
  }
  function Cash() {
    const ShiftData = JSON.parse(localStorage.getItem('_IdShift'));
    let ClientDateCreated = getDateTime();
    let date = getInvoiceNumber();

    const resultArray = JSON.parse(localStorage.getItem('Order'));

    let dealArr = getDealArray();

    const Order = {
      invoiceNumber: `${date}`,
      clientCreatedDate: ClientDateCreated,
      clientModifiedDate: ClientDateCreated,
      saleType: 'external',
      saleMode: 'cash',
      shiftNumber: ShiftData.shiftNumber,
      saleAmountSumOfItems: netTotal.toFixed(2),
      discount: discount.toFixed(2),
      overallSaleDiscountAmount: discountMain,

      saleAmountTotal: (
        amountWithoutTax.toFixed(2) -
        discount.toFixed(2) -
        discountMain
      ).toFixed(2),
      TotalQuantity: totalItems,
      itemsSold: productListInMainBody,
      dealsSold: dealArr,
      orderMode: orderModeName,
      rider: riderName,
      customerRef: customerinfo ? customerinfo._id : null,
      customerInfo: customerinfo,
      taxAmount: tax,
      subTotal: amountWithoutTax,
      waiter: waiterName,
      table: tableName,
      paidAmount:
        calValue === ''
          ? (
              amountWithoutTax.toFixed(2) -
              discount.toFixed(2) -
              discountMain +
              parseFloat(deliveryCharges || 0)
            ).toFixed(2)
          : calValue,
      returnAmount:
        calValue === ''
          ? 0
          : parseFloat(calValue) -
            (
              amountWithoutTax.toFixed(2) -
              discount.toFixed(2) -
              discountMain +
              parseFloat(deliveryCharges || 0)
            ).toFixed(2),
      deliveryCharges: deliveryCharges || 0,
      branchRef: branch,
    };

    resultArray.Order.push(Order);
    localStorage.setItem('Order', JSON.stringify(resultArray));
    sessionStorage.removeItem('Invoice');
    window.sessionStorage.setItem('Invoice', JSON.stringify(Order));

    changeOrderStatus(date, Order.customerInfo, 'paid');

    removeOrderLocalStorage();
    ResetEveryThing();
  }
  async function changeOrderStatus(orderId, customerRef, operation) {
    if (customerRef !== undefined) {
      let state;
      if (operation == 'kot') {
        state = 'inprocess';
      } else if (operation == 'cancel') {
        state = 'cancelled';
      } else if (operation == 'paid') {
        state = 'completed';
      } else if (operation == 'bill') {
        state = 'dispatched';
      }
      const ChangeStatus = {
        state: state,
        orderId: orderId,
        customerRef: customerRef._id,
      };

      if (ChangeStatus != undefined) {
        let res = await APIService.ChangeStatusRequest(ChangeStatus);

        if (res.data) {
          setNotify({
            isOpen: true,
            message: 'Order ' + operation + ' successfully',
            type: 'success',
            notificationTime: 5000,
          });
        }
      }
    }
  }
  function getDealArray() {
    let dealArr = [];
    productListInMainBody.forEach(function (item) {
      if (item.isDeal && item.dealItems != undefined) {
        if (item.dealItems.length > 0) {
          let dealItemArr = [];
          item.dealItems.forEach(function (dealItem) {
            const dealI = {
              itemRef: dealItem.itemRef,
              quantity: 1,
              additionalPrice: dealItem.additionalPrice,
            };
            dealItemArr.push(dealI);
          });
          const deal = {
            dealRef: item._id,
            dealPrice: item.salePrice,
            dealFinalPrice: item.saleFinalPrice,
            taxAmount: 0,
            quantity: item.Quantity,
            dealItems: dealItemArr,
          };
          dealArr.push(deal);
        }
      }
    });
    return dealArr;
  }
  function bookCallCenterOrder() {
    if (branch != '#') {
      let OrderExecution = OdmsOrderExecution();

      if (OrderExecution) {
        let ClientDateCreated = getDateTime();
        let date = getInvoiceNumber();
        let ordermode = orderModeValues.filter(
          (item) => item._id === orderMode.toString()
        )[0].nameValue;

        let dealArr = getDealArray();

        const Order = {
          invoiceNumber: `${date}`,
          clientCreatedDate: ClientDateCreated,
          clientModifiedDate: ClientDateCreated,
          saleType: ordermode,
          saleAmountSumOfItems: netTotal.toFixed(2),
          discount: discount.toFixed(2),
          overallSaleDiscountAmount: discountMain,
          saleAmountTotal: (
            netTotal.toFixed(2) -
            discount.toFixed(2) -
            discountMain
          ).toFixed(2),
          TotalQuantity: totalItems,
          itemsSold: productListInMainBody,
          dealsSold: dealArr,
          orderMode: orderModeName,
          customerRef: customerinfo ? customerinfo._id : null,
          customerInfo: customerinfo,
          taxAmount: tax,
          subTotal: amountWithoutTax,
          paidAmount:
            calValue === ''
              ? (
                  netTotal.toFixed(2) -
                  discount.toFixed(2) -
                  discountMain +
                  parseFloat(deliveryCharges || 0)
                ).toFixed(2)
              : calValue,
          returnAmount:
            calValue === ''
              ? 0
              : parseFloat(calValue) -
                (
                  netTotal.toFixed(2) -
                  discount.toFixed(2) -
                  discountMain +
                  parseFloat(deliveryCharges || 0)
                ).toFixed(2),
          deliveryCharges: deliveryCharges || 0,
          deliveryTime: deliveryTime,
          branchRef: branch,
          branchName: '',
          orderIdOdms: callCenterOrderNumber,
          specialNote: specialNote,
        };

        localStorage.removeItem('BookOrder');
        localStorage.setItem('BookOrder', JSON.stringify(Order));

        createOrder();
        ResetEveryThing();
        setBranch('#');
      } else {
        setNotify({
          isOpen: true,
          message: 'Order Modification Time Elapsed',
          type: 'error',
        });
      }
    } else {
      setNotify({
        isOpen: true,
        message: 'Please select a branch',
        type: 'warning',
        notificationTime: 5000,
      });
    }
  }
  async function createOrder() {
    let order = JSON.parse(localStorage.getItem('BookOrder'));
    if (order != undefined) {
      await APIService.OrderSyncRequest(order).then((response) => {
        if (response.data) {
          setNotify({
            isOpen: true,
            message: 'Order placed successfully',
            type: 'success',
            notificationTime: 5000,
          });
          localStorage.removeItem('bookOrder');
        }
      });
    }
  }
  function PayCredit() {
    const ShiftData = JSON.parse(localStorage.getItem('_IdShift'));
    let ClientDateCreated = getDateTime();
    let date = getInvoiceNumber();

    const resultArray = JSON.parse(localStorage.getItem('Order'));
    const Order = {
      invoiceNumber: `${date}`,
      clientCreatedDate: ClientDateCreated,
      clientModifiedDate: ClientDateCreated,
      saleType: 'external',
      saleMode: 'creditcard',
      shiftNumber: ShiftData.shiftNumber,
      saleAmountSumOfItems: netTotal.toFixed(2),
      discount: discount.toFixed(2),
      overallSaleDiscountAmount: discountMain.toFixed(2),
      saleAmountTotal: (
        netTotal.toFixed(2) -
        discount.toFixed(2) -
        discountMain.toFixed(2)
      ).toFixed(2),
      TotalQuantity: totalItems,
      itemsSold: productListInMainBody,
      orderMode: orderModeName,
      rider: riderName,
      customerRef: customerinfo ? customerinfo._id : null,
      customerInfo: customerinfo,
      taxAmount: tax,
      subTotal: amountWithoutTax,
      waiter: waiterName,
      table: tableName,
      paidAmount:
        calValue === ''
          ? (
              netTotal.toFixed(2) -
              discount.toFixed(2) -
              discountMain +
              parseFloat(deliveryCharges || 0)
            ).toFixed(2)
          : calValue,
      returnAmount:
        calValue === ''
          ? 0
          : parseFloat(calValue) -
            (
              netTotal.toFixed(2) -
              discount.toFixed(2) -
              discountMain +
              parseFloat(deliveryCharges || 0)
            ).toFixed(2),
      deliveryCharges: deliveryCharges || 0,
      branchRef: branch,
    };
    resultArray.Order.push(Order);
    localStorage.setItem('Order', JSON.stringify(resultArray));
    sessionStorage.removeItem('Invoice');
    window.sessionStorage.setItem('Invoice', JSON.stringify(Order));
    removeOrderLocalStorage();
    ResetEveryThing();
  }
  function PayCash() {
    ReactDOM.render(<Print />, document.getElementById('print-mount'));
    document.getElementById('print-button').click();
    ReactDOM.unmountComponentAtNode(document.getElementById('print-mount'));
    InvoiceSync();
  }
  function PrePaymentBill(rider = undefined) {
    const ShiftData = JSON.parse(localStorage.getItem('_IdShift'));
    let ClientDateCreated = getDateTime();
    let date = getInvoiceNumber();

    const Order = {
      invoiceNumber: `${date}`,
      clientCreatedDate: ClientDateCreated,
      clientModifiedDate: ClientDateCreated,
      saleType: 'external',
      saleMode: 'cash',
      shiftNumber: ShiftData.shiftNumber,
      saleAmountSumOfItems: netTotal.toFixed(2),
      discount: discount.toFixed(2),
      overallSaleDiscountAmount: discountMain,
      saleAmountTotal: (
        netTotal.toFixed(2) -
        discount.toFixed(2) -
        discountMain
      ).toFixed(2),
      TotalQuantity: totalItems,
      itemsSold: productListInMainBody,
      orderMode: orderModeName,
      rider: rider ? rider : riderName,
      customerRef: customerinfo ? customerinfo._id : null,
      customerInfo: customerinfo,
      taxAmount: tax,
      subTotal: amountWithoutTax,
      waiter: waiterName,
      table: tableName,
      paidAmount:
        calValue === ''
          ? parseFloat(
              netTotal.toFixed(2) -
                discount.toFixed(2) -
                discountMain +
                deliveryCharges || 0
            ).toFixed(2)
          : calValue,
      returnAmount:
        calValue === ''
          ? 0
          : parseFloat(calValue) -
            (
              netTotal.toFixed(2) -
                discount.toFixed(2) -
                discountMain +
                deliveryCharges || 0
            ).toFixed(2),
      deliveryCharges: deliveryCharges || 0,
      deliveryInstruction: specialNote || '',
    };

    changeOrderStatus(date, Order.customerInfo, 'bill');
    sessionStorage.removeItem('Invoice');
    window.sessionStorage.setItem('Invoice', JSON.stringify(Order));

    ReactDOM.render(
      <PrintPrePaymentBill />,
      document.getElementById('print-mount')
    );
    document.getElementById('print-button').click();
    ReactDOM.unmountComponentAtNode(document.getElementById('print-mount'));
  }
  function reCalculateFooter() {
    var ttlItems = 0;
    var amtWithoutTaxs = 0;
    var taxs = 0;
    var ttldiscount = 0;
    var netTotals = 0;
    productListInMainBody.forEach(function (item) {
      ttlItems = ttlItems + parseFloat(item.Quantity);
      amtWithoutTaxs =
        parseFloat(amtWithoutTaxs) +
        parseFloat(item.salePrice) * parseFloat(item.Quantity);
      taxs =
        taxs +
        (parseFloat(item.unitSaleFinalPrice) - parseFloat(item.unitSalePrice)) *
          parseFloat(item.Quantity);
      netTotals =
        parseFloat(netTotals) +
        parseFloat(item.unitSaleFinalPrice) * parseFloat(item.Quantity);
      ttldiscount = parseFloat(ttldiscount) + parseFloat(item.Tempdiscount);
    });
    setTotalItems(ttlItems);
    setAmountWithoutTax(amtWithoutTaxs);
    setTax(taxs);
    setDiscount(ttldiscount);
    setNetTotal(netTotals);
  }
  function RemoveItemFromMainBody(productList, index = 0) {
    setTotalItems(totalItems - productList.Quantity);
    setNetTotal(
      parseFloat(netTotal) -
        parseFloat(productList.unitSaleFinalPrice) *
          parseFloat(productList.Quantity)
    );
    setAmountWithoutTax(
      parseFloat(amountWithoutTax) -
        parseFloat(productList.unitSalePrice) * parseFloat(productList.Quantity)
    );
    setTax(
      parseFloat(tax) -
        (parseFloat(productList.unitSaleFinalPrice) *
          parseFloat(productList.Quantity) -
          parseFloat(productList.unitSalePrice) *
            parseFloat(productList.Quantity))
    );
    setDiscount(parseFloat(discount) - parseFloat(productList.Tempdiscount));

    let mainArr = [];
    let mainArrKot = [];
    productListInMainBody.map((item, idx) => {
      if (idx != index) {
        mainArr.push(item);
      }
    });

    kotList.map((item, idx) => {
      if (idx != index) {
        mainArrKot.push(item);
      }
    });

    setproductListInMainBody(mainArr);
    setKotList(mainArrKot);
  }
  function handleBarCodeScan(data) {
    const found = findByBarcode(data);
    if (found === null) {
      //do nothing just exit from loop
    } else {
      selectProductForMainBody(found);
    }
  }
  function findByBarcode(Barcode) {
    var result = null;

    const obj = categories.filter((a) => a._id != 0);
    for (const inobj of obj) {
      for (const innerobj of inobj.products) {
        if (innerobj.barcode === Barcode) {
          return innerobj;
        }
      }
    }
    return result;
  }
  function EndShiftSalesDetails(obj) {
    setRecords(obj);
    setEndShiftSaleDetail('none');
    setEndShiftSaleDetailTab('block');
  }
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function cancelOrder(index) {
    const ShiftData = JSON.parse(localStorage.getItem('_IdShift'));
    let ClientDateCreated = getDateTime();
    let date = getInvoiceNumber();
    const resultArray = JSON.parse(localStorage.getItem('Order'));

    let TempList = JSON.parse(localStorage.getItem('HoldOrder'));
    const Order = {
      invoiceNumber: `${date}`,
      clientCreatedDate: ClientDateCreated,
      clientModifiedDate: ClientDateCreated,
      saleType: 'external',
      saleMode: 'Cancel',
      shiftNumber: ShiftData.shiftNumber,
      saleAmountSumOfItems: TempList.HoldOrders[index].NetTotal.toFixed(2),
      discount: TempList.HoldOrders[index].Discount.toFixed(2),
      overallSaleDiscountAmount: discountMain,
      saleAmountTotal: (
        TempList.HoldOrders[index].NetTotal.toFixed(2) -
        TempList.HoldOrders[index].Discount.toFixed(2) -
        discountMain
      ).toFixed(2),
      TotalQuantity: TempList.HoldOrders[index].TotalItems,
      itemsSold: TempList.HoldOrders[index].Products[0],
      orderMode: TempList.HoldOrders[index].OrderModeName,
      rider: TempList.HoldOrders[index].Rider,
      customerRef: TempList.HoldOrders[index].Customer
        ? TempList.HoldOrders[index].Customer._id
        : null,
      customerInfo: TempList.HoldOrders[index].Customer,
      taxAmount: TempList.HoldOrders[index].Tax,
      subTotal: TempList.HoldOrders[index].AmountWithoutTax,
      waiter: TempList.HoldOrders[index].Waiter,
      table: TempList.HoldOrders[index].TableNo,
      cancelReason: cancelReason || value,
      branchRef: TempList.HoldOrders[index].branchRef,
    };
    resultArray.Order.push(Order);
    localStorage.setItem('Order', JSON.stringify(resultArray));
    sessionStorage.removeItem('Invoice');
    window.sessionStorage.setItem('Invoice', JSON.stringify(Order));

    ResetEveryThing();
  }

  function cancelSlip() {
    ReactDOM.render(<Print />, document.getElementById('print-mount'));
    document.getElementById('print-button').click();
    ReactDOM.unmountComponentAtNode(document.getElementById('print-mount'));
    InvoiceSync();
    removeOrderLocalStorage();
  }

  function addNote() {
    if (selectedOrderIndex >= 0) {
      let TempList = JSON.parse(localStorage.getItem('HoldOrder'));
      TempList.HoldOrders[selectedOrderIndex].specialNote = specialNote;
      localStorage.setItem('HoldOrder', JSON.stringify(TempList));
      setHoldOrder(TempList.HoldOrders);
    } else {
      localStorage.setItem('_note', specialNote);
    }
  }
  function onChangeCustomerContactNumber(e) {
    const re = /^[0-9\b]+$/;

    if (e.target.value === '' || re.test(e.target.value)) {
      setCustomerContactNumber(e.target.value);
    }
  }
  function onChangeCustomerName(e) {
    const re = /^[a-zA-Z ]*$/;

    if (e.target.value === '' || re.test(e.target.value)) {
      setCustomerName(e.target.value);
    }
  }
  function keyPressDownForSearchCustomer(e) {
    if (e.keyCode === 13) {
      searchCustomer(e.target.value);
    }
  }
  function searchCustomer(searchBy) {
    APIService.GetCustomerData(searchBy).then((Response) => {
      if (Response.length > 0) {
        setCustomerList(Response);
      } else {
        setNotify({
          isOpen: true,
          message: 'Customers Data not found',
          type: 'error',
        });
      }
    });

    setCustomerContactNumber(searchBy);
  }

  function checkActiveStatus(itemRef) {
    const obj = categories.filter((a) => a._id != 0);
    for (const inobj of obj) {
      for (const innerobj of inobj.products) {
        if (innerobj._id === itemRef._id) {
          if (innerobj.status === 'active') {
            return 'active';
          } else {
            return 'disabled';
          }
        }
      }
    }
  }
  function isAreaEnabled(customer) {
    let addressListPrimary = customer.addresses.filter(
      (item) => item.isPrimary && item.addressType === 'shipping'
    )[0];

    if (addressListPrimary) {
      let areaId =
        addressListPrimary.areaRef && addressListPrimary.areaRef._id
          ? addressListPrimary.areaRef._id
          : addressListPrimary.areaRef;
      // condition for users having one branch and
      // don't want city area at customer creation time.
      if (areaId === null) {
        return true;
      }
      let areaEnabled = branchAreaData.filter(
        (areaItem) => areaItem._id == areaId && areaItem.status === 'active'
      );

      if (areaEnabled == undefined || areaEnabled.length == 0) {
        return false;
      }
      return true;
    }
  }

  function handleCustomerAppend(customer) {
    setCustomerinfo(customer);
    setArea(customer.areaRef || '#');
    setIsCustomerAttached(true);
    handleCloseNewCustomerModal();
    handleCloseCustomerModal();
    handleCloseNewCustomerModalComp();
    handleCloseSearchCustomerModalComp();

    let addressListPrimary = customer.addresses.filter(
      (item) => item.isPrimary && item.addressType === 'shipping'
    )[0];

    if (addressListPrimary) {
      let areaId =
        addressListPrimary.areaRef && addressListPrimary.areaRef._id
          ? addressListPrimary.areaRef._id
          : addressListPrimary.areaRef;
      // condition for users having one branch and
      // don't want city area at customer creation time.
      if (areaId === null) {
        return;
      }
      setArea(areaId);
      let areaD = branchAreaData.filter(
        (item) => item._id === areaId && item.status === 'active'
      );

      if (areaD == undefined) {
        setBranch('#');
      } else {
        var inc = 0;

        let mapping = areaD[0].branchMapping;

        if (mapping != undefined && mapping.length > 0) {
          mapping.forEach((branchMapping) => {
            if (branchMapping !== null) {
              if (checkBranchEnable(branchMapping.branchRef)) {
                if (inc === 0) {
                  if (orderMode == 2) {
                    setDeliveryCharges(branchMapping.deliveryCharges);
                    setDeliveryTime(branchMapping.deliveryTime);
                  } else {
                    setDeliveryCharges(0);
                    setDeliveryTime(0);
                  }
                  setBranch(
                    branchMapping.branchRef ||
                      (branchData.length > 0 ? branchData[0]._id : getBranch())
                  );
                  inc = inc + 1;
                }
              }
            }
          });
        } else {
          setBranch('#');
        }
      }
    }
    setCustomerResponse(null);
  }
  function handleCustomerremove() {
    setCustomerinfo();
    setCustomerList([]);
    setIsCustomerAttached(false);
  }
  function checkoutButtonClick() {
    if (branch !== '#') {
      setProcessVia('checkout');
      selectModal();
    } else {
      setNotify({
        isOpen: true,
        message: 'Please select a branch',
        type: 'warning',
        notificationTime: 5000,
      });
    }
  }
  function newOrderButtonClick() {
    if (productListInMainBody.length == 0) {
      setProcessVia('neworder');
      setNewOrder(true);
      newOrder();
    }
  }
  const openNewCustomerModalComp = () => {
    setOpenNewCustomerComp(true);
  };

  const handleCloseNewCustomerModalComp = () => {
    setOpenNewCustomerComp(false);
  };
  const handleCloseSearchCustomerModalComp = () => {
    setOpenSearchCustomerComp(false);
  };
  const handleCloseCustomerModal = () => {
    setOpenCustomer(false);
  };
  const openCustomerModal = () => {
    setOpenSearchCustomerComp(true);
  };
  const handleCloseNewCustomerModal = () => {
    setOpenNewCustomer(false);
  };
  const openNewCustomerModal = () => {
    setOpenNewCustomer(true);
  };
  const handleCloseRiderModal = () => {
    setOpenRider(false);
  };
  const openRiderModal = () => {
    setOpenRider(true);
  };
  const handleCloseWaiterModal = () => {
    setOpenWaiter(false);
  };
  const openWaiterModal = () => {
    setOpenWaiter(true);
  };
  const openCancelOrderModal = () => {
    setCancelOrder(true);
  };
  const handleCloseCancelOrderModal = () => {
    setCancelOrder(false);
  };
  const openSpecialNoteModal = () => {
    setOpenSpecialNote(true);
  };
  const handleCloseSpecialNoteModal = () => {
    setOpenSpecialNote(false);
  };
  const handleCloseTableModal = () => {
    setOpenTable(false);
  };
  const openTableModal = () => {
    setOpenTable(true);
  };
  const handleDealManagementModal = () => {
    setOpenDealManagement(true);
  };
  const closeDealManagementModal = () => {
    setOpenDealManagement(false);
    setDealManagement2ndBodyItem([]);
    setDealItemListing([]);
    setDealLimitCheck([]);
  };
  const addDealToPOS = () => {
    if (
      dealManagementValues[0].dealOptions.filter(
        (item, index) => item.maxItemLimit - dealLimitCheck[index] !== 0
      ).length > 0
    ) {
      setNotify({
        isOpen: true,
        message: 'Please complete the deal',
        type: 'warning',
        notificationTime: 5000,
      });
    } else {
      let sumAdditionalPrice = dealItemListing.reduce(function (prev, current) {
        return prev + +current.additionalPrice;
      }, 0);

      const dealResult = {
        _id: dealManagementValues[0]._id,
        name: dealManagementValues[0].name,
        salePrice: dealManagementValues[0].dealPrice + sumAdditionalPrice,
        saleFinalPrice:
          dealManagementValues[0].dealFinalPrice + sumAdditionalPrice,
        unitSaleFinalPrice:
          dealManagementValues[0].dealFinalPrice + sumAdditionalPrice,
        isDeal: true,
        barcode: null,
        Quantity: 1,
        unitQuantity: 1,
        unitSalePrice: dealManagementValues[0].dealPrice + sumAdditionalPrice,
        taxPercentage: dealManagementValues[0].dealTaxPercentage,
        Tempdiscount: 0,
        minDiscountPercentage: 0,
        maxDiscountPercentage: 0,
        dealItems: dealItemListing,
      };

      selectProductForMainBody(dealResult);
      closeDealManagementModal();
    }
  };

  const dealManagementListingTable = () => (
    <TableContainer className={classes.container}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {['Items', 'Additional Price', 'Action'].map((column) => (
              <TableCell key={column}>{column}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody id="foodDealOptionsTbody">
          {dealItemListing ? (
            dealItemListing.map((row, index) => (
              <TableRow key={row._id}>
                <TableCell key={row.id}>{row.itemRef.name}</TableCell>
                <TableCell key={row.id}>{row.additionalPrice}</TableCell>
                <TableCell key={row.id}>
                  <IconButton
                    id={`foodDealOptionsRemoveItem${index}`}
                    onClick={() => {
                      --dealLimitCheck[row.dealIndex];
                      setDealLimitCheck(dealLimitCheck);
                      const deleteItem = dealItemListing.filter(
                        (item, ind) => index !== ind
                      );
                      setDealItemListing(deleteItem);
                    }}
                  >
                    <CancelIcon fontSize="medium" color="secondary" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell>Shahid</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
  const dealManagement2ndBodyItems = () =>
    dealManagement2ndBodyItem.map((tile, index) => (
      <GridListTile key={'hello' + index} cols={1}>
        <Card
          key={tile._id}
          className={classes.rootItem}
          style={{ height: 'auto', width: 120 }}
          id={'lst_id_prod_' + index}
          onClick={() => {
            if (checkActiveStatus(tile.itemRef) === 'active') {
              if (
                dealLimitCheck[tile.dealIndex] ===
                dealManagementValues[0].dealOptions[tile.dealIndex].maxItemLimit
              ) {
                setNotify({
                  isOpen: true,
                  message: 'You cannot add more items from this deal',
                  type: 'warning',
                  notificationTime: 5000,
                });
              } else {
                dealLimitCheck[tile.dealIndex] =
                  dealLimitCheck[tile.dealIndex] + 1 || 1;
                setDealLimitCheck(dealLimitCheck);
                setDealItemListing([...dealItemListing, tile]);
              }
            } else {
              setNotify({
                isOpen: true,
                message: 'Product is disabled',
                type: 'warning',
                notificationTime: 5000,
              });
            }
          }}
        >
          <CardMedia
            className={classes.media}
            image="https://material-ui.com/static/images/cards/paella.jpg"
          />
          <CardContent className={classes.con1}>
            <Typography variant="body2" component="body2" align="center">
              Rs: {Math.round(tile.additionalPrice)}
            </Typography>
          </CardContent>
          <CardContent className={classes.con2} style={{ paddingBottom: 0 }}>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              align="center"
              style={{ wordWrap: 'break-word' }}
            >
              {tile.itemRef.name}
            </Typography>
          </CardContent>
        </Card>
      </GridListTile>
    ));

  const modleOpenNayaPay = () => {
    if (!customerinfo && 1 == 2) {
      setNotify({
        isOpen: true,
        message: 'Customer did not attached.',
        type: 'warning',
        notificationTime: 3000,
      });
    } else setOpenNayaPay(true);
  };
  const modleCloseNayaPay = () => {
    setOpenNayaPay(false);
  };
  function nayaPay(invoiceNumber, a) {
    const ShiftData = JSON.parse(localStorage.getItem('_IdShift'));
    let ClientDateCreated = getDateTime();

    const Order = {
      invoiceNumber: invoiceNumber,
      clientCreatedDate: ClientDateCreated,
      clientModifiedDate: ClientDateCreated,
      saleType: 'external',
      saleMode: 'nayapay',
      shiftNumber: ShiftData.shiftNumber,
      saleAmountSumOfItems: parseFloat(netTotal).toFixed(2),
      discount: parseFloat(discount).toFixed(2),
      overallSaleDiscountAmount: discountMain,
      saleAmountTotal: (netTotal - discount - discountMain).toFixed(2),
      TotalQuantity: totalItems,
      itemsSold: productListInMainBody,
      totalTaxAmount: tax,
      customerRef: customerinfo ? customerinfo._id : null,
      customerInfo: customerinfo,
      QRCode: a,
    };

    sessionStorage.removeItem('Invoice');
    window.sessionStorage.setItem('Invoice', JSON.stringify(Order));
  }
  function GenerateNayaPayInvoice() {
    setGenerateQr(false);
    modleCloseNayaPay();

    PrePaymentBill();
  }
  const [jwt] = useState(
    JSON.parse(window.localStorage.getItem('NayaPayActivation'))
  );
  function NayaPayQRCodeGenerator() {
    let date = getInvoiceNumber();
    let cash = (netTotal - discount).toFixed(2);
    date = date.substring(date.length - 10, date.length);

    const a = generateRetailDynamicCode(
      jwt ? (jwt.merchantName ? jwt.merchantName : null) : null, //Merchant Name
      jwt ? (jwt.merchantID ? jwt.merchantID : null) : null, //Merchant ID
      date, //Invoice Number
      null, //Currency Code
      cash, //Total Amount
      false, //Tip Flag
      false, //Tip Flat Flag
      0, //Tip Value
      'terminal-001', //Terminal ID
      'nayapay77@testing.com' // NayaPayID
    );
    nayaPay(date, a);
    setNayaPayQRCode(a);
  }
  function handleGenerateQRCode() {
    NayaPayQRCodeGenerator();
    setGenerateQr(true);
  }
  async function NayaPayPushInvoice(invoiceId, consumer, amount) {
    return APIService.NayaPayPushInvoice(invoiceId, consumer, amount).then(
      (Responce) => {
        return Responce;
      }
    );
  }
  function handleNayaPayPushInvoice() {
    let cash = (netTotal - discount).toFixed(2);
    try {
      if (nayaPayId.length > 4) {
        NayaPayPushInvoice(invoiceNo, nayaPayId, cash).then((Response) => {
          if (Response.success) {
            setGeneratePushInvoice(false);
            nayaPay(invoiceNo, null);
            GenerateNayaPayInvoice();
            setNotify({
              isOpen: true,
              message: 'Invoice has been Pushed',
              type: 'success',
              notificationTime: 5000,
            });
          } else {
            setNotify({
              isOpen: true,
              message: `${Response.errors[0].error}`,
              type: 'error',
              notificationTime: 5000,
            });
          }
        });
      } else {
        setNotify({
          isOpen: true,
          message: `Enter Valid Naya Pay ID`,
          type: 'warning',
          notificationTime: 5000,
        });
      }
    } catch (e) {
      //do nothing
    }
  }
  function handleCallPushInvoice() {
    let date = getInvoiceNumber();
    date = date.substring(date.length - 10, date.length);
    setInvoiceNo(date);
    setGeneratePushInvoice(true);
  }
  async function addNewCustomer() {
    try {
      let branchArray = JSON.parse(localStorage.getItem('Branch')) || [];
      let validationMsg = 'Please Enter Customer Name, Contact Number and Area';
      if (branchArray.length <= 1) {
        validationMsg = 'Please Enter Customer Name and Contact Number';
      }
      if (
        orderModeName &&
        customerName != '' &&
        customerContactNumber != '' &&
        (branchArray.length > 1 ? area !== '' && area !== '#' : true)
      ) {
        let ResponceCust = {
          data: null,
          error: null,
          loading: false,
        };

        let customer = {
          _id: 0,
          name: customerName,
          CustomerType: 'walkin',
          city: city,
          cityRef: city,
          status: true,
          customerId: '0',
          contactNumber: customerContactNumber,
          email: customerEmail == '' ? '' : customerEmail,
          billingAddress: customerAddress || '',
          shippingAddress: customerAddress || '',
          nearestLandMark: customerLandmark == '' ? '' : customerLandmark,
          creditLimit: '#',
          openingAmount: 0,
          area: area == '#' ? '' : area,
          areaRef: area,
        };

        ResponceCust.data = null;
        ResponceCust.error = null;
        ResponceCust = await CustomerService.insertCustomer(customer).then(
          (rc) => {
            return rc;
          }
        );
        if (ResponceCust.data) {
          handleCustomerAppend(ResponceCust.data.data);
          setNotify({
            isOpen: true,
            message: 'Customer Created Successfully',
            type: 'success',
          });
        }

        if (ResponceCust.error) {
          setNotify({
            isOpen: true,
            message: ResponceCust.messege,
            type: 'error',
          });
        }
      } else {
        setNotify({
          isOpen: true,
          message: validationMsg,
          type: 'error',
        });
      }
    } catch (e) {
      setNotify({
        isOpen: true,
        message: 'Unable To Process Request',
        type: 'error',
      });
    }
  }
  const QRCodex = (
    <>
      <QRCode value={nayaPayQRCode} size={250} />
      <div
        style={{
          display: 'flex',
          marginTop: '25px',
        }}
      >
        <Button
          variant="contained"
          onClick={() => setGenerateQr(false)}
          id="btnPOSGenerateQRCode"
          style={{
            marginRight: 'auto',
            marginLeft: '5px',
          }}
          color="primary"
        >
          Back
        </Button>
        <Button
          variant="contained"
          onClick={() => GenerateNayaPayInvoice()}
          id="btnPOSGenerateQRCode"
          style={{
            marginRight: '5px',
            marginLeft: 'auto',
          }}
          color="primary"
        >
          Print Invoice
        </Button>
      </div>
    </>
  );
  function selectModal() {
    if (processVia !== 'neworder') {
      if (orderMode == 1) {
        handleOpen6();
      } else {
        if (orderMode == 2) {
          if (customerinfo === undefined) {
            openCustomerModal();
          } else if (riderName === undefined) {
            openRiderModal();
          } else {
            if (productListInMainBody.length > 0) {
              if (kotList.filter((a) => a.isKot == false).length == 0) {
                handleOpen6();
              }
            }
          }
        } else {
          if (waiterName === undefined) {
            openWaiterModal();
          } else if (tableName === undefined) {
            openTableModal();
          } else {
            if (productListInMainBody.length > 0) {
              if (kotList.filter((a) => a.isKot == false).length == 0) {
                handleOpen6();
              }
            }
          }
        }
      }
    }
  }
  let apiResponseDto = {
    data: [],
    error: null,
    loading: false,
    statusCode: 0,
  };
  function newOrder() {
    if (
      (isOdms && (orderMode === '2' || orderMode === 1)) ||
      (!isOdms && orderMode === '2')
    ) {
      openCustomerModal();
    } else if (orderMode === '3' && !isOdms) {
      openWaiterModal();
    }
    let selectedOrderModeName = orderModeName;
    let selectedOrderMode = orderMode;
    let selectedIsNewOrder = true;

    ResetEveryThing();
    setOrderModeName(selectedOrderModeName);
    setOrderMode(selectedOrderMode);
    setNewOrder(selectedIsNewOrder);
  }
  function cancel(idx, orderDate) {
    let reason = localStorage.getItem('CancelOrderReason');
    let cancelTime = localStorage.getItem('Cancel Order Time');

    if (reason === undefined || reason === 'default') {
      setNotify({
        isOpen: true,
        message: 'No cancel order reason found',
        type: 'error',
      });
    } else {
      cancelOrderReasonList = JSON.parse(
        localStorage.getItem('CancelOrderReason')
      );

      cancelOrderReasonList
        .filter((a) => a.status == 'active')
        .forEach(function (item) {
          CancelReasonData.push(item.reason);
        });

      setCancelReasonData(CancelReasonData);
      setValue(CancelReasonData[0]);

      if (cancelTime === undefined || cancelTime === 'default') {
        setCancelOrder(true);
        setSelectedOrderIndex(idx);
      } else {
        let mins = JSON.parse(localStorage.getItem('Cancel Order Time'))[0]
          .cancelOrderTime;

        if (mins === '0') {
          setNotify({
            isOpen: true,
            message: 'Cancel order restriction',
            type: 'warning',
          });
        } else if (mins === '') {
          setCancelOrder(true);
          setSelectedOrderIndex(idx);
        } else {
          diffMinutes(mins, idx, orderDate);
        }
      }
    }
  }
  function diffMinutes(mins, idx, orderDate) {
    let diffInMins = getDifferenceInMinutes(new Date(orderDate), new Date());

    if (diffInMins <= mins) {
      setCancelOrder(true);
      setSelectedOrderIndex(idx);
    } else {
      setNotify({
        isOpen: true,
        message: 'Unable to cancel the order',
        type: 'warning',
      });
    }
  }
  let options = [
    { key: 'credit', label: 'CREDIT' },
    { key: 'cashondelivery', label: 'COD' },
  ];
  function PrintEndShift() {
    if (
      expectedAmount !== '' &&
      closingAmount !== '' &&
      internalSaleAmount !== ''
    ) {
      options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      };
      const ShiftData = JSON.parse(localStorage.getItem('_IdShift'));
      const EndShiftData = {
        startDate: new Date(ShiftData.createdAt).toLocaleString(
          undefined,
          options
        ),
        endDate: new Date(ShiftData.updatedAt).toLocaleString(
          undefined,
          options
        ),
        expectedAmount: parseFloat(expectedAmount).toLocaleString('en', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        closingAmount: parseFloat(closingAmount).toLocaleString('en', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        internalSale: parseFloat(internalSaleAmount).toLocaleString('en', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        difference: (expectedAmount - closingAmount).toFixed(2),
        comments: comments,
      };
      localStorage.setItem('ShiftReport', JSON.stringify(EndShiftData));
      ReactDOM.render(
        <EndShiftPrint />,
        document.getElementById('print-mount')
      );
      document.getElementById('print-button').click();
      ReactDOM.unmountComponentAtNode(document.getElementById('print-mount'));
    }
  }
  function ShowKotCheckoutButton() {
    let ItemsForKot = kotList.filter((item) => !item.isKot).length;
    if (ItemsForKot > 0) {
      return (
        <KotButton
          classes={classes}
          totalItems={totalItems}
          sendKot={sendKot}
          isOdms={isOdms}
        />
      );
    } else {
      return (
        <CheckoutButton
          classes={classes}
          kotList={kotList}
          checkoutButtonClick={checkoutButtonClick}
        />
      );
    }
  }
  const body3 = (
    <div style={modalStyle} className={classes.paper2}>
      <h2 id="simple-modal-title">Running Orders</h2>
      <IconButton
        style={{
          top: '0px',
          right: '0px',
          position: 'absolute',
        }}
        color="inherit"
        aria-label="menu"
        onClick={handleClose3}
        id="btnFoodRunningOrderClose"
      >
        <ClearIcon />
      </IconButton>
      <div style={{ height: '350px', overflowY: 'scroll' }}>
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Date</th>
              <th>Order Number</th>
              <th>Order Mode</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {holdOrder.length <= 0 ? 'No running order.' : ''}

            {holdOrder.map(function (item, index) {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.Date}</td>
                  <td>{item.HoldOrderNumber}</td>
                  <td>{item.OrderModeName}</td>
                  <td>
                    <ButtonGroup
                      variant="outlined"
                      color="primary"
                      aria-label="contained primary button group"
                      style={{ marginRight: '5px', marginLeft: 'auto' }}
                    >
                      <Button
                        onClick={() => HoldOrderReload(index)}
                        id={'btnFoodRunningRecallOrder' + index}
                      >
                        <ReplayIcon />
                      </Button>
                      <Button
                        onClick={() => {
                          cancel(index, item.Date);
                        }}
                        color="secondary"
                        id={'btnFoodRunningRemoveOrder' + index}
                        disabled={!shiftButton}
                      >
                        <ClearOutlinedIcon />
                      </Button>
                    </ButtonGroup>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );

  function getListOfQueuedOrders() {
    return localStorage.getItem('QueuedOrders') === null
      ? []
      : JSON.parse(localStorage.getItem('QueuedOrders'));
  }

  let queuedOrderList = getListOfQueuedOrders();
  const [state, setState] = React.useState({
    kotToggle: false,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    localStorage.setItem('directKOTState', event.target.checked);
  };
  const bodyCallCenterOrdersQueue = (
    <div style={modalStyle} className={classes.paper2}>
      <h2 id="simple-modal-title">Queued Orders</h2>
      <Typography variant="subtitle2" gutterBottom key={'directPrintKot'}>
        {'Auto Kot : '}

        <Switch
          checked={state.kotToggle}
          onChange={handleChange}
          color="primary"
          name="kotToggle"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </Typography>
      <IconButton
        style={{
          top: '0px',
          right: '0px',
          position: 'absolute',
        }}
        color="inherit"
        aria-label="menu"
        onClick={handleCloseQueuedOrders}
        id="btnFoodQueuedOrderClose"
      >
        <ClearIcon />
      </IconButton>
      <div style={{ height: '350px', overflowY: 'scroll' }}>
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Date</th>
              <th>Order Number</th>
              <th>Order Mode</th>
              <th>Order Status</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {queuedOrderList.length <= 0 ? 'No queued order.' : ''}

            {queuedOrderList.map(function (item, index) {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{ISOdateFormatter(item.History[0].createdAt)}</td>
                  <td>{item._id}</td>
                  <td>{item.saleDetails.saleType}</td>

                  <td>{item.History[0].state || 'none'}</td>
                  <td>
                    <ButtonGroup
                      variant="outlined"
                      color="primary"
                      aria-label="contained primary button group"
                      style={{ marginRight: '5px', marginLeft: 'auto' }}
                    >
                      <Button
                        onClick={() => QueueOrderReload(queuedOrderList[index])}
                        id={'btnFoodRunningRecallOrder' + index}
                      >
                        <ReplayIcon />
                      </Button>
                      <Button
                        onClick={() => {
                          changeOrderStatus(
                            item._id,
                            item.customerRef,
                            'cancel'
                          );
                        }}
                        color="secondary"
                        id={'btnFoodRunningRemoveOrder' + index}
                      >
                        <ClearOutlinedIcon />
                      </Button>
                    </ButtonGroup>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
  const body4 = (
    <div
      style={{
        height: '200px',
        width: '500px',
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -50%)`,
      }}
      className={classes.paper2}
    >
      <h2 id="simple-modal-title">Opening Amount</h2>
      <div
        className={classes.flex}
        style={{
          textAlign: 'center',
        }}
      >
        <TextField
          id="txtFoodOpeningAmt"
          label="Opening Amount"
          variant="outlined"
          color="secondary"
          value={openingamount}
          onChange={(e) => {
            const re = /^[0-9\b]+$/;
            if (e.target.value === '' || re.test(e.target.value)) {
              setOpeningamount(e.target.value);
            }
          }}
          style={{
            marginLeft: 'auto',
          }}
        />
        <Button
          variant="contained"
          onClick={handleOpeningAmount}
          id="btnFoodOpeningAmt"
          style={{
            display: 'flex',
            width: '100px',
            marginRight: 'auto',
            marginLeft: '10px',
          }}
          color="primary"
        >
          OK
        </Button>
      </div>
    </div>
  );
  const body5 = (
    <div style={modalStyle} className={classes.paper2}>
      <h2 id="simple-modal-title">End Shift</h2>
      <IconButton
        style={{
          top: '0px',
          right: '0px',
          position: 'absolute',
        }}
        color="inherit"
        aria-label="menu"
        onClick={handleClose5}
        id="btnFoodEndShiftClose"
      >
        <ClearIcon />
      </IconButton>
      <div style={{ display: endShiftSaleDetail }}>
        <div style={{ display: 'flex' }}>
          <div>
            <TextField
              id="txtFoodEndShiftExpectedAmt"
              label="Expected Amount"
              variant="outlined"
              color="secondary"
              value={expectedAmount}
              margin="normal"
              InputProps={{
                classes: {
                  input: classes.TextFieldresize,
                },
              }}
            />
            <br />
            <TextField
              id="txtFoodEndShiftClosingAmt"
              label="Closing Amount"
              variant="outlined"
              color="secondary"
              margin="normal"
              value={closingAmount}
              onChange={(e) => setClosingAmount(e.target.value)}
              InputProps={{
                classes: {
                  input: classes.TextFieldresize,
                },
              }}
            />
            <br />
            <TextField
              id="txtFoodEndShiftInternalSales"
              label="Internal Sales"
              variant="outlined"
              color="secondary"
              margin="normal"
              value={internalSaleAmount}
              onChange={(e) => setInternalSaleAmount(e.target.value)}
              InputProps={{
                classes: {
                  input: classes.TextFieldresize,
                },
              }}
            />
            <br />
            <TextField
              id="txtFoodEndShiftDifference"
              label="Difference"
              variant="outlined"
              color="secondary"
              margin="normal"
              value={(
                parseFloat(expectedAmount === '' ? '0' : expectedAmount) -
                parseFloat(closingAmount === '' ? '0' : closingAmount)
              ).toFixed(2)}
              InputProps={{
                classes: {
                  input: classes.TextFieldresize,
                },
              }}
            />
            <br />
            <TextField
              id="txtFoodEndShiftComments"
              margin="normal"
              label="Comments"
              variant="outlined"
              color="secondary"
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              InputProps={{
                classes: {
                  input: classes.TextFieldresize,
                },
              }}
            />
            <br />

            <div
              className={classes.flex}
              style={{
                bottom: '15px',
                position: 'fixed',
                width: '94%',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  marginRight: 'auto',
                  marginLeft: '5px',
                  height: '50px',
                  width: '150px',
                }}
                onClick={handleShiftEnd}
                id="btnFoodEndShiftSave"
              >
                End Shift
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<PrintIcon />}
                style={{
                  height: '40px',
                  width: 'auto',
                  top: '10px',
                  position: 'relative',
                  padding: '0px 25px 0px !important',
                }}
                onClick={PrintEndShift}
                id="btnPOSEndShiftSave"
              >
                Print
              </Button>
              {!isLoaded ? (
                <Button
                  variant="contained"
                  style={{
                    marginLeft: 'auto',
                    marginRight: '5px',
                    height: '50px',
                    width: '150px',
                  }}
                  onClick={fetchSales}
                  id="btnFoodEndShiftViewSales"
                >
                  View Sales
                </Button>
              ) : (
                <Button
                  variant="contained"
                  style={{
                    marginLeft: 'auto',
                    marginRight: '5px',
                    height: '50px',
                    width: '150px',
                  }}
                  onClick={hideSales}
                  id="btnFoodEndShiftHideSales"
                >
                  Hide Sales
                </Button>
              )}
            </div>
          </div>

          {isLoaded ? (
            <div
              style={{
                width: '100%',
                display: display,
                overflowX: 'hidden',
                overflowY: 'scroll',
                height: '500px',
              }}
            >
              <TableContainer className={classes.tableContent}>
                <Table
                  id="foodEndShiftSalesTable"
                  className={classes.toTable}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell>Date & Time</TableCell>
                      <TableCell align="center">Net Sale Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody id="foodEndShiftSalesTbody">
                    {rows.map((row, index) => (
                      <TableRow
                        key={index}
                        id={'foodEndShiftSalesTrow' + index}
                        onClick={() => EndShiftSalesDetails(row)}
                      >
                        <TableCell>{index + 1}</TableCell>
                        {row.clientCreatedDate ? (
                          <TableCell>
                            {ISOdateFormatter(row.clientCreatedDate)}
                          </TableCell>
                        ) : (
                          <TableCell>
                            {ISOdateFormatter(row.createdAt)}
                          </TableCell>
                        )}
                        <TableCell align="center">
                          Rs. {row.saleAmountTotal}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {endShiftSaleDetailTab === 'block' ? (
        <div style={{ display: endShiftSaleDetailTab }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <TextField
                disabled
                name="invoiceNumber"
                label="invoice Number"
                variant="outlined"
                size="small"
                value={records.invoiceNumber}
                InputProps={{
                  readOnly: true,
                  disabled: true,
                }}
                id="txtEndShiftSalesInvoiceNo"
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                disabled
                name="shiftNumber"
                label="Shift Number"
                variant="outlined"
                size="small"
                value={records.shiftNumber}
                InputProps={{
                  readOnly: true,
                  disabled: true,
                }}
                id="txtEndShiftSalesShiftNo"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                disabled
                name="saleAmountTotal"
                label="Sale Total Amount"
                variant="outlined"
                size="small"
                value={records.saleAmountTotal}
                InputProps={{
                  readOnly: true,
                  disabled: true,
                  startAdornment: (
                    <InputAdornment position="start">Rs.</InputAdornment>
                  ),
                }}
                id="txtEndShiftSalesSaleAmtTotal"
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                disabled
                name="overallSaleDiscountAmount"
                label="Sale Total Discount Amount"
                variant="outlined"
                size="small"
                value={records.overallSaleDiscountAmount}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Rs.</InputAdornment>
                  ),
                  readOnly: true,
                  disabled: true,
                }}
                id="txtEndShiftSalesOverallSaleDiscAmt"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                disabled
                name="saleMode"
                label="Sale Mode"
                variant="outlined"
                size="small"
                value={records.saleMode}
                InputProps={{
                  readOnly: true,
                  disabled: true,
                }}
                id="txtEndShiftSalesSaleMode"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                disabled
                name="saleType"
                label="Sale Type"
                variant="outlined"
                size="small"
                value={records.saleType}
                InputProps={{
                  readOnly: true,
                  disabled: true,
                }}
                id="txtEndShiftSalesSaleType"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                disabled
                name="saleAmountSumOfItems"
                variant="outlined"
                label="Sale Amount Sum Of Items"
                size="small"
                value={records.saleAmountSumOfItems}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Rs.</InputAdornment>
                  ),
                  readOnly: true,
                  disabled: true,
                }}
                id="txtEndShiftSalesSaleAmtSumOfItems"
              />
            </Grid>
          </Grid>
          <h2>Items Sold</h2>
          <TableContainer
            style={{
              height: '330px',
            }}
          >
            <Table
              id="endShiftSalesTable"
              className={classes.toTable}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Item Name</TableCell>
                  <TableCell>Item Category</TableCell>
                  <TableCell align="center">Qty Sold</TableCell>
                  <TableCell align="center">Discount Price</TableCell>
                  <TableCell align="center">Final Sale Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody id="endShiftSalesTbody">
                {records.itemsSold.map((item, index) => (
                  <TableRow key={item._id} id={`endShiftSalesTrow${index}`}>
                    <TableCell>{item.itemRef.name}</TableCell>
                    <TableCell>{item.itemRef.categoriesRef[0].name}</TableCell>
                    <TableCell align="center">{item.quantity}</TableCell>
                    <TableCell align="center">
                      Rs. {item.discountAmount}
                    </TableCell>
                    <TableCell align="center">
                      Rs. {item.saleAmountTotal}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid item xs={3}>
            <Button
              variant="contained"
              style={{
                marginLeft: 'auto',
                marginRight: '5px',
                marginTop: '15px',
                height: '50px',
                width: '150px',
              }}
              onClick={endShiftSaleDetailTabBack}
              id="btnEndShiftSalesBack"
            >
              Back
            </Button>
          </Grid>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
  const body6 = (
    <div style={modalStyle} className={classes.paper2}>
      <h2 id="simple-modal-title">Checkout</h2>
      <IconButton
        style={{
          top: '0px',
          right: '0px',
          position: 'absolute',
        }}
        color="inherit"
        aria-label="menu"
        onClick={handleClose6}
        id="btnFoodPaymentClose"
      >
        <ClearIcon />
      </IconButton>
      <Grid container spacing={3}>
        <Grid item sm={4}>
          <Button
            variant="contained"
            color="primary"
            id="calSeven"
            className={classes.CalButton}
            onClick={() => cal('7')}
          >
            7
          </Button>
          <Button
            variant="contained"
            color="primary"
            id="calEight"
            className={classes.CalButton}
            onClick={() => cal('8')}
          >
            8
          </Button>
          <Button
            variant="contained"
            color="primary"
            id="calNine"
            className={classes.CalButton}
            onClick={() => cal('9')}
          >
            9
          </Button>
          <br />
          <Button
            variant="contained"
            color="primary"
            id="calFour"
            className={classes.CalButton}
            onClick={() => cal('4')}
          >
            4
          </Button>
          <Button
            variant="contained"
            color="primary"
            id="calFive"
            className={classes.CalButton}
            onClick={() => cal('5')}
          >
            5
          </Button>
          <Button
            variant="contained"
            color="primary"
            id="calSix"
            className={classes.CalButton}
            onClick={() => cal('6')}
          >
            6
          </Button>
          <br />
          <Button
            variant="contained"
            color="primary"
            id="calOne"
            className={classes.CalButton}
            onClick={() => cal('1')}
          >
            1
          </Button>
          <Button
            variant="contained"
            color="primary"
            id="calTwo"
            className={classes.CalButton}
            onClick={() => cal('2')}
          >
            2
          </Button>
          <Button
            variant="contained"
            color="primary"
            id="calThree"
            className={classes.CalButton}
            onClick={() => cal('3')}
          >
            3
          </Button>
          <br />
          <Button
            variant="contained"
            color="primary"
            id="calClear"
            className={classes.CalButton}
            onClick={() => cal('c')}
          >
            c
          </Button>
          <Button
            variant="contained"
            color="primary"
            id="calZero"
            className={classes.CalButton}
            onClick={() => cal('0')}
          >
            0
          </Button>
          <Button
            variant="contained"
            color="primary"
            id="calDot"
            className={classes.CalButton}
            onClick={() => cal('.')}
          >
            .
          </Button>
          <br />
          <Button
            variant="contained"
            color="primary"
            style={{
              height: '70px',
              fontSize: '20px',
              marginTop: '20px',
              marginLeft: '5px',
              marginRight: '5px',
              width: '140px',
            }}
            id="btnFoodPaymentCash"
            onClick={() => {
              Cash();
              PayCash();
            }}
            startIcon={<Money />}
          >
            CASH
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{
              height: '70px',
              fontSize: '20px',
              marginTop: '20px',
              marginLeft: '5px',
              marginRight: '5px',
              width: '140px',
            }}
            id="btnFoodPaymentCredit"
            onClick={() => {
              PayCredit();
              PayCash();
            }}
            startIcon={<CreditCard />}
          >
            CC
          </Button>
          <Button
            variant="contained"
            style={{
              height: '55px',
              fontSize: '20px !important',
              marginTop: '5px',
              marginBottom: '5px',
              marginLeft: '0px',
              marginRight: '0px',
              width: '110px',
              backgroundColor: '#45AAF2',
              color: '#fff',
              display: 'none',
            }}
            id="btnFoodPaymentNayaPay"
            onClick={modleOpenNayaPay}
            disabled={productListInMainBody.length <= 0}
          >
            Naya Pay
          </Button>
        </Grid>
        <Grid item sm={4}>
          <TextField
            margin="normal"
            label="Due"
            id="txtFoodPaymentDue"
            variant="outlined"
            value={(
              amountWithoutTax -
              discount +
              parseFloat(deliveryCharges || 0) +
              parseFloat(tax || 0)
            ).toFixed(2)}
            InputProps={{
              endAdornment: <InputAdornment position="end">Rs</InputAdornment>,
              classes: {
                input: classes.TextFieldresize,
              },
            }}
          />
          {discountBitFields ? (
            <TextField
              margin="normal"
              label="Adjustment (Ctrl + D)"
              id="txtFoodPaymentAdjustment"
              variant="outlined"
              disabled
              value={finalDiscount}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Rs</InputAdornment>
                ),
                classes: {
                  input: classes.TextFieldresize,
                },
              }}
            />
          ) : (
            <TextField
              margin="normal"
              label="Adjustment (Ctrl + D)"
              id="txtFoodPaymentAdjustment"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {signDiscount[0]}
                  </InputAdornment>
                ),
                classes: {
                  input: classes.TextFieldresize,
                },
              }}
              value={finalDiscount}
              onChange={(e) => controlOverAllDiscount(e.target.value)}
            />
          )}
          <TextField
            margin="normal"
            label="Amount Payable"
            id="txtFoodPaymentAmtPayable"
            variant="outlined"
            value={(
              amountWithoutTax -
              discount -
              parseFloat(discountMain) +
              parseFloat(deliveryCharges || 0) +
              parseFloat(tax || 0)
            ).toFixed(2)}
            InputProps={{
              endAdornment: <InputAdornment position="end">Rs</InputAdornment>,
              classes: {
                input: classes.TextFieldresize,
              },
            }}
          />
        </Grid>
        <Grid item sm={4}>
          <TextField
            label="Paid"
            id="txtFoodPaymentPaid"
            margin="normal"
            value={calValue}
            onChange={(e) => setCalValue(e.target.value)}
            type="number"
            variant="outlined"
            InputProps={{
              endAdornment: <InputAdornment position="end">Rs</InputAdornment>,
              classes: {
                input: classes.TextFieldresize,
              },
            }}
          />
          <TextField
            label="Return"
            id="txtFoodPaymentReturn"
            margin="normal"
            value={(
              parseFloat(calValue === '' ? '0' : calValue) -
              (
                parseFloat(amountWithoutTax) -
                parseFloat(discount) -
                parseFloat(discountMain) +
                parseFloat(deliveryCharges || 0) +
                parseFloat(tax || 0)
              ).toFixed(2)
            ).toFixed(2)}
            variant="outlined"
            InputProps={{
              endAdornment: <InputAdornment position="end">Rs</InputAdornment>,
              classes: {
                input: classes.TextFieldresize,
              },
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
  const PushInvoice = (
    <>
      <TextField
        id="txtFoodNayaPayId"
        label="NayaPay ID"
        variant="outlined"
        color="primary"
        value={nayaPayId}
        onChange={(e) => setNayaPayId(e.target.value)}
        style={{
          marginLeft: 'auto',
        }}
        autoComplete="off"
      />

      <div
        style={{
          display: 'flex',
          marginTop: '25px',
        }}
      >
        <Button
          variant="contained"
          onClick={() => setGeneratePushInvoice(false)}
          id="btnFoodBack"
          style={{
            marginRight: 'auto',
            marginLeft: '5px',
          }}
          color="primary"
        >
          Back
        </Button>
        <Button
          variant="contained"
          onClick={handleNayaPayPushInvoice}
          id="btnFoodPushInvoice"
          style={{
            marginRight: '5px',
            marginLeft: 'auto',
          }}
          color="primary"
        >
          Push Invoice
        </Button>
      </div>
    </>
  );
  const modalBodyNayaPay = (
    <div style={modalStyle} className={classes.paper2}>
      <h2 id="simple-modal-title">Payment Through Naya Pay</h2>
      {generateQr ? (
        QRCodex
      ) : generatePushInvoice ? (
        PushInvoice
      ) : (
        <>
          <div
            style={{
              margin: '10px',
            }}
          >
            <Button
              variant="contained"
              onClick={handleGenerateQRCode}
              id="btnFoodGenerateQRCode"
              style={{
                display: 'flex',
                marginRight: 'auto',
                marginLeft: 'auto',
                height: '100px',
                width: '250px',
              }}
              color="primary"
            >
              Generate QR Code
            </Button>
          </div>
          <Online>
            <hr />
            <div
              style={{
                margin: '10px',
              }}
            >
              <Button
                variant="contained"
                onClick={() => handleCallPushInvoice()}
                id="btnFoodPushInvoice"
                style={{
                  display: 'flex',
                  marginRight: 'auto',
                  marginLeft: 'auto',
                  height: '100px',
                  width: '250px',
                }}
                color="primary"
              >
                Push Invoice
              </Button>
            </div>
          </Online>
        </>
      )}
    </div>
  );

  const modalCancelOrder = (
    <div
      style={{
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: '45%',
        width: '30%',
      }}
      className={classes.paper2}
    >
      <h2 id="simple-modal-title">Cancel Order</h2>
      <IconButton
        style={{
          top: '0px',
          right: '0px',
          position: 'absolute',
        }}
        color="inherit"
        aria-label="menu"
        onClick={handleCloseCancelOrderModal}
        id="btnFoodRunningOrderClose"
      >
        <ClearIcon />
      </IconButton>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Autocomplete
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            id="controllable-states-demo"
            options={CancelReasonData}
            style={{ marginTop: '20px', width: '100%' }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Cancel Order Reason"
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.ButtonsCotainerMargin}>
        <Grid item xs={12} sm={12} align="center">
          <div>
            <Controls.Button
              text="Cancel Order"
              id="btnCancelOrder"
              style={{ marginTop: '25px' }}
              onClick={() => {
                if (value !== null) {
                  setCancelReason(value);
                  cancelOrder(selectedOrderIndex);
                  cancelSlip();
                  handleCloseCancelOrderModal();
                } else {
                  setNotify({
                    isOpen: true,
                    message: 'Please select cancel order reason',
                    type: 'error',
                  });
                }
              }}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );

  const modalSpecialNote = (
    <div
      style={{
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: '45%',
        width: '30%',
      }}
      className={classes.paper2}
    >
      <h2 id="simple-modal-title">Special Note</h2>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Controls.Input
            style={{ marginTop: '20px', width: '100%' }}
            name="deliveryInstructions"
            label="Delivery Instructions"
            multiline
            row={3}
            value={specialNote}
            onChange={(e) => setSpecialNote(e.target.value)}
            id="txtSpecialNote"
          />
        </Grid>
      </Grid>
      <Grid container className={classes.ButtonsCotainerMargin}>
        <Grid item xs={12} sm={12} align="center">
          <div>
            <Controls.Button
              text="Add Note"
              id="btnAddNote"
              style={{ marginTop: '25px' }}
              onClick={() => {
                addNote();
                handleCloseSpecialNoteModal();
              }}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );

  const modalBodyTable = (
    <div style={modalStyle} className={classes.paper2}>
      <h2 id="simple-modal-title">Select Table</h2>
      {tableValues.map((table, index) => (
        <Button
          key={table._id}
          variant="contained"
          color="primary"
          id={'btnFoodTableLst' + index}
          className={classes.CalButton}
          onClick={() => selectTable(table.name)}
        >
          {table.name}
        </Button>
      ))}
    </div>
  );
  const modalBodyRider = (
    <div style={modalStyle} className={classes.paper2}>
      <h2 id="simple-modal-title">Select Rider</h2>
      {riderValues.map((rider, index) => (
        <Button
          key={rider._id}
          variant="contained"
          color="primary"
          id={'btnFoodRiderLst' + index}
          className={classes.CalButton}
          onClick={() => selectRider(rider.name)}
        >
          {rider.name}
        </Button>
      ))}
    </div>
  );
  const modalBodyDealManagement = (
    <div style={modalStyle} className={classes.paper2}>
      <Grid container>
        <Grid item xs={12}>
          <h1>{dealManagementValues[0].name}</h1>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={3}>
          <MenuList>
            {dealManagementValues[0].dealOptions.map((tile, index) => (
              <MenuItem
                key={'menuitems' + index}
                style={{ padding: '10px 16px' }}
                onClick={() => {
                  dealManagementValues[0].dealOptions[index].items.forEach(
                    (elem) => {
                      elem.dealIndex = index;
                    }
                  );
                  setDealManagement2ndBodyItem(
                    dealManagementValues[0].dealOptions[index].items
                  );
                }}
              >
                <ListItemIcon>
                  <SendIcon fontSize="small" />
                </ListItemIcon>
                <Badge
                  badgeContent={`${dealLimitCheck[index] || 0} / ${
                    tile.maxItemLimit
                  }`}
                  color="primary"
                  key={`${tile.dealOptionName}+${index}`}
                  overlap="circle"
                  className={classes.dealBadge}
                >
                  <Typography
                    style={{ left: 0 }}
                    overlap="rectangle"
                    variant="inherit"
                  >
                    {tile.dealOptionName}
                  </Typography>
                </Badge>
              </MenuItem>
            ))}
          </MenuList>
        </Grid>
        <Grid item xs={4} className={classes.dialOptionList}>
          <GridList cellHeight={160} className={classes.gridList} cols={2}>
            {dealManagement2ndBodyItems()}
            {/* dealManagement2ndBodyItems is a function & dealManagement2ndBodyItem is a state variable*/}
          </GridList>
        </Grid>
        <Grid item xs={5}>
          {dealManagementListingTable()}
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.modalFooter}>
        <Button
          variant="contained"
          onClick={closeDealManagementModal}
          id="btnCancelDeal"
          style={{
            height: '40px',
            width: 'auto',
            position: 'relative',
            marginLeft: '5px',
            marginRight: 'auto',
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={addDealToPOS}
          id="btnAddDeal"
          style={{
            height: '40px',
            width: 'auto',
            position: 'relative',
            marginLeft: 'auto',
            marginRight: '5px',
          }}
        >
          ADD DEAL
        </Button>
      </Grid>
    </div>
  );
  const modalBodyWaiter = (
    <div style={modalStyle} className={classes.paper2}>
      <h2 id="simple-modal-title">Select Waiter</h2>
      {waiterValues.map((waiter, index) => (
        <Button
          key={waiter._id}
          variant="contained"
          color="primary"
          id={'btnFoodWaiterLst' + index}
          className={classes.CalButton}
          onClick={() => selectWaiter(waiter.name)}
        >
          {waiter.name}
        </Button>
      ))}
    </div>
  );
  const modalBodyNewCustomer = (
    <div style={modalStyle} className={classes.paper2}>
      <h2 id="simple-modal-title">New Customer</h2>

      <Paper elevation={0} square className={classes.PaperMargin}>
        <Grid container>
          <Grid item xs={3} sm={3}>
            <Controls.Input
              name="name"
              label="Customer Name"
              value={customerName}
              onChange={(e) => {
                onChangeCustomerName(e);
              }}
              id="txtFoodCustName"
            />
          </Grid>
          <Grid item xs={3} sm={3}>
            <Controls.Input
              name="contactNumber"
              label="Contact Number"
              value={customerContactNumber}
              onChange={(e) => {
                onChangeCustomerContactNumber(e);
              }}
              id="txtFoodCustContactNo"
              inputProps={{
                maxLength: 11,
              }}
            />
          </Grid>

          <Grid item xs={3} sm={3}>
            <Controls.Input
              name="email"
              label="Email"
              value={customerEmail}
              onChange={(e) => setCustomerEmail(e.target.value)}
              id="txtFoodCustEmail"
            />
          </Grid>
          <Grid item xs={3} sm={3}>
            <Controls.Input
              name="nearestLandMark"
              label="Nearest Landmark"
              value={customerLandmark}
              onChange={(e) => setCustomerLandmark(e.target.value)}
              id="txtFoodCustNearestLandmark"
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3} sm={3}>
            <Controls.Select
              name="city"
              placeholder="City"
              id="ddFoodCustCity"
              style={{ width: '80%', marginTop: '20px' }}
              className={classes.textfieldOutlined}
              options={cityData}
              label={'City'}
              isNoneAllowed={false}
              value={city}
              onChange={onChangeCity}
            />
          </Grid>
          <Grid item xs={3} sm={3}>
            <Controls.Select
              name="area"
              placeholder="Area"
              id="ddFoodCustArea"
              style={{ width: '80%', marginTop: '20px' }}
              className={classes.textfieldOutlined}
              value={area}
              options={areaValues}
              onChange={onChangeArea}
              label={'Area'}
              isNoneAllowed={false}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Controls.Input
              style={{ marginTop: '20px', width: '100%' }}
              name="billingAddress"
              label="Billing Address"
              multiline
              row={3}
              value={customerAddress}
              onChange={(e) => setCustomerAddress(e.target.value)}
              id="txtFoodCustBillingAddress"
            />
          </Grid>
        </Grid>
        <Grid container className={classes.ButtonsCotainerMargin}>
          <br></br>
          <Grid item xs={3} sm={4} align="left">
            <Controls.Button
              text="Cancel"
              color="default"
              onClick={handleCloseNewCustomerModal}
              id="btnFoodCustCancel"
            />
          </Grid>
          <Grid item xs={6} sm={6}></Grid>
          <Grid item xs={1} sm={2} align="right">
            <div>
              <Controls.Button
                text="Save"
                id="btnFoodCustSave"
                onClick={addNewCustomer}
              />
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
  const modalBodySearchCustomer = (
    <div style={modalStyle} className={classes.paper2}>
      <h2 id="simple-modal-title">Search Customer</h2>
      <IconButton
        style={{
          top: '0px',
          right: '0px',
          position: 'absolute',
        }}
        color="inherit"
        aria-label="menu"
        onClick={handleCloseCustomerModal}
        id="btnSearchCustomerClose"
      >
        <ClearIcon />
      </IconButton>
      <Box
        p={2}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <TextField
          label="Contact Number"
          margin="normal"
          onKeyDown={keyPressDownForSearchCustomer}
          onChange={(e) => setCustomerSearchText(e.target.value)}
          id="txtFoodCustSearch"
          className={clsx(classes.margin, classes.textField)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          autoComplete="off"
        />
        <Button
          variant="contained"
          onClick={() => searchCustomer(customerSearchText)}
          id="btnSearchCustomer"
          style={{
            display: 'flex',
            marginRight: 'auto',
            marginLeft: '10px',
          }}
          color="primary"
        >
          Search Customer
        </Button>
        <Button
          variant="contained"
          onClick={() => openNewCustomerModal()}
          id="btnFoodAddNewCustomer"
          style={{
            display: 'flex',
          }}
          color="primary"
        >
          Add New Customer
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table
          id="foodCustTable"
          className={classes.table}
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Customer ID</TableCell>
              <TableCell align="center">Customer Name</TableCell>
              <TableCell align="center">Contact Number</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody id="foodCustTbody">
            {customerList.map((row, index) => (
              <TableRow id={'foodCustTrow' + index} key={row.customerId}>
                <TableCell component="th" scope="row">
                  {row.customerId}
                </TableCell>
                <TableCell align="center">{row.name}</TableCell>
                <TableCell align="center">{row.contactNumber}</TableCell>
                <TableCell align="center">
                  <Button
                    variant="contained"
                    onClick={() => handleCustomerAppend(row)}
                    id="btnFoodCustAdd"
                    style={{
                      display: 'flex',
                      marginRight: 'auto',
                      marginLeft: '10px',
                    }}
                    color="primary"
                  >
                    Select
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );

  const modalBodyNewCustomerComp = (
    <div style={modalStyle} className={classes.paper2}>
      <IconButton
        style={{
          top: '0px',
          right: '0px',
          position: 'absolute',
        }}
        color="inherit"
        aria-label="menu"
        onClick={handleCloseNewCustomerModalComp}
        id="btnSearchCustomerClose"
      >
        <ClearIcon />
      </IconButton>
      <CustomerDetails
        recordForEdit={customerInformation}
        openInPos={true}
        setCustomerResponse={setCustomerResponse}
      />
    </div>
  );

  useEffect(() => {
    if (customerResponse != null) {
      handleCustomerAppend(customerResponse);
      searchCustomer('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerResponse]);

  function getCityName(row) {
    let cityName;
    if (row.addresses && row.addresses.length > 0) {
      if (getAddressListData(row).cityRef) {
        cityName = getAddressListData(row).cityRef.name;
      } else {
        cityName = '';
      }
    }
    return cityName;
  }

  function getAreaName(row) {
    let areaName;
    if (row.addresses != undefined && row.addresses.length > 0) {
      if (getAddressListData(row).areaRef) {
        areaName = getAddressListData(row).areaRef.name;
      } else {
        areaName = '';
      }
    }
    return areaName;
  }

  function getAddressListData(row) {
    let arr = row.addresses.filter((a) => a.isPrimary === true)[0];

    if (arr == undefined || arr.length == 0) {
      arr = row.addresses[0];
    }
    return arr;
  }

  const modalBodySearchCustomerComp = (
    <div style={modalStyle} className={classes.paper2}>
      <h2 id="simple-modal-title">Search Customer</h2>
      <IconButton
        style={{
          top: '0px',
          right: '0px',
          position: 'absolute',
        }}
        color="inherit"
        aria-label="menu"
        onClick={handleCloseSearchCustomerModalComp}
        id="btnSearchCustomerClose"
      >
        <ClearIcon />
      </IconButton>
      <Box
        p={2}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <TextField
          label="Contact Number"
          margin="normal"
          onKeyDown={keyPressDownForSearchCustomer}
          onChange={(e) => setCustomerSearchText(e.target.value)}
          id="txtFoodCustSearch"
          className={clsx(classes.margin, classes.textField)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          autoComplete="off"
        />
        <Button
          variant="contained"
          onClick={() => searchCustomer(customerSearchText)}
          id="btnSearchCustomer"
          style={{
            display: 'flex',
            marginRight: 'auto',
            marginLeft: '10px',
          }}
          color="primary"
        >
          Search Customer
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setCustomerInformation(null);
            openNewCustomerModalComp();
          }}
          id="btnFoodAddNewCustomer"
          style={{
            display: 'flex',
          }}
          color="primary"
        >
          Add New Customer
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table
          id="foodCustTable"
          className={classes.table}
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">Contact Number</TableCell>

              <TableCell align="center">Customer Name</TableCell>

              <TableCell align="center">City</TableCell>
              <TableCell align="center">Area</TableCell>
              <TableCell align="center">Address</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody id="foodCustTbody">
            {customerList.map((row, index) => (
              <TableRow id={'foodCustTrow' + index} key={row._id}>
                <TableCell component="th" scope="row" align="center">
                  {row.contactNumber}
                </TableCell>
                <TableCell align="center">{row.name}</TableCell>
                <TableCell align="center">{getCityName(row)}</TableCell>
                <TableCell align="center">{getAreaName(row)}</TableCell>
                <TableCell align="center">
                  {row.addresses != undefined && row.addresses.length > 0
                    ? getAddressListData(row).addressDetails
                    : row.shippingAddress}
                </TableCell>

                <TableCell align="center">
                  <ButtonGroup
                    variant="outlined"
                    color="primary"
                    aria-label="contained primary button group"
                    style={{ marginRight: '5px', marginLeft: 'auto' }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => {
                        if (isAreaEnabled(row)) {
                          handleCustomerAppend(row);
                        } else {
                          setNotify({
                            isOpen: true,
                            message: 'Area is temporary disabled',
                            type: 'error',
                          });
                        }
                      }}
                      id="btnFoodCustAdd"
                      style={{
                        display: 'flex',
                        marginRight: 'auto',
                        marginLeft: '10px',
                      }}
                      color="primary"
                    >
                      <ReplayIcon />
                    </Button>

                    <Button
                      variant="contained"
                      onClick={() => {
                        setOpenNewCustomerComp(true);
                        setCustomerInformation(row);
                      }}
                      id="btnFoodCustAdd"
                      style={{
                        display: 'flex',
                        marginRight: 'auto',
                        marginLeft: '10px',
                      }}
                      color="primary"
                    >
                      <Edit />
                    </Button>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );

  return (
    <div>
      <div id="print-mount"></div>
      <BarcodeReader onScan={handleBarCodeScan} />
      <Snackbar
        open={alertOpen}
        autoHideDuration={1000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity={alertBodyAndType.type}>
          {alertBodyAndType.message}
        </Alert>
      </Snackbar>
      <Modal
        open={openRider}
        onClose={handleCloseRiderModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBodyRider}
      </Modal>
      <Modal
        open={openDealManagement}
        onClose={handleDealManagementModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {openDealManagement ? modalBodyDealManagement : null}
      </Modal>
      <Modal
        open={openCustomer}
        onClose={handleCloseCustomerModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBodySearchCustomer}
      </Modal>
      <Modal
        open={openNewCustomer}
        onClose={handleCloseNewCustomerModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBodyNewCustomer}
      </Modal>
      <Modal
        open={openSearchCustomerComp}
        //onClose={handleCloseCustomerModal2}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBodySearchCustomerComp}
      </Modal>

      <Modal
        open={openNewCustomerComp}
        //onClose={handleCloseCustomerModal2}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBodyNewCustomerComp}
      </Modal>
      <Modal
        open={openWaiter}
        onClose={handleCloseWaiterModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBodyWaiter}
      </Modal>
      <Modal
        open={openCancelOrder}
        onClose={openCancelOrderModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalCancelOrder}
      </Modal>
      <Modal
        open={openSpecialNote}
        onClose={handleCloseSpecialNoteModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalSpecialNote}
      </Modal>
      <Modal
        open={openTable}
        onClose={handleCloseWaiterModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBodyTable}
      </Modal>
      <Modal
        open={open3}
        onClose={handleClose3}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body3}
      </Modal>
      <Modal
        open={queuedOrders}
        onClose={handleCloseQueuedOrders}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {bodyCallCenterOrdersQueue}
      </Modal>
      <Modal
        open={open4}
        onClose={handleClose4}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body4}
      </Modal>
      <Modal
        open={open5}
        onClose={handleClose5}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body5}
      </Modal>
      <Modal
        open={open6}
        onClose={handleClose6}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body6}
      </Modal>
      <Modal
        open={openNayaPay}
        onClose={modleCloseNayaPay}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBodyNayaPay}
      </Modal>
      <Header
        classes={classes}
        UserRole={UserRole}
        handleOpen4={handleOpen4}
        shiftButton={shiftButton}
        handleOpen5={handleOpen5}
        syncTime={syncTime}
        InvoiceSync={InvoiceSync}
        SIGNOUT={SIGNOUT}
        CompanyName={CompanyName}
        IsOdms={isOdms}
      />

      <Paper elevation={0} square className={classes.PaperMargin}>
        <Grid container>
          <Grid
            item
            sm={7}
            md={7}
            lg={8}
            style={{
              backgroundColor: '#eee',
            }}
          >
            <Grid item xs={12}>
              <Paper
                square
                elevation={0}
                style={
                  ({ width: '100%' },
                  { display: shiftButton || isOdms ? 'block' : 'none' })
                }
              >
                <TextField
                  id="txtFoodSearch"
                  style={{ width: '50%' }}
                  className={classes.textfieldOutlined}
                  size="small"
                  placeholder="Search"
                  variant="outlined"
                  onChange={onChange}
                  value={recordSets.userInput}
                  autoComplete="off"
                  disabled={isNewOrder === true ? false : true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {recordSets.userInput.length > 0 ? (
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickClearText}
                          >
                            <ClearIcon />
                          </IconButton>
                        ) : (
                          <></>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />

                {suggestionsListComponent}

                <Controls.Select
                  name="branch"
                  placeholder="Branch"
                  id="ddBranch"
                  style={{ width: '25%' }}
                  className={classes.textfieldOutlined}
                  options={branchData}
                  isNoneAllowed={false}
                  value={branch}
                  onChange={onChangeBranch}
                  disabled={isOdms ? true : false}
                />
                <Controls.Select
                  name="orderMode"
                  placeholder="Order Mode"
                  id="ddFoodOrderMode"
                  style={{ width: '25%' }}
                  className={classes.textfieldOutlined}
                  options={orderModeValues}
                  value={orderMode}
                  onChange={handleInputDropDownChange}
                  isNoneAllowed={false}
                  disabled={
                    currentOrderNumber == 0 && productListInMainBody.length == 0
                      ? false
                      : true
                  }
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper
                elevation={0}
                square
                style={
                  ({ width: '100%' },
                  { display: shiftButton || isOdms ? 'block' : 'none' })
                }
              >
                <Tabs
                  classes={{ scroller: classes.scroller }}
                  value={active}
                  onChange={(event, newValue) => {
                    setActive(newValue);
                  }}
                  style={{ backgroundColor: '#eee' }}
                  indicatorColor="primary"
                  textColor="primary"
                  variant={'scrollable'}
                  scrollButtons={'on'}
                >
                  {isNewOrder
                    ? categories
                        .filter((a) => a._id != 0)

                        .map((category, index) => (
                          <Tab
                            id={'lstFoodCat' + index}
                            key={category._id}
                            label={category.name}
                            value={category.name}
                            style={{
                              backgroundColor: '#fff',
                              margin: '10px',
                              border: '0',
                              borderRadius: '8px',
                            }}
                            onClick={() => {
                              changeActiveCategory(index);
                            }}
                          />
                        ))
                    : ''}
                </Tabs>
              </Paper>
            </Grid>
            <Grid item sm={12}>
              <Box mx={3} my={1}>
                <Paper
                  elevation={0}
                  style={{
                    height: '57vh',
                    backgroundColor: '#eee',
                    overflowY: 'auto',
                    display: shiftButton || isOdms ? 'block' : 'none',
                  }}
                >
                  <GridList
                    cellHeight={180}
                    style={{
                      margin: 0,
                      padding: '10px 0',
                      justifyContent: 'center',
                    }}
                  >
                    <GridListTile
                      key="Subheader"
                      cols={2}
                      style={{ height: 'auto' }}
                    ></GridListTile>
                    {isNewOrder
                      ? categories[activeCategory].products
                          .filter((a) => a._id != 0 && a.status == 'active')
                          .map((product, index) => (
                            <Card
                              key={product._id}
                              className={classes.rootItem}
                              style={{ height: 'auto', width: 120 }}
                              id={'lstFoodProd' + index}
                              onClick={() => {
                                if (product.isDeal) {
                                  GetDealManagement(product._id);
                                  setOpenDealManagement(true);
                                } else {
                                  selectProductForMainBody(product);
                                }
                              }}
                            >
                              <CardMedia
                                className={classes.media}
                                image={
                                  product.img
                                    ? product.img
                                    : 'https://material-ui.com/static/images/cards/paella.jpg'
                                }
                              />
                              <CardContent className={classes.con1}>
                                <Typography
                                  variant="body2"
                                  component="body2"
                                  align="center"
                                >
                                  Rs: {Math.round(product.unitSaleFinalPrice)}
                                </Typography>
                              </CardContent>
                              <CardContent
                                className={classes.con2}
                                style={{ paddingBottom: 0 }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  color="textSecondary"
                                  align="center"
                                  style={{ wordWrap: 'break-word' }}
                                >
                                  {product.name}
                                </Typography>
                              </CardContent>
                            </Card>
                          ))
                      : ''}
                  </GridList>
                </Paper>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Paper
                  elevation={2}
                  style={{
                    padding: '15px 0 20px 0',
                    bottom: 0,
                    position: 'absolute',
                    width: '67%',
                  }}
                >
                  <Footer
                    classes={classes}
                    totalItems={totalItems}
                    handleOpen3={handleOpen3}
                    shiftButton={shiftButton}
                    holdOrderCount={holdOrderCount}
                    openSpecialNoteModal={openSpecialNoteModal}
                    riderName={riderName}
                    orderMode={orderMode}
                    openRiderModal={openRiderModal}
                    PrePaymentBill={PrePaymentBill}
                    kotList={kotList}
                    isOdms={isOdms}
                    sendKot={sendKot}
                    handleOpenQueuedOrders={handleOpenQueuedOrders}
                    qOrderCount={qOrderCount}
                  />
                </Paper>
              </Box>{' '}
            </Grid>
          </Grid>
          <Grid item sm={5} md={5} lg={4}>
            <Paper
              elevation={0}
              square
              style={{
                bottom: 0,
                borderLeft: '1px solid #eee',
                position: 'relative',
              }}
            >
              <CartHeaderButtons
                newOrderButtonClick={newOrderButtonClick}
                shiftButton={shiftButton}
                isCustomerAttached={isCustomerAttached}
                customerinfo={customerinfo}
                handleCustomerremove={handleCustomerremove}
                openCustomerModal={openCustomerModal}
                totalItems={totalItems}
                IsOdms={isOdms}
                ResetCart={ResetCart}
                setOpenNewCustomerComp={setOpenNewCustomerComp}
                setCustomerInformation={setCustomerInformation}
                custInfo={customerinfo}
              />

              <Box p={1}>
                <TableContainer style={{ height: '53vh' }}>
                  <Table aria-label="simple table" padding="none" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" style={{ paddingLeft: '15px' }}>
                          Items
                        </TableCell>
                        <TableCell align="center">Amount(Rs)</TableCell>
                        <TableCell align="center">Quantity</TableCell>
                        <TableCell align="center">Discount(%)</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody id="foodCartTbody">
                      {productListInMainBody.map((productList, index) =>
                        !productList.isDeal ? (
                          <TableRow
                            key={productList._id}
                            id={'foodCartTrow' + index}
                            hover
                          >
                            <TableCell
                              id={'tyFoodCartProdName' + index}
                              style={{ paddingLeft: '15px' }}
                            >
                              {productList.name}
                            </TableCell>
                            <TableCell
                              id={'tyFoodCartAmt' + index}
                              align="center"
                            >
                              {parseFloat(productList.saleFinalPrice).toFixed(
                                2
                              )}
                            </TableCell>
                            <TableCell align="center">
                              <Box className={classes.posQty}>
                                <IconButton
                                  size="small"
                                  aria-label="add an alarm"
                                  id={'btnFoodCartQtyDec' + index}
                                >
                                  <RemoveCircleOutlineIcon
                                    onClick={() =>
                                      controlQuantity(false, index, '1')
                                    }
                                  />
                                </IconButton>
                                {productList.Quantity}
                                <IconButton
                                  size="small"
                                  aria-label="add an alarm"
                                  id={'btnFoodCartQtyInc' + index}
                                >
                                  <AddCircleOutlineIcon
                                    onClick={() =>
                                      controlQuantity(true, index, '1')
                                    }
                                  />
                                </IconButton>
                              </Box>
                            </TableCell>
                            <TableCell align="center">
                              <TextField
                                id={'txtFoodCartDisc' + index}
                                type="number"
                                variant="outlined"
                                margin="dense"
                                style={{
                                  width: '4rem',
                                }}
                                value={productList.discountPercentage}
                                onChange={(e) => enterDiscount(e, index)}
                                onBlur={(e) => controlDiscount(e, index)}
                                autoComplete="off"
                              />
                            </TableCell>

                            <TableCell align="center">
                              <IconButton
                                size="small"
                                color="secondary"
                                aria-label="add an alarm"
                                id={'btnFoodCartProdRemove' + index}
                                onClick={() => {
                                  RemoveItemFromMainBody(productList, index);
                                  setKotValues(productList, true);
                                }}
                              >
                                <ClearOutlinedIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <React.Fragment>
                            <TableRow
                              className={classRowStyle.root}
                              style={{ height: 0 }}
                              hover
                              id={'foodCartDealTrow' + index}
                            >
                              <TableCell
                                id={'foodCartDealProdName' + index}
                                style={{ textAlign: 'left' }}
                              >
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  id={'foodCartDealExpandRow' + index}
                                  onClick={() => toggleCollapse(index)}
                                >
                                  {open === index ? (
                                    <KeyboardArrowUpIcon />
                                  ) : (
                                    <KeyboardArrowDownIcon />
                                  )}
                                </IconButton>
                                {productList.name}
                              </TableCell>
                              <TableCell style={{ textAlign: 'center' }}>
                                {productList.saleFinalPrice}
                              </TableCell>
                              <TableCell style={{ textAlign: 'center' }}>
                                {1}
                              </TableCell>
                              <TableCell style={{ textAlign: 'center' }}>
                                {0}
                              </TableCell>
                              <TableCell style={{ textAlign: 'center' }}>
                                <IconButton
                                  size="small"
                                  color="secondary"
                                  aria-label="add an alarm"
                                  id={'foodCartDealProdRemove' + index}
                                  onClick={() => {
                                    RemoveItemFromMainBody(productList, index);
                                    setKotValues(productList, true);
                                  }}
                                >
                                  <ClearOutlinedIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                style={{
                                  paddingBottom: '0px !important',
                                  paddingTop: '0px !important',
                                }}
                                colSpan={10}
                              >
                                <Collapse
                                  in={open === index}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <Box margin={1}>
                                    <Table size="small" aria-label="purchases">
                                      <TableBody>
                                        {productList.dealItems.map(
                                          (element) => (
                                            <TableRow key={'hello'}>
                                              <TableCell
                                                style={{
                                                  textAlign: 'left',
                                                  width: '35%',
                                                  position: 'relative',
                                                }}
                                              >
                                                {element.name}
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  textAlign: 'center',
                                                  width: '25%',
                                                  position: 'relative',
                                                }}
                                              >
                                                {element.additionalPrice}
                                              </TableCell>

                                              <TableCell
                                                style={{
                                                  textAlign: 'center',
                                                  width: '10%',
                                                }}
                                              >
                                                {1}
                                              </TableCell>
                                              <TableCell
                                                style={{ textAlign: 'center' }}
                                              ></TableCell>
                                            </TableRow>
                                          )
                                        )}
                                      </TableBody>
                                    </Table>
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box
                  component="div"
                  style={{
                    bottom: 0,
                    width: '100%',
                    borderTop: '2px solid #eee',
                  }}
                >
                  <CartTotal
                    classes={classes}
                    amountWithoutTax={amountWithoutTax}
                    tax={tax}
                    orderMode={orderMode}
                    deliveryCharges={deliveryCharges}
                    netTotal={netTotal}
                    discount={discount}
                    setDeliveryCharges={setDeliveryCharges}
                    itemDiscount={discount}
                  />
                  {!isOdms ? (
                    ShowKotCheckoutButton()
                  ) : (
                    <SaveOrderButton
                      classes={classes}
                      totalItems={totalItems}
                      bookCallCenterOrder={bookCallCenterOrder}
                    />
                  )}
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
      <div id="playSound"></div>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}

export default POSFood;
