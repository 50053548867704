import React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Box, withStyles, Button } from '@material-ui/core';

const styles = {
  button: {
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.23)',
    backgroundColor: '#f7f7f7',
  },
  fullHeight: {
    height: '100%',
  },
};

function Dropzone(props) {
  const { onDrop, accept, classes, style, children } = props;
  const { getRootProps, getInputProps } = useDropzone({
    accept: accept,
    onDrop: onDrop,
  });
  return (
    <Box {...getRootProps()} height="100%">
      <input {...getInputProps()} />
      <Button
        className={classes.button}
        variant="outlined"
        color="primary"
        style={style}
      >
        {children}
      </Button>
    </Box>
  );
}

Dropzone.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  onDrop: PropTypes.func,
  accept: PropTypes.string,
  fullHeight: PropTypes.bool,
  style: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
};

export default withStyles(styles, { withTheme: true })(Dropzone);
