import authHeader from '../../../Services/auth-header';
import { callAPI } from '../../../Services/ApiCaller';
import { API_VERSION } from '../../../Common/constants';

export async function getAllDepartment(
  page,
  rowsPerPage,
  sortDirection,
  sortColumnName,
  searchString
) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  let rowsFrom = page * rowsPerPage;

  let sortOrderdir = sortDirection === 'asc' ? 1 : -1;
  return callAPI(
    `api/${API_VERSION}/departments`,
    null,
    headerObject,
    'GET',
    `?$orderBy=${sortColumnName}&$orderDir=${sortOrderdir}&$skip=${rowsFrom}&$top=${rowsPerPage}${searchString}`
  ).then((Responce) => {
    return Responce;
  });
}

export async function getAllCategoriesForDropDown() {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  return callAPI(
    `api/${API_VERSION}/department`,
    null,
    headerObject,
    'GET',
    `?$orderBy=name&$orderDir=1&$top=10000`
  ).then((Responce) => {
    return Responce;
  });
}

export async function insertDepartment(department) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  return callAPI(
    `api/${API_VERSION}/departments`,
    { name: department.name.trim() },
    headerObject,
    'POST',
    ''
  ).then((Responce) => {
    return Responce;
  });
}

export async function deleteDepartment(_id) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  return callAPI(
    `api/${API_VERSION}/department/`,
    null,
    headerObject,
    'DELETE',
    _id
  ).then((Responce) => {
    return Responce;
  });
}

export async function updateDepartment(department) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  var departmentUpdate = {
    name: department.name,
  };

  return callAPI(
    `api/${API_VERSION}/departments`,
    departmentUpdate,
    headerObject,
    'PUT',
    `/${department._id}`
  ).then((Responce) => {
    return Responce;
  });
}

export async function patchCategory(id, departmentObject) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };

  return callAPI(
    `api/${API_VERSION}/departments`,
    departmentObject,
    headerObject,
    'PATCH',
    `/${id}`
  ).then((Responce) => {
    return Responce;
  });
}
