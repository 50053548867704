import React, { useEffect, useState } from 'react';
import { Paper, Grid } from '@material-ui/core';
import Controls from '../../Utilities/Controls/Controls';
import { useForm, Form } from '../../Utilities/ReuseableCommponents/useForm';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import PageTitle from '../../Layout/PageTitle';
import { useLocation, useHistory } from 'react-router-dom';
import ConfirmDialog from '../../Utilities/ReuseableCommponents/ConfirmDialog';
import Notification from '../../Utilities/ReuseableCommponents/Notification';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import * as CategoryService from './CategoryService';
import * as SetupService from '../Setups/SetupService';

const initialFValues = {
  _id: 0,
  name: '',
  commission: '',
  createdBy: '',
  createdAt: '',
  updatedAt: '',
  __v: 0,
};
let Responce = {
  data: null,
  error: null,
  messege: '',
  loading: false,
};

let apiResponce = {
  data: null,
  error: null,
  loading: false,
  statusCode: 0,
};
function CategoryDetails() {
  const history = useHistory();

  const location = useLocation();
  const [showCommission, setShowCommission] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
    notificationTime: 3000,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const { values, setValues, handleInputChange, resetForm } = useForm(
    initialFValues,
    true
  );

  const addOrEdit = async (category, resetForm) => {
    if (category._id === 0 && category.name) {
      try {
        Responce.data = null;
        Responce.error = null;
        Responce = await CategoryService.insertCategory(category).then(
          (Responce) => {
            return Responce;
          }
        );

        if (Responce.data) {
          resetForm();

          setNotify({
            isOpen: true,
            message: 'Submitted Successfully',
            type: 'success',
          });
        }

        if (Responce.error) {
          setNotify({
            isOpen: true,
            message: Responce.messege
              ? Responce.messege
              : 'Unable To Process Request',
            type: 'error',
          });
        }
      } catch (e) {
        setNotify({
          isOpen: true,
          message: 'Unable To Process Request',
          type: 'error',
        });
      }
    }
    if (category._id !== 0 && category.name) {
      try {
        apiResponce.data = null;
        apiResponce.error = null;
        apiResponce = await CategoryService.updateCategory(category).then(
          (Responce) => {
            return Responce;
          }
        );
        if (apiResponce.data) {
          resetForm();
          setNotify({
            isOpen: true,
            message: 'Category Detail Updated Successfully',
            type: 'success',
          });
        }
        if (apiResponce.error) {
          setNotify({
            isOpen: true,
            message: 'Unable To Process Request',
            type: 'error',
          });
        }
      } catch (e) {
        setNotify({
          isOpen: true,
          message: 'Unable To Process Request',
          type: 'error',
        });
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    addOrEdit(values, resetForm);
  };

  useEffect(() => {
    if (location.state.recordForEdit != null)
      setValues({ ...location.state.recordForEdit });
    SetupService.getSetupWithName('Commission').then((response) => {
      if (response.data && response.data.data.length > 0) {
        setShowCommission(response.data.data[0].data[0].value);
        localStorage.setItem(
          '_Commission',
          response.data.data[0].data[0].value
        );
      }
      return response;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state.recordForEdit]);

  return (
    <>
      <PageTitle
        title={location.state.pageHeaderTitile}
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="categoryDetailsHeading"
      />
      <Paper
        elevation={6}
        square
        className="paperMargin"
        style={{
          borderRadius: '10px',
        }}
      >
        <Form
          onSubmit={handleSubmit}
          style={{
            overrides: {
              MuiFormLabel: {
                asterisk: {
                  color: '#db3131',
                  '&$error': {
                    color: '#db3131',
                  },
                },
              },
            },
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={6} md={2}>
              <Controls.Input
                name="name"
                label="Category Name"
                value={values.name}
                onChange={handleInputChange}
                className="searchInput"
                id="txtCatName"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              {showCommission && (
                <Controls.Input
                  name="commission"
                  label="Commission (%)"
                  type="number"
                  value={values.commission}
                  onChange={handleInputChange}
                  className="searchInput"
                  id="txtCatcommission"
                />
              )}
            </Grid>
            <Grid container className="buttonsCotainerMargin">
              <br></br>
              <Grid item xs={12} sm={6} align="left">
                <Controls.Button
                  text="Cancel"
                  color="default"
                  onClick={history.goBack}
                  startIcon={<CloseIcon />}
                  className="actionBtnMargin"
                  id="btnCancelCat"
                />
              </Grid>
              <Grid item xs={12} sm={6} align="right">
                <div>
                  <Controls.Button
                    type="submit"
                    startIcon={<SaveIcon />}
                    text="Save"
                    className="actionBtnMargin"
                    id="btnSaveCat"
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}

export { CategoryDetails as default };
