import React from 'react';
import styles from './rembrandssalesInvoice.module.css';
import NakshLogo from '../../../../Assets/Images/naksh-logo.png';
import NakshQR from '../../../../Assets/Images/CCQR.jpg';
import noImageAvailable from '../../../../Assets/Images/undefined.png';
import { getFormattedDateTime } from '../../../../Common/constants';

class ComponentToPrint extends React.Component {
  render() {
    const copies = ['Customer', 'Internal', 'Logistic'];

    const data = JSON.parse(window.sessionStorage.getItem('Invoice'));
    const subTotal =
      parseFloat(data.saleAmountTotal) -
      parseFloat(data.totalTaxAmount) -
      parseFloat(data.overallSaleDiscountAmount);

    const fractionDigits = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };

    var invoiceDate = getFormattedDateTime(data.clientCreatedDate);
    return (
      <div>
        {copies.map((copy) => (
          <div id="pageCopy" key={copy}>
            {copy === 'Logistic' ? (
              <div>
                <div className="fullWidth">
                  <div className={styles.flexColumn}>
                    <div>
                      <p className={styles.orderNumber}>
                        Order # {data.invoiceNumber}
                      </p>
                    </div>
                    <div className={styles.topDiv}>
                      <div>
                        <p className={styles.orderDate}>Date: {invoiceDate}</p>
                        <div>
                          <img className={styles.brandLogo} src={NakshLogo} />
                        </div>
                        <div>
                          <img className={styles.qrCode} src={NakshQR} />
                        </div>
                      </div>
                      <div>
                        <p className={styles.paymentDiv}>
                          <span>Payment: Cash On Delivery</span>
                          <br />
                          <span>
                            Price: PKR{' '}
                            {parseFloat(data.saleAmountTotal).toLocaleString(
                              undefined,
                              fractionDigits
                            )}
                          </span>
                        </p>
                        <div>
                          <h1 className={styles.mb0}>Receiver</h1>
                          <p className={styles.mainInfo}>
                            <span>
                              Name:{' '}
                              {data.customerInfo
                                ? data.customerInfo.name
                                : 'N/A'}{' '}
                            </span>
                            <br />
                            <span>
                              Contact No:{' '}
                              {data.customerInfo
                                ? data.customerInfo.contactNumber
                                : 'N/A'}{' '}
                            </span>
                            <br />
                            <span>
                              Shipping Address:{' '}
                              {data.customerInfo
                                ? data.customerInfo.shippingAddress
                                : 'N/A'}
                            </span>
                            <br />
                          </p>
                        </div>
                        <div>
                          <h1 className={styles.senderTitle}>Sender</h1>
                          <p className={styles.mainInfo}>
                            <span>Name: Naksh</span>
                            <br />
                            <span>Contact No: 03165098056</span>
                            <br />
                            <span>
                              Shipping Address: Street 14, I-9/2 I-9/2 I-9,
                              Islamabad, Islamabad Capital Territory 44000
                            </span>
                            <br />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <p className={styles.orderNumber}>TRACKING NUMBER</p>
                      <p className={styles.pStyle}></p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="alignCenter">
                  <img
                    variant="square"
                    className={styles.secondLogo}
                    alt="Naksh logo"
                    src={NakshLogo}
                  />
                </div>
                <p className={styles.orderSummary}>Order Summary</p>
                <p className={styles.orderSummaryDetail}>({copy} Copy)</p>
                <table>
                  <tbody>
                    <tr>
                      <td className="generalReportPadding">
                        <strong>
                          Order Placement Date: {' ' + invoiceDate} <br />
                          <strong>Order # {data.invoiceNumber}</strong>
                        </strong>
                      </td>
                      <td />
                      <td />
                      <td className={styles.tdWidth}>
                        Name:{' '}
                        {data.customerInfo ? data.customerInfo.name : 'N/A'}{' '}
                        <br />
                        Contact No:{' '}
                        {data.customerInfo
                          ? data.customerInfo.contactNumber
                          : 'N/A'}{' '}
                        <br />
                        Shipping Address:{' '}
                        {data.customerInfo
                          ? data.customerInfo.shippingAddress
                          : 'N/A'}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br></br>
                <table className={styles.tableStyle}>
                  <tbody>
                    <tr className={styles.lineHeight1}>
                      <th className={styles.itemStyle}>Item</th>
                      <th>Description</th>
                      <th className={styles.thAlignCenter}>Quantity</th>
                      {/* <th style={{ padding: '0px 18px 0px' }} /> */}
                      <th className={styles.thAlignCenter}>Unit Price</th>
                    </tr>
                    {data.itemsSold.map((item) => (
                      <tr className={styles.trStyle} key={item._id} id="row">
                        <td className={styles.pr10}>
                          {item.img ? (
                            <img
                              className={styles.productImage}
                              src={item.img}
                            />
                          ) : (
                            <img
                              className={styles.productImage}
                              src={noImageAvailable}
                            />
                          )}
                        </td>
                        <td className={styles.tdPadding}>
                          <p>[{item.sku ? item.sku : item.barcode}]</p>
                          <p>{item.name}</p>
                          <p>
                            Color:{' '}
                            {item.description
                              ? item.description.includes(':::')
                                ? item.description.split(':::')[0]
                                : 'N/A'
                              : 'N/A'}
                          </p>
                          {item.description
                            ? item.description.includes(':::')
                              ? item.description.split(':::')[1]
                              : item.description
                            : 'N/A'}
                        </td>
                        <td className={styles.itemQty}>{item.Quantity}</td>
                        <td className={styles.itemQty}>
                          PKR{' '}
                          {parseFloat(item.saleFinalPrice).toLocaleString(
                            undefined,
                            fractionDigits
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <table id="total-calc">
                  <tbody>
                    <tr id="pay-method">
                      <td className={styles.tdPadding1}>
                        <strong>Payment Method:</strong> Cash On Delivery
                      </td>
                      <td className={styles.tdPadding2} />
                      <td className={styles.tdPadding3} />
                      <td className={styles.tdPadding4}>SubTotal:</td>
                      <td className={styles.tdPadding5}>
                        <strong>
                          PKR{' '}
                          {subTotal.toLocaleString(undefined, fractionDigits)}
                        </strong>
                      </td>
                    </tr>
                    <tr id="adj">
                      <td />
                      <td />
                      <td />
                      <td className={styles.tdPadding6}>Adjustment:</td>
                      <td className={styles.tdPadding7}>
                        <strong>
                          PKR{' '}
                          {parseFloat(
                            data.overallSaleDiscountAmount
                          ).toLocaleString(undefined, fractionDigits)}
                        </strong>
                      </td>
                    </tr>
                    <tr id="tax">
                      <td />
                      <td />
                      <td />
                      <td className={styles.tdPadding6}>Tax(17%):</td>
                      <td className={styles.tdPadding7}>
                        <strong>
                          PKR{' '}
                          {parseFloat(data.totalTaxAmount).toLocaleString(
                            undefined,
                            fractionDigits
                          )}
                        </strong>
                      </td>
                    </tr>
                    <tr id="total-amount">
                      <td />
                      <td />
                      <td />
                      <td className={styles.tdPadding8}>
                        <strong>Total:</strong>
                      </td>
                      <td className={styles.tdPadding8}>
                        <strong>
                          PKR{' '}
                          {parseFloat(data.saleAmountTotal).toLocaleString(
                            undefined,
                            fractionDigits
                          )}
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <br />
                <br />
                <p id="thanks" className={styles.thankYouDiv}>
                  <strong className={styles.thankYouText}>
                    Thank You For Shopping!
                    <br /> Hope to hear from you again.
                    <br /> Naksh
                  </strong>
                  <img
                    id="logo"
                    variant="square"
                    className={styles.footerQR}
                    alt="QR code"
                    src={NakshQR}
                  />
                </p>
                <div id="footer" className={styles.footerInfo}>
                  Need Help? Call Us: +92 3165098056 | nakshhelp@gmail.com
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }
}

export default ComponentToPrint;
