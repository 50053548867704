import React from 'react';
import { Modal } from '@material-ui/core';
import SalesReturnModalBody from './SalesReturnModalBody';

import PropTypes from 'prop-types';
const SalesReturnModal = (props) => {
  return (
    <div>
      <Modal
        open={props.openSalesReturn}
        onClose={props.modleCloseSalesReturn}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div>
          <SalesReturnModalBody {...props} />
        </div>
      </Modal>
    </div>
  );
};
SalesReturnModal.propTypes = {
  openSalesReturn: PropTypes.any,
  modleCloseSalesReturn: PropTypes.any,
  props: PropTypes.any,
};
export default SalesReturnModal;
