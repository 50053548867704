import authHeader from '../../../Services/auth-header';
import { callAPI } from '../../../Services/ApiCaller';
import { API_VERSION } from '../../../Common/constants';

export const getSaleModes = () => [
  { _id: 'cash', name: 'Cash', nameValue: 'cash' },
  { _id: 'creditcard', name: 'Credit Card', nameValue: 'creditcard' },
  { _id: 'nayapay', name: 'Naya Pay', nameValue: 'nayapay' },
  { _id: 'credit', name: 'Credit', nameValue: 'credit' },
  { _id: 'cashondelivery', name: 'COD', nameValue: 'cashondelivery' },
];

export async function FetchProfitAndLossReportList(
  page,
  rowsPerPage,
  sortDirection,
  sortColumnName,
  searchString
) {
  const header = authHeader();
  const headerObject = {
    'Content-Type': 'application/json',
    Authorization: header.Authorization,
  };
  let rowsFrom = page * rowsPerPage;

  let sortOrderdir = sortDirection === 'asc' ? 1 : -1;
  if (!sortColumnName) {
    sortColumnName = 'createdAt';
  }
  return callAPI(
    `api/${API_VERSION}/inventory/profitloss`,
    null,
    headerObject,
    'GET',
    `?orderBy=${sortColumnName}&$orderDir=${sortOrderdir}&$skip=${rowsFrom}&$top=${rowsPerPage}${searchString}`
  ).then((Responce) => {
    return Responce;
  });
}
