import React, { useState, useEffect } from 'react';
import Controls from '../../Utilities/Controls/Controls';
import { Form } from '../../Utilities/ReuseableCommponents/useForm';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import PageTitle from '../../Layout/PageTitle';
import { useLocation, useHistory } from 'react-router-dom';
import useTable from '../../Utilities/ReuseableCommponents/useTable';
import {
  InputAdornment,
  makeStyles,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Grid,
} from '@material-ui/core';
import { getFormattedDateTime as ISOdateFormatter } from '../../../Common/constants';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: '75%',
  },
  newButton: {
    position: 'absolute',
    right: '10px',
  },
  PaperMargin: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  CellRightAlign: {
    textAlign: 'right',
  },
}));

const headCells = [
  { id: 'name', label: 'Product' },
  { id: 'categoryName', label: 'Category' },
  { id: 'barcode', label: 'Barcode', align: 'center' },
  { id: 'sku', label: 'SKU', align: 'center' },
  { id: 'quantitySold', label: 'Qty Sold', align: 'center' },
  { id: 'qtyReturned', label: 'Qty Returned', align: 'center' },
  { id: 'saleFinalPrice', label: 'Final Sale Price', align: 'right' },
  { id: 'discountAmount', label: 'Discount Price', align: 'right' },
];

function GetAmount(val) {
  if (val) {
    return val.toLocaleString('en');
  } else {
    return 0;
  }
}

export default function SalesDetails() {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const [records] = useState(() => {
    if (location.state)
      if (location.state.recordForEdit)
        if (!location.state.recordForEdit.itemsSold) return history.goBack();
        else return location.state.recordForEdit;
      else return history.goBack();
    else return history.goBack();
  });
  const [newItemsSold, setNewItemsSold] = useState([]);
  let miscChargesLimit = JSON.parse(localStorage.getItem('_Misc'));

  useEffect(() => {
    function fnnewItemSold(particularSale) {
      return particularSale.itemsSold.map((particularItemSold) => {
        let particularItemSold_Id =
          particularItemSold.itemRef != null
            ? particularItemSold.itemRef._id
            : 0;
        var matchedReturnedItem = [];
        if (particularSale.itemsReturned.length > 0) {
          matchedReturnedItem = particularSale.itemsReturned.filter(
            (particularItemReturned) => {
              return (
                particularItemReturned.itemRef._id.toString() ==
                particularItemSold_Id.toString()
              );
            }
          );
        }
        return {
          ...particularItemSold,
          qtyReturned:
            matchedReturnedItem.length > 0
              ? matchedReturnedItem[0].quantity
              : 0,
        };
      });
    }

    if (records.itemsSold.length > 0) {
      var newSoldItems = fnnewItemSold(records);
      setNewItemsSold(newSoldItems);
    }
  }, [records]);

  const [filterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(newItemsSold, headCells, filterFn);

  return (
    <>
      <PageTitle
        title={location.state.pageHeaderTitile}
        icon={<ViewListOutlinedIcon fontSize="large" />}
        id="salesDetailsHeading"
      />
      <Paper elevation={1} square className={classes.PaperMargin}>
        <Form>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <Controls.Input
                disabled
                name="shiftNumber"
                label="Shift Number"
                value={records.shiftNumber}
                InputProps={{
                  readOnly: true,
                  disabled: true,
                }}
                id="txtSalesDetailsShiftNumber"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controls.Input
                disabled
                name="clientUpdatedAt"
                label="Date & Time"
                value={ISOdateFormatter(records.clientUpdatedAt)}
                InputProps={{
                  readOnly: true,
                  disabled: true,
                }}
                id="txtSalesDetailsDateTime"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controls.Input
                disabled
                name="invoiceNumber"
                label="Invoice Number"
                value={records.invoiceNumber}
                InputProps={{
                  readOnly: true,
                  disabled: true,
                }}
                id="txtSalesDetailsInvoiceNumber"
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Controls.Input
                disabled
                name="itemsSold"
                label="Products Sold"
                value={records.itemsSold.length}
                InputProps={{
                  readOnly: true,
                  disabled: true,
                }}
                id="txtSalesDetailsItemsSold"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controls.Input
                disabled
                name="itemsReturned"
                label="Products Returned"
                value={records.itemsReturned.length}
                InputProps={{
                  readOnly: true,
                  disabled: true,
                }}
                id="txtSalesDetailsItemsReturned"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controls.Input
                disabled
                name="overallSaleDiscountAmount"
                label="Adjustment"
                value={
                  records.overallSaleDiscountAmount
                    ? records.overallSaleDiscountAmount
                    : 0
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Rs.</InputAdornment>
                  ),
                  readOnly: true,
                  disabled: true,
                }}
                id="txtSalesOverallDiscount"
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Controls.Input
                disabled
                name="saleAmountSumOfItems"
                label="Total Sale Amount"
                value={
                  records.saleAmountSumOfItems
                    ? records.saleAmountSumOfItems.toLocaleString('en')
                    : 0
                }
                InputProps={{
                  readOnly: true,
                  disabled: true,
                  startAdornment: (
                    <InputAdornment position="start">Rs.</InputAdornment>
                  ),
                }}
                id="txtSalesTotalSaleAmount"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controls.Input
                disabled
                name="returnedAmountSumOfItems"
                label="Total Return Amount"
                value={
                  records.returnedAmountSumOfItems
                    ? records.returnedAmountSumOfItems.toLocaleString('en')
                    : 0
                }
                InputProps={{
                  readOnly: true,
                  disabled: true,
                  startAdornment: (
                    <InputAdornment position="start">Rs.</InputAdornment>
                  ),
                }}
                id="txtSalesTotalReturnAmount"
              />
            </Grid>
            {records.deliveryCharges > 0 ? (
              <>
                <Grid item xs={12} sm={4}>
                  <Controls.Input
                    disabled
                    name="deliveryCharges"
                    label="Delivery Charges"
                    value={records.deliveryCharges.toLocaleString('en')}
                    InputProps={{
                      readOnly: true,
                      disabled: true,
                      startAdornment: (
                        <InputAdornment position="start">Rs.</InputAdornment>
                      ),
                    }}
                    id="txtDeliveryCharges"
                  />
                </Grid>
              </>
            ) : (
              ''
            )}

            {miscChargesLimit && miscChargesLimit.status && (
              <>
                <Grid item xs={12} sm={4}>
                  <Controls.Input
                    disabled
                    name="misc"
                    label="Misc. Charges"
                    value={
                      records.miscellaneousCharges
                        ? records.miscellaneousCharges.toLocaleString('en')
                        : 0
                    }
                    InputProps={{
                      readOnly: true,
                      disabled: true,
                      startAdornment: (
                        <InputAdornment position="start">Rs.</InputAdornment>
                      ),
                    }}
                    id="txtMiscCharges"
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12} sm={4}>
              <Controls.Input
                disabled
                name="netSaleTotal"
                label="Net Sale Amount"
                value={(
                  parseFloat(records.deliveryCharges) +
                  parseFloat(
                    records.miscellaneousCharges
                      ? records.miscellaneousCharges
                      : 0
                  ) +
                  parseFloat(
                    records.saleAmountSumOfItems -
                      parseFloat(
                        records.overallSaleDiscountAmount
                          ? records.overallSaleDiscountAmount
                          : 0
                      ) -
                      (records.returnedAmountSumOfItems
                        ? records.returnedAmountSumOfItems
                        : 0)
                  )
                ).toLocaleString('en')}
                InputProps={{
                  readOnly: true,
                  disabled: true,
                  startAdornment: (
                    <InputAdornment position="start">Rs.</InputAdornment>
                  ),
                }}
                id="txtSalesNetSaleAmount"
              />
            </Grid>
          </Grid>
        </Form>
      </Paper>

      <Paper celevation={6} square className={classes.PaperMargin}>
        <Toolbar disableGutters>
          <h2>Products Sold</h2>
        </Toolbar>
        <TblContainer>
          <TblHead />
          <TableBody id="salesDetailsListTbody">
            {recordsAfterPagingAndSorting().map((item, index) =>
              item.itemRef != null ? (
                <TableRow key={item._id} id={`salesDetailsListTrow${index}`}>
                  <TableCell>{item.itemRef.name}</TableCell>
                  <TableCell>{item.itemRef.categoriesRef[0].name}</TableCell>
                  <TableCell align="center">{item.itemRef.barcode}</TableCell>
                  <TableCell align="center">{item.itemRef.sku}</TableCell>
                  <TableCell align="center">
                    {item.quantity.toLocaleString('en')}
                  </TableCell>
                  <TableCell align="center">
                    {GetAmount(item.qtyReturned)}
                  </TableCell>
                  <TableCell className={classes.CellRightAlign}>
                    Rs.{' '}
                    {GetAmount(
                      item.saleFinalPrice * (item.quantity - item.qtyReturned)
                    )}
                  </TableCell>
                  <TableCell className={classes.CellRightAlign}>
                    Rs. {GetAmount(item.discountAmount)}
                  </TableCell>
                </TableRow>
              ) : (
                ''
              )
            )}
          </TableBody>
        </TblContainer>
        <TblPagination />
        <Grid item xs={3}>
          <Controls.Button
            text="Back"
            color="default"
            onClick={history.goBack}
            id="btnBackSaleDetails"
          />
        </Grid>
      </Paper>
    </>
  );
}
