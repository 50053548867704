import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.4em',
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '0px solid slategrey',
    },
  },

  root: {
    padding: theme.spacing(3, 2),
    margin: '5px',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  flex: {
    display: 'flex',
  },
  categoryWindow: {
    width: '15%',
    borderRight: '2px solid #ddd',
    overflowY: 'scroll',
  },
  productWindow: {
    width: '15%',
    borderRight: '2px solid #ddd',
    overflowY: 'scroll',
  },
  CalButton: {
    width: '90%',
    height: '55px',
    margin: '5px',
    fontSize: '20px !important',
    borderRadius: '4px !important',
  },
  resize: {
    fontSize: 50,
  },
  mainWindow: {
    width: '70%',
    overflowY: 'scroll',
  },
  table: {
    minWidth: 650,
  },
  mainTableNumberTextField: {
    width: '100px',
  },
  Cards: {
    marginLeft: '25px',
    marginRight: '25px',
    marginTop: '10px',
    marginBottom: '10px',
    textAlign: 'center',
  },
  button: {
    margin: theme.spacing(1),
  },
  btnClassCheckout: {
    margin: theme.spacing(0),
    backgroundColor: '#4caf50',
    color: '#ffffff',
    border: '0',
    height: '100%',

    '&:hover': {
      backgroundColor: '#388e3c',
    },

    '&:disabled': {
      backgroundColor: '#dddddd',
    },
  },
  paper2: {
    position: 'absolute',
    width: 'auto !important',
    height: 'auto !important',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowX: 'scroll',
  },
  paper6: {
    position: 'absolute',
    width: '350px !important',
    textAlign: 'center',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowX: 'scroll',
    height: '500px !important',
  },
  paper3: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowX: 'scroll',
    height: 'auto !important',
  },
  paper4: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowX: 'scroll',
  },
  paperOpnAmt: {
    height: '200px !important',
    position: 'absolute',
    width: '500px',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowX: 'scroll',
  },
  Cals: {
    margin: '10px',
    textAlign: 'center',
  },
  CalsDetails: {
    margin: '10px',
    width: '250px',
  },
  spanbody: {
    fontSize: '0.80em',
    width: '35px',
    marginInlineStart: '0px',
    marginInlineEnd: '0px',
    fontWeight: 'bold',
    display: 'inline-flex',
    paddingLeft: '2px',
  },
  TextFieldresize: {
    fontSize: 25,
    height: '35px',
    width: '100%',
    padding: '10px 10px !important',
  },
  TextFieldresizeChkout: {
    fontSize: 25,
    height: '45px',
    paddingBottom: '0px',
  },
  tableContent: {
    padding: '20px',
    paddingTop: '0px',
  },
  SearchListStyling: {
    width: '100%',
    maxWidth: '405px',
    maxHeight: '300px',
    backgroundColor: theme.palette.background.paper,
    position: 'absolute',
    zIndex: '99',
    overflowY: 'scroll',
    marginTop: '50px',
    marginLeft: '50px',
    borderRadius: '6px',
    boxShadow: theme.shadows[5],
  },
  companyNameCSS: {
    marginLeft: 'auto',
    marginRight: 'auto',
    color: '#494949',
    textTransform: 'uppercase',
    display: 'flex',
    fontSize: '21px',
    fontWeight: '500',
  },
  toTable: {
    '& thead th': {
      fontWeight: '500',
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
    },
    '& tbody td': {
      fontWeight: '300',
    },
    '& tbody tr:hover': {
      backgroundColor: '#f9f9f9',
      cursor: 'pointer',
    },
  },
  clearItemIcon: {
    color: '#FF0000',
    '&:focus': {
      background: '#FFD2D2AA',
    },
  },
  posTableBRow: {
    '&:focus': {
      background: '#f1f1f1',
    },
  },
  posCustTableRow: {
    '&:focus': {
      background: '#f1f1f1',
    },
  },
  posHeldOTableRow: {
    '&:focus': {
      background: '#f1f1f1',
    },
  },
  paper5: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  paper16: {
    padding: theme.spacing(1),
    textAlign: 'center',
    background: '#FAFAFA',
  },
  RemoveMargin: {
    margin: '0px',
  },
  paper10: {
    zIndex: 999,
    position: 'absolute',
    top: '125px',
    margin: theme.spacing(1),
    height: '490px',
    width: '300px',
  },

  RetailPOSMainTable: {
    '& MuiInputBase-input': {
      color: 'green',
      fontSize: '50px !important',
    },
  },
  posMainDiv: {
    '@media (max-width: 1199px)': {
      flexDirection: 'column',
    },
  },
  posInnerDiv: {
    '@media (max-width: 1199px)': {
      display: 'flex',
    },
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  displayDesktop: {
    '@media (max-width: 1199px)': {
      display: 'none',
    },
    lineHeight: 'normal',
  },
  endShiftCalc: {
    width: '370px',
    '@media (max-width: 991px)': {
      width: '100%',
      marginBottom: '30px',
    },
  },
  endShiftDetail: {
    minWidth: '550px',
    '@media (max-width: 991px)': {
      paddingLeft: '0 !important',
    },
  },
  endShiftMain: {
    display: 'flex',
    '@media (max-width: 991px)': {
      flexWrap: 'wrap',
    },
  },
}));

export default useStyles;
